import React from "react";
import { Link } from "react-router-dom";
import { getIsMobile } from "../../utils/general";

const StickyHeader: React.FC<{
  backLink?: string;
  backText?: string;
  onClickBack?: () => void;
  className?: string;
}> = ({ backLink = "/tests/", backText = "", onClickBack, className = "" }) => {
  const isMobile = getIsMobile();
  const showBackButtonText = !isMobile && backText;

  return (
    // sticky nav bar
    <nav
      className={`w-full sticky top-0 px-6 md:px-12 py-4 flex justify-between align-center items-center z-20 pointer-events-none border-b-2 border-gray-300 ${className}`}
    >
      {/* link back */}
      <Link to={backLink} onClick={onClickBack}>
        <button
          className={`p-2 rounded-full border-2 border-evvy-black text-evvy-black group flex items-center pointer-events-auto`}
          aria-label="Back"
        >
          {/* arrow button visible in desktop */}
          <span
            className={`uppercase ${
              showBackButtonText ? "group-hover:hidden" : ""
            } w-7 sm:w-8 h-7 sm:h-8 text-center`}
          >
            <h4 className="font-bold leading-6">←</h4>
          </span>
          {/* full text button visible on hover on desktop only */}
          {showBackButtonText && (
            <span className="px-2 h-7 sm:h-8 hidden group-hover:inline uppercase tracking-wider font-semibold">
              <span className="pr-2">
                <h4 className="font-bold leading-6 margin-0 inline">←</h4>
              </span>{" "}
              {backText}
            </span>
          )}
        </button>
      </Link>
      {/* evvy logo */}
      <Link to="/tests" className="pointer-events-auto">
        <img
          className="h-8 sm:h-10 cursor-pointer"
          src="/images/evvy-logo-text.svg"
          loading="lazy"
          alt="Evvy Text Logo"
        />
      </Link>
    </nav>
  );
};

export default StickyHeader;
