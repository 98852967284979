import { PurchaseType } from "../../types/care";
import { Cart } from "../../types/cart";

export const getConsultPurchaseTypeFromCart = (cart: Cart): PurchaseType => {
  const bundleInCart = cart.line_items.some(
    (item) => item.item_type === "bundle-treatment"
  );
  const aLaCareTreatmentsInCart = cart.line_items.filter(
    (item) => item.item_type === "individual-treatment"
  );
  const purchaseType =
    bundleInCart && aLaCareTreatmentsInCart.length > 0
      ? "bundle-and-a-la-care"
      : bundleInCart && !aLaCareTreatmentsInCart.length
      ? "dynamic-bundle"
      : "a-la-care";
  return purchaseType;
};
