// A row in the bacteria table
import React, { useCallback, KeyboardEvent } from "react";
import { useSelector, useDispatch } from "react-redux";

import BacteriaTypesTooltip from "../results_v2/bacteriaTypesTooltip";
import { BacteriaRowContent } from "./bacteriaRowContent";
import UnorderedListItem from "../common/unorderedListItem";
import ArrowLink from "../results_v2/arrowLink";

//icons
import { ReactComponent as ArrowUp } from "../common/icons/arrow-up-no-tail.svg";
import { ReactComponent as ArrowDown } from "../common/icons/arrow-down-no-tail.svg";

// lib
import { updateHover, updateSelect } from "../../pages/results_v2/resultsSlice";
import { secondaryColors, typeDictionary } from "../../utils/viz";
import { eventNames } from "../../utils/analytics/customEventTracking";
import { Bacteria, MicrobialLoadLevel } from "../../types/results";
import { LOAD_DISPLAY_MAP } from "../../pages/results_v2/constants";
import { SampleLoad } from "./sampleLoad";

const BacteriaTableRow: React.FC<{
  bgColorClass: string;
  bacteria: Bacteria;
  percent: number;
  microbialLoad?: MicrobialLoadLevel;
  analyticsOnClick: (fn: (() => void) | null, eventName: string) => any;
  isMobile: boolean;
  gradientTo: string;
  gradientFrom: string;
  noBorder?: boolean;
  borderRadiusClass?: string;
}> = ({
  bgColorClass,
  bacteria,
  percent,
  microbialLoad,
  analyticsOnClick,
  isMobile,
  gradientTo,
  gradientFrom,
  noBorder,
  borderRadiusClass = "rounded-none",
}) => {
  /* Redux */
  // redux dispatch method for updating global store
  const dispatch = useDispatch();
  // get hovered & selected bacteria from store
  const hoveredBacteria = useSelector(
    (state: { results: { value: { hovered: any } } }) =>
      state.results.value.hovered
  );
  const selectedBacteria = useSelector(
    (state: { results: { value: { selected: any } } }) =>
      state.results.value.selected
  );
  const hovered = hoveredBacteria === bacteria.id;
  const open = selectedBacteria === bacteria.id;

  // update parent's state on click or keydown (for accessibility)
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === 13 || e.keyCode === 32) {
        open
          ? dispatch(updateSelect(null))
          : dispatch(updateSelect(bacteria.id));
      }
    },
    [open]
  );

  const handleClick = useCallback(() => {
    open ? dispatch(updateSelect(null)) : dispatch(updateSelect(bacteria.id));
  }, [open]);

  const handleHover = useCallback(() => {
    dispatch(updateHover(bacteria.id));
  }, []);

  return (
    <>
      <a href={`#${bacteria.id}-row`} tabIndex={-1}>
        <div
          className={`px-6 sm:px-8 py-7
       ${
         open
           ? "cursor-default"
           : `cursor-pointer ${
               !isMobile ? "hover:bg-gradient-to-r" : ""
             } ${gradientFrom} ${gradientTo}`
       }
       ${
         !open && hovered
           ? `bg-gradient-to-r ${gradientFrom} ${gradientTo}`
           : ""
       }
       ${borderRadiusClass}`}
          role="button"
          tabIndex={0}
          aria-expanded={open}
          aria-labelledby="bacteria information"
          onClick={
            !open
              ? analyticsOnClick(handleClick, eventNames.EXPANDED_RESULTS_CARD)
              : undefined
          }
          onKeyDown={handleKeyDown}
          onMouseOver={!isMobile ? handleHover : undefined}
        >
          <div
            className={`flex items-center justify-between font-semibold ${
              open ? "flex-wrap cursor-pointer" : ""
            }`}
            onClick={open ? handleClick : undefined}
          >
            {/* bacteria name and label */}
            <div className={`flex items-center mr-4 ${open ? "mb-4" : ""}`}>
              {/* show type label when drawer is open otherwise show dot */}
              {open ? (
                <BacteriaTypesTooltip
                  textConfig=""
                  text={typeDictionary[bacteria.type].description}
                >
                  <div
                    className={`rounded-full t4 tracking-wider w-max px-5 py-1 ${bgColorClass}`}
                  >
                    {bacteria.get_type_display}
                  </div>
                </BacteriaTypesTooltip>
              ) : (
                <div
                  className={`w-[22px] h-[22px] rounded-full ${bgColorClass}`}
                  aria-hidden="true"
                />
              )}
            </div>
            <div
              className={`flex items-center justify-between flex-grow t2 ${
                open ? "mb-4" : ""
              }`}
            >
              {/* TODO: add truncate after max-w-3/4? */}
              <div
                className={`${
                  isMobile ? "b2 medium" : "t1"
                } max-w-3/4 text-left`}
              >
                {bacteria.short_name}
              </div>
              {/* bacteria percent */}
              <div
                className={`flex ${isMobile ? "b2 medium" : "t1"} items-center`}
              >
                <div>{percent ? `${percent}%` : "Detected"}</div>
                {/* open / close arrow */}
                <div className="ml-3">
                  {open ? (
                    <ArrowUp fill={secondaryColors["evvy-black"]} />
                  ) : (
                    <ArrowDown fill={secondaryColors["evvy-black"]} />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* detail drawer that opens on click */}
          <div className={`slide ${open ? "" : "hide"} pointer-events-auto`}>
            {/* Microbial load if present */}
            {microbialLoad && (
              <div className="py-2 space-y-4">
                <div className="flex justify-between">
                  <div className="b1 medium">{`Microbial load: ${LOAD_DISPLAY_MAP[microbialLoad]}`}</div>
                  {!percent && (
                    <div className="b1 medium text-evvy-black/50">
                      % Unknown
                    </div>
                  )}
                </div>
                <SampleLoad level={microbialLoad} type={bacteria.type} />
              </div>
            )}
            <BacteriaRowContent bacteria={bacteria} open={open} />
          </div>
        </div>
        {/* bottom border - hidden from screen readers*/}
        {!noBorder && (
          <div
            className="border-evvy-black border-opacity-30 border-b border-dashed mx-8"
            aria-hidden="true"
          />
        )}
      </a>
    </>
  );
};

export default React.memo(BacteriaTableRow);
