/**
 * A simple styled button which is blue with no border
 * @param {string} text a string to label button/link
 * @param {*} onClick click handler for button
 * @param {string} paddingXClass padding class for x axis
 * @param {boolean} disabled whether button is disabled
 * @returns jsx of a button or anchor tag
 */

import React from "react";
import LoadingSpinner from "./loadingSpinner";

const BlueRectangularButton = ({
  text,
  onClick,
  paddingXClass = "sm:px-10",
  paddingYClass = "py-5",
  disabled = false,
  type = "button",
  loading = false,
  fullWidth = false,
  children = null,
}: {
  text: string;
  onClick?: () => void;
  paddingXClass?: string;
  paddingYClass?: string;
  disabled?: boolean;
  type?: "button" | "submit";
  loading?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`t4 font-semibold tracking-wider rounded-sm w-full ${
        !fullWidth && "sm:w-auto"
      } mx-auto ${paddingYClass} ${paddingXClass} bg-evvy-blue text-evvy-black relative`}
    >
      {children ? (
        <div>{children}</div>
      ) : (
        <div className={`w-full ${loading ? "invisible" : ""}`}>{text}</div>
      )}
      {loading && <LoadingSpinner className="text-black absolute inset-0" />}
    </button>
  );
};

export default BlueRectangularButton;
