import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  AddressInfo,
  HdyhauSurvey,
  HealthContextFields,
  Test,
} from "../../../types/test";
import HealthHistoryForm from "../../../components/healthHistoryV2/forms/healthHistoryForm";
import LoadingSpinner from "../../../components/common/loadingSpinner";

const HealthHistory = ({
  healthContext,
  ldtEnabled,
  fetchHealthContext,
  updateHealthContext,
  createHealthContext,
  recordConsent,
  test,
  fetchTest, // used to refetch test to check is_expan_upsell_enabled
  addressInfo,
  updateAddressInfo,
  hdyhauSurvey,
  submitHdyhauSurvey,
}: {
  healthContext: HealthContextFields;
  ldtEnabled: boolean;
  createHealthContext: any;
  recordConsent: any;
  updateHealthContext: (
    hash: string,
    data: any,
    onSubmit: any,
    onError: any
  ) => void;
  test: Test;
  fetchHealthContext: any;
  fetchTest: any;
  addressInfo: AddressInfo;
  updateAddressInfo: any;
  hdyhauSurvey: HdyhauSurvey;
  submitHdyhauSurvey: any;
}) => {
  if (!test) {
    return (
      <div className="bg-evvy-cream min-h-screen flex items-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div>
      <Routes>
        <Route
          path=":pageSlug"
          element={
            <HealthHistoryForm
              test={test}
              fetchTest={fetchTest}
              fetchHealthContext={fetchHealthContext}
              healthContext={healthContext}
              updateHealthContext={updateHealthContext}
              createHealthContext={createHealthContext}
              ldtEnabled={ldtEnabled}
              recordConsent={recordConsent}
              addressInfo={addressInfo}
              updateAddressInfo={updateAddressInfo}
              hdyhauSurvey={hdyhauSurvey}
              submitHdyhauSurvey={submitHdyhauSurvey}
            />
          }
        />
        {/* redirect on load */}
        <Route
          path="/"
          // use the test health context to determine where to redirect because healthContext may not yet be loaded
          element={
            !test.healthcontext?.is_version_2 ? (
              <Navigate to={`/tests/${test.hash}/context`} />
            ) : test.healthcontext?.sample_taken_at ? (
              test.expan_upsell_enabled ? (
                <Navigate to="additional" />
              ) : (
                <Navigate to="health-profile" />
              )
            ) : (
              <Navigate to="intro" />
            )
          }
        />
      </Routes>
    </div>
  );
};

export default HealthHistory;
