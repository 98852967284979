import { postRequest, getRequest, patchRequest } from "../utils/axios";

export const healthContextService = {
  getContext,
  createContext,
  updateContext,
  createExtraData,
  updateRelatedDiagnoses,
};

// hash is always the Test.hash
function getContext(hash, onSuccess, onError) {
  return getRequest(
    `/api/v1/health-context/${hash}/`,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function createContext(hash, onSuccess, onError) {
  return postRequest(
    "/api/v1/health-context/",
    { hash: hash },
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function updateContext(hash, data, onSuccess, onError) {
  // sort answers if stored as an array of answers
  // so that they are consistently sent in the same order
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      data[key].sort();
    }
  });

  return patchRequest(
    `/api/v1/health-context/${hash}/`,
    data,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      console.log("patchrequest error", error);
      console.log("patchrequest response", response);
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function createExtraData(hash, data, onSuccess, onError) {
  return postRequest(
    `/api/v1/health-context/${hash}/extra/`,
    data,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function updateRelatedDiagnoses(hash, data, onSuccess, onError) {
  return postRequest(
    `/api/v1/health-context/${hash}/diagnoses/`,
    { hash, ...data },
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}
