/**
 * A styled button or link (same style, different html for accessibility)
 * @param {[] | jsx} children array of jsx elements or a single jsx element (usually text)
 * @param {boolean} isLink determines whether element is an anchor tag or button
 * @param {string} href an external url
 * @param {string} textColorClass this should be a string for a text color, such as `text-evvy-black`, since in tailwind, the stroke of the text is referenced with `currentColor`.
 * @param {string} bgColorClass this should be a string for a background color, such as `bg-evvy-black`, since in tailwind, the fill of the background is referenced with `currentColor`.
 * @param {string} text a string to label button/link
 * @param {boolean} noIcon for style that does not have an arrow or + after text
 * @param {boolean} arrow for style that has an arrow
 * @param {boolean} arrowRight for style that has a right arrow instead of an up arrow
 * @param {boolean} noTopMargin sets top margin to 0 when true
 * @param {*} onClick click handler for button
 * @returns jsx of a button or anchor tag
 */

import React from "react";

// simple linked Text with either a plus or arrow
const LinkedText = ({
  isLink = false,
  download = false,
  href,
  children,
  borderColorClass = "border-evvy-black",
  onClick,
  arrow = false,
  noTopMargin = false,
  arrowRight = false,
  noIcon = false,
  disabled = false,
  overrideTextClass = "",
  target = "_blank",
}) => {
  return (
    <>
      {isLink ? (
        <a
          href={href}
          download={download}
          target={target}
          onClick={onClick ? onClick : null}
          className={`linkedText ${
            overrideTextClass ? overrideTextClass : "t4"
          } border-b-2 ${borderColorClass} cursor-pointer w-max p-pd ${
            noTopMargin ? "mt-0" : "mt-8"
          }`}
          rel="noreferrer"
        >
          {children}
          {!noIcon &&
            (arrow ? (
              arrowRight ? (
                <span className="ml-1">{"->"}</span>
              ) : (
                "↓"
              )
            ) : (
              <span className="ml-1">+</span>
            ))}
        </a>
      ) : (
        <button
          disabled={disabled}
          className={`linkedText ${
            overrideTextClass ? overrideTextClass : "t4"
          } border-b-2 ${borderColorClass} cursor-pointer w-max p-pd ${
            noTopMargin ? "mt-0" : "mt-8"
          }`}
          onClick={onClick ? onClick : null}
        >
          {children}
          {!noIcon &&
            (arrow ? (
              arrowRight ? (
                <span className="ml-1">{"->"}</span>
              ) : (
                "↓"
              )
            ) : (
              <span className="ml-1">+</span>
            ))}
        </button>
      )}
    </>
  );
};

export default LinkedText;
