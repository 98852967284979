import React, { useEffect, useState, useRef } from "react";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import UpdateReminderMsg from "./updateReminderMessage";
import { indexCheckboxStyles, indexRadioStyles } from "../../utils/colors";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";
import {
  prefillAnswer,
  prefillMultiSelectAnswer,
  uncheckNoneChangeHandler,
} from "../../utils/answerUtils";

const MenstrualHealthForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, test } = subrouteProps;

  // for tooltips
  const periodCountsQuestion = useRef(null);
  const menstrualCycleQuestion = useRef(null);
  const periodDaysQuestion = useRef(null);
  const periodProductsQuestion = useRef(null);
  const periodProductsOtherQuestion = useRef(null);

  const allQuestions = {
    hormonal_period_counts: periodCountsQuestion,
    hormonal_menstrual_cycle: menstrualCycleQuestion,
    hormonal_period_days: periodDaysQuestion,
    hormonal_period_products: periodProductsQuestion,
    hormonal_period_products_other: periodProductsOtherQuestion,
  };

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  const populateInitialValues = () => {
    return {
      hormonal_period_counts: prefillAnswer({
        questionName: "hormonal_period_counts",
        healthContext,
        answerOptions: healthContext._options.hormonal_period_counts,
      }),
      hormonal_menstrual_cycle: prefillAnswer({
        questionName: "hormonal_menstrual_cycle",
        healthContext,
        answerOptions: healthContext._options.hormonal_menstrual_cycle,
      }),
      hormonal_period_days: prefillAnswer({
        questionName: "hormonal_period_days",
        healthContext,
        answerOptions: healthContext._options.hormonal_period_days,
      }),
      hormonal_period_products: prefillMultiSelectAnswer({
        questionName: "hormonal_period_products",
        healthContext,
        answerOptions: healthContext._options.hormonal_period_products,
      }),
      hormonal_period_products_other: prefillAnswer({
        questionName: "hormonal_period_products_other",
        healthContext,
      }),
    };
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "profile",
      questionGroup: "menstrual health",
      testOrder: test.test_order,
      testHash: test.hash,
      version: 1,
    });
  }, []);

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">Menstrual Health</h3>
        <div className="flex-1 leading-5">
          Your menstrual cycle can play a role in your susceptibility to vaginal
          infections. Research shows that the presence of menstrual blood during
          your period can cause shifts in the bacterial composition of your
          vaginal microbiome. Lactobacilli levels seem to be lowest during
          menses (your period), making this the time when you may be most
          susceptible to infections such as BV. Providing this information can
          help us better contextualize your results!
        </div>
      </div>

      {healthContext.past_health_history ? <UpdateReminderMsg /> : ""}

      <Formik
        initialValues={populateInitialValues()}
        validate={(values) => {
          var errors = {};
          Object.keys(allQuestions).forEach((key) =>
            ReactTooltip.hide(allQuestions[key].current)
          );

          // required questions
          const requiredQuestions = ["hormonal_period_counts"];

          // all other questions are only required if someone gets a period
          if (healthContext.basics_recent_period == "YE") {
            requiredQuestions.push(
              "hormonal_menstrual_cycle",
              "hormonal_period_days"
            );

            // required multiselect questions (multi select, needs at least 1)
            const requiredMultiselectQuestions = ["hormonal_period_products"];
            requiredMultiselectQuestions.forEach((key) => {
              if (!values[key].length) {
                errors[key] = "This is a required question";
                ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
              }
            });

            // required "other" context
            const requiredOtherQuestions = ["hormonal_period_products"];
            requiredOtherQuestions.forEach((key) => {
              if (values[key].includes("OT") && !values[`${key}_other`]) {
                errors[`${key}_other`] = "This is a required question";
                ReactTooltip.show(allQuestions[`${key}_other`].current); // manually show, without requiring hover
              }
            });
          }

          requiredQuestions.forEach((key) => {
            if (!values[key]) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          submitCurrentPage(values, (response) => {
            console.log("form got an error", response);
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((k) => {
                ReactTooltip.show(allQuestions[k].current);
              });
            } else {
              setError(response || "Error saving health context");
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("hormonal_period_counts", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("hormonal_period_counts", errors, apiErrors)}
              data-for="hormonal_period_counts"
              ref={periodCountsQuestion}
            >
              <ReactTooltip
                id="hormonal_period_counts"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("hormonal_period_counts")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.hormonal_period_counts.map((o, i) => (
                  <label
                    className="block mb-3 cursor-pointer flex items-center"
                    key={o[0]}
                  >
                    <Field
                      type="radio"
                      name="hormonal_period_counts"
                      value={o[0]}
                      className={indexRadioStyles(i)}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            {healthContext.basics_recent_period == "YE" ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("hormonal_menstrual_cycle", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "hormonal_menstrual_cycle",
                  errors,
                  apiErrors
                )}
                data-for="hormonal_menstrual_cycle"
                ref={menstrualCycleQuestion}
              >
                <ReactTooltip
                  id="hormonal_menstrual_cycle"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("hormonal_menstrual_cycle")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <p>
                  psst: this is the number of days between the first day of one
                  period to the first day of the next period
                </p>
                <div role="group" aria-labelledby="checkbox-group">
                  {healthContext._options.hormonal_menstrual_cycle.map(
                    (o, i) => (
                      <label className="block mb-3 cursor-pointer" key={o[0]}>
                        <Field
                          type="radio"
                          name="hormonal_menstrual_cycle"
                          value={o[0]}
                          className={indexRadioStyles(i)}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    )
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {healthContext.basics_recent_period == "YE" ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("hormonal_period_days", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("hormonal_period_days", errors, apiErrors)}
                data-for="hormonal_period_days"
                ref={periodDaysQuestion}
              >
                <ReactTooltip
                  id="hormonal_period_days"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("hormonal_period_days")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <div role="group" aria-labelledby="checkbox-group">
                  {healthContext._options.hormonal_period_days.map((o, i) => (
                    <label className="block mb-3 cursor-pointer" key={o[0]}>
                      <Field
                        type="radio"
                        name="hormonal_period_days"
                        value={o[0]}
                        className={indexRadioStyles(i)}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {healthContext.basics_recent_period == "YE" ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("hormonal_period_products", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "hormonal_period_products",
                  errors,
                  apiErrors
                )}
                data-for="hormonal_period_products"
                ref={periodProductsQuestion}
              >
                <ReactTooltip
                  id="hormonal_period_products"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("hormonal_period_products")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <p>Please select all that apply</p>
                <div role="group" aria-labelledby="checkbox-group">
                  {healthContext._options.hormonal_period_products.map(
                    (o, i) => (
                      <label
                        className="block mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="checkbox"
                          name="hormonal_period_products"
                          value={o[0]}
                          className={indexCheckboxStyles(i)}
                          onChange={uncheckNoneChangeHandler({
                            handleChange,
                            setFieldValue,
                            fieldName: "hormonal_period_products",
                            fieldValues: values.hormonal_period_products,
                          })}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    )
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {healthContext.basics_recent_period == "YE" &&
            values.hormonal_period_products.includes("OT") ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("hormonal_period_products_other", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "hormonal_period_products_other",
                  errors,
                  apiErrors
                )}
                data-for="hormonal_period_products_other"
                ref={periodProductsOtherQuestion}
              >
                <ReactTooltip
                  id="hormonal_period_products_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("hormonal_period_products_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="hormonal_period_products_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MenstrualHealthForm;
