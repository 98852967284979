import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

/* Local Imports */
// data
import { testsService } from "../../services/tests";
import { compareService } from "../../services/compare";

// components
import Layout from "../../components/layout";
import ErrorBoundary from "../../components/common/errorBoundary";
import Modal from "../../components/common/modal";
import Selection from "../../components/compare/selection";
import CompareTypeModal from "../../components/compare/typeModal";
import CompareInfoModal from "../../components/compare/infoModal";
import CompareVizLegend from "../../components/compare/compareVizLegend";
import CompareViz from "./viz";
import TextCard from "../../components/compare/textCard";
import { ReactComponent as Info } from "../../components/common/icons/info.svg";
import Tippy from "@tippyjs/react/headless";

// lib
import {
  setHoveredValence,
  setClickedValence,
  setTypeModalData,
} from "./compareSlice";
import { formatters } from "../../utils/viz";
import {
  listItemDictionary,
  typesDictionary,
  emptyCopy as E,
  mobileCopy,
  dataTypes,
} from "./constants";
import { useResponsiveSize } from "../../utils/customHooks";
import { getPeriodContext, lastPeriod } from "../../utils/copy";
import { contextLanguage } from "./helpers";
import RectangularButton from "../../components/common/rectangularButton";
import {
  eventHandlers,
  eventNames,
  sendViewedComparePage,
  sendViewedComparePopUp,
} from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { sageDatabaseUpdateCopy } from "../constants";
import { useLocation } from "react-router-dom";

/** TOOLTIP
 * This tooltip is on rollover of the info button when viewing the typical results of a type */
const Tooltip = () => (
  <Tippy
    arrow
    render={() => (
      <div
        id="tooltip"
        role="tooltip"
        className="hidden md:flex bg-evvy-pine-gradientLight p-3 rounded-md max-w-sm"
        tabIndex={-1}
      >
        <div className="text-evvy-white">
          We’ve compiled the average microbial results and health history
          responses for members of each Community State Type (CST) to make it
          easy to contextualize your results.
        </div>
      </div>
    )}
    placement="bottom"
    animation={false}
  >
    <Info className="ml-1 mb-1 w-4 h-4 inline" />
  </Tippy>
);

/** COMPARE */
const Compare = () => {
  const comparePageKey = `visited-compare-page`; // localstorage key for visiting compare page

  /* state management */
  const dispatch = useDispatch();
  const [infoModalOpen, setInfoModalOpen] = useState(
    localStorage.getItem(comparePageKey) ? false : true
  );
  const [compareLoading, setCompareLoading] = useState(false);
  const [baseLoading, setBaseLoading] = useState(true);
  const [tests, setTests] = useState([]);
  const [emptyCopy, setEmptyCopy] = useState(E.DEFAULT);
  const [base, setBase] = useState({
    selectedOption: null,
    selectedDatatype: null,
    selectedQueryParams: { hash: null, compare_type: null },
    testId: null,
  });
  const [compare, setCompare] = useState({
    selectedOption: dataTypes.DEFAULT,
    selectedDatatype: dataTypes.TEST,
    selectedQueryParams: { hash: null, compare_type: null },
    testId: null,
  });
  const [baseData, setBaseData] = useState(null);
  const [compareData, setCompareData] = useState(null);

  // the associated behavior in this file should only happen in the instance of clicked, not hover
  const selectedValence = useSelector(
    (state) => state.compare.value.clickedValence
  );

  const [typeModalDataType, typeModalDataInfo, typeModalDataOpen] = useSelector(
    (state) => state.compare.value.typeModalData
  );

  /* Custom Hooks */
  const [[width, height]] = useResponsiveSize(window.innerWidth, 700);

  /* Route Params */
  const location = useLocation();

  /* Config */
  const config = {
    test: {
      options: tests.filter((d) => d.status === "RD"), // only show results ready tests
      labelAcc: (d) =>
        formatters.abbrDate(new Date(d.healthcontext.sample_taken_at)),
      valAcc: (d) => d.hash,
      compare_type: "previous",
      displayAcc: (d) => {
        return (
          <>
            <div>Your Results from</div>
            <div>
              {formatters.abbrDate(new Date(d.healthcontext.sample_taken_at))}
            </div>
          </>
        );
      },
    },
    type: {
      options: Object.keys(typesDictionary),
      labelAcc: (d) => typesDictionary[d],
      valAcc: (d) => d,
      compare_type: "valencia",
      displayAcc: (d, tooltipBoolean) => {
        return (
          <>
            <div>Results from</div>
            <div>
              {`Type ${d}`}
              {tooltipBoolean && <Tooltip />}
            </div>
          </>
        );
      },
    },
  };

  /* Effects */
  // First render
  useEffect(() => {
    // fetch all tests
    fetchTests();
    sendViewedComparePage();
    // record that we were here!
    recordVisit();
  }, []);

  useEffect(() => {
    // call fetch tests passing query params
    queryHandler(base, setBaseData, setBaseLoading);
  }, [JSON.stringify(base.selectedQueryParams)]);

  useEffect(() => {
    // call fetch tests passing query params
    queryHandler(compare, setCompareData, setCompareLoading);
  }, [JSON.stringify(compare.selectedQueryParams)]);

  useEffect(() => {
    if (infoModalOpen) {
      sendViewedComparePopUp();
    }
  }, [infoModalOpen]);

  /* Actions */
  const fetchTests = () => {
    testsService.fetchTests(
      (response) => {
        const tests = response.data;
        setTests(tests);
        let initialTest;
        const { state } = location;
        // if directed to compare from specific test, find test based on location state
        if (state) {
          const { i_type, i_id } = state;
          // if not null, get data from query
          if (i_type && i_id) {
            initialTest = tests.find(({ hash }) => hash === i_id);
          }
        } else {
          // else tests return from db pre-sorted by modify date: find first 'Ready' test
          initialTest = tests.find(({ status: status }) => status === "RD");
        }
        const { hash, pretty_hash } = initialTest;
        const selectedDatatype = dataTypes.TEST;
        const selectedOption = initialTest;
        const selectedQueryParams = {
          compare_type: config[dataTypes.TEST].compare_type,
          hash: hash,
        };
        const testId = pretty_hash;
        // update base state with retrieved info
        setBase((prevBase) => ({
          ...prevBase,
          selectedDatatype,
          selectedOption,
          selectedQueryParams,
          testId,
        }));
      },
      (error) => {
        console.error(error);
      }
    );
  };

  function fetchCompareData(params, setData, setLoading) {
    const { compare_type, hash } = params;
    if (compare_type && hash) {
      compareService.fetchData(
        params,
        (response) => {
          setData(response.data);
          setLoading(false);
        },
        (error) => {
          setData(null);
          setLoading(false);
          setEmptyCopy(E.ERROR);
          console.log(error.message);
        }
      );
    }
  }

  const recordVisit = () => {
    localStorage.setItem(comparePageKey, true);
  };

  const queryHandler = (state, setData, setLoading) => {
    const { selectedQueryParams } = state;
    if (selectedQueryParams) {
      const { hash, compare_type } = selectedQueryParams;
      if (hash && compare_type) {
        fetchCompareData(selectedQueryParams, setData, setLoading);
      } else {
        setData(null);
      }
    }
  };

  return (
    <Layout title="Compare" bgClass="bg-evvy-cream">
      {/* MOBILE */}
      <div className="md:hidden m-auto max-w-md">
        <h1>{mobileCopy.HEADER}</h1>
        <p className="t1">{mobileCopy.CONTENT}</p>
      </div>

      {/* DESKTOP */}
      <div className="hidden md:block pb-10 px-3 lg:px-0">
        {/* Initial intro modal */}
        {infoModalOpen && (
          <Modal
            closeModal={() => setInfoModalOpen(false)}
            widthClass="max-w-5xl"
          >
            <CompareInfoModal handleClose={() => setInfoModalOpen(false)} />
          </Modal>
        )}

        {/* Type modal (learn more about type...) */}
        {typeModalDataOpen && (
          <Modal
            closeModal={() => dispatch(setTypeModalData([null, null, false]))}
          >
            <CompareTypeModal
              type={typeModalDataType}
              typeData={typeModalDataInfo}
              handleClose={() =>
                dispatch(setTypeModalData([null, null, false]))
              }
            />
          </Modal>
        )}

        {/* drop downs */}
        <ErrorBoundary>
          <Selection
            w={width}
            h={height}
            config={config}
            base={base}
            setBase={setBase}
            setBaseLoading={setBaseLoading}
            compare={compare}
            setCompare={setCompare}
            setCompareLoading={setCompareLoading}
            emptyCopy={emptyCopy}
            setEmptyCopy={setEmptyCopy}
          />

          {/* chart */}
          <div className="bg-white md:rounded-lg md:border border-evvy-black border-opacity-30 border-dashed border-black border-opacity-50 md:h-compare-screen min-h-600">
            {/* make sure date is loaded from drop down */}
            <div className="grid specialGrid md:py-4 items-center justify-items-center relative z-10 h-full">
              {/* Bring up initial compare modal */}
              <div
                className="absolute top-2 right-2 h-0 content cursor-pointer"
                onClick={analyticsClickHandler({
                  eventName: eventNames.CLICKED_MORE_INFO_COMPARE,
                  eventArgs: {},
                  clickHandler: () => setInfoModalOpen(true),
                })}
              >
                <Info className="ml-1 mb-1 w-4 h-4 inline" />
              </div>

              {/* COMPARE empty state */}
              {!compareData && !compareLoading && (
                <div
                  className="row-start-2 col-start-1 bg-evvy-cream h-110 w-full transform translate-x-1/4 rounded-md flex items-center justify-end text-center"
                  style={{ zIndex: -1 }}
                >
                  <div className="max-w-300 w-1/2 h-fit my-auto mr-40 ml-auto">
                    <h5 className="h-full">
                      {compare.selectedOption !== dataTypes.DEFAULT &&
                        config[compare.selectedDatatype]?.displayAcc(
                          compare.selectedOption,
                          false
                        )}
                    </h5>
                    {config.test.options.length > 1 ? (
                      <div className="leading-5 t3 px-4">{emptyCopy}</div>
                    ) : (
                      <>
                        <div className="leading-5">{E.ONE_TEST}</div>
                        <div className="t4 mt-4">for evvy members:</div>
                        <div className="mb-1">{E.EVVY_MEMBERS}</div>
                        <div className="t4 my-4">not an evvy member yet?</div>
                        <a
                          href="https://checkout.evvy.com/cart/clear?return_to=/cart/add?items[][id]=40109629243543%26items[][quantity]=1%26items[][selling_plan]=442466455%26return_to=/checkout"
                          target="_blank"
                        >
                          <RectangularButton
                            text="Upgrade Now →"
                            textColorClass="text-evvy-black"
                            bgColorClass="bg-evvy-blue"
                            fullWidth
                            onClick={analyticsClickHandler({
                              eventName: eventNames.CLICKED_UPGRADE_NOW,
                              eventArgs: {
                                testHash: base?.selectedOption?.hash,
                                cst: baseData?.common_community_type,
                              },
                            })}
                          />
                        </a>
                      </>
                    )}
                  </div>
                </div>
              )}

              {/* BASE empty state */}
              {!baseData && !baseLoading && (
                <div
                  className="row-start-2 col-start-3 bg-evvy-cream h-110 w-full transform -translate-x-1/4 rounded-md flex items-center justify-end"
                  style={{ zIndex: -1 }}
                >
                  <div className="max-w-300 w-1/2 h-fit my-auto ml-24 md:ml-32 lg:ml-40 mr-auto text-right">
                    <h5 className="h-full">
                      {base.selectedOption !== dataTypes.DEFAULT &&
                        config[base.selectedDatatype]?.displayAcc(
                          base.selectedOption,
                          false
                        )}
                    </h5>
                    <div className="leading-5">{emptyCopy}</div>
                  </div>
                </div>
              )}

              {/* COMPARE header */}
              {compareData && !compareLoading && (
                <h5 className="row-start-1 col-start-1 h-fit w-full max-w-300 my-4 ml-auto mr-auto lg:mr-60 px-4 lg:px-0">
                  {compare.selectedOption !== dataTypes.DEFAULT &&
                    config[compare.selectedDatatype]?.displayAcc(
                      compare.selectedOption,
                      true
                    )}
                </h5>
              )}

              {/* BASE header */}
              {baseData && !baseLoading && (
                <h5 className="row-start-1 col-start-3 h-fit w-full max-w-300 my-4 ml-auto lg:ml-60 mr-auto px-4 lg:px-0">
                  {base.selectedOption !== dataTypes.DEFAULT &&
                    config[base.selectedDatatype]?.displayAcc(
                      base.selectedOption,
                      true
                    )}
                </h5>
              )}

              {/* COMPARE text */}
              {compareData && !compareLoading && (
                <div className="hide-scrollbar row-start-2 col-start-1 py-2 h-full w-full max-w-300 ml-auto mr-auto lg:mr-60 px-4 lg:px-0 overflow-auto">
                  <div className="h-12">
                    {compareData.common_community_type &&
                      (compare.selectedDatatype === dataTypes.TEST ? (
                        <div className="t4 text-evvy-blue">{`ID: ${compare.testId}`}</div>
                      ) : (
                        <div className="text-sm cursor-pointer underline">
                          {/* Temporarily disabling as types are removed from compare page for now */}
                          {/* onClick={() => setTypeModalData([compareData.common_community_type, compareData.common_community_type_info, true])} */}
                          {`Learn more about Type ${compareData.common_community_type}`}
                        </div>
                      ))}
                  </div>
                  <TextCard
                    selectedValence={selectedValence}
                    selectedValenceData={
                      selectedValence !== null &&
                      compareData &&
                      compareData.bacteria_valences?.[selectedValence].bacteria
                    }
                    contextLanguage={
                      !compareLoading &&
                      compareData &&
                      contextLanguage(
                        compareData,
                        compare.selectedDatatype,
                        compare.selectedOption
                      )
                    }
                    compareDiffVersion={
                      compareData &&
                      baseData &&
                      compareData.test_version === "sage" &&
                      compareData.test_version !== baseData.test_version
                    }
                  />
                </div>
              )}

              {/* CENTER viz */}
              <div className="row-start-2 col-start-2 flex flex-col items-center h-full w-full">
                <CompareViz
                  baseData={baseData}
                  compareData={compareData}
                  baseLoading={baseLoading}
                  compareLoading={compareLoading}
                />
              </div>

              {/* BASE text */}
              <div className="hide-scrollbar row-start-2 col-start-3 py-2 h-full w-full max-w-300 ml-auto lg:ml-60 mr-auto px-4 lg:px-0 overflow-auto">
                {baseData && !baseLoading && (
                  <div className="h-12">
                    {baseData?.common_community_type &&
                      (base.selectedDatatype === dataTypes.TEST ? (
                        <div className="t4 text-evvy-blue">{`ID: ${base.testId}`}</div>
                      ) : (
                        <div className="text-sm cursor-pointer underline">
                          {/* Temporarily disabling as types are removed from compare page for now */}
                          {/* onClick={() => setTypeModalData([baseData?.common_community_type, baseData?.common_community_type_info, true])} */}
                          {`Learn more about Type ${baseData?.common_community_type}`}
                        </div>
                      ))}
                  </div>
                )}

                <TextCard
                  selectedValence={selectedValence}
                  selectedValenceData={
                    selectedValence !== null &&
                    baseData &&
                    baseData.bacteria_valences?.[selectedValence].bacteria
                  }
                  contextLanguage={
                    !baseLoading &&
                    baseData &&
                    contextLanguage(
                      baseData,
                      base.selectedDatatype,
                      base.selectedOption
                    )
                  }
                  compareDiffVersion={
                    compareData &&
                    baseData &&
                    baseData.test_version === "sage" &&
                    compareData.test_version !== baseData.test_version
                  }
                />
              </div>

              {selectedValence &&
                compareData &&
                baseData &&
                compareData.test_version !== baseData.test_version &&
                (baseData.bacteria_valences?.[selectedValence].bacteria
                  .map((x) => x.is_new && !x.tooltip)
                  .some((y) => y) ||
                  compareData.bacteria_valences?.[selectedValence].bacteria
                    .map((x) => x.is_new && !x.tooltip)
                    .some((y) => y)) && (
                  <div className="row-start-3 col-start-3 self-end justify-self-end pl-14 pr-3.5">
                    <div className="bg-gray-200 p-2 text-sm rounded-md text-left">
                      {sageDatabaseUpdateCopy}
                    </div>
                  </div>
                )}

              {/* LEGEND */}
              <div className="row-start-3 col-span-full pt-6 self-end">
                <CompareVizLegend
                  wrapOnSm
                  dotConfig="w-4 h-4"
                  textConfig="uppercase text-[12px] font-semibold leading-5 cursor-pointer"
                  tooltipTextConfig="text-sm"
                  onMouseOver={(code) => dispatch(setHoveredValence(code))}
                  onMouseOut={() => dispatch(setHoveredValence(null))}
                  onClick={(code) => dispatch(setClickedValence(code))}
                  selected={selectedValence}
                />
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </div>
    </Layout>
  );
};

export default Compare;
