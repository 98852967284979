import React from "react";
import {
  Page,
  Text,
  Link,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";

import { Marked } from "@ts-stack/markdown";

import pdfStyles from "./pdfStyles.js";
import { fill } from "../../utils/viz.js";

const DEFAULT_BACTERIA_DESCRIPTION =
  "The scientific community is still figuring out this microbe's exact role in the vaginal microbiome, but we will keep your results updated as further research emerges!";

// change styling in /pdfStyles.js
const styles = StyleSheet.create(pdfStyles[0] as any);

interface MyDocumentProps {
  microbiome: any;
  contextLanguage?: Array<{ text: string; coloredText: string; outro: string }>;
  showIntroPage?: boolean;
}

const MyDocument: React.FC<MyDocumentProps> = ({
  microbiome,
  contextLanguage = undefined,
  showIntroPage = true,
}) => {
  // results pill color data loaded from utils/viz.js
  const bacteriaTagColor = function (bacteriaType: any) {
    return fill(bacteriaType);
  };

  // logo img src
  const imgUrl = `${process.env.PUBLIC_URL}/images/evvy-logo-text.png`;

  // define replaceAll in case it's not available (10% of browsers)
  if (typeof String.prototype.replaceAll == "undefined") {
    (String.prototype as any).replaceAll = function (
      match: string,
      replace: string
    ) {
      return this.replace(new RegExp(match, "g"), replace);
    };
  }

  return (
    <Document>
      {showIntroPage && (
        <Page size="A4" style={styles.body}>
          <Image
            style={styles.image}
            // src="https://mma.prnewswire.com/media/1573444/Evvy_Logo.jpg"
            src={imgUrl}
          />

          <View
            style={[styles.pt_l, styles.pb_s, styles.border_t, styles.border_b]}
          >
            <Text
              style={[
                styles.fw_normal,
                styles.fs_body,
                styles.lh_lg,
                styles.pb_l,
              ]}
            >
              The attached results are from the{" "}
              <Link src="" style={[styles.fw_bold]}>
                Evvy Vaginal Health Test
              </Link>
              , an at-home test of the vaginal microbiome that leverages
              metagenomic sequencing to identify all bacteria and fungi that are
              present in a sample.
            </Text>
          </View>
          <View style={[styles.pt_l, styles.pb_s, styles.border_b]}>
            <Text
              style={[
                styles.fw_medium,
                styles.fs_body,
                styles.fw_bold,
                styles.pb_m,
                { color: "#6ca7ff" },
              ]}
            >
              ABOUT THE TEST
            </Text>
            <Text
              style={[
                styles.fw_normal,
                styles.fs_body,
                styles.lh_lg,
                styles.pb_l,
              ]}
            >
              All Evvy tests are analyzed in a U.S. laboratory that is certified
              by CLIA standards. Microbial DNA is extracted from the vaginal
              swab sample and analyzed using metagenomics next-gen sequencing
              (mGNS).
            </Text>
            <Text
              style={[
                styles.fw_normal,
                styles.fs_body,
                styles.lh_lg,
                styles.pb_l,
              ]}
            >
              This report provides a comprehensive understanding of the state of
              the vaginal microbiome with detailed information on the relative
              abundance of bacteria and fungi. This information may uncover
              insights into which interventions are most likely to work based on
              pathogens present and symptoms reported.
            </Text>
          </View>
          <View style={[styles.pt_l, styles.pb_s, styles.border_b]}>
            <Text
              style={[
                styles.fw_medium,
                styles.fs_body,
                styles.fw_bold,
                styles.pb_m,
                { color: "#41907a" },
              ]}
            >
              RESULTS AND INTERPRETATION
            </Text>
            <Text
              style={[
                styles.fw_normal,
                styles.fs_body,
                styles.lh_lg,
                styles.pb_l,
              ]}
            >
              Detecting the presence of certain bacteria by this test does not
              imply diagnosis of a disease state. This test is for research use
              only. Some of these bacteria may not be considered pathogenic.
              Evvy’s Vaginal Health Test is not a diagnostic test. It is
              intended to be used in context with other clinical criteria and
              lab tests for patient management decisions.
            </Text>
          </View>
          <View style={[styles.pt_l, styles.pb_m]}>
            <Text
              style={[
                styles.fw_medium,
                styles.fs_body,
                styles.fw_bold,
                styles.pb_m,
                { color: "#b0b0b0" },
              ]}
            >
              CONTACT US
            </Text>
            <Text
              style={[
                styles.fw_normal,
                styles.fs_body,
                styles.lh_lg,
                styles.pb_l,
                { width: "60%" },
              ]}
            >
              We’re building a community of mission-driven care providers
              devoted to creating the future of women’s health research and
              education.
            </Text>
            <Text
              style={[
                styles.fw_normal,
                styles.fs_body,
                styles.lh_lg,
                styles.pb_l,
                { width: "60%" },
              ]}
            >
              If you have any questions for our clinical team, please feel free
              to contact us at providers@evvy.com.
            </Text>
          </View>

          <Text style={[styles.ta_c, styles.fw_normal, styles.fs_h4]}>
            EVVY.COM
          </Text>
        </Page>
      )}
      {/* to end page/create new page, use either
    ...</Page> or <View ... break>...*/}

      {contextLanguage && contextLanguage?.length > 0 && (
        <Page size="A4" style={styles.body} wrap>
          <View style={[styles.pt_xl, styles.pb_m]}>
            <Text style={[styles.fs_body, styles.pb_xxl, styles.fw_bold]}>
              AT THE TIME OF TAKING YOUR SAMPLE, YOU TOLD US:
            </Text>
            {contextLanguage.map(
              (el: { text: string; coloredText: string; outro: string }) => (
                <View
                  style={[
                    styles.border_t,
                    styles.pt_m,
                    styles.pb_s,
                    styles.d_flex,
                    styles.flex_row,
                  ]}
                  key={el.coloredText}
                >
                  <Text
                    style={[
                      styles.fs_body,
                      styles.lh_md,
                      styles.pb_l,
                      { color: "#41907a" },
                      styles.fs_util_1,
                    ]}
                  >
                    &bull;&nbsp;
                  </Text>
                  <Text
                    style={[
                      styles.fw_normal,
                      styles.fs_body,
                      styles.lh_lg,
                      styles.pb_l,
                      styles.pt_s,
                    ]}
                  >
                    {el.text}&nbsp;
                    <Link
                      src=""
                      style={[
                        styles.fw_normal,
                        styles.fs_body,
                        styles.lh_lg,
                        styles.pb_l,
                        styles.pt_s,
                        { color: "#41907a" },
                      ]}
                    >
                      {el.coloredText}
                    </Link>
                    &nbsp;{el.outro}
                  </Text>
                </View>
              )
            )}
          </View>
        </Page>
      )}

      <Page size="A4" style={styles.body} wrap>
        <View style={[styles.pt_xl, styles.pb_m]}>
          {microbiome.map(
            (row_el: any, row_i: number) =>
              row_el.bacteria && (
                <View
                  key={"view-" + row_i}
                  style={[styles.border_t, styles.pt_m, styles.pb_xl]}
                >
                  <View
                    style={[
                      styles.d_flex,
                      styles.flex_row,
                      styles.ta_btw,
                      styles.pb_m,
                    ]}
                  >
                    <View
                      style={[styles.d_flex, styles.flex_row, styles.ta_strt]}
                    >
                      <Text
                        style={[
                          styles.fw_normal,
                          styles.ta_l,
                          styles.fs_h3,
                          styles.fw_bold,
                          styles.d_flex,
                          styles.lh_md,
                          styles.align_c,
                        ]}
                      >
                        {row_el.bacteria.short_name}&nbsp;
                      </Text>
                      <View
                        style={[
                          styles.pt_xs,
                          styles.ph_md,
                          styles.ta_l,
                          styles.lh_s,
                          {
                            borderRadius: 2,
                            backgroundColor: bacteriaTagColor(
                              row_el.bacteria.type
                            ),
                            textTransform: "uppercase",
                          },
                        ]}
                      >
                        <Text
                          style={[
                            styles.fw_normal,
                            styles.fs_h5,
                            styles.lh_lg,
                            styles.pb_xs,
                            styles.d_flex,
                            styles.align_c,
                            { marginBottom: 0 },
                          ]}
                        >
                          {row_el.bacteria.get_type_display}
                        </Text>
                      </View>
                    </View>
                    <Text
                      style={[
                        styles.fw_normal,
                        styles.fs_body,
                        styles.lh_s,
                        styles.pb_l,
                        styles.d_flex,
                        styles.align_c,
                      ]}
                    >
                      {row_el.normalized_percent
                        ? `${row_el.normalized_percent}%`
                        : "Detected"}
                    </Text>
                  </View>
                  <Html
                    stylesheet={{
                      p: styles.htmlBlocks,
                    }}
                  >
                    {row_el.bacteria.description
                      ? Marked.parse(row_el.bacteria.description).replaceAll(
                          "|",
                          ""
                        )
                      : Marked.parse(DEFAULT_BACTERIA_DESCRIPTION)}
                  </Html>
                </View>
              )
          )}
        </View>
        <Text
          style={[styles.disclaimer, styles.fs_italic]}
          fixed
        >{`The descriptions, associations, and “status” of each microbe are based on literature and meant for educational purposes. They are not meant to serve as a diagnosis and/or analysis of your individual results.`}</Text>

        {/* page count */}
        <Text
          style={[
            styles.fs_h4,
            styles.fw_normal,
            styles.ta_c,
            styles.pageNumber,
          ]}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default MyDocument;
