import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/common/modal";
import CloseX from "../common/closeX";
import RectangularButton from "../common/rectangularButton";
import { indexRadioStyles } from "../../utils/colors";
import { CANCELLATION_EMAIL } from "../../pages/constants";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import {
  sendSelectedCancellationReason,
  sendSubmitCancelMembership,
} from "../../utils/analytics/customEventTracking";

const MODAL_TITLE = "Cancel Membership";
const CANCELLATION_REASONS = [
  "It's too expensive",
  "I signed up for a membership on accident",
  "I'm no longer interested in testing my vaginal microbiome",
  "I'm switching to another at-home testing service or treatment provider",
  "I'm feeling better and don't need to test anymore",
  "I had a negative interaction with an Evvy team member / care member",
  "I have tests at home that I haven't used yet",
  "The Evvy experience did not meet my expectations",
  "My results took too long",
  "I prefer to test on a one-off basis going forward",
  "Other",
];
const CANCEL_MEMBERSHIP = "Cancel Membership";
const SELECT_REASON = "Please select a cancellation reason";
const SUBMIT_CANCELLATION = "Submit Cancellation";

export const SubscriptionCancelModal = () => {
  // cancellation reason state
  const [cancelReason, setCancelReason] = useState("");
  const [cancelReasonOther, setCancelReasonOther] = useState("");

  // context
  const currentUser = useLoggedInUser();

  const emailBody = `I would like to cancel my Evvy membership.%0D%0A
  Name: ${currentUser.identity.firstName} ${currentUser.identity.lastName}%0A
  Email: ${currentUser.identity.email}%0A
  My reason for cancelling is: ${cancelReason}${
    cancelReason === "Other" ? `: ${cancelReasonOther}` : ""
  }`;

  // route params
  const navigate = useNavigate();

  const closeModal = () => {
    navigate("/account/");
  };

  return (
    <Modal widthClass="w-full md:max-w-lg" closeModal={closeModal}>
      <div className="bg-white py-12 px-6 md:px-12">
        <CloseX
          handleClose={closeModal}
          side="right"
          textColorClass="text-evvy-black-dull"
        />
        <div className="t1 text-evvy-pine">{MODAL_TITLE}</div>
        <div className="mt-4 space-y-4">
          <h4 className="t3">{SELECT_REASON}</h4>
          <div role="group" aria-labelledby="checkbox-group">
            {CANCELLATION_REASONS.map((reason, index) => (
              <div
                key={index}
                className="block mb-3 cursor-pointer flex items-center"
              >
                <input
                  type="radio"
                  id={reason}
                  name="reason"
                  className={indexRadioStyles(index)}
                  value={reason}
                  checked={cancelReason === reason}
                  onChange={() => {
                    setCancelReason(reason);
                    sendSelectedCancellationReason({ cancelReason: reason });
                  }}
                />
                <label
                  htmlFor={reason}
                  className="block cursor-pointer flex items-center"
                >
                  <span className="ml-2">{reason}</span>
                </label>
              </div>
            ))}
          </div>
          {/* cancel other reason if other is selected */}
          {cancelReason === "Other" && (
            <input
              type="text"
              id="other"
              name="other"
              className="appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black focus:outline-none focus:ring-transparent focus:border-crazy-purple"
              placeholder="Please specify"
              onChange={(e) => setCancelReasonOther(e.target.value)}
            />
          )}
          <div className="flex justify-center px-2 pt-2">
            <RectangularButton
              textColorClass="text-black"
              fullWidth
              isLink
              href={`mailto:${CANCELLATION_EMAIL}?subject=${CANCEL_MEMBERSHIP}&body=${emailBody}`}
              verticalPadding="py-5"
              onClick={() => {
                sendSubmitCancelMembership({ cancelReason });
                closeModal();
              }}
              text={SUBMIT_CANCELLATION}
              disabled={
                !cancelReason ||
                (cancelReason === "Other" && !cancelReasonOther)
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
