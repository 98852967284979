import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout";
import { Link, useNavigate } from "react-router-dom";
import { sendProviderClickedJoinCommunity } from "../../utils/analytics/customEventTracking";
import { useProviderProfile } from "../../hooks/provider/useProviderProfile";

type WaitingForVerificationProps = {};

export const WaitingForVerification: FC<WaitingForVerificationProps> = () => {
  const [profile] = useProviderProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile?.verified) {
      navigate("/");
    }
  }, [navigate, profile]);

  return (
    <Layout noHeader padded centered bgClass="bg-evvy-cream">
      <div className="block w-full mt-32 sm:mt-10 mb-4 ">
        <Helmet>
          <title>Providers | Verification</title>
        </Helmet>
        <div className="max-w-4xl py-10 mx-auto px-8 sm:px-0">
          <div className="sm:flex">
            <h2 className="flex-1 mr-2">
              Thank you for submitting your application!
            </h2>

            <div className="flex-1">
              <div className="uppercase mb-4 inline-block font-semibold tracking-wider text-sm">
                What's next
              </div>
              <p>
                The Evvy team will{" "}
                <b>review and verify your information in 1-2 business days</b>,
                and if approved you will receive an email with a link to access
                your provider portal.
              </p>
              <p>
                Your provider portal is your one-stop shop to order Evvy tests
                for your patients, view the status of tests, and review results
                when they are ready. In the meantime, if you have any questions,
                please reach out to{" "}
                <a href="mailto:providers@evvy.com!">providers@evvy.com!</a>
              </p>

              <div className="mt-8 hidden sm:block">
                <img
                  className="h-32"
                  src="/images/graphics/triple-science-blue.png"
                  loading="lazy"
                  alt="Evvy Microbiome Test"
                />
              </div>

              <div className="mt-8">
                <Link
                  to="https://www.evvy.com/provider"
                  target="_blank"
                  className="uppercase underline inline-block font-semibold tracking-wider text-sm cursor-pointer"
                  onClick={() => {
                    sendProviderClickedJoinCommunity();
                  }}
                >
                  In the mean time, join our provider community →
                </Link>
              </div>
            </div>
          </div>

          <div className="border-t border-black border-dashed mt-20 py-10 flex" />
        </div>
      </div>
    </Layout>
  );
};
