import React from "react";
import { Link } from "react-router-dom";
import { Page, getHealthHistoryURL } from "../../utils/healthHistoryV2";
import classNames from "classnames";
import ProgressBar from "./progressBar";
import { ReactComponent as BackArrow } from "../common/icons/arrow-left.svg";

const HEALTH_CONTEXT_SECTION = "HEALTH_CONTEXT";
const HEALTH_PROFILE_SECTION = "HEALTH_PROFILE";
const TAKE_SAMPLE_SECTION = "TAKE_SAMPLE";
const INTRO_SECTION = "INTRO";

export const getHealthHistorySection = (section: string) => {
  if (
    INTRO_SECTIONS.includes(section) ||
    HEALTH_CONTEXT_SECTIONS.includes(section)
  ) {
    return "context";
  }
  if (HEALTH_PROFILE_SECTIONS.includes(section)) {
    return "profile";
  }
  if (TAKE_SAMPLE_SECTIONS.includes(section)) {
    return "sample";
  }
  if (DONE_SECTIONS.includes(section)) {
    return "done";
  }
};

export const INTRO_SECTIONS = ["What to Expect", "Address"];
export const HEALTH_CONTEXT_SECTIONS = [
  "Address",
  "Updates",
  "Symptoms",
  "Discharge",
  "Diagnoses",
  "Treatments",
  "Sexual Activity",
  "How we can help",
];
const TAKE_SAMPLE_SECTIONS = ["Sample"];
export const HEALTH_PROFILE_SECTIONS = [
  "Health Profile",
  "Demographics",
  "Lifestyle",
  "Related Diagnoses",
  "Infertility or subfertility",
  "Other Cancer",
  "Ovarian Cancer",
  "Cervical Cancer",
  "Endometrial Cancer",
  "Breast Cancer",
  "Diabetes",
  "HPV",
  "Cervical Intra-Epithelial Neoplasia",
  "Endometriosis",
  "Pregnancy",
  "Hormonal Health",
  "Quality of Life",
  "Why Evvy",
  "How did you hear about us?",
];
export const DONE_SECTIONS = ["What's next?"];

const Header = ({
  onClickBack,
  currentSection,
  previousPage,
  hash,
  prettyHash,
}: {
  onClickBack?: () => void;
  currentSection?: string;
  previousPage?: Page | null;
  hash?: string;
  prettyHash?: string;
}) => {
  const isPreviousPageHome = !previousPage || currentSection === "What's next?";
  const getSection = (section: string) => {
    if (HEALTH_CONTEXT_SECTIONS.includes(section)) {
      return HEALTH_CONTEXT_SECTION;
    } else if (HEALTH_PROFILE_SECTIONS.includes(section)) {
      return HEALTH_PROFILE_SECTION;
    } else if (TAKE_SAMPLE_SECTIONS.includes(section)) {
      return TAKE_SAMPLE_SECTION;
    } else if (INTRO_SECTIONS.includes(section)) {
      return INTRO_SECTION;
    }
  };

  const showNav = currentSection !== "What's next?";
  const currentHealthHistoryStep = currentSection && getSection(currentSection);
  const showTestHash =
    currentSection &&
    (INTRO_SECTIONS.includes(currentSection) ||
      HEALTH_PROFILE_SECTIONS.includes(currentSection));

  return (
    <React.Fragment>
      <nav
        className={`bg-evvy-cream border-b items-center border-evvy-black border-opacity-20 w-full top-0 px-6 md:px-20 pt-[40px] pb-[10px] sm:py-3 flex justify-between align-center z-20 pointer-events-none`}
      >
        <Link
          to={
            currentSection === "What to Expect" ||
            currentSection === "What's next?"
              ? `/tests/`
              : getHealthHistoryURL(hash, previousPage?.slug)
          }
          onClick={onClickBack ? onClickBack : undefined}
        >
          <button
            className={`p-2 text-evvy-black group flex items-center pointer-events-auto`}
            aria-label="Back to home"
          >
            {currentSection === "What to Expect" ||
            currentSection === "What's next?" ? (
              <span className="px-2 h-8 flex items-center inline uppercase tracking-wider font-semibold">
                <span className="w-[30px] h-[30px] flex justify-center items-center rounded-full border-2 border-evvy-black">
                  <BackArrow />
                </span>
                <div className="ml-2 p-0">Exit test</div>
              </span>
            ) : (
              <span className="uppercase text-center">
                <span className="w-[30px] h-[30px] flex justify-center items-center rounded-full border-2 border-evvy-black">
                  <BackArrow />
                </span>
              </span>
            )}
          </button>
        </Link>

        {/* Nav for desktop */}
        {showNav ? (
          <div
            className={classNames(
              `mx-auto t5 w-full hidden sm:flex  items-center`,
              { "max-w-4xl": showTestHash, "max-w-3xl": !showTestHash }
            )}
          >
            {showTestHash && (
              <div className="b2 semibold hidden md:flex">
                Test ID {prettyHash}
              </div>
            )}
            <div className="flex-1 flex items-center justify-center color-evvy-black p-2 t5 text-cente tracking-wider pointer-events-none font-semibold border-t-4 border-transparent">
              <div
                className={`border rounded-full text-center mr-2 ${
                  currentHealthHistoryStep === "HEALTH_CONTEXT"
                    ? "border-crazy-purple bg-crazy-purple text-white"
                    : "border-black"
                }`}
              >
                <div className="w-8 h-8 leading-6 flex items-center justify-center t5 semibold rounded-full">
                  1
                </div>
              </div>
              <div className="t5">HEALTH CONTEXT</div>
            </div>
            <div className="border-evvy-black border-b flex-1 flex items-center justify-center color-evvy-black p-2 t5 text-center tracking-wider pointer-events-none font-semibold border-t-4 border-transparent">
              <div
                className={`border rounded-full text-center mr-2 ${
                  currentHealthHistoryStep === "TAKE_SAMPLE"
                    ? "border-coral bg-coral text-white"
                    : "border-black"
                }`}
              >
                <div className="w-8 h-8 leading-6 flex items-center justify-center t5 semibold rounded-full">
                  2
                </div>
              </div>
              <div>TAKE YOUR SAMPLE</div>
            </div>
            <div className="flex-1 flex items-center justify-center color-evvy-black p-2 t5 text-center tracking-wider pointer-events-none font-semibold border-t-4 border-transparent">
              <div
                className={`border rounded-full text-center mr-2 ${
                  currentHealthHistoryStep === "HEALTH_PROFILE"
                    ? "border-dv-pond bg-dv-pond text-white"
                    : "border-black"
                }`}
              >
                <div className="w-8 h-8 leading-6 flex items-center justify-center t5 semibold rounded-full">
                  3
                </div>
              </div>
              <div>HEALTH PROFILE</div>
            </div>
          </div>
        ) : null}
        <Link to="/tests" className="pointer-events-auto">
          <img
            className="h-8 mt-2 sm:h-10"
            src="/images/evvy-logo-text.svg"
            loading="lazy"
            alt="Evvy Text Logo"
          />
        </Link>
      </nav>

      {/* nav for mobile */}
      {showNav && (
        <div
          className={classNames(
            "sm:hidden flex justify-center py-4 px-2 t5 font-semibold tracking-wider border-evvy-black border-b border-opacity-20"
          )}
        >
          {currentSection === "What to Expect" && (
            <div className="b2 semibold justify-center">
              Test ID {prettyHash}
            </div>
          )}
          <div
            className={`flex items-center mx-auto ${
              currentHealthHistoryStep === "HEALTH_CONTEXT" ? "block" : "hidden"
            }`}
          >
            <div className="border rounded-full border-crazy-purple bg-crazy-purple text-white text-center mr-2">
              <div className="w-6 h-6 block leading-6">1</div>
            </div>
            <div>HEALTH CONTEXT</div>
          </div>
          <div
            className={`flex items-center mx-auto ${
              currentHealthHistoryStep === "TAKE_SAMPLE" ? "block" : "hidden"
            }`}
          >
            <div className="border rounded-full border-coral bg-coral text-white text-center mr-2">
              <div className="w-6 h-6 block leading-6">2</div>
            </div>
            <div>TAKE YOUR SAMPLE</div>
          </div>
          <div
            className={`flex items-center mx-auto ${
              currentHealthHistoryStep === "HEALTH_PROFILE" ? "block" : "hidden"
            }`}
          >
            <div className="border rounded-full border-marine bg-marine text-white text-center mr-2">
              <div className="w-6 h-6 block leading-6">3</div>
            </div>
            <div>HEALTH PROFILE</div>
          </div>
        </div>
      )}
      <ProgressBar currentSection={currentSection} />
    </React.Fragment>
  );
};

export default Header;
