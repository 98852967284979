import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer from "../footer";

// layout to be used for welcome views (like verify email)
// very similar to the auth layout
const LayoutWelcome = ({
  children,
  title,
  metaTitle,
  subtitle,
  contentWidthClass = "",
  smallTopMargin,
}) => (
  <div className="min-h-screen bg-white flex flex-col">
    {metaTitle ? (
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
    ) : (
      ""
    )}
    <div className="bg-evvy-cream flex py-5">
      <div className="mx-6 sm:mx-auto">
        <img
          className="h-8 sm:h-10"
          src="/images/evvy-logo-text.svg"
          loading="lazy"
          alt="Evvy Text Logo"
        />
      </div>
    </div>
    {title ? (
      <div
        className={`sm:mx-auto px-6 sm:px-0 sm:w-full ${
          contentWidthClass ? contentWidthClass : "sm:max-w-lg"
        } ${smallTopMargin ? "" : "mt-12"}`}
      >
        <h3 className="text-4xl sm:text-5xl mt-6 sm:text-center">
          {title}
          {subtitle ? (
            <React.Fragment>
              <br />
              {subtitle}
            </React.Fragment>
          ) : (
            ""
          )}
        </h3>
      </div>
    ) : (
      ""
    )}
    <div
      className={`${
        smallTopMargin ? "mt-[50px]" : "mt-8"
      } px-6 sm:mx-auto sm:w-full sm:px-0 ${
        contentWidthClass ? contentWidthClass : "sm:max-w-lg"
      } pb-8 flex-grow`}
    >
      <div className="bg-white">{children}</div>
    </div>

    <Footer />
  </div>
);

export default LayoutWelcome;
