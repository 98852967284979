import React from "react";
import Markdown from "markdown-to-jsx";
import { CircleCheckmark } from "../../../../components/common/circleCheckmark";

const INTRO_COPY =
  "Please confirm the following are true before taking your sample:";
const BULLETS = [
  "You’re not currently on your period or spotting",
  "You haven’t had sex in the past 24 hours",
  "You haven’t used any vulvar topical creams or gels for 24 hours (e.g. HRT, steriod cream)",
  "You haven’t used any vaginally-inserted treatments in the last 48 hours (e.g. suppositories, creams, gels, etc.)",
  "You haven't used antibiotics in the last 7 days",
];

const LDT_BULLETS = [
  "High fever (>101.9F/38.8C)",
  "Shortness of breath, difficulty breathing, chest pain, or heart palpitations",
  "Abnormal bruising or bleeding",
  "Extreme fatigue, dizziness, new weakness or paralysis, or pain",
  "Difficulty with speech or confusion",
  "Suicidal thoughts",
  "Inability to remain hydrated or keep down fluid",
];

const LDT_DISCLAIMER =
  "You are not currently experiencing any of the following:";

const TEST_LATER_COPY_MD =
  "If you're experiencing any of the above, now is not a good time to take your sample. Please come back when all of the above statements are true for you!";

const Verify = () => {
  return (
    <div className="p-2">
      <div className="mx-auto">
        <p className="b1 semibold mb-0">{INTRO_COPY}</p>
        <br />
        <br />
        <ul className="list-none ml-0 flex-1 b2 medium">
          {BULLETS.map((bullet, i) => (
            <li key={i} className="flex mb-8">
              <span className="mt-0.5 mr-4">
                <CircleCheckmark
                  circleClassName="fill-highlighter-mint"
                  checkmarkClassName="stroke-evvy-black"
                />
              </span>
              {bullet}
            </li>
          ))}
          <li className="flex items-center">
            <span className="mt-0.5 mr-4">
              <CircleCheckmark
                circleClassName="fill-highlighter-mint"
                checkmarkClassName="stroke-evvy-black"
              />
            </span>
            {LDT_DISCLAIMER}
          </li>
          <ul className="list-none ml-12 flex-1 font-normal">
            {LDT_BULLETS.map((bullet, i) => (
              <li key={i} className="flex mb-4">
                • {bullet}
              </li>
            ))}
            <p>
              <i>
                If you are experiencing any of these, please seek immediate
                medical attention or call 911.
              </i>
            </p>
          </ul>
        </ul>
        <div className="my-8 bg-evvy-black opacity-20 h-px w-full" />
        <div className="text-left">
          <p className="b2 semibold">{TEST_LATER_COPY_MD}</p>
        </div>
      </div>
    </div>
  );
};

export default Verify;
