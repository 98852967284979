import React from "react";

const PatientMessage = ({ message, prevMessage }) => {
  const prevDate = prevMessage
    ? new Date(Date.parse(prevMessage.created_at)).toLocaleDateString("en-US")
    : "";
  const currDate = message
    ? new Date(Date.parse(message.created_at)).toLocaleDateString("en-US")
    : "";
  const dateToShow =
    currDate && !prevDate
      ? currDate
      : currDate && prevDate && currDate !== prevDate
      ? currDate
      : "";

  return (
    <React.Fragment>
      {dateToShow && (
        <div className="b3 regular text-gray-500 pt-8">{dateToShow}</div>
      )}
      <div className="flex justify-end my-6 py-3" key={message?.id}>
        <div className="w-10/12 sm:w-3/5 relative">
          <div className="p-7 w-full bg-evvy-silverfish rounded-xl text-left b1 whitespace-pre-wrap">
            {message.text}
          </div>
          <div className="mx-auto b3 absolute right-0 pt-1 text-gray-500">
            {new Date(Date.parse(message.created_at)).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PatientMessage;
