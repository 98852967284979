import React, { useEffect, useState } from "react";
import {
  Question,
  getRelatedDiagnosisMap,
} from "../../../utils/healthHistoryV2";
import {
  HealthContextFields,
  HealthContextRelatedDiagnosesFields,
} from "../../../types/test";
import QuestionTitle from "./questionTitle";
import { Field } from "formik";
import {
  indexCheckboxStyles,
  multiselectFollowupRadioStyles,
} from "../../../utils/colors";
import { UNSELECTED_OPTION } from "../../../utils/healthHistoryV2";
import { getQuestionTitle } from "../../../utils/questions";
import { uncheckNoneChangeHandler } from "../../../utils/answerUtils";
import classNames from "classnames";

// Validation and formatting of multidimensional questions happens in healthHistoryV2.ts in handle
// For a question to be valid, it must either have all options set to the default value (representing "None" being selected),
// OR all currently checked options must be set to a non-default value
// On submitting the form, all empty values are set to the default value to be stored in the database

const MultidimensionalQuestion = ({
  question,
  healthContext,
  handleChange,
  setFieldValue,
  setCurrentCheckedOptionsForPage,
  values,
}: {
  question: Question;
  healthContext: HealthContextFields;
  handleChange: () => void;
  setFieldValue: () => void;
  setCurrentCheckedOptionsForPage: any;
  values: any;
}) => {
  const options = question.options;
  const currentOptions = question.getFollowupOptions?.({
    healthContextValues: healthContext,
  });
  const isMultiselectFollowupQuestion =
    question.optionAnswerType === "multiselect";
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<
    string[]
  >([]);

  // None option is selected if all other values have the question default selected
  const [noneOptionSelected, setNoneOptionSelected] = useState<boolean>(false);

  // The question default (populated if the user does not select the checkbox)
  const defaultUnselectedOption = question
    ? UNSELECTED_OPTION[question.slug]
    : "";

  // Followup options that render below the checkbox:
  // e.g. prescription_treatments -> ["not-prescribed", "vaginal-symptom", "urinary-symptom", "other"]
  // ["vaginal-symptom", "urinary-symptom", "other"] are followup options, ["not-prescribed"] is the default
  const followupOptions = currentOptions?.filter(
    (option) => option[1] !== defaultUnselectedOption
  );

  // This use effect runs everytime the question changes
  useEffect(() => {
    const initialValue = question.getInitialValue({
      healthContextValues: healthContext,
    });

    let relatedDiagnosis: HealthContextRelatedDiagnosesFields | undefined;
    if (question.isRelatedDiagnosisFollowup) {
      // get condition for related diagnosis multidimensionals
      // "infertility_treatments" -> "infertility"
      const relatedDiagnoses = healthContext.healthcontextrelateddiagnoses_set;
      const relatedDiagnosisMap = getRelatedDiagnosisMap(relatedDiagnoses);
      relatedDiagnosis = relatedDiagnosisMap[question.slug.split("_")[0]];
    }

    // On first render, make sure to check none option if all options are "default"
    const allValuesDefault = options?.every((option) => {
      if (isMultiselectFollowupQuestion) {
        // Main one is the diagnoses question, which is stored as multiselect on the backend
        // (e.g. diagnoses_bv, diagnoses_candida, etc.)
        return initialValue[option as keyof HealthContextFields]?.includes(
          defaultUnselectedOption
        );
      } else if (relatedDiagnosis) {
        return (
          relatedDiagnosis?.[
            option as keyof HealthContextRelatedDiagnosesFields
          ] === defaultUnselectedOption
        );
      } else {
        // For singleselect multidimensional questions:
        // (e.g. symptoms_additional_dryness, symptoms_additional_excessive_discharge)
        return (
          initialValue[option as keyof HealthContextFields] ===
          defaultUnselectedOption
        );
      }
    });

    if (allValuesDefault) {
      setNoneOptionSelected(true);
    } else {
      setNoneOptionSelected(false);
    }
  }, [question]);

  // This runs everytime values stored on frontend change (e.g. user checks/unchecks)
  useEffect(() => {
    // "selected" options are ones that don't equal the "default" value
    const selected = Object.keys(values).filter((field) => {
      const value = values[field as keyof HealthContextFields];
      if (Array.isArray(value)) {
        return value.length !== 0 && !value.includes(defaultUnselectedOption);
      } else {
        return value && value !== defaultUnselectedOption;
      }
    });
    setCurrentSelectedOptions(
      Array.from(new Set([...selected, ...currentSelectedOptions]))
    );
  }, [values]);

  // Clear default values if option not selected
  const setCurrentOptionEmptyAndAllOtherDefaultValuesToEmpty = (
    currentOption: string,
    setFieldValue: (fieldName: string, defaultAnswer: string | string[]) => void
  ) => {
    if (options) {
      for (const option of options) {
        if (
          isMultiselectFollowupQuestion &&
          values[option]?.includes(defaultUnselectedOption)
        ) {
          setFieldValue(option, []);
        } else if (values[option] === defaultUnselectedOption) {
          setFieldValue(option, "");
        }
      }
    }
    // Set current option to empty
    setFieldValue(currentOption, isMultiselectFollowupQuestion ? [] : "");
  };

  // Clear all options regardless of current value
  const setAllValuesToEmpty = (
    setFieldValue: (fieldName: string, defaultAnswer: string | string[]) => void
  ) => {
    if (options) {
      for (const option of options) {
        if (isMultiselectFollowupQuestion) {
          setFieldValue(option, []);
        } else {
          setFieldValue(option, "");
        }
      }
    }
  };

  const handleCheckboxChange =
    ({
      handleChange,
      setFieldValue,
      option,
    }: {
      handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
      setFieldValue: (
        fieldName: string,
        defaultAnswer: string | string[]
      ) => void;
      option: string;
    }) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e);
      // Handling checked -> unchecked
      if (!e.target.checked) {
        if (option?.includes("none")) {
          // deselect none checkbox
          setNoneOptionSelected(false);
          // set all other to empty
          setAllValuesToEmpty(setFieldValue);
        } else {
          // set values to empty if currently populated with default value
          setCurrentOptionEmptyAndAllOtherDefaultValuesToEmpty(
            option,
            setFieldValue
          );
          setCurrentSelectedOptions(
            currentSelectedOptions.filter((value) => value !== option)
          );
          setCurrentCheckedOptionsForPage(
            currentSelectedOptions.filter((value) => value !== option)
          );
        }
      } else if (e.target.checked) {
        if (option?.includes("none")) {
          // select "none" checkbox
          setNoneOptionSelected(true);
          // set all other values to the default value
          if (options) {
            for (const option of options) {
              if (isMultiselectFollowupQuestion) {
                setFieldValue(option, [defaultUnselectedOption]);
              } else {
                setFieldValue(option, defaultUnselectedOption);
              }
            }
          }
          setCurrentSelectedOptions([]);
          setCurrentCheckedOptionsForPage([]);
        } else {
          // make sure the none option is not checked
          setNoneOptionSelected(false);
          // set values to empty if current populated with default value
          setCurrentOptionEmptyAndAllOtherDefaultValuesToEmpty(
            option,
            setFieldValue
          );
          setCurrentSelectedOptions([...currentSelectedOptions, option]);
          setCurrentCheckedOptionsForPage([...currentSelectedOptions, option]);
        }
      }
    };

  return (
    <>
      <QuestionTitle questionTitle={question.slug} />
      <label className="mb-[10px] cursor-pointer flex items-center">
        <Field
          type="checkbox"
          name={"none"}
          value={noneOptionSelected}
          className={indexCheckboxStyles(0)}
          onChange={handleCheckboxChange({
            handleChange,
            setFieldValue,
            option: "none",
          })}
          checked={noneOptionSelected}
        />
        <span className="ml-2 leading-5">
          {getQuestionTitle(
            question.slug === "infertility_treatments"
              ? "fertility_treatment_none"
              : "option_none"
          )}
        </span>
      </label>
      <div>
        {followupOptions &&
          options?.map((option, index) => (
            <div key={option}>
              <label className="mb-[10px] cursor-pointer flex items-center">
                <Field
                  type="checkbox"
                  name={option}
                  value={values[option as keyof HealthContextFields]}
                  className={indexCheckboxStyles(index)}
                  onChange={handleCheckboxChange({
                    handleChange,
                    setFieldValue,
                    option,
                  })}
                  checked={currentSelectedOptions?.includes(option)}
                />
                <span className="ml-2 leading-5">
                  {getQuestionTitle(option)}
                </span>
              </label>
              {/* Only render the follow-up options when option is selected */}
              {currentSelectedOptions?.includes(option) && (
                <div
                  className={classNames("flex px-8 pb-2", {
                    "flex-col":
                      question.answerType === "multiselect_dimensional",
                    "flex-row":
                      question.answerType === "multiselect_dimensional_short",
                  })}
                >
                  {followupOptions
                    ?.filter(
                      (followupOption) =>
                        followupOption[0] !== defaultUnselectedOption &&
                        !option?.includes("_other")
                    )
                    .map((followupOption, index) => (
                      <label
                        className={classNames(
                          "mb-[10px] w-auto text-left flex justify-center cursor-pointer p-3 rounded-xl mr-2",
                          {
                            "bg-evvy-blue-light": isMultiselectFollowupQuestion
                              ? values[option]?.includes(followupOption[0])
                              : values[option] === followupOption[0],
                            "border-evvy-blue border-2":
                              isMultiselectFollowupQuestion
                                ? values[option]?.includes(followupOption[0])
                                : values[option] === followupOption[0],
                            "border-evvy-grey border hover:bg-evvy-blue-light hover:border-evvy-blue hover:border":
                              isMultiselectFollowupQuestion
                                ? !values[option]?.includes(followupOption[0])
                                : values[option] !== followupOption[0],
                            "sm:w-[393px]":
                              question.answerType === "multiselect_dimensional",
                            "w-full text-center":
                              question.answerType ===
                              "multiselect_dimensional_short",
                          }
                        )}
                        key={followupOption[0]}
                      >
                        <Field
                          type={"radio"}
                          id={`${option}-${followupOption[0]}`}
                          name={option}
                          value={followupOption[0]}
                          className={multiselectFollowupRadioStyles(
                            index,
                            question.answerType ===
                              "multiselect_dimensional_short"
                          )}
                          onChange={uncheckNoneChangeHandler({
                            handleChange,
                            setFieldValue,
                            fieldName: question.slug,
                            fieldValues: values[question.slug],
                          })}
                          checked={
                            isMultiselectFollowupQuestion
                              ? values[option]?.includes(followupOption[0])
                              : values[option] === followupOption[0]
                          }
                        />
                        <label
                          className={classNames("cursor-pointer w-full", {
                            "pl-2":
                              question.answerType === "multiselect_dimensional",
                          })}
                          htmlFor={`${option}-${followupOption[0]}`}
                        >
                          {followupOption[1]}
                        </label>
                      </label>
                    ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

export default MultidimensionalQuestion;
