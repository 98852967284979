import React from "react";
import CloseX from "../plan/closeX";

type CompareTypeModalProps = {
  handleClose: () => void;
  type?: string;
  typeData?: string[];
};

const CompareTypeModal: React.FC<CompareTypeModalProps> = ({
  handleClose,
  type,
  typeData,
}) => {
  if (!typeData) return null;
  return (
    <div className="content">
      <div className="p-6 bg-evvy-white flex">
        <div className="t1 w-4/5">{`Type ${type}`}</div>
        <div className="flex-col space-around">
          <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
        </div>
      </div>
      <div className="p-8 bg-dv-pond text-evvy-white space-y-6">
        <ul>
          {typeData?.map((d, i) => (
            <li key={i}>{d}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(CompareTypeModal);
