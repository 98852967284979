import React from "react";
import ResearchToolip from "./researchTooltip";
import ResearchImage from "./researchImage";
import LinkedText from "../common/linkedText";
import Circle from "./circle";
import { titleSplit, splitDescription, replaceDescription } from "./utils";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";

const YouShouldKnow = ({ item, color, openModalWith }) => {
  const {
    title,
    description,
    section,
    research_rating,
    description_cta,
    planitemcitation_set,
    slug,
  } = item;

  return (
    <div className="rounded-xl bg-evvy-white w-96 h-auto m-4 md:my-8 flex flex-col text-left">
      <div className="t1 p-8 max-w-1 border border-evvy-cream border-collapse flex">
        <Circle textColorClass={`text-${color}`} />
        <div className="t1 w-3/5">
          {titleSplit(title, "Since you told us", `text-${color}`)}
        </div>
      </div>
      <div className="p-8 h-full border border-evvy-cream border-collapse">
        <div className="content line-clamp-6">
          {replaceDescription(description)}
        </div>
        <LinkedText
          onClick={analyticsClickHandler({
            eventName: eventNames.CLICKED_LEARN_MORE,
            eventArgs: { slug, groupName: null, planVersion: "v1" },
            clickHandler: () =>
              openModalWith({
                type: "_learnmore",
                data: {
                  section,
                  title,
                  splitDescription: splitDescription(description),
                  description_cta,
                  planitemcitation_set,
                  color,
                  slug,
                },
              }),
          })}
        >
          Learn More
        </LinkedText>
      </div>
      <div className="t4 p-8 h-auto border border-evvy-cream border-collapse">
        <ResearchToolip
          openModalWith={openModalWith}
          eventArgs={{
            slug,
            groupName: null,
            researchStatus: research_rating?.title,
            planVersion: "v1",
          }}
        />
        <ResearchImage status={research_rating.title} />
      </div>
    </div>
  );
};

export default YouShouldKnow;
