import { HdyhauSurvey, HealthContextFields } from "../../../types/test";
import { Question } from "../../../utils/healthHistoryV2";
import MultidimensionalQuestion from "./multidimensionalQuestion";
import SingleQuestion from "./singleQuestion";
import TextFieldQuestion from "./textField";
import RelatedDiagnosesQuestion from "./relatedDiagnosesYearDropdown";
import NumberScale from "./numberScaleQuestion";
import HeightQuestion from "./heightQuestion";
import DropdownQuestion from "./dropdownQuestion";
import LoadingSpinner from "../../common/loadingSpinner";

const QuestionWrapper = ({
  question,
  values,
  healthContext,
  setFieldValue,
  handleChange,
  setSelectedRelatedDiagnoses,
  setCurrentCheckedOptionsForPage,
  hdyhauSurvey,
}: {
  question: Question;
  values: any;
  healthContext: HealthContextFields;
  setFieldValue: any;
  handleChange: any;
  setSelectedRelatedDiagnoses?: any;
  setCurrentCheckedOptionsForPage?: any;
  hdyhauSurvey?: HdyhauSurvey;
}) => {
  // Sometimes health context is not loaded on first render, so don't render the question yet
  if (!healthContext) {
    return <LoadingSpinner />;
  }
  switch (question.answerType) {
    case "multiselect_dimensional":
      return (
        <MultidimensionalQuestion
          question={question}
          healthContext={healthContext}
          setFieldValue={setFieldValue}
          setCurrentCheckedOptionsForPage={setCurrentCheckedOptionsForPage}
          handleChange={handleChange}
          values={values}
        />
      );
    case "multiselect_dimensional_short":
      return (
        <MultidimensionalQuestion
          question={question}
          healthContext={healthContext}
          setFieldValue={setFieldValue}
          setCurrentCheckedOptionsForPage={setCurrentCheckedOptionsForPage}
          handleChange={handleChange}
          values={values}
        />
      );
    case "multiselect_dimensional_dropdown":
      return (
        <RelatedDiagnosesQuestion
          question={question}
          healthContext={healthContext}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          values={values}
          setCurrentCheckedOptionsForPage={setCurrentCheckedOptionsForPage}
          setSelectedRelatedDiagnoses={setSelectedRelatedDiagnoses}
        />
      );
    case "singleselect":
      return (
        <SingleQuestion
          question={question}
          healthContext={healthContext}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          values={values}
          hdyhauSurvey={hdyhauSurvey}
        />
      );
    case "multiselect":
      return (
        <SingleQuestion
          question={question}
          healthContext={healthContext}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          values={values}
        />
      );
    case "date":
      return (
        <TextFieldQuestion
          question={question}
          currentValue={values[question.slug]}
        />
      );
    case "multitext":
      return (
        <TextFieldQuestion
          question={question}
          currentValue={values[question.slug]}
        />
      );
    case "singletext":
      return (
        <TextFieldQuestion
          question={question}
          currentValue={values[question.slug]}
        />
      );
    case "number":
      return (
        <TextFieldQuestion
          question={question}
          currentValue={values[question.slug]}
        />
      );
    case "dropdown":
      return (
        <DropdownQuestion
          question={question}
          values={values}
          healthContext={healthContext}
        />
      );
    case "height":
      return <HeightQuestion />;
    case "number_scale":
      return <NumberScale questionTitle={question.slug} />;
    default:
      return <div>default</div>;
  }
};

export default QuestionWrapper;
