import React from "react";
import Markdown from "markdown-to-jsx";

const UnorderedListItem = ({ text, noBorder, capitalize = false }) => {
  return (
    <div role="listitem">
      <div className="flex items-start text-left">
        {/* dot - hidden from screen readers */}
        <div
          className={`dot mt-2 rounded-full bg-[#C9C2BD] flex-shrink-0`}
          aria-hidden="true"
        />
        {/* list text */}
        <div className={`ml-4 b1 regular`}>
          {capitalize && text.length ? (
            <>
              <span className="uppercase">{text[0]}</span>
              <span>{text.slice(1)}</span>
            </>
          ) : (
            <Markdown>{text}</Markdown>
          )}
        </div>
      </div>
      {/* dashed line  - hidden from screenreaders */}
      {!noBorder && (
        <div
          className="border-evvy-black border-opacity-30 border-b border-dashed w-full pt-4"
          aria-hidden="true"
        />
      )}
    </div>
  );
};

export default UnorderedListItem;
