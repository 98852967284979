import { Link } from "react-router-dom";
import { cn } from "../../utils/cn";

export const BackButton: React.FC<{
  backLink?: string;
  backText?: string;
  onClickBack?: () => void;
  showLabelWithoutHover?: boolean;
}> = ({
  backLink = "/tests/",
  backText = "",
  onClickBack,
  showLabelWithoutHover = false,
}) => {
  return (
    <Link to={backLink} onClick={onClickBack}>
      <button
        className="p-2 rounded-full border-2 border-evvy-black text-evvy-black group flex items-center pointer-events-auto"
        aria-label="Back"
      >
        {!showLabelWithoutHover && (
          <span className="group-hover:hidden w-7 h-7 text-center">
            <h4 className="font-bold leading-6">←</h4>
          </span>
        )}
        {/* add text to the right using backText */}
        {backText && (
          <span
            className={cn("px-2 h-7 tracking-wider font-semibold", {
              inline: showLabelWithoutHover,
              "hidden group-hover:inline": !showLabelWithoutHover,
            })}
          >
            <h4 className="font-bold leading-6 inline-block">←</h4>
            <span className="pl-2">{backText}</span>
          </span>
        )}
      </button>
    </Link>
  );
};
