import { createContext } from "react";
import { Cart } from "../types/cart";
import { Test } from "../types/test";

export type CartContextType = {
  cart?: Cart;
  setCart: (cart: Cart) => void;
  showCart: boolean;
  setShowCart: (showCart: boolean) => void;
  latestReadyTest?: Test;
  loading: boolean;
  error?: string | null;
  refetchCartAndTest: () => void;
};

export const CartContext = createContext<CartContextType>({
  setCart: () => {},
  showCart: false,
  setShowCart: () => {},
  loading: false,
  error: null,
  refetchCartAndTest: () => {},
});
