import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { sendViewedHealthHistorySection } from "../../../../utils/analytics/customEventTracking";
import { loadSurvicate } from "../../../../utils/analytics/helpers";
import { Test } from "../../../../types/test";
import { useLoggedInUser } from "../../../../hooks/useLoggedInUser";
import Markdown from "markdown-to-jsx";

const Done = ({ test }: { test: Test }) => {
  // route params
  const { testHash: hash } = useParams();
  const currentUser = useLoggedInUser();

  // effects
  useEffect(() => {
    sendViewedHealthHistorySection({
      section: "done",
      sectionStatus: "blank",
      testOrder: test.test_order,
      testHash: hash,
      version: 2,
    });
  }, []);

  useEffect(() => {
    if (test && currentUser) {
      return loadSurvicate(currentUser, {
        hasExpan: test.has_vpcr_test,
        hasUpsellEnabled: test.expan_upsell_enabled,
      });
    }
  }, [currentUser, test]);

  // actions
  const BULLETS = [
    "📫 Make sure to ship your sample to our lab according to the instructions guide in your box",
    "🔬 You’ll get an email once your results are ready (approximately\n2-3 weeks after they arrive at our lab) ",
  ];

  const LEARN_MORE_BULLET = `💙 Learn more about the science <u>[on our blog.](https://www.evvy.com/blog/)</u>`;

  const LDT_BULLET_2 = test.has_vpcr_test
    ? "🔬 You'll get an email once your initial results are ready (3-5 business days after your sample arrives at the lab), and once your full results are ready (7-10 business days after your sample arrives at the lab)"
    : "🔬 You’ll get an email once your results are ready (approximately\n7-10 business days after they arrive at our lab) ";

  return (
    <>
      <div className="bg-evvy-white pl-8 pr-8 pt-8">
        <p>{BULLETS[0]}</p>
        <p>{LDT_BULLET_2}</p>

        <div className="mt-8 pb-8">
          <Markdown>{LEARN_MORE_BULLET}</Markdown>
        </div>
      </div>
      <img
        className="w-full rounded-b-lg"
        src="/images/graphics/triple-science-blue.png"
        loading="lazy"
        alt="Evvy Microbiome Test"
      />
    </>
  );
};

export default Done;
