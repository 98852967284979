import { TRANSPARENT_BUNDLE_FREEBIES } from "../constants";

const BundleFreebies = () => (
  <div className="flex flex-wrap gap-y-4  sm:gap-x-4">
    {TRANSPARENT_BUNDLE_FREEBIES.map((item, index) => (
      <div
        key={index}
        className="text-[12px] font-medium flex flex-row items-center max-w-[238px]"
      >
        <img
          className="w-[90px] h-[90px] rounded-lg flex-shrink-0"
          loading="lazy"
          src={item.imgSrc}
          alt={item.altText}
        />
        <div className="ml-4">
          <span className="hidden md:block">{item.desktopText}</span>
          <span className="block md:hidden">{item.mobileText}</span>
          <div className="opacity-50">
            Free
            <span className="ml-1 line-through">{item.price}</span>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default BundleFreebies;
