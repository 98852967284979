import { useEffect, useRef, useState } from "react";
// local imports
import { breakpoints } from "./viz";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function useResponsiveSize(
  widthAccessor,
  startingWidth,
  heightAccessor = null,
  startingHeight
) {
  // sets window dimensions on resize
  const [[width, height, isMobile], setWindowDimensions] = useState([
    startingWidth,
    startingHeight
      ? startingHeight
      : heightAccessor
      ? heightAccessor
      : window.document.documentElement.clientHeight,
    startingWidth < breakpoints.md,
  ]);
  useEffect(() => {
    if (widthAccessor) {
      const setDimensions = () => {
        const isMobile = widthAccessor < breakpoints.md;
        setWindowDimensions([
          widthAccessor,
          heightAccessor
            ? heightAccessor
            : window.document.documentElement.clientHeight,
          isMobile,
        ]);
      };
      setDimensions();
      window.addEventListener("resize", setDimensions);
      return () => window.removeEventListener("resize", setDimensions);
    }
  }, [widthAccessor, heightAccessor]);

  return [[width, height, isMobile]];
}

function useResponsiveMargin(el, heightAccessor = null, startingHeight) {
  // sets window dimensions on resize
  const [margin, setWindowDimensions] = useState(startingHeight);
  useEffect(() => {
    if (el) {
      const setMargin = () => {
        let m = null;
        if (window.innerWidth < breakpoints.md) {
          m = heightAccessor ? heightAccessor : el.offsetHeight;
        }
        setWindowDimensions(m);
      };
      setMargin();
      window.addEventListener("resize", setMargin);
      return () => window.removeEventListener("resize", setMargin);
    }
  }, [el]);

  return margin;
}

export { usePrevious, useResponsiveSize, useResponsiveMargin };
