import React from "react";
import { Consult, Prescription, PrescriptionRecord } from "../../../types/care";
import { ReactComponent as CircleX } from "../../../components/care/icons/circle-x.svg";
import { VAGINAL_TEST_PRICE } from "../../constants";
import { sendClickedRemoveRefillFromCart } from "../../../utils/analytics/customEventTracking";
import CheckoutButton from "../../../components/care/CheckoutButton";

const OrderDetailsCard = ({
  lineItems,
  setLineItems,
  totalPrice,
  consult,
  prescriptionSlugs,
}: {
  lineItems: { prescriptions: PrescriptionRecord[]; test: boolean };
  setLineItems: (items: {
    prescriptions: PrescriptionRecord[];
    test: boolean;
  }) => void;
  totalPrice: number;
  consult?: Consult;
  prescriptionSlugs?: Prescription["slug"][];
}) => {
  // TODO: Add error state for when fetching checkout URL fails
  const [createCheckoutLinkError, setCreateCheckoutLinkError] = React.useState<
    string | null
  >(null);

  const removeFromCart = (
    prescription?: PrescriptionRecord,
    test?: boolean
  ) => {
    sendClickedRemoveRefillFromCart({
      consultId: consult?.uid,
      prescriptionSlug: prescription?.product.slug,
    });
    if (test) {
      setLineItems({
        prescriptions: lineItems.prescriptions,
        test: false,
      });
    } else {
      setLineItems({
        prescriptions: lineItems.prescriptions?.filter(
          (item) => item !== prescription
        ),
        test: lineItems.test,
      });
    }
  };

  return (
    <div className="bg-white p-7 rounded-2xl lg:max-w-[320px]">
      <p className="t4">Order details</p>
      {lineItems.prescriptions.length === 0 && !lineItems.test && (
        <p className="t3">Nothing here yet!</p>
      )}
      {lineItems.test && (
        <div className="flex justify-between items-center relative my-6">
          <div className="b1 pr-2">Evvy Test</div>
          <div className="leading-[1.714rem] mr-2 ml-auto">
            ${VAGINAL_TEST_PRICE}
          </div>
          <CircleX
            onClick={() => removeFromCart(undefined, true)}
            className="h-6 w-6 cursor-pointer flex-shrink-0"
          />
        </div>
      )}
      {lineItems?.prescriptions?.map((item: PrescriptionRecord) => (
        <div
          key={item?.uid}
          className="flex justify-between items-center relative my-6"
        >
          <div className="b1 pr-2">{item.product.title_short_display}</div>
          <div className="leading-[1.714rem] mr-2 ml-auto">
            ${item.product.ecomm_product?.price}
          </div>
          <CircleX
            onClick={() => removeFromCart(item)}
            className="h-6 w-6 cursor-pointer flex-shrink-0"
          />
        </div>
      ))}

      <div className="mb-4 w-full px-2 bg-evvy-black opacity-20 h-px mt-4" />
      <p className="t4">Subtotal: ${totalPrice}</p>
      <p>Shipping & taxes calculated at checkout</p>
      <CheckoutButton
        lineItems={lineItems}
        consultId={consult?.uid}
        prescriptionSlugs={prescriptionSlugs}
        setError={setCreateCheckoutLinkError}
      />
    </div>
  );
};

export default OrderDetailsCard;
