/**
 * A link styled with an icon (goes in help module)
 * @param {string} href an external url
 * @param {jsx} icon an svg imported as a react component
 * @param {string} color this should be a string for a color, such as `evvy-black`, since in tailwind, the stroke of the border is referenced with `currentColor` which is set via a border class.
 * @param {string} text a string to label button
 * @returns jsx of a button
 */

import React from "react";

const OutlinedButton = ({
  href = undefined,
  icon,
  text,
  sameTab = false,
  color = "evvy-black",
  onClick = undefined,
}: {
  href?: string;
  icon: JSX.Element;
  text: string;
  sameTab?: boolean;
  color?: string;
  onClick?: () => void;
}) => {
  return (
    <a
      href={href}
      target={sameTab ? "_self" : "_blank"}
      onClick={onClick}
      className={`w-full flex justify-start cursor-pointer items-center space-x-3 p-3 rounded-md border border-${color}/25 t4 text-${color}`}
      rel="noreferrer"
    >
      <div style={{ width: "20px", height: "20px" }}>{icon}</div>
      <div>{text}</div>
    </a>
  );
};

export default OutlinedButton;
