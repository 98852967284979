import { Link } from "react-router-dom";
import { ReactComponent as AlertTriangle } from "../common/icons/alert-triangle.svg";
import { getIsMobile } from "../../utils/general";
import { ResistanceResult } from "../../types/results";
import { sendPlanClickedL1ResultsLink } from "../../utils/analytics/customEventTracking";

const AMR_COPY =
  "Please note that your test found positive resistance gene(s) for the following classes of antibiotics: ";

export const AMRResistanceBanner: React.FC<{
  testHash: string;
  resistanceResults: ResistanceResult[];
}> = ({ testHash, resistanceResults }) => {
  const isMobile = getIsMobile();
  const detectedResistances = resistanceResults.filter(
    (resistanceResult) => resistanceResult.detected
  );
  const detectedResistanceNames = detectedResistances.map(
    (resistanceResult) => resistanceResult.resistance.display_name
  );

  return (
    <div className="bg-evvy-cream text-evvy-black px-5 py-4 mt-5 rounded-md justify-start items-center gap-5 inline-flex">
      <div className="h-5 w-6 relative">
        <AlertTriangle className="stroke-evvy-black" />
      </div>
      <div className={`${isMobile ? "b3" : "b2"} text-left space-y-3`}>
        <div>
          {AMR_COPY}
          {detectedResistanceNames.join(", ")}
          {". View results "}
          <Link
            className="underline persistSize"
            to={`/results/${testHash}/download/`}
            onClick={() =>
              sendPlanClickedL1ResultsLink({
                testHash,
                type: "amr",
              })
            }
          >
            here.
          </Link>
        </div>
      </div>
    </div>
  );
};
