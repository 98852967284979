import React from "react";
import { Link } from "react-router-dom";

// mobile-responsive, transitions at "md"
const Footer = () => {
  const copyrightText = `Copyright © ${new Date().getFullYear()} Evvy. All rights reserved`;
  const policyLinkStyle = "underline underline-offset-2";
  const legalDisclaimer = `Evvy is a vaginal health platform that provides access to comprehensive testing and care. We partner with independent clinical entities that can provide medical advice, care, lab testing, pharmacy services, and more through their licensed professionals. Evvy itself is not a healthcare provider and cannot provide medical advice or care. We do not own, operate, supervise, or control the medical practices or other services users may access through the Evvy platform.  Evvy is not intended to replace the advice of your doctor, your Pap smear, or regular OB-GYN visits.`;

  return (
    <footer className="bg-evvy-black text-evvy-cream px-6 md:px-16 pt-6 pb-8 md:flex text-sm z-0 align-bottom">
      <div className="font-semibold hidden md:block tracking-wider uppercase">
        {/*<a href="https://www.evvy.com/" className="pr-5">Meet your Microbiome</a>*/}
        {/*<a href="https://www.evvy.com/ask" className="pr-8">#AskEvvy on our blog</a>
        <a href="https://www.evvy.com/resources" className="pr-8">See our Sources</a>*/}
      </div>
      <div className="max-w-xl pb-4 md:pb-0">
        <Link to="/tests" className="pointer-events-auto">
          <img
            className="h-8 mt-2 sm:h-10 mb-4"
            src="/images/evvy-logo-text-white.svg"
            loading="lazy"
            alt="Evvy Text Logo"
          />
        </Link>
        <div>{legalDisclaimer}</div>
      </div>

      <div className="flex md:ml-auto items-end">
        <div className="hidden md:block">{copyrightText}</div>
        <div className="px-2 hidden md:block"></div>
        <div className={policyLinkStyle}>
          <a href="https://www.evvy.com/terms-of-service">Terms of Service</a>
        </div>
        <div className="px-2">|</div>
        <div className={policyLinkStyle}>
          <a href="https://www.evvy.com/privacy-policy">Privacy Policy</a>
        </div>
        <div className="px-2">|</div>
        <div className={policyLinkStyle}>
          <a href="https://www.evvy.com/consumer-health-data-privacy-policy">
            Consumer Health Data Privacy
          </a>
        </div>
      </div>
      <div className="block md:hidden mt-2">{copyrightText}</div>
    </footer>
  );
};

export default Footer;
