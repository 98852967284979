import TimelineNumber from "../../../../components/plan_unfurled/timelineNumber";

const Start = () => {
  return (
    <div className="p-3">
      <div className="flex">
        <div className="flex flex-col items-center pr-4">
          <TimelineNumber number={1} color="bg-crazy-purple" size="30px" />
          <div className="border-l border-black border-opacity-20 h-full" />
        </div>
        <div className="flex flex-col justify-center mt-2">
          <p className="t3 sm:mb-2 mb-0 align-middle">
            Health Context questionnaire{" "}
            <span className="text-gray-500 sm:inline hidden">• 5 min</span>
          </p>
          <p className="t3 mb-2 text-gray-500 sm:hidden block">5 min</p>
          <p className="b2">
            Questions about your vaginal health & symptoms at time of sample
            collection
          </p>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex flex-col items-center pr-4 h-16">
          <TimelineNumber number={2} color="bg-coral" size="30px" />
          <div className="border-l border-black border-opacity-20 h-full" />
        </div>
        <div>
          <p className="t3 mb-0 sm:mb-6 align-middle">
            Take your sample{" "}
            <span className="text-gray-500 sm:inline hidden">• 1 min</span>
          </p>
          <p className="mb-0 t3 mb-2 text-gray-500 sm:hidden block">1 min</p>
        </div>
      </div>
      <div className="flex align-center">
        <div className="flex flex-col items-center pr-4">
          <TimelineNumber
            number={3}
            color="bg-evvy-pine bg-opacity-70"
            size="30px"
          />
        </div>
        <div className="mt-2">
          <p className="t3 sm:mb-3 mb-0">
            Health Profile questionnaire{" "}
            <span className="text-gray-500 sm:mb-6 mb-0 sm:inline hidden">
              • 5 min
            </span>
          </p>
          <p className="t3 text-gray-500 sm:hidden inline">5 min</p>
          <p className="b2">Questions about your whole body health</p>
        </div>
      </div>
    </div>
  );
};

export default Start;
