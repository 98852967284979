import {
  TransparentCareProductConfig,
  TreatmentFormFactor,
  TreatmentPlan,
} from "../../types/care";
import ALaCarePillImg from "../common/images/horizontalImages/pill.png";
import ALaCareCreamImg from "../common/images/horizontalImages/cream.png";
import ALaCareApplicatorCreamImg from "../common/images/horizontalImages/applicator-cream.png";
import ALaCareSuppositoryImg from "../common/images/horizontalImages/suppository.png";

import BundlePillImg from "../common/images/sidePeekImages/side-peek-pill.png";
import BundleCreamImg from "../common/images/sidePeekImages/side-peek-cream.png";
import BundleApplicatorCreamImg from "../common/images/sidePeekImages/side-peek-applicator-cream.png";
import BundleSuppositoryImg from "../common/images/sidePeekImages/side-peek-suppository.png";

const weekMessage = {
  1: "Once you start your first treatment, fill in your treatment start date to customize your calendar! <br><br>If your treatment program includes antibiotics, try to time the start of your treatment so that your period doesn’t happen during your course of antibiotics.",
  2: "",
  3: "Great job getting to week 3! Any questions? Don't hesitate to reach out to support@evvy.com. ",
  4: "",
  5: "You're in your second month! These next 30 days are crucial — and we're here for you every step of the way.",
  6: "Many Evvy members start noticing a difference in the next week or so — but everyone's microbiome is different, so this timeline might vary for you.",
  7: "You're almost there! Thank you for trusting Evvy to be your partner in your vaginal health journey.",
};
const getWeekMessage = (currentWeek: number, treatmentPlan: TreatmentPlan) => {
  const treatmentCalendar = treatmentPlan.calendar;
  const numWeeks = treatmentCalendar.last_treatment_week;

  // special handling for soo3 and vaginal estrogen
  // TOOD: this is a hacky way to do this. We should refactor this to be more dynamic
  const SOO3 = "soo-3";
  const EST1 = "est-1";
  const maxWeekNumIncludingVaginalEstrogen =
    treatmentCalendar.treatment_schedule.reduce((acc, curr) => {
      if (curr.prescriptions.some((p) => p.slug === EST1) && curr.week > acc) {
        return curr.week;
      }
      return acc;
    }, 0);
  const includesSOO3 = treatmentPlan.prescriptions.some((p) => p.slug === SOO3);
  const includesVaginalEstrogen =
    maxWeekNumIncludingVaginalEstrogen > 0 &&
    currentWeek > maxWeekNumIncludingVaginalEstrogen;

  var useAsNeededCopy = "";
  if (includesSOO3 && !includesVaginalEstrogen) {
    useAsNeededCopy =
      "Don't forget! You have hyaluronic acid available to alleviate vaginal dryness or irritation as needed. Revisit your medication instructions for all the details.";
  } else if (!includesSOO3 && includesVaginalEstrogen) {
    // only include copy for week 3+
    useAsNeededCopy =
      "Don't forget! Using vaginal estrogen 2-3x weekly can help to maintain results. Revisit your medication instructions for all the details.";
  } else if (includesSOO3 && includesVaginalEstrogen) {
    useAsNeededCopy =
      "Don’t forget! You have hyaluronic acid and vaginal estrogen available as needed. Revisit your medication instructions for all the details.";
  }

  const messages = [];

  if (currentWeek === numWeeks) {
    // last week, ask user to retest!
    const lastWeekMessage =
      "Curious how your microbiome has changed with treatment? With Evvy, you can retest and see how your microbiome shifted so we can iterate on the best care for you.";
    // remove use as needed copy if it's the last week
    useAsNeededCopy = "";
    messages.push(lastWeekMessage);
  } else if (
    !(currentWeek === 1 && treatmentCalendar.start_date) &&
    weekMessage[currentWeek as keyof typeof weekMessage]
  ) {
    // special handling for 1st week. If treatment start date is already set, then don't return the message.
    messages.push(weekMessage[currentWeek as keyof typeof weekMessage]);
  }

  if (useAsNeededCopy) {
    messages.push(useAsNeededCopy);
  }

  return messages.join("<br><br>");
};

// gets appropriate cta constant for current week, dependent on # weeks in treatment plan
const getWeekCTA = (currentWeek: number, numWeeks: number) => {
  if (currentWeek === 1) {
    return "initial-check-in";
  }
  if (currentWeek === numWeeks) {
    // last week, ask user to retest!
    return "final-retest";
  }
  return "";
};

const weekCtaTitle = {
  "initial-check-in": "Initial Check In",
  // "mid-plan-check-in": "Mid-Plan Check In",
  "final-retest": "Retest with Evvy",
};

const weekCtaDescription = {
  "initial-check-in":
    "Schedule time with your coach to introduce you to your treatment plan, explain each medication, and answer any of your questions.",
  // "mid-plan-check-in": "Check in with your coach to talk about how treatment is going, and how your symptoms are responding.",
};

const getWeekCTADescription = (ctaType: string, isMember: boolean) => {
  if (ctaType === "final-retest") {
    if (isMember) {
      return "Congratulations on completing treatment! Retest again to understand what treatments worked for you and how your microbiome has shifted. Click below to head to your dashboard and order your next test now.";
    } else {
      return "Congratulations on completing treatment! Retest again to understand what treatments worked for you and how your microbiome has shifted.";
    }
  } else {
    return (
      weekCtaDescription[ctaType as keyof typeof weekCtaDescription] ||
      "Unknown type"
    );
  }
};

// slightly different ones for the PDF
const weekCtaDescriptionPdf = {
  "initial-check-in":
    "Remember to log into your Evvy acccount to schedule time with your coach so they can introduce you to your treatment plan, explain each medication, and answer any of your questions.",
  // "mid-plan-check-in": "Remember to log into your Evvy acccount and schedule a check in with your coach to talk about how treatment is going and how your symptoms are responding.",
  "final-retest":
    "Congratulations on completing treatment! Retest again to understand what treatments worked for you and how your microbiome has shifted.",
};

// currently used in FAQ section, treatment Plan, and CTAs in the calendar
const calendlyURL = "https://calendly.com/d/d2k-yw3-qtq/evvy-1-1-care-call";

const getWeekCalendlyURL = () => {
  // if (ctaType === weekCTA[1]) {
  //   return generalCoachingCallLink;
  // } else {
  //   return calendlyURL;
  // }
  return calendlyURL;
};

const CARE_CARD_SUBHEAD = {
  maintenance: "Get clinical care with Evvy",
  probioticsOnly: "Get clinical care with Evvy",
  default: "Get clinical care with Evvy",
};

const CARE_DEFAULT_VALUE_PROP_BULLETS = [
  "Personalized treatment program and calendar created by an Evvy-affiliated provider",
  "2 months of prescription medication shipped to your door, which can include treatments like antibiotics, vaginally-inserted probiotics, and supplements — customized to your microbiome and tailored to your preferences",
  "1:1 onboarding call with a health coach",
];

const CARE_DEFAULT_SUBTITLE =
  "Access first-of-its-kind, personalized, and integrative vaginal healthcare";

const careLandingCopy = {
  careIneligible: {
    title: "Talk to a health coach who gets it",
    descriptionOne:
      "Based on your latest test results and microbiome state, you qualify for a **free 1:1 call with an Evvy health coach!**",
    descriptionTwo:
      "Looking for other forms of care? Check out our provider recommendations below to get high quality in-person care.",
    blockTitle: "Book a free coaching call with an Evvy health coach",
    bullets: [
      "Review your results in the context of your unique symptoms and goals",
      "Talk through your plan and next steps",
      "Get all your questions answered",
      "Help you prepare to discuss your results with your doctor or partner",
    ],
    ctaText: "Book now",
  },
  probioticsOnly: {
    title: CARE_CARD_SUBHEAD.probioticsOnly,
    descriptionOne: CARE_DEFAULT_SUBTITLE,
    bullets: [
      {
        text: "7 weeks of prescription-grade oral and vaginal probiotics shipped to your door — specifically compounded for you",
      },
      {
        text: "Personalized treatment calendar created by a provider",
        subtext: "",
      },
      { text: "1:1 onboarding call with a health coach" },
      { text: "HSA/FSA eligible" },
    ],
  },
  maintenance: {
    title: CARE_CARD_SUBHEAD.maintenance,
    descriptionOne: CARE_DEFAULT_SUBTITLE,
    bullets: [
      {
        text: "3 months of prescription-grade oral and vaginal probiotics shipped to your door — specifically compounded for you",
        subtext: "",
      },
      {
        text: "2 courses of boric acid to combat symptoms if they arise",
        subtext: "",
      },
      {
        text: "Personalized maintenance calendar created by a provider",
        subtext: "",
      },
      { text: "Continuous, async support included" },
    ],
  },
  removeAntibiotics: {
    title: "Get innovative clinical care from Evvy",
    descriptionOne: CARE_DEFAULT_SUBTITLE,
    bullets: [
      {
        text: CARE_DEFAULT_VALUE_PROP_BULLETS[1],
        subtext: "",
      },
      {
        text: CARE_DEFAULT_VALUE_PROP_BULLETS[0],
      },
      {
        text: CARE_DEFAULT_VALUE_PROP_BULLETS[2],
      },

      { text: "HSA/FSA eligible" },
    ],
  },
};

const CARE_SHIPPING_HSA_COPY = "Free shipping & HSA/FSA eligible.";

const intakeDisclaimerCopy =
  "Before clicking continue, please review all the responses above for completeness and accuracy. Your provider will make a prescribing decision based on this information and your detailed test results, and will only reach out if they have further questions.";

const REMOVE_ANTIBIOTICS_DISCOUNT = 50;

const TREATMENT_TYPE_TO_COLOR = {
  "Reduce Disruptive": [
    "bg-highlighter-salmon",
    "bg-highlighter-salmon/40",
    "bg-highlighter-salmon/70",
  ],
  "Promote Protective": [
    "bg-evvy-silverfish",
    "bg-evvy-silverfish/40",
    "bg-evvy-silverfish/70",
  ],
  Maintenance: [
    "bg-highlighter-purple",
    "bg-highlighter-purple/40",
    "bg-highlighter-purple/70",
  ],
};

export {
  weekCtaTitle,
  weekCtaDescriptionPdf,
  calendlyURL,
  careLandingCopy,
  getWeekCTA,
  getWeekCalendlyURL,
  getWeekCTADescription,
  getWeekMessage,
  intakeDisclaimerCopy,
  CARE_DEFAULT_VALUE_PROP_BULLETS,
  CARE_CARD_SUBHEAD,
  CARE_DEFAULT_SUBTITLE,
  CARE_SHIPPING_HSA_COPY,
  REMOVE_ANTIBIOTICS_DISCOUNT,
  TREATMENT_TYPE_TO_COLOR,
};

export const SEXUAL_ACTIVITY_TITLE =
  "Be mindful of sexual activity or use a condom";
export const SEXUAL_ACTIVITY_P1 = {
  maintenance:
    "Avoid all types of sexual intercourse while using boric acid to protect you and your partner from any potential adverse effects.",
  treatment:
    "Sexual activity should be avoided when on certain vaginal treatments. Please double check the instructions for each prescription to know when it is safe.",
  null: "Sexual activity should be avoided when on certain vaginal treatments. Please double check the instructions for each prescription to know when it is safe.",
};
export const SEXUAL_ACTIVITY_P2 = {
  maintenance:
    "While not taking boric acid, please use a condom when you’re sexually active to make sure disruptive bacteria isn’t re-introduced to your vaginal microbiome!",
  treatment:
    "FYI: sexual activity can cause disruptive bacteria to be re-introduced to your vaginal microbiome — and condoms can play a pivotal role in preventing this!",
  null: "FYI: sexual activity can cause disruptive bacteria to be re-introduced to your vaginal microbiome — and condoms can play a pivotal role in preventing this!",
};
export const PERIOD_TITLE = "If your period starts while taking treatment";
export const PERIOD_P1 =
  "Boric acid and vaginal probiotics can be stopped when on your period and resumed after, but all other treatments can continue as prescribed.";
export const PERIOD_P2_TREATMENT_ONLY =
  "We do recommend timing your start date so that your full dose of antibiotics isn't interrupted by your period.";
export const TIPS_TITLE = "Some tips from Evvy";
export const TIPS_DESCRIPTION =
  "While you may have heard these tips before, Evvy members have actually found the following helpful:";
export const TIPS_BULLETS = [
  "Try wearing cotton underwear",
  "Avoid the use of bath bombs, fragranced soaps, and scented wipes",
  "Use warm water and unscented soap to wash your vulva",
  "Change quickly out of wet or sweaty clothes",
];
export const TREATMENT_SUBTITLE =
  "We've created a custom 2-month treatment plan for you:";

export const A_LA_CARE_SHOP_TREATMENTS_PAGE_PATH = "/care/shop/treatments/";

export const CARE_INTAKE_STATIC_PATH = "/care/intake/";

export const VIEW_RX_PRODUCTS = "View RX products →";

export const SHIPPING_DAYS = "2-4 business days";

export const getTransparentCareImageFromFormFactor = (
  productConfig: TransparentCareProductConfig,
  formFactor: TreatmentFormFactor
) => {
  const FORM_FACTOR_TO_IMAGE = {
    bundle: {
      pill: BundlePillImg,
      cream: BundleCreamImg,
      "applicator-cream": BundleApplicatorCreamImg,
      suppository: BundleSuppositoryImg,
    },
    individual: {
      pill: ALaCarePillImg,
      cream: ALaCareCreamImg,
      "applicator-cream": ALaCareApplicatorCreamImg,
      suppository: ALaCareSuppositoryImg,
    },
  };

  return FORM_FACTOR_TO_IMAGE[productConfig][formFactor];
};

export const FORM_FACTOR_TO_IMAGE_V2 = {
  pill: {
    image: {
      bundle: getTransparentCareImageFromFormFactor("bundle", "pill"),
      individual: getTransparentCareImageFromFormFactor("individual", "pill"),
    },
    sizeClassName: {
      bundle: "h-36",
      individual: "h-24 sm:h-40",
    },
  },
  cream: {
    image: {
      bundle: getTransparentCareImageFromFormFactor("bundle", "cream"),
      individual: getTransparentCareImageFromFormFactor("individual", "cream"),
    },
    sizeClassName: {
      bundle: "h-[115px]",
      individual: "h-10 sm:h-16",
    },
  },
  "applicator-cream": {
    image: {
      bundle: getTransparentCareImageFromFormFactor(
        "bundle",
        "applicator-cream"
      ),
      individual: getTransparentCareImageFromFormFactor(
        "individual",
        "applicator-cream"
      ),
    },
    sizeClassName: {
      bundle: "h-32",
      individual: "h-5 sm:h-8",
    },
  },
  suppository: {
    image: {
      bundle: getTransparentCareImageFromFormFactor("bundle", "suppository"),
      individual: getTransparentCareImageFromFormFactor(
        "individual",
        "suppository"
      ),
    },
    sizeClassName: {
      bundle: "h-36",
      individual: "h-16 sm:h-24",
    },
  },
};

export const TRANSPARENT_BUNDLE_FREEBIES = [
  {
    imgSrc: "/images/graphics/phone-treatment-plan.png",
    altText: "Provider-Designed Treatment Calendar",
    desktopText: "Daily Treatment Plan Designed by a Provider",
    mobileText: "Provider-Designed Treatment Calendar",
    price: "$30",
    slug: "calendar",
  },
  {
    imgSrc: "/images/graphics/health-coach-call-square.png",
    altText: "1:1 Call with an Expert Health Coach",
    desktopText: "1:1 Call with an Expert Health Coach",
    mobileText: "1:1 Coaching Call",
    price: "$30",
    slug: "coach",
  },
  {
    imgSrc: "/images/graphics/evvy-travel-bag.png",
    altText: "Exclusive Evvy Travel Bag",
    desktopText: "Exclusive Evvy Travel Bag",
    mobileText: "Versatile Travel Bag",
    price: "$25",
    slug: "bag",
  },
];

export const CONSULT_REVIEW_FREEBIE = {
  imgSrc: "/images/graphics/phone-treatment-plan.png",
  altText: "Provider Consult Review",
  desktopText: "Provider Consult Review",
  mobileText: "Provider Consult Review",
  price: "$30",
  slug: "consult-review",
};
export const ANTIFUNGAL_FLUCONAZOLE_SLUG = "af-1";

export const BORIC_ACID_14_DAY_SLUG = "ba-14";

export const CONSULT_TURNAROUND_TIME = "24 business hours";
export const CONSULT_TURNAROUND_TIME_UNGATED = "24 hours";
