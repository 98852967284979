import { useContext } from "react";
import { AuthContext } from "../contexts/authContext";

export const useExperiments = () => {
  const authContext = useContext(AuthContext);
  const currentUser = authContext.currentUser;
  if (!currentUser) {
    throw new Error("useExperiments must be used within an AuthContext");
  }
  return currentUser.experiments;
};
