import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// footer on every health context form. mainly for Formik submit button, but also to render page index details
const FormFooter = ({ submit, errors, ...subrouteProps }) => {
  const {
    previousPage,
    hash,
    isLastPage,
    pageIndex,
    totalPages,
    isSubmitting,
  } = subrouteProps;

  return (
    <div className="border-t border-dashed mt-10 py-10">
      {errors && Object.keys(errors).length > 0 ? (
        <div className="bg-coral p-2 px-3 font-medium rounded-md mb-8">
          Please fix the errors above to continue
        </div>
      ) : (
        ""
      )}

      <div className="block sm:flex items-center">
        <div className="flex-1 text-left mb-4 sm:mb-0">
          {previousPage ? (
            <Link
              to={`/tests/${hash}/context/${previousPage}/`}
              className="cursor-pointer tracking-wider font-semibold uppercase underline"
            >
              ← Back
            </Link>
          ) : (
            ""
          )}
        </div>
        <div className="flex-1 text-center">
          <button
            className="cursor-pointer tracking-wider py-6 px-10 sm:px-20 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none"
            onClick={() => submit()}
            disabled={isSubmitting}
            type="button"
          >
            {isLastPage ? "Next" : "Next"}
          </button>
        </div>
        <div className="flex-1 mt-4 sm:mt-0 sm:text-right font-medium opacity-75">
          {/*<div>Section {pageIndex + 1} of {totalPages}</div>*/}
        </div>
      </div>
    </div>
  );
};

export default FormFooter;
