/**
 * An outlined link styled with a right arrow icon
 * @param {string} text the text label for the button
 * @param {string} href source to link to
 * @returns jsx of a div whose role is link
 */

import { React, useState } from "react";
import { ReactComponent as UpperRightArrow } from "./icons/arrow-right-no-tail.svg";
import { secondaryColors } from "../../utils/viz";

const ArrowLink = ({ text, href, ariaHidden, onClick }) => {
  const [hovered, setHovered] = useState(false);
  const handleClick = () => {
    window.open(href, "_blank");
    if (onClick) {
      onClick();
    }
  };
  return (
    // assign role link to div so that link can nest within an <a> tag
    <div
      className={`flex items-center b1 medium border border-evvy-pine rounded-full px-4 py-1 mt-3 mr-2 transition-colors
      ${hovered ? "text-white bg-evvy-pine" : "text-evvy-pine"}
      hover:bg-evvy-pine hover:text-white`}
      role="link"
      aria-hidden={`${ariaHidden}`}
      tabIndex={ariaHidden ? -1 : 0}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          handleClick();
        }
      }}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      onFocus={() => setHovered(true)}
      onBlur={() => setHovered(false)}
    >
      {text}
      <UpperRightArrow
        className="ml-2"
        stroke={hovered ? "#FFF" : secondaryColors["evvy-pine"]}
      />
    </div>
  );
};

export default ArrowLink;
