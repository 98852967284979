export const PillsCircleIcon: React.FC<{
  className?: string;
  strokeClassName?: string;
}> = ({ className = "", strokeClassName = "" }) => {
  return (
    <svg
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="current" />
      <g clipPath="url(#clip0_7074_15649)">
        <path
          d="M20.337 25.3049C23.4869 25.3049 26.0403 22.7515 26.0403 19.6017C26.0403 16.4519 23.4869 13.8984 20.337 13.8984C17.1872 13.8984 14.6338 16.4519 14.6338 19.6017C14.6338 22.7515 17.1872 25.3049 20.337 25.3049Z"
          className={strokeClassName}
          stroke="black"
          strokeWidth="1.3302"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          className={strokeClassName}
          d="M18.2588 22.13L22.4157 17.0586"
          stroke="black"
          strokeWidth="1.3302"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          className={strokeClassName}
          d="M36.6984 26.1719L31.2778 33.4216C29.8312 35.3504 27.1043 35.7494 25.1755 34.3028C23.2467 32.8562 22.8476 30.1293 24.2942 28.2005L29.7148 20.9509C31.1614 19.0221 33.8883 18.623 35.8171 20.0696C37.7459 21.4996 38.145 24.2265 36.6984 26.1719Z"
          stroke="black"
          strokeWidth="1.3302"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          className={strokeClassName}
          d="M27.0049 24.5742L33.9885 29.7953"
          stroke="black"
          strokeWidth="1.3302"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7074_15649">
          <rect
            width="24.2596"
            height="22.5969"
            fill="white"
            transform="translate(13.9688 13.2344)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
