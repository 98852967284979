/**
 * A rounded button used to filter the bacteria table
 * @param {string} text the text label for the button
 * @returns jsx of a button
 */

import { useDispatch, useSelector } from "react-redux";
import { updateFilter } from "../../pages/results_v2/resultsSlice";

const FilterPill = ({ text, data, onClick }) => {
  /* Redux */
  // redux dispatch method for updating global store
  const dispatch = useDispatch();

  // get selected health association filter from store
  const filteredAssociation = useSelector(
    (state) => state.results.value.filtered
  );

  const handleClick = () => {
    // toggle or update selected health association filter pill
    let filter;
    if (filteredAssociation) {
      filter = text === filteredAssociation.name ? null : data;
    } else {
      filter = data;
    }
    // dispatch to store
    dispatch(updateFilter(filter));
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className={`unset text-[12px] sm:text-[13px] font-semibold uppercase tracking-wide pointer-events-auto rounded-full px-4 py-1 mr-2 mt-3 border border-transparent
    ${
      text === filteredAssociation?.name
        ? "text-white bg-evvy-pine"
        : "text-evvy-pine bg-dv-pond bg-opacity-10"
    } hover:border-evvy-pine`} // when pill is selected or hovered, swap background & text colors
      onClick={handleClick}
    >
      {text}
    </button>
  );
};

export default FilterPill;
