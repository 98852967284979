import { Field } from "formik";
import { Question } from "../../../utils/healthHistoryV2";
import { HealthContextFields, HealthContextOptions } from "../../../types/test";
import { cn } from "../../../utils/cn";
import QuestionTitle from "./questionTitle";

const DropdownQuestion = ({
  values,
  healthContext,
  question,
}: {
  values: any;
  healthContext: HealthContextFields;
  question: Question;
}) => {
  const options =
    healthContext._options[question.slug as keyof HealthContextOptions];

  return (
    <>
      <QuestionTitle questionTitle={question.slug} />
      <Field
        as={"select"}
        id={question.slug}
        name={question.slug}
        className={cn(
          "border border-black border-opacity-20 rounded-xl sm:w-96 w-64 outline-none focus:ring-0 p-3 cursor-pointer"
        )}
        placeholder="Your Answer"
        autoComplete="off"
        value={values[question.slug as keyof HealthContextFields]}
      >
        <option value="" label="Select option" />
        {options?.map((option) => (
          <option key={option[0]} value={option[0]}>
            {option[1]}
          </option>
        ))}
      </Field>
    </>
  );
};

export default DropdownQuestion;
