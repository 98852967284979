import React, { FC, ReactElement, useContext } from "react";

import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/authContext";
import { getLoginNextPath } from "../../utils/general";

type VerifiedRouteProps = {
  authRequired?: boolean;
  consentRequired?: boolean;
  verifyEmailRequired?: boolean;
  children: ReactElement;
};

// Route component that takes properties
export const VerifiedRoute: FC<VerifiedRouteProps> = ({
  authRequired,
  consentRequired,
  verifyEmailRequired,
  children,
}) => {
  const { authenticated, currentUser } = useContext(AuthContext);
  const location = useLocation();

  const isProviderRoute = location.pathname.startsWith("/provider/");

  const loginUrl =
    location.pathname === "/"
      ? "/login/"
      : getLoginNextPath(
          location.pathname,
          new URLSearchParams(location.search)
        );

  if (authRequired && !authenticated) {
    return <Navigate to={loginUrl} />;
  }

  if (
    !currentUser?.identity.isProvider && // Provider's don't require the research consent
    consentRequired &&
    authenticated &&
    !currentUser?.consentCompleted?.research
  ) {
    return <Navigate to={`/research-consent/`} />;
  }

  if (
    verifyEmailRequired &&
    authenticated &&
    !currentUser?.identity?.emailVerified
  ) {
    return <Navigate to={`/verify-email/`} />;
  }

  // If the person is not a provider and we try to navigate to a provider route, redirect to the login page
  if (isProviderRoute && !currentUser?.identity.isProvider) {
    return <Navigate to={`/login/`} />;
  }

  // If the person is a provider and we try to navigate to a non provider route, redirect to the provider home unless expliceitly allowed
  if (!isProviderRoute && currentUser?.identity.isProvider) {
    return <Navigate to={`/provider/`} />;
  }

  return children;
};
