import { useCallback, useState, useEffect } from "react";
import Layout from "../../components/layout";
import Header from "../../components/header";
import {
  ActivateTestModal,
  AskEvvyCard,
  CheckList,
  CommunityCard,
  TestCards,
} from "../../components/tests/";

import { testsService } from "../../services/tests";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { sendViewedMyTestsPage } from "../../utils/analytics/customEventTracking";
import { Test } from "../../types/test";
import {
  FIND_TEST_ID_BODY,
  FIND_TEST_ID_FOOTER,
  FIND_TEST_ID_SUBTITLE,
} from "../../components/tests/constants";
import { AnnouncementModal } from "../../components/tests/announcementModal";
import { RefillsCard } from "../../components/tests/refillsCard";
import { EligibilityCountdownBanner } from "../../components/care/eligibilityCountdownBanner";
import { useCompletedConsults } from "../../hooks/useCompletedConsults";
import useAnnouncements from "../../hooks/useAnnouncements";
import { cn } from "../../utils/cn";
import { ReactComponent as AlertTriangle } from "../../components/common/icons/alert-triangle.svg";
import { useConsults } from "../../hooks/care/useConsults";

export const TestsHome = () => {
  const [loading, setLoading] = useState(false);
  const [tests, setTests] = useState<Test[]>([]);
  const [mostRecentReadyTest, setMostRecentReadyTest] = useState<Test>();

  const [activateTestModalOpen, setActivateTestModalOpen] = useState(false);

  //context
  const user = useLoggedInUser();
  const completedConsults = useCompletedConsults();
  const { consults } = useConsults();

  const ungatedRxConsults = consults?.filter(
    (consult) => consult.type === "ungated-rx"
  );

  //  controls whether the checklist is the first card shown on mobile
  const [checkListFirstOnMobile, setCheckListFirstOnMobile] = useState(true);
  const firstConsultWithRefills = completedConsults?.find(
    (consult) => consult?.ready_for_refills
  );

  const showCareEligibleBanner =
    mostRecentReadyTest &&
    mostRecentReadyTest.eligible_for_care &&
    !mostRecentReadyTest?.latest_vaginitis_consult?.consult_paid;

  // get announcements
  const { unreadAnnouncementToShow } = useAnnouncements();
  const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);

  // effects
  const fetchTests = useCallback(() => {
    setLoading(true);
    var careEligible = false;
    testsService.fetchTests(
      (response: { data: any }) => {
        const testsData = response.data as Test[];
        setTests(response.data);
        if (testsData.length > 0) {
          const firstTest = testsData[0];
          if (!firstTest?.results_viewed_at) {
            setCheckListFirstOnMobile(false);
          }

          // get the most recent ready test
          const latestReadyTest = testsData
            .filter((test: Test) => test.status === "RD")
            .sort((a: Test, b: Test) => {
              return a.results_ready_at && b.results_ready_at
                ? new Date(b.results_ready_at).getTime() -
                    new Date(a.results_ready_at).getTime()
                : 0;
            })[0];

          if (latestReadyTest) {
            setMostRecentReadyTest(latestReadyTest);
            careEligible = latestReadyTest.eligible_for_care;
          }
        }
        setLoading(false);
        sendViewedMyTestsPage({ testOrder: testsData.length, careEligible });
      },
      (error: any) => {
        console.error(error);
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    fetchTests();
  }, [fetchTests]); // Fetches tests upon page load

  useEffect(() => {
    if (unreadAnnouncementToShow) {
      setAnnouncementModalOpen(true);
    }
  }, [unreadAnnouncementToShow]);

  return (
    <>
      <Layout
        title="Evvy - My Tests"
        padded={true}
        bgClass="bg-evvy-cream"
        full={false}
        header={
          <Header
            noBottomPadding={showCareEligibleBanner}
            noRoundedBottom={showCareEligibleBanner}
            bottomBanner={
              showCareEligibleBanner ? (
                <EligibilityCountdownBanner
                  test={mostRecentReadyTest}
                  goToCareOnClick
                />
              ) : undefined
            }
          />
        }
        centered={false}
        extraFooter={false}
      >
        <div className="max-w-5xl mx-auto">
          {user && user.identity.firstName && (
            <h2 className="-mt-4">Hi, {user.identity.firstName}</h2>
          )}
          <div className="grid grid-cols-1 md:grid-cols-5 md:space-x-6">
            <div className="col-span-1 md:col-span-3">
              {ungatedRxConsults?.length === 0 &&
                user?.identity.registrationRoute === "treatment" && (
                  <div className="bg-highlighter-salmon/70 rounded-2xl p-4 mb-4 text-evvy-black">
                    <div className="flex items-center mb-3">
                      <AlertTriangle className="w-5 h-5 mr-1" />
                      <span className="text-sm font-semibold leading-none uppercase">
                        Attention
                      </span>
                    </div>
                    <div className="text-sm font-medium">
                      You might be missing a care order for this account. Please
                      reach out to{" "}
                      <a
                        className="underline persistSize"
                        href="mailto:support@evvy.com"
                      >
                        support@evvy.com
                      </a>{" "}
                      so we can help.
                    </div>
                  </div>
                )}
              {!loading && tests.length === 0 && (
                <div className="p-4 sm:p-8 rounded-2xl bg-white mb-4">
                  <div className="uppercase font-semibold tracking-wider text-sm mb-6">
                    Can't find your test ID?
                  </div>
                  <p>{FIND_TEST_ID_SUBTITLE}</p>
                  <p>{FIND_TEST_ID_BODY}</p>
                  <p>{FIND_TEST_ID_FOOTER}</p>
                  <div>
                    <img
                      className="object-cover w-full rounded-lg"
                      src="/images/graphics/evvy-kit-updated.png"
                      loading="lazy"
                      alt="Evvy Kit"
                      style={{ height: "400px" }}
                    />
                  </div>
                </div>
              )}
              {!loading && tests.length > 0 && (
                <div className="mb-4">
                  <TestCards
                    tests={tests}
                    openActivateModal={() => setActivateTestModalOpen(true)}
                  />
                </div>
              )}
            </div>

            <div
              className={cn("col-span-1 md:col-span-2 md:order-2 pb-4", {
                "order-first": checkListFirstOnMobile,
              })}
            >
              <CheckList
                openActivateModal={() => setActivateTestModalOpen(true)}
              />
              {/* layout on desktop */}
              <div className="hidden md:block space-y-6 pt-6">
                {firstConsultWithRefills && (
                  <RefillsCard consult={firstConsultWithRefills} />
                )}
                <CommunityCard />
                <AskEvvyCard />
              </div>
            </div>

            {/* layout on mobile */}
            {firstConsultWithRefills && (
              <div className="md:hidden col-span-1 order-2">
                <RefillsCard consult={firstConsultWithRefills} />
              </div>
            )}
            <div
              className={cn("md:hidden col-span-1 order-3", {
                "mt-4": firstConsultWithRefills,
              })}
            >
              <CommunityCard />
            </div>
            <div className="md:hidden col-span-1 order-4 mt-4">
              <AskEvvyCard />
            </div>
          </div>
        </div>
      </Layout>
      {activateTestModalOpen && (
        <ActivateTestModal
          refetchTests={fetchTests}
          handleClose={() => setActivateTestModalOpen(false)}
        />
      )}
      {announcementModalOpen && (
        <AnnouncementModal
          announcement={unreadAnnouncementToShow}
          handleClose={() => setAnnouncementModalOpen(false)}
        />
      )}
    </>
  );
};
