import React from "react";
import CloseX from "../closeX";

const ResearchStatusContentTemplate = ({ popover = true, handleClose }) => {
  return (
    <div
      className={`bg-dv-pond content p-4 ${
        popover ? "max-w-sm rounded-lg" : "py-8 w-full"
      }`}
    >
      {!popover && (
        <div>
          <div className="t4 text-evvy-white">Research Status</div>
          <CloseX handleClose={handleClose} />
        </div>
      )}
      <div className={`my-2 text-evvy-white`}>
        Scientific research is always evolving, so{" "}
        <span className="text-highlighter-yellow">“Research Status”</span> gives
        you a sense of how well-established the scientific literature is on a
        certain topic so far.
      </div>
      <div className={`my-2 text-evvy-white`}>
        We evaluated the research on each topic based on the quantity of high
        quality studies, and rated each item as:
      </div>
      <div className="bg-highlighter-yellow text-evvy-black font-medium w-max p-1">
        Novel
      </div>
      <div className={`my-2 text-evvy-white`}>
        Research is limited but promising.
      </div>
      <div className="bg-highlighter-yellow text-evvy-black font-medium w-max p-1">
        Emerging
      </div>
      <div className={`my-2 text-evvy-white`}>
        Research is sufficient but evolving.
      </div>
      <div className="bg-highlighter-yellow text-evvy-black font-medium w-max p-1">
        Established
      </div>
      <div className={`my-2 text-evvy-white`}>Research is well-documented.</div>
    </div>
  );
};

export default React.memo(ResearchStatusContentTemplate);
