/**
 * Unfurled Plan Page
 */

import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Scrollama, Step } from "react-scrollama"; //Intersection Observer handler library
import { select } from "d3";

/* Local */
// components
import Layout from "../../components/layout";
import Header from "../../components/header";
import ErrorBoundary from "../../components/common/errorBoundary";
import RectangularButton from "../../components/common/rectangularButton";
import SymptomCard from "../../components/plan_unfurled/symptomCard";
import PlanCard from "../../components/plan_unfurled/planCard";
import CareCard from "../../components/plan_unfurled/careCard";
import PlanNote from "../../components/plan_unfurled/planNote";
import { StiPositiveBanner } from "../../components/plan_unfurled/stiPositiveBanner";
import LinkedText from "../../components/common/linkedText";
import LoadingSpinner from "../../components/common/loadingSpinner";
import { EligibilityCountdownBanner } from "../../components/care/eligibilityCountdownBanner";

// imported components
import Markdown from "markdown-to-jsx";

// modals
import Modal from "../../components/common/modal";
import LearnMoreContentTemplate from "../../components/plan_unfurled/modalTemplates/learnMore";
import RecommendationContentTemplate from "../../components/plan_unfurled/modalTemplates/recommendation";
import NextTestContentTemplate from "../../components/plan_unfurled/modalTemplates/nextTest";
import ResearchTemplate from "../../components/plan_unfurled/modalTemplates/research";
import LeaveReviewContentTemplate from "../../components/plan_unfurled/modalTemplates/leaveReview";
import PricingTemplate from "../../components/plan_unfurled/modalTemplates/pricing";

// lib
import { defaultNote } from "../results_v2/constants";
import {
  yourPlanCopy,
  planItemKeys,
  careWidgetCopy,
  modalTypes as MTYPES,
  PLAN_VERSION,
} from "../plan_unfurled/constants.tsx";
import { setMoveTestUpError, setResearchModalOpen } from "../plan/planSlice";
import { GENERAL_COACHING_CALL_LINK, GET_CLINICAL_CARE } from "../constants";

import { testsService } from "../../services/tests";
import { planService } from "../../services/plan";
import { subscriptionService } from "../../services/subscription";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import {
  eventNames,
  sendViewedPlanPage,
} from "../../utils/analytics/customEventTracking";
import { getIsMobile } from "../../utils/general";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { AMRResistanceBanner } from "../../components/plan_unfurled/amrResistanceBanner";
import {
  getButtonCTATextForConsultation,
  handleCareIntroCTAClick,
} from "../../components/care/utils";
import { loadSurvicate } from "../../utils/analytics/helpers";
import { Link } from "react-router-dom";
import useSubscription from "../../hooks/useSubscription";
import { ProblemsModuleExpandedModal } from "../../components/plan_unfurled/modalTemplates/problemsModuleExpandedModal";
import { useProblemsSummary } from "../../hooks/care/useProblemsSummary";
import { A_LA_CARE_SHOP_TREATMENTS_PAGE_PATH } from "../../components/care/constants";

const UnfurledPlan = () => {
  /* state management */
  const [loading, setLoading] = useState(true);
  const [isMember, setIsMember] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [goal, setGoal] = useState(null);
  const [noteParts, setNoteParts] = useState([]);
  const [microbialActions, setMicrobialActions] = useState([]);
  const [nonMicrobialActions, setNonMicrobialActions] = useState(null);
  const [testHash, setTestHash] = useState(null);
  const [careEligible, setCareEligible] = useState(null);
  const [aLaCareEligible, setALaCareEligible] = useState(null);
  const [carePrice, setCarePrice] = useState(null);
  const [consult, setConsult] = useState(null);
  const [test, setTest] = useState(null);
  const [planProfileInfo, setPlanProfileInfo] = useState({});
  const [eligibleTreatmentPathways, setEligibleTreatmentPathways] = useState(
    []
  );
  const [vpcrResults, setVpcrResults] = useState({});
  const [resistanceDetected, setResistanceDetected] = useState(false);
  // care state
  const [removeAntibiotics, setRemoveAntibiotics] = useState(false);
  const [selectedPathwaySlug, setSelectedPathwaySlug] = useState("");
  const [careButtonCTACopy, setCareButtonCTACopy] = useState(`get started →`);
  // track analytics event sent only once
  const [viewPageAnalyticsSent, setViewPageAnalyticsSent] = useState(false);

  const currentUser = useLoggedInUser();
  const { subscription } = useSubscription();
  const { testSummary } = useProblemsSummary(testHash);

  const transparentCareEnabled = currentUser?.features.transparentCare;

  /* Redux */
  // redux dispatch method for updating global store
  const dispatch = useDispatch();

  // get data from store about whether research modal should be open
  const researchModalOpen = useSelector(
    (state) => state.plan.value.researchModalOpen
  );

  /* Route Params */
  const navigate = useNavigate();

  /* Effects */

  // Fetches plan & user subscription status upon page load
  useEffect(() => {
    scrollToTop(); // scroll to top on page load (dont' preserve scroll height of origin)
    fetchPlan();
    fetchIsMember();
  }, []);

  // load survicate when currentUser is set
  useEffect(() => {
    if (currentUser) return loadSurvicate(currentUser);
  }, [currentUser]);

  useEffect(() => {
    hashLinkScroll();
  }, [loading]);

  // Fetches test once testHash retrieved from plan api
  useEffect(() => {
    if (testHash) fetchTest();
  }, [testHash]);

  // watch store change to see if research item should open modal
  // NOTE: might want to move all modal actions into store at some point
  useEffect(() => {
    researchModalOpen
      ? setModalData({ type: MTYPES.RESEARCH })
      : setModalData(null);
  }, [researchModalOpen]);

  const fetchPlan = () => {
    setLoading(true);
    planService.fetchPlan(
      (response) => {
        const {
          goal,
          note,
          plan_items,
          test_hash,
          plan_profile,
          care_eligible,
          care_price,
          vpcr_results,
          eligible_treatment_pathways,
        } = response.data || {};

        // set goal and note
        setGoal(goal);
        // set note to be default note copy if not specified
        const resultsNote = note || defaultNote;
        // split the note text on our special newline character "|"
        if (resultsNote && resultsNote.includes("|")) {
          setNoteParts(resultsNote.split("|"));
        } else if (resultsNote) {
          setNoteParts([resultsNote]);
        }
        setTestHash(test_hash);
        setCareEligible(care_eligible);
        setCarePrice(care_price);
        setPlanProfileInfo(plan_profile);
        setEligibleTreatmentPathways(eligible_treatment_pathways);
        // store different groups of plan items in different pieces of state
        const microbialActions = plan_items.filter(
          ({ group }) => group === planItemKeys.MICROBIAL
        );
        setMicrobialActions(microbialActions);
        const nonMicrobialActions = plan_items.filter(
          ({ group }) => group === planItemKeys.NON_MICROBIAL
        );
        setNonMicrobialActions(nonMicrobialActions);
        // set vpcr results
        setVpcrResults(vpcr_results);
        // set resistance detected
        if (
          vpcr_results.has_results &&
          vpcr_results.pcrresultresistance_set &&
          vpcr_results.pcrresultresistance_set.length > 0
        ) {
          setResistanceDetected(
            vpcr_results.pcrresultresistance_set.some(
              (resistanceResult) => resistanceResult.detected
            )
          );
        }

        // set initial selected pathway slug
        if (eligible_treatment_pathways && eligible_treatment_pathways.length) {
          setSelectedPathwaySlug(eligible_treatment_pathways[0].slug);
        }

        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      },
      true
    );
  };

  // analytics
  useEffect(() => {
    if (planProfileInfo && testSummary && !viewPageAnalyticsSent) {
      sendViewedPlanPage({
        planVersion: PLAN_VERSION,
        careEligible,
        aLaCareEligible: test?.eligible_for_a_la_care,
        carePrice,
        planProfileId: planProfileInfo?.id,
        eligibleTreatmentPathways:
          eligibleTreatmentPathways &&
          eligibleTreatmentPathways?.map((pathway) => pathway.slug),
        microbiomeState: testSummary.problems_summary.microbiome_state,
        conditions: testSummary.problems_summary.conditions,
      });
      setViewPageAnalyticsSent(true);
    }
  }, [
    careEligible,
    carePrice,
    planProfileInfo,
    eligibleTreatmentPathways,
    testSummary,
    viewPageAnalyticsSent,
    test?.eligible_for_a_la_care,
  ]);

  // Check whether user is subscribed member
  const fetchIsMember = () => {
    subscriptionService.fetchHasSubscription(
      (response) => {
        const { exists } = response.data || {};
        setIsMember(exists);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  // get test associated with this plan
  const fetchTest = () => {
    setLoading(true);
    testsService.fetchTest(
      testHash,
      (response) => {
        const {
          eligible_for_care,
          eligible_for_a_la_care,
          latest_vaginitis_consult,
        } = response.data || {};
        setTest(response.data);
        setCareEligible(eligible_for_care);
        setALaCareEligible(eligible_for_a_la_care);
        setConsult(latest_vaginitis_consult);
        setCareButtonCTACopy(
          getButtonCTATextForConsultation(
            latest_vaginitis_consult,
            !currentUser.care.vaginitis.hasCompletedConsult &&
              !transparentCareEnabled
          )
        );
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      }
    );
  };

  // move order date for test to today
  const moveTestUp = () => {
    subscriptionService.triggerNextCharge(
      (response) => {
        // handle request and send success or error back to next test modal
        dispatch(setMoveTestUpError(false));
      },
      (error) => {
        dispatch(setMoveTestUpError(true));
        console.error(error);
      }
    );
  };

  /* switch case to determine which submit handler to pass to modal renderer */
  /* Do NOT forget the break statement! */
  const handleSubmit = (type, data) => {
    switch (type) {
      case MTYPES.NEXT_TEST: {
        moveTestUp();
        break;
      }
      default:
        break;
    }
  };

  // Utils
  const getBaseAnalyticsEventArgs = () => {
    return {
      planVersion: PLAN_VERSION,
      careEligible,
      carePrice,
      isMember,
      planProfileId: planProfileInfo?.id,
    };
  };

  const hashLinkScroll = () => {
    const { hash } = window.location;
    if (hash !== "") {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 50);
    }
  };

  const scrollToTop = () => {
    const { hash } = window.location;
    if (hash === "") {
      window.scrollTo(0, 0); // scroll to top on page load (dont' preserve scroll height of origin)
    }
  };

  const username = useMemo(() => {
    // fetch user info from storage
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // default
    let username = "there";
    if (currentUser) {
      const { first_name, email } = currentUser.identity;
      // use email address as default if first name is empty
      username = first_name ? first_name : email.substr(0, email.indexOf("@"));
    }
    return username;
  }, []);

  /* Scrollama handler */
  // This callback fires when a Step hits the offset threshold. It receives the
  // data prop of the step, which in this demo stores the index of the step.
  const onStepEnter = ({ element }) => {
    // when card is scrolled into view, reveal at full opacity
    select(element).classed("opacity-20", false);
  };

  const handleCloseModal = (modalType) => {
    setModalData(null);
    if (modalType === MTYPES.RESEARCH) {
      dispatch(setResearchModalOpen(false));
    }
  };

  const isMobile = getIsMobile();
  const showEligibilityCountdownBanner =
    careEligible && test && !consult?.consult_paid;

  const MODAL_COMPONENTS = [
    {
      type: MTYPES.LEARN_MORE,
      ModalComponent: LearnMoreContentTemplate,
      data: modalData?.data,
    },
    {
      type: MTYPES.RECOMMENDATION,
      ModalComponent: RecommendationContentTemplate,
      data: {
        ...modalData?.data,
        aLaCareEligible: test?.eligible_for_a_la_care,
      },
    },
    {
      type: MTYPES.NEXT_TEST,
      ModalComponent: NextTestContentTemplate,
      handleSubmit,
      subscription,
    },
    { type: MTYPES.RESEARCH, ModalComponent: ResearchTemplate },
    { type: MTYPES.LEAVE_REVIEW, ModalComponent: LeaveReviewContentTemplate },
    {
      type: MTYPES.PRICING,
      ModalComponent: PricingTemplate,
      data: modalData?.data,
    },
    {
      type: MTYPES.PROBLEMS,
      ModalComponent: ProblemsModuleExpandedModal,
      data: modalData?.data,
    },
  ];

  return (
    <>
      <Layout title="My Plan" noHeader full>
        {/* main page layout */}
        <div
          className={`min-w-full ${
            careEligible
              ? "bg-evvy-cream"
              : "bg-cover bg-no-repeat bg-evvy-cream"
          }`}
          style={
            !loading && !careEligible
              ? {
                  backgroundImage: `url('/images/graphics/plan-bg-gradient-2.png')`,
                }
              : {}
          }
        >
          <div className="grow-0 w-full">
            <Header
              noBottomPadding={showEligibilityCountdownBanner}
              noRoundedBottom={showEligibilityCountdownBanner}
              bottomBanner={
                showEligibilityCountdownBanner ? (
                  <EligibilityCountdownBanner test={test} goToCareOnClick />
                ) : undefined
              }
            />
          </div>
          <div className="max-w-7xl mx-auto px-3 md:px-0">
            <ErrorBoundary>
              <div className="grid md:grid-cols-5 md:space-x-12 mx-auto sm:pt-12">
                {/* plan intro card */}
                <div className="md:col-span-2 md:sticky md:self-start md:top-12 pb-4 md:pb-16">
                  <div className="bg-evvy-white rounded-lg p-6 md:p-10">
                    <h2>Your Plan</h2>
                    <div className="t3 regular">
                      We’ve created a personalized plan for your unique vaginal
                      microbiome.
                    </div>
                    {loading ? (
                      <div className="py-5">
                        <LoadingSpinner />
                      </div>
                    ) : careEligible ? (
                      <>
                        <div className="bg-evvy-silverfish/[0.33] px-5 py-6 mt-6">
                          <div className="b2 regular">
                            {careWidgetCopy.CARE_ELIGIBLE}
                          </div>
                          <div className="mt-6">
                            <RectangularButton
                              text={`${careButtonCTACopy}`}
                              target="_self"
                              bgColorClass="bg-evvy-blue"
                              textColorClass="text-evvy-black"
                              onClick={analyticsClickHandler({
                                eventName:
                                  eventNames.PLAN_CLICKED_START_CONSULT,
                                eventArgs: {
                                  ...getBaseAnalyticsEventArgs(),
                                  location: "plan-intro",
                                },
                                clickHandler: transparentCareEnabled
                                  ? () => navigate("/care/")
                                  : // TODO: remove old care intro click handler for v0-bundle post transparent care launch
                                    () =>
                                      handleCareIntroCTAClick(
                                        test,
                                        consult,
                                        removeAntibiotics, // remove antibiotics is not selected
                                        navigate,
                                        careButtonCTACopy,
                                        "plan-intro",
                                        selectedPathwaySlug
                                      ),
                              })}
                              horizontalPadding="block"
                              fullWidth
                              verticalPadding="py-6"
                            />
                          </div>
                        </div>
                        <div className="text-center pt-4 pb-2 md:pb-0">
                          <LinkedText
                            onClick={analyticsClickHandler({
                              eventName:
                                eventNames.PLAN_CLICKED_LEARN_MORE_CARE,
                              eventArgs: {
                                ...getBaseAnalyticsEventArgs(),
                                location: "plan-intro",
                              },
                            })}
                            isLink
                            href={"/care/"}
                            noIcon
                            target="_self"
                          >
                            Learn more about clinical care
                          </LinkedText>
                        </div>
                      </>
                    ) : aLaCareEligible ? (
                      <>
                        <div className="bg-evvy-silverfish/[0.33] px-5 py-6 mt-6">
                          <div className="b2 regular">
                            {careWidgetCopy.CARE_ELIGIBLE}
                          </div>
                          <div className="mt-6">
                            <RectangularButton
                              text={`${careButtonCTACopy}`}
                              target="_self"
                              bgColorClass="bg-evvy-blue"
                              textColorClass="text-evvy-black"
                              onClick={() =>
                                navigate(
                                  transparentCareEnabled
                                    ? "/care/"
                                    : A_LA_CARE_SHOP_TREATMENTS_PAGE_PATH
                                )
                              }
                              horizontalPadding="block"
                              fullWidth
                              verticalPadding="py-6"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="t3 pt-4 regular">
                          {careWidgetCopy.CARE_INELIGIBLE}
                        </div>
                        <div className="mt-8">
                          <RectangularButton
                            text={"Schedule a free coaching call →"}
                            target="_self"
                            bgColorClass="bg-evvy-blue"
                            textColorClass="text-evvy-black"
                            isLink
                            href={GENERAL_COACHING_CALL_LINK}
                            onClick={analyticsClickHandler({
                              eventName: eventNames.CLICKED_TALK_EXPERT,
                              eventArgs: {
                                ...getBaseAnalyticsEventArgs(),
                                location: "plan-intro",
                              },
                            })}
                            horizontalPadding="block"
                            fullWidth
                            verticalPadding="py-6"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* Your plan section */}
                <div className="md:col-span-3">
                  <section className="flex justify-end mx-auto lg:grid pb-6 sm:pb-12">
                    <div className="text-center max-w-plan-column-md md:ml-28">
                      <div className="rounded-lg">
                        {/* your microbial goal  */}
                        <div
                          id="myplan"
                          className="bg-gradient-to-r from-evvy-pine-gradientLight to-evvy-pine p-8 mb-4 text-center rounded-lg"
                        >
                          <div className="text-highlighter-yellow t4 mb-2">
                            Microbiome Goal
                          </div>
                          <div className="t3 text-evvy-white">
                            <Markdown>{goal ? goal : ""}</Markdown>
                          </div>
                        </div>

                        <div className="bg-gradient-to-r from-evvy-pine-gradientLight to-evvy-pine p-2.5 text-center rounded-lg">
                          <div className="text-evvy-white t4">Your Plan</div>
                        </div>
                        <div
                          className={`bg-evvy-white px-5 sm:px-10 py-8 mb-4 rounded-b-lg`}
                        >
                          <div className="t3 medium">
                            {careEligible ? (
                              <>
                                <div>
                                  Review this plan with your provider or{" "}
                                </div>
                                <Link
                                  to="/care/"
                                  className="!underline underline-offset-4"
                                >
                                  get clinical care with Evvy
                                </Link>
                              </>
                            ) : (
                              <div>
                                We recommend you review this plan with your
                                provider
                              </div>
                            )}
                          </div>
                          {test &&
                            vpcrResults.has_results &&
                            vpcrResults.sti_positive && (
                              <StiPositiveBanner test={test} />
                            )}
                          {resistanceDetected && (
                            <AMRResistanceBanner
                              testHash={testHash}
                              resistanceResults={
                                vpcrResults.pcrresultresistance_set
                              }
                            />
                          )}
                          <div className="pb-8 mt-8">
                            <Scrollama onStepEnter={onStepEnter} offset={0.5}>
                              {microbialActions.map((data, i) => (
                                <Step data={i} key={`step-${i}`}>
                                  <div className="transition-opacity duration-700 opacity-20">
                                    <PlanCard
                                      number={i + 1}
                                      data={data}
                                      allData={microbialActions}
                                      totalSteps={microbialActions.length}
                                      openResearchModal={setModalData}
                                      openModalWith={setModalData}
                                      userIsMember={isMember}
                                      analyticsEventArgs={{
                                        slug: data.slug,
                                        groupName: data.get_group_display,
                                        planVersion: PLAN_VERSION,
                                        careEligible,
                                        carePrice,
                                        planProfileId: planProfileInfo?.id,
                                        location: "plan",
                                      }}
                                    />
                                  </div>
                                </Step>
                              ))}
                            </Scrollama>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </ErrorBoundary>
          </div>
        </div>
        {/* Eligible for care card! */}
        {!loading && careEligible && test && (
          <ErrorBoundary>
            {/* full height gradient */}
            <div
              className="flex flex-col py-8 px-4 md:py-8 bg-cover bg-no-repeat "
              style={{
                backgroundImage: isMobile
                  ? `url('/images/graphics/plan-bg-gradient-2.png')`
                  : `url('/images/graphics/plan-bg-gradient.png')`,
              }}
            >
              <CareCard
                consult={consult}
                analyticsEventArgs={getBaseAnalyticsEventArgs()}
                test={test}
                removeAntibiotics={removeAntibiotics}
                setRemoveAntibiotics={setRemoveAntibiotics}
                selectedPathwaySlug={selectedPathwaySlug}
                setSelectedPathwaySlug={setSelectedPathwaySlug}
              />
            </div>
          </ErrorBoundary>
        )}
        {/* More from Evvy's Experts Section! */}
        <div className="min-w-full bg-evvy-cream">
          <div className="max-w-7xl mx-auto px-4 md:px-0">
            <ErrorBoundary>
              <section
                title="more from experts"
                className="bg-evvy-cream py-8 md:py-16"
              >
                <h3 className="md:text-5xl text-center py-5 md:py-12">
                  More from Evvy's Experts
                </h3>
                <div className="grid md:grid-cols-2 md:space-x-16 mx-auto">
                  {/* Plan note and coaching CTA */}
                  <div className="md:col-span-1">
                    <PlanNote
                      username={username}
                      noteParts={noteParts}
                      coachingCallLink={GENERAL_COACHING_CALL_LINK}
                      analyticsEventArgs={getBaseAnalyticsEventArgs()}
                    />
                  </div>
                  {/* non microbial steps */}
                  {nonMicrobialActions && nonMicrobialActions?.length ? (
                    <div className="md:col-span-1">
                      <div className="bg-evvy-pine text-evvy-cream rounded-lg pt-12 pb-4 mt-6 mb-6 md:mt-0">
                        <div className="px-12">
                          <h2>{yourPlanCopy.NON_MICROBIAL_TITLE}</h2>
                          <div className="b2">
                            {yourPlanCopy.NON_MICROBIAL_DESC}
                          </div>
                        </div>
                        <div className="border-black rounded-lg bg-evvy-white mt-12 mx-6 mb-2">
                          {nonMicrobialActions &&
                            nonMicrobialActions.map((data, i) => (
                              <SymptomCard
                                key={`symptom-card-${i}`}
                                data={data}
                                noBorder={i === nonMicrobialActions.length}
                                openModalWith={setModalData}
                                analyticsEventArgs={{
                                  slug: data.slug,
                                  groupName: data.get_group_display,
                                  planVersion: PLAN_VERSION,
                                  careEligible,
                                  carePrice,
                                  planProfileId: planProfileInfo?.id,
                                  location: "plan",
                                }}
                              />
                            ))}
                        </div>
                      </div>
                      <div className="caption">{yourPlanCopy.DISCLAIMER}</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </ErrorBoundary>
          </div>
        </div>
      </Layout>
      {modalData && (
        <ErrorBoundary>
          <Modal
            closeModal={() => handleCloseModal(modalData.type)}
            widthClass={
              [
                MTYPES.NEXT_TEST,
                MTYPES.RESEARCH,
                MTYPES.LEAVE_REVIEW,
                MTYPES.PRICING,
              ].includes(modalData.type)
                ? "w-plan-column-sm"
                : "w-plan-column-md"
            }
            hasFocusItem
          >
            {MODAL_COMPONENTS.map(
              ({ type, ModalComponent, data, handleSubmit, subscription }) =>
                modalData.type === type && (
                  <ModalComponent
                    key={type}
                    data={data}
                    handleClose={handleCloseModal}
                    handleSubmit={handleSubmit}
                    subscription={subscription}
                  />
                )
            )}
          </Modal>
        </ErrorBoundary>
      )}
    </>
  );
};

export default UnfurledPlan;
