import classNames from "classnames";

import LinkedText from "./linkedText";
import { secondaryColors } from "../../utils/viz";
import { ReactComponent as ArrowDown } from "../../components/common/icons/arrow-down-no-tail.svg";
import { ReactComponent as ArrowUp } from "../../components/common/icons/arrow-up-no-tail.svg";

export const MoreLessButton = ({
  ctaText,
  onClick,
  expanded,
  useCaret = true,
}: {
  ctaText: string;
  onClick: () => void;
  expanded: boolean;
  useCaret?: boolean;
}) => {
  return (
    <LinkedText onClick={onClick} noIcon={useCaret} href="">
      {ctaText}
      <span className={classNames("ml-1 inline-block", { hidden: !useCaret })}>
        {expanded ? (
          <ArrowUp fill={secondaryColors["evvy-black"]} />
        ) : (
          <ArrowDown fill={secondaryColors["evvy-black"]} />
        )}
      </span>
    </LinkedText>
  );
};
