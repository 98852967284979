import React, { useEffect, useState } from "react";

import { ReactComponent as Checkmark } from "../common/icons/checkmark.svg";

// Green banner that floats towards bottom of screen before auto-dismissing

export const Banner: React.FC<{ text: string; timeoutSeconds: number }> = ({
  text,
  timeoutSeconds = 5,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, timeoutSeconds * 1000);

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="flex justify-center">
      <div className="fixed max-w-3xl bottom-10 z-50 py-4 px-5 mx-4 sm:mx-0 bg-evvy-pine text-white text-center rounded-md">
        <div className="flex my-auto">
          <Checkmark className="h-4 w-4 mr-2" />
          {text}
        </div>
      </div>
    </div>
  );
};
