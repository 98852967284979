/**
 * A small component for highlighted text
 * @param {string} bgColorClass this should be a string for a background color, such as `bg-evvy-black`, since in tailwind, the background of the text is referenced with `currentColor` which is set via a background class
 * @param {string} text text to be highlighted
 * @returns jsx of a highlighted span
 */

import React from "react";

const HighlightedText = ({ bgColorClass = "bg-evvy-blue", text = "" }) => (
  <span className={`${bgColorClass} px-1`}>{text}</span>
);

export default HighlightedText;
