import Markdown from "markdown-to-jsx";
import React from "react";
import { Link } from "react-router-dom";
import { GENERAL_COACHING_CALL_LINK } from "../../../pages/constants";
import { StartStiConsultIntakeButton } from "../../../pages/care/startStiConsultIntakeButton";

const MODAL_COPY = {
  PREGNANCY: {
    TITLE:
      "Based on your medical information, you aren't eligible for virtual care through Evvy at this time.",
    BODY: "Common reasons for ineligibility include pregnancy status or other health conditions that make it difficult for a provider to determine the right treatment without seeing you in person.",
    BODY_2:
      "We'd love to offer you a free call with an Evvy certified health coach to help walk through your results and what you can do next.",
  },
  STATE: {
    TITLE:
      "Based on your shipping information, you aren't eligible for care through Evvy at this time.",
    BODY: "Unfortunately, Evvy is not able to provide care in the state that you asked us to ship your treatments (yet!).",
    BODY_2:
      "In the meantime, we'd love to offer you a free call with an Evvy certified health coach to help walk through your results and what you can do next.",
  },
  SEVERE_SYMPTOMS: {
    TITLE:
      "Based on your medical information, you aren't eligible for virtual care through Evvy at this time.",
    BODY: "The symptoms you reported experiencing require in-person care.",
    BODY_2:
      "Your cost of treatment will be completely refunded. Please email care@evvy.com if you have any questions.",
  },
  NO_TREATMENT: {
    TITLE:
      "Based on your medical information, you aren't eligible for virtual care through Evvy at this time.",
    BODY: "Given the list of medications you have had an adverse reaction to or negative experience with, we cannot recommend an effective treatment protocol for you at this time.",
    BODY_2:
      "We'd love to offer you a free call with an Evvy certified health coach to help walk through your results and what you can do next.",
  },
  UNTREATED_STI: {
    TITLE:
      "Based on your medical information, you aren't eligible for virtual care through Evvy at this time.",
    BODY: `We are unable to treat you until you first complete STI treatment. Untreated STIs can lead to serious medical problems, like chronic pelvic pain, difficulty getting pregnant, and more. Its important for sexual partners to be treated too.`,
    BODY_2:
      "After completing STI treatment, please write to care@evvy.com to see if you are still eligible for clinical care with Evvy (or if you would prefer a refund).",
  },
};

const ExitConsultModal = ({ analyticsOnClick, consult, modalType }) => {
  const COPY = ["pregnancy", "medical-condition"].includes(modalType)
    ? MODAL_COPY.PREGNANCY
    : modalType === "severe-symptoms"
    ? MODAL_COPY.SEVERE_SYMPTOMS
    : modalType === "no-treatment"
    ? MODAL_COPY.NO_TREATMENT
    : modalType === "untreated-sti"
    ? MODAL_COPY.UNTREATED_STI
    : MODAL_COPY.STATE;

  const exitType = ["pregnancy", "medical-condition"].includes(modalType)
    ? "pregnancy"
    : modalType === "severe-symptoms"
    ? "severe symptoms"
    : modalType === "no-treatment"
    ? "no treatment"
    : modalType === "state"
    ? "address"
    : modalType;

  return (
    <div className="block bg-evvy-white p-10 text-center">
      <h5>{COPY.TITLE}</h5>
      {modalType === "severe-symptoms" ? (
        <React.Fragment>
          <div className="b1 mb-4">
            <Markdown>{COPY.BODY}</Markdown>
          </div>
          <div className="b1 mb-4">
            <Markdown>
              **We strongly recommend you seek in-person medical attention as
              soon as possible.**
            </Markdown>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {modalType !== "untreated-sti" && (
            <div className="b1 mb-4">
              <Markdown>
                **Your cost of treatment will be completely refunded.**
              </Markdown>
            </div>
          )}
          <div className="b1 mb-4">
            <Markdown>{COPY.BODY}</Markdown>
          </div>
        </React.Fragment>
      )}

      {COPY.BODY_2 && <div className="b1 mb-4">{COPY.BODY_2}</div>}
      <div className="flex flex-col sm:flex-row gap-4 mt-4">
        <div className="flex-1 max-w-xl mx-auto">
          <GoBackLink
            isSTIConsult={consult.type === "sti"}
            exitType={exitType}
            analyticsOnClick={analyticsOnClick}
          />
        </div>

        {modalType === "untreated-sti" &&
        consult.can_open_sti_treatment_consult ? (
          <div className="flex-1">
            <StartStiConsultIntakeButton
              ctaPrimary
              testHash={consult.test_hash}
            />
          </div>
        ) : (
          modalType !== "severe-symptoms" && (
            <a
              href={GENERAL_COACHING_CALL_LINK}
              target="_blank"
              rel="noreferrer"
              className="flex-1"
            >
              <button
                onClick={analyticsOnClick({
                  exitType,
                  ctaText: "coaching",
                })}
                className="t4 bg-evvy-blue text-evvy-black py-5 px-4 w-full rounded-sm font-semibold tracking-wider border border-evvy-blue focus:outline-none"
              >
                schedule coaching call
              </button>
            </a>
          )
        )}
      </div>
    </div>
  );
};

const GoBackLink = ({ isSTIConsult, exitType, analyticsOnClick }) => {
  return (
    <Link to={isSTIConsult ? `/` : `/care/`}>
      <button
        onClick={analyticsOnClick({ exitType, ctaText: "care" })}
        className="t4 bg-evvy-white text-evvy-black py-5 px-12 w-full rounded-sm font-semibold tracking-wider border border-evvy-black focus:outline-none"
      >
        {isSTIConsult ? "return to home" : "return to care"}
      </button>
    </Link>
  );
};

export default ExitConsultModal;
