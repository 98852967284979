import { Link, useNavigate } from "react-router-dom";

import TreatmentPreview from "./treatmentPreview";
import LinkedText from "../common/linkedText";
import { ReactComponent as UpperRightArrow } from "../../components/results_v2/icons/upper-right-arrow.svg";
import { secondaryColors } from "../../utils/viz";

import { getIsMobile } from "../../utils/general";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";
import Markdown from "markdown-to-jsx";
import { getCareIntakeUrl, getTreatmentPlanUrlFromConsult } from "./utils";
import {
  CONSULT_TURNAROUND_TIME,
  CONSULT_TURNAROUND_TIME_UNGATED,
  VIEW_RX_PRODUCTS,
} from "./constants";
import { Consult } from "../../types/care";
import { Test } from "../../types/test";
import { useMemo } from "react";
import { cn } from "../../utils/cn";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";

type ConsultCardProps = {
  consult: Consult;
  analyticsEventArgs: any;
  test?: Test;
  loading?: boolean;
};

const ConsultCard: React.FC<ConsultCardProps> = ({
  consult,
  analyticsEventArgs,
  test,
  loading = false,
}) => {
  const consultStatus = consult?.status;
  const currentUser = useLoggedInUser();
  const transparentCareEnabled = currentUser?.features?.transparentCare;
  const isMobile = getIsMobile();
  const planType = consult?.plan_type;
  const isUngatedRxConsult = consult?.type === "ungated-rx";
  const purchaseType = consult?.purchase_type;
  const isALaCare = purchaseType === "a-la-care";
  const bannerWarningStates = ["ID", "OR", "ST"];
  const consultPaidButIntakeIncomplete =
    (consultStatus === "OR" || consultStatus === "ST") && consult?.consult_paid;
  const showOrderNumbersOverTestID =
    consult?.type === "ungated-rx" &&
    !test &&
    consult?.orders &&
    consult?.orders?.length > 0;

  const inProgressPrimaryCopy = useMemo(() => {
    if (consultPaidButIntakeIncomplete) return "Complete your clinical intake";
    if (transparentCareEnabled)
      return "A provider is finalizing your treatments!";
    if (isALaCare) return "Your request is in the works";
    return `Your ${planType} program is in the works!`;
  }, [consultPaidButIntakeIncomplete, isALaCare, planType]);

  const completePrimaryCopy = transparentCareEnabled
    ? "A provider has prescribed your treatment!"
    : isALaCare
    ? "Your Rx products are ready!"
    : `Your ${planType} program <br>is ready!`;

  const primaryCopy =
    consultStatus === "CP" ? completePrimaryCopy : inProgressPrimaryCopy;

  const defaultSecondaryCopy = transparentCareEnabled
    ? "An Evvy-affiliated provider is finalizing your treatments. We’ll reach out over email if there are any questions."
    : isALaCare
    ? "An Evvy-affiliated provider is reviewing your request and will reach out if they have any questions."
    : `An Evvy-affiliated provider is reviewing your results and designing your custom ${planType} program.`;

  const secondaryCopy = (() => {
    if (consultStatus === "CP") {
      return "";
    }
    if (consultPaidButIntakeIncomplete) {
      return "An Evvy-affiliated provider cannot review your request until you complete your clinical intake.";
    }
    return defaultSecondaryCopy;
  })();

  const bannerCopy = useMemo(() => {
    if (consultStatus === "ID") {
      return "ID verification failed, <u>click here to reupload</u>";
    } else if (consultPaidButIntakeIncomplete) {
      return "Request cannot be processed until clinical intake is complete, <u>click here to complete</u>.";
    } else if (transparentCareEnabled) {
      return `You should hear back from your provider within ${
        isUngatedRxConsult
          ? CONSULT_TURNAROUND_TIME_UNGATED
          : CONSULT_TURNAROUND_TIME
      } with your finalized treatment.`;
    } else if (isALaCare) {
      return `This is typically complete within ${
        isUngatedRxConsult
          ? CONSULT_TURNAROUND_TIME_UNGATED
          : CONSULT_TURNAROUND_TIME
      }. Look out for an email from Evvy!`;
    } else {
      return `You will receive your ${planType} program (or a follow up question from your provider) within 24 hours.`;
    }
  }, [consultPaidButIntakeIncomplete, consultStatus, isALaCare, planType]);

  const threadStatus = consult?.is_closed ? "closed" : "open";
  const messageStatus = consult?.consultmessagethread?.has_unread_message
    ? "new"
    : consult?.consultmessagethread?.last_message_at
    ? "no_unread"
    : "no_messages";

  // route params
  const navigate = useNavigate();

  /* Actions */
  const redirectToPage = () => {
    if (consult?.status === "ID") {
      if (consult?.type === "ungated-rx") {
        navigate(getCareIntakeUrl("identity-verification/reupload", consult));
      } else {
        navigate(getCareIntakeUrl("identity-verification/redo", consult));
      }
    } else if (consultPaidButIntakeIncomplete)
      if (consult?.type === "ungated-rx") {
        navigate(getCareIntakeUrl("consent", consult));
      } else {
        navigate(getCareIntakeUrl("demographics", consult));
      }
  };

  const redirectToMessageThreadPage = () => {
    navigate(`/care/consults/${consult?.uid}/messages/`);
  };

  return (
    <div className="max-w-6xl w-full mx-auto pb-4 sm:pb-10 pt-4 sm:pt-8 px-4">
      <div className="bg-gradient-to-r from-evvy-blue-gradientLight to-evvy-blue p-1"></div>
      <div className="block px-6 sm:px-14 py-4 sm:py-14 bg-white rounded-lg sm:flex flex-wrap">
        <div className="flex-1 sm:pr-8 content-center mb-auto">
          <h3>
            <Markdown>{primaryCopy}</Markdown>
          </h3>

          {/* mobile version of the new message CTA */}
          {consult && consult?.consultmessagethread?.has_unread_message ? (
            <div className="flex sm:hidden my-8">
              <div
                onClick={analyticsClickHandler({
                  eventName: eventNames.CARE_CLICKED_VIEW_MESSAGE,
                  eventArgs: {
                    consultId: consult?.uid,
                    testHash: test?.hash,
                    location: "care",
                    threadStatus,
                    messageStatus,
                  },
                  clickHandler: redirectToMessageThreadPage,
                })}
                className={`bg-coral py-4 w-full text-center mx-auto ${
                  isMobile ? "b2 medium" : "t3"
                } cursor-pointer`}
              >
                You have a new message:{" "}
                <span className="underline">{"View ->"}</span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="my-4 whitespace-pre-wrap leading-6 list-disc">
            {secondaryCopy}
          </div>

          {consult &&
            consult?.allows_new_messages &&
            !consult?.consultmessagethread?.has_unread_message && (
              <div className="flex mb-8">
                <div
                  onClick={analyticsClickHandler({
                    eventName: eventNames.CARE_CLICKED_MESSAGE_PROVIDER,
                    eventArgs: analyticsEventArgs,
                    clickHandler: redirectToMessageThreadPage,
                  })}
                  className={`py-1 sm:py-4 w-full mx-auto cursor-pointer`}
                >
                  <div className={`list-disc pb-1.5`}>
                    Additional information to share?
                  </div>
                  <div className="text-sm uppercase tracking-wider font-semibold underline underline-offset-4">
                    Message your provider now
                  </div>
                </div>
              </div>
            )}
        </div>

        <div className="hidden sm:min-h-full w-[1px] bg-dust sm:flex" />

        <hr
          className="block sm:hidden my-8"
          style={{ backgroundColor: "#e5e7eb" }}
        />

        <div className="flex-1 mt-8 sm:mt-0 sm:pl-8">
          {!loading && (
            <div className="grid grid-cols-4 sm:grid-cols-3 gap-4 mb-4">
              <div className="col-span-2 sm:col-span-1">
                <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                  {showOrderNumbersOverTestID ? "Order #" : "Test ID"}
                </div>
                <div className="font-semibold">
                  {showOrderNumbersOverTestID
                    ? consult?.orders?.map((order) => (
                        <div
                          className="mb-1 last:mb-0"
                          key={order.order_number}
                        >
                          {order.order_number}
                        </div>
                      ))
                    : test?.pretty_hash?.toUpperCase()}
                </div>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500 whitespace-nowrap">
                  {showOrderNumbersOverTestID ? "Order" : "Test"} Date
                </div>
                <div className="font-semibold">
                  {showOrderNumbersOverTestID
                    ? consult?.orders?.map((order) => (
                        <div className="mb-1 last:mb-0" key={order.create_date}>
                          {new Date(order.create_date).toLocaleDateString()}
                        </div>
                      ))
                    : test?.healthcontext?.sample_taken_at}
                </div>
              </div>
              {!["IN", "OR"].includes(consultStatus) && (
                <div className="col-span-2 sm:col-span-1">
                  <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
                    {transparentCareEnabled
                      ? "Clinical Intake"
                      : "Provider Intake"}
                  </div>
                  <Link
                    className="underline persistSize cursor-pointer inline-block font-semibold"
                    onClick={analyticsClickHandler({
                      eventName: eventNames.CARE_CLICKED_VIEW_INTAKE,
                      eventArgs: {
                        ...analyticsEventArgs,
                        testOrder: test?.test_order,
                      },
                    })}
                    to={
                      consult?.type === "ungated-rx"
                        ? getCareIntakeUrl("consent", consult)
                        : getCareIntakeUrl("demographics", consult)
                    }
                  >
                    View
                  </Link>
                </div>
              )}
            </div>
          )}
          {consult && consult?.consultmessagethread?.has_unread_message ? (
            <div className="hidden sm:flex my-8">
              <div
                onClick={analyticsClickHandler({
                  eventName: eventNames.CARE_CLICKED_VIEW_MESSAGE,
                  eventArgs: {
                    consultId: consult?.uid,
                    testHash: test?.hash,
                    location: "care",
                    threadStatus,
                    messageStatus,
                  },
                  clickHandler: redirectToMessageThreadPage,
                })}
                className={`bg-coral py-4 w-full text-center mx-auto ${
                  isMobile ? "b2 medium" : "t3"
                } cursor-pointer`}
              >
                You have a new message:{" "}
                <span className="underline underline-offset-4">
                  {"View ->"}
                </span>
              </div>
            </div>
          ) : consult && !["CP"].includes(consult?.status) ? (
            <div className="flex my-8">
              <div
                onClick={redirectToPage}
                className={cn("p-4 px-4 mt-8 sm:px-14 text-center mx-auto", {
                  "b2 medium": isMobile,
                  t3: !isMobile,
                  "cursor-pointer": bannerWarningStates.includes(consultStatus),
                  "bg-coral": bannerWarningStates.includes(consultStatus),
                  "bg-evvy-silverfish":
                    !bannerWarningStates.includes(consultStatus),
                })}
              >
                <Markdown>{bannerCopy}</Markdown>
              </div>
            </div>
          ) : (
            ""
          )}

          {consult && consult?.status === "CP" && (
            <TreatmentPreview
              consult={consult}
              isMobile={isMobile}
              transparentCareEnabled={transparentCareEnabled}
            />
          )}
          {consult && consult?.status === "CP" && (
            <Link
              onClick={analyticsClickHandler({
                eventName: eventNames.CARE_CLICKED_VIEW_TREATMENT_PLAN,
                eventArgs: {
                  ...analyticsEventArgs,
                  testOrder: test?.test_order,
                },
              })}
              to={getTreatmentPlanUrlFromConsult(consult)}
            >
              <div className="w-full p-8 my-8 t4 text-evvy-black text-center bg-evvy-blue">
                {transparentCareEnabled
                  ? "View my treatments →"
                  : isALaCare
                  ? VIEW_RX_PRODUCTS
                  : `View ${planType} Program →`}
              </div>
            </Link>
          )}
          {consult &&
            consult?.status === "CP" &&
            consult?.prescription_tracking_link && (
              <div className="mt-6 text-center">
                <LinkedText
                  onClick={analyticsClickHandler({
                    eventName: eventNames.CARE_CLICKED_TRACK_ORDER,
                    eventArgs: analyticsEventArgs,
                  })}
                  noTopMargin
                  noIcon
                  isLink
                  href={consult?.prescription_tracking_link}
                >
                  TRACK YOUR ORDER
                  <span className="ml-2 inline-block">
                    <UpperRightArrow fill={secondaryColors["evvy-black"]} />
                  </span>
                </LinkedText>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ConsultCard;
