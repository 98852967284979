import { createSlice } from "@reduxjs/toolkit";
import { ResultsSliceState } from "../../types/subscription";

const initialResultsSliceState: ResultsSliceState = {
  value: {
    hovered: null, // hovered bacteria id
    selected: null, // selected bacteria id
    filtered: null, // filtered health association
    scoreGradientHasTransitioned: [], // results protective/disruptive score krystal has transitioned
    selectedType: null, // GO, BA, UN, or NE
  },
};

export const resultsSlice = createSlice({
  name: "results",
  initialState: initialResultsSliceState,
  reducers: {
    // update hovered bacteria id
    updateHover: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value.hovered = action.payload;
    },
    // update selected bacteria id (new id or set to null to unselect)
    updateSelect: (state, action) => {
      state.value.selected = action.payload;
    },
    // update selected health association filter (new string or set to null to clear filter)
    updateFilter: (state, action) => {
      state.value.filtered = action.payload;
    },
    // update whether or not the score gradient has transitioned yet on this page render
    updateScoreGradientHasTransitioned: (state, action) => {
      state.value.scoreGradientHasTransitioned = action.payload;
    },
    // update whether or not the score gradient has transitioned yet on this page render
    updateSelectedType: (state, action) => {
      state.value.selectedType = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateHover,
  updateSelect,
  updateFilter,
  updateScoreGradientHasTransitioned,
  updateSelectedType,
} = resultsSlice.actions;

export default resultsSlice.reducer;
