import Tippy from "@tippyjs/react/headless";
import { cn } from "../../../utils/cn";
import { transparentCarePhaseColorMap } from "../../../utils/colors";
import { ReactComponent as Info } from "../../plan_unfurled/icons/info.svg";
import { TransparentCareProductConfig } from "../../../types/care";
import { getPurposeTagText } from "../utils";
import classNames from "classnames";

const Tooltip = () => (
  <Tippy
    arrow
    render={() => (
      <div
        id="tooltip"
        role="tooltip"
        className="leading-snug bg-evvy-pine p-3 rounded-md max-w-sm text-evvy-white"
        tabIndex={-1}
      >
        Eligibility for this treatment is limited to 21 days after receiving
        results.
      </div>
    )}
    placement="bottom"
    animation={false}
  >
    <Info
      stroke="currentColor"
      fill="currentColor"
      className="ml-1.5 w-4 h-4 text-evvy-black-dull/75"
    />
  </Tippy>
);

const TransparentCarePhasePill: React.FC<{
  phase: string;
  className?: string;
  daysUntilExpiry?: number;
  productConfig?: TransparentCareProductConfig;
}> = ({ phase, className, daysUntilExpiry, productConfig }) => (
  <div
    className={cn(
      "uppercase text-sm font-semibold rounded-full border border-black border-opacity-5 px-4 xs:px-2.5 py-1 xs:py-0.5 z-10 flex items-center justify-center",
      { "max-w-min": !daysUntilExpiry },
      transparentCarePhaseColorMap[
        phase as keyof typeof transparentCarePhaseColorMap // hacky type assertion to appease TS
      ].full,
      className
    )}
  >
    <span className={classNames({ "sm:block hidden": daysUntilExpiry })}>
      {getPurposeTagText(phase)}{" "}
    </span>
    {/* Only show the category on desktop (e.g. "Treat - 21 days left" is just "21 days left" on mobile) */}
    {daysUntilExpiry && (
      <span
        className={classNames({
          "sm:block hidden ml-1 mr-1": daysUntilExpiry,
        })}
      >
        -
      </span>
    )}
    <span> {daysUntilExpiry ? `${daysUntilExpiry} days left` : ""} </span>
    {phase === "treat" && productConfig === "individual" && daysUntilExpiry && (
      <Tooltip />
    )}
  </div>
);

export default TransparentCarePhasePill;
