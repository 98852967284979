import { useState, useEffect, useCallback } from "react";
import { careService } from "../../services/care";
import { Prescription } from "../../types/care";

export const useTreatment = (treatmentSlug?: string) => {
  const [data, setData] = useState<Prescription>();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  const fetchTreatment = useCallback(async () => {
    if (treatmentSlug) {
      setIsLoading(true);
      try {
        const response = await careService.asyncFetchTreatmentProductInfo(
          treatmentSlug
        );
        setData(response.data);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [treatmentSlug]);

  useEffect(() => {
    if (treatmentSlug) fetchTreatment();
  }, [treatmentSlug, fetchTreatment, refetch]);

  const refetchTreatment = () => {
    setRefetch((prev) => !prev);
  };

  return {
    treatment: data,
    error,
    isLoading,
    refetchTreatment,
  };
};
