import Markdown from "markdown-to-jsx";

import { ReactComponent as Info } from "../plan_unfurled/icons/info.svg";
import Tippy from "@tippyjs/react/headless";

import { Test } from "../../types/test";
import { getIsMobile } from "../../utils/general";
import { useNavigate } from "react-router-dom";
import { daysElapsed } from "../../utils/datetime";
import { useMemo } from "react";
import { Consult } from "../../types/care";
import { cn } from "../../utils/cn";

/** TOOLTIP
 * This tooltip is on rollover of the info button when viewing the explanation around eligibility */
const Tooltip = () => (
  <Tippy
    arrow
    render={() => (
      <div
        id="tooltip"
        role="tooltip"
        className="leading-snug bg-evvy-pine p-3 rounded-md max-w-sm text-evvy-white"
        tabIndex={-1}
      >
        To ensure your program is based on recent test results, eligibility for
        the Complete Treatment Program is limited to 21 days after receiving
        results.
      </div>
    )}
    placement="bottom"
    animation={false}
  >
    <Info
      stroke="currentColor"
      fill="currentColor"
      className="ml-1.5 w-4 h-4 text-evvy-black-dull/75"
    />
  </Tippy>
);

const getDaysRemainingCopy = (daysRemaining: number) => {
  if (daysRemaining <= 0) return "Less than 1 day";
  if (daysRemaining === 1) return "1 day";
  return `${daysRemaining} days`;
};

export const EligibilityCountdownBanner = ({
  test,
  goToCareOnClick = false,
}: {
  test: Test;
  goToCareOnClick?: boolean;
}) => {
  const navigate = useNavigate();

  const isMobile = getIsMobile();

  const careExpiresAt = test.care_eligibility_expires_at;
  const daysRemainingToOptIn = careExpiresAt
    ? daysElapsed(Date.now(), careExpiresAt) + 1
    : 0;
  const daysRemainingCopy = getDaysRemainingCopy(daysRemainingToOptIn);

  const onClickBanner = () => {
    if (goToCareOnClick) {
      navigate("/care/");
    }
  };

  return (
    <div className="mb-2">
      <div
        className={cn(
          "flex items-center justify-center text-center b2 regular py-3 px-3 bg-evvy-white",
          {
            "rounded-b-xl": !isMobile,
            "cursor-pointer": goToCareOnClick,
            "rounded-b-lg": isMobile,
          }
        )}
        onClick={onClickBanner}
      >
        <Markdown>
          {"**" +
            daysRemainingCopy +
            (isMobile ? " left" : " remaining") +
            ` to purchase your Complete Treatment Program**`}
        </Markdown>
        <Tooltip />
      </div>
    </div>
  );
};
