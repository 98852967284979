import { postRequest, getRequest } from "../utils/axios";
import { toQueryString } from "../utils/urls";
import { omit } from "lodash";

export const compareService = {
  fetchData,
};

function fetchData(params, onSuccess, onError) {
  const url = `/api/v1/compare/${toQueryString({
    compare_id: params.hash,
    ...omit(params, "hash"),
  })}`;
  return getRequest(
    url,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}
