/**
 * a results table composed of a date picker (header), user results (upper table/1st section), user symptoms (lower table/2nd section)
 * @param {object} baseTestsData an object containing individual user tests, used for the date picker
 * @param {object} compareTestData an object containing relevant user results
 * @param {string} cstType a string containing the Community State Type (Vaginal Microbiome Type)
 */

import React, { useState } from "react";

// utils
import {
  categoryToColorMap,
  secondaryColors,
  bacteriaTypeToCodeMap,
  formatters,
} from "../../../utils/viz";
import {
  smellCodes,
  dischargeCodes,
  treatmentCodes,
  diagnosesCodes,
} from "../../../utils/copy";
import { timeSincePeriod } from "../../../pages/results_v2/helpers";
import { sageDatabaseUpdateCopy } from "../../../pages/constants";

// local components
import TrendsTableCategoryRow from "./trendsTableCategoryRow";
import TrendsTableHistoryRow from "./trendsTableHistoryRow";
import DropDownMenu from "./dropDown";

//icons
import { ReactComponent as ArrowDown } from "../../common/icons/arrow-down-no-tail.svg";
import { ReactComponent as ArrowUp } from "../../common/icons/arrow-up-no-tail.svg";

// lib
import {
  DATA_TYPES,
  TYPES_DICTIONARY,
  TREND_DIRECTION,
} from "../../../pages/results_v2/constants";
import { analyticsClickHandler } from "../../../utils/analytics/helpers";
import {
  eventHandlers,
  eventNames,
} from "../../../utils/analytics/customEventTracking";
import { useResponsiveSize } from "../../../utils/customHooks";

const TrendsTableContainer = ({
  baseTestsData,
  compareTestData,
  config,
  base,
  setBase,
  setBaseLoading,
  compare,
  setCompare,
  setCompareLoading,
  currentTestHash,
  earliestTest,
}) => {
  /* state management */

  // Retrieves window width on resize
  const [[width, height, isMobile]] = useResponsiveSize(window.innerWidth, 700);

  // state controller for whether all bacteria table rows should be visible
  const [showAllRows, setShowAllRows] = useState(false);

  // TO DO: (export to constants?)
  // array of bacteria names
  const bacteriaCodes = ["protective", "disruptive", "variable", "unknown"];

  const cstType = (test) => {
    return test && test.default
      ? test.common_community_type
      : TYPES_DICTIONARY[test?.common_community_type] || "";
  };

  // the percent value of a bacteira in compare data results
  const percentValue = (test, bacteriaType) => {
    return (
      test?.bacteria_valences?.[bacteriaTypeToCodeMap[bacteriaType]]?.percent ||
      0
    );
  };

  // the calculated percent difference between current and previous/compare tests
  const percentDiff = (bacteriaType) => {
    return (
      (baseTestsData?.bacteria_valences?.[bacteriaTypeToCodeMap[bacteriaType]]
        ?.percent || 0) -
      (compareTestData?.bacteria_valences?.[bacteriaTypeToCodeMap[bacteriaType]]
        ?.percent || 0)
    );
  };

  const getTrendDirection = (percentChange) => {
    if (percentChange === 0) {
      return TREND_DIRECTION.NEUTRAL;
    } else if (percentChange < 0) {
      return TREND_DIRECTION.DOWN;
    }
    if (percentChange > 0) {
      return TREND_DIRECTION.UP;
    }
  };

  // bacteria category color
  const bacteriaTypeColor = (bacteriaType) => {
    return categoryToColorMap[bacteriaType].fill;
  };

  // breakdown of bacteria types in user results
  const bacteriaDetails = (test, bacteriaType) => {
    return (
      test?.bacteria_valences?.[bacteriaTypeToCodeMap[bacteriaType]]
        ?.bacteria || []
    );
  };

  const dominantMicrobe = (test) => {
    return test?.dominant_bacteria_name || "";
  };

  const symptomSmell = (test) => {
    if (test?.health_history?.symptoms_vaginal_smell.length > 0) {
      return test.default
        ? test?.health_history?.symptoms_vaginal_smell
        : test?.health_history?.symptoms_vaginal_smell
            .map((symptom) => smellCodes[symptom])
            .join(", ");
    } else {
      return "Nothing unusual";
    }
  };

  const symptomDischarge = (test) => {
    if (test?.health_history?.symptoms_vaginal_discharge.length > 0) {
      return test.default
        ? test?.health_history?.symptoms_vaginal_discharge
        : test?.health_history?.symptoms_vaginal_discharge
            .map((symptom) => dischargeCodes[symptom])
            .join(", ");
    } else {
      return "Normal";
    }
  };

  // handle data array calls for treatments, diagnoses
  const treatments = (test) => {
    if (test?.health_history?.treatments.length > 0) {
      return test.default
        ? test.health_history?.treatments
        : test?.health_history?.treatments
            .map((symptom) => treatmentCodes[symptom])
            .join(", ");
    } else {
      return "Nothing";
    }
  };

  const diagnoses = (test) => {
    if (test?.health_history?.diagnoses.length > 0) {
      return test.default
        ? test.health_history?.diagnoses
        : test?.health_history?.diagnoses
            .map((symptom) => diagnosesCodes[symptom])
            .join(", ");
    } else {
      return "Nothing";
    }
  };

  const getValueFromBase = (key) => {
    return base.selectedDatatype ? config[base.selectedDatatype][key] : "";
  };

  const getValueFromCompare = (key) => {
    return compare.selectedDatatype
      ? config[compare.selectedDatatype]?.[key]
      : "";
  };

  return (
    <div className="w-full bg-white rounded-lg mt-6 mb-8">
      <div
        className="grid gap-8 mx-auto px-4 py-7 pb-4 text-left"
        style={{
          gridTemplateColumns: isMobile ? "1fr 1fr" : ".75fr 1fr 1fr .25fr",
        }}
      >
        {!isMobile && <div className="mx-4 mb-4 md:mb-0" />}
        <div className="mx-auto mb-4 md:mb-0 w-full text-base md:b1 semibold">
          <DropDownMenu
            disabled={getValueFromCompare("options").length <= 1}
            options={getValueFromCompare("options")}
            valAcc={getValueFromCompare("valAcc")}
            labelAcc={getValueFromCompare("labelAcc")}
            onSubmit={(d) => {
              setCompareLoading(true);
              setCompare((prevCompare) => ({
                ...prevCompare,
                selectedOption: d,
                selectedQueryParams: {
                  hash: config[compare.selectedDatatype].valAcc(d),
                  compare_type: config[compare.selectedDatatype].compare_type,
                },
                testId: d.pretty_hash,
              }));
              eventHandlers[eventNames.RESULTS_CHANGED_TEST_DATE]({
                testOrder: compare?.selectedOption?.test_order,
                testHash: compare?.selectedOption?.hash,
                cst: compareTestData?.common_community_type,
                side: "left",
              });
            }}
            hasPlaceholder
            currentSelection={
              compare.selectedOption === DATA_TYPES.DEFAULT
                ? "DEFAULT"
                : earliestTest
                ? null
                : compare.selectedOption
            }
          />
        </div>
        <div className="mx-auto mb-4 md:mb-0 w-full text-base md:b1 semibold">
          <DropDownMenu
            disabled={getValueFromBase("options").length <= 1}
            options={getValueFromBase("options")}
            valAcc={getValueFromBase("valAcc")}
            labelAcc={getValueFromBase("labelAcc")}
            onSubmit={(d) => {
              setBaseLoading(true);
              setBase((prevBase) => ({
                ...prevBase,
                selectedOption: d,
                selectedQueryParams: {
                  hash: config[base.selectedDatatype].valAcc(d),
                  compare_type: config[base.selectedDatatype].compare_type,
                },
                testId: d.pretty_hash,
              }));
              eventHandlers[eventNames.RESULTS_CHANGED_TEST_DATE]({
                testOrder: base?.selectedOption?.test_order,
                testHash: base?.selectedOption?.hash,
                cst: baseTestsData?.common_community_type,
                side: "right",
              });
            }}
            hasPlaceholder
            currentSelection={
              base.selectedOption === DATA_TYPES.DEFAULT
                ? "DEFAULT"
                : base.selectedOption
            }
          />
        </div>
      </div>

      <div className="px-5 md:px-7">
        {/* map through bacteriaCodes for bacteria type */}
        {bacteriaCodes.map((d, i) => (
          <TrendsTableCategoryRow
            key={`${d}-${i}`}
            bacteriaType={d}
            typeColor={bacteriaTypeColor(d)}
            percentCurrent={formatters.numShort(percentValue(baseTestsData, d))}
            percentLast={formatters.numShort(percentValue(compareTestData, d))}
            percentDiff={
              compareTestData?.default || !compareTestData
                ? 0
                : Math.abs(formatters.numShort(percentDiff(d)).replace("−", ""))
            }
            trendDirection={
              compareTestData?.default || !compareTestData
                ? TREND_DIRECTION.NEUTRAL
                : getTrendDirection(percentDiff(d))
            }
            currentBacteria={bacteriaDetails(baseTestsData, d)}
            compareBacteria={bacteriaDetails(compareTestData, d)}
            newSide={
              baseTestsData?.test_version === "sage" ? "base" : "compare"
            }
            compareDiffVersion={
              compareTestData &&
              baseTestsData &&
              compareTestData?.common_community_type &&
              compareTestData.test_version !== baseTestsData.test_version
            }
            analyticsOnClick={(clickHandler) =>
              analyticsClickHandler({
                eventName: eventNames.RESULTS_EXPAND_MICROBE_CATEGORY_BREAKDOWN,
                eventArgs: {
                  testOrder: base?.selectedOption?.test_order,
                  testHash: base?.selectedOption?.hash,
                  cst: baseTestsData?.common_community_type,
                  microbeCategory: d,
                },
                clickHandler,
              })
            }
          />
        ))}
      </div>

      <div className="px-5 md:px-7">
        {compareTestData?.common_community_type &&
          baseTestsData &&
          compareTestData.test_version !== baseTestsData.test_version && (
            <div className="block sm:hidden bg-gray-200 p-2 text-[12px] sm:text-sm rounded-md text-left">
              {sageDatabaseUpdateCopy}
            </div>
          )}
        {/* set symptomCategory to constants */}
        <TrendsTableHistoryRow
          symptomCategory={"CST Type"}
          symptomDetailCurrent={cstType(baseTestsData)}
          symptomDetailLast={cstType(compareTestData)}
        />
        <TrendsTableHistoryRow
          symptomCategory={"Dominant microbe"}
          symptomDetailCurrent={dominantMicrobe(baseTestsData)}
          symptomDetailLast={dominantMicrobe(compareTestData)}
        />
        <TrendsTableHistoryRow
          symptomCategory={"Your period started"}
          symptomDetailCurrent={timeSincePeriod(baseTestsData, base)}
          symptomDetailLast={
            compareTestData?.default || !compareTestData
              ? compareTestData?.health_history?.basics_last_period_date
              : timeSincePeriod(compareTestData, compare)
          }
          shortlist={!showAllRows}
        />
        <TrendsTableHistoryRow
          symptomCategory={"Vaginal smell"}
          symptomDetailCurrent={symptomSmell(baseTestsData)}
          symptomDetailLast={
            !compareTestData ? "" : symptomSmell(compareTestData)
          }
          hide={!showAllRows}
        />
        <TrendsTableHistoryRow
          symptomCategory={"Discharge"}
          symptomDetailCurrent={symptomDischarge(baseTestsData)}
          symptomDetailLast={
            !compareTestData ? "" : symptomDischarge(compareTestData)
          }
          hide={!showAllRows}
        />
        <TrendsTableHistoryRow
          symptomCategory={"Recently used"}
          symptomDetailCurrent={treatments(baseTestsData)}
          symptomDetailLast={
            !compareTestData ? "" : treatments(compareTestData)
          }
          hide={!showAllRows}
        />
        <TrendsTableHistoryRow
          symptomCategory={"Diagnosed with"}
          symptomDetailCurrent={diagnoses(baseTestsData)}
          symptomDetailLast={!compareTestData ? "" : diagnoses(compareTestData)}
          hide={!showAllRows}
        />
      </div>

      <div className="grid grid-cols-3 pb-4 pointer-events-auto">
        <div className="col-span-1"></div>
        <button
          className="col-span-1 unset flex items-center text-base font-semibold mt-6 mb-4 mx-auto"
          onClick={
            showAllRows
              ? () => setShowAllRows(!showAllRows)
              : analyticsClickHandler({
                  eventName: eventNames.RESULTS_EXPAND_HEALTH_HISTORY,
                  eventArgs: {
                    testOrder: base?.selectedOption?.test_order,
                    testHash: base?.selectedOption?.hash,
                    cst: baseTestsData?.common_community_type,
                  },
                  clickHandler: () => setShowAllRows(!showAllRows),
                })
          }
        >
          {showAllRows ? (
            <>
              Hide
              <ArrowUp
                fill={secondaryColors["evvy-blue"]}
                className="ml-3 mt-1"
              />
            </>
          ) : (
            <>
              View all
              <ArrowDown
                fill={secondaryColors["evvy-blue"]}
                className="ml-3 mt-1"
              />
            </>
          )}
        </button>
        <div className="col-span-1 justify-self-end mr-7 mt-4">
          {compareTestData?.common_community_type &&
            baseTestsData &&
            compareTestData.test_version !== baseTestsData.test_version && (
              <div className="hidden sm:block bg-gray-200 p-2 text-[12px] sm:text-sm rounded-md text-left">
                {sageDatabaseUpdateCopy}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default TrendsTableContainer;
