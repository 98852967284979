import React from "react";
import { useEffect, useReducer, useState } from "react";

// types
import {
  CadenceOptionType,
  CalendarTreatment,
  TreatmentAdjustmentType,
} from "../../../types/care";

// components
import { ReactComponent as Pills } from "../../plan_unfurled/icons/pills.svg";
import { ReactComponent as AlertTriangle } from "../../common/icons/alert-triangle.svg";
import RectangularButton from "../../common/rectangularButton";

// utils
import { careService } from "../../../services/care";
import {
  formatDate,
  strDateToStrDateDayBeforeOrAfter,
  strDateToTimestamp,
  stripTimezone,
} from "../../../utils/datetime";

import { CARE_SUPPORT_EMAIL } from "../../../pages/constants";
import { useBaseAnalyticsArgs } from "../../../hooks/useBaseAnalyticsArgs";
import {
  sendEditTreatmentClickedBack,
  sendEditTreatmentClickedNext,
  sendEditTreatmentSelectCadenceOption,
  sendEditTreatmentSelectReason,
  sendEditTreatmentSelectType,
} from "../../../utils/analytics/customEventTracking";
import { SimpleDropdownSelect } from "../../common/simpleDropdownSelect";
import { differenceInDays } from "date-fns";

const SUBTITLE =
  "Need to adjust your treatment plan? We can change it to meet your needs.";

const SUPPORTED_ADJUSTMENTS = ["remove", "delay", "cadence"];

const ADJUSTMENT_TYPE_TO_DISPLAY = {
  remove: {
    slug: "remove",
    icon: <AlertTriangle className="stroke-black h-6 w-6" />,
    text: "Stop or remove treatment",
  },
  delay: {
    slug: "delay",
    icon: <Pills className="stroke-black h-6 w-6" />,
    text: "Delay treatment start",
  },
  shorten: {
    slug: "remove",
    icon: <AlertTriangle className="stroke-black h-6 w-6" />,
    text: "Stop or remove treatment",
  },
  add: {
    slug: "add",
    icon: <Pills className="stroke-black h-6 w-6" />,
    text: "Add treatment back to calendar",
  },
  cadence: {
    slug: "cadence",
    icon: <Pills className="stroke-black h-6 w-6" />,
    text: "Change frequency",
  },
};

function stepsReducer(
  steps: string[],
  action: {
    type: string;
    adjustmentType?: TreatmentAdjustmentType;
  }
) {
  const currentStep = steps[steps.length - 1];
  const newSteps = [...steps];
  switch (action.type) {
    case "next":
      if (
        currentStep === "select-adjustment" &&
        action.adjustmentType === "remove"
      ) {
        newSteps.push("remove-screen");
      } else if (
        currentStep === "select-adjustment" &&
        action.adjustmentType === "delay"
      ) {
        newSteps.push("delay-screen");
      } else if (
        currentStep === "select-adjustment" &&
        action.adjustmentType === "cadence"
      ) {
        newSteps.push("cadence-screen");
      } else if (
        currentStep === "remove-screen" &&
        action.adjustmentType === "shorten"
      ) {
        newSteps.push("shorten-screen");
      } else {
        newSteps.push("reason-why");
      }
      return newSteps;
    case "back":
      return newSteps.slice(0, -1);
    default:
      return newSteps;
  }
}

const EditCalendarTreatmentHeader: React.FC<{
  title: string;
  treatment?: CalendarTreatment;
  subtitle?: string;
  dateLabel?: string;
}> = ({ title, treatment, subtitle, dateLabel }) => {
  return (
    <>
      <h3 className="mt-4">{title}</h3>
      {treatment && <TreatmentCard treatment={treatment} />}
      {subtitle && <div className="mb-4 b2">{subtitle}</div>}
      {dateLabel && <div className="b2 medium mb-4">{dateLabel}</div>}
    </>
  );
};

const OptionSelector: React.FC<{
  title: string;
  subtitle: string;
  options: Array<{
    slug: string;
    display?: string;
    icon?: React.ReactNode;
  }>;
  selectedOption?: string;
  onSelect: (slug: string) => void;
  treatment: CalendarTreatment;
  analyticsEvent: (args: any) => void;
}> = ({
  title,
  subtitle,
  options,
  selectedOption,
  onSelect,
  treatment,
  analyticsEvent,
}) => {
  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  return (
    <>
      <EditCalendarTreatmentHeader
        title={title}
        treatment={treatment}
        subtitle={subtitle}
      />
      <div className="space-y-4">
        {options.map((adjustmentOption, index) => (
          <div
            key={`adjustment-${index}`}
            className={`rounded-md p-4 border-[1.5px] ${
              selectedOption === adjustmentOption.slug
                ? "border-evvy-blue bg-evvy-blue/10"
                : "border-slate-300 bg-white"
            } hover:border-evvy-blue hover:bg-evvy-blue/10 cursor-pointer`}
            onClick={() => {
              onSelect(adjustmentOption.slug);
              analyticsEvent({
                ...baseAnalyticsArgs,
                calendarTreatmentId: treatment.id,
                prescriptionSlug: treatment.product.slug,
                editType: adjustmentOption.slug,
              });
            }}
          >
            <div className="flex ml-6 md:ml-0">
              {adjustmentOption.icon && (
                <div className="mr-3">{adjustmentOption.icon}</div>
              )}
              <div className="my-auto b2">{adjustmentOption.display}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const DateSelector: React.FC<{
  title: string;
  subtitle: string;
  dateLabel: string;
  treatment: CalendarTreatment;
  selectedDate: Date | null | undefined;
  setSelectedDate: (date?: Date) => void;
  minDate: string;
  maxDate: string;
}> = ({
  title,
  subtitle,
  dateLabel,
  treatment,
  selectedDate,
  setSelectedDate,
  minDate,
  maxDate,
}) => {
  return (
    <>
      <EditCalendarTreatmentHeader
        title={title}
        treatment={treatment}
        subtitle={subtitle}
        dateLabel={dateLabel}
      />
      <input
        id="treatmentdate"
        name="treatmentdate"
        type="date"
        value={selectedDate ? stripTimezone(selectedDate) : ""}
        min={minDate}
        max={maxDate}
        onChange={(e) => {
          if (e.target.valueAsDate) {
            const localDate = new Date(
              e.target.valueAsDate.getTime() +
                e.target.valueAsDate.getTimezoneOffset() * 60000
            );
            setSelectedDate(localDate);
          }
        }}
        autoComplete="off"
        required
        style={{ fontSize: "16px" }}
        className="mb-4 appearance-none block w-full p-3 border rounded-md border-evvy-blue rounded-xs shadow-sm
          placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
      />
    </>
  );
};

const EDIT_MEDICATION = "Edit medication";

const SelectAdjustmentView: React.FC<{
  treatment: CalendarTreatment;
  adjustmentType?: TreatmentAdjustmentType;
  setAdjustmentType: (adjustmentType: TreatmentAdjustmentType) => void;
}> = ({ treatment, adjustmentType, setAdjustmentType }) => {
  var allowedAdjustments = treatment.allowed_adjustments.filter((adj) =>
    SUPPORTED_ADJUSTMENTS.includes(adj)
  );
  //   if treatment is currently deleted, only allow adding it back
  if (treatment.deleted) {
    allowedAdjustments = ["add"];
  }
  // sort allowed adjustments alphabetically
  allowedAdjustments.sort();
  var allowedAdjustmentsDisplay = allowedAdjustments.map(
    (adjustment) => ADJUSTMENT_TYPE_TO_DISPLAY[adjustment]
  );
  // make above unique but still an array
  allowedAdjustmentsDisplay = [...new Set(allowedAdjustmentsDisplay)];

  return (
    <OptionSelector
      title={EDIT_MEDICATION}
      subtitle={SUBTITLE}
      options={allowedAdjustmentsDisplay.map((adjustment) => ({
        slug: adjustment.slug,
        display: adjustment.text,
        icon: adjustment.icon,
      }))}
      selectedOption={adjustmentType}
      onSelect={(slug) => setAdjustmentType(slug as TreatmentAdjustmentType)}
      treatment={treatment}
      analyticsEvent={sendEditTreatmentSelectType}
    />
  );
};

const TreatmentCard: React.FC<{ treatment: CalendarTreatment }> = ({
  treatment,
}) => {
  return (
    <div className="bg-evvy-cream p-4 my-6 rounded-md">
      <div className="grid grid-cols-4">
        <div className="flex flex-col space-y-1 col-span-3">
          <div className="b1 medium">
            {treatment.product.title_short_display}
          </div>
          <div className="b3">
            {treatment.product.dose_text?.replace("|", " ")}
          </div>
        </div>
        <div className="flex justify-end col-span-1">
          <img
            className="h-16 w-16"
            src={treatment.product.image_url}
            alt={treatment.product.title_short_display}
          />
        </div>
      </div>
    </div>
  );
};

const CADENCE_OPTIONS = [
  {
    slug: "once-per-week",
    display: "Once a week",
  },
  {
    slug: "twice-per-week",
    display: "Twice a week",
  },
  {
    slug: "three-times-per-week",
    display: "Three times a week",
  },
];
const CHANGE_FREQUENCY_TITLE = "Change frequency";
const CHANGE_FREQUENCY_SUBTITLE =
  "How often do you want to take this medication?";
const ChangeCadenceScreen: React.FC<{
  treatment: CalendarTreatment;
  cadence?: CadenceOptionType;
  setCadence: (cadence: CadenceOptionType) => void;
}> = ({ treatment, cadence, setCadence }) => (
  <OptionSelector
    title={CHANGE_FREQUENCY_TITLE}
    subtitle={CHANGE_FREQUENCY_SUBTITLE}
    options={CADENCE_OPTIONS.map((cadenceOption) => ({
      slug: cadenceOption.slug,
      display: cadenceOption.display,
    }))}
    selectedOption={cadence}
    onSelect={(slug) => setCadence(slug as CadenceOptionType)}
    treatment={treatment}
    analyticsEvent={sendEditTreatmentSelectCadenceOption}
  />
);

const STOP_TREATMENT = "Stop treatment";
const STOP_TREATMENT_TYPE_QUESTION =
  "Have you already started taking this treatment?";
const RemoveTreatmentScreen: React.FC<{
  treatment: CalendarTreatment;
  adjustmentType?: TreatmentAdjustmentType;
  setAdjustmentType: (adjustmentType: TreatmentAdjustmentType) => void;
}> = ({ treatment, adjustmentType, setAdjustmentType }) => {
  return (
    <>
      <EditCalendarTreatmentHeader
        title={STOP_TREATMENT}
        treatment={treatment}
      />
      {/* dropdown select of yes/no */}
      <div>
        <div className="b2 medium">{STOP_TREATMENT_TYPE_QUESTION}</div>
        {/* simple dropdown menu */}

        <div className="flex items-center my-4">
          <SimpleDropdownSelect
            value={adjustmentType === "shorten" ? "yes" : "no"}
            optionList={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            onChange={(e) => {
              if (e.target.value === "yes") {
                setAdjustmentType("shorten");
              } else {
                setAdjustmentType("remove");
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

const WHEN_STOP_TREATMENT = "On what day do you want to stop treatment?";
const ShortenTreatmentScreen: React.FC<{
  treatment: CalendarTreatment;
  selectedDate?: Date | null;
  setSelectedDate: (setSelectedDate?: Date) => void;
  setError: (error: string) => void;
}> = ({ treatment, selectedDate, setSelectedDate, setError }) => {
  const endDateText = treatment.dates.end_date
    ? formatDate(strDateToTimestamp(treatment.dates.end_date))
    : "";

  if (
    selectedDate &&
    treatment.dates.start_date &&
    stripTimezone(selectedDate) < stripTimezone(treatment.dates.start_date)
  ) {
    setError(
      "The date you selected is before the treatment start date. Please select a later date."
    );
  } else if (
    selectedDate &&
    treatment.dates.end_date &&
    stripTimezone(selectedDate) >= stripTimezone(treatment.dates.end_date)
  ) {
    setError(
      "The date you selected is after the treatment end date. Please select an earlier date."
    );
  } else {
    setError("");
  }

  return (
    <DateSelector
      title={STOP_TREATMENT}
      subtitle={
        endDateText
          ? `You are currently set to finish this medication on ${endDateText}`
          : ""
      }
      dateLabel={WHEN_STOP_TREATMENT}
      treatment={treatment}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      minDate={strDateToStrDateDayBeforeOrAfter(treatment.dates.start_date, 1)}
      maxDate={strDateToStrDateDayBeforeOrAfter(treatment.dates.end_date, -1)}
    />
  );
};

const DELAY_TREATMENT = "Delay treatment start";
const WHEN_DELAY_TREATMENT = "On what day do you want to start treatment?";
const DelayTreatmentScreen: React.FC<{
  treatment: CalendarTreatment;
  selectedDate?: Date | null;
  setSelectedDate: (setSelectedDate?: Date) => void;
  setError: (error: string) => void;
}> = ({ treatment, selectedDate, setSelectedDate, setError }) => {
  const startDateText = treatment.dates.start_date
    ? formatDate(strDateToTimestamp(treatment.dates.start_date))
    : "";

  // validate
  if (
    selectedDate &&
    treatment.dates.start_date &&
    stripTimezone(selectedDate) <= stripTimezone(treatment.dates.start_date)
  ) {
    setError(
      "The date you selected is the same or earlier than your current treatment start date. Please select a later date."
    );
  } else {
    setError("");
  }

  return (
    <DateSelector
      title={DELAY_TREATMENT}
      subtitle={
        startDateText
          ? `You are currently set to start this medication on ${startDateText}`
          : ""
      }
      dateLabel={WHEN_DELAY_TREATMENT}
      treatment={treatment}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      minDate={strDateToStrDateDayBeforeOrAfter(treatment.dates.start_date, 1)}
      maxDate={strDateToStrDateDayBeforeOrAfter(treatment.dates.end_date, -1)}
    />
  );
};

const REASON_WHY_SUBTITLE =
  "We get it. Life happens! Let us know why you’re making changes to your treatment.";
const TELL_US_WHY = "Tell us why";
const WHAT_REASON =
  "What is the main reason why you're editing this treatment?";
const TELL_US_MORE = "Please tell us more";

const ReasonWhyScreen: React.FC<{
  treatment: CalendarTreatment;
  adjustmentType?: TreatmentAdjustmentType;
  reasonWhy?: string;
  setReasonWhy: (reasonWhy: string) => void;
  reasonWhyDetails?: string;
  setReasonWhyDetails: (reasonWhyDetails: string) => void;
}> = ({
  treatment,
  adjustmentType,
  reasonWhy,
  setReasonWhy,
  reasonWhyDetails,
  setReasonWhyDetails,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [reasonOptions, setReasonOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [error, setError] = useState<string>();
  //  fetch reason options from api
  useEffect(() => {
    const fetchReasonOptions = async () => {
      try {
        const response = await careService.asyncFetchTreatmentAdjustmentReasons(
          adjustmentType
        );
        const data = response.data;
        setReasonOptions(data);
      } catch (e) {
        console.error(e);
        setError("Error fetching reason options");
      } finally {
        setLoading(false);
      }
    };
    fetchReasonOptions();
  }, []);

  //   analytics
  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  return (
    <>
      <EditCalendarTreatmentHeader
        title={TELL_US_WHY}
        subtitle={REASON_WHY_SUBTITLE}
      />

      {/* dropdown select of yes/no */}
      <div>
        <div className="b2 medium">{WHAT_REASON}</div>
        {/* simple dropdown menu */}
        <div className="flex items-center my-4">
          <SimpleDropdownSelect
            value={reasonWhy}
            optionList={reasonOptions}
            onChange={(e) => {
              setReasonWhy(e.target.value);
              // analytics
              sendEditTreatmentSelectReason({
                ...baseAnalyticsArgs,
                calendarTreatmentId: treatment.id,
                prescriptionSlug: treatment.product.slug,
                reason: reasonWhy,
              });
            }}
          />
        </div>
        {/* follow up question, only shown once selected reason why */}
        {reasonWhy && (
          <div>
            <div className="b2 medium">{TELL_US_MORE}</div>
            <textarea
              id="reasonwhydetails"
              name="reasonwhydetails"
              value={reasonWhyDetails}
              onChange={(e) => setReasonWhyDetails(e.target.value)}
              className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
              autoComplete="off"
              rows={3}
            />
          </div>
        )}
      </div>
    </>
  );
};

const EditCalendarTreatment: React.FC<{
  treatment: CalendarTreatment;
  closeEditTreatment: () => void;
  setSavedTreatmentMessage: (message: string) => void;
  refetchCalendar: () => void;
}> = ({
  treatment,
  closeEditTreatment,
  setSavedTreatmentMessage,
  refetchCalendar,
}) => {
  const [adjustmentType, setAdjustmentType] =
    useState<TreatmentAdjustmentType>();
  const [cadence, setCadence] = useState<CadenceOptionType>();
  const [reasonWhy, setReasonWhy] = useState<string>();
  const [reasonWhyDetails, setReasonWhyDetails] = useState<string>();
  const [selectedStartDate, setSelectedStartDate] = useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>();
  // reducer for page step history
  // current step is the last element in the array
  const [steps, dispatch] = useReducer(stepsReducer, ["select-adjustment"]);
  //   Loading + error state
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  // current step
  const currentStep = steps[steps.length - 1];
  const canProgressToNextStep =
    !error &&
    adjustmentType &&
    (currentStep === "shorten-screen"
      ? selectedEndDate
      : currentStep === "delay-screen"
      ? selectedStartDate
      : currentStep === "cadence-screen"
      ? cadence
      : currentStep === "reason-why"
      ? reasonWhy && reasonWhyDetails
      : true);

  // analytics
  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  // effects
  useEffect(() => {
    // if no steps, close edit treatment page
    if (steps.length === 0) {
      closeEditTreatment();
    }
  }, [steps]);

  // step actions
  const progressToNextStep = (
    selectedAdjustmentType?: TreatmentAdjustmentType
  ) => {
    setSavedTreatmentMessage("");
    dispatch({ type: "next", adjustmentType: selectedAdjustmentType });
  };
  const goBack = (selectedAdjustmentType?: TreatmentAdjustmentType) => {
    setError("");
    dispatch({ type: "back" });
    sendEditTreatmentClickedBack({
      ...baseAnalyticsArgs,
      calendarTreatmentId: treatment.id,
      prescriptionSlug: treatment.product.slug,
      currentStep,
      editType: selectedAdjustmentType,
    });
  };

  const submitTreatmentAdjustment = async ({
    selectedAdjustmentType,
    selectedReasonWhy,
    selectedReasonWhyDetails,
    startDate,
    endDate,
  }: {
    selectedAdjustmentType?: TreatmentAdjustmentType;
    selectedReasonWhy?: string;
    selectedReasonWhyDetails?: string;
    startDate?: Date;
    endDate?: Date;
  }) => {
    setError("");
    setLoading(true);
    var payload = {
      update_type: selectedAdjustmentType,
      reason: selectedReasonWhy,
      reason_details: selectedReasonWhyDetails,
      days: 0,
      end_date: "",
      cadence_option: "",
    };

    if (
      selectedAdjustmentType === "delay" &&
      startDate &&
      treatment?.dates?.start_date
    ) {
      // if update is delay, need to calculate the number of days to delay from the treatment start date and new delay date
      const daysDifference = differenceInDays(
        startDate,
        treatment.dates.start_date
      );
      payload.days = daysDifference;
    } else if (selectedAdjustmentType === "shorten") {
      payload.end_date = endDate?.toISOString().split("T")[0] || "";
    } else if (selectedAdjustmentType === "cadence") {
      payload.cadence_option = cadence || "";
    }

    try {
      await careService.asyncUpdateCalendarTreatment(treatment.id, payload);
      refetchCalendar();
      closeEditTreatment();
      setSavedTreatmentMessage(
        `Your update to ${treatment.product.title_short_display} has been saved.`
      );
    } catch (e) {
      console.error(e);
      setError(
        `Error updating treatment. Please try again or contact ${CARE_SUPPORT_EMAIL}.`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="grow flex flex-col justify-between">
      <div>
        {/* back button with left facing arrow */}
        <button
          className="b2 medium"
          aria-label="Back"
          onClick={() => goBack(adjustmentType)}
        >
          <span className="flex">
            <h4 className="font-bold text-sm my-auto">←</h4>
            <div className="ml-1 mt-1"> back</div>
          </span>
        </button>

        {currentStep === "select-adjustment" ? (
          <SelectAdjustmentView
            treatment={treatment}
            adjustmentType={adjustmentType}
            setAdjustmentType={setAdjustmentType}
          />
        ) : currentStep === "cadence-screen" ? (
          <ChangeCadenceScreen
            treatment={treatment}
            cadence={cadence}
            setCadence={setCadence}
          />
        ) : currentStep === "remove-screen" ? (
          <RemoveTreatmentScreen
            treatment={treatment}
            adjustmentType={adjustmentType}
            setAdjustmentType={setAdjustmentType}
          />
        ) : currentStep === "shorten-screen" ? (
          <ShortenTreatmentScreen
            treatment={treatment}
            selectedDate={selectedEndDate}
            setSelectedDate={setSelectedEndDate}
            setError={setError}
          />
        ) : currentStep === "reason-why" ? (
          <ReasonWhyScreen
            treatment={treatment}
            adjustmentType={adjustmentType}
            reasonWhy={reasonWhy}
            setReasonWhy={setReasonWhy}
            reasonWhyDetails={reasonWhyDetails}
            setReasonWhyDetails={setReasonWhyDetails}
          />
        ) : currentStep === "delay-screen" ? (
          <DelayTreatmentScreen
            treatment={treatment}
            selectedDate={selectedStartDate}
            setSelectedDate={setSelectedStartDate}
            setError={setError}
          />
        ) : (
          ""
        )}
      </div>

      {/* next button, blue rectangular evvy button */}
      <div className="mt-4">
        {/* error message */}
        {error && (
          <div className="py-2 font-medium text-red-400 rounded-sm mt-6">
            {error}
          </div>
        )}
        <RectangularButton
          text={currentStep === "reason-why" ? "Submit" : "Next"}
          bgColorClass="bg-evvy-blue"
          textColorClass="text-black"
          fullWidth
          disabled={!canProgressToNextStep || loading}
          onClick={() => {
            if (currentStep === "reason-why") {
              submitTreatmentAdjustment({
                selectedAdjustmentType: adjustmentType,
                selectedReasonWhy: reasonWhy,
                selectedReasonWhyDetails: reasonWhyDetails,
                startDate: selectedStartDate,
                endDate: selectedEndDate,
              });
            } else {
              progressToNextStep(adjustmentType);
            }
            sendEditTreatmentClickedNext({
              ...baseAnalyticsArgs,
              calendarTreatmentId: treatment.id,
              prescriptionSlug: treatment.product.slug,
              currentStep,
              editType: adjustmentType,
            });
          }}
        />
      </div>
    </div>
  );
};

export default EditCalendarTreatment;
