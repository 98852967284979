/**
 * A close icon for modals
 * @param {*} handleClose function to handle the function of selecting the X
 * @param {string} textColorClass this should be a string for a text color, such as `text-evvy-black`, since in tailwind, the stroke of the X is referenced with `currentColor` which is set via a text class
 * @returns jsx of an x
 */
const CloseX = ({ handleClose, textColorClass }) => {
  return (
    <button
      className={`absolute top-4 right-4 ${
        textColorClass ? textColorClass : "text-evvy-white"
      } cursor-pointer`}
      onClick={handleClose}
    >
      <svg
        className="block h-8 w-8"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  );
};

export default CloseX;
