import { useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { group } from "d3";
import Layout from "../../components/layout";
import RectangularButton from "../../components/common/rectangularButton";
import YouShouldTry from "../../components/plan/youShouldTryCard";
import YouShouldKnow from "../../components/plan/youShouldKnowCard";
import Modal from "../../components/common/modal";
import ErrorBoundary from "../../components/common/errorBoundary";
import ResearchToolipContentTemplate from "../../components/plan/modalTemplates/researchStatus";
import LearnMoreContentTemplate from "../../components/plan/modalTemplates/learnMore";
import FeedbackContentTemplate from "../../components/plan/modalTemplates/feedback";
import LeaveReviewContentTemplate from "../../components/plan/modalTemplates/leaveReview";
import { testsService } from "../../services/tests";
import { planService } from "../../services/plan";
import { colorNames } from "../../components/plan/utils";
import { setSubmitFeedbackError } from "../plan/planSlice";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import {
  eventNames,
  sendViewedPlanPage,
} from "../../utils/analytics/customEventTracking";
import { modalTypes as MTYPES } from "../plan/constants.js";

const renderModal = ({ type, data }, closeModal, handleSubmit) => {
  switch (type) {
    case "_learnmore": {
      return <LearnMoreContentTemplate data={data} handleClose={closeModal} />;
    }
    case "_research": {
      return (
        <ResearchToolipContentTemplate
          popover={false}
          handleClose={closeModal}
        />
      );
    }
    case MTYPES.FEEDBACK: {
      return (
        <FeedbackContentTemplate
          handleClose={closeModal}
          handleSubmit={handleSubmit}
        />
      );
    }
    case MTYPES.LEAVE_REVIEW: {
      return <LeaveReviewContentTemplate handleClose={closeModal} />;
    }
    default: {
      return null;
    }
  }
};

const Plan = () => {
  /* state management */
  const [thingsToTry, setThingsToTry] = useState(null);
  const [thingsToKnow, setThingsToKnow] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [testHash, setTestHash] = useState(null);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(true);
  const [aLaCareEligible, setALaCareEligible] = useState(false);

  /* Redux */
  // redux dispatch method for updating global store
  const dispatch = useDispatch();

  /* effects */
  useEffect(() => {
    window.scrollTo(0, 0); // scroll to top on page load (dont' preserve scroll height of origin)
    fetchPlan();
    sendViewedPlanPage({ planVersion: "v1", aLaCareEligible: aLaCareEligible });
  }, [aLaCareEligible]); // Fetches tests upon page load

  // Fetches test once testHash retrieved from plan api
  useEffect(() => {
    if (testHash) fetchTest();
  }, [testHash]);

  /* actions */
  const fetchPlan = () => {
    // setLoading(true);
    planService.fetchPlanOld(
      (response) => {
        const { test_hash } = response.data || {};
        setTestHash(test_hash);
        // console.log("response :>> ", response.data.plan_items);
        /* sets a map of section => items, e.g., "KN" => [things to know] */
        const planItems = group(
          response.data.plan_items,
          (item) => item.section
        );
        if (planItems.has("TR")) setThingsToTry(planItems.get("TR"));
        if (planItems.has("KN")) setThingsToKnow(planItems.get("KN"));
        // setLoading(false);
      },
      (error) => {
        console.error(error);
        // setLoading(false);
      }
    );
  };

  // get test associated with this plan
  const fetchTest = () => {
    testsService.fetchTest(
      testHash,
      (response) => {
        const { eligible_for_a_la_care, submitted_plan_feedback } =
          response.data || {};
        setALaCareEligible(eligible_for_a_la_care);
        setFeedbackSubmitted(submitted_plan_feedback);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  // submit feedback for this plan
  const submitFeedback = (isHelpful, feedback = "") => {
    planService.submitFeedback(
      testHash,
      isHelpful,
      feedback,
      (response) => {
        // handle submission and send success or error back to feedback modal
        dispatch(setSubmitFeedbackError(false));
        // update feedback submitted state to hide feedback section
        setFeedbackSubmitted(true);
      },
      (error) => {
        dispatch(setSubmitFeedbackError(true));
        console.error(error);
      }
    );
  };

  const username = useMemo(() => {
    // fetch user info from storage
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // default
    let username = "Hello";
    if (currentUser) {
      const { first_name, email } = currentUser.identity;
      // use email address as default if first name is empty
      username = first_name ? first_name : email.substr(0, email.indexOf("@"));
    }
    return username;
  }, []);

  /* switch case to determine which submit handler to pass to modal renderer */
  const handleSubmit = (type, data) => {
    switch (type) {
      case MTYPES.FEEDBACK: {
        submitFeedback(false, data);
        break;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Layout title="My Plan" bgClass="bg-evvy-cream">
      {modalData !== null && (
        <ErrorBoundary>
          <Modal
            closeModal={() => setModalData(null)}
            widthClass={
              [MTYPES.FEEDBACK, MTYPES.LEAVE_REVIEW].includes(modalData.type)
                ? "w-full md:w-plan-column-sm"
                : "w-full md:w-1/3"
            }
            hasFocusItem={[MTYPES.FEEDBACK, MTYPES.LEAVE_REVIEW].includes(
              modalData.type
            )}
          >
            {renderModal(
              modalData,
              () => setModalData(null),
              (data) => handleSubmit(modalData.type, data)
            )}
          </Modal>
        </ErrorBoundary>
      )}
      <div className="min-w-full">
        {thingsToTry && (
          <ErrorBoundary>
            <div className="border-b border-dashed border-evvy-black">
              <div className="w-4/5 md:w-2/5 mx-auto my-10 text-center">
                <h2>{`Hi ${username}, here are your next steps:`}</h2>
                <p className="opacity-50">
                  We’ve studied thousands of academic papers, treatment
                  ingredient lists, best-of listicles, and more — all to make
                  sure this plan is personalized to you. We’ll keep this page
                  updated as we uncover more through our research!
                </p>
              </div>
              <div className="min-w-full flex flex-wrap mb-10 justify-between">
                {thingsToTry.map((item, i) => (
                  <YouShouldTry
                    key={`try-${i}`}
                    item={item}
                    openModalWith={setModalData}
                  />
                ))}
              </div>
            </div>
          </ErrorBoundary>
        )}
        {thingsToKnow && (
          <ErrorBoundary>
            <div>
              <div className="w-4/5 md:w-2/5 mx-auto my-10 text-center">
                <h2>{`We ${
                  thingsToTry ? "also" : ""
                } think it's important that you know:`}</h2>
              </div>
              <div className="min-w-full flex flex-wrap mb-10 justify-between">
                {thingsToKnow.map((item, i) => (
                  <YouShouldKnow
                    key={`know-${i}`}
                    item={item}
                    color={colorNames[i % colorNames.length]}
                    openModalWith={setModalData}
                  />
                ))}
              </div>
            </div>
          </ErrorBoundary>
        )}
      </div>
      <ErrorBoundary>
        {/* feedback section - show only if feedback has never been submitted for this plan */}
        {!feedbackSubmitted && (
          <section title="feedback" className="bg-evvy-cream">
            <div className="flex flex-col items-center m-auto py-20 mx-6 md:m-auto max-w-screen-sm">
              <div className="b1">Was this plan helpful for you?</div>
              <div className="flex justify-between items-center gap-8 mt-8 w-full">
                {/* yes button sends positive feedback to backend and promps user to leave review */}
                <RectangularButton
                  text="yes"
                  bgColorClass="bg-evvy-blue"
                  textColorClass="text-evvy-black"
                  fullWidth
                  onClick={analyticsClickHandler({
                    eventName: eventNames.CLICKED_PLAN_FEEDBACK,
                    eventArgs: { response: "yes", planVersion: "v1" },
                    clickHandler: () => {
                      // send feedback with is_helpful set to true
                      submitFeedback(true);
                      // then open modal to collect freetext feedback from user
                      setModalData({
                        type: MTYPES.LEAVE_REVIEW,
                      });
                    },
                  })}
                />
                {/* 'no' button sends negative feedback to backend and opens feedback modal */}
                <RectangularButton
                  onClick={analyticsClickHandler({
                    eventName: eventNames.CLICKED_PLAN_FEEDBACK,
                    eventArgs: { response: "no", planVersion: "v1" },
                    clickHandler: () => {
                      // send feedback with is_helpful set to false
                      // ( make sure negative feedback recorded even if user doesn't finish text feedback modal flow)
                      submitFeedback(false);
                      // then open modal to collect freetext feedback from user
                      setModalData({
                        type: MTYPES.FEEDBACK,
                      });
                    },
                  })}
                  text="no"
                  bgColorClass="bg-evvy-black"
                  textColorClass="text-evvy-white"
                  fullWidth
                />
              </div>
            </div>
          </section>
        )}
      </ErrorBoundary>
    </Layout>
  );
};

export default Plan;
