import { AxiosResponse } from "axios";
import { getRequest, postRequest } from "../utils/axios";

const updateAccount = (
  data: any,
  onSuccess: (response: any) => void,
  onError: (error: any, response: any) => void
) => {
  return postRequest(
    "/api/v1/account/",
    data,
    (response: any) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: any, response: any) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
};

const getProviderProfile: () => Promise<AxiosResponse<any>> = async () => {
  const response = await getRequest(
    "/api/v1/provider/profile",
    (response: any) => {
      return response;
    },
    (error: any, response: any) => {
      throw error;
    }
  );
  return response;
};

const updateProviderProfile = async (data: any) => {
  const response = await postRequest(
    "/api/v1/provider/profile",
    data,
    (response: any) => {
      return response;
    },
    (error: any, response: any) => {
      throw error;
    }
  );
  return response;
};

export const accountService = {
  updateAccount,
  getProviderProfile,
  updateProviderProfile,
};
