/**
 * a results table composed of a date picker (header), user results (upper table/1st section), user symptoms (lower table/2nd section)
 * @param {object} testsData an object containing individual user tests, used for the date picker
 * @param {object} insightsCompareData an object containing relevant user
 * @param {string} cstType a string containing the Community State Type (Vaginal Microbiome Type)
 */

/* Libraries */
import React from "react";
import { useDispatch } from "react-redux";

/* Local Imports */
import { setClickedValence } from "../../../pages/compare/compareSlice";

const DropDownMenu = ({
  options,
  onSubmit,
  hasPlaceholder,
  currentSelection,
  valAcc = (d) => d,
  labelAcc = (d) => d,
  disabled = false,
}) => {
  /* Redux */
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const hash = e.target.value;
    const option = options.find((option) => valAcc(option) === hash);
    onSubmit(option);
    dispatch(setClickedValence(null)); // clear clicked state when dropdown changes
  };

  const getCurrentSelection =
    currentSelection === "DEFAULT" || !currentSelection
      ? currentSelection
      : valAcc(currentSelection);

  return (
    <div className="flex items-center">
      <div className="relative w-full">
        <select
          onChange={handleChange}
          disabled={disabled}
          className="cursor-pointer capitalize bg-none bg-transparent rounded-md border-solid border-evvy-blue py-2 w-full whitespace-nowrap"
          value={getCurrentSelection}
        >
          {hasPlaceholder && (
            <option value="DEFAULT" disabled>
              {options.length > 1 ? "Select..." : "No Other Tests"}
            </option>
          )}
          {options &&
            options.map((option) => (
              <option key={valAcc(option)} value={valAcc(option)}>
                {labelAcc(option)}
              </option>
            ))}
        </select>
        <div className="absolute right-3 top-3 text-evvy-blue">↓</div>
      </div>
    </div>
  );
};

export default DropDownMenu;
