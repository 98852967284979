import React, { useState, useEffect } from "react";

const ReferralNote = ({ consult, openModal }) => {
  // display referral note to user only once
  // if has viewed consult, don't show referral note
  const consultKey = `referred-consult-${consult.uid}`; // localstorage key for visiting this consult
  const [showReferralNote, setShowReferralNote] = useState(
    localStorage.getItem(consultKey) ? false : true
  );

  const recordReferralNoteView = () => {
    localStorage.setItem(consultKey, true);
  };

  useEffect(() => {
    if (showReferralNote) {
      openModal({ type: "referral", data: consult });
    }
    recordReferralNoteView();
  }, []);

  return <React.Fragment>{showReferralNote ? <div></div> : ""}</React.Fragment>;
};

export default ReferralNote;
