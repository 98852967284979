// TODO: migrate this to results v2 components
/**
 * A styled card for the 'insights' section (goes inside carousel)
 * @param {string} text a string of the non-highlighted title text
 * @param {string} highlightedText a string of highlighted title text
 * @param {string} color this should be a string for a color, such as `evvy-black`, since in tailwind, the stroke of the border is referenced with `currentColor` which is set via a border class. It will also be passed on to the highlighted text element to set the background color.
 * @param {string} description a string of the details for this insight
 * @param {string} researchRating an object containing a string key called 'title' whose value is a string of the status of the research (e.g. 'Emerging') to be passed onto the Research Item component
 * @param {string} link a string with the text label for the LinkedText component
 * @param {*} openModalWith callback to handle link click and open modal, passing along card data
 * @returns jsx of a card
 */
import React from "react";

// imported components
import Markdown from "markdown-to-jsx";

// components
import LinkedText from "../common/linkedText";
import HighlightedText from "./highlightedText";
import ResearchItem from "./researchItem";

// lib
import { modalTypes as MTYPES } from "../../pages/plan_unfurled/constants.tsx";
import { eventNames } from "../../utils/analytics/customEventTracking.js";
import { analyticsClickHandler } from "../../utils/analytics/helpers";

const InsightsCard = ({
  data,
  color = "evvy-blue",
  link = "learn more",
  openModalWith,
  eventArgs = null,
}) => {
  const {
    title,
    description,
    research_rating: researchRating,
    slug,
    get_group_display: groupName,
  } = data;
  // double pipe indicates division between part of title that should and should not be highlighted
  const [text = "", highlightedText = ""] = title.split("||") || ["", ""];
  return (
    <div
      className={`flex flex-col justify-between text-left h-full px-12 pt-8 pb-10 bg-evvy-white border-t-8 border-${color}`}
      style={{ width: "335px" }}
    >
      <h5 className="leading-10 mb-auto">
        <span className={"uppercase"}>{text[0]}</span>
        <span>{text.slice(1)} </span>
        <HighlightedText bgColorClass={`bg-${color}`} text={highlightedText} />
      </h5>
      {/* only show first line in card */}
      <p className="line-clamp-2 mt-6 mb-4">
        <Markdown>{description}</Markdown>
      </p>
      <div className="mb-10">
        <LinkedText
          borderColorClass="border-evvy-pine"
          noTopMargin
          onClick={analyticsClickHandler({
            eventName: eventNames.CLICKED_LEARN_MORE,
            eventArgs: eventArgs,
            clickHandler: () =>
              openModalWith({
                type: MTYPES.LEARN_MORE,
                data: Object.assign({ analyticsEventArgs: eventArgs }, data),
              }),
          })}
        >
          {link}
        </LinkedText>
      </div>
      <ResearchItem
        status={researchRating?.title}
        eventArgs={Object.assign({}, eventArgs, {
          researchStatus: researchRating?.title,
        })}
      />
    </div>
  );
};

export default InsightsCard;
