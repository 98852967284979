/**
 * A simple checkmark icon in a filled color circle
 * @returns jsx of the loading spinner
 */
export const CircleCheckmark = ({
  circleClassName,
  className,
  checkmarkClassName = "stroke-white",
}: {
  circleClassName?: string;
  className?: string;
  checkmarkClassName?: string;
}) => {
  return (
    <svg
      className={className}
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.5"
        cy="11"
        r="11"
        className={circleClassName ? circleClassName : "fill-zinc-300"}
      />
      <path
        d="M17 6.6001L9.3 15.4001L6 12.1001"
        className={checkmarkClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
