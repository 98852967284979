import React from "react";
import Tippy from "@tippyjs/react/headless";

const BacteriaTypesToolip = ({ text, children, textConfig }) => {
  return (
    <>
      <Tippy
        render={(attr) => (
          <div
            id="tooltip"
            role="tooltip"
            className={`bg-evvy-cream rounded-md px-6 py-3 w-max shadow max-w-screen-xs xs:max-w-full`}
            tabIndex={-1}
            {...attr}
          >
            <div
              className={`flex items-center justify-center flex-grow ${
                textConfig ? textConfig : "t3"
              }`}
            >
              {text}
            </div>
            <div id="arrow" data-popper-arrow />
          </div>
        )}
        animation={false}
      >
        {children}
      </Tippy>
    </>
  );
};

export default React.memo(BacteriaTypesToolip);
