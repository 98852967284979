import { useState, useEffect, useCallback } from "react";
import { FAQ } from "../../types/care";
import { careService } from "../../services/care";

export const useFAQs = (page?: string) => {
  const [data, setData] = useState<FAQ[]>();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchFAQs = useCallback(async () => {
    if (page) {
      setIsLoading(true);
      try {
        const response = await careService.fetchFAQs(page);
        setData(response.data.faqs);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [page]);

  useEffect(() => {
    if (page) fetchFAQs();
  }, [page, fetchFAQs]);

  return {
    faqs: data,
    error,
    isLoading,
  };
};
