import React, { useState } from "react";
import Webcam from "react-webcam";
import CloseX from "../../common/closeX";
import LinkedText from "../../common/linkedText";
import BlueRectangularButton from "../../common/blueRectangularButton";
import LoadingSpinner from "../../common/loadingSpinner";

const WebcamComponent = () => <Webcam />;

const TakePhotoModal = ({
  photoType,
  uploadPhoto,
  handleClose,
  uploading,
  error,
}) => {
  const [newImage, setNewImage] = useState(null);
  const [webcamLoading, setWebcamLoading] = useState(true); // initialize to loading
  var facingMode = "user";
  if (photoType === "id") {
    // allow user to use env-facing camera to take pic of ID on mobile
    facingMode = "environment";
  }
  const videoConstraints = {
    width: 450,
    height: 300,
    facingMode,
  };

  const webcamRef = React.useRef(null);

  /*
   * Actions
   */
  // capture photo from webcam
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setNewImage(imageSrc);
  }, [webcamRef]);

  // upload photo to backend
  const savePhoto = () => {
    uploadPhoto(newImage, photoType);
  };

  return (
    <div className="block bg-evvy-white p-4">
      <div className="flex justify-end items-center mb-6">
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>
      {error ? (
        <div className="text-center text-red-700 py-20">{error}</div>
      ) : uploading ? (
        <div className="text-center mb-6">
          <h5>Uploading your photo</h5>
          <div className="b2 mb-8">Hang on, this may take a minute</div>
          <LoadingSpinner />
        </div>
      ) : (
        <React.Fragment>
          <div className="mb-8 text-center">
            <h5>
              {newImage
                ? "Confirm photo"
                : photoType === "selfie"
                ? "Take your selfie"
                : "Take a photo of your ID"}
            </h5>
          </div>
          <div className="webcam-container justify-center flex">
            {newImage ? (
              <img src={newImage} />
            ) : (
              <Webcam
                audio={false}
                height={videoConstraints.height}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={videoConstraints.width}
                videoConstraints={videoConstraints}
                hidden={webcamLoading}
                onUserMedia={() => setWebcamLoading(false)}
              />
            )}
          </div>
          {webcamLoading && <LoadingSpinner />}
          <div className="text-center mt-4">
            <BlueRectangularButton
              text={newImage ? "Use this photo" : "Take Photo"}
              disabled={webcamLoading}
              onClick={
                newImage
                  ? savePhoto
                  : (e) => {
                      e.preventDefault();
                      capture();
                    }
              }
            />
          </div>
          <div className="text-center p-3">
            <LinkedText
              onClick={newImage ? () => setNewImage(null) : handleClose}
              noIcon
              noTopMargin
            >
              {newImage ? "Retake photo" : "Cancel"}
            </LinkedText>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default TakePhotoModal;
