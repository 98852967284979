import { BacteriaType, MicrobialLoadLevel } from "../../types/results";

type SampleLoadProps = {
  level: MicrobialLoadLevel;
  className?: string;
  type?: BacteriaType | "OVERALL";
};

const TYPE_TO_COLOR_MAP = {
  OVERALL: "bg-evvy-pine",
  BA: "bg-BA-fill",
  GO: "bg-GO-fill",
  NE: "bg-NE-fill",
  UN: "bg-UN-fill",
};

export const SampleLoad: React.FC<SampleLoadProps> = ({
  className,
  level,
  type = "OVERALL",
}: SampleLoadProps) => {
  return (
    <div className={`flex space-x-1 ${className}`}>
      <div
        className={`p-1 ${TYPE_TO_COLOR_MAP[type]} rounded-l-full w-1/3`}
      ></div>
      <div
        className={`p-1 w-1/3 ${TYPE_TO_COLOR_MAP[type]} ${
          level === "L" ? "bg-opacity-25" : ""
        }`}
      ></div>
      <div
        className={`p-1 w-1/3 ${TYPE_TO_COLOR_MAP[type]} rounded-r-full ${
          level !== "H" ? "bg-opacity-25" : ""
        }`}
      ></div>
    </div>
  );
};
