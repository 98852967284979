import { cn } from "../../utils/cn";

/**
 * A close icon for modals
 * @param {*} handleClose function to handle the function of selecting the X
 * @param {string} textColorClass this should be a string for a text color, such as `text-evvy-black`, since in tailwind, the stroke of the X is referenced with `currentColor` which is set via a text class
 * @returns jsx of an x
 */
const CloseX: React.FC<{
  handleClose: () => void;
  textColorClass: string;
  side?: "left" | "right";
  positionClass?: string;
}> = ({
  handleClose,
  textColorClass,
  side = "right",
  positionClass = "absolute top-2",
}: any) => {
  return (
    <button
      className={cn(
        positionClass,
        "cursor-pointer",
        textColorClass || "text-evvy-white",
        { "right-3": side === "right", "left-3": side !== "right" }
      )}
      onClick={handleClose}
    >
      <svg
        className="block h-8 w-8"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  );
};

export default CloseX;
