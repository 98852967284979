import { getRequest } from "../utils/axios";

export const trendsService = {
  fetchTrends,
};

function fetchTrends(onSuccess, onError) {
  // get trend data
  return getRequest(
    `/api/v1/trends/`,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}
