import React from "react";
import { setTypeModalData } from "../../pages/compare/compareSlice";
import { useDispatch } from "react-redux";
import { ReactComponent as Info } from "../../components/common/icons/infoBlack.svg";

const ArrowListItem = ({
  bgColorClass = "bg-evvy-white",
  text = "",
  coloredText = "",
  afterText = "",
  noBorder,
  capitalize = false,
  type,
}) => {
  const dispatch = useDispatch();

  return (
    <div role="listitem">
      <div className={`flex items-start text-left ${bgColorClass} py-4`}>
        {/* arrow - hidden from screen readers */}
        <div className={` flex-shrink-0 ml-2`} aria-hidden="true">
          ->
        </div>
        {/* list text */}
        <div className="ml-4 text-evvy-black">
          {capitalize && text.length ? (
            // grey text
            <span className="opacity-50">
              <span className="uppercase">{text[0]}</span>
              <span>{text.slice(1)}</span>
            </span>
          ) : (
            text
          )}
          {/* black text */}
          <span>{` ${coloredText} `}</span>
          <span>{afterText}</span>
          {type && (
            <span
              className="cursor-pointer"
              role="button"
              aria-label="Open CST type info modal"
              tabIndex={0}
              onClick={() => dispatch(setTypeModalData(type))}
              onKeyDown={({ key }) => {
                if (key === "Enter") {
                  dispatch(setTypeModalData(type));
                }
              }}
            >
              <Info className="ml-2 inline w-4 h-4" />
            </span>
          )}
        </div>
      </div>
      {/* dashed line  - hidden from screenreaders */}
      {!noBorder && (
        <div
          className="border-evvy-black border-opacity-30 border-b border-dashed w-full"
          aria-hidden="true"
        />
      )}
    </div>
  );
};

export default ArrowListItem;
