import { useContext } from "react";
import { ConsultsContext } from "../../contexts/consultsContext";

export const useConsults = () => {
  const { consults, refetchConsults, loading } = useContext(ConsultsContext);

  return {
    consults,
    refetchConsults,
    loading,
  };
};
