import React, { useEffect, useMemo, useState } from "react";
import {
  CartAction,
  CartItem,
  CartState,
  useCart,
} from "../../../contexts/aLaCareCartContext";

// types
import { Test } from "../../../types/test";
import { Consult } from "../../../types/care";

// services
import { careService } from "../../../services/care";

// components
import RectangularButton from "../../common/rectangularButton";
import { CollapsibleStickyModal } from "../../common/collapsibleStickyModal";
import { EvvyMarkdown } from "../../common/evvyMarkdown";
import { ReactComponent as XIcon } from "../../common/icons/X.svg";
import { ReactComponent as AlertTriangle } from "../../common/icons/alert-triangle.svg";
import {
  getCareIntakeUrl,
  getTreatmentPlanUrlFromConsult,
  sumAndFormatCartItemsToPriceString,
} from "../utils";
import { useNavigate } from "react-router-dom";
import {
  A_LA_CARE_SHOP_TREATMENTS_PAGE_PATH,
  CARE_INTAKE_STATIC_PATH,
  VIEW_RX_PRODUCTS,
} from "../constants";
import {
  sendShopTreatmentsClickedAddToCart,
  sendShopTreatmentsClickedRemoveFromCart,
  sendTreatmentCartClickedCheckout,
  sendTreatmentCartClickedViewMoreProducts,
} from "../../../utils/analytics/customEventTracking";
import { useProblemsSummary } from "../../../hooks/care/useProblemsSummary";
import { Link } from "react-router-dom";

const ALaCareCart = ({
  test,
  state,
  dispatch,
  removeFromCart,
  baseAnalyticsArgs,
}: {
  test: Test;
  state: CartState;
  dispatch: React.Dispatch<CartAction>;
  removeFromCart: (item: CartItem) => void;
  baseAnalyticsArgs: any;
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const SUBMIT_ERROR_MESSAGE =
    "Error creating consult. Please try again. If problem persists, please contact Evvy support.";
  const navigate = useNavigate();

  const goToCareIntakeIntro = (consult: Consult) => {
    navigate(getCareIntakeUrl("intro", consult));
  };
  const aLaCarePaid = test.latest_vaginitis_consult?.consult_paid;
  const hasVisitedShopTreatmentsPage = localStorage.getItem(
    `visited-shop-treatments-${test.hash}`
  )
    ? true
    : false;

  const clickCartRequest = async () => {
    setSubmitting(true);
    // trigger analytics
    sendTreatmentCartClickedCheckout(baseAnalyticsArgs);
    // first check of test already has latest vaginitis consult
    var latestVaginitisConsult = test.latest_vaginitis_consult;
    if (latestVaginitisConsult) {
      try {
        await careService.updateConsultPurchaseType(
          latestVaginitisConsult.uid,
          "a-la-care"
        );
        goToCareIntakeIntro(latestVaginitisConsult);
      } catch (error) {
        console.error(error);
        setError(SUBMIT_ERROR_MESSAGE);
      }
    } else {
      // create vaginitis consult and then go to checkout
      careService.createConsult(
        {
          hash: test.hash,
          purchase_type: "a-la-care",
          type: "vaginitis",
        },
        (response: { data: Consult }) => {
          latestVaginitisConsult = response.data;
          goToCareIntakeIntro(latestVaginitisConsult);
        },
        (error: any) => {
          console.error(error);
          setError(SUBMIT_ERROR_MESSAGE);
        }
      );
    }
  };

  // define cta text and action
  var cartCTAText = "";
  var cartCTAAction = () => {};
  if (test.latest_vaginitis_consult?.status === "CP") {
    cartCTAText = VIEW_RX_PRODUCTS;
    cartCTAAction = () =>
      navigate(getTreatmentPlanUrlFromConsult(test.latest_vaginitis_consult));
  } else if (test.latest_vaginitis_consult?.consult_paid) {
    cartCTAText = "Complete Intake ->";
    cartCTAAction = () => navigate(CARE_INTAKE_STATIC_PATH);
  } else {
    cartCTAText = "REQUEST ->";
    cartCTAAction = clickCartRequest;
  }

  return (
    <>
      {/* items in cart - title on left, price on right with x button to remove */}
      <div className="p-7 space-y-3">
        {state.items.map((item, i) => (
          <div key={`item-${i}`} className="b1 flex justify-between">
            <div className="medium">{item.name}</div>
            <div className="flex">
              <div className="regular">{`$${item.price}`}</div>
              <button
                onClick={() => removeFromCart(item)}
                disabled={submitting || aLaCarePaid}
              >
                <XIcon />
              </button>
            </div>
          </div>
        ))}
        {/* if items in cart, then show free provider consult fee */}
        {state.items.length > 0 ? (
          <div className="b1 flex justify-between">
            <div className="medium">Provider consult</div>
            <div className="flex">
              <div className="regular">Free</div>
              <div className="regular ml-1.5">
                <EvvyMarkdown>{`~~$30.00~~`}</EvvyMarkdown>
              </div>
            </div>
          </div>
        ) : (
          <div className="b1 regular">Nothing here yet!</div>
        )}
        {/* display orange banner to view main shop treatments page if they have not yet viewed */}
        {!hasVisitedShopTreatmentsPage && (
          <div className="bg-coral/70 p-4 flex my-auto items-center rounded-lg">
            <AlertTriangle className="stroke-evvy-black h-4 w-5 mr-1" />
            <span className="b2 medium">
              You’re eligible for{" "}
              <Link
                to={A_LA_CARE_SHOP_TREATMENTS_PAGE_PATH}
                onClick={() =>
                  sendTreatmentCartClickedViewMoreProducts(baseAnalyticsArgs)
                }
                className="underline persistSize"
              >
                more Rx products
              </Link>
              .
            </span>
          </div>
        )}
      </div>
      {/* line all the way across */}
      <hr className="my-0" />
      {/* "Subtotal" on left, and summed price of all items on right */}
      <div className="p-5">
        <div className="flex justify-between items-center t3 medium">
          <div>Subtotal</div>
          <div>
            {/* format as currency so 0.00 */}
            {sumAndFormatCartItemsToPriceString(state.items)}
          </div>
        </div>
        <div className="b1 regular pt-2.5 pb-5">
          Shipping & taxes calculated at checkout
        </div>
        {error && (
          <div className="text-center caption regular text-red-500 pb-1">
            {error}
          </div>
        )}
        <RectangularButton
          text={cartCTAText}
          textColorClass="text-evvy-black"
          verticalPadding="py-4"
          fullWidth
          disabled={state.items.length === 0 || submitting}
          onClick={cartCTAAction}
        />
        <div className="b3 medium pt-2.5">
          Your specific prescriptions will be determined at the sole discretion
          of an Evvy-affiliated provider.
        </div>
      </div>
    </>
  );
};

export const useALaCareStickyCart = ({ test }: { test?: Test }) => {
  const { state, dispatch } = useCart();
  const [showCart, setShowCart] = useState<boolean>(state.items.length > 0);
  const [collapseCart, setCollapseCart] = useState<boolean>(true);
  const aLaCarePaid = test?.latest_vaginitis_consult?.consult_paid;
  const selectedTreatments = useMemo(() => {
    return test?.latest_vaginitis_consult?.selected_treatments || [];
  }, [test?.latest_vaginitis_consult?.selected_treatments]);
  const cartId = test?.hash || "";
  const { testSummary } = useProblemsSummary(test?.hash);

  const cartHeader =
    state.items.length === 0
      ? "No items added"
      : `${state.items.length} item${state.items.length > 1 ? "s" : ""} ${
          aLaCarePaid ? "purchased" : "added"
        }`;

  const baseAnalyticsArgs = useMemo(() => {
    return {
      testHash: test?.hash,
      consultId: test?.latest_vaginitis_consult?.uid,
      careEligible: test?.eligible_for_care,
      eligibleTreatmentPathways: test?.eligible_treatment_pathways?.map(
        (pathway) => pathway.slug
      ),
      aLaCareEligible: test?.eligible_for_a_la_care,
      selectedTreatments: state?.items.map((item) => item.id),
    };
  }, [test, state]);

  // Update the cart based on consult's paid status and cartId mismatch
  useEffect(() => {
    // if they've already paid, set the cart state based on what's persisted on the consult
    if (aLaCarePaid) {
      dispatch({
        type: "SET_CART",
        items: selectedTreatments.map((treatment) => ({
          id: treatment.slug,
          name: treatment.title_short_display,
          price: treatment.ecomm_product.price,
          quantity: 1,
        })),
        cartId: cartId,
      });
    } else if (cartId && state.cartId && state.cartId !== cartId) {
      // reset cart if there is a cartId mismatch
      dispatch({ type: "SET_CART", items: [], cartId: cartId });
    } else if (cartId && !state.cartId) {
      // set cartId
      dispatch({ type: "SET_CART_ID", cartId: cartId });
    }
  }, [aLaCarePaid, selectedTreatments, cartId, state.cartId, dispatch]);

  // if cart has items in it, then set showCart to true
  useEffect(() => {
    if (state.items.length > 0) {
      setShowCart(true);
    }
  }, [state.items]);

  const addToCart = useMemo(() => {
    return (item: CartItem) => {
      const inCart = state.items.find((i) => i.id === item.id);
      // if item already in cart, then just return
      if (inCart) {
        return;
      }

      dispatch({ type: "ADD_TO_CART", item });
      setCollapseCart(false);
      sendShopTreatmentsClickedAddToCart({
        ...baseAnalyticsArgs,
        treatmentSlug: item.id,
        selectedTreatments: state.items.map((item) => item.id).concat(item.id),
        symptoms: testSummary?.problems_summary?.symptoms,
        conditions: testSummary?.problems_summary?.conditions,
        microbiomeState: testSummary?.problems_summary?.microbiome_state,
      });
    };
  }, [
    baseAnalyticsArgs,
    dispatch,
    state.items,
    testSummary?.problems_summary?.conditions,
    testSummary?.problems_summary?.microbiome_state,
    testSummary?.problems_summary?.symptoms,
  ]);

  const removeFromCart = useMemo(() => {
    return (item: CartItem) => {
      dispatch({ type: "REMOVE_FROM_CART", itemId: item.id });
      setCollapseCart(false);
      sendShopTreatmentsClickedRemoveFromCart({
        ...baseAnalyticsArgs,
        treatmentSlug: item.id,
        selectedTreatments: state.items
          .map((item) => item.id)
          .filter((id) => id !== item.id),
        symptoms: testSummary?.problems_summary?.symptoms,
        conditions: testSummary?.problems_summary?.conditions,
        microbiomeState: testSummary?.problems_summary?.microbiome_state,
      });
    };
  }, [
    baseAnalyticsArgs,
    dispatch,
    state.items,
    testSummary?.problems_summary?.conditions,
    testSummary?.problems_summary?.microbiome_state,
    testSummary?.problems_summary?.symptoms,
  ]);

  const cartComponent = test && showCart && (
    <CollapsibleStickyModal
      header={cartHeader}
      isCollapsed={collapseCart}
      setIsCollapsed={setCollapseCart}
    >
      <ALaCareCart
        test={test}
        state={state}
        dispatch={dispatch}
        removeFromCart={removeFromCart}
        baseAnalyticsArgs={baseAnalyticsArgs}
      />
    </CollapsibleStickyModal>
  );

  // also return add to cart function
  return {
    cartComponent: cartComponent,
    addToCart: addToCart,
    removeFromCart: removeFromCart,
  };
};
