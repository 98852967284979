import { useEffect, useState } from "react";
import { ProviderProfile } from "../../types/user";
import { accountService } from "../../services/account";
import axios from "axios";

export const useProviderProfile: () => [
  ProviderProfile | undefined,
  boolean,
  string
] = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [providerProfile, setProviderProfile] = useState<ProviderProfile>();

  useEffect(() => {
    const fetchProviderProfileInfo = async () => {
      try {
        const response = await accountService.getProviderProfile();
        setProviderProfile(response.data as ProviderProfile);
        setLoading(false);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error.message);
        }
        setLoading(false);
      }
    };

    fetchProviderProfileInfo();
  }, []);

  return [providerProfile, loading, error];
};
