import React from "react";
import { Field } from "formik";
import QuestionTitle from "./questionTitle";

const HeightQuestion = () => {
  return (
    <>
      <QuestionTitle questionTitle={"demographics_height"} />
      <div className="flex flex-row flex-wrap">
        <div className="flex sm:w-1/2 w-full flex-col">
          <label className="mb-2 t4">Feet</label>
          <Field
            id="demographics_height_feet"
            name="demographics_height_feet"
            type="number"
            className="border-px border-black outline-none focus:ring-0 p-4 mr-0 sm:mr-4"
            placeholder="Your Answer"
            autoComplete="off"
          />
        </div>
        <div className="flex sm:w-1/2 w-full flex-col sm:mt-0 mt-2">
          <label className="mb-2 t4">Inches</label>
          <Field
            id="demographics_height_inches"
            name="demographics_height_inches"
            type="number"
            className="border-px border-black outline-none focus:ring-0 p-4"
            placeholder="Your Answer"
            autoComplete="off"
          />
        </div>
      </div>
    </>
  );
};

export default HeightQuestion;
