import { scaleOrdinal, format, utcFormat, timeFormat } from "d3";

/**
 * TODO: possible import
 * This object is copied from tailwind.config.js. ideally this would be imported
 * (ref: https://tailwindcss.com/docs/configuration#referencing-in-java-script)
 * but react prevents relative imports from outside of src */
const colors = {
  GO: {
    // protective
    gradientLight: "#BFE3D9", // TODO: remove once old plan is deprecated
    gradientFrom: "#BFE3D9",
    gradientTo: "#6CA7FF",
    fill: "#6CA7FF",
    stroke: "#4A8DF2",
    hover: "#4D8AFF",
    scoreGradientFrom: "#DFEAFC", // for score tracker area chart gradient
    scoreGradientTo: "#6CA7FF",
    scoreMarkerFill: "#DFEAFC",
  },
  NE: {
    // neutral
    gradientLight: "#D4D0FF",
    gradientFrom: "#D6D6FF",
    gradientTo: "#C99ADA",
    oldFill: "#C99ADA", // for backwards compatability for planv1 gradients
    fill: "#C29ED5",
    stroke: "#C29ED5",
    hover: "#B57DCC",
  },
  BA: {
    // disruptive
    gradientLight: "#FFE484",
    gradientFrom: "#FFE484",
    gradientTo: "#FFA684",
    fill: "#FFA684",
    stroke: "#FF7E4D",
    hover: "#FF8A65",
    scoreGradientFrom: "#FFA684", // for score tracker area chart gradient
    scoreGradientTo: "#FFEDE6",
    scoreMarkerFill: "#FFEDE6",
  },
  UN: {
    // unknown
    gradientLight: "#E0BDEC",
    gradientFrom: "#D6D6FF",
    gradientTo: "#99AAFF",
    oldFill: "#D6D6FF", // for backwards compatability for planv1 gradients
    fill: "#CEDEFC",
    stroke: "#BEB4FC",
    hover: "#BBD1FC",
  },
};

const fill = scaleOrdinal()
  .domain(["NE", "GO", "BA", "UN"])
  .range([
    colors["NE"].fill,
    colors["GO"].fill,
    colors["BA"].fill,
    colors["UN"].fill,
  ]);

const typeDictionary = {
  GO: {
    label: "protective",
    description: "Can protect a healthy vaginal microbiome",
  },
  BA: {
    label: "disruptive",
    description: "Can disrupt a healthy vaginal microbiome",
  },
  NE: {
    label: "variable",
    description:
      "Can be found in both healthy and disrupted vaginal microbiomes",
  },
  UN: {
    label: "unknown",
    description:
      "Research has not determined its role in the vaginal microbiome",
  },
};

const textClasses = {
  GO: {
    fill: "text-GO-fill",
    hover: "text-GO-hover",
  },
  BA: {
    fill: "text-BA-fill",
    hover: "text-BA-hover",
  },
  NE: {
    fill: "text-NE-fill",
    hover: "text-NE-hover",
  },
  UN: {
    fill: "text-UN-fill",
    hover: "text-UN-hover",
  },
};

const gradientClasses = {
  GO: {
    from: "from-GO-gradientFrom",
    to: "to-GO-gradientTo",
  },
  BA: {
    from: "from-BA-gradientFrom",
    to: "to-BA-gradientTo",
  },
  NE: {
    from: "from-NE-gradientFrom",
    to: "to-NE-gradientTo",
  },
  UN: {
    from: "from-UN-gradientFrom",
    to: "to-UN-gradientTo",
  },
};

const bgClasses = {
  GO: {
    fill: "bg-GO-fill",
    hover: "bg-GO-hover",
  },
  BA: {
    fill: "bg-BA-fill",
    hover: "bg-BA-hover",
  },
  NE: {
    fill: "bg-NE-fill",
    hover: "bg-NE-hover",
  },
  UN: {
    fill: "bg-UN-fill",
    hover: "bg-UN-hover",
  },
};

const stroke = scaleOrdinal()
  .domain(["NE", "GO", "BA", "UN"])
  .range([
    colors["NE"].stroke,
    colors["GO"].stroke,
    colors["BA"].stroke,
    colors["UN"].stroke,
  ]);

const formatters = {
  fpct: format(".1%"),
  numShort: format(".1f"),
  num: format(".2f"),
  numLong: format(".3f"),
  numTrunc: format(".0f"),
  trunc: (string) => (string ? `${string.substr(0, 30)}...` : ""),
  date: timeFormat("%B %e, %Y"),
  abbrDate: utcFormat("%b %e, %Y"),
  pdfDate: utcFormat("%m_%d_%y"),
  slashDate: utcFormat("%m/%d/%Y"),
  slashDateAbbr: utcFormat("%m/%d/%y"),
  monthDayDate: utcFormat("%m/%d"),
};

const secondaryColors = {
  "dv-pond": "#41907A",
  "dv-pink": "#D272FF",
  "dv-apple": "#731C00",
  "dv-terra": "#A8643D",
  "dv-frog": "#99A866",
  "dv-sage": "#BFBDA7",
  "evvy-blue": "#6CA7FF",
  "evvy-pine": "#074C4C",
  "evvy-black": "#11161A",

  /* other site colors */
  coral: "#FFA684",
  marine: "#074C4C",
  "crazy-purple": "#D272FF",
  "image-placeholder": "#c4c4c4",
  "evvy-black-dull": "#11161a4d", // it's black but at 30% opacity
};

const tagToColorMapping = {
  Maintenance: "NE",
  "Promote Protective": "GO",
  "Reduce Disruptive": "BA",
};

const categoryToColorMap = {
  protective: colors["GO"],
  variable: colors["NE"],
  disruptive: colors["BA"],
  unknown: colors["UN"],
};

const bacteriaTypeToCodeMap = {
  protective: "GO",
  variable: "NE",
  disruptive: "BA",
  unknown: "UN",
};

// copied from tailwind.css due to relative import object named above
const breakpoints = {
  xs: 530,
  sm: 640,
  md: 770,
  lg: 1024,
};

const trendsTablePillColors = {
  black: "#11161A",
  green: "#41907A",
  red: "#DB6363",
};

export function convertToTSV(data) {
  const tsvHeaders = ["Bacteria Name", "Description", "Percent"];
  const tsvData = data.map((d) =>
    [d.bacteria.short_name, d.bacteria.description, d.normalized_percent].join(
      "\t"
    )
  );
  return [
    "data:text/csv;charset=utf-8,",
    tsvHeaders.join("\t"),
    ...tsvData,
  ].join("\r\n");
}

export {
  fill,
  stroke,
  formatters,
  breakpoints,
  typeDictionary,
  colors,
  secondaryColors,
  tagToColorMapping,
  textClasses,
  bgClasses,
  gradientClasses,
  categoryToColorMap,
  bacteriaTypeToCodeMap,
  trendsTablePillColors,
};
