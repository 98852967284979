/* Research modal template - opens from search info icon click */
// external
import React from "react";
import { useDispatch } from "react-redux";
// components
import CloseX from "../../common/closeX";

// lib
import { researchModalCopy as COPY } from "../../../pages/plan_unfurled/constants.tsx";
import { setResearchModalOpen } from "../../../pages/plan/planSlice";

const ResearchTemplate = ({ handleClose }) => {
  /* Redux */
  // redux dispatch method for updating global store
  const dispatch = useDispatch();

  /* Actions */
  const onClose = () => {
    dispatch(setResearchModalOpen(false));
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <div className={`w-full flex flex-col p-10 bg-evvy-white text-left`}>
      {/* close button */}
      <div className="flex justify-end items-center">
        <CloseX handleClose={onClose} textColorClass="text-evvy-black" />
      </div>
      {/* title */}
      <div className="w-full t1 text-evvy-pine">{COPY.TITLE}</div>
      {/* description */}
      <div>
        {COPY.DESCRIPTION.split("|")?.map((line, i) => (
          <div className="caption my-4" key={`line-${i}`}>
            {line}
          </div>
        ))}
      </div>
      {/* research statuses */}
      <div>
        {COPY.STATUSES?.map(({ status = "", description = "" }, i) => (
          // no border bottom on last item
          <div
            key={`${status}-${i}`}
            className={`mt-2 pb-2 ${
              COPY.STATUSES?.length === i + 1
                ? ""
                : "border-b border-evvy-pine/15"
            }`}
          >
            <div className="b2 semibold text-evvy-pine">{status}</div>
            <div className="caption">{description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResearchTemplate;
