import Tippy from "@tippyjs/react";

export const QuestionMarkTooltip = ({
  className = "",
  tooltipText,
}: {
  className?: string;
  tooltipText: string;
}) => {
  return (
    <Tippy
      arrow
      render={() => (
        <div
          id="tooltip"
          role="tooltip"
          className="flex bg-evvy-pine p-3 rounded-md max-w-sm"
          tabIndex={-1}
        >
          <div className="text-evvy-white">{tooltipText}</div>
        </div>
      )}
      animation={false}
    >
      <div
        className={`${className} w-4 h-4 my-auto rounded-full flex items-center justify-center border border-black`}
      >
        <span className="text-[12px]">?</span>
      </div>
    </Tippy>
  );
};
