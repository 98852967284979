import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Helmet } from "react-helmet";

import AlreadySubmitted from "../../../components/healthContext/alreadySubmittedMessage";
import TestLaterModal from "../../../components/sample/testLaterModal";

import { testsService } from "../../../services/tests";
import {
  eventNames,
  sendViewedHealthHistorySection,
} from "../../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../../utils/analytics/helpers";

const TestSample = ({ test, healthContext }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [laterModalOpen, setLaterModalOpen] = useState(false);

  // route params
  const { testHash: hash } = useParams();
  const navigate = useNavigate();

  const navigateToNextPage = () => {
    // navigate to additional upsell screen if feature is enabled and test is ldt and they haven't already paid
    if (test.is_ldt && !test.has_vpcr_test && test.expan_upsell_enabled) {
      navigate(`/tests/${hash}/additional/info/`);
    } else {
      // otherwise go to health history
      navigate(`/tests/${hash}/history/`);
    }
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    sendViewedHealthHistorySection({
      section: "sample",
      sectionStatus: "blank",
      testOrder: test.test_order,
      testHash: hash,
      version: 1,
    });
  }, []); // Should always start at top of screen

  // don't render any sample until we've loaded up the health context
  if (!healthContext) return "";

  return (
    <div>
      <Helmet>
        <title>Test | Sample</title>
      </Helmet>
      {healthContext && healthContext.health_history_submitted_at ? (
        <AlreadySubmitted />
      ) : (
        ""
      )}

      <Formik
        initialValues={{
          sample_ph: healthContext.sample_ph || "",
          taken: healthContext && healthContext.sample_taken_at ? true : false,
        }}
        validate={(values) => {
          var errors = {};
          // if (!values.sample_ph){
          //   errors['sample_ph'] = 'Please enter a value for pH';
          // } else if (values.sample_ph < 0){
          //   errors['sample_ph'] = 'Please enter a value greater than 0';
          // } else if (values.sample_ph > 14){
          //   errors['sample_ph'] = 'Please enter a value less than 14';
          // } else if (!String(values.sample_ph).includes('.')){
          //   // this throws an error on 8.0 -> the string becomes "8"
          //   // errors['sample_ph'] = 'pH should contain 1 decimal point';
          // }

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          // if the test is already submitted, just go to the health context
          if (test.healthcontext.sample_taken_at) {
            navigateToNextPage();
            return;
          }

          // otherwise make the request
          setError(null);
          setLoading(true);
          testsService.submitSample(
            hash,
            values.sample_ph,
            (data) => {
              setLoading(false);
              navigateToNextPage();
            },
            (error, response) => {
              setError(response);
              setLoading(false);
            }
          );
        }}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <Form className="mb-0">
            <div className="block sm:flex w-full">
              <div className="flex-1 sm:h-60">
                <h2>
                  Time to{" "}
                  <span className="bg-coral rounded-sm px-1 -mx-1">test!</span>
                </h2>
              </div>

              <div className="flex-1 flex flex-col font-medium">
                <p className="text-lg">
                  Grab your test kit and follow the instructions to take your
                  sample.{" "}
                </p>
                <p className="text-lg">
                  Make sure to come back here to finish the activation process!
                </p>

                <div className="mt-auto rounded-md bg-highlighter-yellow flex p-4 items-center">
                  <div role="group" aria-labelledby="checkbox-group">
                    <label className="block cursor-pointer" key={"taken"}>
                      <Field
                        type="checkbox"
                        name="taken"
                        onClick={analyticsClickHandler({
                          eventName:
                            eventNames.HEALTH_HISTORY_FORM_CLICKED_SAMPLE_TAKEN,
                          eventArgs: {
                            testOrder: test.test_order,
                            testHash: hash,
                            version: 1,
                          },
                        })}
                        className={`border-none w-6 h-6 rounded-sm shadow-none cursor-pointer focus:ring-0 focus:outline-0 text-evvy-blue opacity-100 bg-white active:bg-white checked:bg-evvy-blue active:bg-evvy-blue hover:bg-evvy-blue focus:bg-evvy-blue`}
                      />
                      <span className="ml-4">I've taken my sample</span>
                    </label>
                  </div>
                </div>
              </div>

              {/*<div className="flex-1 flex flex-col">
                  <p>Refer to the instructions guide in your kit to take your swab sample and check your pH.</p>
                  <p>Enter your pH below when you’re done!</p>

                  {error || errors['sample_ph'] ? (
                    <div className="mt-auto bg-coral p-2 px-3 font-medium rounded-sm">
                      {error || errors['sample_ph']}
                    </div>
                  ) : ''}

                  <div className="mt-auto rounded-md bg-highlighter-yellow flex p-4 items-center">
                    <div className="mr-4 font-semibold">pH Result: </div>
                    <Field name="sample_ph" type="number" step="0.1" className="py-1 px-2 border-0 border-b border-evvy-black w-20 bg-transparent outline-none focus:ring-0 focus:border-evvy-black"/>
                  </div>
                </div>*/}
            </div>

            <div className="border-t border-dashed mt-10 py-10 flex items-center">
              <div className="flex-1">
                <div
                  className="uppercase inline-block underline font-semibold tracking-wider uppercase cursor-pointer"
                  onClick={() => setLaterModalOpen(true)}
                >
                  ← I'll test later
                </div>
              </div>
              <div className="flex-1">
                <button
                  type="submit"
                  disabled={loading || !values.taken}
                  className="cursor-pointer w-full flex-1 tracking-wider py-6 px-10 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none"
                >
                  Continue →
                </button>
              </div>
              <div className="hidden sm:block flex-1"></div>
            </div>
          </Form>
        )}
      </Formik>

      {laterModalOpen ? (
        <TestLaterModal closeModal={() => setLaterModalOpen(false)} />
      ) : (
        ""
      )}
    </div>
  );
};

export default TestSample;
