import { useEffect, useState } from "react";
import Layout from "../../components/layout";

// components
import LoadingSpinner from "../../components/common/loadingSpinner";
import ThreadPreview from "../../components/care/messages/threadPreview";

// lib
import { getIsMobile } from "../../utils/general";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import {
  eventNames,
  sendMessagingViewMessagesHome,
} from "../../utils/analytics/customEventTracking";
import { useIntercom } from "react-use-intercom";

// services
import { testsService } from "../../services/tests";

const MessagesHome = ({ consults, loading }) => {
  /*
   * State
   */
  const [currentConsult, setCurrentConsult] = useState(null);
  const consultsWithMessages = consults.filter(
    (consult) => consult.consultmessagethread
  );
  const allClosed = consultsWithMessages.every((c) => c?.is_closed);
  const isMobile = getIsMobile();
  const { show } = useIntercom();

  // Effects
  useEffect(() => {
    fetchLatestReadyTest(recordViewedPage);
  }, []);

  /* Actions */
  // fetch user's latest ready test
  const fetchLatestReadyTest = (onSuccess) => {
    testsService.fetchLatestReadyTest(
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error, response) => {
        console.error(response);
      }
    );
  };

  // record analytics event
  const recordViewedPage = (response) => {
    const consult = response.data?.latest_vaginitis_consult;
    setCurrentConsult(consult);
    const test = response.data;
    sendMessagingViewMessagesHome({
      consultId: consult?.uid,
      testHash: test?.hash,
      numThreads: consultsWithMessages?.length,
    });
  };

  return (
    <Layout
      title="Care | Messages"
      padded={!isMobile}
      bgClass="bg-evvy-cream"
      full
    >
      <div className="max-w-5xl w-full mx-auto px-4">
        {isMobile ? (
          <h3 className="py-3">Your messages</h3>
        ) : (
          <h2>Your messages</h2>
        )}
        {allClosed && (
          <div className="pb-8">
            <span className="b1 regular">
              Messages are only active when your treatment is in review. Have a
              question?{" "}
            </span>
            <span
              onClick={analyticsClickHandler({
                eventName: eventNames.MESSAGING_CLICKED_SUPPORT,
                eventArgs: {
                  location: "messaging_home",
                },
                clickHandler: () => show(),
              })}
              className="uppercase font-bold underline underline-offset-4 cursor-pointer"
            >
              {" "}
              Contact Evvy Support.
            </span>
          </div>
        )}
        {!loading && consultsWithMessages.length === 0 ? (
          <div>You have no messages!</div>
        ) : !loading && consultsWithMessages.length > 0 ? (
          consultsWithMessages.map((consult) => (
            <ThreadPreview
              consult={
                consult.test_hash === currentConsult?.test_hash
                  ? currentConsult
                  : consult
              }
              key={consult.test_hash}
            />
          ))
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </Layout>
  );
};

export default MessagesHome;
