import { postRequest, getRequest, patchRequest } from "../utils/axios";

export const surveyService = {
  getHdyhauSurvey,
  createHdyhauSurvey,
};

function createHdyhauSurvey(data, onSuccess, onError) {
  return postRequest(
    "/api/v1/survey/",
    // start out with just hdyhau survey always
    { survey_type: "HD", ...data },
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function getHdyhauSurvey(onSuccess, onError) {
  return getRequest(
    `/api/v1/survey/?survey_type=HD`,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}
