import { useEffect, useState } from "react";
import { getDynamicLink } from "../services/dynamicLink";
import { useParams } from "react-router-dom";

export const LinkRedirect = () => {
  const { hash } = useParams();
  async function getAndGoToRedirectLink(hash: string) {
    try {
      const response = await getDynamicLink(hash);

      if (response) {
        const checkout_url = response.data.checkout_url;
        window.location.replace(checkout_url);
      }
    } catch {
      console.error("Error fetching dynamic link. Please try again.");
    }
  }

  useEffect(() => {
    if (hash) {
      getAndGoToRedirectLink(hash);
    }
  }, []);

  return (
    <div className="bg-evvy-white rounded-xl py-8 px-4 md:px-8 flex pt-4">
      <div className={"font-semibold text-lg"}>
        Redirecting you to the checkout page...
      </div>
    </div>
  );
};
