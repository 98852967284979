import React from "react";
import CloseX from "../closeX";
import Check from "../check";
import Circle from "../circle";
import { titleSplit } from "../utils";
import { analyticsClickHandler } from "../../../utils/analytics/helpers";
import { eventNames } from "../../../utils/analytics/customEventTracking";

const LearnMoreContentTemplate = ({ data, handleClose }) => {
  const {
    title,
    splitDescription,
    section,
    description_cta,
    planitemcitation_set,
    color,
    slug,
  } = data;
  const showCitations = planitemcitation_set.length !== 0;

  return (
    <div className="content">
      <div className="p-8 bg-evvy-white flex">
        {section === "TR" ? (
          <Check />
        ) : (
          <Circle textColorClass={`text-${color}`} />
        )}
        <div className="t1 w-4/5">
          {section === "TR"
            ? titleSplit(title, "You may want to")
            : titleSplit(title, "Since you told us", `text-${color}`)}
        </div>
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>
      <div className="p-8 bg-dv-pond text-evvy-white">
        <div
          className={
            showCitations && "border-b border-dashed border-evvy-white"
          }
        >
          {splitDescription.map((string) => (
            <div className="my-4">{string}</div>
          ))}
          {/* TODO: possibly split this into text and url so we can use linked text component and add arrow */}
          <div
            className="t4 my-4"
            dangerouslySetInnerHTML={{ __html: description_cta }}
          />
        </div>
        <div className="my-4">
          {showCitations && <div className="t4 my-2">Our Sources</div>}
          {showCitations &&
            planitemcitation_set.map((citation) => (
              <div className="my-2 underline">
                <a
                  key={citation.title}
                  href={citation.url}
                  onClick={analyticsClickHandler({
                    eventName: eventNames.CLICKED_SOURCE,
                    eventArgs: {
                      slug,
                      groupName: null,
                      citationTitle: citation.title,
                      planVersion: "v1",
                    },
                  })}
                  target="_blank"
                >
                  {citation.title}
                </a>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(LearnMoreContentTemplate);
