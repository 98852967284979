import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Helmet } from "react-helmet";

import AlreadySubmitted from "../../../../components/healthContext/alreadySubmittedMessage";
import { ReactComponent as AlertTriangle } from "../../../../components/common/icons/alert-triangle.svg";

import { testsService } from "../../../../services/tests";
import {
  eventNames,
  sendViewedHealthHistorySection,
} from "../../../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../../../utils/analytics/helpers";
import { HealthContextFields, Test } from "../../../../types/test";
import {
  Page,
  getHealthHistoryURL,
  getNextPage,
} from "../../../../utils/healthHistoryV2";
import FormFooter from "../../../../components/healthHistoryV2/questions/formFooter";

const TakeSampleConfirm = ({
  currentPage,
  test,
  healthContext,
  fetchHealthContext,
  ldtEnabled,
}: {
  currentPage: Page;
  test: Test;
  healthContext: HealthContextFields;
  fetchHealthContext: any;
  ldtEnabled: boolean;
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState<boolean>();

  // route params
  const { testHash: hash } = useParams();
  const navigate = useNavigate();

  const navigateToNextPage = () => {
    window.scrollTo(0, 0);
    const nextPage = getNextPage({
      currentPage,
      healthContextValues: healthContext,
      test,
      ldtEnabled: ldtEnabled,
      expanUpsellEnabled: test?.expan_upsell_enabled,
    });
    const url = getHealthHistoryURL(hash, nextPage?.slug);
    navigate(url);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    sendViewedHealthHistorySection({
      section: "sample",
      sectionStatus: "blank",
      testOrder: test.test_order,
      testHash: hash,
      version: 2,
    });
  }, []); // Should always start at top of screen

  // don't render any sample until we've loaded up the health context
  if (!healthContext) return <></>;

  return (
    <div className="bg-white p-4 rounded-b-lg">
      <Helmet>
        <title>Test | Sample</title>
      </Helmet>
      {healthContext && healthContext.health_history_submitted_at ? (
        <AlreadySubmitted />
      ) : (
        ""
      )}

      <Formik
        initialValues={{
          taken: healthContext && healthContext.sample_taken_at ? true : false,
        }}
        validate={(values) => {
          var errors = {};
          if (!values.taken) {
            errors = { taken: "You must confirm you've taken your sample" };
          }
          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          // if the test is already submitted, just go to the health context
          if (healthContext.sample_taken_at) {
            navigateToNextPage();
            return;
          }
          // otherwise make the request
          setError(null);
          setLoading(true);
          testsService.submitSample(
            hash!,
            undefined,
            () => {
              fetchHealthContext(test.hash);
              setLoading(false);
              navigateToNextPage();
            },
            (error: any, response: any) => {
              setError(response);
              setLoading(false);
            }
          );
        }}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <Form className="mb-0">
            <div className="max-w-full sm:max-w-4xl mx-auto p-2 sm:px-0">
              <div className="mt-auto rounded-md bg-highlighter-mint flex p-4 items-center">
                <div role="group" aria-labelledby="checkbox-group">
                  <label className="block cursor-pointer" key={"taken"}>
                    <Field
                      type="checkbox"
                      name="taken"
                      onClick={analyticsClickHandler({
                        eventName:
                          eventNames.HEALTH_HISTORY_FORM_CLICKED_SAMPLE_TAKEN,
                        eventArgs: {
                          testOrder: test.test_order,
                          testHash: hash,
                        },
                      })}
                      className={`border-2 border-black border-opacity-20 w-[16px] h-[16px] rounded-sm shadow-none cursor-pointer checked:ring-0 focus:ring-0 text-evvy-blue opacity-100 bg-white active:bg-white checked:bg-evvy-blue`}
                    />
                    <span className="ml-4">I've taken my sample</span>
                  </label>
                </div>
              </div>
            </div>
            {errors && Object.keys(errors).length > 0 && (
              <div className="bg-coral p-2 px-3 font-medium rounded-md flex">
                <AlertTriangle className="stroke-evvy-black h-4 w-5 mr-1" />
                {errors["taken"]}
              </div>
            )}
            <FormFooter handleSubmit={handleSubmit} errors={errors} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TakeSampleConfirm;
