import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import DemographicsForm from "../../../components/healthHistory/demographicsForm";
import RelatedDiagnosesForm from "../../../components/healthHistory/relatedDiagnosesForm";
import MenstrualHealthForm from "../../../components/healthHistory/menstrualHealthForm";
import HormonalHealthForm from "../../../components/healthHistory/hormonalHealthForm";
import PregnancyForm from "../../../components/healthHistory/pregnancyForm";
import AdditionalForm from "../../../components/healthHistory/additionalForm";
import QualityOfLifeForm from "../../../components/healthHistory/qualityOfLifeForm";

import InvalidForm from "../../../components/healthHistory/invalidForm";
import AlreadySubmitted from "../../../components/healthContext/alreadySubmittedMessage";

import { testsService } from "../../../services/tests";

// this is a page used to fill out a section of the health history form
// it'll contain that form's page, and navigation to go forward/back
const HistoryPage = ({
  healthContext,
  test,
  updateHealthContext,
  hdyhauSurvey,
  submitHdyhauSurvey,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(null);

  // route params
  const { testHash: hash, page } = useParams();
  const navigate = useNavigate();

  // available pages
  const defaultPages = [
    "demographics",
    "quality-of-life",
    "diagnoses",
    "menstrual",
    "hormonal",
    "pregnancy",
    "additional",
  ];
  const additionalPages = []; // we might at some point add additional pages depending on the user's source
  const allPages = [...defaultPages, ...additionalPages];

  // get current page's context regarding all pages
  const isValidPage = allPages.includes(page);
  const pageIndex = isValidPage ? allPages.indexOf(page) : null; // starts at 0. increment page number to user appropriately
  const currentPageName = isValidPage ? allPages[pageIndex] : "invalid";
  const isFirstPage = isValidPage ? pageIndex === 0 : null;
  const isLastPage = isValidPage ? pageIndex === allPages.length - 1 : null;
  const previousPage = !isFirstPage ? allPages[pageIndex - 1] : null;
  const nextPage = !isLastPage ? allPages[pageIndex + 1] : null;

  const completedHistory =
    healthContext && healthContext.health_history_submitted_at;

  // actions
  const submitCurrentPage = (data, onError) => {
    // if user has already submitted their health history, just continue
    if (completedHistory) {
      if (nextPage) {
        navigate(`/tests/${hash}/history/${nextPage}/`);
        window.scrollTo(0, 0);
      } else {
        // done with the health context, go to done page
        navigate(`/tests/${hash}/done/`);
        window.scrollTo(0, 0);
      }
      return;
    }

    // submits current page then goes to next one if there is one
    setIsSubmitting(true);
    // submit hdyhau survey only if not previously submitted and user fills out the first question!
    if (data.hdyhau && !hdyhauSurvey.uid) {
      submitHdyhauSurvey(data);
    }
    updateHealthContext(
      hash,
      data,
      (newHealthContext) => {
        // setIsSubmitting(false)
        if (nextPage) {
          navigate(`/tests/${hash}/history/${nextPage}/`);
          setIsSubmitting(false);
          window.scrollTo(0, 0);
        } else {
          // done with the health context, mark completion date and go to success screen
          testsService.submitHealthHistory(
            hash,
            (response) => {
              navigate(`/tests/${hash}/done/`);
              setIsSubmitting(false);
              window.scrollTo(0, 0);
            },
            (error, response) => {
              alert(response);
              setIsSubmitting(false);
            }
          );
        }
      },
      (response) => {
        console.log("error submitting", response);
        setIsSubmitting(false);
        if (onError) {
          onError(response);
        }
      }
    );
  };

  // pass the pagination props to the subroutes (mainly for the footer)
  const subrouteProps = {
    submitCurrentPage: submitCurrentPage,
    test: test,
    hash: hash,
    isLastPage: isLastPage,
    previousPage: previousPage,
    pageIndex: pageIndex,
    totalPages: allPages.length,
    isSubmitting: isSubmitting,
    healthContext: healthContext,
  };
  const additionalFormProps = {
    hdyhauSurvey,
    ...subrouteProps,
  };

  // don't render any forms until we've loaded up the health context
  if (!healthContext) return "";

  const formElement = () => {
    switch (currentPageName) {
      case "demographics":
        return <DemographicsForm {...subrouteProps} />;
      case "quality-of-life":
        return <QualityOfLifeForm {...subrouteProps} />;
      case "diagnoses":
        return <RelatedDiagnosesForm {...subrouteProps} />;
      case "menstrual":
        return <MenstrualHealthForm {...subrouteProps} />;
      case "hormonal":
        return <HormonalHealthForm {...subrouteProps} />;
      case "pregnancy":
        return <PregnancyForm {...subrouteProps} />;
      case "additional":
        return <AdditionalForm {...additionalFormProps} />;
      default:
        return <InvalidForm />;
    }
  };

  return (
    <div>
      <Helmet>
        <title>Test | History | {currentPageName}</title>
      </Helmet>
      {completedHistory ? <AlreadySubmitted /> : ""}
      {
        // render the appropriate form
        formElement()
      }
    </div>
  );
};

export default HistoryPage;
