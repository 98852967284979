import { AxiosResponse, AxiosError } from "axios";
import { getRequest, putRequest } from "../utils/axios";

export const fetchOpenOrders = async () => {
  const response = await getRequest(
    "/api/v1/orders/?open=true",
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

export const attachOrderToUser = async (
  checkoutId: string | undefined,
  orderNumber: string | undefined
) => {
  const response = await putRequest(
    `/api/v1/orders/attach`,
    { order_number: orderNumber, checkout_id: checkoutId },
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};
