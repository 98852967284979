import { useState } from "react";
import { Consult, PrescriptionRecord } from "../../../types/care";
import { ReactComponent as ErrorIcon } from "../../../components/care/icons/red-info-circle.svg";
import { ReactComponent as ArrowDown } from "../../../components/common/icons/arrow-down-no-tail.svg";
import { ReactComponent as ArrowUp } from "../../../components/common/icons/arrow-up-no-tail.svg";
import { secondaryColors } from "../../../utils/viz";
import { getTagBackgroundClass } from "../../../components/care/utils";
import {
  sendClickedAddRefillToCart,
  sendClickedRetestForPrescription,
  sendViewedPrescriptionDetails,
} from "../../../utils/analytics/customEventTracking";
import PrescriptionLearnMoreButton from "../../../components/care/PrescriptionLearnMoreButton";

const PrescriptionCard = ({
  prescription,
  addToCart,
  orderTest,
  first,
  last,
  selected,
  hasTestInCart,
  openModal,
  consult,
}: {
  prescription: PrescriptionRecord;
  addToCart: ({
    prescriptions,
    test,
  }: {
    prescriptions?: PrescriptionRecord[];
    test?: boolean;
  }) => void;
  orderTest: () => void;
  selected: boolean;
  first: boolean;
  last: boolean;
  hasTestInCart: boolean;
  openModal: () => void;
  consult: Consult;
}) => {
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const roundTopStyles = `rounded-t-2xl`;
  const roundBottomStyles = `rounded-b-2xl`;
  const buttonStyles = `uppercase font-semibold tracking-wider text-sm w-full sm:my-0 my-4 sm:w-40 h-16 rounded-sm text-center block border border-evvy-black bg-evvy-white`;
  const tagBackgroundColorClass = getTagBackgroundClass(
    prescription.product.tag
  );

  const showNoRefills =
    !prescription.refills_remaining ||
    prescription.refills_remaining < 0 ||
    !prescription.is_refillable_product;

  return (
    <div
      className={`min-w-96 bg-white p-7 ${first && roundTopStyles} ${
        last && roundBottomStyles
      } ${!first && !last && "border-t border-b bg-evvy-black"} max-w-2xl`}
    >
      {showNoRefills && (
        <div className="uppercase font-semibold text-sm bg-highlighter-salmon w-fit py-2 px-4 mb-3">
          No refills available
        </div>
      )}
      <div className="sm:flex justify-between mb-5">
        <div className="flex">
          <img
            className="w-28 h-28 object-cover rounded-lg"
            src={prescription.product.image_url}
            alt="product"
          />
          <div className="pl-4">
            <p className={"medium pb-2 mb-0 text-[20px]"}>
              {prescription.product.title_short_display}
            </p>
            <p className="medium text-md pb-2 mb-0 text-[20px]">
              {prescription.is_refillable_product &&
              prescription.product.ecomm_product?.price
                ? `$${prescription.product.ecomm_product.price}`
                : "\u00A0"}
            </p>
            <PrescriptionLearnMoreButton
              consult={consult}
              location="refills_cart"
              prescriptionSlug={prescription.product?.slug}
              openModal={openModal}
            />
          </div>
        </div>
        {showNoRefills && (
          <button
            className={buttonStyles}
            onClick={() => {
              sendClickedRetestForPrescription({
                consultId: consult?.uid,
                prescriptionSlug: prescription.product.slug,
              });
              orderTest();
            }}
            disabled={hasTestInCart}
          >
            Re-test
          </button>
        )}
        {prescription.refills_remaining > 0 &&
          prescription.is_refillable_product && (
            <button
              className={buttonStyles}
              onClick={() => {
                sendClickedAddRefillToCart({
                  consultId: consult?.uid,
                  prescriptionSlug: prescription.product.slug,
                });
                addToCart({ prescriptions: [prescription] });
              }}
              disabled={selected}
            >
              Add refill
            </button>
          )}
      </div>
      <div className="w-full px-2 sm:bg-evvy-black opacity-20 h-px" />
      <div
        className={"flex cursor-pointer justify-between mt-4 md:mt-8"}
        onClick={() => {
          sendViewedPrescriptionDetails({
            consultId: consult?.uid,
            prescriptionSlug: prescription.product.slug,
          });
          setOpenDetails(!openDetails);
        }}
      >
        <div className="flex items-center">
          <p className="leading-none uppercase font-semibold text-sm mb-0 pb-0 pr-2">
            DETAILS
          </p>
          {(prescription.refills_remaining === 0 ||
            !prescription.is_refillable_product) && (
            <ErrorIcon className="mx-auto md:mx-0" />
          )}
        </div>
        <span className="ml-2 flex items-center">
          {openDetails ? (
            <ArrowUp fill={secondaryColors["evvy-black"]} />
          ) : (
            <ArrowDown fill={secondaryColors["evvy-black"]} />
          )}
        </span>
      </div>
      {openDetails && (
        <div>
          <div className="flex mt-8">
            <div className="pr-8 pb-4">
              <p className="font-semibold cone mb-4">Category:</p>
              <div
                className={`uppercase font-semibold text-sm bg-gradient-to-r ${tagBackgroundColorClass} w-fit py-2 px-4 mb-3`}
              >
                <p className="mb-0 pb-0">{prescription.product.tag}</p>
              </div>
            </div>
            {prescription?.is_refillable_product &&
              prescription?.refills_remaining > 0 && (
                <>
                  <div className="mr-8 h-24 bg-evvy-black opacity-20 w-px" />
                  <div>
                    <p className="font-semibold cone mb-4">Refills left:</p>
                    <p>{prescription.refills_remaining}</p>
                  </div>
                </>
              )}
          </div>
          {!prescription.refills_remaining &&
            prescription.is_refillable_product && (
              <div className="max-w-[460px]">
                <p className="text-md">
                  You’ve run out of prescribed refills for this treatment. We
                  recommend re-testing to see if this treatment still makes
                  sense for you.
                </p>
              </div>
            )}
          {!prescription.is_refillable_product && (
            <div className="max-w-[460px]">
              <p className="text-md">
                We do not currently offer refills for this treatment. We
                recommend re-testing to see if this treatment is right for you.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PrescriptionCard;
