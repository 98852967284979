import React, { useEffect, useState } from "react";
import { Switch, Route, Link } from "react-router-dom";
import Layout from "../../components/layout";

import { careService } from "../../services/care";
import { getTreatmentPlanUrlFromConsult } from "../../components/care/utils";

// Lists your past consults
const Consults = ({ consults }) => {
  // TODO: style this!
  return (
    <Layout title="Care | Consults" padded={true} bgClass="bg-evvy-cream" full>
      <h1>Your past consults</h1>
      {consults && consults.length ? (
        <div>
          {consults.map((c) => (
            <div key={c.uid}>
              <Link
                to={getTreatmentPlanUrlFromConsult(c)}
                className="underline cursor-pointer"
              >
                {c.uid}
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <div>No past consults...</div>
      )}
    </Layout>
  );
};

export default Consults;
