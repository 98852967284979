import React, { useState } from "react";
import { select } from "d3";
import { colors, emptyCopy } from "../../pages/compare/constants";
import ArrowListItem from "../common/arrowListItem";
import { ReactComponent as Info } from "../../components/common/icons/infoGray.svg";
import Tippy from "@tippyjs/react/headless";
import Markdown from "markdown-to-jsx";
import { secondaryColors } from "../../utils/viz";

/** TOOLTIP
 * This tooltip is on rollover of the info button when viewing the extra info on a microbe */
const Tooltip = ({ tooltipCopy }) => (
  <Tippy
    render={() => (
      <div
        id="tooltip"
        role="tooltip"
        className="hidden md:flex bg-evvy-white p-3 rounded-md max-w-sm border border-dashed border-black"
        tabIndex={-1}
      >
        <div className="text-evvy-black">
          <Markdown>{tooltipCopy.replace("|", "<br><br>")}</Markdown>
        </div>
      </div>
    )}
    placement="top"
    animation={false}
  >
    <Info
      stroke={secondaryColors["image-placeholder"]}
      className="ml-2 mb-0.5 w-4 h-4 inline"
    />
  </Tippy>
);

const TextCard = ({
  selectedValence,
  selectedValenceData,
  contextLanguage,
  compareDiffVersion,
}) => {
  if (selectedValenceData && selectedValenceData.length) {
    return (
      <table className="space-y-6 py-6 max-w-300">
        <tbody>
          {selectedValenceData.map(
            ({ short_name, percent, tooltip, is_new }, i) => (
              <tr key={i}>
                <td className="py-2 t3 border-none flex">
                  <div
                    className={`dot mr-4 my-auto rounded-full flex-shrink-0 text-${selectedValence}-fill bg-current`}
                  />
                  <span>
                    {short_name}
                    {compareDiffVersion && tooltip ? (
                      <Tooltip tooltipCopy={tooltip} />
                    ) : compareDiffVersion && is_new ? (
                      "*"
                    ) : (
                      ""
                    )}
                  </span>
                </td>
                <td className="py-2 t3 border-none">
                  {percent < 0.1 ? "<0.1" : percent}%
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    );
  }
  if (selectedValenceData && selectedValenceData.length === 0) {
    return <div className="content max-w-300">{emptyCopy.NO_VALENCE}</div>;
  }
  if (contextLanguage) {
    return (
      <div className="t3 h-fit max-w-300" role="list">
        {contextLanguage.map((contextLanguageItem, i) => (
          <ArrowListItem
            text={contextLanguageItem.text}
            coloredText={contextLanguageItem.coloredText}
            afterText={contextLanguageItem.outro}
            key={`contextItem-${i}`}
            bgColorClass={i % 2 === 0 ? "bg-evvy-white" : "bg-evvy-cream"} // every other row should be cream
            type={contextLanguageItem.type}
            capitalize
            noBorder={i === contextLanguage.length - 1} // do not apply bottom border at final list item
          />
        ))}
      </div>
    );
  }
  return <div></div>;
};

export default React.memo(TextCard);
