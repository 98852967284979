import { AxiosError, AxiosResponse } from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import LayoutAuth from "../../components/auth/layout";

import { authService } from "../../services/auth";
import {
  eventNames,
  sendLoginViewedPage,
  sendUserLoggedIn,
} from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { getParameterByName } from "../../utils/urls";
import { successMessageCopy, SuccessMessageKey } from "../constants";
import { attachOrderToUser } from "../../services/order";

const Login = ({ loginRoute = "" }) => {
  const isTreatmentLoginRoute = loginRoute === "treatment";
  const [userParams, setUserParams] = useState({
    orderNumber: "",
    checkoutID: "",
    email: "",
  });

  useEffect(() => {
    // Trigger analytics event
    sendLoginViewedPage({
      version: isTreatmentLoginRoute ? "ungated-rx" : "standard",
    });

    if (isTreatmentLoginRoute) {
      const userParams = new URLSearchParams(
        window.location.search.replace(/\+/g, "%2B")
      );
      setUserParams({
        orderNumber: decodeURIComponent(userParams.get("orderNumber") || ""),
        checkoutID: decodeURIComponent(userParams.get("checkoutID") || ""),
        email: decodeURIComponent(userParams.get("email") || ""),
      });
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] =
    useState<SuccessMessageKey | null>(getParameterByName("successMessage"));

  const getRegisterLink = () => {
    if (isTreatmentLoginRoute) {
      if (userParams.checkoutID || userParams.orderNumber) {
        let baseLoginUrl = "/treatments/register";

        if (userParams.orderNumber) {
          baseLoginUrl = baseLoginUrl.concat(
            `?orderNumber=${userParams.orderNumber}`
          );
        } else if (userParams.checkoutID) {
          baseLoginUrl = baseLoginUrl.concat(
            `?checkoutID=${userParams.checkoutID}`
          );
        }

        if (userParams.email) {
          baseLoginUrl = baseLoginUrl.concat(`&email=${userParams.email}`);
        }
        return encodeURI(baseLoginUrl);
      } else {
        return "/treatments/register/";
      }
    } else {
      return "/register/";
    }
  };

  const submitForm = (e: SyntheticEvent) => {
    e.preventDefault(); // to not post to the current url
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    const elementTarget = e.target as typeof e.target & {
      email: { value: string };
      password: { value: string };
    };
    authService.login(
      elementTarget.email.value,
      elementTarget.password.value,
      (response: AxiosResponse) => {
        setError(null);

        // For first time ungated RX logins, attempt to attach order to the user
        if (
          isTreatmentLoginRoute &&
          (userParams.checkoutID || userParams.orderNumber)
        ) {
          attachOrderToUser(userParams.checkoutID, userParams.orderNumber);
        }

        authService.fetchUser(
          () => {
            const next = getParameterByName("next", window.location);
            if (next) {
              window.location = next;
            } else {
              window.location.href = "/"; // take user to their account page
            }
            // trigger analytics event
            sendUserLoggedIn();
          },
          () => {
            console.error("Failed to fetch user info");
          }
        );
      },
      (error: AxiosError, response: { detail?: string }) => {
        setError(
          error && response && response.detail
            ? response.detail
            : "could not log in"
        );
        setLoading(false);
      }
    );
  };

  return (
    <LayoutAuth
      title={
        isTreatmentLoginRoute
          ? "Sign into your account to complete your order"
          : "Welcome back."
      }
      metaTitle="Evvy — Log In to Your Account"
      subtitle={isTreatmentLoginRoute ? null : "Sign in to your account."}
    >
      <form action="#" onSubmit={submitForm}>
        {successMessage && successMessageCopy[successMessage] ? (
          <div className="bg-highlighter-mint p-2 px-3 font-medium rounded-xs mb-6">
            {successMessageCopy[successMessage]}
          </div>
        ) : (
          ""
        )}

        {/* show error, but if incorrect password, show custom message */}
        {error === "No active account found with the given credentials" ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-xs flex -mt-3 mb-3 items-center">
            <div>The email or password you entered is incorrect.</div>
          </div>
        ) : error ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-xs mt-6">
            {error}
          </div>
        ) : (
          ""
        )}

        <div>
          <label htmlFor="email" className="block uppercase font-medium">
            Email Address
          </label>
          <div className="mt-1">
            <input
              id="email"
              style={{ fontSize: "16px" }}
              name="email"
              value={userParams.email}
              onChange={(e) =>
                setUserParams({ ...userParams, email: e.target.value })
              }
              type="email"
              autoFocus
              autoComplete="email"
              required
              className="appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black focus:outline-none focus:ring-transparent focus:border-crazy-purple sm"
            />
          </div>
        </div>

        <div className="mt-6">
          <label htmlFor="password" className="block uppercase font-medium">
            Password
          </label>
          <div className="mt-1">
            <input
              id="password"
              style={{ fontSize: "16px" }}
              name="password"
              type="password"
              autoComplete="current-password"
              required
              minLength={8}
              className="appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black focus:outline-none focus:ring-transparent focus:border-crazy-purple sm"
            />
          </div>
        </div>

        {/* show password reset link all the time */}
        <div className="text-right mt-6">
          <Link
            to="/password-reset/"
            onClick={analyticsClickHandler({
              eventName: eventNames.LOGIN_CLICKED_CTA,
              eventArgs: {
                version: isTreatmentLoginRoute ? "ungated-rx" : "standard",
                ctaText: "Forgot Password",
              },
            })}
            className="ml-auto underline uppercase tracking-wider text-sm font-semibold"
          >
            Forgot Password?
          </Link>
        </div>

        <div className="sm:flex items-center justify-between sm:flex-row-reverse mt-10">
          <button
            type="submit"
            className="cursor-pointer w-full sm:w-auto justify-center tracking-wider py-6 px-20 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
            disabled={loading}
            onClick={analyticsClickHandler({
              eventName: eventNames.LOGIN_CLICKED_CTA,
              eventArgs: {
                version: isTreatmentLoginRoute ? "ungated-rx" : "standard",
                ctaText: "Next",
              },
            })}
          >
            {loading ? "Next →" : "Next →"}
          </button>

          <div className="mr-auto mt-4 sm:mt-0">
            <div>
              New to Evvy?{" "}
              <Link
                to={getRegisterLink()}
                className="underline uppercase text-sm font-semibold tracking-wider"
                onClick={analyticsClickHandler({
                  eventName: eventNames.LOGIN_CLICKED_CTA,
                  eventArgs: {
                    version: isTreatmentLoginRoute ? "ungated-rx" : "standard",
                    ctaText: "Sign Up",
                  },
                })}
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </form>
    </LayoutAuth>
  );
};

export default Login;
