import { Link } from "react-router-dom";

import LayoutWelcome from "../../components/welcome/layout";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";

const WelcomeIntro = () => {
  const currentUser = useLoggedInUser();
  return (
    <LayoutWelcome
      metaTitle="Welcome"
      title={
        currentUser.identity.firstName
          ? currentUser.identity.firstName + ","
          : ""
      }
      subtitle={"Welcome to Evvy"}
    >
      <div className="mb-8 text-center">
        <div className="">
          <img
            className="mx-auto"
            src="/images/graphics/triple-welcome.png"
            loading="lazy"
            alt="Evvy Science Red"
          />
        </div>
        <div className="mt-8">
          <Link to="/tests/">
            <button className="cursor-pointer w-full sm:w-auto justify-center tracking-wider py-6 px-20 text-sm font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none">
              Let's go →
            </button>
          </Link>
        </div>
      </div>
    </LayoutWelcome>
  );
};

export default WelcomeIntro;
