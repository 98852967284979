import { AxiosError } from "axios";
import { careService } from "../../services/care";
import { Prescription, PrescriptionRecord } from "../../types/care";
import { sendClickedCheckoutForRefills } from "../../utils/analytics/customEventTracking";
import { cn } from "../../utils/cn";

interface CheckoutButtonProps {
  lineItems: {
    prescriptions: PrescriptionRecord[];
    test: boolean;
  };
  consultId?: string;
  prescriptionSlugs?: Prescription["slug"][];
  setError: (error: string | null) => void;
  location?: "footer" | "page";
}

const CheckoutButton: React.FC<CheckoutButtonProps> = ({
  lineItems,
  consultId,
  prescriptionSlugs,
  setError,
  location = "page",
}) => {
  const createAndOpenCheckoutLink = () => {
    careService.fetchRefillsCheckoutURL(
      consultId,
      {
        prescriptions: lineItems.prescriptions.map(
          (prescription) => prescription.uid
        ),
        test: lineItems.test,
      },
      (response: { data: { checkout_url: any } }) => {
        sendClickedCheckoutForRefills({
          consultId: consultId,
          lineItems: lineItems.test
            ? [
                ...lineItems.prescriptions?.map((item) => item.product.slug),
                "evvy-test",
              ]
            : [...lineItems.prescriptions?.map((item) => item.product.slug)],
          prescriptionsAvailable: prescriptionSlugs,
        });
        window.open(response.data.checkout_url, "_blank") ||
          window.location.replace(response.data.checkout_url);
      },
      (error: AxiosError) => {
        setError(
          error && error.response && error.response.data
            ? error.response.data
            : "Error fetching checkout URL."
        );
      }
    );
  };

  return (
    <>
      <button
        className={cn(
          "uppercase font-semibold tracking-wider text-sm rounded-sm text-center block bg-evvy-blue whitespace-nowrap",
          {
            "px-5 py-2": location === "footer",
            "w-full py-5": location === "page",
          }
        )}
        onClick={createAndOpenCheckoutLink}
        disabled={lineItems.prescriptions?.length === 0 && !lineItems.test}
      >
        Request →
      </button>
      <div className="mt-6 b4 sm:max-w-3xl">
        Your specific prescriptions will be determined at the sole discretion of
        an Evvy-affiliated provider based on your results, health profile, and
        clinical intake.
      </div>
    </>
  );
};

export default CheckoutButton;
