import { User } from "../../types/user";
import { eventHandlers } from "./customEventTracking";

const analyticsClickHandler =
  ({
    eventName,
    eventArgs,
    clickHandler,
  }: {
    eventName: string;
    eventArgs: any;
    clickHandler?: () => void;
  }) =>
  () => {
    eventHandlers[eventName](eventArgs);
    if (clickHandler) {
      clickHandler();
    }
  };

export { analyticsClickHandler };

interface CustomWindow extends Window {
  _sva: any; // Replace 'any' with a more specific type if known
}
declare let window: CustomWindow;

export const loadSurvicate = (currentUser: User, extraTraits = {}) => {
  const survicateKey = process.env.REACT_APP_SURVICATE_KEY;
  if (survicateKey) {
    // window._sva = window._sva || {};
    // survicate code for identifying the user attributes. Must be called before main survicate script is loaded
    // https://help.survicate.com/en/articles/3937812-target-your-survey-based-on-respondent-attributes
    (function (opts) {
      opts.traits = {
        email: currentUser.identity.email,
        ...extraTraits,
      };
    })((window._sva = window._sva || {}));

    const survicateScript = document.createElement("script");
    survicateScript.src = `https://survey.survicate.com/workspaces/${survicateKey}/web_surveys.js`;
    survicateScript.async = true;

    document.body.appendChild(survicateScript);

    return () => {
      // Remove the script when the component unmounts
      document.body.removeChild(survicateScript);
    };
  } else {
    console.log("Survicate not initialized since no key was provided.");
  }
};
