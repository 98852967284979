import React from "react";

import { typeDictionary, bgClasses } from "../../utils/viz";
import BacteriaTypesToolip from "../results_v2/bacteriaTypesTooltip";

const CompareVizLegend = ({
  hasInteractivityDisabled,
  wrapOnSm,
  dotConfig,
  textConfig,
  tooltipTextConfig,
  onMouseOver,
  onMouseOut,
  onClick,
  selected,
}) => (
  <div
    className={`cursor-default flex ${
      wrapOnSm ? "md:grid-cols-4" : "lg:grid-cols-4"
    } gap-3`}
  >
    {Object.entries(typeDictionary).map(([code, { label, description }]) => (
      <BacteriaTypesToolip
        text={description}
        key={`legend-dot-${code}`}
        textConfig={tooltipTextConfig && tooltipTextConfig}
      >
        <div
          className="flex"
          tabIndex={hasInteractivityDisabled ? -1 : 0}
          onMouseOver={() => (onMouseOver && onMouseOver(code)) || null}
          onMouseOut={() => (onMouseOut && onMouseOut(code)) || null}
          onClick={() => (onClick && onClick(code)) || null}
        >
          <div
            className={`${dotConfig ? dotConfig : "dot-legend"} rounded-full ${
              bgClasses[code].fill
            } flex-shrink-0 mr-2`}
            aria-hidden={true}
          />
          <div
            className={`border-b-2 border-transparent ${
              textConfig ? textConfig : "t4"
            } ${
              selected && typeDictionary[selected].label === label
                ? `border-evvy-black`
                : ""
            }`}
          >
            {label}
          </div>
        </div>
      </BacteriaTypesToolip>
    ))}
  </div>
);

export default React.memo(CompareVizLegend);
