import { facebookSupportGroupLink } from "../../pages/constants";
import { sendTestsClickedJoinCommunity } from "../../utils/analytics/customEventTracking";
import LinkedText from "../common/linkedText";
import twoGalsImage from "../common/images/twoGals.png";

export const CommunityCard: React.FC<{}> = () => {
  const JOIN_SUPPORT_GROUP_CTA_TEXT = "Join our support community";
  const SUPPORT_COMMUNITY_DESCRIPTION =
    "Join Evvy's Support Community for Vaginal Health (it's free!) to connect, share tips, learn about vaginal health, and more with other Evvy users.";

  return (
    <div className="bg-white p-6 sm:p-8 rounded-2xl">
      <div className="b2 medium pb-4">{SUPPORT_COMMUNITY_DESCRIPTION}</div>
      <LinkedText
        href={facebookSupportGroupLink}
        isLink
        arrow
        arrowRight
        onClick={sendTestsClickedJoinCommunity}
      >
        {JOIN_SUPPORT_GROUP_CTA_TEXT}
      </LinkedText>
      <img
        src={twoGalsImage}
        loading="lazy"
        alt="Evvy Community Support Group"
        className="rounded-2xl mt-4 h-44 w-full object-cover"
      />
    </div>
  );
};
