/**
 * A tag with status of research on a given recommendation
 * @param {string} status a string with the status of the research (e.g. 'Emerging')
 * @returns jsx of research status
 */
import React from "react";
import { useDispatch } from "react-redux";

// components
import { ReactComponent as Info } from "./icons/info.svg";
// lib
import { secondaryColors } from "../../utils/viz";
import { setResearchModalOpen } from "../../pages/plan/planSlice";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";

const ResearchItem = ({
  status = "",
  eventArgs = null,
  handleOpenModal = null,
}) => {
  /* Redux */
  // redux dispatch method for updating global store
  const dispatch = useDispatch();

  const handleOpen = () => {
    // if handleOpenModal isn't specified, then use global store
    if (handleOpenModal) {
      handleOpenModal();
    } else {
      dispatch(setResearchModalOpen(true));
    }
  };

  return (
    <div className="flex b2 gap-1">
      <button
        type="button"
        onClick={
          eventArgs
            ? analyticsClickHandler({
                eventName: eventNames.CLICKED_RESEARCH_STATUS,
                eventArgs,
                clickHandler: handleOpen,
              })
            : handleOpen
        }
      >
        <Info
          stroke={secondaryColors["dv-pond"]}
          fill={secondaryColors["dv-pond"]}
        />
      </button>
      <div className="ml-1 medium flex-shrink-0">Research Status:</div>
      <div>
        <span className="uppercase">{status[0]}</span>
        <span>{status.slice(1)}</span>
      </div>
    </div>
  );
};

export default ResearchItem;
