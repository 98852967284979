import React, { useEffect, useState, useRef } from "react";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import UpdateReminderMsg from "./updateReminderMessage";
import { indexCheckboxStyles, indexRadioStyles } from "../../utils/colors";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";
import {
  prefillAnswer,
  prefillMultiSelectAnswer,
  uncheckNoneChangeHandler,
} from "../../utils/answerUtils";

const DemographicsForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, test } = subrouteProps;
  // for tooltips
  const heightQuestion = useRef(null);
  const weightQuestion = useRef(null);
  const raceQuestion = useRef(null);
  const raceOtherQuestion = useRef(null);
  const bornWithVaginaQuestion = useRef(null);
  const bornWithVaginaOtherQuestion = useRef(null);
  const genderAffirmingCareQuestion = useRef(null);
  const genderIdentityQuestion = useRef(null);
  const genderIdentityOtherQuestion = useRef(null);

  const allQuestions = {
    demographics_height: heightQuestion,
    demographics_weight: weightQuestion,
    demographics_race: raceQuestion,
    demographics_race_other: raceOtherQuestion,
    demographics_born_with_vagina: bornWithVaginaQuestion,
    demographics_born_with_vagina_other: bornWithVaginaOtherQuestion,
    demographics_gender_affirming_care: genderAffirmingCareQuestion,
    demographics_gender_identity: genderIdentityQuestion,
    demographics_gender_identity_other: genderIdentityOtherQuestion,
  };

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      return apiErrors[key][0];
    }
  };

  const validateWeight = (weight) => {
    let errorMessage;
    if (weight && (weight < 50 || weight > 500)) {
      errorMessage = "Weight out of range";
    }
    return errorMessage;
  };

  const validateHeight = (feet, inches) => {
    let errorMessage;
    if (inches > 11) {
      errorMessage = "Inches must be less than 12";
    }
    const height = feet * 12 + inches;
    if (height && (height < 36 || height > 108)) {
      errorMessage = "Height out of range";
    }
    return errorMessage;
  };

  const populateInitialValues = () => {
    return {
      demographics_height_feet: prefillAnswer({
        questionName: "demographics_height",
        healthContext,
        transformationFn: (h) => parseInt(h / 12),
      }),
      demographics_height_inches: prefillAnswer({
        questionName: "demographics_height",
        healthContext,
        transformationFn: (h) => parseInt(h % 12),
      }),
      demographics_weight: prefillAnswer({
        questionName: "demographics_weight",
        healthContext,
      }),
      demographics_race: prefillMultiSelectAnswer({
        questionName: "demographics_race",
        healthContext,
      }),
      demographics_race_other: prefillAnswer({
        questionName: "demographics_race_other",
        healthContext,
      }),
      demographics_born_with_vagina: prefillAnswer({
        questionName: "demographics_born_with_vagina",
        healthContext,
        answerOptions: healthContext._options.demographics_born_with_vagina,
      }),
      demographics_born_with_vagina_other: prefillAnswer({
        questionName: "demographics_born_with_vagina_other",
        healthContext,
      }),
      demographics_gender_affirming_care: prefillAnswer({
        questionName: "demographics_gender_affirming_care",
        healthContext,
        answerOptions:
          healthContext._options.demographics_gender_affirming_care,
      }),
      demographics_gender_identity: prefillAnswer({
        questionName: "demographics_gender_identity",
        healthContext,
      }),
      demographics_gender_identity_other: prefillAnswer({
        questionName: "demographics_gender_identity_other",
        healthContext,
      }),
    };
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "profile",
      questionGroup: "demographics",
      testOrder: test.test_order,
      testHash: test.hash,
      version: 1,
    });
  }, []);

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1 mr-4">Demographics</h3>
        <div className="flex-1 leading-5">
          Age and ethnicity actually play a significant role in differences in
          the microbiome. By filling this in, you help us decode why that may
          be.
        </div>
      </div>

      {healthContext.past_health_history ? <UpdateReminderMsg /> : ""}

      <Formik
        initialValues={populateInitialValues()}
        validate={(values) => {
          var errors = {};
          Object.keys(allQuestions).forEach((key) =>
            ReactTooltip.hide(allQuestions[key].current)
          );

          // required questions
          const requiredQuestions = [
            "demographics_height_feet",
            "demographics_height_inches",
            "demographics_weight",
            "demographics_born_with_vagina",
            "demographics_gender_identity",
          ];
          requiredQuestions.forEach((key) => {
            if (!values[key] && values[key] !== 0) {
              // note that 0 is a perfectly fine answer (height inches)
              const errorKey = [
                "demographics_height_feet",
                "demographics_height_inches",
              ].includes(key)
                ? "demographics_height"
                : key; // convert this combined field
              errors[errorKey] = "This is a required question";
              ReactTooltip.show(allQuestions[errorKey].current); // manually show, without requiring hover
            }
            if (
              values["demographics_born_with_vagina"].includes("NO") &&
              !values["demographics_gender_affirming_care"]
            ) {
              errors["demographics_gender_affirming_care"] =
                "This is a required question";
              ReactTooltip.show(
                allQuestions["demographics_gender_affirming_care"].current
              );
            }
          });

          // required multiselect questions (multi select, needs at least 1)
          const requiredMultiselectQuestions = ["demographics_race"];
          requiredMultiselectQuestions.forEach((key) => {
            if (!values[key].length) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          // required "other" context
          const requiredOtherQuestions = [
            "demographics_race",
            "demographics_born_with_vagina",
            "demographics_gender_identity",
          ];
          requiredOtherQuestions.forEach((key) => {
            if (values[key].includes("OT") && !values[`${key}_other`]) {
              errors[`${key}_other`] = "This is a required question";
              ReactTooltip.show(allQuestions[`${key}_other`].current); // manually show, without requiring hover
            }
          });

          const errorMsg = validateWeight(values["demographics_weight"]);
          if (errorMsg) {
            errors["demographics_weight"] = errorMsg;
            ReactTooltip.rebuild();
            ReactTooltip.show(allQuestions["demographics_weight"].current);
          }

          const errorHeightMsg = validateHeight(
            values["demographics_height_feet"],
            values["demographics_height_inches"]
          );
          if (errorHeightMsg) {
            errors["demographics_height"] = errorHeightMsg;
            ReactTooltip.rebuild();
            ReactTooltip.show(allQuestions["demographics_height"].current);
          }

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          values["demographics_height"] =
            values["demographics_height_feet"] * 12 +
            values["demographics_height_inches"];
          submitCurrentPage(values, (response) => {
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((k) => {
                ReactTooltip.rebuild();
                ReactTooltip.show(allQuestions[k].current);
              });
            } else {
              setError(response || "Error saving health context");
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("demographics_height", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("demographics_height", errors, apiErrors)}
              data-for="demographics_height"
              ref={heightQuestion}
            >
              <ReactTooltip
                id="demographics_height"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("demographics_height")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <div className="flex">
                <div className="mr-4">
                  <label>Feet</label>
                  <Field
                    name="demographics_height_feet"
                    type="number"
                    className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                    autoComplete="off"
                  />
                </div>
                <div>
                  <label>Inches</label>
                  <Field
                    name="demographics_height_inches"
                    type="number"
                    className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("demographics_weight", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("demographics_weight", errors, apiErrors)}
              data-for="demographics_weight"
              ref={weightQuestion}
            >
              <ReactTooltip
                id="demographics_weight"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("demographics_weight")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <div>
                <Field
                  name="demographics_weight"
                  type="integer"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  autoComplete="off"
                />
              </div>
            </div>

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("demographics_race", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("demographics_race", errors, apiErrors)}
              data-for="demographics_race"
              ref={raceQuestion}
            >
              <ReactTooltip
                id="demographics_race"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("demographics_race")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <p>Please select all that apply</p>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.demographics_race.map((o, i) => (
                  <label className="block mb-3 cursor-pointer" key={o[0]}>
                    <Field
                      type="checkbox"
                      name="demographics_race"
                      value={o[0]}
                      className={indexCheckboxStyles(i)}
                      onChange={uncheckNoneChangeHandler({
                        handleChange,
                        setFieldValue,
                        fieldName: "demographics_race",
                        fieldValues: values.demographics_race,
                        noneCode: "PR",
                      })}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            {values.demographics_race.includes("OT") ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("demographics_race_other", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "demographics_race_other",
                  errors,
                  apiErrors
                )}
                data-for="demographics_race_other"
                ref={raceOtherQuestion}
              >
                <ReactTooltip
                  id="demographics_race_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("demographics_race_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="demographics_race_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("demographics_born_with_vagina", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError(
                "demographics_born_with_vagina",
                errors,
                apiErrors
              )}
              data-for="demographics_born_with_vagina"
              ref={bornWithVaginaQuestion}
            >
              <ReactTooltip
                id="demographics_born_with_vagina"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("demographics_born_with_vagina")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.demographics_born_with_vagina.map(
                  (o) => (
                    <label className="block mb-3 cursor-pointer" key={o[0]}>
                      <Field
                        type="radio"
                        name="demographics_born_with_vagina"
                        value={o[0]}
                        className={indexRadioStyles(null)}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  )
                )}
              </div>
            </div>
            {values.demographics_born_with_vagina.includes("NO") && (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError(
                    "demographics_gender_affirming_care",
                    errors,
                    apiErrors
                  )
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "demographics_gender_affirming_care",
                  errors,
                  apiErrors
                )}
                data-for="demographics_gender_affirming_care"
                ref={genderAffirmingCareQuestion}
              >
                <ReactTooltip
                  id="demographics_gender_affirming_care"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("demographics_gender_affirming_care")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <div role="group" aria-labelledby="checkbox-group">
                  {healthContext._options.demographics_gender_affirming_care?.map(
                    (o) => (
                      <label className="block mb-3 cursor-pointer" key={o[0]}>
                        <Field
                          type="radio"
                          name="demographics_gender_affirming_care"
                          value={o[0]}
                          className={indexRadioStyles(null)}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    )
                  )}
                </div>
              </div>
            )}

            {values.demographics_born_with_vagina.includes("OT") ? (
              <div
                className={`p-4 border rounded-md ${
                  getError(
                    "demographics_born_with_vagina_other",
                    errors,
                    apiErrors
                  )
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "demographics_born_with_vagina_other",
                  errors,
                  apiErrors
                )}
                data-for="demographics_born_with_vagina_other"
                ref={bornWithVaginaOtherQuestion}
              >
                <ReactTooltip
                  id="demographics_born_with_vagina_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("demographics_born_with_vagina_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="demographics_born_with_vagina_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("demographics_gender_identity", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError(
                "demographics_gender_identity",
                errors,
                apiErrors
              )}
              data-for="demographics_gender_identity"
              ref={genderIdentityQuestion}
            >
              <ReactTooltip
                id="demographics_gender_identity"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("demographics_gender_identity")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.demographics_gender_identity.map(
                  (o, i) => (
                    <label className="block mb-3 cursor-pointer" key={o[0]}>
                      <Field
                        type="radio"
                        name="demographics_gender_identity"
                        value={o[0]}
                        className={indexRadioStyles(i)}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  )
                )}
              </div>
            </div>

            {values.demographics_gender_identity.includes("OT") ? (
              <div
                className={`p-4 border rounded-md ${
                  getError(
                    "demographics_gender_identity_other",
                    errors,
                    apiErrors
                  )
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "demographics_gender_identity_other",
                  errors,
                  apiErrors
                )}
                data-for="demographics_gender_identity_other"
                ref={genderIdentityOtherQuestion}
              >
                <ReactTooltip
                  id="demographics_gender_identity_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("demographics_gender_identity_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="demographics_gender_identity_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DemographicsForm;
