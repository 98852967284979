import React, { useState } from "react";
import { TreatmentPlan } from "../../../types/care";
import { careService } from "../../../services/care";
import { sendTreatmentResetCalendar } from "../../../utils/analytics/customEventTracking";
import { CARE_SUPPORT_EMAIL } from "../../../pages/constants";

export const ConfirmCalendarResetModal: React.FC<{
  handleClose: () => void;
  data: {
    treatmentPlan: TreatmentPlan;
    refetch: () => void;
  };
}> = ({ handleClose, data }) => {
  const { treatmentPlan, refetch } = data || {};
  const [savingResetCalendar, setSavingResetCalendar] = useState(false);
  const [error, setError] = useState<string>();

  const resetCalendar = async () => {
    try {
      setSavingResetCalendar(true);
      await careService.resetCalendarToDefault(treatmentPlan.consult.uid);
      refetch();
      handleClose();
      sendTreatmentResetCalendar({
        consultUid: treatmentPlan.consult.uid,
        testHash: treatmentPlan.test.hash,
      });
      setSavingResetCalendar(true);
    } catch (e) {
      console.error(e);
      setError(
        `Unable to reset your calendar. Please try again or contact ${CARE_SUPPORT_EMAIL}.`
      );
    } finally {
      setSavingResetCalendar(false);
    }
  };

  return (
    <>
      <div
        className={`w-full flex flex-col pt-7 pb-20 sm:pb-7 px-8 bg-evvy-white`}
      >
        {error && <div className="text-red-500 text-center py-4">{error}</div>}
        <h3 className="text-2xl text-center">
          Resetting your treatment calendar will undo all modifications you've
          made to your treatments.
        </h3>
        <button
          className="bg-evvy-blue py-4 px-8 w-full rounded-sm font-semibold tracking-wider focus:outline-none"
          type="submit"
          disabled={savingResetCalendar}
          onClick={() => resetCalendar()}
        >
          Reset and Save
        </button>
        <button
          className="mt-2 border border-black py-4 px-8 w-full rounded-sm font-semibold tracking-wider focus:outline-none"
          type="submit"
          disabled={savingResetCalendar}
          onClick={() => handleClose()}
        >
          Cancel
        </button>
      </div>
    </>
  );
};
