export const StethoscopeIcon: React.FC<{
  className?: string; // background color
  strokeClassName?: string; // stethoscope color
}> = ({ className = "", strokeClassName = "" }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_5729_8311"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <circle cx="20" cy="20" r="20" fill="#074C4C" />
      </mask>
      <g mask="url(#mask0_5729_8311)">
        <circle
          cx="20"
          cy="20"
          r="20"
          fill={className ? className : "#D272FF"}
        />
        <path
          d="M22.4534 14.9196C22.4534 18.2566 19.8498 20.9764 16.6545 20.9764C13.4591 20.9764 10.8555 18.2566 10.8555 14.9196V12.5856C10.8555 11.949 11.388 11.4282 12.0389 11.4282H14.1494"
          stroke={strokeClassName ? strokeClassName : "white"}
          stroke-width="1.7"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M22.4522 14.9196V12.5856C22.4522 11.949 21.9196 11.4282 21.2687 11.4282H19.1582"
          stroke={strokeClassName ? strokeClassName : "white"}
          stroke-width="1.7"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M27.4836 24.3901C27.4836 27.3221 25.0575 29.714 22.0396 29.714C19.0218 29.714 16.5957 27.3414 16.5957 24.3901"
          stroke={strokeClassName ? strokeClassName : "white"}
          stroke-width="1.7"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M16.5957 20.9766V24.738"
          stroke={strokeClassName ? strokeClassName : "white"}
          stroke-width="1.7"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M27.4825 24.3907C29.0294 24.3907 30.2834 23.1644 30.2834 21.6517C30.2834 20.1389 29.0294 18.9126 27.4825 18.9126C25.9356 18.9126 24.6816 20.1389 24.6816 21.6517C24.6816 23.1644 25.9356 24.3907 27.4825 24.3907Z"
          stroke={strokeClassName ? strokeClassName : "white"}
          stroke-width="1.7"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};
