import React, { ReactNode } from "react";
import Header from "./header";
import Footer from "./footer";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { cn } from "../utils/cn";

const Layout = ({
  children,
  title,
  padded,
  full,
  bgClass,
  header = (
    <Header
      noBottomPadding={undefined}
      noRoundedBottom={undefined}
      bottomBanner={undefined}
    />
  ),
  noHeader,
  centered,
  extraFooter,
}: {
  children: React.ReactNode;
  title?: string;
  padded?: boolean;
  full?: boolean;
  bgClass?: string;
  header?: React.ReactNode;
  noHeader?: boolean;
  centered?: boolean;
  extraFooter?: React.ReactNode;
}) => (
  <div
    className={classNames("min-h-screen flex flex-col", {
      [bgClass || ""]: !!bgClass,
      "bg-white": !bgClass,
    })}
  >
    {title && (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    )}

    {!noHeader && header}

    <div
      className={`flex-grow flex flex-col ${
        padded ? "my-12 px-4 lg:px-0" : ""
      }`}
    >
      <div
        className={`mx-auto flex-grow flex flex-col w-full ${
          padded ? "max-w-6xl" : full ? "max-w-full" : "max-w-7xl"
        } ${centered ? "items-center" : ""}`}
      >
        {children}
      </div>
    </div>
    {extraFooter && extraFooter}
    <Footer />
  </div>
);
const LayoutStickyFooter: React.FC<{
  children?: ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "bg-evvy-dark-beige w-full sticky bottom-0 z-10 py-3.5 items-center flex flex-col sm:flex-row justify-between px-4 md:px-16 lg:px-[90px]",
        className
      )}
    >
      {children}
    </div>
  );
};

Layout.StickyFooter = LayoutStickyFooter;

export default Layout;
