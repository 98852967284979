import { Prescription } from "../../../types/care";
import { getTreatmentPurposeTagColor } from "../utils";

export const TreatmentDescriptionTag = ({
  treatment,
  className = "",
}: {
  treatment: Prescription;
  className?: string;
}) => {
  const purposeTagBGColor = getTreatmentPurposeTagColor(treatment.purpose_tag);

  return (
    <div className={className}>
      <div
        className={`${purposeTagBGColor} rounded-full p-1.5 inline-block mr-2 my-auto`}
      />
      <span className="b2 medium">{treatment.preview_short_description}</span>
    </div>
  );
};
