import axios from "axios";
import { authService } from "../services/auth";
import { getLoginNextPath } from "./general";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? `https://${process.env.REACT_APP_RENDER_API_SERVICE}.onrender.com`
    : "http://localhost:8000";
const allowed401s = [
  "/api/token/",
  "/api/token/refresh/",
  "/api/token/logout/",
]; // most 401s should take user to /login/. add valid 401s here

// set interceptors to pass auth token if we have it in the local storage
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// if response is 401 and it's not a retry, refresh access token
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error, response) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      !allowed401s.includes(originalRequest.url.replace(BASE_URL, ""))
    ) {
      originalRequest._retry = true;
      return postRequest(
        "/api/token/refresh/",
        { refresh: localStorage.getItem("refreshToken") },
        (response) => {
          const accessToken = response.data.access;
          localStorage.setItem("accessToken", accessToken);
          // console.log("got a new token")
          return axios(originalRequest);
        },
        (error, response) => {
          // log the user out if their refresh failed
          authService.logout();
          // if (onError){
          //   console.log("returnign onerror")
          //   return onError(error, response)
          // }
        }
      );
    }

    // return Error object with Promise
    return Promise.reject(error, error.response && error.response.data);
  }
);

const getRequest = async (url, successCallback, errorCallback) => {
  const queryParams = new URLSearchParams(window.location.search);
  const impersonate = queryParams.get("__impersonate");
  if (impersonate) {
    if (url.includes("?")) {
      url += `&__impersonate=${impersonate}`;
    } else {
      url += `?__impersonate=${impersonate}`;
    }
  }
  return axios
    .get(BASE_URL + url)
    .then((response) => {
      if (successCallback) {
        return successCallback(response);
      }
    })
    .catch((error) => {
      // take user to login if they just made an invalid 401 request
      if (
        error.response &&
        error.response.status === 401 &&
        !allowed401s.includes(url)
      ) {
        const searchParams = new URLSearchParams(window.location.search);
        window.location = getLoginNextPath(
          window.location.pathname,
          searchParams
        );
      } else {
        console.error(error);
        console.error(error?.response);
        if (errorCallback) {
          return errorCallback(error, error.response && error.response.data);
        }
      }
    });
};

const postRequest = (url, data, successCallback, errorCallback) => {
  return axios
    .post(BASE_URL + url, data)
    .then((response) => {
      if (successCallback) {
        return successCallback(response);
      }
    })
    .catch((error) => {
      // take user to login if they just made an invalid 401 request
      if (
        error.response &&
        error.response.status === 401 &&
        !allowed401s.includes(url)
      ) {
        const searchParams = new URLSearchParams(window.location.search);
        window.location = getLoginNextPath(
          window.location.pathname,
          searchParams
        );
      } else {
        // add error logging for all post requests
        console.error(error);
        console.error(error?.response);
        if (errorCallback) {
          return errorCallback(error, error.response && error.response.data);
        }
      }
    });
};

const patchRequest = (url, data, successCallback, errorCallback) => {
  return axios
    .patch(BASE_URL + url, data)
    .then((response) => {
      if (successCallback) {
        return successCallback(response);
      }
    })
    .catch((error) => {
      console.error(error);
      console.error(error?.response);

      // take user to login if they just made an invalid 401 request
      if (
        error.response &&
        error.response.status === 401 &&
        !allowed401s.includes(url)
      ) {
        const searchParams = new URLSearchParams(window.location.search);
        window.location = getLoginNextPath(
          window.location.pathname,
          searchParams
        );
      } else {
        if (errorCallback) {
          return errorCallback(error, error.response && error.response.data);
        }
      }
    });
};

const putRequest = (url, data, successCallback, errorCallback) => {
  return axios
    .put(BASE_URL + url, data)
    .then((response) => {
      if (successCallback) {
        return successCallback(response);
      }
    })
    .catch((error) => {
      // take user to login if they just made an invalid 401 request
      if (
        error.response &&
        error.response.status === 401 &&
        !allowed401s.includes(url)
      ) {
        const searchParams = new URLSearchParams(window.location.search);
        window.location = getLoginNextPath(
          window.location.pathname,
          searchParams
        );
      } else {
        console.error(error);
        console.error(error?.response);
        if (errorCallback) {
          return errorCallback(error, error.response && error.response.data);
        }
      }
    });
};

const deleteRequest = (url, successCallback, errorCallback) => {
  return axios
    .delete(BASE_URL + url)
    .then((response) => {
      if (successCallback) {
        return successCallback(response);
      }
    })
    .catch((error) => {
      // take user to login if they just made an invalid 401 request
      if (
        error.response &&
        error.response.status === 401 &&
        !allowed401s.includes(url)
      ) {
        const searchParams = new URLSearchParams(window.location.search);
        window.location = getLoginNextPath(
          window.location.pathname,
          searchParams
        );
      } else {
        console.error(error);
        console.error(error?.response);
        if (errorCallback) {
          return errorCallback(error, error.response && error.response.data);
        }
      }
    });
};

export { deleteRequest, getRequest, postRequest, patchRequest, putRequest };
