const toQueryString = (params) => {
  return (
    "?" +
    Object.keys(params)
      .map(function (key) {
        return key + "=" + params[key];
      })
      .join("&")
  );
};

const getParamsFromUrl = (location) => {
  // returns object of params from current url
  var search = location.search.substring(1);

  if (search === "") {
    return {};
  }

  return JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
};

const getParameterByName = (name, location) => {
  // location is optional
  name = name.replace(/[\[\]]/g, "\\$&"); // eslint-disable-line no-useless-escape
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(location ? location.href : window.location.href);
  if (!results) return null;
  if (!results[2]) return "";
  const parameterValue = decodeURIComponent(results[2].replace(/\+/g, " "));
  if (name === "next") {
    return whitelistNextParameter(parameterValue);
  }
  return parameterValue;
};

const whitelistNextParameter = (parameterValue) => {
  // allow if it is relative path
  var validRelativePath = /^\/[\/\w-]+$/.test(parameterValue);
  if (validRelativePath) {
    return parameterValue;
  }

  const hostname = window.location.hostname;
  // don't need to validate for localhost
  if (hostname === "localhost") {
    return parameterValue;
  }

  // absolute path needs to be our own domain
  var regex = new RegExp("^https?:\\/\\/" + hostname + "[\\/\\w-]*$");
  if (regex.test(parameterValue)) {
    return parameterValue;
  } else {
    return null;
  }
};

export { toQueryString, getParamsFromUrl, getParameterByName };
