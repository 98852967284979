import { Field } from "formik";
import { Question } from "../../../utils/healthHistoryV2";
import QuestionTitle from "./questionTitle";
import { cn } from "../../../utils/cn";
import { HealthContextFields } from "../../../types/test";

const TextFieldQuestion = ({
  question,
  currentValue,
}: {
  question: Question;
  currentValue: string;
}) => {
  const questionTitle = question.slug;
  let fieldType = "";
  const isOtherFollowup =
    question.slug.includes("_other") &&
    // Cancer type other and fertility treatments other are special cases and should be rendered as normal text fields
    question.slug !== ("cancer_type_other" as keyof HealthContextFields) &&
    question.slug !==
      ("fertility_treatment_other" as keyof HealthContextFields);

  if (
    question.answerType === "singletext" ||
    question.answerType === "multitext"
  ) {
    fieldType = "text";
  } else if (question.answerType === "date") {
    fieldType = "date";
  } else {
    fieldType = "number";
  }
  return (
    <div className="mt-2 mb-2">
      {!isOtherFollowup && <QuestionTitle questionTitle={questionTitle} />}
      <Field
        type={fieldType}
        id={questionTitle}
        name={questionTitle}
        component={fieldType === "text" ? "textarea" : ""}
        rows={question.answerType === "multitext" ? 5 : 1} // Specify the number of rows for the text area
        className={cn(
          "border-px border-black outline-none w-full outline-none focus:ring-0 p-[14px]",
          {
            "border border-opacity-20":
              question.answerType === "multitext" ||
              question.answerType === "singletext",
            "sm:w-[470px] w-[250px] mr-4 ml-7 -mt-[28px]": isOtherFollowup,
          }
        )}
        placeholder={
          isOtherFollowup ? 'Please describe "other"' : "Your Answer"
        }
        autoComplete="off"
        value={currentValue}
      />
    </div>
  );
};

export default TextFieldQuestion;
