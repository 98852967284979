import { getRequest } from "../utils/axios";

const fetchResults = (hash: string) => {
  return getRequest(
    `/api/v1/user-tests/${hash}/results/`,
    (response: any) => {
      return response;
    },
    (error: any, response: any) => {
      throw error;
    }
  );
};

export const resultsService = {
  fetchResults,
};
