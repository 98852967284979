import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { TestsHome } from "./testsHome";
import TestDetail from "./testDetail";
import TestDone from "./testDone";
import { Upgrade } from "./additional/upgrade";
import { testsService } from "../../services/tests";
import { Test } from "../../types/test";
import { AnnouncementModal } from "../../components/tests/announcementModal";
import useAnnouncements from "../../hooks/useAnnouncements";
import { useConsults } from "../../hooks/care/useConsults";

const Tests = () => {
  const [currentTest, setCurrentTest] = useState<Test>();
  const { mostRecentAnnouncement } = useAnnouncements();
  const { consults } = useConsults();
  const mostRecentConsult = consults?.[0];

  // route params
  const navigate = useNavigate();

  /* Effects */
  useEffect(() => {
    async function fetchCurrentTest() {
      try {
        const response = await testsService.fetchCurrentTest();
        if (response) {
          setCurrentTest(response.data);
        }
      } catch (error) {
        // still allow page to load of current test is unable to load
        console.error(error);
      }
    }
    fetchCurrentTest();
  }, []);

  /* Context */
  return (
    <Routes>
      <Route path="/" element={<TestsHome />} />

      {/* redirect to intake history of current test if it exists, otherwise go to tests home page */}
      <Route
        path="/intake/history/"
        element={
          currentTest ? (
            currentTest.healthcontext?.is_version_2 ||
            !currentTest.healthcontext ? (
              <Navigate to={`/tests/${currentTest.hash}/health-history/`} />
            ) : (
              <Navigate to={`/tests/${currentTest.hash}/history/`} />
            )
          ) : (
            <TestsHome />
          )
        }
      />
      <Route
        path="/announcement/"
        element={
          <AnnouncementModal
            handleClose={() => navigate("/")}
            announcement={mostRecentAnnouncement}
          />
        }
      />
      <Route path="/:testHash/done/" element={<TestDone />} />
      <Route path="/:testHash/additional/info/" element={<Upgrade />} />
      <Route path=":testHash/*" element={<TestDetail />} />
    </Routes>
  );
};

export default Tests;
