import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Field, Form, Formik } from "formik";
import BlueRectangularButton from "../../components/common/blueRectangularButton";
import {
  CreateProviderBulkTestOrderData,
  providerService,
} from "../../services/provider";
import Modal from "../../components/common/modal";
import CloseX from "../../components/common/closeX";
import axios from "axios";
import { AddressSearchInput } from "../../components/care/consultIntake";
import { States } from "../../utils/location";
import {
  sendProviderPlacedOrder,
  sendProviderViewedOrderPage,
} from "../../utils/analytics/customEventTracking";
import ExpandableComponent from "../../components/common/expandableComponent";
import { ExpandedPCRInfoModal } from "../../components/common/expandedPCRInfoModal";

const TYPE_TO_PRICE = {
  patient: 129,
  provider: 99,
  bulk: 99,
  addOn: 119,
};

type OrderType = "patient" | "provider" | "bulk";

type OrderFormValues = {
  add_sti: boolean;
  type: OrderType;
  email: string;
  confirmNotNY: boolean;
};

const BULK_PURCHASE_QUANTITY_OPTIONS = [10, 25, 50, 100];

export const ProviderOrder = () => {
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const [error, setError] = useState<string>("");

  const isProviderPurchaseConfirmation =
    params.get("q") === "provider-order-complete";

  const [orderConfirmationModalOpen, setOrderConfirmationModalOpen] =
    useState<boolean>(isProviderPurchaseConfirmation);
  const type = params.get("type") as OrderType;

  const [bulkOrderModalOpen, setBulkOrderModalOpen] = useState<boolean>(false);
  const [expandedPCRInfoModalOpen, setExpandedPCRInfoModalOpen] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    sendProviderViewedOrderPage();
  }, []);

  const initialValues: OrderFormValues = {
    add_sti: false,
    type: type || "patient",
    email: "",
    confirmNotNY: false,
  };

  const orderSingleTest = async (
    patientEmail: string,
    type: "patient" | "provider",
    add_sti: boolean
  ) => {
    try {
      return await providerService.createProviderTestOrder(
        patientEmail,
        type,
        add_sti
      );
    } catch (e: any) {
      if (axios.isAxiosError(error)) {
        setError(error.message);
      }
    }
  };

  const orderBulkTests = async (data: CreateProviderBulkTestOrderData) => {
    try {
      const result = await providerService.createProviderBulkTestOrder(data);
      return result;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        setError(error?.response?.data);
      }
    }
  };

  return (
    <>
      {orderConfirmationModalOpen && (
        <ConfirmationModal
          type={type || "patient"}
          onClose={() => {
            setOrderConfirmationModalOpen(false);
            navigate("/provider/tests");
          }}
        />
      )}
      {expandedPCRInfoModalOpen && (
        <ExpandedPCRInfoModal
          from="providers"
          onClose={() => setExpandedPCRInfoModalOpen(false)}
        />
      )}

      <div className="flex max-w-7xl mx-auto pb-4 h-full grow">
        {error && (
          <div className="bg-red-400 p-8 rounded-2xl mb-4 max-w-5xl mx-auto">
            {error}
          </div>
        )}
        <div className="w-1/2 bg-evvy-dark-cream">
          <img
            className="object-cover w-full "
            src="/images/graphics/evvy-kit-blue-large.png"
            loading="lazy"
            alt="Evvy Kit"
          />
        </div>
        <div className="w-1/2">
          <div className="mx-auto px-6 pt-4">
            <h3>Evvy Vaginal Health Test</h3>
            <p className="text-lg mb-8">
              Our easy, at-home vaginal microbiome test uncovers 700+ bacteria &
              fungi with a single swab. Includes a free 1:1 coaching call and
              custom plan. Built with leading OB-GYNs.
            </p>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values) => {
                setError("");
                setLoading(true);
                if (values.type === "patient") {
                  await orderSingleTest(
                    values.email,
                    values.type,
                    values.add_sti
                  );

                  setOrderConfirmationModalOpen(true);
                } else if (values.type === "provider") {
                  const result = await orderSingleTest(
                    values.email,
                    values.type,
                    values.add_sti
                  );
                  if (result && result.data) {
                    const providerTestOrder = result.data;

                    if (providerTestOrder.provider_order_link) {
                      window.location.href =
                        providerTestOrder.provider_order_link;
                    }
                  }

                  sendProviderPlacedOrder({
                    type: values.type,
                    addSTI: values.add_sti,
                  });
                } else if (values.type === "bulk") {
                  setBulkOrderModalOpen(true);
                }
                setLoading(false);
              }}
            >
              {({ values, setFieldValue }) => {
                const totalPrice =
                  TYPE_TO_PRICE[values.type] +
                  (values.add_sti ? TYPE_TO_PRICE.addOn : 0);

                return (
                  <Form className="mb-0 flex flex-col gap-4">
                    {/* STI Testing Checkbox */}
                    {bulkOrderModalOpen && (
                      <BulkOrderModal
                        loading={loading}
                        error={error}
                        add_sti_default={values.add_sti}
                        onSubmit={async (values) => {
                          setError("");
                          setLoading(true);

                          const num_ordered =
                            values.num_ordered_custom ?? values.num_ordered;
                          const result = await orderBulkTests({
                            ...values,
                            num_ordered: parseInt(num_ordered),
                            state_code:
                              values.state_code === "--"
                                ? undefined
                                : values.state_code,
                          });
                          if (result?.status === 201) {
                            setBulkOrderModalOpen(false);
                            sendProviderPlacedOrder({
                              type: "bulk",
                              addSTI: values.add_sti,
                            });
                            setOrderConfirmationModalOpen(true);
                          }
                          setLoading(false);
                        }}
                        onClose={() => {
                          setBulkOrderModalOpen(false);
                        }}
                      />
                    )}
                    <div className={`flex gap-2`}>
                      <p className="uppercase font-semibold mb-0 ">Add-on</p>

                      <div className="rounded-2xl bg-orange-500 flex justify-center h-fit px-2 py-1 text-white text-[12px] font-semibold uppercase">
                        Recommended
                      </div>
                    </div>

                    <label
                      className={`rounded-xl p-4 cursor-pointer hover:bg-white ${
                        values.add_sti
                          ? "bg-white outline-evvy-blue outline outline-2"
                          : "outline-gray-300 outline"
                      }`}
                    >
                      <div className="flex items-start">
                        <Field
                          type="checkbox"
                          name="add_sti"
                          className="form-checkbox h-5 w-5 text-evvy-blue focus:!ring-evvy-blue focus:!border-evvy-blue mt-1"
                        />
                        <span className="ml-3 text-gray-700">
                          Add STI + PCR Testing
                          <span className="text-sm text-gray-500 block">
                            Prelim results in 1-3 days
                          </span>
                        </span>
                        <div className="ml-auto" />
                        <div className="text-right">
                          <p className="text-lg text-gray-500 mb-0">
                            +${TYPE_TO_PRICE.addOn} per test
                          </p>
                        </div>
                      </div>
                      <hr className="my-4" />
                      <p className="text-gray-700 mt-2 mb-0">
                        Our Expanded PCR Panel add-on tests for chlamydia,
                        gonorrhea, <em>Mycoplasma genitalium</em>, and trich,
                        plus 11 common microbes with our Expanded PCR Panel.
                      </p>

                      <ExpandableComponent
                        title="More about our Expanded PCR Panel"
                        closedTitle="Show less"
                      >
                        <ul className="list-disc text-sm mt-2 mb-0">
                          <li className="font-semibold mb-1">
                            Highly recommended if you have symptoms
                          </li>
                          <li className="mb-1">
                            Prelim PCR results in 1-3 business days for 4 STIs
                            and 11 common microbes (
                            <span
                              onClick={() => {
                                setExpandedPCRInfoModalOpen(true);
                              }}
                              className="cursor-pointer underline"
                            >
                              see list
                            </span>
                            )
                          </li>
                          <li className="mb-1">
                            Final vaginal microbiome results in 7-10 days
                          </li>
                          <li className="mb-1">
                            Reports antibiotic resistance and microbial load
                          </li>
                          <li className="mb-1">
                            All with one easy at-home swab
                          </li>
                        </ul>
                        <p className="text-sm mt-0">
                          Note: this panel is not yet available in NY or US
                          territories
                        </p>
                      </ExpandableComponent>
                    </label>

                    {/* Order Type */}
                    <div className="flex flex-col gap-3">
                      <p className="uppercase font-semibold my-auto ">Payer</p>
                      {/* Patient Pays */}
                      <label
                        className={`flex items-start cursor-pointer py-4 rounded-xl p-4 hover:bg-white ${
                          values.type === "patient"
                            ? "bg-white outline-evvy-blue outline outline-2"
                            : "outline-gray-300 outline"
                        }`}
                      >
                        <Field
                          type="radio"
                          name="type"
                          value="patient"
                          className="form-radio h-5 w-5 text-evvy-blue focus:!ring-evvy-blue focus:!border-evvy-blue mt-1"
                          onClick={() => {
                            setSearchParams({ type: "patient" });
                          }}
                        />
                        <span className="ml-3 text-gray-700">
                          Patient Pays
                          <span className="text-sm text-gray-500 block mt-2">
                            Enter your patient's email and we'll send them a
                            link to purchase an Evvy test.
                          </span>
                        </span>
                        <div className="ml-auto" />
                        <div className="text-right ml-16">
                          <p className="text-lg text-gray-500 mb-0">
                            ${TYPE_TO_PRICE.patient}
                          </p>
                        </div>
                      </label>

                      {/* Provider Pays */}
                      <label
                        className={`flex items-start cursor-pointer py-4 rounded-xl p-4 hover:bg-white ${
                          values.type === "provider"
                            ? "bg-white outline-evvy-blue outline outline-2"
                            : "outline-gray-300 outline"
                        }`}
                      >
                        <Field
                          type="radio"
                          name="type"
                          value="provider"
                          className="form-radio h-5 w-5 text-evvy-blue focus:!ring-evvy-blue focus:!border-evvy-blue mt-1"
                          onClick={() => {
                            setSearchParams({ type: "provider" });
                          }}
                        />
                        <span className="ml-3 text-gray-700">
                          Invoice Patient
                          <span className="text-sm text-gray-500 block mt-2">
                            Pay for a test for your patient and we'll ship them
                            a test directly. You can then invoice them.
                          </span>
                        </span>
                        <div className="ml-auto" />
                        <div className="text-right ml-16">
                          <p className=" text-gray-500 mb-0 text-lg">
                            ${TYPE_TO_PRICE.provider}
                          </p>
                        </div>
                      </label>

                      {/* Bulk */}
                      <label
                        className={`flex items-start py-4 rounded-xl p-4 hover:bg-white cursor-pointer ${
                          values.type === "bulk"
                            ? "bg-white outline-evvy-blue outline outline-2"
                            : "outline-gray-300 outline"
                        }`}
                      >
                        <Field
                          type="radio"
                          name="type"
                          value="bulk"
                          className="form-radio h-5 w-5 text-evvy-blue focus:!ring-evvy-blue focus:!border-evvy-blue mt-1"
                          onClick={() => {
                            setSearchParams({ type: "bulk" });
                          }}
                        />
                        <span className="ml-3 text-gray-700">
                          Bulk Order
                          <span className="text-sm text-gray-500 block mt-2">
                            Order multiple tests for your clinic.
                          </span>
                        </span>
                        <div className="ml-auto" />
                        <div className="text-right ml-16">
                          <p className=" text-gray-500 mb-0 text-lg">
                            ${totalPrice} per test
                          </p>
                        </div>
                      </label>
                    </div>

                    <div
                      className={`${
                        values.type === "bulk" &&
                        "opacity-50 cursor-not-allowed"
                      }`}
                    >
                      <p className="uppercase font-semibold my-auto">
                        Patient Email
                      </p>
                      <div className="mt-2">
                        <Field
                          type="email"
                          name="email"
                          className={`focus:!ring-evvy-blue focus:!border-evvy-blue block border border-gray-300 py-4 rounded-xl w-full ${
                            values.type === "bulk" &&
                            "opacity-50 cursor-not-allowed "
                          } `}
                          placeholder="Enter your patient's email"
                          disabled={values.type === "bulk"}
                        />
                      </div>
                    </div>
                    {values.add_sti && (
                      <label className="flex items-start">
                        <Field
                          type="checkbox"
                          name="confirmNotNY"
                          className="form-checkbox h-5 w-5 text-evvy-blue focus:!ring-evvy-blue focus:!border-evvy-blue"
                        />
                        <span className="ml-3 text-gray-700 my-auto">
                          {values.type !== "bulk"
                            ? "This patient is not a New York or US territory resident."
                            : "I understand these tests are not available for NY or US territory residents."}
                        </span>
                      </label>
                    )}
                    {/* Submit Button */}
                    <div className="flex justify-between items-center">
                      <BlueRectangularButton
                        loading={loading}
                        text={
                          values.type === "bulk"
                            ? `Request`
                            : `Buy Now - $${totalPrice}`
                        }
                        type="submit"
                        fullWidth
                        paddingXClass="px-8"
                        disabled={
                          (values.add_sti && !values.confirmNotNY) ||
                          (values.type !== "bulk" &&
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                              values.email
                            ))
                        }
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const SINGLE_PATIENT_ORDER_CONFIRMATION_TEXT = `An order has been placed for your patient. They will receive a
link to purchase the test and then proceed through processing
their sample at the lab. Once the test results are ready, you will
receive an email with a link to their results.`;

const SINGLE_PROVIDER_ORDER_CONFIRMATION_TEXT = `An order has been placed for your patient. Evvy will send the patient the test directly and 
take care of everything from there! Once the test results are ready, you will
receive an email with a link to their results.`;

const BULK_ORDER_CONFIRMATION_TEXT = `Thank you! Our support team has been notified and will email you
an invoice shortly.`;

const ConfirmationModal = ({
  type,
  onClose,
}: {
  type: OrderType;
  onClose: () => void;
}) => {
  return (
    <Modal widthClass="w-full md:w-6/12" closeModal={onClose} preventBodyScroll>
      <div className="flex justify-end items-center">
        <CloseX
          handleClose={() => {
            onClose();
          }}
          textColorClass="text-evvy-black"
        />
      </div>
      <div className="flex flex-col p-8">
        <h3>Order Complete!</h3>
        <p className="b2 mb-0">
          {type === "patient"
            ? SINGLE_PATIENT_ORDER_CONFIRMATION_TEXT
            : type === "provider"
            ? SINGLE_PROVIDER_ORDER_CONFIRMATION_TEXT
            : BULK_ORDER_CONFIRMATION_TEXT}
        </p>
      </div>
    </Modal>
  );
};

const BULK_ORDER_HOW_IT_WORKS_BULLETS = [
  "Fill out the below information to order Evvy tests in bulk for your clinic.",
  "After you submit your order, we will send you an invoice for payment and then ship your order (the tests typically arrive within 5 business days).",
];

const BulkOrderModal = ({
  loading,
  error,
  onSubmit,
  onClose,
  add_sti_default,
}: {
  loading: boolean;
  error: string;
  onSubmit: (values: {
    num_ordered: string;
    num_ordered_custom?: string;
    address_first_line: string;
    address_second_line: string;
    city: string;
    state_code: States | "--";
    zip_code: string;
    add_sti: boolean;
  }) => void;
  onClose: () => void;
  add_sti_default: boolean;
}) => {
  const [expandedPCRInfoModalOpen, setExpandedPCRInfoModalOpen] =
    useState<boolean>(false);

  return (
    <Modal widthClass="w-full md:w-6/12" closeModal={onClose} preventBodyScroll>
      <div className="flex justify-end items-center">
        <CloseX
          handleClose={() => {
            onClose();
          }}
          textColorClass="text-evvy-black"
        />
      </div>
      {expandedPCRInfoModalOpen && (
        <ExpandedPCRInfoModal
          onClose={() => setExpandedPCRInfoModalOpen(false)}
          from={"providers"}
        />
      )}
      <Formik
        initialValues={{
          address_first_line: "",
          address_second_line: "",
          city: "",
          state_code: "--",
          zip_code: "",
          num_ordered: "10",
          add_sti: add_sti_default,
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <div className="p-8">
            <h3 className="mb-8">Bulk Order Tests</h3>
            <h4 className="t1 mb-4">How it works:</h4>
            <ul className={"list-none ml-0 text-base mb-6"}>
              {BULK_ORDER_HOW_IT_WORKS_BULLETS.map((bullet, index) => (
                <li key={index} className={"flex"}>
                  <span className={`mr-1.5 whitespace-nowrap`}>{"->"}</span>
                  {bullet}
                </li>
              ))}
            </ul>
            <Form>
              <div className="flex-1  mb-3 rounded-lg gap-4 flex flex-col">
                <label className={`p-4 cursor-pointer bg-evvy-cream`}>
                  <div className="flex items-start">
                    <Field
                      type="checkbox"
                      name="add_sti"
                      className="form-checkbox h-5 w-5 text-evvy-blue focus:!ring-evvy-blue focus:!border-evvy-blue mt-1"
                    />
                    <span className="ml-3 text-gray-700">
                      Add STI + PCR Testing
                      <span className="text-sm text-gray-500 block">
                        Prelim results in 1-3 days
                      </span>
                    </span>
                    <div className="ml-auto" />
                    <div className="text-right">
                      <p className="text-lg text-gray-500 mb-0">
                        +${TYPE_TO_PRICE.addOn} per test
                      </p>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <p className="text-gray-700 mt-2 mb-0">
                    Our Expanded PCR Panel add-on tests for chlamydia,
                    gonorrhea, <em>Mycoplasma genitalium</em>, and trich, plus
                    11 common microbes with our Expanded PCR Panel.
                  </p>

                  <ExpandableComponent
                    title="More about our Expanded PCR Panel"
                    closedTitle="Show less"
                  >
                    <ul className="list-disc text-sm mt-2 mb-0">
                      <li className="font-semibold mb-1">
                        Highly recommended if you have symptoms
                      </li>
                      <li className="mb-1">
                        Prelim PCR results in 1-3 business days for 4 STIs and
                        11 common microbes (
                        <span
                          onClick={() => {
                            setExpandedPCRInfoModalOpen(true);
                          }}
                          className="cursor-pointer underline"
                        >
                          see list
                        </span>
                        )
                      </li>
                      <li className="mb-1">
                        Final vaginal microbiome results in 7-10 days
                      </li>
                      <li className="mb-1">
                        Reports antibiotic resistance and microbial load
                      </li>
                      <li className="mb-1">All with one easy at-home swab</li>
                    </ul>
                    <p className="text-sm mt-0">
                      Note: this panel is not yet available in NY or US
                      territories
                    </p>
                  </ExpandableComponent>
                </label>
                <div>
                  <h4 className="t1">
                    {"Number of Tests "}
                    <span className="text-evvy-blue focus:!ring-evvy-blue focus:!border-evvy-blue">
                      *
                    </span>
                  </h4>

                  <div className="flex gap-4 flex-col md:flex-row">
                    <Field
                      as="select"
                      name="num_ordered"
                      required
                      className="border-0 bg-evvy-cream pr-8 focus:ring-0 p-4"
                    >
                      <>
                        {BULK_PURCHASE_QUANTITY_OPTIONS.map(
                          (quantity, index) => {
                            return (
                              <option key={index} value={quantity}>
                                {quantity} ($
                                {quantity * TYPE_TO_PRICE.bulk +
                                  (values.add_sti
                                    ? quantity * TYPE_TO_PRICE.addOn
                                    : 0)}
                                )
                              </option>
                            );
                          }
                        )}
                        <option value={"Custom"}>Custom</option>
                      </>
                    </Field>
                    {values.num_ordered === "Custom" && (
                      <Field
                        name="num_ordered_custom"
                        as="input"
                        type="number"
                        min={0}
                        className="mt-8"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <h4 className="t1">
                    {"Street Address "}
                    <span className="text-evvy-blue focus:!ring-evvy-blue focus:!border-evvy-blue">
                      *
                    </span>
                  </h4>

                  <AddressSearchInput
                    setFormFieldValue={setFieldValue}
                    getInitialValues={() => values}
                    required
                    className={`border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4`}
                  />
                </div>
                <div>
                  <h4 className="t1">{"Apartment, Suite, etc. (optional) "}</h4>
                  <Field
                    name="address_second_line"
                    as="input"
                    className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                    placeholder="Your Answer"
                  />
                </div>
                <div className="flex flex-col sm:flex-row w-full gap-4">
                  <div className="grow">
                    <h4 className="t1">
                      {"City "}
                      <span className="text-evvy-blue focus:!ring-evvy-blue focus:!border-evvy-blue">
                        *
                      </span>
                    </h4>
                    <Field
                      name="city"
                      as="input"
                      required
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                    />
                  </div>
                  <div>
                    <h4 className="t1">
                      {"State "}
                      <span className="text-evvy-blue focus:!ring-evvy-blue focus:!border-evvy-blue">
                        *
                      </span>
                    </h4>
                    <Field
                      as="select"
                      name="state_code"
                      required
                      className="border-0 bg-evvy-cream pr-8 focus:ring-0 p-4"
                    >
                      <>
                        <option key={0}>--</option>

                        {Object.entries(States).map(([key, value]) => {
                          return (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          );
                        })}
                      </>
                    </Field>
                  </div>
                  <div>
                    <h4 className="t1">
                      {"Zip Code "}
                      <span className="text-evvy-blue focus:!ring-evvy-blue focus:!border-evvy-blue">
                        *
                      </span>
                    </h4>
                    <Field
                      name="zip_code"
                      as="input"
                      required
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                    />
                  </div>
                </div>
                <div className="flex mt-6">
                  <BlueRectangularButton
                    type="submit"
                    text="Order"
                    loading={loading}
                    fullWidth
                    disabled={
                      loading ||
                      !values.address_first_line ||
                      !values.city ||
                      !values.state_code ||
                      values.state_code === "--" ||
                      !values.zip_code ||
                      (values.num_ordered === "Custom" &&
                        !values.num_ordered_custom)
                    }
                  />
                </div>

                {error && <span className="text-red-500">{error}</span>}
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </Modal>
  );
};
