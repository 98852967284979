/**
 * A card styled with a gradient slider
 * @param {object} scoreData
 * @param {string} scoreType "protective" or "disruptive"
 * @param {string} boxShadowClass tailwind class string for box shadow (highlights card on scroll)
 * @returns jsx of a card
 */

import React from "react";

// imported components
import Markdown from "markdown-to-jsx";

// local components
import ScoreGradient from "./scoreGradient";
import { getIsMobile } from "../../../utils/general";

const ScoreCard = ({
  scoreType,
  scoreData,
  boxShadowClass = "",
  initial = true,
}) => {
  const {
    title,
    score_description: description,
    score_category_description: categoryDescription,
    value,
  } = scoreData || {};

  const isMobile = getIsMobile();

  return (
    <div className={`bg-white p-7 rounded-[10px] ${boxShadowClass}`}>
      <div className={`${isMobile ? "t3" : "b0 capitalize"} semibold`}>
        {title}
      </div>
      <div className="b2 my-4">{description}</div>
      <h1 className="serif my-6">{value}%</h1>
      <ScoreGradient scoreType={scoreType} score={initial ? 0 : value} />
      <div className="b2 mt-6" id={"score" + scoreType}>
        <Markdown>{categoryDescription}</Markdown>
      </div>
    </div>
  );
};

export default ScoreCard;
