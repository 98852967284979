import { createContext } from "react";
import { Order } from "../types/orders";

export type OrdersContextType = {
  openOrders: Order[]; // Array of Order objects
  openTestOrders: Order[]; // Array of Order objects
  openUngatedRxOrders: Order[]; // Array of Order objects
  setOpenOrders: (orders: Order[]) => void; // Function to set open orders
  loading: boolean; // Loading state
  refetchOpenOrders: () => void; // Function to refetch orders
};

export const OrdersContext = createContext<OrdersContextType>({
  openOrders: [],
  openTestOrders: [],
  openUngatedRxOrders: [],
  setOpenOrders: () => {}, // Default function implementation
  loading: false,
  refetchOpenOrders: () => {}, // Default function implementation
});
