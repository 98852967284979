import { useContext } from "react";
import { OrdersContext } from "../contexts/ordersContext";

export const useOrdersFromContext = () => {
  const {
    openOrders,
    openTestOrders,
    openUngatedRxOrders,
    setOpenOrders,
    loading,
    refetchOpenOrders,
  } = useContext(OrdersContext);

  return {
    openOrders,
    openTestOrders,
    openUngatedRxOrders,
    setOpenOrders,
    loading,
    refetchOpenOrders,
  };
};
