import React from "react";

import RectangularButton from "../common/rectangularButton";
import { getIsMobile } from "../../utils/general";

const GetCareStickyFooter = ({
  onClick,
  ctaText,
  zIndex = "z-30",
  className = "",
  disabled = false,
}) => {
  const isMobile = getIsMobile();

  return (
    <div
      className={`sticky bottom-0 inset-x-0 bg-evvy-cream content-center ${zIndex} ${className}`}
    >
      <div className="py-4 px-4 md:px-20 flex justify-between">
        <h5 className="hidden md:block my-auto">Get Evvy Clinical Care</h5>
        <RectangularButton
          text={ctaText}
          bgColorClass="bg-evvy-blue"
          textColorClass="text-evvy-black"
          verticalPadding="py-6"
          horizontalPadding="px-16"
          fullWidth={isMobile}
          onClick={onClick}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default GetCareStickyFooter;
