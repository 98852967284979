import { useState, useEffect, useMemo } from "react";
import { Announcement } from "../types/user";
import { announcementService } from "../services/announcements";
import { AxiosResponse } from "axios";

const useAnnouncements = () => {
  const [data, setData] = useState<Announcement[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        announcementService.fetchAnnouncements((response: AxiosResponse) => {
          setData(response.data);
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAnnouncements();
  }, []);

  const unreadAnnouncementToShow = useMemo(() => {
    if (data.length > 0 && !data[0].viewed_at) {
      return data[0];
    }
  }, [data]);

  const mostRecentAnnouncement = data[0]; // it is okay that we don't do an existence check here because we handle the undefined case in the component

  return {
    announcements: data,
    unreadAnnouncementToShow,
    mostRecentAnnouncement,
    isLoading,
  };
};

export default useAnnouncements;
