import { useState, useRef, SyntheticEvent } from "react";

// hooks
import { useNavigate } from "react-router-dom";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";

// components
import Modal from "../common/modal";
import CloseX from "../common/closeX";
import ReactTooltip from "react-tooltip";

import { testsService } from "../../services/tests";
import { AxiosError } from "axios";
import {
  sendClickedActivationModalActivate,
  eventNames,
} from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { Test } from "../../types/test";
import {
  FIND_TEST_ID_BODY,
  FIND_TEST_ID_FOOTER,
  FIND_TEST_ID_SUBTITLE,
  FIND_TEST_ID_TITLE,
} from "./constants";
import { getHealthHistoryURL } from "../../utils/healthHistoryV2";

export const ActivateTestModal: React.FC<{
  handleClose: () => void;
  refetchTests: () => void;
}> = ({ handleClose, refetchTests }) => {
  // state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showCantFindID, setShowCantFindID] = useState(false);

  const navigate = useNavigate();
  const currentUser = useLoggedInUser();

  // refs
  const newKitInput = useRef<HTMLInputElement>(null);

  // actions
  const submitForm = (e: SyntheticEvent) => {
    e.preventDefault(); // to not post to the current url

    const targetElement = e.target as typeof e.target & {
      test_id: { value: string };
    };
    const testId = targetElement.test_id.value;
    if (!testId) return;

    setLoading(true);
    setError(null);
    ReactTooltip.hide(newKitInput.current || undefined); // manually hide, in case it was invalid before
    testsService.attachTest(
      testId, // api takes either raw hash or pretty hash with "-" breaks
      (response: { data: any }) => {
        setError(null);
        const testData = response.data as Test;
        // clear the form
        setLoading(false);
        targetElement.test_id.value = "";

        // refetch the tests list
        refetchTests();

        // If the health context already exists, take them to v1; should not hit this case.
        if (testData.healthcontext && !testData.healthcontext.is_version_2) {
          navigate(`/tests/${testData.hash}/`);
        } else {
          const url = getHealthHistoryURL(testData.hash, "intro");
          navigate(url);
        }
      },
      (error: AxiosError) => {
        setError(
          error && error.response && error.response.data
            ? error.response.data
            : "Error finding test ID"
        );
        setLoading(false);
        if (newKitInput.current) {
          ReactTooltip.show(newKitInput.current); // manually show, without requiring hover
        }
      }
    );
    if (currentUser) {
      sendClickedActivationModalActivate({
        testOrder: currentUser.tests.totalTestCount,
        testHash: testId,
      });
    }
  };

  return (
    <Modal
      closeModal={handleClose}
      widthClass={showCantFindID ? "w-full" : "w-full md:w-1/2"}
      allowOverflow
    >
      {!showCantFindID ? (
        <div className="p-4 sm:p-8 rounded-2xl bg-white">
          <div className="uppercase font-semibold tracking-wider text-sm">
            Activate a new test
          </div>
          <ReactTooltip
            effect="solid"
            place="left"
            type="error"
            backgroundColor="#FFA684"
            textColor="#11161A"
            class="rounded-xs py-28"
          />

          <p className="mt-4 mb-0 medium">
            Your Test ID is located on the collection tube in your test kit.
            <br />
            <span
              className="font-semibold underline cursor-pointer persistSize"
              onClick={() => {
                setShowCantFindID(true);
                analyticsClickHandler({
                  eventName: eventNames.CLICKED_CANT_FIND_ID,
                  eventArgs: {},
                  clickHandler: () =>
                    ReactTooltip.hide(newKitInput.current || undefined),
                })();
              }}
            >
              Can’t find ID?
            </span>
          </p>

          <form
            action="#"
            onSubmit={submitForm}
            className="flex mt-4 mb-0 flex-wrap"
          >
            <input
              id="test_id"
              type="text"
              placeholder="Enter your 10-character Test ID"
              ref={newKitInput}
              onChange={() =>
                ReactTooltip.hide(newKitInput.current || undefined)
              }
              data-tip={error}
              data-event="fakeEvent"
              autoComplete="off"
              spellCheck={false}
              className={`px-5 py-3 flex-grow text-black bg-evvy-cream border-transparent rounded-sm focus:outline-none focus:ring-transparent ${
                error
                  ? "border-coral focus:border-coral"
                  : "focus:border-transparent"
              }`}
              style={{ fontSize: "16px" }}
            />
            <button
              type="submit"
              disabled={loading}
              className="ml-0 w-full sm:w-auto mb-20 sm:mb-0 py-4 px-10 bg-evvy-black text-white rounded-sm font-semibold tracking-wider focus:outline-none hover:bg-evvy-pine"
            >
              Activate
            </button>
          </form>
        </div>
      ) : (
        <div className="block md:flex">
          <div className="flex-1">
            <img
              className="object-cover w-full h-full"
              src="/images/graphics/evvy-kit-updated.png"
              loading="lazy"
              alt="Evvy Kit"
            />
          </div>
          <div className="flex-1 bg-evvy-cream flex flex-col">
            <CloseX
              handleClose={() => setShowCantFindID(false)}
              textColorClass="text-evvy-black"
            />
            <div className="h-full p-8 md:p-20">
              <h2>{FIND_TEST_ID_TITLE}</h2>
              <p>{FIND_TEST_ID_SUBTITLE}</p>
              <p>{FIND_TEST_ID_BODY}</p>
              <p>{FIND_TEST_ID_FOOTER}</p>
            </div>
            <button
              className="cursor-pointer w-full tracking-wider py-6 px-20 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
              onClick={() => setShowCantFindID(false)}
            >
              Got It →
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};
