import { useEffect } from "react";
import Modal from "./modal";
import CloseX from "./closeX";
import Markdown from "markdown-to-jsx";
import {
  sendClickedViewExpandedPCRModal,
  sendClickedViewExpandedPCRModalFromHealthHistory,
  sendClickedViewExpandedPCRModalFromProviderOrder,
} from "../../utils/analytics/customEventTracking";

const MODAL_TITLE =
  "Evvy’s Expanded PCR Panel tests for the following via PCR:";

const WHATS_INCLUDED = [
  {
    title: "4 STIs",
    description:
      "Chlamydia, Gonorrhea, Trichomonas vaginalis (“trich”), Mycoplasma genitalium",
  },
  {
    title: "11 Common Microbes",
    description:
      "Candida albicans, Gardnerella vaginalis, Lactobacillus crispatus, Lactobacillus gasseri, Mobiluncus curtisii, Mobiluncus mulieris, Mycoplasma hominis, Prevotella bivia, Streptococcus agalactiae (group B), Ureaplasma parvum, Ureaplasma urealyticum\n\n_Note: these microbes are also tested for via metagenomics in our full vaginal microbiome test, but the Expanded PCR Panel tests for them via PCR with a 1-3 day turnaround._",
  },
  {
    title: "10 Antibiotic Resistance Classes",
    description:
      "Quinolone, Methicillin, Vancomycin, Beta-lactam, Carbapenem, Macrolide, Aminoglycoside, Tetracycline, Bactrim, Extended Spectrum Beta Lactamase CTX-M",
  },
];

export const ExpandedPCRInfoModal = ({
  onClose,
  from,
}: {
  onClose: () => void;
  from: "account" | "health_history" | "providers";
}) => {
  useEffect(() => {
    // trigger analytics!

    if (from === "account") {
      sendClickedViewExpandedPCRModal();
    } else if (from === "health_history") {
      sendClickedViewExpandedPCRModalFromHealthHistory();
    } else if (from === "providers") {
      sendClickedViewExpandedPCRModalFromProviderOrder();
    }
  }, [from]);

  return (
    <Modal widthClass="w-full md:max-w-md" closeModal={onClose}>
      <div className="bg-white py-12 px-6 md:px-12">
        <CloseX
          handleClose={onClose}
          side="right"
          textColorClass="text-evvy-black-dull"
        />
        <div className="t1 text-evvy-pine">{MODAL_TITLE}</div>
        <div className="mt-4 space-y-4">
          {WHATS_INCLUDED.map((item, index) => (
            <div
              key={index}
              className={`text-sm ${
                index < WHATS_INCLUDED.length - 1 ? "border-b pb-4" : ""
              }`}
            >
              <span className="font-bold">{item.title}: </span>
              <span className="regular">
                <Markdown>{item.description}</Markdown>
              </span>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
