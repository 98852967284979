import React from "react";
import RectangularButton from "./rectangularButton";

/* Note: as of React 17, ErrorBoundary not supported in Hooks */

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    if (window.newrelic) {
      window.newrelic.noticeError(error);
    }

    console.error("React Boundary Error", error, errorInfo);
  }

  render() {
    const { componentName = "this section", children } = this.props;
    if (this.state.hasError) {
      return (
        <div widthClass="max-w-1/2">
          <div className="p-14 bg-evvy-cream">
            <div className="md:flex gap-8 justify-between">
              <div className="max-w-sm">
                <h3>
                  Sorry about that — something went wrong displaying{" "}
                  {componentName}.
                </h3>
              </div>
              <div className="max-w-sm">
                <p className="mb-8"> Try refreshing the page.</p>
                <p className="mb-8">
                  If this the problem persists, please reach out to{" "}
                  <a href="mailto:support@evvy.com">support@evvy.com</a>.
                </p>
                <RectangularButton
                  text="refresh"
                  bgColorClass="bg-evvy-blue"
                  textColorClass="text-evvy-black"
                  fullWidth={true}
                  onClick={() => window.location.reload()}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
