import React, { useRef, useEffect, useState } from "react";
import { mean, extent, min, max, select } from "d3";

/* stacked bar component */
const LabelLine = ({ series, xPos, biome, yScale, loading, right }) => {
  // config
  const translateAmt = 20;
  const xLength = 12;
  const paddingAmt = 4;
  const gapAmt = 12;
  const duration = 500;
  const allTypes = ["GO", "BA", "NE", "UN"];

  // state management
  const [yPos, setYPos] = useState(100);
  const [[indexMin, indexMax], setIndexExtent] = useState([1, 1]);
  const [indexMean, setIndexMean] = useState(1);
  const [[minPos, maxPos], setPositions] = useState([100, 100]);

  // refs
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const g = select(ref.current);
      g.select("#topVert")
        .transition()
        .duration(duration)
        .attr("y1", `${-translateAmt * indexMin - paddingAmt}`)
        .attr("y2", `${-translateAmt * (indexMax + 1)}`);

      g.select("#topHoriz")
        .transition()
        .duration(duration)
        .attr("y1", `${-translateAmt * indexMean - gapAmt}`)
        .attr("y2", `${-translateAmt * indexMean - gapAmt}`);

      g.select("#midVert")
        .transition()
        .duration(duration)
        .attr("y1", yScale(yPos))
        .attr("y2", `${-translateAmt * indexMean - gapAmt}`);

      g.select("#botHoriz")
        .transition()
        .duration(duration)
        .attr("y1", yScale(yPos))
        .attr("y2", yScale(yPos));

      g.select("#botVert")
        .transition()
        .duration(duration)
        .attr("y1", yScale(minPos))
        .attr("y2", yScale(maxPos));
    }
  }, [indexMin, indexMax, indexMean, yPos, minPos, maxPos]);

  // get type keys of bacteria valences which are less than 2% of total microbiome
  const smallKeys = Object.entries(biome)
    .filter(([, { percent }]) => percent && percent < 2)
    .map(([key]) => key);

  useEffect(() => {
    // get series data for those small percentage types
    const smallData = series.filter((s) => smallKeys.includes(s.key));
    // get the yPositions of those types by finding the center of the corresponding rectangle
    // (the mean of the valence's y position and the valence's height)
    const yPositions = smallData.map(([[height, yPos]]) =>
      mean([height, yPos])
    );
    // get the mean of the total y positions (in case there are more than one small percentage type)
    const yPos = mean(yPositions);
    setYPos(yPos);
    // find the stack order of each of the types
    const indexes = smallKeys.map((type) =>
      allTypes.findIndex((key) => type === key)
    );
    // get the extent for the indexes
    const indexExtent = indexes.length ? extent(indexes) : [1, 1];
    setIndexExtent(indexExtent);
    // find the middle index of the of the small percentage types to later position bottom of the line
    const indexMean = mean(indexExtent);
    setIndexMean(indexMean);

    // calculations for bottom vertical line
    // get all heights
    const heights = smallData.map(([[height]]) => height);
    // get all y-positions
    const positions = smallData.map(([[, position]]) => position);
    // bottom of rectangle
    const minPos = min(positions);
    // top of rectangle
    const maxPos = max(heights);
    setPositions([minPos, maxPos]);
  }, [xPos, series, biome]);

  return (
    <g
      stroke="#C4C4C4"
      style={{ opacity: !smallKeys.length || loading ? 0 : 1 }}
      ref={ref}
    >
      {/* top vertical line */}
      <line
        id="topVert"
        x1={xPos + (right ? paddingAmt : -paddingAmt)}
        x2={xPos + (right ? paddingAmt : -paddingAmt)}
      />
      {/* top horizontal line */}
      <line
        id="topHoriz"
        x1={xPos + (right ? xLength : -xLength)} // draw line to the left or the right of vertical line depending on which side of viz it is on
        x2={xPos + (right ? paddingAmt : -paddingAmt)}
      />
      {/* vertical line */}
      <line
        id="midVert"
        x1={xPos + (right ? xLength : -xLength)}
        x2={xPos + (right ? xLength : -xLength)}
      />
      {/* bottom horizontal line */}
      <line
        id="botHoriz"
        x1={xPos + (right ? xLength : -xLength)}
        x2={xPos + (right ? paddingAmt : -paddingAmt)}
      />
      {/* bottom vertical line */}
      <line
        id="botVert"
        x1={xPos + (right ? paddingAmt : -paddingAmt)}
        x2={xPos + (right ? paddingAmt : -paddingAmt)}
      />
    </g>
  );
};

export default LabelLine;
