import Markdown from "markdown-to-jsx";
import React, { useState } from "react";
import RectangularButton from "../common/rectangularButton";
import CloseX from "../common/closeX";
import { Consult } from "../../types/care";
import { SHIPPING_DAYS } from "./constants";

const WhatsNextModal: React.FC<{
  handleClose: () => void;
  consult: Consult;
  menopausal?: boolean;
}> = ({ handleClose, consult, menopausal }) => {
  const [pageIndex, setPageIndex] = useState(0);

  const whatsNextStepCopyMD = [
    {
      header: "What's Next?",
      steps: [
        {
          title: "Look out for your prescriptions in the mail.",
          description: `Your prescriptions have been sent to our partner pharmacy, and orders will typically take **${SHIPPING_DAYS}** to ship. A tracking link will be sent to your email!`,
        },
        {
          title: `Read through your ${consult.treatment_pathway?.type} program thoroughly.`,
          description:
            "If you have any questions, schedule an onboarding call with your coach — we’re here to help every step of the way!",
        },
        {
          title:
            "When your treatments arrive, mark your start date in your Evvy calendar.",
          description:
            "This will calculate exactly what treatments you’ll need to take each day! You can find your Evvy calendar on the “Care” page in your account.",
        },
      ],
      ctaCopy: !menopausal
        ? "Continue"
        : `View ${consult.treatment_pathway?.type} Program →`,
      action: !menopausal ? () => setPageIndex(1) : handleClose,
      hidden: false,
    },
    {
      header: `FAQs about menstruation and your ${consult.treatment_pathway?.type} program`,
      steps: [
        {
          title: "If I’m about to get my period, should I start treatment?",
          description: `If your program contains antibiotics, we recommend timing your start date so that your full dose of antibiotics isn't interrupted by your period.`,
        },
        {
          title: `What should I do if I get my period during my ${consult.treatment_pathway?.type} program?`,
          description:
            "If you start your period during treatment, head to your calendar and input the dates of your period! Your calendar will adjust automatically and let you know which treatments you can continue.<br><br>Generally, boric acid and vaginal probiotics are often stopped during active bleeding and resumed after your period. The soothing ointment can be applied during your period.",
        },
      ],
      ctaCopy: `View ${consult.treatment_pathway?.type} Program →`,
      action: handleClose,
      hidden: menopausal,
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      {pageIndex === 1 && (
        <CloseX
          handleClose={handleClose}
          textColorClass="text-evvy-black"
          side="left"
        />
      )}
      <div className="h-full md:order-2 bg-evvy-cream flex flex-col justify-center">
        <img
          src="/images/graphics/care-packaging.png"
          loading="lazy"
          alt="care example treatments"
          className="h-full"
        />
      </div>
      <div className="p-4 pb-12 md:p-8 lg:p-16 bg-evvy-cream flex flex-col md:order-1">
        <h3 className="text-center mt-4 md:text-left">
          {whatsNextStepCopyMD[pageIndex].header}
        </h3>
        <div className="grow flex flex-col justify-center mb-6">
          {whatsNextStepCopyMD[pageIndex].steps.map((step, index) => (
            <div
              key={index}
              className={`flex ${
                index < whatsNextStepCopyMD[pageIndex].steps.length - 1
                  ? "mb-6"
                  : ""
              }`}
            >
              <div className="whitespace-nowrap mt-1 mr-3 b3 semibold">
                {`0${index + 1} -->`}
              </div>
              <div>
                <div className="b2 semibold mb-3">
                  <Markdown>{step.title}</Markdown>
                </div>
                <div>
                  <Markdown>{step.description}</Markdown>
                </div>
              </div>
            </div>
          ))}
        </div>
        <RectangularButton
          textColorClass="text-black"
          fullWidth
          verticalPadding="py-5"
          onClick={whatsNextStepCopyMD[pageIndex].action}
          text={whatsNextStepCopyMD[pageIndex].ctaCopy}
        />
      </div>
    </div>
  );
};

export default WhatsNextModal;
