import React, { useState, useEffect } from "react";
import FocusTrap from "focus-trap-react";
import { ReactComponent as ArrowDown } from "./icons/arrow-down-no-tail.svg";
import { ReactComponent as ArrowUp } from "./icons/arrow-up-no-tail.svg";
import { secondaryColors } from "../../utils/viz";

interface CollapsibleModalProps {
  header: string;
  children: React.ReactNode;
  hasFocusItem?: boolean;
  preventBodyScroll?: boolean;
  isCollapsed: boolean;
  setIsCollapsed: (collapsed: boolean) => void;
}

export const CollapsibleStickyModal: React.FC<CollapsibleModalProps> = ({
  header,
  children,
  hasFocusItem = false,
  preventBodyScroll = false,
  isCollapsed = false,
  setIsCollapsed,
}) => {
  useEffect(() => {
    if (preventBodyScroll) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [preventBodyScroll]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const modalContent = (
    <div
      className={`fixed z-20 bottom-0 sm:right-32 w-full sm:max-w-md drop-shadow-xl`}
    >
      <div className="">
        <div
          className="bg-evvy-silverfish rounded-t-lg p-5 cursor-pointer flex justify-between items-center"
          onClick={toggleCollapse}
        >
          <div className="b1 medium my-auto">{header}</div>
          {isCollapsed ? (
            <ArrowUp fill={secondaryColors["evvy-black"]} />
          ) : (
            <ArrowDown fill={secondaryColors["evvy-black"]} />
          )}
        </div>
        {!isCollapsed && <div className="bg-evvy-white">{children}</div>}
      </div>
    </div>
  );

  return <FocusTrap active={hasFocusItem}>{modalContent}</FocusTrap>;
};
