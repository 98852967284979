import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// components
import LoadingSpinner from "../../common/loadingSpinner";

// lib
import { formatters } from "../../../utils/viz";
import { analyticsClickHandler } from "../../../utils/analytics/helpers";
import { eventNames } from "../../../utils/analytics/customEventTracking";
import { getCareIntakeUrl } from "../utils";

// services
import { careService } from "../../../services/care";
import { testsService } from "../../../services/tests";

const ThreadPreview = ({ consult }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [test, setTest] = useState(null);
  const [latestMessage, setLatestMessage] = useState(null);
  const [messageStatus, setMessageStatus] = useState("");
  const threadStatus = consult.is_closed ? "closed" : "open";
  const threadBackgroundColor = consult.is_closed
    ? "bg-gray-50"
    : "bg-evvy-white";

  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    fetchLatestMessage();
    fetchTest();
  }, []);

  // Actions
  const fetchLatestMessage = () => {
    setLoading(true);
    careService.fetchConsultMessages(
      consult.uid,
      1,
      "",
      (response) => {
        if (response.data?.messages?.length) {
          setLatestMessage(response.data?.messages[0]);
        }
        setMessageStatus(
          consult?.consultmessagethread?.has_unread_message
            ? "new"
            : response.data?.messages?.length
            ? "no_unread"
            : "no_messages"
        );
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.error(error);
      }
    );
  };

  // get test associated with this consult
  const fetchTest = () => {
    testsService.fetchTest(
      consult.test_hash,
      (response) => {
        setTest(response.data);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const redirectToMessageThreadPage = () => {
    navigate(`/care/consults/${consult?.uid}/messages/`);
  };

  return (
    <div key={consult.uid} className={consult.is_closed ? "text-gray-500" : ""}>
      <div
        onClick={analyticsClickHandler({
          eventName: eventNames.CARE_CLICKED_VIEW_MESSAGE,
          eventArgs: {
            consultId: consult.uid,
            testHash: consult.test_hash,
            threadStatus,
            messageStatus,
            location: "messaging_home",
          },
          clickHandler: redirectToMessageThreadPage,
        })}
        className={`${threadBackgroundColor} rounded-t-lg p-5 sm:p-8 mb-0.5 cursor-pointer`}
      >
        <div className="flex justify-between mb-1">
          {consult.is_closed ? (
            <div className="t4 text-gray-400">closed</div>
          ) : (
            <div
              className={`${
                !consult?.consultmessagethread?.has_unread_message
                  ? "invisible"
                  : ""
              } b3 semibold bg-coral px-2 py-1 rounded-full`}
            >
              NEW
            </div>
          )}
          <div className="b3 regular text-gray-400">
            {consult.consultmessagethread.last_message_at &&
              formatters.abbrDate(
                new Date(consult.consultmessagethread.last_message_at)
              )}
          </div>
        </div>
        <div className="flex">
          <div className="my-2 b1 medium">
            Messages for Test ID: {consult.test_pretty_hash}
          </div>
          <Link
            className="ml-auto"
            to={`/care/consults/${consult?.uid}/messages/`}
          >
            <svg
              className="w-5 h-5 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </Link>
        </div>
        <div className="b1 regular text-gray-500 overflow-hidden truncate whitespace-nowrap">
          {!loading && latestMessage ? (
            latestMessage?.text
          ) : !loading && !latestMessage ? (
            ""
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>
      <div
        className={`${threadBackgroundColor} rounded-b-lg px-5 sm:px-8 py-5 mb-6`}
      >
        <div className="flex">
          <div className="border-r border-black pr-3">
            <Link
              to={`/results/${consult.test_hash}/`}
              className="underline b3"
              onClick={analyticsClickHandler({
                eventName: eventNames.MESSAGING_CLICKED_TEST_RESULTS,
                eventArgs: {
                  consultId: consult.uid,
                  testHash: consult.test_hash,
                  threadStatus,
                  messageStatus,
                },
              })}
            >
              {`Test Results ${
                test?.healthcontext?.sample_taken_at
                  ? `(taken ${formatters.slashDateAbbr(
                      new Date(test?.healthcontext?.sample_taken_at)
                    )})`
                  : ""
              }`}
            </Link>
          </div>
          <div className="pl-3">
            <Link
              to={getCareIntakeUrl("demographics", consult)}
              className="underline b3"
              onClick={analyticsClickHandler({
                eventName: eventNames.MESSAGING_CLICKED_PROVIDER_INTAKE,
                eventArgs: {
                  consultId: consult.uid,
                  testHash: consult.test_hash,
                  threadStatus,
                  messageStatus,
                },
              })}
            >
              Provider Intake
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ThreadPreview;
