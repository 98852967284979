/**
 * A small component for recommendation tags
 * @param {string} tag text for recommendation (e.g. 'Reduce Disruptive') and for background gradient color mapping
 * @returns jsx of tag
 */

import React from "react";

//lib
import { tagToColorMapping } from "../../utils/viz";

const Tag = ({ tag }) => (
  <div
    className={`t4 flex items-center max-w-max py-2 px-4 bg-gradient-to-r from-${tagToColorMapping[tag]}-gradientLight to-${tagToColorMapping[tag]}-oldFill`}
    style={{ height: "30px" }}
  >
    {tag}
  </div>
);

export default Tag;
