import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// hooks
import { useTransparentCareTreatments } from "../../hooks/care/transparentCare/useTransparentCareTreatments";

// types
import {
  AddCartItemPayload,
  Cart,
  CART_ITEM_TYPE,
  CartFreebieItem,
  CartItem,
} from "../../types/cart";
import { Test } from "../../types/test";
import {
  Prescription,
  TransparentCareRecommendations,
  TreatmentGroups,
  TreatmentPhaseDetails,
} from "../../types/care";

// images
import CarePackagingImg from "../care/images/care-packaging-no-bg.png";
import RainingPrescriptionsImg from "../care/images/raining-prescriptions.png";

// components
import { EvvyMarkdown } from "../common/evvyMarkdown";
import RectangularButton from "../common/rectangularButton";
import Panel from "../common/panel";
import LoadingSpinner from "../common/loadingSpinner";
import BlueRectangularButton from "../common/blueRectangularButton";
import { ReactComponent as XIcon } from "../common/icons/X.svg";
import LinkedText from "../common/linkedText";
import ErrorBanner from "../common/errorBanner";
import TransparentCarePhasePill from "../care/transparentCare/TransparentCarePhasePill";

// services
import { careService } from "../../services/care";

// utils
import {
  formatNumberToPriceString,
  getCareIntakeUrl,
  getCarePageVisitedLocalStorageKey,
  getTreatmentImageFromFormFactor,
  sumAndFormatCartItemsToPriceString,
} from "../care/utils";
import { cn } from "../../utils/cn";
import {
  CONSULT_REVIEW_FREEBIE,
  TRANSPARENT_BUNDLE_FREEBIES,
} from "../care/constants";
import { SUPPORT_EMAIL } from "../../pages/constants";
import { getConsultPurchaseTypeFromCart } from "../../utils/ecomm/utils";
import { usePanelCart } from "../../hooks/cart/usePanelCart";
import {
  sendTreatmentCartClickedCheckout,
  sendTreatmentCartClickedViewMoreProducts,
  sendTreatmentCartViewedCart,
  sendTreatmentClickedEmptyStateCTA,
} from "../../utils/analytics/customEventTracking";

const UserCart = ({
  cart,
  latestReadyTest,
  closeCart,
  addToCart,
  removeFromCart,
  baseCartAnalyticsArgs,
}: {
  cart?: Cart;
  latestReadyTest?: Test;
  closeCart: () => void;
  addToCart: (items: AddCartItemPayload[]) => void;
  removeFromCart: (items: CartItem[]) => void;
  baseCartAnalyticsArgs: any;
}) => {
  const cartIsEmpty = !cart || cart.line_items.length === 0;

  if (cartIsEmpty) {
    return (
      <EmptyState
        cart={cart}
        test={latestReadyTest}
        closeCart={closeCart}
        addToCart={addToCart}
        baseCartAnalyticsArgs={baseCartAnalyticsArgs}
      />
    );
  }
  return (
    <FullCartState
      cart={cart}
      test={latestReadyTest}
      removeFromCart={removeFromCart}
      baseCartAnalyticsArgs={baseCartAnalyticsArgs}
    />
  );
};

const FullCartState = ({
  cart,
  test,
  removeFromCart,
  baseCartAnalyticsArgs,
}: {
  cart: Cart;
  test?: Test;
  removeFromCart: (items: CartItem[]) => void;
  baseCartAnalyticsArgs: any;
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [
    showEligibleForMoreWarningBanner,
    setShowEligibleForMoreWarningBanner,
  ] = useState<boolean>(false);
  const navigate = useNavigate();
  const { showCart, refetchCartAndTest, setShowCart } = usePanelCart();
  const { transparentCareTreatments } = useTransparentCareTreatments(
    test?.hash
  );

  const CART_DISCLAIMER =
    "*Your specific prescriptions will be determined at the sole discretion of an Evvy-affiliated provider.";

  const carePageViewedKey = getCarePageVisitedLocalStorageKey(test?.hash || "");
  const hasVisitedCarePage = localStorage.getItem(carePageViewedKey)
    ? true
    : false;

  const bundleItems = cart.line_items.filter(
    (item) => item.item_type === "bundle-treatment"
  );

  const individualItems = cart.line_items.filter(
    (item) => item.item_type === "individual-treatment"
  );

  useEffect(() => {
    // if user has not visited care page and is currently not on care page, show warning banner
    const currentPage = window.location.pathname;
    if (currentPage !== "/care/" && !hasVisitedCarePage) {
      setShowEligibleForMoreWarningBanner(true);
    }
  }, [hasVisitedCarePage]);

  // trigger analytics event on load
  useEffect(() => {
    if (showCart) {
      refetchCartAndTest();
      sendTreatmentCartViewedCart({
        warningInCart: showEligibleForMoreWarningBanner,
        ...baseCartAnalyticsArgs,
      });
    } else {
      // on close cart, clear the error message
      setError("");
    }
  }, [showCart]);

  const allEligibleTreatments = useMemo(() => {
    if (!transparentCareTreatments || !test) {
      return [];
    }
    const eligibleForProgramCare = test.eligible_for_care;
    var allTreatmentGroups = [
      // if bundle treatments are available, then show them instead of recommended a la care treatments
      eligibleForProgramCare
        ? transparentCareTreatments.transparent_bundle.treatments
        : transparentCareTreatments.recommended_a_la_care_treatments,
      transparentCareTreatments.a_la_care_treatments,
    ];
    // filter out any undefined groups
    allTreatmentGroups = allTreatmentGroups.filter((group) => group);
    // Flatten the array and extract all prescriptions
    const allTreatments = allTreatmentGroups.flatMap((group) =>
      Object.values(group).flatMap((phase) => phase.treatments)
    );
    // filter only for eligible = true
    return allTreatments.filter((treatment) => treatment.eligible);
  }, [transparentCareTreatments, test]);

  // trigger on transparentCareTreatments change and cart change
  useEffect(() => {
    if (cart && allEligibleTreatments?.length > 0) {
      // if user has any treatments in cart which are not in the eligible treatments, then remove them from cart
      const cartItems = cart.line_items;
      const itemsToRemove = cartItems.filter(
        (item) =>
          !allEligibleTreatments.find(
            (treatment) => treatment.id === item.treatment_product.id
          )
      );
      if (itemsToRemove.length > 0) {
        // if any items are bundle items, then remove the whole bundle
        var bundleItemsToRemove = itemsToRemove.filter(
          (item) => item.item_type === "bundle-treatment"
        );
        if (bundleItemsToRemove.length > 0) {
          // get all bundle items in cart
          var bundleItemsToRemove = cartItems.filter(
            (item) => item.item_type === "bundle-treatment"
          );
        }
        const individualItemsToRemove = itemsToRemove.filter(
          (item) => item.item_type === "individual-treatment"
        );

        const allItemsToRemove = [
          ...bundleItemsToRemove,
          ...individualItemsToRemove,
        ];

        removeFromCart(allItemsToRemove);
        setError(
          "Some items in your cart are no longer available and have been removed."
        );
      }
    }
  }, [allEligibleTreatments, cart, test]);

  // Actually no checkout YET -- THIS SHOULD GO TO INTAKE INTRO!
  const createConsultAndGoToIntake = async () => {
    // if not cart, then there's nothing to checkout
    // Right now we require a test for checkout, but that might not be true in the future, but for now we'll check
    if (!cart || !test) {
      console.error("No cart or test found");
      return;
    }
    setSubmitting(true);

    // first need to determine purchase type (bundle or a-la-care or both!)
    const purchaseType = getConsultPurchaseTypeFromCart(cart);

    try {
      // first need to get a consult
      var consult = test.latest_vaginitis_consult;
      if (!consult) {
        // let's create a consult
        const consultResponse = await careService.asyncCreateConsult({
          hash: test.hash,
          purchase_type: purchaseType,
          type: "vaginitis",
        });
        consult = consultResponse.data;
      }

      // consult must exist at this point
      if (!consult) {
        throw new Error("Consult not found");
      }

      // update consult purchase type if it doesn't match
      if (consult.purchase_type !== purchaseType) {
        await careService.updateConsultPurchaseType(consult.uid, purchaseType);
      }

      // analytics!!
      sendTreatmentCartClickedCheckout({
        warningInCart: showEligibleForMoreWarningBanner,
        purchaseType,
        ...baseCartAnalyticsArgs,
      });

      // navigate to intake intro
      navigate(getCareIntakeUrl("intro", consult));
    } catch (error) {
      console.error("Error creating consult", error);
      setError(
        `Error creating consult. Please try again or contact ${SUPPORT_EMAIL}`
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      {/* cart items */}
      <div className="px-4 md:px-8 pb-8">
        {/* show bundle items if exist */}
        {bundleItems.length > 0 && (
          <BundleCartItem
            items={bundleItems}
            removeFromCart={removeFromCart}
            disabled={submitting}
            initializeExpanded={individualItems.length === 0}
          />
        )}
        {individualItems.length > 0 && (
          <div
            className={cn("space-y-6", {
              "mt-7": bundleItems.length > 0,
            })}
          >
            {/* show individual items if exist */}
            {individualItems.map((item) => (
              <ALaCareCartItem
                key={`alc-${item.id}`}
                item={item}
                removeFromCart={removeFromCart}
                disabled={submitting}
              />
            ))}

            {showEligibleForMoreWarningBanner && (
              <ErrorBanner parentAdditionalClassName="mt-6 md:mt-4">
                <span className="b2 medium">
                  You’re eligible for{" "}
                  <Link
                    to="/care/"
                    onClick={() => {
                      sendTreatmentCartClickedViewMoreProducts({
                        warningInCart: showEligibleForMoreWarningBanner,
                        ...baseCartAnalyticsArgs,
                      });
                      // close cart
                      setShowCart(false);
                    }}
                    className="underline persistSize"
                  >
                    more treatments
                  </Link>
                  .
                </span>
              </ErrorBanner>
            )}
            {bundleItems.length === 0 && (
              <ALaCareCartItem
                freebieInfo={CONSULT_REVIEW_FREEBIE}
                removeFromCart={removeFromCart}
                disabled={submitting}
              />
            )}
          </div>
        )}
      </div>
      {/* checkout button pinned to bottom of parent container*/}
      <div className="sticky bottom-0 inset-x-0 px-4 md:px-8 py-5 border-t border-evvy-dark-beige bg-evvy-cream">
        {/* subtotal */}
        <div className="flex justify-between text-sm md:text-base medium mb-2.5">
          <div className="">Subtotal</div>
          {/* format number as price */}
          <div>{sumAndFormatCartItemsToPriceString(cart.line_items)}</div>
        </div>
        <div className="text-sm md:text-base mb-5">
          Shipping & taxes calculated at checkout
        </div>
        {error && (
          <ErrorBanner parentAdditionalClassName="mb-2">{error}</ErrorBanner>
        )}
        {/* checkout button */}
        <BlueRectangularButton
          text="check out ->"
          fullWidth
          paddingXClass="px-5"
          paddingYClass="py-4"
          disabled={submitting}
          onClick={createConsultAndGoToIntake}
        />
        <div className="text-sm mt-2 font-medium">{CART_DISCLAIMER}</div>
      </div>
    </div>
  );
};

const ALaCareCartItem = ({
  item,
  freebieInfo = CONSULT_REVIEW_FREEBIE,
  removeFromCart,
  disabled,
}: {
  item?: CartItem;
  freebieInfo?: CartFreebieItem;
  removeFromCart: (items: CartItem[]) => void;
  disabled?: boolean;
}) => {
  const treatment = item?.treatment_product;
  const image = treatment
    ? getTreatmentImageFromFormFactor(treatment.form_factor)
    : freebieInfo.imgSrc;
  const altText = treatment ? treatment.form_factor : freebieInfo.altText;
  return (
    <div className="flex justify-between">
      <div className="flex space-x-4">
        <div className="bg-evvy-dark-cream rounded-lg h-14 w-14 md:h-16 md:w-16 relative">
          <img
            className={cn("object-cover mx-auto absolute", {
              "max-w-14 max-h-14": treatment,
              "bottom-0 inset-x-0":
                treatment && treatment.form_factor !== "suppository",
              "bottom-4": treatment && treatment.form_factor === "suppository",
              "rounded-lg w-full h-full": !treatment,
            })}
            loading="lazy"
            src={image}
            alt={altText}
          />
        </div>
        <div className="my-auto space-y-1 w-44 md:w-48">
          {treatment && (
            <TransparentCarePhasePill
              phase={treatment.purpose_tag as keyof TreatmentGroups}
              className="text-[12px]"
            />
          )}
          <div className="text-sm md:text-base medium">
            {treatment ? treatment.preview_title : freebieInfo.mobileText}
          </div>
        </div>
      </div>
      <div className="flex my-auto">
        <div className="my-auto text-sm md:text-base">
          {treatment ? (
            `${formatNumberToPriceString(
              Number(treatment.ecomm_product.price)
            )}`
          ) : (
            <>
              Free{" "}
              <span className="text-evvy-black/50">
                <EvvyMarkdown>{`~~${freebieInfo.price}~~`}</EvvyMarkdown>
              </span>
            </>
          )}
        </div>
        {item && (
          <button onClick={() => removeFromCart([item])} disabled={disabled}>
            <XIcon />
          </button>
        )}
      </div>
    </div>
  );
};

const BundleCartItem = ({
  items,
  removeFromCart,
  disabled,
  initializeExpanded,
}: {
  items: CartItem[];
  removeFromCart: (items: CartItem[]) => void;
  disabled?: boolean;
  initializeExpanded: boolean;
}) => {
  const [expanded, setExpanded] = useState<boolean>(initializeExpanded);
  const { showCart } = usePanelCart();

  const fullPrice = items.reduce(
    (acc, item) =>
      acc + Number(item.treatment_product.ecomm_product?.price || 0),
    0
  );
  const bundlePrice = items.reduce(
    (acc, item) =>
      acc + Number(item.treatment_product.bundle_variant?.price || 0),
    0
  );

  useEffect(() => {
    if (showCart) {
      setExpanded(initializeExpanded);
    } else {
      const timer = setTimeout(() => {
        setExpanded(initializeExpanded);
      }, 300); // delay 300ms until cart is closed to reset state

      return () => clearTimeout(timer);
    }
  }, [initializeExpanded, showCart]);

  return (
    <div className="rounded-2xl bg-evvy-white p-4 md:p-5">
      <div className="flex justify-between">
        <div className="flex space-x-4">
          <div className="bg-evvy-dark-cream rounded-lg h-14 w-14 md:h-24 md:w-24 flex">
            <img
              className="h-8 md:h-14 my-auto mx-auto object-cover"
              src={CarePackagingImg}
              alt="care packaging"
            />
          </div>
          <div className="medium my-auto pr-3">Complete Treatment Program</div>
        </div>
        {/* price and remove button */}
        <div className="flex justify-between my-auto">
          <div className="my-auto">{`$${bundlePrice}`}</div>
          <div className=" text-evvy-black/50 ml-1 my-auto">
            <EvvyMarkdown>{`~~$${fullPrice}~~`}</EvvyMarkdown>
          </div>
          <button onClick={() => removeFromCart(items)} disabled={disabled}>
            <XIcon />
          </button>
        </div>
      </div>
      <div className="border-t border-dashed border-evvy-black/20 my-5" />
      {/* show expanded items if expanded */}
      {expanded && (
        <>
          <div className="gap-x-4 gap-y-5 grid grid-cols-1 md:grid-cols-2">
            {items.map((item) => (
              <IndividualBundleItem
                key={item.id}
                treatment={item.treatment_product}
              />
            ))}
          </div>
          {/* Freebie items */}
          <div className="my-4">
            <div className="text-evvy-black/50 text-sm">
              Included with Complete Treatment Program
            </div>
            <div className="mt-4 gap-x-4 gap-y-5 grid grid-cols-1 md:grid-cols-2">
              {TRANSPARENT_BUNDLE_FREEBIES.map((item) => (
                <IndividualBundleItem freebieInfo={item} />
              ))}
            </div>
          </div>
        </>
      )}
      <LinkedText
        onClick={() => setExpanded(!expanded)}
        noIcon
        noTopMargin
        href=""
      >
        {expanded ? "Hide what's included*" : "Show what's included*"}
      </LinkedText>
    </div>
  );
};

const IndividualBundleItem = ({
  treatment,
  freebieInfo = TRANSPARENT_BUNDLE_FREEBIES[0],
}: {
  treatment?: Prescription;
  freebieInfo?: CartFreebieItem;
}) => {
  const image = treatment
    ? getTreatmentImageFromFormFactor(treatment.form_factor)
    : freebieInfo.imgSrc;

  const altText = treatment ? treatment.form_factor : freebieInfo.altText;

  return (
    <div className="flex justify-between">
      <div className="flex space-x-4">
        <div className="bg-evvy-dark-cream rounded-lg h-14 w-14 md:h-16 md:w-16 relative">
          <img
            className={cn("max-w-14 max-h-14 object-cover mx-auto absolute", {
              "bottom-0 inset-x-0":
                treatment && treatment?.form_factor !== "suppository",
              "bottom-4": treatment && treatment?.form_factor === "suppository",
              "rounded-lg": !treatment,
            })}
            loading="lazy"
            src={image}
            alt={altText}
          />
        </div>
        <div className="my-auto md:my-0 w-44">
          {treatment && (
            <div className="hidden md:block">
              <TransparentCarePhasePill
                phase={treatment.purpose_tag as keyof TreatmentGroups}
                className="text-[12px]"
              />
            </div>
          )}
          <div className="text-sm md:text-base medium my-auto">
            {treatment ? treatment.preview_title : freebieInfo.mobileText}
          </div>
          <div className="text-evvy-black/50 text-sm">
            <EvvyMarkdown>
              {treatment
                ? `$${Number(treatment?.bundle_variant.price)} ~~$${Number(
                    treatment?.ecomm_product.price
                  )}~~`
                : `Free ~~${freebieInfo.price}~~`}
            </EvvyMarkdown>
          </div>
        </div>
      </div>
      {treatment && (
        <div className="my-auto md:hidden">
          <TransparentCarePhasePill
            phase={treatment.purpose_tag as keyof TreatmentGroups}
            className="text-[12px]"
          />
        </div>
      )}
    </div>
  );
};

const EmptyState = ({
  cart,
  test,
  closeCart,
  addToCart,
  baseCartAnalyticsArgs,
}: {
  cart?: Cart;
  test?: Test;
  closeCart: () => void;
  addToCart: (items: AddCartItemPayload[]) => void;
  baseCartAnalyticsArgs: any;
}) => {
  const navigate = useNavigate();
  const { showCart } = usePanelCart();
  const eligibleForProgramCare = test?.eligible_for_care;
  const closeCartAndGoToCare = () => {
    closeCart();
    // navigate to care
    navigate("/care/");
  };

  // trigger analytics event on load
  useEffect(() => {
    if (showCart) {
      sendTreatmentCartViewedCart(baseCartAnalyticsArgs);
    }
  }, [showCart]);

  return test && eligibleForProgramCare ? (
    <EmptyStateProgramCare
      test={test}
      closeCartAndGoToCare={closeCartAndGoToCare}
      addToCart={addToCart}
      baseCartAnalyticsArgs={baseCartAnalyticsArgs}
    />
  ) : (
    <EmptyStateALaCare
      closeCartAndGoToCare={closeCartAndGoToCare}
      baseCartAnalyticsArgs={baseCartAnalyticsArgs}
    />
  );
};

const EmptyStateALaCare = ({
  closeCartAndGoToCare,
  baseCartAnalyticsArgs,
}: {
  closeCartAndGoToCare: () => void;
  baseCartAnalyticsArgs: any;
}) => {
  return (
    <>
      <div className="space-y-6 md:space-y-8 pb-5 md:pb-7 px-4 md:px-7">
        <div className="b1 medium md:text-lg/[24px] text-center">
          Looking kind of empty in here.<br></br>We recommend:
        </div>
        <div className="rounded-2xl md:mx-3">
          <div className="h-52 bg-evvy-silverfish rounded-t-2xl">
            <div className="relative h-full">
              <img
                className="h-44 mx-auto object-fill cursor-pointer rounded-t-2xl"
                src={RainingPrescriptionsImg}
                onClick={closeCartAndGoToCare}
              />
              <div className="absolute right-0 left-0 top-20 bottom-0 bg-gradient-to-b from-transparent to-evvy-white pointer-events-none"></div>
            </div>
          </div>
          <div className="bg-evvy-white px-4 md:px-7 space-y-6 pb-4 md:pb-7 rounded-b-2xl">
            <div>
              <h4 className="md:text-2xl mb-2">Individual Rx Treatments</h4>
              <div className="text-evvy-black/50">
                Customized plan for your unique symptoms and needs
              </div>
            </div>
            <RectangularButton
              onClick={() => {
                // close panel
                sendTreatmentClickedEmptyStateCTA({
                  ctaText: "Browse all care",
                  ...baseCartAnalyticsArgs,
                });
                closeCartAndGoToCare();
              }}
              text={"Browse all care ->"}
              textColorClass="text-evvy-black"
              fullWidth
              bgColorClass="bg-transparent"
              borderColor="border-evvy-black"
            />
          </div>
        </div>
      </div>
    </>
  );
};

// Format all bundle treatments into AddCartItemPayload
export const getBundleTreatmentsFromPayload = (
  transparentCareTreatments?: TransparentCareRecommendations
) => {
  const { treat, rebuild, relief } =
    transparentCareTreatments?.transparent_bundle.treatments || {};
  const allBundleTreatments = [
    ...(treat?.treatments || []),
    ...(rebuild?.treatments || []),
    ...(relief?.treatments || []),
  ];
  const itemsToAdd = allBundleTreatments.map((treatment) => {
    return {
      item_type: "bundle-treatment" as CART_ITEM_TYPE,
      quantity: 1,
      treatment_product_id: treatment.id,
      treatment_product: treatment,
    };
  });
  return itemsToAdd;
};

const EmptyStateProgramCare = ({
  test,
  closeCartAndGoToCare,
  addToCart,
  baseCartAnalyticsArgs,
}: {
  test: Test;
  closeCartAndGoToCare: () => void;
  addToCart: (items: AddCartItemPayload[]) => void;
  baseCartAnalyticsArgs: any;
}) => {
  const { transparentCareTreatments } = useTransparentCareTreatments(test.hash);
  const treatmentsToAdd = getBundleTreatmentsFromPayload(
    transparentCareTreatments
  );

  const addTransparentBundleToCart = useCallback(() => {
    addToCart(treatmentsToAdd);
    sendTreatmentClickedEmptyStateCTA({
      ctaText: "Add to cart",
      ...baseCartAnalyticsArgs,
    });
  }, [treatmentsToAdd]);

  return (
    <>
      <div className="space-y-6 md:space-y-8 pb-5 md:pb-7 px-4 md:px-7">
        <div className="b1 medium md:text-lg/[24px] text-center">
          Looking kind of empty in here.<br></br>We recommend:
        </div>
        <div className="rounded-2xl md:mx-3">
          <div className="h-60 overflow-hidden bg-evvy-dark-cream rounded-t-2x pt-6">
            <img
              className="mx-auto object-cover h-44 cursor-pointer"
              src={CarePackagingImg}
              onClick={closeCartAndGoToCare}
            />
          </div>
          <div className="-mt-14 h-14 bg-gradient-to-b from-transparent to-evvy-white" />
          <div className="bg-evvy-white px-4 md:px-7 space-y-6 pb-4 md:pb-7 rounded-b-2xl">
            <div>
              <h4 className="md:text-2xl mb-2">
                The Complete Treatment Program
              </h4>
              <div>Customized plan for your unique symptoms and needs</div>
            </div>
            <BlueRectangularButton
              text=""
              fullWidth
              paddingXClass="px-5"
              paddingYClass="py-4"
              // TODO: analytics
              onClick={addTransparentBundleToCart}
            >
              <div className="flex justify-between">
                <div>Add to cart</div>
                <div>
                  $
                  {
                    transparentCareTreatments?.transparent_bundle?.pricing
                      ?.bundled_price
                  }
                  <span className="ml-1 text-evvy-black/50">
                    <EvvyMarkdown>{`~~$${transparentCareTreatments?.transparent_bundle.pricing.full_price}~~`}</EvvyMarkdown>
                  </span>
                </div>
              </div>
            </BlueRectangularButton>
          </div>
        </div>
      </div>
      <div className="border-t border-evvy-dark-beige py-5">
        <RectangularButton
          onClick={() => {
            // trigger analytics
            sendTreatmentClickedEmptyStateCTA({
              ctaText: "Browse all care",
              ...baseCartAnalyticsArgs,
            });
            // close panel
            closeCartAndGoToCare();
          }}
          text={"Browse all care ->"}
          textColorClass="text-evvy-black"
          fullWidth
          bgColorClass="bg-transparent"
        />
      </div>
    </>
  );
};

export const PanelCartComponent = ({
  cart,
  showCart,
  setShowCart,
  latestReadyTest,
  isLoading,
  addToCart,
  removeFromCart,
  baseCartAnalyticsArgs,
}: {
  cart?: Cart;
  showCart: boolean;
  setShowCart: (show: boolean) => void;
  latestReadyTest?: Test;
  isLoading: boolean;
  addToCart: (items: AddCartItemPayload[]) => void;
  removeFromCart: (items: CartItem[]) => void;
  baseCartAnalyticsArgs: any;
}) => {
  return (
    <Panel handleClose={() => setShowCart(false)} isOpen={showCart}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <UserCart
          cart={cart}
          latestReadyTest={latestReadyTest}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
          closeCart={() => setShowCart(false)}
          baseCartAnalyticsArgs={baseCartAnalyticsArgs}
        />
      )}
    </Panel>
  );
};
