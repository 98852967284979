import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import LayoutWelcome from "../../components/welcome/layout";

import { authService } from "../../services/auth";
import { getParameterByName } from "../../utils/urls";
import { useOptionalUser } from "../../hooks/useOptionalUser";

const VerifyEmailConfirm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [confirmed, setConfirmed] = useState(false);

  const currentUser = useOptionalUser();
  const navigate = useNavigate();

  // effects
  useEffect(() => {
    confirm();
  }, []); // Send email when loading up this page

  // actions
  const confirm = () => {
    if (loading) return;

    const hash = getParameterByName("hash");

    setLoading(true);
    authService.verifyEmailConfirm(
      hash,
      (response) => {
        // if no logged in user, go take them to the login page
        if (!currentUser) {
          navigate("/login/?successMessage=EMAIL_VERIFIED");
        } else {
          // user is logged in. go show them success message and allow them to continue
          // upon confirming, refetch user config and show success
          authService.fetchUser(
            () => {
              setConfirmed(true);
              setLoading(false);
            },
            () => {
              setLoading(false);
              setError("Failed to fetch user");
            }
          );
        }
      },
      (error) => {
        setLoading(false);
        setError(
          error && error.response && error.response.data
            ? error.response.data
            : "could not verifify email"
        );
      }
    );
  };

  return (
    <LayoutWelcome
      metaTitle="Verify Email Confirm"
      title={
        currentUser && currentUser.identity.first_name
          ? currentUser.identity.first_name + ","
          : ""
      }
      subtitle={"Welcome to Evvy"}
    >
      <div className="mb-8 text-center">
        {loading ? (
          <div>Please wait...</div>
        ) : error ? (
          <div className="bg-coral p-2 px-3 text-sm font-medium rounded-xs">
            {error}
          </div>
        ) : confirmed ? (
          <React.Fragment>
            <div>
              <span className="tracking-wider">
                Your email address is confirmed
              </span>
            </div>

            <div className="mt-8">
              <img
                className="h-40 mx-auto"
                src="/images/graphics/triple-welcome.png"
                loading="lazy"
                alt="Evvy Science"
              />
            </div>

            <div className="mt-8">
              <Link to="/">
                <button className="cursor-pointer w-full sm:w-auto justify-center tracking-wider py-6 px-20 text-sm font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none">
                  Let's Go →
                </button>
              </Link>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    </LayoutWelcome>
  );
};

export default VerifyEmailConfirm;
