import { stack } from "d3-shape";
import { group } from "d3-array";

const allTypes = ["GO", "BA", "NE", "UN"];

/* creates stacked dataset */
const seriesGenerator = stack()
  .keys(allTypes)
  .value((d, key) => d[key].percent);

/* creates series data for chords  to pass to stack generator */
const chordSeriesGenerator = (data) =>
  Array.from(group(data, ({ key }) => key)).map(
    ([type, [groupOne, groupTwo]]) => {
      groupTwo[0].data.xPosition = 0;
      groupOne[0].data.xPosition = 1;
      const typeGroup = [...groupOne, ...groupTwo];
      typeGroup.key = type;
      return typeGroup;
    }
  );

export { seriesGenerator, chordSeriesGenerator };
