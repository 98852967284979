import React from "react";
import { Helmet } from "react-helmet";

import { getQuestionTitle } from "../../utils/questions";
import { arraysEqual } from "../../utils/general";

// shows a Q & A version of what the user told us
// really should only be viewed after they have submitted health profile
const TestResponses = ({ test, healthContext }) => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []); // Should always start at top of screen

  // don't render anything until we've loaded up the health context
  if (!healthContext) return "";

  // determine how we show each response for the questions
  // each one of these types has a specific render method below
  const questionSections = [
    {
      title: "The Basics",
      questions: {
        symptoms_has_current: "select", // this should be moved for V2 HH
        symptoms_best_description: "select", // this should be moved for V2 HH
        symptoms_reason_for_test: "multiselect", // this should be moved for V2 HH
        basics_reason_for_test: "multiselect",
        basics_reason_for_test_other: "text",
        basics_primary_concerns: "text",
        basics_hoping_to_learn: "text",
        basics_follow_recommendations: "select",
        basics_follow_recommendations_details: "text",
        basics_changes_since_last: "multiselect",
        basics_recent_period: "select",
        basics_last_period_date: "text",
        post_care_symptom_change: "select",
        post_care_improved_symptoms: "multiselect",
        post_care_improved_symptoms_other: "text",
        post_care_side_effects: "multiselect",
        post_care_side_effects_other: "text",
        post_care_followed_protocol: "post_care_followed_protocol", // special case
      },
    },
    {
      title: "Symptoms",
      questions: {
        vaginal_discharge: "select",
        symptoms_vaginal_smell: "select",
        symptoms_vaginal_smell_other: "text",
        symptoms_vaginal_discharge: "select", // deprecated
        symptoms_vaginal_discharge_other: "text", // deprecated
        symptoms_vaginal_discharge_color: "select",
        symptoms_vaginal_discharge_color_other: "text",
        symptoms_vaginal_discharge_consistency: "select",
        symptoms_vaginal_discharge_consistency_other: "text",
        symptoms_additional: "question_only",
        symptoms_additional_odorous_discharge: "select_symptoms_additional",
        symptoms_additional_vagina_pain: "select_symptoms_additional",
        symptoms_additional_vulva_pain: "select_symptoms_additional",
        symptoms_additional_vulva_redness: "select_symptoms_additional",
        symptoms_additional_vaginal_swelling: "select_symptoms_additional",
        symptoms_additional_external_itchiness: "select_symptoms_additional",
        symptoms_additional_internal_itchiness: "select_symptoms_additional",
        symptoms_additional_dryness: "select_symptoms_additional",
        symptoms_additional_burning_sensation: "select_symptoms_additional",
        symptoms_additional_pain_with_sex: "select_symptoms_additional",
        symptoms_additional_pain_while_peeing: "select_symptoms_additional",
        symptoms_additional_excessive_discharge: "select_symptoms_additional",
      },
    },
    {
      title: "Diagnoses",
      questions: {
        diagnoses_30: "question_only",
        diagnoses_30day_bv: "select_diagnoses_30",
        diagnoses_30day_yeast_infection: "select_diagnoses_30",
        diagnoses_30day_uti: "select_diagnoses_30",
        diagnoses_30day_av: "select_diagnoses_30",
        diagnoses_30day_ureaplasma: "select_diagnoses_30",
        diagnoses_30day_mycoplasma: "select_diagnoses_30",
        diagnoses_30day_vaginal_lactobacillosis: "select_diagnoses_30",
        diagnoses_30day_cytolytic_vaginosis: "select_diagnoses_30",
        diagnoses_30day_chlamydia: "select_diagnoses_30",
        diagnoses_30day_trichomoniasis: "select_diagnoses_30",
        diagnoses_30day_gonorrhea: "select_diagnoses_30",
      },
    },
    {
      title: "Treatments",
      questions: {
        treatments: "treatments_extra_data",
        treatments_other: "text",
      },
    },
    {
      title: "Sexual Activity",
      questions: {
        sexual_activity_timing: "select",
        sexual_activity_condoms: "select",
        sexual_activity: "multiselect",
      },
    },
    {
      title: "Anything Else",
      questions: {
        health_context_anything_else: "text",
      },
    },
    {
      title: "Demographics",
      questions: {
        demographics_height: "demographics_height",
        demographics_weight: "text",
        demographics_race: "multiselect",
        demographics_race_other: "text",
        demographics_born_with_vagina: "select",
        demographics_born_with_vagina_other: "text",
        demographics_gender_identity: "select",
        demographics_gender_identity_other: "text",
      },
    },
    {
      title: "Quality of Life",
      questions: {
        related_diagnoses_quality_of_life: "related_diagnoses_quality_of_life",
        quality_of_life_most_affected: "multiselect",
      },
    },
    {
      title: "Related Diagnoses",
      questions: {
        diagnoses: "question_only",
        diagnoses_bv: "multiselect_diagnoses",
        diagnoses_yeast_infection: "multiselect_diagnoses",
        diagnoses_uti: "multiselect_diagnoses",
        diagnoses_av: "multiselect_diagnoses",
        diagnoses_ureaplasma: "multiselect_diagnoses",
        diagnoses_mycoplasma: "multiselect_diagnoses",
        diagnoses_vaginal_lactobacillosis: "multiselect_diagnoses",
        diagnoses_cytolytic_vaginosis: "multiselect_diagnoses",
        diagnoses_chlamydia: "multiselect_diagnoses",
        diagnoses_trichomoniasis: "multiselect_diagnoses",
        diagnoses_genital_herpes: "multiselect_diagnoses",
        diagnoses_genital_warts: "multiselect_diagnoses",
        diagnoses_hiv: "multiselect_diagnoses",
        diagnoses_hpv: "multiselect_diagnoses",
        diagnoses_gonorrhea: "multiselect_diagnoses",
        related_diagnoses: "multiselect",
        related_diagnoses_other: "text",
        related_diagnoses_immunocompromised: "select",
        related_diagnoses_trigger: "multiselect",
        related_diagnoses_trigger_other: "text",
      },
    },
    {
      title: "Menstrual Health",
      questions: {
        hormonal_period_counts: "select",
        hormonal_menstrual_cycle: "select",
        hormonal_period_days: "select",
        hormonal_period_products: "multiselect",
        hormonal_period_products_other: "text",
      },
    },
    {
      title: "Hormonal Health",
      questions: {
        hormonal_symptoms: "multiselect",
        contraception_types_used: "multiselect",
        contraception_type: "select",
        contraception_type_other: "text",
        contraception_brand: "text",
        contraception_changed: "select", // deprecated
        hormonal_menopause: "select",
      },
    },
    {
      title: "Pregnancy",
      questions: {
        pregnancy_status: "multiselect",
        pregnancy_breastfeeding: "select",
        pregnancy_postpartum_timing: "select",
        pregnancy_month: "select",
        pregnancy_previously: "select",
        pregnancy_experienced: "multiselect",
        pregnancy_experienced_other: "text",
      },
    },
    {
      title: "Anything Else",
      questions: {
        health_history_anything_else: "text",
      },
    },
  ].map((s) => {
    // for each section, get the questions we'll be showing (need to have answers)
    s.questionsToShow = Object.keys(s.questions).filter((key) => {
      // Special case where we want to display something even if it doesn't have an answer
      if (["treatments_extra_data"].includes(s.questions[key])) {
        return true;
      }

      // Special case where we want to display something only if another question in its section has an answer
      if (s.questions[key] === "question_only") {
        const questionsWithAnswers = Object.keys(s.questions).filter((k) => {
          if (!healthContext[k] || healthContext[k].length === 0) {
            return false;
          }
          return true;
        });
        return questionsWithAnswers.length > 0;
      }

      // checking 0 length in case it's an array for multiselect
      if (!healthContext[key] || healthContext[key].length === 0) {
        return false;
      }
      return true;
    });

    return s;
  });

  // these questions usually follow a "question_only" type, and should have a smaller title
  const smallQuestionTitles = [
    "select_symptoms_additional",
    "multiselect_diagnoses",
    "select_diagnoses_30",
  ];

  return (
    <div>
      <Helmet>
        <title>Test | Responses</title>
      </Helmet>

      <div className="mb-0 bg-evvy-cream rounded-lg p-5 sm:p-12">
        {!healthContext.health_history_submitted_at ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-md my-6">
            You are viewing an incomplete subset of your responses for this
            test. Please submit your health history.
          </div>
        ) : (
          ""
        )}

        <div className="block w-full">
          <div className="py-10 text-center">
            <h3>Your Health Context</h3>
            <div className="t4">
              Test Date:{" "}
              {healthContext.health_history_submitted_at || "missing date"}
            </div>
          </div>
        </div>

        {questionSections
          .filter((s) => s.questionsToShow.length > 0)
          .map((section) => (
            <div
              key={section.title}
              className="mb-2 border-t border-dotted border-gray-400 py-10"
            >
              <h3 className="">{section.title}</h3>

              {Object.keys(section.questions)
                .filter((key) => section.questionsToShow.includes(key))
                .map((key) => (
                  <div
                    key={key}
                    className={`${
                      smallQuestionTitles.includes(section.questions[key])
                        ? "mt-6 pl-4"
                        : "mt-10"
                    }`}
                  >
                    <div className={`t3 mb-2`}>{getQuestionTitle(key)}</div>

                    {/* different answer rendering based on question type */}
                    {["text"].includes(section.questions[key]) ? (
                      <ul className="content list-disc">
                        <li>{healthContext[key] || "--"}</li>
                      </ul>
                    ) : ["select"].includes(section.questions[key]) ? (
                      <ul className="content list-disc">
                        {/* single select should filter down to just 1 option */}
                        {healthContext._all_options[key]
                          .filter((o) => healthContext[key].includes(o[0]))
                          .map((o) => (
                            <li key={o[0]}>{o[1]}</li>
                          ))}
                      </ul>
                    ) : ["select_symptoms_additional"].includes(
                        section.questions[key]
                      ) ? (
                      <ul className="content list-disc">
                        {/* special kind of select, where different questions use the same options */}
                        {healthContext._all_options["symptoms_additional"]
                          .filter((o) => healthContext[key].includes(o[0]))
                          .map((o) => (
                            <li key={o[0]}>{o[1]}</li>
                          ))}
                      </ul>
                    ) : ["multiselect"].includes(section.questions[key]) ? (
                      <ul className="content list-disc">
                        {healthContext._all_options[key]
                          .filter((o) => healthContext[key].includes(o[0]))
                          .map((o) => (
                            <li key={o[0]}>{o[1]}</li>
                          ))}
                      </ul>
                    ) : ["multiselect_diagnoses"].includes(
                        section.questions[key]
                      ) ? (
                      <ul className="content list-disc">
                        {/* special kind of multiselect, where different questions use the same options */}
                        {healthContext._all_options["diagnoses"]
                          .filter((o) => healthContext[key].includes(o[0]))
                          .map((o) => (
                            <li key={o[0]}>{o[1]}</li>
                          ))}
                      </ul>
                    ) : ["select_diagnoses_30"].includes(
                        section.questions[key]
                      ) ? (
                      <ul className="content list-disc">
                        {/* special kind of multiselect, where different questions use the same options */}
                        {healthContext._all_options["diagnoses_30"]
                          .filter((o) => healthContext[key].includes(o[0]))
                          .map((o) => (
                            <li key={o[0]}>{o[1]}</li>
                          ))}
                      </ul>
                    ) : ["treatments_extra_data"].includes(
                        section.questions[key]
                      ) ? (
                      <React.Fragment>
                        {/* very special question. has multiple subquestions for each treatment user selected */}
                        <ul className="content list-disc">
                          {healthContext._all_options["treatments"]
                            .filter((o) =>
                              healthContext["treatments"].includes(o[0])
                            )
                            .map((o) => (
                              <li key={o[0]}>{o[1]}</li>
                            ))}
                        </ul>

                        {!arraysEqual(healthContext.treatments, ["NO"]) &&
                          healthContext.treatments.map((t) => (
                            <div key={t} className={`mt-8`}>
                              <h3 className={`t3 mb-2`}>
                                {
                                  healthContext._all_options[
                                    "treatments"
                                  ].filter((o) => o[0] === t)[0][1]
                                }
                              </h3>
                              {healthContext.healthcontextextradata_set
                                .filter((e) =>
                                  e.key.includes(`treatments__${t}`)
                                )
                                .map((e) => (
                                  <div className="mt-6 pl-4" key={e.key}>
                                    <h3 className={`t3 mb-1`}>
                                      {getQuestionTitle(
                                        e.key.replace(`__${t}__`, "_")
                                      )}
                                    </h3>
                                    <ul className="content list-disc">
                                      <li>{e.value}</li>
                                    </ul>
                                  </div>
                                ))}
                            </div>
                          ))}
                      </React.Fragment>
                    ) : ["demographics_height"].includes(
                        section.questions[key]
                      ) ? (
                      <ul className="content list-disc">
                        <li>
                          {parseInt(healthContext.demographics_height / 12)}'{" "}
                          {healthContext.demographics_height % 12}"
                        </li>
                      </ul>
                    ) : ["related_diagnoses_quality_of_life"].includes(
                        section.questions[key]
                      ) ? (
                      <ul className="content list-disc">
                        <li>
                          {healthContext.related_diagnoses_quality_of_life} / 10
                        </li>
                      </ul>
                    ) : ["post_care_followed_protocol"].includes(
                        section.questions[key]
                      ) ? (
                      <ul className="content list-disc">
                        <li>{healthContext.post_care_followed_protocol} / 5</li>
                      </ul>
                    ) : ["question_only"].includes(section.questions[key]) ? (
                      <React.Fragment>
                        {/* nothing to display here :) */}
                      </React.Fragment>
                    ) : (
                      <ul className="content list-disc text-red-500">
                        <li>Invalid question type</li>
                      </ul>
                    )}
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default TestResponses;
