import { TestTrendSummary } from "../../../types/test";
import CloseX from "../../common/closeX";
import ProblemsModule from "../../tests/problemsModule";

export const ProblemsModuleExpandedModal = ({
  data,
  handleClose,
}: {
  // data is an object with property testHash which is a string
  data: { testSummary: TestTrendSummary };
  handleClose: () => void;
}) => {
  return (
    <div className="bg-evvy-white w-full p-5">
      {/* close button */}
      <div className="flex justify-end items-center">
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>
      <ProblemsModule testSummary={data.testSummary} modalVersion />
    </div>
  );
};
