/**
 * A button styled with an arrow (goes in plan modals)
 *  @param {string} href an external url
 * @param {*} onClick function to handle button click
 * @param {string} text a string to label button
 * @returns jsx of a button
 */
import React from "react";

const ArrowButton = ({ href, text, onClick }) => {
  return (
    <a
      href={href}
      target="_blank"
      className="flex justify-between items-center w-full px-4 py-2 bg-evvy-blue-gradientLight bg-opacity-50 text-evvy-pine t4"
      onClick={onClick ? onClick : null}
    >
      <div>{text}</div>
      <div className="b1 flex flex-shrink-0">-></div>
    </a>
  );
};

export default ArrowButton;
