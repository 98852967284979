import { useState } from "react";
import CloseX from "../../common/closeX";
import RectangularButton from "../../common/rectangularButton";
import LinkedText from "../../common/linkedText";
import { ReactComponent as EvvyTextLogo } from "../../plan_unfurled/icons/evvy-logo-text.svg";
import { nextTestCopy } from "../../../pages/plan_unfurled/constants";
import { secondaryColors } from "../../../utils/viz";
import { PlanState, Subscription } from "../../../types/subscription";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { formatDate, strDateToTimestamp } from "../../../utils/datetime";

const NextTestContentTemplate = ({
  handleClose,
  handleSubmit,
  subscription,
}: {
  handleClose: () => void;
  handleSubmit: () => void;
  subscription: Subscription | undefined;
}) => {
  const [submitted, setSubmitted] = useState(false);
  // error coming from next-charge api
  const submitError = useSelector((state: PlanState) => {
    return state.plan.value.moveTestUpError;
  });

  const nextTestRefillDate = subscription?.next_charge_date
    ? formatDate(strDateToTimestamp(subscription.next_charge_date))
    : "N/A";
  const COPY = nextTestCopy(nextTestRefillDate);

  /* Actions */
  const onClose = () => {
    setSubmitted(false);
    handleClose();
  };

  const onSubmit = (e: { preventDefault: () => void }) => {
    // stop page from refreshing on submit button click (keep button type submit for accessibility)
    e.preventDefault();
    setSubmitted(true);
    handleSubmit();
  };

  if (!subscription) return null;

  return (
    <div
      className={classNames(
        "w-full flex flex-col items-center p-10 bg-evvy-white space-y-6",
        { "text-center": submitted, "text-left": !submitted }
      )}
    >
      {/* close button */}
      <div className="flex justify-end items-center">
        <CloseX handleClose={onClose} textColorClass="text-evvy-black" />
      </div>
      {submitted && !submitError && (
        <EvvyTextLogo
          fill={secondaryColors["evvy-pine"]}
          stroke={secondaryColors["evvy-pine"]}
        />
      )}
      <h5 className="w-full">
        {submitted && !submitError ? COPY.SUBMIT_TITLE : COPY.TITLE}
      </h5>
      <div className={classNames("caption", { "pb-8": submitted })}>
        {/* if user has clicked submit, display success text or error text depending on whether we receive an error from api */}
        {submitted ? (
          submitError ? (
            <p>{COPY.SUBMIT_ERROR_CAPTION}</p>
          ) : (
            COPY.SUBMIT_CAPTION.split("|")?.map((line, i) => (
              <p key={`line-${i}`}>{line}</p>
            ))
          )
        ) : (
          // otherwise show text prompting user to submit feedback
          COPY.CAPTION?.map((line, i) => <p key={`line-${i}`}>{line}</p>)
        )}
      </div>
      {/* only show action buttons if user has not clicked submit */}
      {!submitted && (
        <>
          <div>
            <RectangularButton
              noLeftMargin
              text="send next test now"
              bgColorClass="bg-evvy-blue"
              textColorClass="text-evvy-black"
              buttonType="submit"
              onClick={onSubmit}
            />
          </div>
          <div>
            <LinkedText noTopMargin noIcon onClick={onClose} href={undefined}>
              Cancel
            </LinkedText>
          </div>
        </>
      )}
    </div>
  );
};

export default NextTestContentTemplate;
