import React, { useEffect, useState } from "react";
import Autocomplete from "react-autocomplete";
import { Helmet } from "react-helmet";

import ErrorBoundary from "../../../components/common/errorBoundary";
import Modal from "../../../components/common/modal";

import { careService } from "../../../services/care";
import { ReactComponent as SearchIcon } from "../../../components/care/icons/search.svg";
import AddMedicationModal from "../../../components/care/consultIntake/addMedicationModal";
import WhiteRectangularButton from "../../../components/care/consultIntake/whiteRectangularButton";
import BlueRectangularButton from "../../../components/common/blueRectangularButton";
import { sendConsultIntakeViewSection } from "../../../utils/analytics/customEventTracking";

const MedicationQuestions = ({ consultIntake, submitPage, loading }) => {
  const previousSelectedMedications = consultIntake?.current_medications
    ? consultIntake.current_medications.split("|")
    : [];
  const [error, setError] = useState(null);
  const [loadingMedications, setLoadingMedications] = useState(true);
  const [searchTermValue, setSearchTermValue] = useState("");
  const [medicationList, setMedicationList] = useState([]);
  const [selectedMeds, setSelectedMeds] = useState(previousSelectedMedications);
  const [modalOpen, setModalOpen] = useState(false);

  /*
   * Effects
   */
  useEffect(() => {
    fetchMedications(); // fetch list of ~7000 cached medication names
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: consultIntake?.consult?.test_hash,
      section: "treatment",
      consultType: consultIntake?.consult?.type,
    });
  }, []);

  /*
   * Actions
   */
  // fetches medications from API.
  // since this list is fairly small, it's much simpler to load it as a static list
  // In future, as this list gets larger, we can query subsets of the list from the backend
  const fetchMedications = () => {
    setLoadingMedications(true);
    setError(false);
    careService.fetchMedications(
      (response) => {
        setMedicationList(response.data);
        setLoadingMedications(false);
      },
      (error, response) => {
        console.error(error);
        setError(response);
        setLoadingMedications(false);
      }
    );
  };

  // submits page data to update consult intake and proceed to next page
  const submitMedicationPage = () => {
    const data = {};
    if (selectedMeds.length > 0) {
      data.current_medications = selectedMeds.join("|");
    } else {
      data.current_medications = "";
    }
    submitPage(data, (response) => {
      console.error(response);
      setError("Error submitting medications");
    });
  };

  // adds medication to selected meds list
  const selectMedication = (medName) => {
    if (!selectedMeds.includes(medName)) {
      setSelectedMeds([...selectedMeds, medName]);
      setSearchTermValue("");
    }
  };

  // removes medication from selected meds list
  const removeMedication = (medName) => {
    if (selectedMeds.includes(medName)) {
      setSelectedMeds(selectedMeds.filter((i) => i !== medName));
    }
  };

  /*
   * Utils
   */
  // utils for autocomplete
  const matchMedicationToTerm = (medicationName, termValue) => {
    return medicationName.toLowerCase().indexOf(termValue.toLowerCase()) !== -1;
  };
  const sortMedicationResults = (a, b, value) => {
    const aLower = a.toLowerCase();
    const bLower = b.toLowerCase();
    const valueLower = value.toLowerCase();
    const queryPosA = aLower.indexOf(valueLower);
    const queryPosB = bLower.indexOf(valueLower);
    if (queryPosA !== queryPosB) {
      return queryPosA - queryPosB;
    }
    return aLower < bLower ? -1 : 1;
  };

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Helmet>
        <title>
          Care | Consult |{" "}
          {consultIntake.consult.type === "sti" ? "STI Intake" : "Intake"} |
          Treatment
        </title>
      </Helmet>

      {modalOpen && (
        <ErrorBoundary>
          <Modal
            closeModal={() => setModalOpen(false)}
            widthClass="w-full md:w-2/5"
          >
            <AddMedicationModal
              submitMedication={selectMedication}
              handleClose={() => setModalOpen(false)}
            />
          </Modal>
        </ErrorBoundary>
      )}

      <div className="max-w-2xl px-4">
        <h3 className="text-center">Treatment Questions</h3>
        {error ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
            {error}
          </div>
        ) : (
          ""
        )}
        <div className="flex-1 bg-evvy-white p-5 sm:p-10 mb-3 rounded-lg">
          <div className="t2 medium">
            Please list any current medications or supplements
          </div>
          <div className="b1 py-3">
            Include any prescription medication, over the counter treatment, or
            vitamins you’ve taken in the last two weeks.
          </div>
          <Autocomplete
            value={searchTermValue}
            wrapperProps={{ className: "w-full" }}
            inputProps={{ id: "medications-autocomplete" }}
            items={medicationList}
            getItemValue={(item) => item}
            shouldItemRender={matchMedicationToTerm}
            sortItems={sortMedicationResults}
            open={searchTermValue !== ""}
            onChange={(event, value) => {
              setSearchTermValue(value);
            }}
            renderInput={(props) => (
              <div className="relative">
                <SearchIcon className="pointer-events-none absolute top-4 left-4 h-6 w-6" />
                <input
                  disabled={loadingMedications}
                  placeholder={
                    loadingMedications
                      ? "Loading..."
                      : "Search name (e.g. Benadryl)"
                  }
                  className="w-full b1 medium py-4 pl-12 border border-gray-300 focus:outline-none focus:ring-transparent focus:border-evvy-blue"
                  {...props}
                />
              </div>
            )}
            onSelect={(value) => selectMedication(value)}
            renderMenu={(children) => (
              <div className="w-full max-h-72 border border-gray-300 mt-1 overflow-y-scroll">
                {children}
              </div>
            )}
            renderItem={(item, isHighlighted) => (
              <div
                className={`item w-full p-3 b1 medium ${
                  isHighlighted ? "bg-evvy-silverfish bg-opacity-50" : ""
                }`}
                key={item}
              >
                {item}
              </div>
            )}
          />
          {searchTermValue && (
            <div
              onClick={() => setModalOpen(true)}
              className="w-full p-4 cursor-pointer border border-gray-300 font-semibold uppercase text-sm"
            >
              I don't see my medication listed →
            </div>
          )}
          {selectedMeds.length > 0 && (
            <div>
              <div className="font-semibold uppercase text-sm pt-6">
                Your current treatment
              </div>
              <div className="flex flex-wrap">
                {selectedMeds.map((medName) => (
                  <div
                    key={medName}
                    className="flex items-center b1 medium border border-evvy-pine rounded-full pl-3 pr-2 py-1 mt-3 mr-2 cursor-pointer transition-colors text-evvy-pine hover:bg-evvy-pine hover:text-white"
                    onClick={() => removeMedication(medName)}
                  >
                    {medName}
                    <span className="ml-1">
                      <svg
                        className="block h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex mt-6">
          {selectedMeds.length > 0 ? (
            <BlueRectangularButton
              text="Continue"
              paddingXClass="sm:px-32"
              onClick={submitMedicationPage}
              disabled={loading || loadingMedications}
            />
          ) : (
            <WhiteRectangularButton
              text="I don't take any medication"
              onClick={submitMedicationPage}
              disabled={loading || loadingMedications}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MedicationQuestions;
