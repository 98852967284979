import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
} from "react";

export interface CartItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
}

export interface CartState {
  items: CartItem[];
  cartId: string | null;
}

const getStoredCart = () => {
  const storedCart = JSON.parse(localStorage.getItem("aLaCartItems") || "{}");
  if (storedCart.expiry && storedCart.expiry > new Date().getTime()) {
    return storedCart;
  }
  return { items: [], cartId: null };
};

const initialState: CartState = getStoredCart();

export type CartAction =
  | { type: "ADD_TO_CART"; item: CartItem }
  | { type: "REMOVE_FROM_CART"; itemId: string }
  | { type: "CLEAR_CART" }
  | { type: "SET_CART_ID"; cartId: string | null }
  | { type: "SET_CART"; cartId: string | null; items: CartItem[] };

const cartReducer = (state: CartState, action: CartAction): CartState => {
  switch (action.type) {
    case "ADD_TO_CART":
      return { ...state, items: [...state.items, action.item] };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.itemId),
      };
    case "CLEAR_CART":
      return { ...state, items: [] };
    case "SET_CART_ID":
      return { ...state, cartId: action.cartId };
    case "SET_CART":
      return { ...state, cartId: action.cartId, items: action.items };
    default:
      return state;
  }
};

const CartContext = createContext<{
  state: CartState;
  dispatch: React.Dispatch<CartAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // this state is the local cart state -- we will remove this once we are fully on the persistant cart
  const [state, dispatch] = useReducer(cartReducer, initialState);

  useEffect(() => {
    // set expiration date for localStorage after 7 days
    const ttl = 7 * 24 * 60 * 60 * 1000;
    const expiry = new Date().getTime() + ttl;
    localStorage.setItem(
      "aLaCartItems",
      JSON.stringify({
        items: state.items,
        expiry,
        cartId: state.cartId,
      })
    );
  }, [state.items]);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
