import { useContext } from "react";
import { ConsultsContext } from "../../contexts/consultsContext";
import {
  createAndGoToSTIConsult,
  handleSTIConsultCTAClick,
} from "../../components/care/utils";
import { ReactComponent as AlertTriangle } from "../../components/common/icons/alert-triangle.svg";
import { sendClickedSTIIntakeCTA } from "../../utils/analytics/customEventTracking";

export const StartStiConsultIntakeButton = ({
  ctaPrimary,
  testHash,
  location,
}: {
  ctaPrimary: boolean;
  testHash: string;
  location: "testCard" | "untreatedSTIModal" | "careIntakeModal";
}) => {
  const { consults } = useContext(ConsultsContext);
  const latestSTIConsult = consults?.find(
    (consult) => consult.test_hash === testHash && consult.type === "sti"
  );

  const ctaText = latestSTIConsult ? "Finish STI Intake" : "Get STI Treatment";

  return (
    <button
      className={
        ctaPrimary
          ? "w-full bg-evvy-blue text-black hover:bg-evvy-pine hover:text-white mt-auto p-6 font-semibold tracking-wider focus:outline-none flex justify-center"
          : "w-full bg-white border border-black hover:bg-evvy-black hover:text-white mt-auto p-6 font-semibold tracking-wider focus:outline-none flex justify-center"
      }
      onClick={async () => {
        handleSTIConsultCTAClick(
          testHash,
          { location, testHash, ctaText },
          latestSTIConsult
        );
      }}
    >
      <AlertTriangle className="stroke-evvy-black h-4 w-5 mr-4" />
      {ctaText} →
    </button>
  );
};
