import { ProblemsSummary, TestScores } from "./test";
import { User } from "./user";

export type ProviderTestOrder = {
  provider: User;
  patient_email: string;
  test_pretty_hash?: string;
  bacteria_data: any[];
  results_pdf_url: string;
  create_date: string;
  status: string;
  results_released_at?: string;
  sample_taken_at?: string;
  provider_order_link?: string;
  add_expanded_pcr: boolean;
  care_eligibility_info: CareEligibilityInfo;
  problems_summary: ProblemsSummary;
  scores: TestScores;
  patient_first_name: string;
  patient_last_name: string;
};

export type CareEligibilityInfo = {
  status: "Eligible" | "Eligibility lapsed" | "Enrolled" | "Ineligible";
  tooltip: string;
};

export enum Status {
  WAITING = "Waiting for Patient",
  RECEIVED_AT_LAB = "Test Received at Lab",
  RECEIVED = "Patient Received Test",
  SEQUENCING = "Test Sequencing at Lab",
  READY = "Results Ready",
}

export type ProviderTestData = {
  orderDate: string;
  patientEmail: string;
  testHash: string;
  status: Status;
  sampleDate?: string;
  hasAddedExpan?: boolean;
  resultsData: {
    reportUrl?: string;
    bacteriaData: any[];
    problemsSummary: ProblemsSummary;
  };
  careEligibilityInfo?: { status: string; tooltip: string };
  scores: TestScores;
  patientFirstName: string;
  patientLastName: string;
};
