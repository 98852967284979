import classNames from "classnames";

const indexColor = (index) => {
  const colorMap = {
    0: "evvy-pine",
    1: "coral",
    2: "crazy-purple",
    3: "marine",
    4: "evvy-blue",
    5: "evvy-black",
    6: "highlighter-salmon",
    7: "evvy-pine",
    8: "coral",
    9: "crazy-purple",
    10: "marine",
    11: "evvy-blue",
  };
  const numColors = Object.keys(colorMap).length;
  const modIndex = index % numColors;
  return colorMap[modIndex];
};

// multiselect checkboxes
const indexCheckboxStyles = (index) => {
  const isMobile = window && window.innerWidth < 600 ? true : false;
  const color = indexColor(index);
  return `border-2 rounded border-black border-opacity-20 w-[16px] h-[16px] shadow-none cursor-pointer focus:ring-0 focus:outline-0 text-${color} opacity-100 active:bg-evvy-cream checked:bg-${color} ${
    !isMobile ? `hover:bg-${color}` : ""
  }`;
};

// for radio buttons. takes optional index to color it, otherwise blue
const indexRadioStyles = (index) => {
  var color = "evvy-blue";
  return `border-2 border-black border-opacity-20 w-[16px] h-[16px] rounded-full shadow-none cursor-pointer focus:ring-0 focus:outline-0 text-${color} opacity-100 active:bg-evvy-cream checked:bg-${color} active:bg-${color} hover:bg-${color} focus:bg-${color}`;
};

const multiselectFollowupRadioStyles = (index, isShortAnswer) => {
  var color = "evvy-blue";

  return classNames(
    `border-2 border-black border-opacity-20 w-[14px] h-[14px] rounded-full shadow-none cursor-pointer focus:ring-0 focus:outline-0 text-${color} opacity-100 active:bg-evvy-cream checked:bg-${color} active:bg-${color} hover:bg-${color} focus:bg-${color}`,
    {
      hidden: isShortAnswer,
    }
  );
};

const multiselectFollowupStyles = (firstChild, lastChild) => {
  return classNames(
    `inline-flex items-center justify-center align-center p-5 bg-evvy-cream peer-checked:bg-highlighter-mint cursor-pointer dark:hover:text-evvy-black dark:peer-checked:bg-highlighter-mint peer-checked:text-evvy-black hover:text-evvy-black hover:bg-gray-100 dark:text-gray-700 dark:bg-evvy-cream dark:hover:bg-highlighter-mint my-px`,
    {
      "rounded-t-lg": firstChild,
      "rounded-b-lg": lastChild,
    }
  );
};

const transparentCarePhaseColorMap = {
  treat: {
    full: "bg-highlighter-salmon",
    opacity70: "bg-highlighter-salmon/70",
    opacity40: "bg-highlighter-salmon/40",
  },
  rebuild: {
    full: "bg-evvy-silverfish",
    opacity70: "bg-evvy-silverfish/70",
    opacity40: "bg-evvy-silverfish/40",
  },
  relief: {
    full: "bg-highlighter-mint",
    opacity70: "bg-highlighter-mint/70",
    opacity40: "bg-highlighter-mint/40",
  },
};

export {
  indexColor,
  indexCheckboxStyles,
  indexRadioStyles,
  multiselectFollowupStyles,
  multiselectFollowupRadioStyles,
  transparentCarePhaseColorMap,
};
