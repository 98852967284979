/* Libraries */
import React from "react";
import { useDispatch } from "react-redux";

/* Local Imports */
import { setClickedValence } from "../../pages/compare/compareSlice";

const DropDownMenu = ({
  options,
  onSubmit,
  hasPlaceholder,
  currentSelection,
  valAcc = (d) => d,
  labelAcc = (d) => d,
  disabled = false,
}) => {
  /* Redux */
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const hash = e.target.value;
    const option = options.find((option) => valAcc(option) === hash);
    onSubmit(option);
    dispatch(setClickedValence(null)); // clear clicked state when dropdown changes
  };

  return (
    <div className="flex items-center">
      <div className="relative">
        <select
          onChange={handleChange}
          disabled={disabled}
          className="cursor-pointer capitalize bg-none bg-transparent rounded-md border-solid border-evvy-blue py-3 w-full lg:w-48 max-w-48"
          value={currentSelection}
        >
          {hasPlaceholder && (
            <option value="DEFAULT" disabled>
              {options.length > 1 ? "Select..." : "No Other Tests"}
            </option>
          )}
          {options &&
            options.map((option) => (
              <option key={valAcc(option)} value={valAcc(option)}>
                {labelAcc(option)}
              </option>
            ))}
        </select>
        <div className="absolute right-3 top-4 text-evvy-blue">↓</div>
      </div>
    </div>
  );
};

export default DropDownMenu;
