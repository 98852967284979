import { ReactComponent as ArrowUp } from "./icons/arrow-up-no-tail.svg";
import { ReactComponent as ArrowDown } from "./icons/arrow-down-no-tail.svg";

type ShowDetailButtonProps = {
  className?: string;
  open: boolean;
  toggleOpenClose: () => void;
};

export const ShowDetailButton: React.FC<ShowDetailButtonProps> = ({
  className = "",
  open,
  toggleOpenClose,
}: ShowDetailButtonProps) => {
  return (
    <button
      className={`flex justify-center items-center ${className}`}
      onClick={toggleOpenClose}
    >
      {open ? (
        <ArrowUp className="h-4 w-4 fill-evvy-black" />
      ) : (
        <ArrowDown className="h-4 w-4 fill-evvy-black" />
      )}
    </button>
  );
};
