import { Cart } from "../../types/cart";
import { cn } from "../../utils/cn";
import { ReactComponent as CartIcon } from "../common/icons/cart.svg";
import { sendTreatmentCartClickedCartNav } from "../../utils/analytics/customEventTracking";

const ShoppingCartNavIcon = ({
  cart,
  openCart,
  className = "",
  baseCartAnalyticsArgs,
}: {
  cart?: Cart;
  openCart: () => void;
  className?: string;
  baseCartAnalyticsArgs: any;
}) => {
  const hasItemsInCart = cart && cart.line_items.length > 0;
  return (
    <div className={cn("relative flex", className)}>
      <CartIcon
        className="cursor-pointer h-8 w-8"
        onClick={() => {
          sendTreatmentCartClickedCartNav(baseCartAnalyticsArgs);
          openCart();
        }}
      />
      <div
        className={cn(
          "absolute top-1 -right-0.5 inline-block w-2 h-2 bg-dv-orange rounded-full",
          { hidden: !hasItemsInCart }
        )}
      />
    </div>
  );
};

export default ShoppingCartNavIcon;
