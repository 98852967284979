// external
import React from "react";
// components
import CloseX from "../../common/closeX";
import RectangularButton from "../../common/rectangularButton";
import { ReactComponent as EvvyTextLogo } from "../../plan_unfurled/icons/evvy-logo-text.svg";
// lib
import {
  reviewCopy as COPY,
  links,
} from "../../../pages/plan_unfurled/constants.tsx";
import { secondaryColors } from "../../../utils/viz";

const LeaveReviewContentTemplate = ({ handleClose }) => {
  /* Actions */
  const onClose = () => {
    handleClose();
  };

  return (
    <div className="w-full flex flex-col items-center p-10 bg-evvy-white">
      {/* close button */}
      <div className="flex justify-end items-center mb-6">
        <CloseX handleClose={onClose} textColorClass="text-evvy-black" />
      </div>
      <EvvyTextLogo
        className="mb-6"
        fill={secondaryColors["evvy-black"]}
        stroke={secondaryColors["evvy-black"]}
      />
      <h5 className="w-full text-center mb-1">{COPY.TITLE}</h5>
      <div className={`caption text-center pt-5 mb-8`}>{COPY.CAPTION}</div>
      <RectangularButton
        isLink
        href={links.REVIEW}
        onClick={handleClose}
        noLeftMargin
        text="leave a review"
        bgColorClass="bg-evvy-blue"
        textColorClass="text-evvy-black"
      />
    </div>
  );
};

export default LeaveReviewContentTemplate;
