import { useState } from "react";
import Modal from "../../components/common/modal";
import { Formik, Field, Form } from "formik";

import { authService } from "../../services/auth";
import { accountService } from "../../services/account";

interface PasswordModalProps {
  handleClose: () => void;
}

const PasswordModal: React.FC<PasswordModalProps> = ({ handleClose }) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  return (
    <Modal widthClass="w-full md:w-6/12" closeModal={handleClose}>
      <div className="bg-white p-12">
        <div className="mb-12">
          <h2>Update your password</h2>
        </div>

        <Formik
          initialValues={{
            current_password: "",
            new_password: "",
            new_password_again: "",
          }}
          validate={(values) => {
            setError(null);

            if (
              !values.current_password ||
              !values.new_password ||
              !values.new_password_again
            ) {
              setError("Missing required fields");
            }
            if (values.new_password !== values.new_password_again) {
              setError("New passwords do not match");
            }
            return {};
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            setError(null);
            setLoading(true);
            accountService.updateAccount(
              values,
              () => {
                // log user out and make them log in again
                authService.logout();
                handleClose();
              },
              (error, response) => {
                setError(response || "Error saving account info");
                setLoading(false);
              }
            );
          }}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => (
            <Form>
              {error && (
                <div className="bg-coral p-2 px-3 font-medium rounded-sm my-6">
                  {error}
                </div>
              )}

              <div className="mb-8">
                <div className="text-lg font-medium mb-2">Current Password</div>
                <Field
                  id="current_password"
                  name="current_password"
                  type="password"
                  autoFocus
                  autoComplete="off"
                  required
                  placeholder="Current Password"
                  className="appearance-none block w-full p-6 bg-evvy-cream rounded-xs border-none focus:outline-none focus:ring-transparent focus:border-none"
                />
              </div>

              <div className="mb-8">
                <div className="text-lg font-medium mb-2">New Password</div>
                <Field
                  id="new_password"
                  name="new_password"
                  type="password"
                  autoComplete="off"
                  required
                  placeholder="Enter New Password"
                  className="appearance-none block w-full p-6 bg-evvy-cream rounded-xs border-none focus:outline-none focus:ring-transparent focus:border-none"
                  data-html={true}
                  data-tip="<div>
                    8 or more characters<br/>
                    Alphanumeric characters<br/>
                    No common passwords<br/>
                    Password can't be similar to email
                  </div>"
                />
                {/*<ReactTooltip place="top" backgroundColor="#F3F1EE" textColor="#000000" effect="solid" />*/}
              </div>

              <div className="mb-8">
                <div className="text-lg font-medium mb-2">
                  Re-enter Password
                </div>
                <Field
                  id="new_password"
                  name="new_password_again"
                  type="password"
                  autoComplete="off"
                  required
                  placeholder="Enter New Password"
                  className="appearance-none block w-full p-6 bg-evvy-cream rounded-xs border-none focus:outline-none focus:ring-transparent focus:border-none"
                />
              </div>

              <div className="flex items-center">
                <button
                  onClick={handleClose}
                  className="ml-auto underline underline-offset-4 uppercase text-sm font-semibold tracking-wider cursor-pointer"
                >
                  Cancel
                </button>
                <button
                  className="cursor-pointer tracking-wider ml-12 py-6 px-20 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
                  type="submit"
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default PasswordModal;
