import { Link } from "react-router-dom";
import Markdown from "markdown-to-jsx";
import { ReactComponent as AlertTriangle } from "../common/icons/alert-triangle.svg";
import { getIsMobile } from "../../utils/general";
import {
  sendClickedSTIIntakeCTA,
  sendPlanClickedL1ResultsLink,
} from "../../utils/analytics/customEventTracking";
import { Test } from "../../types/test";
import {
  createAndGoToSTIConsult,
  handleSTIConsultCTAClick,
} from "../care/utils";

const STI_WARNING_COPY_MD = [
  "It is critical that you talk to your healthcare provider about treating any detected STIs as soon as possible.",
  "This plan is focused on shifting your overall vaginal microbiome and does **not** include any steps related to STI treatment. This plan will not be effective if STIs are not treated first.",
];
const STI_WARNING_LINK_COPY =
  "You can review STI results and STI treatment guidelines";

const STI_TREATMENT_COPY = [
  "It is critical that you talk to your healthcare provider about treating any detected STIs as soon as possible.",
  "This plan is focused on shifting your overall vaginal microbiome and does **not** include any steps related to STI treatment.",
];

const STI_TREATMENT_LINK_COPY = "Get STI Treament";

export const StiPositiveBanner: React.FC<{ test: Test }> = ({ test }) => {
  const isMobile = getIsMobile();

  const stiCopy = test.can_open_sti_treatment_consult
    ? STI_TREATMENT_COPY
    : STI_WARNING_COPY_MD;

  const stiLinkCopy = test.can_open_sti_treatment_consult
    ? STI_TREATMENT_LINK_COPY
    : STI_WARNING_LINK_COPY;

  return (
    <div className="bg-evvy-cream text-evvy-black px-5 py-4 mt-5 rounded-md justify-start items-center gap-5 inline-flex">
      <div className="h-5 w-6 relative">
        <AlertTriangle className="stroke-evvy-black" />
      </div>
      <div className={`${isMobile ? "b3" : "b2"} text-left space-y-3`}>
        {stiCopy.map((copy, index) => (
          <div key={`sti-${index}`}>
            <Markdown>{copy}</Markdown>
          </div>
        ))}
        <div>
          {stiLinkCopy}{" "}
          {test.can_open_sti_treatment_consult ? (
            <span
              className="underline persistSize lowercase cursor-pointer"
              onClick={() => {
                if (test) {
                  handleSTIConsultCTAClick(
                    test.hash,
                    {
                      location: "planPageDisclaimer",
                      testHash: test.hash,
                      ctaText: stiLinkCopy,
                    },
                    test.latest_sti_consult
                  );
                }
              }}
            >
              here.
            </span>
          ) : (
            <Link
              className="underline persistSize"
              to={`/results/${test.hash}/download/`}
              onClick={() => {
                if (!test.can_open_sti_treatment_consult) {
                  sendPlanClickedL1ResultsLink({
                    testHash: test.hash,
                    type: "sti",
                  });
                }
              }}
            >
              here.
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
