import { cn } from "../../../utils/cn";
import { CircleCheckmark } from "../../common/circleCheckmark";

const bundleFeatures = [
  { text: "HSA/FSA Eligible" },
  { text: "Free Shipping" },
  { text: "OB/GYN Approved", hideOnSmall: true },
];

const BundleEcommFeatures = () => {
  return (
    <div className="b2 mt-4 md:mt-5 mb-3 md:mb-0 text-center caption">
      <div className="flex justify-center md:justify-between mb-2">
        {bundleFeatures.map((feature, index) => (
          <div
            key={index}
            className={cn("flex justify-center items-center", {
              "ml-3 xs:ml-5": index > 0,
              "hidden xs:flex": feature.hideOnSmall,
            })}
          >
            <CircleCheckmark
              className="h-4 w-4"
              circleClassName="stroke-marine stoke-3"
              checkmarkClassName="stroke-marine"
            />
            <span className="ml-2">{feature.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BundleEcommFeatures;
