/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, ReactNode } from "react";
import { Cart } from "../types/cart";
import { cartService } from "../services/cart";
import { CartContext } from "./cartContext";
import { testsService } from "../services/tests";
import { Test } from "../types/test";
import axios from "axios";

export const CartContextProvider = ({
  isAuthenticated,
  children,
}: {
  isAuthenticated: boolean;
  children: ReactNode;
}) => {
  const [cart, setCart] = useState<Cart>();
  const [latestReadyTest, setLatestReadyTest] = useState<Test>();
  const [showCart, setShowCart] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    async function getCartIfExists() {
      try {
        const response = await cartService.getLatestActiveCart();
        if (response) {
          setCart(response);
        }
      } catch (error) {
        console.error(error);
        setError("Error fetching cart");
      } finally {
        setLoading(false);
      }
    }

    async function getLatestReadyTest() {
      try {
        const response = await testsService.asyncFetchLatestReadyTest();
        setLatestReadyTest(response.data);
      } catch (error) {
        if (axios.isAxiosError(error) && error?.response?.status === 404) {
          // no ready test
          setLatestReadyTest(undefined);
        } else {
          console.error(error);
          setError("Error fetching latest ready test");
        }
      }
    }

    if (isAuthenticated) {
      getLatestReadyTest();
      getCartIfExists();
    }
  }, [isAuthenticated, refetch]);

  const refetchCartAndTest = () => {
    setRefetch((prev) => !prev);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        showCart,
        setShowCart,
        latestReadyTest,
        loading,
        error,
        refetchCartAndTest,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
