import PrescriptionLearnMoreButton from "./PrescriptionLearnMoreButton";
import { Consult, Prescription, PrescriptionRecord } from "../../types/care";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StethoscopeIcon } from "./icons/stethoscopeIcon";
import { ReactComponent as XIcon } from "../common/icons/X.svg";
import { PrescriptionTag } from "./prescriptionTag";
import {
  ANTIFUNGAL_FLUCONAZOLE_SLUG,
  BORIC_ACID_14_DAY_SLUG,
} from "./constants";
import { getPurposeTagText, getTreatmentPurposeTagColor } from "./utils";

const FREE_ADD_ON_COPY: Record<string, string> = {
  [ANTIFUNGAL_FLUCONAZOLE_SLUG]:
    "Since you mentioned a history of yeast infections after treatment, your provider proactively prescribed an antifungal for you — free of charge. Info on how to use this treatment as part of your plan below!",
  [BORIC_ACID_14_DAY_SLUG]:
    "Since you mentioned your symptoms get worse after your period, your provider proactively prescribed extra doses of boric acid to mitigate those post-period symptoms. We included these in your treatment package free of charge!",
};

const FREE_ADD_ON_SLUGS = [ANTIFUNGAL_FLUCONAZOLE_SLUG, BORIC_ACID_14_DAY_SLUG];

// renders a prescription card which when clicked opens up modal
const PrescriptionCard: React.FC<{
  prescription: Prescription;
  consult: Consult;
  openModal: () => void;
  refillablePrescriptions?: PrescriptionRecord[];
  selectedTreatmentSlugs?: string[];
  transparentCareEnabled?: boolean;
}> = ({
  prescription,
  consult,
  openModal,
  refillablePrescriptions,
  selectedTreatmentSlugs,
  transparentCareEnabled,
}) => {
  const isAlaCareTreatmentPlan = consult?.purchase_type === "a-la-care";
  const isv0BundleTreatmentPlan = consult?.purchase_type === "v0-bundle";

  const SEEN_PROVIDER_ALERT_FOR_PRESCRIPTION = `closed-provider-alert-${prescription.slug}-${consult.uid}`;
  let hasClosedProviderAlert = localStorage.getItem(
    SEEN_PROVIDER_ALERT_FOR_PRESCRIPTION
  )
    ? true
    : false;

  // Show the "free add on" provider message if they haven't closed it previously
  // and the treatment is NOT part of the selected treatments and the treatment is part of the free add-ons
  const [showProviderAlert, setShowProviderAlert] = useState(
    !hasClosedProviderAlert &&
      !selectedTreatmentSlugs?.includes(prescription.slug) &&
      FREE_ADD_ON_SLUGS.includes(prescription.slug)
  );

  const purposeTagBGColor = getTreatmentPurposeTagColor(
    prescription.purpose_tag
  );
  const navigate = useNavigate();

  const showRefillCTA = useMemo(() => {
    const isRefillablePrescription = refillablePrescriptions?.find(
      (prescriptionRecord) =>
        prescriptionRecord.product.slug === prescription.slug
    );
    const consultReadyForRefills = consult.ready_for_refills;
    return isRefillablePrescription && consultReadyForRefills;
  }, [refillablePrescriptions, prescription.slug]);

  return (
    <div className="bg-evvy-cream py-[15px] px-[15px] rounded-lg">
      {transparentCareEnabled &&
        !isv0BundleTreatmentPlan &&
        showProviderAlert && (
          <div className="p-[15px] bg-highlighter-mint rounded-lg mb-[26px] flex">
            <div className="flex">
              <div className="mr-4">
                <StethoscopeIcon className="white" strokeClassName="black" />
              </div>
              <div>
                <p className="t4 mb-0">From your provider</p>
                <p className="mb-0">
                  {FREE_ADD_ON_COPY[prescription.slug] || ""}
                </p>
              </div>
            </div>
            <div>
              <button
                onClick={() => {
                  localStorage.setItem(
                    SEEN_PROVIDER_ALERT_FOR_PRESCRIPTION,
                    "true"
                  );
                  setShowProviderAlert(false);
                }}
              >
                <XIcon />
              </button>
            </div>
          </div>
        )}
      <div className="flex">
        {prescription?.image_url && (
          <div className="flex-shrink-0 bg-mud rounded-md h-[75px] w-[75px] p-2 pb-0 mr-4">
            <img
              src={prescription?.image_url}
              alt={prescription?.title_short_display}
              className="h-full w-full"
            />
          </div>
        )}
        <div className="w-full">
          <div className="flex justify-between">
            <div className="mr-4 w-min mb-2">
              {isAlaCareTreatmentPlan || transparentCareEnabled ? (
                prescription.purpose_tag && (
                  <div className={`rounded-md ${purposeTagBGColor} p-2`}>
                    <span className="text-sm uppercase tracking-wider semibold">
                      {getPurposeTagText(prescription.purpose_tag)}
                    </span>
                  </div>
                )
              ) : (
                <PrescriptionTag tag={prescription?.tag} />
              )}
            </div>
            <div className="ml-auto justify-end">
              <PrescriptionLearnMoreButton
                consult={consult}
                location="treatment_plan"
                prescriptionSlug={prescription?.slug}
                openModal={openModal}
              />
            </div>
          </div>
          <div className="t1 medium mb-1">
            {prescription?.title_short_display}
          </div>
          {/* When transparentCare flag is torn down, we can remove this */}
          {!transparentCareEnabled && (
            <div className="mt-0 mb-1 text-lg">
              {consult.purchase_type === "a-la-care" &&
                prescription?.quantity_text?.replace("|", " ")}
              {consult.purchase_type === "v0-bundle" &&
                prescription?.dose_text?.replace("|", " ")}{" "}
            </div>
          )}
          {(isAlaCareTreatmentPlan || transparentCareEnabled) &&
            prescription.use_tag && (
              <>
                <div
                  className={`${purposeTagBGColor} rounded-full p-1.5 inline-block mr-2 my-auto`}
                />
                <span className="b2 medium">{prescription.use_tag}</span>
              </>
            )}
          {(isAlaCareTreatmentPlan || transparentCareEnabled) &&
            showRefillCTA && (
              <div className="mt-4 border border-px w-[160px] border-black p-3 flex items-center align-center">
                <span
                  onClick={() => {
                    // pass in query param for cart treatments
                    navigate(
                      `/care/consults/${consult?.uid}/refills/${prescription.slug}`
                    );
                  }}
                  className={classNames(
                    "text-evvy-black t4 border-black cursor-pointer text-center w-full"
                  )}
                >
                  Get a refill →
                </span>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default PrescriptionCard;
