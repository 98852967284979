import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { max, ascending } from "d3-array";
import { scaleLinear } from "d3-scale";

// components
import StackedBar from "./stackedBar";
import LabelLine from "./labelLine";

// lib
import { setHoveredValence, setClickedValence } from "./compareSlice";
import { fill } from "../../utils/viz";
import { useResponsiveSize } from "../../utils/customHooks";
import {
  seriesGenerator,
  chordSeriesGenerator,
} from "../../utils/dataTransformations";

const CompareViz = ({
  baseData,
  compareData,
  showSankey = true,
  hasInteractivityDisabled = false,
  baseLoading = false,
  compareLoading = false,
}) => {
  /* Refs */
  const svgRef = useRef(null);

  /* State Management */
  const dispatch = useDispatch();
  const selectedValence = useSelector(
    (state) =>
      state.compare.value.clickedValence || state.compare.value.hoveredValence
  );
  const clickedValence = useSelector(
    (state) => state.compare.value.clickedValence
  );
  /* Custom Hooks */

  // retrieves dynamic svg dimensions (responds to screen resize)
  const [[width, height]] = useResponsiveSize(
    svgRef.current && svgRef.current.parentElement.clientWidth,
    800,
    svgRef.current && svgRef.current.parentElement.clientHeight,
    300
  );

  /* Config */
  const config = {
    height: height,
    width: width,
    margin: { top: 0, right: 0, bottom: 0, left: 0 },
    barWidth: 60,
  };

  /* Data */

  const seriesBase = baseData && seriesGenerator([baseData.bacteria_valences]);
  const seriesCompare =
    compareData && seriesGenerator([compareData.bacteria_valences]);
  const seriesAll = [...(seriesBase || []), ...(seriesCompare || [])]; // optionally spread based on truthiness
  const chords = seriesBase && seriesCompare && chordSeriesGenerator(seriesAll); // only create chords if there are two series

  /* Scales */

  const xScale = scaleLinear()
    .domain([0, 1])
    .range([config.barWidth - 1, config.width - config.barWidth + 1]); // account for gap

  const yScale = scaleLinear()
    .domain([0, max(seriesAll, (d) => max(d, (d) => d[1]))])
    .rangeRound([config.height - config.margin.bottom, config.margin.top]);

  /* Path Generators */

  const barGenerator = (series, xPos, biome) => (
    <StackedBar
      series={series}
      xPos={xPos}
      biome={biome}
      yScale={yScale}
      width={config.barWidth}
      hasInteractivityDisabled={hasInteractivityDisabled}
      clickedValence={clickedValence}
    />
  );

  const placeholderBarGenerator = (xPos) => (
    <rect
      x={xPos}
      y={0}
      height={"100%"}
      width={config.barWidth}
      fill="#C4C4C4"
      clipPath="url(#round-corner)"
      className="relative z-10"
    />
  );

  const animatedBarGenerator = (xPos, className, fill) => (
    <rect
      x={xPos}
      y={0}
      height="100%"
      width={config.barWidth}
      className={className}
      fill={fill}
    />
  );

  return (
    <svg
      ref={svgRef}
      className="w-full h-full"
      style={{ overflow: "visible" }}
      onMouseOut={() => dispatch(setHoveredValence(null))}
    >
      <defs>
        <linearGradient id="Gradient1" x1="0" x2="0" y1="0" y2="1">
          {fill
            .domain()
            .reverse()
            .map((type, i) => (
              <stop
                key={`${type}-${i}-${1}`}
                offset={i * 0.25}
                stopColor={fill(type)}
              />
            ))}
        </linearGradient>
        <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
          {fill.domain().map((type, i) => (
            <stop
              key={`${type}-${i}-${2}`}
              offset={i * 0.25}
              stopColor={fill(type)}
            />
          ))}
        </linearGradient>
      </defs>

      {/* first stacked bar */}
      <g>
        {compareLoading
          ? animatedBarGenerator(0, "animatedBarOne", "url(#Gradient1)")
          : compareData && seriesCompare
          ? barGenerator(seriesCompare, 0, compareData.bacteria_valences)
          : placeholderBarGenerator(0)}
      </g>
      {compareData && seriesCompare && (
        <g>
          <LabelLine
            xPos={0}
            series={seriesCompare}
            biome={compareData.bacteria_valences}
            yScale={yScale}
            loading={compareLoading}
          />
        </g>
      )}
      {/* chords */}
      {!baseLoading && !compareLoading && (
        <g className="group">
          {chords &&
            chords.map((chord) => (
              <path
                key={`${chord.key}-chord`}
                d={generateChordPath(chord, xScale, yScale)}
                style={{
                  fill:
                    !clickedValence || clickedValence === chord.key
                      ? `${fill(chord.key)}`
                      : "#C4C4C4",
                }}
                className={`
                cursor-pointer
                transition-opacity
                ${
                  showSankey &&
                  !hasInteractivityDisabled &&
                  selectedValence === chord.key
                    ? "opacity-90"
                    : "opacity-30"
                }`}
                onMouseOver={() => dispatch(setHoveredValence(chord.key))}
                onClick={() => dispatch(setClickedValence(chord.key))}
              />
            ))}
        </g>
      )}
      {/* center line */}
      <line
        x1="50%"
        x2="50%"
        y1="0"
        y2="100%"
        stroke="black"
        strokeDasharray="2"
        strokeOpacity="30%"
      />
      {/* second stacked bar */}
      <g>
        {baseLoading
          ? animatedBarGenerator(
              config.width - config.barWidth,
              "animatedBarTwo",
              "url(#Gradient2)"
            )
          : baseData && seriesBase
          ? barGenerator(
              seriesBase,
              config.width - config.barWidth,
              baseData.bacteria_valences
            )
          : placeholderBarGenerator(config.width - config.barWidth)}
      </g>
      {baseData && seriesBase && (
        <g>
          <LabelLine
            xPos={config.width}
            series={seriesBase}
            biome={baseData.bacteria_valences}
            yScale={yScale}
            loading={baseLoading}
            right
          />
        </g>
      )}
    </svg>
  );
};

/* chord path generator */
function generateChordPath(d, xScale, yScale) {
  const start = d[0];
  const end = d[1];
  const x0 = xScale(start.data.xPosition),
    x1 = xScale(end.data.xPosition),
    y0 = yScale(start[1]),
    y1 = yScale(end[1]),
    yB0 = yScale(start[0]),
    yB1 = yScale(end[0]),
    h0 = Math.abs(y0 - yB0),
    h1 = Math.abs(y1 - yB1),
    midX = x0 + (x1 - x0) * 0.5;

  return `
      M ${x0} ${y0}
      C ${midX} ${y0} ${midX} ${y1} ${x1} ${y1}
      L ${x1} ${y1 + h1}
      C ${midX} ${y1 + h1} ${midX} ${y0 + h0} ${x0} ${y0 + h0}
      Z
    `;
}

export default CompareViz;
