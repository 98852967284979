import { createSlice } from "@reduxjs/toolkit";
import { CompareSliceState } from "../../types/subscription";

const initialCompareSliceState: CompareSliceState = {
  value: {
    hoveredValence: null,
    clickedValence: null,
    typeModalData: [null, null, false],
  },
};

export const compareSlice = createSlice({
  name: "compare",
  initialState: initialCompareSliceState,
  reducers: {
    setHoveredValence: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value.hoveredValence = action.payload;
    },
    setClickedValence: (state, action) => {
      if (state.value.clickedValence === action.payload) {
        // click on the same one to deselect
        state.value.clickedValence = null;
      } else state.value.clickedValence = action.payload;
    },
    setTypeModalData: (state, action) => {
      const { payload } = action;
      const [typeModalDataType, typeModalDataInfo, typeModalDataOpen] = payload;
      state.value.typeModalData = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHoveredValence, setClickedValence, setTypeModalData } =
  compareSlice.actions;

export default compareSlice.reducer;
