import React, { useState } from "react";
import { ReactComponent as SendIcon } from "../icons/send.svg";

// lib
import { analyticsClickHandler } from "../../../utils/analytics/helpers";
import { eventNames } from "../../../utils/analytics/customEventTracking";
import { useIntercom } from "react-use-intercom";

const StickyMessageInputBar = ({
  sending,
  sendMessage,
  isClosed,
  analyticsEventArgs,
}) => {
  const [hasMessageInput, setHasMessageInput] = useState(false);
  const { show } = useIntercom();

  const submitForm = (e) => {
    e.preventDefault(); // to not post to the current url

    const messageText = e.target.elements["message_text"].value;
    if (!messageText) return;

    const targetElement = e.target.elements["message_text"];

    sendMessage(messageText, () => {
      targetElement.value = "";
    });
  };

  return (
    <div className="w-full sticky bottom-0 bg-evvy-cream pt-7 pb-1 px-4">
      {isClosed ? (
        <React.Fragment>
          <div className="b1 regular text-center">
            This thread is now closed. Have a question about your treatment
            plan?
          </div>
          <div
            onClick={analyticsClickHandler({
              eventName: eventNames.MESSAGING_CLICKED_SUPPORT,
              eventArgs: {
                location: "message_thread",
                ...analyticsEventArgs,
              },
              clickHandler: () => show(),
            })}
            className="t4 bold text-center pt-2 pb-6 underline underline-offset-4"
          >
            Contact Evvy Support
          </div>
        </React.Fragment>
      ) : (
        <form action="#" onSubmit={submitForm}>
          <div className="relative max-w-6xl mx-auto">
            <textarea
              rows={hasMessageInput ? "5" : "1"}
              onChange={(e) =>
                e?.target?.value
                  ? setHasMessageInput(true)
                  : setHasMessageInput(false)
              }
              id="message_text"
              placeholder="Type a new message here"
              className={`w-full b1 medium h-fit p-4 border border-gray-300 focus:outline-none focus:ring-transparent focus:border-evvy-blue rounded-lg ${
                sending ? "text-gray-500" : ""
              }`}
            />
            {hasMessageInput && (
              <button
                onClick={analyticsClickHandler({
                  eventName: eventNames.MESSAGING_CLICKED_SUBMIT_MESSAGE,
                  eventArgs: analyticsEventArgs,
                })}
                type="submit"
                disabled={sending}
              >
                <SendIcon className="pointer-events-auto absolute bottom-4 right-4 h-8 w-8" />
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default StickyMessageInputBar;
