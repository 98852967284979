import { timeDay } from "d3-time";
import { format, parse } from "date-fns";

export const daysElapsed = (
  start: string | number | Date,
  end: string | number | Date
) => timeDay.count(new Date(start), new Date(end)) - 1;

export const dayOfWeek = (timestamp: number) => {
  return format(new Date(timestamp), "eee");
};

export const strDateToStrDateDayBeforeOrAfter = (
  dateString: string | undefined,
  daysBeforeOrAfter: number
) => {
  if (!dateString) return new Date().toISOString().split("T")[0]; // if dateString is undefined, return today's date
  const date = new Date(dateString);
  date.setDate(date.getDate() + daysBeforeOrAfter);
  return date.toISOString().split("T")[0];
};

export const strDateToTimestamp = (strdate: string) => {
  // converts format like 2021-05-02 to timestamp
  return new Date(`${strdate}T00:00:00`).getTime();
};

// function to convert dateToTimestamp
export const dateToTimestamp = (date: Date) => {
  return date.getTime();
};

export const timeStampToEasyTime = (timestamp: number) => {
  var datetime = new Date(timestamp * 1000);

  // separate vars just to check
  const today = new Date().setHours(0, 0, 0, 0);
  const thatDay = new Date(timestamp * 1000).setHours(0, 0, 0, 0);

  if (today === thatDay) {
    // just return time like 5:39 PM
    const time = datetime.toLocaleTimeString("en-US");
    return `${time.split(":").slice(0, 2).join(":")} ${time.split(" ")[1]}`;
  }
  // return day (prefixed with day of week), and remove time
  return `${dayOfWeek(timestamp * 1000)} ${
    datetime.toLocaleDateString("en-US").split(",")[0]
  }`;
};

export const timeStampToFullTime = (timestamp: number) => {
  var datetime = new Date(timestamp * 1000);
  return `${dayOfWeek(timestamp * 1000)} ${datetime.toLocaleString("en-US")}`;
};

export const formatDate = (timestamp: number, includeYear: boolean = true) => {
  // converts timestamp to format like "May 2, 2021"
  const dateFormat = includeYear ? "MMM d, yyyy" : "MMM d";
  return format(new Date(timestamp), dateFormat);
};

export const timeStampToUnix = (timestamp: Date) => {
  // timestamp in seconds (Unix timestamp)
  return Math.floor(timestamp.getTime() / 1000);
};

export const getDateDisplay = (strdate: string) => {
  const timestamp = strDateToTimestamp(strdate); // removes user local timezone
  return format(timestamp, "MMM d");
};

// util that strips a date's timezone for lexicographic comparison
// ex. 2024-05-02T00:00:00.000Z -> 2024-05-02 (no timezone)
// this is useful for comparing dates without worrying about timezone
// 2024-05-02 < 2024-05-03 lexicographically works because year, month,
// day are ordered from most to least significant
export const stripTimezone = (date: number | string | Date): string => {
  return new Date(date).toISOString().split("T")[0];
};


export const generateYearOptions = (startYear: number, endYear: number) => {
  const years = [];
  for (let i = endYear; i >= startYear; i--) {
    years.push(i);
  }
  return years;
};

// display util to convert string date from 06/11/2022 to "June 11, 2022"
export const getDateDisplayFromDateStr = (strdate: string) => {
  // Parse the date string to a Date object
  const date = parse(strdate, "MM/dd/yyyy", new Date());
  // Format the Date object to the desired display format
  return format(date, "MMMM d, yyyy");
};

