import { useCallback, useEffect, useState } from "react";
import { Subscription } from "../types/subscription";
import { subscriptionService } from "../services/subscription";

function useSubscription() {
  const [data, setData] = useState<Subscription>();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  const fetchSubscription = useCallback(async () => {
    try {
      const response = await subscriptionService.fetchSubscriptionInfo();
      setData(response?.data);
    } catch (error: any) {
      setError(error);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSubscription();
  }, [fetchSubscription, refetch]);

  const refetchSubscription = () => {
    setRefetch((prev) => !prev);
  };

  return {
    subscription: data,
    error,
    isLoading,
    refetchSubscription,
  };
}

export default useSubscription;
