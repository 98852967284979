import { FC } from "react";
import { ReactComponent as Checkmark } from "../common/icons/checkmark.svg";
import classNames from "classnames";

type BulletsProps = {
  bullets: string[] | React.ReactNode[];
  className?: string;
  iconType?: "checkmark" | "arrow";
  linePaddingClass?: string;
};

export const Bullets: FC<BulletsProps> = ({
  bullets,
  className,
  iconType = "checkmark",
  linePaddingClass = "pb-2",
}) => {
  return (
    <ul className={classNames("mt-1.5 list-none ml-0 text-base", className)}>
      {bullets.map((bullet, index) => (
        <li
          key={index}
          className={`flex ${
            index < bullets.length - 1 ? linePaddingClass : ""
          }`}
        >
          <span
            className={`${
              iconType === "arrow" ? "mt-0" : "mt-0.5"
            }  mr-1.5 whitespace-nowrap`}
          >
            {iconType === "arrow" ? (
              "->"
            ) : (
              <Checkmark className="text-evvy-pine" />
            )}
          </span>
          {bullet}
        </li>
      ))}
    </ul>
  );
};
