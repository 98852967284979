import React from "react";

import { ReactComponent as Check } from "../../components/plan_unfurled/icons/check.svg";
import { secondaryColors } from "../../utils/viz";
import { eventNames } from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import {
  weekCtaTitle,
  getWeekCTADescription,
  getWeekCalendlyURL,
} from "./constants";

// renders a treatment calendar CTA, based on a constant type (we have a few)
const TreatmentCalendarCTA = ({
  ctaType,
  isMember,
  treatmentPlan,
  retestLink,
}) => {

  const ctaButtonStyle =
    "bg-evvy-pine text-white py-4 px-8 w-full sm:max-w-xs rounded-sm font-semibold tracking-wider focus:outline-none";

  return (
    <div className="bg-highlighter-mint p-4 sm:p-4 rounded-lg">
      <div className="text-evvy-pine font-semibold text-lg flex items-center justify-center sm:justify-start">
        <span className="mr-2">
          <Check
            stroke={secondaryColors["evvy-pine"]}
            width="16px"
            height="16px"
          />
        </span>

        <span>{weekCtaTitle[ctaType] || "Unknown type"}</span>
      </div>
      <div className="mt-4 leading-5 sm:text-left">
        <span>{getWeekCTADescription(ctaType, isMember)}</span>
      </div>
      <div className="mt-4">
        {/* might be dynamic based on ctaType */}
        {ctaType === "final-retest" ? (
          <a href={retestLink} target="_blank">
            <button
              className={ctaButtonStyle}
              onClick={analyticsClickHandler({
                eventName: eventNames.TREATMENT_CLICKED_RETEST,
                eventArgs: {
                  consultId: treatmentPlan?.consult?.uid,
                  testHash: treatmentPlan?.test?.hash,
                  location: "calendar",
                },
              })}
            >
              Order Next Test
            </button>
          </a>
        ) : (
          <a href={getWeekCalendlyURL(ctaType)} target="_blank">
            <button
              className={ctaButtonStyle}
              onClick={analyticsClickHandler({
                eventName: eventNames.TREATMENT_CLICKED_COACHING,
                eventArgs: {
                  consultId: treatmentPlan?.consult?.uid,
                  testHash: treatmentPlan?.test?.hash,
                  location: "calendar",
                  callName: ctaType,
                },
              })}
            >
              Schedule a Call
            </button>
          </a>
        )}
      </div>
    </div>
  );
};

export default TreatmentCalendarCTA;
