import { Field } from "formik";
import {
  HdyhauSurvey,
  HealthContextFields,
  HealthContextOptions,
  HealthContextRelatedDiagnosesOptions,
} from "../../../types/test";
import { Question } from "../../../utils/healthHistoryV2";
import QuestionTitle from "./questionTitle";
import { indexCheckboxStyles, indexRadioStyles } from "../../../utils/colors";
import { uncheckNoneChangeHandler } from "../../../utils/answerUtils";

const SingleQuestion = ({
  question,
  healthContext,
  handleChange,
  setFieldValue,
  values,
  hdyhauSurvey,
}: {
  question: Question;
  healthContext: HealthContextFields;
  handleChange: () => void;
  setFieldValue: () => void;
  values: any;
  hdyhauSurvey?: HdyhauSurvey;
}) => {
  const slug = question.slug;

  let questionOptions;
  if (question.getOptions) {
    questionOptions = question.getOptions({
      currentPageHealthContextValues: values,
      healthContextValues: healthContext,
      hdyhauSurvey: hdyhauSurvey,
    });
  } else {
    questionOptions = question.isRelatedDiagnosisFollowup
      ? healthContext._related_diagnosis_options[
          slug as keyof HealthContextRelatedDiagnosesOptions
        ]
      : healthContext._options[slug as keyof HealthContextOptions];
  }

  return (
    <>
      <QuestionTitle questionTitle={question.slug} />
      <div role="group" aria-labelledby="checkbox-group">
        {questionOptions?.map((option, index) => (
          <div
            key={option[0]}
            className="mb-[10px] cursor-pointer flex items-center"
          >
            <label className="cursor-pointer flex items-center">
              <Field
                type={
                  question.answerType === "singleselect" ? "radio" : "checkbox"
                }
                name={question.slug}
                value={option[0]}
                className={
                  question.answerType === "singleselect"
                    ? indexRadioStyles(index)
                    : indexCheckboxStyles(index)
                }
                onChange={
                  question.answerType === "multiselect"
                    ? uncheckNoneChangeHandler({
                        handleChange,
                        setFieldValue,
                        fieldName: question.slug,
                        fieldValues: values[question.slug],
                      })
                    : handleChange
                }
              />
              <span className="ml-2 leading-5">{option[1]}</span>
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default SingleQuestion;
