const WhiteRectangularButton = ({
  text,
  onClick,
  hasMargin = true,
  fullWidth = false,
}: {
  text: string;
  onClick: () => void;
  hasMargin?: boolean;
  fullWidth?: boolean;
}) => {
  return (
    <button
      onClick={onClick}
      className={`t4 font-semibold tracking-wider rounded-sm w-full sm:w-auto sm:mx-auto py-5 px-10 border border-evvy-black bg-evvy-white text-evvy-black ${
        hasMargin && "mx-4"
      } ${fullWidth && "sm:w-full"}`}
    >
      {text}
    </button>
  );
};

export default WhiteRectangularButton;
