import { cn } from "../../../utils/cn";

interface TransparentPhaseHeaderProps {
  headerText: string;
  phaseColor: string;
  headerClassName?: string;
}

export const TransparentPhaseHeader: React.FC<TransparentPhaseHeaderProps> = ({
  headerText,
  phaseColor,
  headerClassName,
}) => {
  const parts = headerText.split("||");

  return (
    <h4 className={cn(headerClassName)}>
      {parts.map((part, index) => (
        <span key={index} className={cn({ [phaseColor]: index % 2 === 1 })}>
          {part}
        </span>
      ))}
    </h4>
  );
};

export default TransparentPhaseHeader;
