import { useNavigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useProviderProfile } from "../../hooks/provider/useProviderProfile";
import { useProviderTestOrders } from "../../hooks/provider/useProviderTestOrders";
import LoadingSpinner from "../../components/common/loadingSpinner";
import Layout from "../../components/layout";

export const ProviderHome = () => {
  const navigate = useNavigate();

  const [providerProfile, providerProfileLoading, providerProfileError] =
    useProviderProfile();

  useEffect(() => {
    if (providerProfile) {
      if (!providerProfile.has_completed_intake) {
        navigate("/provider/intake");
      } else if (!providerProfile.verified) {
        navigate("/provider/verify");
      }
    }
  }, [navigate, providerProfile]);

  return (
    <>
      {providerProfileError && (
        <div className="bg-red-400 p-8 rounded-2xl mb-4 max-w-5xl mx-auto">
          {providerProfileError}
        </div>
      )}
      {providerProfileLoading ? <LoadingSpinner /> : <Outlet />}
    </>
  );
};

export const VerifiedProviderRoutes = () => {
  const navigate = useNavigate();
  const [
    providerTestOrders,
    providerTestOrdersLoading,
    providerTestOrdersError,
  ] = useProviderTestOrders();

  const hasSeenResources = localStorage.getItem("hasSeenResources");
  const hasExistingOrder = providerTestOrders.length > 0;

  const isOnHomePage = window.location.pathname === "/provider/";

  useEffect(() => {
    if (!providerTestOrdersLoading) {
      if (!hasSeenResources && isOnHomePage) {
        navigate("/provider/resources");
      } else if (hasExistingOrder && isOnHomePage) {
        navigate("/provider/tests");
      } else if (isOnHomePage) {
        navigate("/provider/order");
      }
    }
  }, [
    hasExistingOrder,
    hasSeenResources,
    isOnHomePage,
    providerTestOrdersLoading,
    navigate,
  ]);

  return (
    <Layout title="Provider Home" full bgClass="bg-evvy-cream">
      <div className="hidden md:block">
        {providerTestOrdersError && (
          <div className="bg-red-400 p-8 rounded-2xl mb-4 max-w-5xl mx-auto">
            {providerTestOrdersError}
          </div>
        )}
        {providerTestOrdersLoading ? <LoadingSpinner /> : <Outlet />}
      </div>
      <div className="md:hidden m-auto max-w-md p-8">
        <h1>Hey there small screen</h1>
        <p className="t1">
          Our provider portal is meant to be used on Desktop. Revisit us from
          your computer or tablet to get the full experience!
        </p>
      </div>
    </Layout>
  );
};
