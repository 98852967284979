const arraysEqual = (a, b) => {
  // doesn't work on arrays of objects

  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

const getIsMobile = () => {
  const isMobile = window && window.innerWidth < 600 ? true : false;
  return isMobile;
};

const getLoginNextPath = (nextPath, existingQueryParams) => {
  // if /treatments/login exists in next path
  if (nextPath.includes("/treatments/login")) {
    return `${nextPath}?${existingQueryParams}`;
  } else if (existingQueryParams) {
    var newPath = `/login/?next=${nextPath}&${existingQueryParams}`;
    // make sure to replace any && with & (just in case!)
    newPath = newPath.replace("&&", "&");
    // if trailing & exists, remove it
    if (newPath.endsWith("&")) {
      newPath = newPath.slice(0, -1);
    }
    return newPath;
  } else {
    return `/login/?next=${nextPath}`;
  }
};

export { arraysEqual, getIsMobile, getLoginNextPath };
