import { AxiosResponse } from "axios";
import { getRequest } from "../utils/axios";

export const getDynamicLink: (
  hash: string
) => Promise<AxiosResponse<any>> = async (hash) => {
  const response = await getRequest(
    `/api/v1/dynamic-link/?hash=${hash}`,
    (response: any) => {
      return response;
    },
    (error: any, response: any) => {
      throw error;
    }
  );
  return response;
};
