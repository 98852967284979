import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import Markdown from "markdown-to-jsx";

// services
import { testsService } from "../../../../services/tests";

// components
import { PrescriptionCircleIcon as Prescription } from "../../../../components/care/icons/prescriptionCircleIcon";
import { ReactComponent as FirstAidKit } from "../../../../components/care/icons/first-aid-circle.svg";
import { sendConsultIntakeViewSection } from "../../../../utils/analytics/customEventTracking";

// types
import { ConsultIntake } from "../../../../types/care";
import { Test } from "../../../../types/test";
import { AxiosError, AxiosResponse } from "axios";
import LoadingSpinner from "../../../../components/common/loadingSpinner";
import {
  PRIVACY_POLICY,
  TELEHEALTH_INFORMED_CONSENT,
  TERMS_OF_SERVICE,
} from "../../../constants";

const DISCLAIMER_COPY_MD =
  "*If you are experiencing any ulcers, wounds that won't heal, or external (dermatological) symptoms, please seek in-person care.*";

const TITLE = "How it works";

const IntakeIntro = ({
  consultIntake,
  submitPage,
  loading,
}: {
  consultIntake: ConsultIntake;
  submitPage: () => void;
  loading: boolean;
}) => {
  const [error, setError] = useState<string>("");
  const [checked, setChecked] = useState(
    Boolean(consultIntake?.terms_agreed_at)
  );
  const testHash = consultIntake?.consult?.test_hash;
  const [test, setTest] = useState<Test>();
  const consult = consultIntake?.consult;

  /* Effects */
  // First render
  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consult.uid,
      testHash: consult.test_hash,
      section: "welcome",
      consultType: "sti",
    });
  }, [consult.test_hash, consult.uid]);

  useEffect(() => {
    const fetchTest = () => {
      testsService.fetchTest(
        testHash,
        (response: AxiosResponse) => {
          setTest(response.data);
        },
        (error: AxiosError) => {
          console.error(error);
          setError("Error fetching test data");
        }
      );
    };
    if (testHash) {
      fetchTest();
    }
  }, [testHash]);

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Helmet>
        <title>Care | Consult | STI Intake | Intro</title>
      </Helmet>
      {!test ? (
        <LoadingSpinner />
      ) : (
        <div className="max-w-xl px-4">
          <div className="flex-1 text-center sm:px-10">
            {/* <div className="uppercase font-semibold tracking-wider text-sm">Hi, {currentUser.identity.first_name || "there"}</div> */}
            <h3 className="mt-2 mx-4 sm:mx-12 sm:px-4">STI Treatment</h3>
          </div>

          <div className="flex-1 text-center bg-evvy-white p-5 sm:px-8 sm:py-10 rounded-lg">
            <div className="uppercase font-semibold tracking-wider text-sm mt-5 sm:mt-0 mb-7 sm:mb-8">
              {TITLE}
            </div>
            <div className="sm:pt-1 text-left b1">
              <div className="flex mb-8">
                <div className="flex-shrink-0 h-14 w-14 mr-6">
                  <FirstAidKit className="h-full w-full" />
                </div>
                <div className="">
                  For a $29 rush consultation fee, an Evvy-affiliated provider
                  will review your results and if appropriate, send a
                  prescription to your chosen pharmacy within 1 business day.
                </div>
              </div>
              <div className="flex mb-8">
                <div className="flex-shrink-0 h-14 w-14 mr-6">
                  <Prescription className="h-full w-full" />
                </div>
                <div className="">
                  Your chosen pharmacy will contact you to pick up your
                  prescription once it is ready.
                </div>
              </div>
            </div>
            <div className="border-t border-evvy-black-dull pt-5 mt-7 sm:mt-8 text-left">
              <div className="b3">
                <Markdown>{DISCLAIMER_COPY_MD}</Markdown>
              </div>
            </div>

            <div className="flex mt-6 sm:mt-8 mb-4">
              <input
                checked={checked}
                onChange={() => setChecked(!checked)}
                id="terms"
                name="terms"
                type="checkbox"
                required
                className="mt-0.5 bg-evvy-cream rounded-sm border-evvy-blue cursor-pointer focus:outline-none focus:ring-evvy-blue active:ring-evvy-blue checked:bg-evvy-blue"
              />
              <label
                htmlFor="terms"
                className="block font-medium ml-2 text-sm text-left"
              >
                I agree to the{" "}
                <a
                  href={PRIVACY_POLICY}
                  className="underline uppercase text-[12px] font-semibold tracking-wider"
                  target="_blank"
                  rel="noreferrer"
                >
                  privacy policy
                </a>
                ,{" "}
                <a
                  href={TERMS_OF_SERVICE}
                  className="underline uppercase text-[12px] font-semibold tracking-wider"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms of service
                </a>{" "}
                and{" "}
                <a
                  href={TELEHEALTH_INFORMED_CONSENT}
                  className="underline uppercase text-[12px] font-semibold tracking-wider"
                  target="_blank"
                  rel="noreferrer"
                >
                  telehealth informed consent
                </a>
                .
              </label>
            </div>
          </div>

          <div className="flex cursor-pointer mt-4 mb-4 sm:mb-6 items-center">
            <button
              disabled={loading || !checked}
              onClick={submitPage}
              className="tracking-wider w-full sm:w-2/3 m-3 sm:mx-auto py-6 px-14 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none"
            >
              Let's get started →
            </button>
          </div>

          {error && (
            <div className="text-red-500 text-center mb-4">{error}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default IntakeIntro;
