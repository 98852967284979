import React from "react";

const UpdateReminderMsg = ({}) => {
  return (
    <div className="bg-coral p-2 px-4 rounded-md my-6">
      Some answers have been prefilled based on what you’ve already told us.
      Please review and confirm these answers still apply.
    </div>
  );
};

export default UpdateReminderMsg;
