import React, { useState } from "react";
import { careService } from "../../../services/care";

const EditCalendarStartDateTemplate = ({ handleClose, data }) => {
  const { consult, refetch } = data || {};
  const [savingStartDate, setSavingStartDate] = useState(false);
  // submit form to set the start date
  const setStartDate = async (e) => {
    e.preventDefault(); // to not post to the current url
    const newStartDate = e.target.elements["treatmentstartdate"].value;
    if (!newStartDate) {
      // other validation? can't be too far in past/future?
      alert("Must select start date");
      return;
    }
    setSavingStartDate(true);
    const data = {
      treatment_start_date: newStartDate,
    };
    try {
      const response = await careService.setTreatmentPlanStartDate(
        consult.uid,
        data
      );

      if (response.status === 200) {
        refetch();
        handleClose();
      } else {
        alert("Error setting start date");
      }
    } catch (error) {
      alert("Error setting start date");
    } finally {
      setSavingStartDate(false);
    }
  };

  return (
    <div className={`w-full flex flex-col py-7 px-8 bg-evvy-white text-center`}>
      {/* title */}
      <h3 className="text-2xl">
        Select the date you are planning to start treatment
      </h3>
      {/* description */}
      <p className="">
        We use this date to create your custom treatment calendar, which you can
        download as a PDF or directly to your calendar.
      </p>
      <form action="#" onSubmit={setStartDate}>
        <input
          id="treatmentstartdate"
          name="treatmentstartdate"
          type="date"
          autoComplete="off"
          required
          style={{ fontSize: "16px" }}
          className="mb-4 appearance-none block w-full p-3 border rounded-md border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
        />
        <button
          className="bg-evvy-blue py-4 px-8 w-full rounded-sm font-semibold tracking-wider focus:outline-none"
          type="submit"
          // onClick={handleClose}
          disabled={savingStartDate}
        >
          {savingStartDate ? "Saving..." : "Save"}
        </button>
      </form>
    </div>
  );
};
export default EditCalendarStartDateTemplate;
