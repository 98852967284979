import React, { useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

const AddressSearchInput = ({
  setFormFieldValue,
  getInitialValues,
  className,
  required = false,
}) => {
  const [currAddress, setCurrAddress] = useState("");

  useEffect(() => {
    const initialValues = getInitialValues();
    setCurrAddress(initialValues?.address_first_line || "");
  }, []);

  // Actions
  const populateFormFields = (selectedAddress, geocodeResults) => {
    if (!geocodeResults?.length) {
      // nothing to populate!
      return;
    }

    const results = geocodeResults[0];
    const addressComponents = results?.address_components;
    if (!addressComponents?.length) {
      // nothing to populate again
      return;
    }

    var address_first_line = "";
    var address_street_number = "";
    var address_street_name = "";
    var address_second_line = "";
    var city = "";
    var zip_code = "";
    var state_code = "";

    addressComponents.forEach((component) => {
      const types = component?.types || [];
      if (types.includes("street_number")) {
        address_street_number = component?.long_name || component?.short_name;
      } else if (types.includes("route")) {
        address_street_name = component?.long_name || component?.short_name;
      } else if (types.includes("subpremise")) {
        address_second_line = component?.long_name || component?.short_name;
      } else if (types.includes("locality")) {
        city = component?.long_name || component?.short_name;
      } else if (types.includes("sublocality") && !city) {
        city = component?.long_name || component?.short_name;
      } else if (types.includes("administrative_area_level_1")) {
        state_code = component?.short_name;
      } else if (types.includes("postal_code")) {
        zip_code = component?.long_name || component?.short_name;
      }
    });

    if (address_street_number && address_street_name) {
      address_first_line = address_street_number + " " + address_street_name;
    } else if (selectedAddress && selectedAddress.includes(",")) {
      address_first_line = selectedAddress.split(",")[0];
    }

    if (address_first_line) {
      setFormFieldValue("address_first_line", address_first_line);
      setCurrAddress(address_first_line);
    }
    if (address_second_line) {
      setFormFieldValue("address_second_line", address_second_line);
    }
    if (city) {
      setFormFieldValue("city", city);
    }
    if (zip_code) {
      setFormFieldValue("zip_code", zip_code);
    }
    if (state_code) {
      setFormFieldValue("state_code", state_code);
    }
  };

  const handleSelect = (selectedAddress, placeId, suggestion) => {
    geocodeByAddress(selectedAddress).then((results) =>
      populateFormFields(selectedAddress, results)
    );
  };

  const handleChange = (newAddress) => {
    setCurrAddress(newAddress);
    setFormFieldValue("address_first_line", newAddress);
  };

  return (
    <PlacesAutocomplete
      value={currAddress}
      onChange={handleChange}
      onSelect={handleSelect}
      debounce={300} // delay 300ms before making call to google maps api
      shouldFetchSuggestions={currAddress?.length > 5}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: "Start typing address",
              className: className
                ? className
                : "location-search-input w-full border border-gray-300 focus:outline-none focus:ring-transparent p-3",
            })}
            required={required}
          />
          <div className="autocomplete-dropdown-container">
            {suggestions.map((suggestion) => {
              const classStyle = `w-full p-3 border-x border-gray-300 cursor-pointer ${
                suggestion.active ? "bg-evvy-cream" : ""
              }`;
              return (
                <div
                  key={suggestion.description}
                  {...getSuggestionItemProps(suggestion, {
                    className: classStyle,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
            {/* google attribution logo: Powered by Google */}
            {suggestions.length ? (
              <div className="w-full p-3 border-x border-gray-300 bg-evvy-cream">
                <img
                  className="h-5"
                  src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png"
                  loading="lazy"
                  alt="Powered by Google"
                ></img>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};
export default AddressSearchInput;
