import React from "react";

// components
import DropDownMenu from "./dropDown";

// lib
import { dataTypes } from "../../pages/compare/constants";

const Selection = ({
  w,
  h,
  config,
  base,
  setBase,
  setBaseLoading,
  compare,
  setCompare,
  setCompareLoading,
  emptyCopy,
  setEmptyCopy,
}) => {
  // allows the dropdown to render before options are available, preventing layout shift
  const getValueFromBase = (key) => {
    return base.selectedDatatype ? config[base.selectedDatatype][key] : "";
  };
  const getValueFromCompare = (key) => {
    return compare.selectedDatatype
      ? config[compare.selectedDatatype]?.[key]
      : "";
  };

  return (
    <div className="mx-auto py-0 pb-4 text-center flex items-center bg-evvy-cream relative z-10 md:z-0">
      <div className="md:w-max mx-auto grid md:flex items-center justify-items-center space-x-6">
        {/* <div className="md:flex items-center"> */}
        <div className="t4 mb-4 md:mb-0">compare my test from</div>
        {/* <div className="mb-4 md:mb-0 md:mx-4"> */}
        {/* <DropDownMenu
              options={Object.keys(optionsDictionary)}
              labelAcc={(d) => optionsDictionary[d]}
              onSubmit={(option) => {
                setBase((prevBase) => ({
                  ...prevBase,
                  selectedDatatype: option,
                  selectedOption: 'default',
                  selectedQueryParams: { hash: null, compare: null }
                }))
                setEmptyCopy(E.DEFAULT)
              }}
            /> */}
        {/* </div> */}
        <div className="mb-4 md:mb-0">
          <DropDownMenu
            // disabled={compare.selectedDatatype === 'default'}
            disabled={getValueFromCompare("options").length <= 1}
            options={getValueFromCompare("options")}
            valAcc={getValueFromCompare("valAcc")}
            labelAcc={getValueFromCompare("labelAcc")}
            onSubmit={(d) => {
              setCompareLoading(true);
              setCompare((prevCompare) => ({
                ...prevCompare,
                selectedOption: d,
                selectedQueryParams: {
                  hash: config[compare.selectedDatatype].valAcc(d),
                  compare_type: config[compare.selectedDatatype].compare_type,
                },
                testId: d.pretty_hash,
              }));
            }}
            hasPlaceholder
            currentSelection={
              compare.selectedOption === dataTypes.DEFAULT
                ? "DEFAULT"
                : undefined
            }
          />
        </div>
        {/* </div> */}
        {/* <div className="md:flex items-center"> */}
        <div className="t4 mb-4 md:mb-0">to my test from</div>
        {/* <div className="mb-4 md:mb-0"> */}
        {/* <DropDownMenu
              options={Object.keys(optionsDictionary)}
              labelAcc={(d) => optionsDictionary[d]}
              onSubmit={(option) => {
                setCompare((prevCompare) => ({
                  ...prevCompare,
                  selectedDatatype: option,
                  selectedOption: 'default',
                  selectedQueryParams: { hash: null, compare: null }
                }))
                setEmptyCopy(E.DEFAULT)
              }}
              hasPlaceholder
              currentSelection={compare.selectedDatatype === dataTypes.DEFAULT ? "DEFAULT" : undefined}
            /> */}
        {/* </div> */}
        <div className="mb-4 md:mb-0">
          <DropDownMenu
            disabled={!base.selectedDatatype}
            options={getValueFromBase("options")}
            valAcc={getValueFromBase("valAcc")}
            labelAcc={getValueFromBase("labelAcc")}
            onSubmit={(d) => {
              setBaseLoading(true);
              setBase((prevBase) => ({
                ...prevBase,
                selectedOption: d,
                selectedQueryParams: {
                  hash: config[base.selectedDatatype].valAcc(d),
                  compare_type: config[base.selectedDatatype].compare_type,
                },
                testId: d.pretty_hash,
              }));
            }}
            hasPlaceholder
            currentSelection={
              base.selectedOption === dataTypes.DEFAULT
                ? "DEFAULT"
                : base.selectedOption?.hash
            }
          />
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Selection;
