/**
 * A styled icon, showing a percent change
 * @param {number} percentage a number indicating the percent difference between two tests
 * @param {string} trendIndicator a string for trend direction (up, down, neutral)
 * @param {component} svg Svg component based on the direction of the percent change
 * @returns jsx of a status bar, with svg and text
 */

import React from "react";

const TrendsPill = ({ percentage, trendIndicator, svg }) => {
  return (
    <div
      className={`flex flex-row w-24 h-8 py-2 px-2 mx-4 rounded-2xl justify-around trend-${trendIndicator}-bg`}
    >
      {svg}
      <span className={`font-semibold trend-${trendIndicator}`}>
        {percentage && percentage}%
      </span>
    </div>
  );
};

export default TrendsPill;
