import React from "react";
// components
import LinkedText from "../common/linkedText";
import { askEvvyLink } from "../../pages/constants";
// lib
import {
  sendCareClickedBlogArticle,
  eventNames,
} from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";

const AskEvvy = ({ analyticsEventArgs }) => {
  const TITLE_1 = "BV vs. yeast infections: what's the difference?";
  const TITLE_2 = "7 reasons why your vaginal infection won't go away";
  const TITLE_3 =
    "Leading OB/GYNs: Why Evvy's At-Home Vaginal Microbiome Test is Revolutionary";
  const TITLE_4 = "How does sex influence vaginal pH?";
  const TITLE_5 = "What is a yeast infection?";

  // actions
  const openAskEvvyArticle = (article) => {
    window.open(article, "_blank");
    sendCareClickedBlogArticle({ ...analyticsEventArgs, articleUrl: article });
  };

  return (
    <div className="justify-center items-center max-w-5xl sm:mt-6 mx-auto">
      <div className="flex mb-3 sm:mb-8 text-center sm:mx-40">
        <h2 className="hidden sm:block mx-auto">
          Get your questions answered on the #AskEvvy blog
        </h2>
        <h3 className="block sm:hidden mx-auto">
          Get your questions answered on the #AskEvvy blog
        </h3>
      </div>

      {/* desktop version */}
      <div className="hidden sm:block">
        <div className="flex items-center mx-auto justify-center space-x-4 mb-4">
          <div
            onClick={() =>
              openAskEvvyArticle(
                "https://www.evvy.com/blog/bv-vs-yeast-whats-the-difference"
              )
            }
            className="h-72 w-full flex items-center basis-1/2 text-evvy-white text-center t2 medium rounded-3xl bg-cover cursor-pointer"
            style={{
              backgroundImage: `url('/images/graphics/microbial_colors.png')`,
            }}
          >
            <div className="mx-auto px-28">{TITLE_1}</div>
          </div>
          <div
            onClick={() =>
              openAskEvvyArticle(
                "https://www.evvy.com/blog/7-reasons-why-your-vaginal-infection-wont-go-away"
              )
            }
            className="h-72 w-full flex items-center basis-1/2 text-evvy-white text-center t2 medium rounded-3xl bg-cover cursor-pointer"
            style={{ backgroundImage: `url('/images/graphics/pills.png')` }}
          >
            <div className="mx-auto px-28">{TITLE_2}</div>
          </div>
        </div>
        <div className="flex items-center mx-auto justify-center space-x-4 mb-4">
          <div
            onClick={() =>
              openAskEvvyArticle(
                "https://www.evvy.com/blog/obgyn-evvy-vaginal-microbiome-test-review"
              )
            }
            className="h-72 w-full flex items-center basis-1/3 text-evvy-white text-center t2 medium rounded-3xl bg-cover drop-shadow-lg cursor-pointer"
            style={{ backgroundImage: `url('/images/graphics/hands.png')` }}
          >
            <div className="mx-auto px-10">{TITLE_3}</div>
          </div>
          <div
            onClick={() =>
              openAskEvvyArticle(
                "https://www.evvy.com/blog/sex-vaginal-ph-microbiome"
              )
            }
            className="h-72 w-full flex items-center basis-1/3 text-evvy-white text-center t2 medium rounded-3xl bg-cover drop-shadow-lg cursor-pointer"
            style={{
              backgroundImage: `url('/images/graphics/back_square.png')`,
            }}
          >
            <div className="mx-auto px-10">{TITLE_4}</div>
          </div>
          <div
            onClick={() =>
              openAskEvvyArticle(
                "https://www.evvy.com/blog/what-is-a-yeast-infection"
              )
            }
            className="h-72 w-full flex items-center basis-1/3 text-evvy-white text-center t2 medium rounded-3xl bg-cover drop-shadow-lg cursor-pointer"
            style={{ backgroundImage: `url('/images/graphics/sheets.png')` }}
          >
            <div className="mx-auto px-10">{TITLE_5}</div>
          </div>
        </div>
      </div>

      {/* mobile version */}
      <div className="block sm:hidden">
        <div className="flex items-center mx-auto justify-center space-x-4 mb-4">
          <div
            onClick={() =>
              openAskEvvyArticle(
                "https://www.evvy.com/blog/bv-vs-yeast-whats-the-difference"
              )
            }
            className="h-48 w-full flex items-center text-evvy-white text-center b1 medium rounded-3xl bg-cover cursor-pointer"
            style={{
              backgroundImage: `url('/images/graphics/microbial_colors.png')`,
            }}
          >
            <div className="mx-auto px-32">{TITLE_1}</div>
          </div>
        </div>
        <div className="flex items-center mx-auto justify-center space-x-4 mb-4">
          <div
            onClick={() =>
              openAskEvvyArticle(
                "https://www.evvy.com/blog/7-reasons-why-your-vaginal-infection-wont-go-away"
              )
            }
            className="h-48 w-full flex items-center basis-1/2 text-evvy-white text-center b1 medium rounded-3xl bg-cover cursor-pointer"
            style={{ backgroundImage: `url('/images/graphics/pills.png')` }}
          >
            <div className="mx-auto px-5">{TITLE_2}</div>
          </div>
          <div
            onClick={() =>
              openAskEvvyArticle(
                "https://www.evvy.com/blog/obgyn-evvy-vaginal-microbiome-test-review"
              )
            }
            className="h-48 w-full flex items-center basis-1/2 text-evvy-white text-center b1 medium rounded-3xl bg-cover drop-shadow-lg cursor-pointer"
            style={{ backgroundImage: `url('/images/graphics/hands.png')` }}
          >
            <div className="mx-auto px-5">{TITLE_3}</div>
          </div>
        </div>
        <div className="flex items-center mx-auto justify-center space-x-4 mb-4">
          <div
            onClick={() =>
              openAskEvvyArticle(
                "https://www.evvy.com/blog/sex-vaginal-ph-microbiome"
              )
            }
            className="h-48 w-full flex items-center basis-1/2 text-evvy-white text-center b1 medium rounded-3xl bg-cover drop-shadow-lg cursor-pointer"
            style={{
              backgroundImage: `url('/images/graphics/back_square.png')`,
            }}
          >
            <div className="mx-auto px-10">{TITLE_4}</div>
          </div>
          <div
            onClick={() =>
              openAskEvvyArticle(
                "https://www.evvy.com/blog/what-is-a-yeast-infection"
              )
            }
            className="h-48 w-full flex items-center basis-1/2 text-evvy-white text-center b1 medium rounded-3xl bg-cover drop-shadow-lg cursor-pointer"
            style={{ backgroundImage: `url('/images/graphics/sheets.png')` }}
          >
            <div className="mx-auto px-10">{TITLE_5}</div>
          </div>
        </div>
      </div>

      <div className="text-center mt-10 sm:mb-4">
        <LinkedText
          onClick={analyticsClickHandler({
            eventName: eventNames.CARE_CLICKED_SEE_ALL_ARTICLES,
            eventArgs: analyticsEventArgs,
          })}
          isLink
          href={askEvvyLink}
          noIcon
          noTopMargin
        >
          See all articles
        </LinkedText>
      </div>
    </div>
  );
};

export default AskEvvy;
