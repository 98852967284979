import React from "react";

const CompareInfoModal = ({ handleClose }) => {
  return (
    <div className="block md:flex">
      <div className="w-1/2" style={{ maxHeight: "400px" }}>
        <img
          src="/images/graphics/gradient.png"
          loading="lazy"
          alt="rainbow gradient"
          className="w-full"
        />
      </div>
      <div className=" w-1/2 bg-evvy-cream flex flex-col">
        <div className="p-8 lg:p-10 flex-1">
          <h2>Introducing: Compare</h2>
          <p>We get it — a lot can happen between two Evvy tests!</p>
          <p>
            We built Compare so you can better understand how your microbiome is
            changing over time, and how your symptoms, treatments, behaviors,
            menstrual cycle, and more are related to those changes.
          </p>
          <p>
            Just use the dropdowns at the top to choose two tests to compare!
          </p>
          <p className="italic">
            Pro tip: click into each colored bar to see exact microbial
            breakdowns.
          </p>
        </div>
        <button
          className="cursor-pointer w-full tracking-wider py-6 px-20 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
          onClick={handleClose}
        >
          Let's Go →
        </button>
      </div>
    </div>
  );
};

export default React.memo(CompareInfoModal);
