import { useState } from "react";
import Tippy from "@tippyjs/react";
import classNames from "classnames";

// types
import {
  ProblemsSummary,
  TestScores,
  TestTrendSummary,
} from "../../types/test";
import { BacteriaType, BacteriaTypeLabel } from "../../types/results";

// utils
import { getDateDisplayFromDateStr } from "../../utils/datetime";
import { bgClasses, typeDictionary } from "../../utils/viz";

// icons
import { ReactComponent as Info } from "../plan_unfurled/icons/info.svg";
import { ReactComponent as AlertTriangle } from "../common/icons/alert-triangle.svg";

// components
import { MoreLessButton } from "../common/moreLessButton";
import { EvvyMarkdown } from "../common/evvyMarkdown";
import { handleSTIConsultCTAClick } from "../care/utils";
import { cn } from "../../utils/cn";

const MICROBIOME_STATE_GOAL_COPY = {
  disrupted: "",
  "sti-positive": "",
  healthy: "Your goal is to maintain this microbiome.",
  suboptimal: "Your goal is to build a healthy vaginal microbiome.",
  healthySymptoms: "Your goal is to relieve your symptoms.",
};

const DisruptedMicrobiomeSummary = ({
  problemsSummary,
}: {
  problemsSummary: ProblemsSummary;
}) => {
  return (
    <div className="b1">
      <span className="mb-2 items-center">
        Your results and symptoms can be associated with
      </span>
      <div className="flex flex-wrap">
        {problemsSummary.conditions.map((condition, i) => (
          <ProblemsTagPill
            key={`condition-${i}`}
            tagText={condition}
            className="bg-coral mb-2 mr-1.5"
          />
        ))}
      </div>
    </div>
  );
};

const STIPositiveMicrobiomeSummary = ({
  problemsSummary,
}: {
  problemsSummary: ProblemsSummary;
}) => {
  return (
    <div className="space-y-2">
      <div className="t4 flex items-center">
        <AlertTriangle className="h-5 w-5 stroke-evvy-black mr-1" />
        STI Detected
      </div>
      <div className="flex flex-wrap">
        {problemsSummary.stis.map((sti, i) => (
          <ProblemsTagPill
            key={`sti-${i}`}
            tagText={sti}
            className="bg-red-400/75 mb-2 mr-1.5"
          />
        ))}
      </div>
    </div>
  );
};

const NonDisruptedMicrobiomeSummary = ({
  problemsSummary,
}: {
  problemsSummary: ProblemsSummary;
}) => {
  const microbiomeStateColor =
    problemsSummary.microbiome_state === "suboptimal"
      ? "bg-coral"
      : "bg-dv-pond/50";

  const goalCopyKey =
    problemsSummary.microbiome_state === "healthy" &&
    problemsSummary.symptoms.length > 0
      ? "healthySymptoms"
      : problemsSummary.microbiome_state;

  return (
    <div className="text-base sm:text-lg space-y-1.5">
      <div className="flex flex-wrap items-center">
        Your vaginal microbiome is{" "}
        <span className="ml-1.5">
          <ProblemsTagPill
            tagText={problemsSummary.microbiome_state}
            key={"1"}
            className={microbiomeStateColor}
          />
        </span>
      </div>
      <div>{MICROBIOME_STATE_GOAL_COPY[goalCopyKey]}</div>
    </div>
  );
};

export const MicrobiomeSummary = ({
  problemsSummary,
}: {
  problemsSummary: ProblemsSummary;
}) => {
  return (
    <div>
      {problemsSummary.microbiome_state === "disrupted" ? (
        <DisruptedMicrobiomeSummary problemsSummary={problemsSummary} />
      ) : problemsSummary.microbiome_state === "sti-positive" ? (
        <STIPositiveMicrobiomeSummary problemsSummary={problemsSummary} />
      ) : (
        <NonDisruptedMicrobiomeSummary problemsSummary={problemsSummary} />
      )}
    </div>
  );
};

const ExpandedInfo = ({
  testSummary,
  expanded,
}: {
  testSummary: TestTrendSummary;
  expanded: boolean;
}) => {
  const problemsSummary = testSummary.problems_summary;
  const moreInfo = problemsSummary.more_info;
  const citations = problemsSummary.citations;
  const noSymptoms = problemsSummary.symptoms.length === 0;
  const isSTIState = problemsSummary.microbiome_state === "sti-positive";

  if (!expanded || moreInfo.length === 0) return null;

  return (
    <div className="space-y-4">
      {/* Relevant context */}
      {testSummary && testSummary.problems_summary.context.length > 0 && (
        <ResultsTags section={"context"} testSummary={testSummary} />
      )}
      {noSymptoms && (
        <div className="b2 regular flex items-center pt-2.5 border-evvy-black border-opacity-30 border-t border-dashed">
          No reported symptoms
          <ProblemsModuleTooltip
            section={"symptoms"}
            includesClinicalIntake={
              problemsSummary.uses_clinical_intake || false
            }
            eligibleForCare={testSummary.care_eligibility.v0_bundle}
          />
        </div>
      )}
      {moreInfo.map((info, i) => (
        <div
          key={`info-${i}`}
          className="b2 regular pt-4 border-evvy-black border-opacity-30 border-t border-dashed"
        >
          <EvvyMarkdown>{info}</EvvyMarkdown>
          {/* if sti state and last item */}
          {isSTIState && i === moreInfo.length - 1 && (
            <>
              {" Get treated for STIs through your healthcare provider"}
              {testSummary.care_eligibility.can_open_sti_treatment_consult && (
                <>
                  {" or "}
                  <span
                    className="underline underline-offset-2 cursor-pointer"
                    onClick={() => {
                      handleSTIConsultCTAClick(
                        testSummary.hash,
                        {
                          location: "problems",
                          testHash: testSummary.hash,
                          ctaText: "Evvy",
                        },
                        testSummary.latest_sti_consult
                      );
                    }}
                  >
                    Evvy.
                  </span>
                </>
              )}
            </>
          )}
        </div>
      ))}
      {citations.length > 0 && (
        <div className="text-sm">
          <div className="semibold">Our Sources:</div>
          <ol className="list-decimal space-y-1">
            {citations.map((citation, i) => (
              <li className="my-0" key={`citation-${i}`}>
                <a
                  href={citation.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm underline"
                >
                  {citation.title}
                </a>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
};

const ResultsTags = ({
  section,
  testSummary,
}: {
  section: "context" | "symptoms";
  testSummary: TestTrendSummary;
}) => {
  const problemsSummary = testSummary.problems_summary;
  const tags = problemsSummary[section] || [];
  const title = section === "context" ? "Relevant Context" : "Your Symptoms";

  if (!tags.length) {
    return null;
  }

  return (
    <div className="space-y-3">
      <div className="t4 flex items-center">
        {title}
        <ProblemsModuleTooltip
          section={section}
          includesClinicalIntake={
            problemsSummary?.uses_clinical_intake || false
          }
          eligibleForCare={testSummary.care_eligibility.v0_bundle}
        />
      </div>
      <div className="flex flex-wrap">
        {tags.map((tag, i) => (
          <ProblemsTagPill
            key={`${section}-${i}`}
            tagText={tag}
            className="bg-evvy-dark-beige mb-2 mr-1.5"
          />
        ))}
      </div>
    </div>
  );
};

const ResultsVisualization = ({ testScores }: { testScores: TestScores }) => {
  return (
    <div className="space-y-4">
      <div className="t4">Your Results</div>
      <div className="h-2.5 bg-evvy-cream rounded-sm overflow-hidden w-full flex">
        {/* stacked progress bar that adds to 100 for each of the bacteria types */}
        {Object.entries(typeDictionary).map(([code, { label }]) => (
          <div
            key={code}
            className={`h-full ${bgClasses[code as BacteriaType].fill}`}
            style={{
              width: `${testScores[label as BacteriaTypeLabel].value}%`,
            }}
          ></div>
        ))}
      </div>
      {/* legend */}
      <div className="flex flex-wrap sm:flex-nowrap">
        {Object.entries(typeDictionary)
          .filter(([code, value]) => code !== "UN")
          .map(([code, { label }]) => (
            <div
              className="flex items-center mr-2 sm:mr-4 last:mr-0 mb-2 sm:mb-0"
              key={`viz-legend-${code}`}
            >
              <div
                className={`h-4 w-4 rounded-full ${
                  bgClasses[code as BacteriaType].fill
                } flex-shrink-0 mr-2`}
                aria-hidden={true}
              />
              <div className={`b3 capitalize medium`}>{`${
                testScores[label as BacteriaTypeLabel].value
              }% ${label}`}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

const TOOLTIP_COPY = {
  symptoms: {
    includesClinicalIntake:
      "These are symptoms you shared in your health history form when you activated your test or in your intake form when you purchased treatment.",
    careEligible:
      "These are symptoms you shared in your health history form when you activated your test. If these have changed, you'll be able to update them in an intake form before a provider reviews.",
    careIneligible:
      "These are symptoms you shared in your health history form when you activated your test.",
  },
  context: {
    includesClinicalIntake:
      "This is additional health information you shared in your health history form or intake form that may play a role in your vaginal microbiome (and vice versa!).",
    careEligible:
      "This is additional health information you shared in your health history form that may play a role in your vaginal microbiome (and vice versa!). If any of this has changed, you'll be able to update your information in an intake form before a provider reviews.",
    careIneligible:
      "This is additional health information you shared in your health history form that may play a role in your vaginal microbiome (and vice versa!).",
  },
};

const ProblemsModuleTooltip = ({
  section,
  includesClinicalIntake,
  eligibleForCare,
}: {
  section: "context" | "symptoms";
  includesClinicalIntake: boolean;
  eligibleForCare?: boolean;
}) => (
  <Tippy
    arrow
    render={() => (
      <div className="flex bg-evvy-pine p-3 rounded-md max-w-sm" tabIndex={-1}>
        <div className="text-evvy-white">
          {
            TOOLTIP_COPY[section][
              includesClinicalIntake
                ? "includesClinicalIntake"
                : eligibleForCare
                ? "careEligible"
                : "careIneligible"
            ]
          }
        </div>
      </div>
    )}
    placement="bottom"
    animation={false}
  >
    <Info
      stroke="currentColor"
      fill="currentColor"
      className="ml-1.5 w-4 h-4 text-evvy-black-dull/75"
    />
  </Tippy>
);

export const ProblemsTagPill = ({
  tagText,
  key,
  className = "bg-evvy-dark-beige",
}: {
  tagText: string;
  key: string;
  className: string;
}) => {
  return (
    <div
      key={key}
      className={classNames(
        "rounded-full py-1 px-2.5 text-sm sm:text-base medium text-center",
        className
      )}
    >
      {tagText}
    </div>
  );
};

const ProblemsModule = ({
  testSummary,
  modalVersion = false,
  showBackgroundContainer = false,
  openModal = () => {},
}: {
  testSummary: TestTrendSummary;
  modalVersion?: boolean;
  showBackgroundContainer?: boolean;
  openModal?: () => void;
}) => {
  const [expanded] = useState(modalVersion);
  const problemsSummary = testSummary?.problems_summary;
  const testDateDisplay = testSummary?.healthcontext.sample_taken_at
    ? getDateDisplayFromDateStr(testSummary?.healthcontext.sample_taken_at)
    : "";

  return (
    <div
      className={cn({
        "bg-white p-2.5 sm:p-5 space-y-5 rounded-xl": showBackgroundContainer,
      })}
    >
      <div className="bg-evvy-cream rounded-xl p-4 sm:p-5 space-y-4">
        <div>
          <div className="t4">Your microbiome summary</div>
          {testDateDisplay && (
            <div className="b3 medium text-evvy-black-dull">{`Based on your sample from ${testDateDisplay}`}</div>
          )}
        </div>
        {/* Your results */}
        {testSummary?.scores && (
          <ResultsVisualization testScores={testSummary.scores} />
        )}

        {problemsSummary.microbiome_state === "sti-positive" && (
          <STIPositiveMicrobiomeSummary problemsSummary={problemsSummary} />
        )}

        {/* <MicrobiomeSummary problemsSummary={problemsSummary} /> */}

        {/* Symptoms */}
        {testSummary && (
          <ResultsTags section={"symptoms"} testSummary={testSummary} />
        )}

        {!expanded && (
          <MoreLessButton
            ctaText="See full summary"
            onClick={openModal}
            expanded={false}
            useCaret={false}
          />
        )}

        {expanded && (
          <ExpandedInfo
            testSummary={testSummary}
            expanded={modalVersion || expanded}
          />
        )}
      </div>
    </div>
  );
};

export default ProblemsModule;
