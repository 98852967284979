import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Markdown from "markdown-to-jsx";
import { ReactComponent as Heart } from "../../../../components/care/icons/heart.svg";
import { ReactComponent as FirstAidKit } from "../../../../components/care/icons/first-aid-kit.svg";
import { ReactComponent as Pills } from "../../../../components/care/icons/pills.svg";
import { sendConsultIntakeViewSection } from "../../../../utils/analytics/customEventTracking";
import { facebookSupportGroupLink } from "../../../constants";
import { ConsultIntake } from "../../../../types/care";

const IntakeDone = ({ consultIntake }: { consultIntake: ConsultIntake }) => {
  /* Effects */
  // First render
  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: consultIntake?.consult?.test_hash,
      section: "thank you",
      consultType: "sti",
    });
  }, [consultIntake.consult?.test_hash, consultIntake.consult.uid]);

  return (
    <div className="block w-full mt-8 sm:mt-10 mb-4">
      <Helmet>
        <title>Care | Consult | STI Intake | Done</title>
      </Helmet>
      <div className="max-w-2xl px-4">
        <h3 className="flex-1 mr-2 text-center">You're done!</h3>
        <div className="flex-1 pt-8 pb-4 px-6 sm:px-8 bg-evvy-white rounded-t-lg">
          <div className="uppercase mb-5 inline-block font-semibold tracking-wider text-sm">
            What's next
          </div>

          <div className="flex">
            <div className="flex-shrink-0 h-10 w-10">
              <FirstAidKit />
            </div>
            <p>
              Your info has been submitted and will be reviewed by a provider.
            </p>
          </div>
          <div className="flex">
            <div className="flex-shrink-0 h-10 w-10">
              <Pills className="mt-1" />
            </div>
            <p>
              <Markdown>
                If your provider believes you're eligible for treatment, you'll
                get an email with your prescription information and where to
                pick it up. You should get this email **within 1 business day**.
              </Markdown>
            </p>
          </div>
          <div className="flex">
            <div className="flex-shrink-0 h-10 w-10">
              <Heart className="" />
            </div>
            <p>
              In the meantime,{" "}
              <a
                href={facebookSupportGroupLink}
                target="_blank"
                className="!underline persistSize"
                rel="noreferrer"
              >
                join the Evvy Support Community here
              </a>
              .
            </p>
          </div>
        </div>

        <div>
          <img
            className="w-full"
            src="/images/graphics/triple-science-blue.png"
            loading="lazy"
            alt="Evvy Microbiome Test"
          />
        </div>

        <Link to={`/tests/`} className="flex pt-3 items-center mt-3">
          <button className="tracking-wider w-full sm:w-auto sm:mx-auto py-7 px-16 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none">
            Return to home →
          </button>
        </Link>
      </div>
    </div>
  );
};
export default IntakeDone;
