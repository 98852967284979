import { useNavigate } from "react-router-dom";
import { Prescription } from "../../../types/care";
import { cn } from "../../../utils/cn";
import { transparentCarePhaseColorMap } from "../../../utils/colors";
import { FORM_FACTOR_TO_IMAGE_V2 } from "../constants";
import TransparentCarePhasePill from "./TransparentCarePhasePill";

const TreatmentBundleCard: React.FC<{
  treatment: Prescription;
  phase: string;
}> = ({ treatment, phase }) => {
  const navigate = useNavigate();
  const formFactor =
    treatment.form_factor as keyof typeof FORM_FACTOR_TO_IMAGE_V2;
  const image = FORM_FACTOR_TO_IMAGE_V2[formFactor]?.image?.bundle;

  return (
    <div
      className="bg-evvy-cream rounded-[10px] min-h-[165px] w-[290px] md:w-[305px] lg:w-[335px] py-6 px-4 relative flex flex-col justify-between cursor-pointer"
      key={treatment.slug}
      onClick={() => {
        navigate(`/care/shop/treatment/${treatment.slug}/`);
      }}
    >
      {/* believe it or not this is actually an intentional empty div and not meant to be a fragment because the parent needs to justify between the two divs */}
      <div>
        <TransparentCarePhasePill phase={phase} productConfig="bundle" />
        <div className="mt-[10px] t1 max-w-[70%]">
          {treatment.preview_title}
        </div>
        <div className="text-sm">
          {treatment.type === "antibiotic" || treatment.type === "antifungal"
            ? "Determined by provider"
            : treatment.quantity_text}
        </div>
      </div>
      {treatment.preview_short_description && (
        <div className="flex flex-row mt-8">
          <div
            className={cn(
              "rounded-full w-2 h-2 mr-1.5 mt-1.5",
              transparentCarePhaseColorMap[
                phase as keyof typeof transparentCarePhaseColorMap // hacky type assertion to appease TS
              ].full
            )}
          />
          <div className="text-sm text-evvy-black text-opacity-60 max-w-[70%]">
            {treatment.preview_short_description}
          </div>
        </div>
      )}
      {image && (
        <div className="absolute inset-y-0 right-0 flex items-center">
          <img
            className={cn(
              "mx-auto drop-shadow-md",
              FORM_FACTOR_TO_IMAGE_V2[formFactor]?.sizeClassName?.bundle
            )}
            loading="lazy"
            src={image}
            alt={treatment.form_factor}
          />
        </div>
      )}
    </div>
  );
};

export default TreatmentBundleCard;
