import { CalendarTreatment, Consult, TreatmentPlan } from "../../../types/care";
import { User } from "../../../types/user";
import { eventNames } from "../../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../../utils/analytics/helpers";
import { getWeekCTA } from "../constants";
import TreatmentCalendarCTA from "../treatmentCalendarCTA";
import EditCalendarTreatment from "./EditCalendarTreatment";
import { TreatmentList } from "./treatmentList";
import { YourLogs } from "./yourLogs";

interface TreatmentCalendarSidebarProps {
  editingTreatment: CalendarTreatment;
  setEditingTreatment: (treatment: CalendarTreatment | null) => void;
  setSavedTreatmentMessage: (message: string) => void;
  refetchTreatmentPlan: () => void;
  editingStartDate: boolean;
  setEditingStartDate: (editing: boolean) => void;
  startDate: string;
  setStartDate: (e: React.FormEvent<HTMLFormElement>) => void;
  startDateUpdated: string;
  setStartDateUpdated: (date: string) => void;
  savingStartDate: boolean;
  calendarHelpText: string;
  treatmentPlan: TreatmentPlan;
  isMenopausal: boolean;
  openPrescriptionModal: () => void;
  openEditModal: (consult: Consult, refetchTreatmentPlan: () => void) => void;
  openEditPeriodModal: () => void;
  openAddNewPeriodModal: () => void;
  openResetCalendarModal: () => void;
  calendarModified: boolean;
  isTreatmentPlan: boolean;
  currentWeek: number;
  weeks: number[];
  currentUser: User;
  retestLink: string;
  consult: Consult;
  baseAnalyticsArgs: { [key: string]: string };
}

const TreatmentCalendarSidebar: React.FC<TreatmentCalendarSidebarProps> = ({
  editingTreatment,
  setEditingTreatment,
  setSavedTreatmentMessage,
  refetchTreatmentPlan,
  editingStartDate,
  setEditingStartDate,
  startDate,
  setStartDate,
  startDateUpdated,
  setStartDateUpdated,
  savingStartDate,
  calendarHelpText,
  treatmentPlan,
  isMenopausal,
  openPrescriptionModal,
  openEditModal,
  openEditPeriodModal,
  openAddNewPeriodModal,
  openResetCalendarModal,
  calendarModified,
  isTreatmentPlan,
  currentWeek,
  weeks,
  currentUser,
  retestLink,
  consult,
  baseAnalyticsArgs,
}) => {
  return (
    <div className="p-8 sm:w-1/3 sm:max-w-1/3 flex-shrink-0 sm:border-r flex flex-col">
      {editingTreatment && (
        <EditCalendarTreatment
          treatment={editingTreatment}
          closeEditTreatment={() => setEditingTreatment(null)}
          setSavedTreatmentMessage={setSavedTreatmentMessage}
          refetchCalendar={refetchTreatmentPlan}
        />
      )}
      {!editingTreatment && (
        <>
          {/* display for start date (set/unset/editing) */}
          {editingStartDate && (
            <form action="#" onSubmit={setStartDate}>
              <div className="medium mb-4">{calendarHelpText}</div>

              <input
                id="startdate"
                name="startdate"
                type="date"
                autoComplete="off"
                required
                style={{ fontSize: "16px" }}
                value={startDateUpdated || ""}
                onChange={(e) => setStartDateUpdated(e.target.value)}
                className="mb-4 appearance-none block w-full p-3 border rounded-md border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
              />

              {/* mini-calendar goes here? */}

              <button
                className="bg-evvy-blue py-4 px-8 w-full rounded-sm font-semibold tracking-wider focus:outline-none"
                type="submit"
                onClick={analyticsClickHandler({
                  eventName: eventNames.TREATMENT_SELECT_START_DATE,
                  eventArgs: {
                    ...baseAnalyticsArgs,
                    treatmentStartDate: startDateUpdated,
                    oldTreatmentStartDate: startDate,
                  },
                })}
                disabled={savingStartDate}
              >
                {savingStartDate ? "Saving..." : "Save"}
              </button>
            </form>
          )}

          {!editingStartDate && startDate && (
            <>
              <div className="medium mb-4">Treatment Start Date</div>
              <div className="mb-4 flex w-full p-4 bg-evvy-cream rounded-md shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent">
                <div className="medium my-auto">{startDate}</div>
                <div
                  className="ml-auto cursor-pointer font-semibold uppercase tracking-wide text-sm/[13px]"
                  onClick={() => setEditingStartDate(true)}
                >
                  Edit
                </div>
              </div>

              {/* mini-calendar goes here? */}
            </>
          )}

          {!editingStartDate && !startDate && (
            <>
              <div className="medium mb-4">{calendarHelpText}</div>

              <button
                className="bg-evvy-blue my-4 py-4 px-8 w-full rounded-sm font-semibold tracking-wider focus:outline-none"
                onClick={() => setEditingStartDate(true)}
              >
                Select Start Date
              </button>
            </>
          )}

          {treatmentPlan && (
            <TreatmentList
              calendarTreatments={treatmentPlan?.calendar?.calendar_treatments}
              setEditingTreatment={setEditingTreatment}
              openPrescriptionModal={openPrescriptionModal}
            />
          )}

          {treatmentPlan && !editingStartDate && !isMenopausal && (
            <YourLogs
              onClickPopup={
                !startDate
                  ? () => openEditModal(consult, refetchTreatmentPlan)
                  : undefined
              }
              openEditPeriodModal={openEditPeriodModal}
              openAddPeriodModal={openAddNewPeriodModal}
              openResetCalendarModal={openResetCalendarModal}
              treatmentPlan={treatmentPlan}
              calendarModified={calendarModified}
            />
          )}

          {/* CTA for non-mobile view only */}
          {isTreatmentPlan &&
            getWeekCTA(currentWeek, weeks.length) &&
            !editingStartDate && (
              <div className="hidden sm:block mt-6">
                <TreatmentCalendarCTA
                  ctaType={getWeekCTA(currentWeek, weeks.length)}
                  treatmentPlan={treatmentPlan}
                  isMember={currentUser.subscription.isMember}
                  retestLink={retestLink}
                />
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default TreatmentCalendarSidebar;
