import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";
import Markdown from "markdown-to-jsx";

import {
  indexRadioStyles,
  indexCheckboxStyles,
} from "../../../../utils/colors";
import { uncheckNoneChangeHandler } from "../../../../utils/answerUtils";
import { getQuestionTitle } from "../../../../utils/questions";
import BlueRectangularButton from "../../../../components/common/blueRectangularButton";
import { sendConsultIntakeViewSection } from "../../../../utils/analytics/customEventTracking";
import UpdateReminderMsg from "../../../../components/care/consultIntake/updateReminderMessage";
import { intakeDisclaimerCopy } from "../../../../components/care/constants";
import { ConsultIntake } from "../../../../types/care";
import { getError } from "./utils";

const PregnancyQuestions = ({
  consultIntake,
  submitPage,
  loading,
}: {
  consultIntake: ConsultIntake;
  submitPage: (data: any, onError: (error: any) => void) => void;
  loading: boolean;
}) => {
  const [error, setError] = useState("");
  const [apiErrors, setApiErrors] = useState({});

  // for tooltips
  const pregnancyStatusQuestion = useRef<HTMLDivElement>(null);
  const medicalConditionsQuestion = useRef<HTMLDivElement>(null);
  const medicalConditionsOtherQuestion = useRef<HTMLDivElement>(null);
  const currentSymptomsQuestion = useRef<HTMLDivElement>(null);
  const severeSymptomsQuestion = useRef<HTMLDivElement>(null);
  const knownSTIExposuresQuestion = useRef<HTMLDivElement>(null);
  const detailsOfKnownSTIExposuresQuestion = useRef<HTMLDivElement>(null);

  const allQuestions = {
    currently_pregnant: pregnancyStatusQuestion,
    medical_conditions: medicalConditionsQuestion,
    medical_conditions_other: medicalConditionsOtherQuestion,
    current_symptoms: currentSymptomsQuestion,
    severe_symptoms: severeSymptomsQuestion,
    sti_intake_known_exposures: knownSTIExposuresQuestion,
    sti_intake_known_exposures_details: detailsOfKnownSTIExposuresQuestion,
  };

  const consult = consultIntake.consult;

  /* Effects */
  // First render
  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consult.uid,
      testHash: consult?.test_hash,
      section: "medical",
      consultType: "sti",
    });
  }, [consult.test_hash, consult.uid]);

  const populateInitialValues = () => {
    return {
      currently_pregnant: consultIntake.currently_pregnant || "",
      medical_conditions: consultIntake.medical_conditions?.length
        ? consultIntake.medical_conditions
        : consultIntake.past_health_history?.related_diagnoses || [],
      medical_conditions_other:
        consultIntake?.medical_conditions_other ||
        consultIntake?.past_health_history?.related_diagnoses_other ||
        "",
      current_symptoms: consultIntake.current_symptoms || [],
      severe_symptoms: consultIntake.severe_symptoms || "",
      sti_intake_known_exposures:
        consultIntake.sti_intake_known_exposures || [],
      sti_intake_known_exposures_details:
        consultIntake.sti_intake_known_exposures_details || "",
    };
  };

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Helmet>
        <title>Care | Consult | STI Intake | Medical Questions</title>
      </Helmet>
      <div className="max-w-2xl px-4">
        <h3 className="text-center">Medical Questions</h3>
        {error ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
            {error}
          </div>
        ) : (
          ""
        )}
        {/* message reminding user to review their answers */}
        {consultIntake?.past_health_history && !consultIntake?.submitted_at ? (
          <UpdateReminderMsg />
        ) : (
          ""
        )}
        <Formik
          initialValues={populateInitialValues()}
          validate={(values) => {
            var errors = {} as {
              currently_pregnant?: string;
              medical_conditions?: string;
              medical_conditions_other?: string;
              current_symptoms?: string;
              severe_symptoms?: string;
              sti_intake_known_exposures?: string;
              sti_intake_known_exposures_details?: string;
            };

            Object.keys(allQuestions).forEach((key) => {
              const typeKey = key as keyof typeof allQuestions;
              const current = allQuestions[typeKey].current;
              if (current) {
                ReactTooltip.hide(current);
              }
            });
            ReactTooltip.rebuild();
            // required questions
            var requiredQuestions = ["currently_pregnant", "severe_symptoms"];

            var requiredMultiselects = [
              "medical_conditions",
              "current_symptoms",
              "sti_intake_known_exposures",
            ];

            var requiredOtherQuestions = ["medical_conditions"];

            requiredQuestions.forEach((key) => {
              const typeKey = key as keyof typeof values;
              if (!values[typeKey]) {
                errors[typeKey] = "This is a required question";
                const current = allQuestions[typeKey].current;
                if (current) {
                  ReactTooltip.show(current);
                }
              }
            });
            // required multiselect questions (multi select, needs at least 1)
            requiredMultiselects.forEach((key) => {
              const typeKey = key as keyof typeof values;
              const requiredArray = values[typeKey] as string[];
              if (!requiredArray.length) {
                errors[typeKey] = "This is a required question";
                const current = allQuestions[typeKey].current;
                if (current) {
                  ReactTooltip.show(current);
                }
              }
            });

            // required "other" context if "Other" is selected
            requiredOtherQuestions.forEach((key) => {
              const typeKey = key as keyof typeof values;
              const requiredArray = values[typeKey] as string[];
              const otherKey = `${key}_other` as keyof typeof values;
              if (requiredArray.includes("OT") && !values[otherKey]) {
                errors[otherKey] = "This is a required question";
                const current = allQuestions[otherKey].current;
                if (current) {
                  ReactTooltip.show(current);
                }
              }
            });

            // Special case where known exposires sti details are required if any of the known exposures are selected
            const knownExposuresKey = "sti_intake_known_exposures";
            const knownExposuresDetailsKey =
              "sti_intake_known_exposures_details";
            const requiredArray = values[knownExposuresKey];
            if (
              !requiredArray.includes("none") &&
              !values[knownExposuresDetailsKey]
            ) {
              errors[knownExposuresDetailsKey] = "This is a required question";
              const current = allQuestions[knownExposuresDetailsKey].current;
              if (current) {
                ReactTooltip.show(current);
              }
            }

            return errors;
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            setError("");
            submitPage(values, (response) => {
              if (typeof response === "object") {
                setApiErrors(response);
                Object.keys(response).forEach((k) => {
                  const typeKey = k as keyof typeof allQuestions;
                  const current = allQuestions[typeKey].current;
                  if (current) {
                    ReactTooltip.show(current);
                  }
                });
              } else {
                setError(response || "Error saving consult intake");
              }
            });
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <Form>
              <div className="flex-1 bg-evvy-white p-5 mb-3 sm:p-8 rounded-lg">
                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("currently_pregnant", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("currently_pregnant", errors, apiErrors)}
                  data-for="currently_pregnant"
                  ref={pregnancyStatusQuestion}
                >
                  <ReactTooltip
                    id="currently_pregnant"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("intake_currently_pregnant")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  {/* <p>Evvy's treatment protocols are not designed to be taken when you are currently pregnant.</p> */}
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.currently_pregnant.map((o, i) => (
                      <label
                        className="mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="radio"
                          name="currently_pregnant"
                          value={o[0]}
                          className={indexRadioStyles(i)}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("medical_conditions", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("medical_conditions", errors, apiErrors)}
                  data-for="medical_conditions"
                  ref={medicalConditionsQuestion}
                >
                  <ReactTooltip
                    id="medical_conditions"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("related_diagnoses")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p>Please select all that apply</p>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.medical_conditions.map((o, i) => (
                      <label
                        className="mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="checkbox"
                          name="medical_conditions"
                          value={o[0]}
                          className={indexCheckboxStyles(i)}
                          onChange={uncheckNoneChangeHandler({
                            handleChange,
                            setFieldValue,
                            fieldName: "medical_conditions",
                            fieldValues: values.medical_conditions,
                          })}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    ))}
                  </div>
                </div>

                {values.medical_conditions.includes("OT") ? (
                  <div
                    className={`p-4 border rounded-md ${
                      getError("medical_conditions_other", errors, apiErrors)
                        ? "border-coral"
                        : "border-transparent"
                    }`}
                    data-tip={getError(
                      "medical_conditions_other",
                      errors,
                      apiErrors
                    )}
                    data-for="medical_conditions_other"
                    ref={medicalConditionsOtherQuestion}
                  >
                    <ReactTooltip
                      id="medical_conditions_other"
                      effect="solid"
                      place="left"
                      type="error"
                      backgroundColor="#FFA684"
                      textColor="#11161A"
                      className="rounded-xs py-20"
                    />
                    <h4 className="t1">
                      {getQuestionTitle("related_diagnoses_other")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name="medical_conditions_other"
                      as="input"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>
                ) : (
                  ""
                )}

                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("current_symptoms", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("current_symptoms", errors, apiErrors)}
                  data-for="current_symptoms"
                  ref={currentSymptomsQuestion}
                >
                  <ReactTooltip
                    id="current_symptoms"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("intake_current_symptoms")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p>Please select all that apply</p>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.current_symptoms.map((o, i) => (
                      <label
                        className="mb-2 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="checkbox"
                          name="current_symptoms"
                          value={o[0]}
                          className={indexCheckboxStyles(i)}
                          onChange={uncheckNoneChangeHandler({
                            handleChange,
                            setFieldValue,
                            fieldName: "current_symptoms",
                            fieldValues: values.current_symptoms,
                          })}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("known_sti_exposures", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("known_sti_exposures", errors, apiErrors)}
                  data-for="known_sti_exposures"
                  ref={knownSTIExposuresQuestion}
                >
                  <ReactTooltip
                    id="known_sti_exposures"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("known_sti_exposures")}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p>Please select all that apply</p>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.sti_intake_known_exposures.map(
                      (o, i) => (
                        <label
                          className="mb-2 cursor-pointer flex items-center"
                          key={o[0]}
                        >
                          <Field
                            type="checkbox"
                            name="sti_intake_known_exposures"
                            value={o[0]}
                            className={indexCheckboxStyles(i)}
                            onChange={uncheckNoneChangeHandler({
                              handleChange,
                              setFieldValue,
                              fieldName: "sti_intake_known_exposures",
                              fieldValues: values.sti_intake_known_exposures,
                            })}
                          />
                          <span className="ml-2">{o[1]}</span>
                        </label>
                      )
                    )}
                  </div>
                </div>

                {!values.sti_intake_known_exposures.includes("none") && (
                  <div
                    className={`mt-2 p-4 border rounded-md ${
                      getError(
                        "sti_intake_known_exposures_details",
                        errors,
                        apiErrors
                      )
                        ? "border-coral"
                        : "border-transparent"
                    }`}
                    data-tip={getError(
                      "sti_intake_known_exposures_details",
                      errors,
                      apiErrors
                    )}
                    data-for="sti_intake_known_exposures_details"
                    ref={detailsOfKnownSTIExposuresQuestion}
                  >
                    <ReactTooltip
                      id="sti_intake_known_exposures_details"
                      effect="solid"
                      place="left"
                      type="error"
                      backgroundColor="#FFA684"
                      textColor="#11161A"
                      className="rounded-xs py-20"
                    />
                    <h4 className="t1">
                      {getQuestionTitle("details_of_known_sti_exposures")}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name="sti_intake_known_exposures_details"
                      as="input"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>
                )}

                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("severe_symptoms", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("severe_symptoms", errors, apiErrors)}
                  data-for="severe_symptoms"
                  ref={severeSymptomsQuestion}
                >
                  <ReactTooltip
                    id="severe_symptoms"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("intake_severe_symptoms")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.severe_symptoms.map((o, i) => (
                      <label
                        className=" mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="radio"
                          name="severe_symptoms"
                          value={o[0]}
                          className={indexRadioStyles(i)}
                        />
                        <span className="ml-2">
                          <Markdown>{o[1]}</Markdown>
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <div className="bg-coral p-2 px-3 font-medium rounded-md my-4 text-center">
                  {errors && Object.keys(errors).length > 0
                    ? "Please fix the errors above to continue"
                    : intakeDisclaimerCopy}
                </div>
              </div>
              <div className="flex mt-6">
                <BlueRectangularButton
                  type="submit"
                  text="Continue"
                  paddingXClass="sm:px-32"
                  disabled={loading}
                  onClick={handleSubmit}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PregnancyQuestions;
