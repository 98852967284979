/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, ReactNode } from "react";
import { ConsultsContext } from "./consultsContext";
import { Consult } from "../types/care";
import { careService } from "../services/care";

export const ConsultsContextProvider = ({
  isAuthenticated,
  children,
}: {
  isAuthenticated: boolean;
  children: ReactNode;
}) => {
  const [consults, setConsults] = useState<Consult[]>();
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    async function fetchConsults() {
      setLoading(true);
      careService.fetchConsults(
        (response: { data: Consult[] }) => {
          setConsults(response.data);
          setLoading(false);
        },
        (error: any, response: any) => {
          console.error(error);
          setLoading(false);
        }
      );
    }

    if (isAuthenticated) {
      fetchConsults();
    }
  }, [isAuthenticated, refetch]);

  const refetchConsults = () => {
    if (!loading) {
      setRefetch((prev) => !prev);
    }
  };

  return (
    <ConsultsContext.Provider
      value={{ consults, setConsults, loading, refetchConsults }}
    >
      {children}
    </ConsultsContext.Provider>
  );
};
