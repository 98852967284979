import React from "react";
import Markdown from "markdown-to-jsx";
import RectangularButton from "../common/rectangularButton";

// lib
import {
  GENERAL_COACHING_CALL_LINK,
  facebookSupportGroupLink,
  communityProviderRecsLink,
} from "../../pages/constants";
import { eventNames } from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { calendlyURL } from "./constants";

const AdditionalCareOptions = ({ consult, analyticsEventArgs }) => {
  const coachingLink =
    consult?.status === "CP" ? calendlyURL : GENERAL_COACHING_CALL_LINK;

  // button click actions
  const handleLinkClick = (href) => {
    window.open(href, "_blank");
  };

  return (
    <div className="justify-center">
      <div className="flex mb-3 lg:mb-12">
        <h2 className="hidden lg:block mx-auto">Additional Care Options</h2>
        <h3 className="block lg:hidden mx-auto">Additional Care Options</h3>
      </div>
      <div
        className={`block max-w-6xl lg:flex flex-wrap lg:space-x-10 lg:mx-auto`}
      >
        <div className="flex-1 pb-4 max-w-md mx-auto">
          <img
            src="/images/graphics/care-options-coaching.png"
            loading="lazy"
            alt="Evvy Coaching"
            className=""
          />
          <div className="bg-evvy-white p-7 rounded-b-xl">
            <h3 className="text-3xl lg:text-3xl px-1">
              <Markdown>*Free* coaching call with an Evvy coach</Markdown>
            </h3>
            <div className="text-base">
              Review your results with a certified health coach in the context
              of your unique symptoms and goals.
            </div>
            <div className="pt-5 sm:pt-12 pb-2 sm:pb-4">
              <RectangularButton
                text={`Talk to an expert →`}
                bgColorClass="bg-evvy-white"
                textColorClass="text-evvy-black"
                borderColor="border-evvy-black"
                onClick={analyticsClickHandler({
                  eventName: eventNames.CARE_CLICKED_TALK_TO_EXPERT,
                  eventArgs: {
                    ...analyticsEventArgs,
                    location: "additional-care-options",
                  },
                  clickHandler: () => handleLinkClick(coachingLink),
                })}
                fullWidth
                verticalPadding="py-6"
                horizontalPadding="px-2"
              />
            </div>
          </div>
        </div>
        <div className="flex-1 pb-4 max-w-md mx-auto">
          <img
            src="/images/graphics/care-options-provider-recs.png"
            loading="lazy"
            alt="Evvy Community Provider Recs"
            className=""
          />
          <div className="bg-evvy-white p-7 rounded-b-xl">
            <h3 className="text-3xl lg:text-3xl px-1">
              <Markdown>Evvy Community Provider Recs</Markdown>
            </h3>
            <div className="text-base">
              The first-ever, crowd-sourced list of our community’s favorite
              vaginal health providers.
            </div>
            <div className="pt-5 sm:pt-[42px] pb-2 sm:pb-4">
              <RectangularButton
                text={`View our community recs →`}
                bgColorClass="bg-evvy-white"
                textColorClass="text-evvy-black"
                borderColor="border-evvy-black"
                onClick={analyticsClickHandler({
                  eventName: eventNames.CARE_CLICKED_VIEW_COMMUNITY_RECS,
                  eventArgs: analyticsEventArgs,
                  clickHandler: () =>
                    handleLinkClick(communityProviderRecsLink),
                })}
                fullWidth
                verticalPadding="py-6"
                horizontalPadding="px-2"
              />
            </div>
          </div>
        </div>
        <div className="flex-1 max-w-md mx-auto">
          <img
            src="/images/graphics/care-options-facebook.png"
            loading="lazy"
            alt="Evvy Facebook Community"
            className=""
          />
          <div className="bg-evvy-white p-7 rounded-b-xl">
            <h3 className="text-3xl lg:text-3xl px-1">
              <Markdown>Join the Evvy Facebook Community</Markdown>
            </h3>
            <div className="text-base pb-0.5">
              Join Evvy's Support Community for Vaginal Health (it's free!) to
              connect, share tips, learn about vaginal health, and more with
              other Evvy users.
            </div>
            <div className="pt-5 sm:pt-[21px] pb-2 sm:pb-4">
              <RectangularButton
                text={`join the evvy community →`}
                bgColorClass="bg-evvy-white"
                textColorClass="text-evvy-black"
                borderColor="border-evvy-black"
                onClick={analyticsClickHandler({
                  eventName: eventNames.CARE_CLICKED_JOIN_COMMUNITY,
                  eventArgs: analyticsEventArgs,
                  clickHandler: () => handleLinkClick(facebookSupportGroupLink),
                })}
                fullWidth
                verticalPadding="py-6"
                horizontalPadding="px-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalCareOptions;
