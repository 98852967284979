import React, { useState, useEffect } from "react";
import { authService } from "../../services/auth";
import { useParams } from "react-router-dom";

// TODO DVY-2631: Implement design for this page
const VerifyAccountOrderTransfer = () => {
  const { hash } = useParams();
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    transferOrderToAccount();
  }, [hash]);

  const transferOrderToAccount = () => {
    if (sending) return;
    setSending(true);
    authService.verifyAccountOrderTransferConfirm(
      hash,
      () => {
        setSent(true);
        setSending(false);
      },
      (error: any) => {
        setSending(false);
        setError(
          error && error.response && error.response.data
            ? error.response.data
            : "Could not verify order"
        );
      }
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (verified) {
    return <div>Order transfered</div>;
  }

  return <div>Order transfer failed</div>;
};

export default VerifyAccountOrderTransfer;
