// returns prefilled answer for health history question based on current & previous health history answers
// for single select and fill in answers
const prefillAnswer = ({
  questionName,
  healthContext,
  transformationFn = (undefined) => undefined,
  answerOptions = undefined,
  defaultAns,
  setQuestionPrefilled = (undefined) => undefined,
}) => {
  let answer;
  let hasAnswer = healthContext[questionName];
  if (Array.isArray(healthContext[questionName])) {
    if (healthContext[questionName].length === 0) {
      hasAnswer = false;
    }
  }
  // if answer is already filled out, just use it
  if (hasAnswer) {
    setQuestionPrefilled?.(false);
    answer = healthContext[questionName];

    // if answer is not filled out but was in past health history, then use past answer
  } else if (
    healthContext.past_health_history &&
    healthContext.past_health_history[questionName]
  ) {
    setQuestionPrefilled?.(true);
    answer = healthContext.past_health_history[questionName];
    // default answer
  } else {
    return defaultAns || "";
  }

  // if the previously selected answer is no longer an option, then reset to default
  if (answerOptions) {
    const answerCodes = answerOptions.map((optionPair) => optionPair[0]);
    if (!answerCodes.includes(answer)) {
      answer = defaultAns || "";
    }
  }

  // if transformation function specified, then apply tranformation before returning answer
  if (transformationFn) {
    return transformationFn(answer);
  } else {
    return answer;
  }
};

// returns prefilled answer for health history question based on current & previous health history answers
// for multi select answers
const prefillMultiSelectAnswer = ({
  questionName,
  healthContext,
  answerOptions,
  transformationFn = (undefined) => undefined,
  defaultAns = undefined,
}) => {
  let answer;
  // if answer is already filled out, just use it
  if (healthContext[questionName].length) {
    answer = healthContext[questionName];
    // if answer is not filled out but was in past health history, then use past answer
  } else if (
    healthContext.past_health_history &&
    healthContext.past_health_history[questionName]
  ) {
    answer = healthContext.past_health_history[questionName];
  } else {
    return defaultAns || [];
  }

  // if the previously selected answer is no longer an option, then filter out
  if (answerOptions) {
    const answerCodes = answerOptions.map((optionPair) => optionPair[0]);
    answer = answer.filter((item) => answerCodes.includes(item));
    if (answer.length === 0) {
      answer = defaultAns || [];
    }
  }

  // if transformation function specified, then apply tranformation before returning answer
  if (transformationFn) {
    return transformationFn(answer);
  } else {
    return answer;
  }
};

// Util for multi select answers with a None option. Automatically unchecks all other checked options
// when checking None. If None is already checked, then it unchecks None when user checks a different option
const uncheckNoneChangeHandler =
  ({
    handleChange,
    setFieldValue,
    fieldName,
    fieldValues,
    noneCode = ["NO", "NA", "none", "prefer-not-to-say"],
  }) =>
  (e) => {
    handleChange(e);
    const isNoneCode = noneCode.includes(e.target.value);

    // Normalize fieldValues to be an array
    const normalizedFieldValues = Array.isArray(fieldValues)
      ? fieldValues
      : [fieldValues];

    if (isNoneCode && e.target.checked) {
      setFieldValue(fieldName, [e.target.value]);
    } else if (
      !isNoneCode &&
      e.target.checked &&
      normalizedFieldValues.some((val) => noneCode.includes(val))
    ) {
      const newValues = normalizedFieldValues.filter(
        (val) => !noneCode.includes(val)
      );
      newValues.push(e.target.value);
      setFieldValue(fieldName, newValues);
    }
  };

export { prefillAnswer, prefillMultiSelectAnswer, uncheckNoneChangeHandler };
