import React from "react";
import { HealthContextFields } from "../../types/test";
import { SectionIntro } from "../../utils/healthHistoryV2";

const SectionIntroCard = ({
  sectionIntro,
  healthContext,
}: {
  sectionIntro: SectionIntro;
  healthContext: HealthContextFields;
}) => {
  return (
    <div
      className={
        "mt-4 mb-6 w-full bg-cover bg-no-repeat sm:flex flex-auto text-center sm:text-left bg-evvy-white rounded-lg justify-center items-center p-[15px] sm:p-[20px]"
      }
      style={{
        backgroundImage: "url('/images/graphics/section-intro-gradient.png')",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <p className="text-left t4 mb-0 block sm:hidden">
        {sectionIntro.getHeader?.(healthContext)}
      </p>
      <h6 className="text-left w-[200px] mr-4 mb-0 p-2 hidden sm:block">
        {sectionIntro.getHeader?.(healthContext)}
      </h6>
      <p className={"w-full sm:w-[500px] text-left sm:text-left b2 mb-0 mt-1"}>
        {sectionIntro?.getText(healthContext)}
      </p>
    </div>
  );
};

export default SectionIntroCard;
