// types
import { TreatmentPathway } from "../../../types/care";

// components
import { QuestionMarkTooltip } from "../../common/questionCircleTooltip";
import { Bullets } from "../../common/bullets";

// utils
import { indexRadioStyles } from "../../../utils/colors";
import { sendCareSelectedTreatmentOption } from "../../../utils/analytics/customEventTracking";
import { cn } from "../../../utils/cn";

const TREATMENT_PATHWAY_CARD_COPY = {
  treatment: {
    title: "Complete Treatment Program",
    subtitle:
      "2 months of treatments that may include prescription antibiotics, antifungals, scientifically-backed probiotics, and boric acid suppositories",
    bullets: [
      "For people who may be eligible for another round of antibiotics/antifungals",
      "Includes a 20 minute, 1:1 call with a health coach",
    ],
  },
  maintenance: {
    title: "Maintenance Program",
    subtitle:
      "3 months of supplements that may include oral probiotics, vaginal probiotics, and boric acid suppositories",
    bullets: [
      "For people who have already gone through treatment and want to maintain their microbiome using only probiotics",
      "Continuous, async support included",
    ],
  },
};

const TREATMENT_DIFFERENT_COPY = {
  antibioticsToAntibiotics:
    "You were previously on an antibiotics plan, and now may benefit from another round of antibiotics treatment. Your provider will review your specific case to determine which antibiotic is safe for you to take.",
  probioticsToAntibiotics:
    "You were previously on a probiotics plan, and now you may be eligible for an antibiotics/antifungals based plan to reduce your disruptive microbes",
};

export const TreatmentPathwayCards = ({
  treatmentPathways,
  selectedPathwaySlug,
  setSelectedPathwaySlug,
  analyticsArgs = {},
  previousTreatmentPathway,
}: {
  treatmentPathways: TreatmentPathway[];
  selectedPathwaySlug: string;
  setSelectedPathwaySlug: (pathway: string) => void;
  analyticsArgs?: any;
  previousTreatmentPathway?: TreatmentPathway;
}) => {
  const onSelect = (treatmentPathway: TreatmentPathway) => {
    setSelectedPathwaySlug(treatmentPathway.slug);
    sendCareSelectedTreatmentOption({
      ...analyticsArgs,
      treatmentPathway: treatmentPathway.slug,
      pathwayType: treatmentPathway.type,
      carePrice: treatmentPathway.pricing_info.discounted_total,
      eligibleTreatmentPathways: treatmentPathways.map(
        (pathway) => pathway.slug
      ),
    });
  };
  const previousPrimaryTreatment =
    previousTreatmentPathway?.primary_treatment || "";

  return (
    <div className="space-y-3" role="group" aria-labelledby="checkbox-group">
      {treatmentPathways.map((treatmentPathway, index) => {
        const tooltipText =
          previousPrimaryTreatment === "antibiotic" &&
          treatmentPathway.primary_treatment === "antibiotic"
            ? TREATMENT_DIFFERENT_COPY.antibioticsToAntibiotics
            : previousPrimaryTreatment === "probiotic" &&
              treatmentPathway.primary_treatment === "antibiotic"
            ? TREATMENT_DIFFERENT_COPY.probioticsToAntibiotics
            : "";
        return (
          <div key={`treatment-${index}`}>
            <TreatmentPathwayCard
              treatmentPathway={treatmentPathway}
              onSelect={() => onSelect(treatmentPathway)}
              selected={selectedPathwaySlug === treatmentPathway.slug}
              tooltipText={tooltipText}
            />
          </div>
        );
      })}
    </div>
  );
};

const TreatmentPathwayCard = ({
  treatmentPathway,
  onSelect,
  selected,
  tooltipText,
}: {
  treatmentPathway: TreatmentPathway;
  onSelect: () => void;
  selected: boolean;
  tooltipText?: string;
}) => {
  const copy = TREATMENT_PATHWAY_CARD_COPY[treatmentPathway.type];

  return (
    <div
      onClick={onSelect}
      className={cn("bg-white rounded-2xl pt-6 pb-5 px-5 space-y-3", {
        "border border-black": selected,
      })}
    >
      <div className="flex justify-between t3 medium">
        <div className="flex space-x-3 my-auto">
          <input
            type="radio"
            name="treatment-pathway"
            value={treatmentPathway.slug}
            className={indexRadioStyles(null)}
            checked={selected}
            onChange={onSelect}
          />
          <div className="flex">
            <div className="text-[15px] sm:text-[16px]/6">{copy.title}</div>
            {tooltipText && (
              <QuestionMarkTooltip
                className="ml-1.5"
                tooltipText={tooltipText}
              />
            )}
          </div>
        </div>
        <div className="text-base sm:text-lg">
          <span className="text-dv-pond semibold">
            ${treatmentPathway.pricing_info.discounted_total}{" "}
          </span>
        </div>
      </div>
      <div className="b2 medium">{copy.subtitle}</div>
      <div className="space-y-1">
        <Bullets
          bullets={copy.bullets}
          linePaddingClass="pb-0"
          className="mb-0"
        />
      </div>
    </div>
  );
};
