import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/common/modal";
import { Formik, Field, Form } from "formik";

import { authService } from "../../services/auth";
import { accountService } from "../../services/account";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { ACCOUNT_PAGE_URL, PROVIDER_ACCOUNT_PAGE_URL } from "./constants";

interface EmailModalProps {
  handleClose: () => void;
}

const EmailModal: React.FC<EmailModalProps> = ({ handleClose }) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // route params
  const navigate = useNavigate();

  // context
  const currentUser = useLoggedInUser();

  return (
    <Modal widthClass="w-full md:w-6/12" closeModal={handleClose}>
      <div className="bg-white p-12">
        <div className="mb-12">
          <h2>Update email address</h2>
        </div>

        <p>Changing your email address will require verification.</p>

        <Formik
          initialValues={{
            email: (currentUser && currentUser.identity.email) || "",
            current_password: "",
          }}
          validate={(values) => {
            setError(null);

            if (!values.email) {
              setError("Email is required");
            }
            if (!values.current_password) {
              setError("Current password is required to change your email");
            }

            return {};
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            setError(null);
            setLoading(true);
            accountService.updateAccount(
              values,
              () => {
                // lets update user config and then take user back to account page
                authService.fetchUser(
                  (response: {
                    data: { identity: { email_verified: boolean } };
                  }) => {
                    setLoading(false);
                    if (
                      !response.data.identity.email_verified &&
                      !currentUser.identity.isProvider
                    ) {
                      navigate("/verify-email/");
                    } else {
                      // this following navigation hack effectively re-renders the account component without
                      // needing to "refresh" the page. the reason we need this is because the account
                      // information is stored in local storage which is not reactive where we display it
                      navigate(
                        currentUser.identity.isProvider
                          ? PROVIDER_ACCOUNT_PAGE_URL
                          : ACCOUNT_PAGE_URL,
                        { replace: true }
                      );
                      handleClose();
                    }
                  }
                );
              },
              (error, response) => {
                setError(response || "Error saving account info");
                setLoading(false);
              }
            );
          }}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => (
            <Form>
              {error && (
                <div className="bg-coral p-2 px-3 font-medium rounded-sm my-6">
                  {error}
                </div>
              )}

              <div className="mb-8">
                <div className="text-lg font-medium mb-2">Current Password</div>
                <Field
                  id="current_password"
                  name="current_password"
                  type="password"
                  autoFocus
                  autoComplete="off"
                  required
                  placeholder="Current Password"
                  className="appearance-none block w-full p-6 bg-evvy-cream rounded-xs border-none focus:outline-none focus:ring-transparent focus:border-none"
                />
              </div>

              <div className="mb-8">
                <div className="text-lg font-medium mb-2">Email Address</div>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="off"
                  required
                  placeholder="Enter new email address"
                  className="appearance-none block w-full p-6 bg-evvy-cream rounded-xs border-none focus:outline-none focus:ring-transparent focus:border-none"
                />
              </div>

              <div className="flex items-center">
                <button
                  onClick={handleClose}
                  className="ml-auto underline underline-offset-4 uppercase text-sm font-semibold tracking-wider cursor-pointer"
                >
                  Cancel
                </button>
                <button
                  className="cursor-pointer tracking-wider ml-12 py-6 px-20 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
                  type="submit"
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default EmailModal;
