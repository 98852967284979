export const CreamCircleIcon: React.FC<{
  className?: string;
  strokeClassName?: string;
}> = ({ className = "", strokeClassName = "" }) => {
  return (
    <svg
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="current" />
      <g clipPath="url(#clip0_7074_15654)">
        <path
          d="M19.4082 33.0029C18.46 33.4875 17.2984 33.1441 16.766 32.222L13.9809 27.398C13.4485 26.4759 13.732 25.2983 14.6257 24.7194L26.3983 17.094C27.4131 16.5081 28.5339 16.6047 28.9238 17.2799L33 24.3402C33.3984 25.0301 32.8915 26.0468 31.8983 26.6202L19.4082 33.0029Z"
          className={strokeClassName}
          stroke="black"
          strokeWidth="1.30557"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.5508 23.5651L29.3718 18.0589C29.285 17.9085 29.3935 17.6762 29.6087 17.552L31.4124 16.5105C31.6275 16.3863 31.883 16.4085 31.9699 16.5589L35.1489 22.0651C35.2357 22.2155 35.1271 22.4478 34.912 22.572L33.1082 23.6134C32.8832 23.7587 32.6443 23.727 32.5508 23.5651Z"
          className={strokeClassName}
          stroke="black"
          strokeWidth="1.30557"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7074_15654">
          <rect
            width="15.2909"
            height="23.3628"
            fill="white"
            transform="translate(30.9756 12.1836) rotate(60)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
