import React from "react";

// wrapper component for scrolling screens
// currently in use only in results page but may be reused
const Screen = ({ children, screenHeight, customMarginClass }) => {
  return (
    <div
      className={`flex flex-col space-y-6 md:justify-center ${
        screenHeight ? "min-h-screen" : ""
      } ${customMarginClass ? customMarginClass : "mb-52"}`}
    >
      {children}
    </div>
  );
};

export default Screen;
