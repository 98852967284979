import React from "react";

import { typeDictionary, bgClasses, formatters } from "../../utils/viz";

const ResultsVizLegend = ({ data }) => (
  <div className={`cursor-default grid grid-cols-2 gap-y-3 gap-x-6 w-fit`}>
    {Object.entries(typeDictionary).map(([code, { label, description }]) => (
      <div className="flex" key={`viz-legend-${code}`}>
        <div
          className={`dot-legend rounded-full ${bgClasses[code].fill} flex-shrink-0 mr-4`}
          aria-hidden={true}
        />
        <div
          className={`border-b-2 border-transparent capitalize t3`}
        >{`${label}`}</div>
      </div>
    ))}
  </div>
);

export default React.memo(ResultsVizLegend);
