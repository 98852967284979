import React, { useEffect } from "react";
import { HealthContextFields } from "../../types/test";
import { EducationalMomentType, Page } from "../../utils/healthHistoryV2";
import classNames from "classnames";
import { sendViewedHealthHistoryEducationalMoment } from "../../utils/analytics/customEventTracking";
import { getHealthHistorySection } from "./header";

const EducationalMomentCard = ({
  educationalMoment,
  healthContext,
  isMobile,
  page,
  hash,
}: {
  educationalMoment: EducationalMomentType;
  healthContext: HealthContextFields;
  isMobile: boolean;
  page: Page;
  hash: string;
}) => {
  const showEducationalMoment =
    educationalMoment.alwaysShow ||
    educationalMoment.showEducationalMoment?.({
      healthContextValues: healthContext,
    });

  useEffect(() => {
    if (showEducationalMoment) {
      sendViewedHealthHistoryEducationalMoment({
        section: getHealthHistorySection(page.section),
        page: page.slug,
        questionGroup: page.section,
        testHash: hash,
      });
    }
  }, []);

  const containerClasses = classNames(
    "bg-cover bg-no-repeat bg-evvy-white rounded-lg justify-center items-center p-4 sm:p-4",
    {
      "absolute ml-[600px] w-[280px] text-left": !isMobile,
      "text-center mb-36 -mt-[75px]": isMobile,
      "mt-0": !isMobile && educationalMoment.alwaysShow,
      "-mt-36": !isMobile && educationalMoment?.showEducationalMoment,
    }
  );

  return (
    <div className="animate-fadeinfast">
      {showEducationalMoment && (
        <div
          className={containerClasses}
          style={{
            backgroundImage:
              "url('/images/graphics/educational-moment-gradient.png')",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <p className={classNames("t4 mb-0 block", { "text-left": isMobile })}>
            {educationalMoment.getHeader?.(healthContext)}
          </p>
          <p className="w-full sm:w-5/7 b2 medium mb-0 mt-1 text-left">
            {educationalMoment?.getText(healthContext)}
          </p>
        </div>
      )}
    </div>
  );
};

export default EducationalMomentCard;
