import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BackArrow } from "../common/icons/back-arrow-circle.svg";

const StickyHeader = ({
  buttonBackGroundClass = "bg-white",
  backgroundColorClass = "",
  onClickBack,
}) => {
  return (
    // sticky nav for results page
    <nav
      className={`w-full sticky top-0 px-6 md:px-12 pt-5 md:pt-7 pb-5 flex justify-between align-center items-center z-20 ${backgroundColorClass} pointer-events-none`}
      style={
        backgroundColorClass === "bg-evvy-cream"
          ? {
              background:
                "linear-gradient(to bottom, #F3F1EE 0%, #F3F1EE 85%, rgba(243, 241, 238, 0) 99%)",
            }
          : {}
      }
    >
      {/* link to "my tests" */}
      <Link to="/tests/" onClick={onClickBack ? onClickBack : undefined}>
        {/* border  wrapper div*/}
        <button
          className={`p-2 bg-evvy-cream md:${buttonBackGroundClass} rounded-full border-2 border-evvy-black text-evvy-black group flex items-center pointer-events-auto`}
          aria-label="Back to my tests"
        >
          {/* arrow button visible in desktop */}
          <span className="uppercase group-hover:hidden w-8 h-8 text-center">
            <h4 className="hidden sm:inline font-bold leading-6">←</h4>
            <BackArrow className="sm:hidden h-full mx-auto w-1/2" />
          </span>
          {/* full text button visible on hover and mobile */}
          <span className="px-2 h-8 hidden group-hover:inline uppercase tracking-wider font-semibold">
            <span className="pr-2">
              <h4 className="font-bold leading-6 margin-0 inline">←</h4>
            </span>{" "}
            Back to tests
          </span>
        </button>
      </Link>
      {/* evvy logo */}
      <img
        className="h-8 sm:h-10"
        src="/images/evvy-logo-text.svg"
        loading="lazy"
        alt="Evvy Text Logo"
      />
    </nav>
  );
};

export default StickyHeader;
