import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { select } from "d3";

// lib
import { setClickedValence, setHoveredValence } from "./compareSlice";
import { fill, formatters } from "../../utils/viz";

/* stacked bar component */
const StackedBar = ({
  series,
  xPos,
  biome,
  yScale,
  width,
  hasInteractivityDisabled,
  clickedValence,
}) => {
  // redux dispatch method for updating global store
  const dispatch = useDispatch();

  // transition extra bars up
  useEffect(() => {
    series.forEach((d, i) => {
      const type = d.key;
      select(`#${type}-${xPos}`)
        .transition()
        .duration(500)
        .attr("y", -20 + -20 * i);
    });
  }, [series, biome]);

  return series.map((d, i) => {
    const [height, yPos] = d[0];
    const type = d.key;
    // get data for current bacteria valence
    const typeData = Object.entries(biome).reduce(
      (acc, [key, data]) => (key === type ? data : acc),
      null
    );
    // if data exists for current valence, extract it in order to pass on to tooltip
    if (typeData) {
      var { percent } = typeData;
    }

    return (
      <g key={type} className="md:cursor-pointer">
        <rect
          x={xPos}
          y={yScale(yPos)}
          height={yScale(height) - yScale(yPos)}
          width={width}
          // fade and grey out rectangles that aren't clicked
          className={
            !clickedValence || clickedValence === type
              ? "opacity-100 "
              : "opacity-50"
          }
          style={{
            fill:
              !clickedValence || clickedValence === type
                ? `${fill(type)}`
                : "#C4C4C4",
          }}
          tabIndex={hasInteractivityDisabled ? -1 : 0}
          onMouseOver={() => {
            if (!hasInteractivityDisabled) {
              dispatch(setHoveredValence(type));
            }
          }}
          onClick={() => {
            if (!hasInteractivityDisabled) {
              dispatch(setClickedValence(type));
            }
          }}
          onFocus={() => {
            if (!hasInteractivityDisabled) {
              dispatch(setHoveredValence(type));
            }
          }}
          onBlur={() => {
            if (!hasInteractivityDisabled) {
              dispatch(setHoveredValence(null));
            }
          }}
        />
        {percent > 2 && (
          <text
            x={xPos + width / 2}
            y={(yScale(height) + yScale(yPos)) / 2}
            dy="0.15em"
            dominantBaseline="middle"
            textAnchor="middle"
            className="t4 pointer-events-none"
          >
            {percent &&
              !isNaN(percent) &&
              `${formatters.numTrunc(Math.floor(percent))}%`}
          </text>
        )}
        {percent > 0 && percent < 2 && (
          <g>
            <rect
              id={`${type}-${xPos}`}
              x={xPos}
              height={16}
              width={width}
              className={
                !clickedValence || clickedValence === type
                  ? "opacity-100 "
                  : "opacity-50"
              }
              style={{
                fill:
                  !clickedValence || clickedValence === type
                    ? `${fill(type)}`
                    : "#C4C4C4",
              }}
              tabIndex={hasInteractivityDisabled ? -1 : 0}
              onMouseOver={() => {
                if (!hasInteractivityDisabled) {
                  dispatch(setHoveredValence(type));
                }
              }}
              onClick={() => {
                if (!hasInteractivityDisabled) {
                  dispatch(setClickedValence(type));
                }
              }}
              onFocus={() => {
                if (!hasInteractivityDisabled) {
                  dispatch(setHoveredValence(type));
                }
              }}
              onBlur={() => {
                if (!hasInteractivityDisabled) {
                  dispatch(setHoveredValence(null));
                }
              }}
            ></rect>
            <text
              x={xPos + width / 2}
              y={-11 + -20 * i}
              dominantBaseline="middle"
              textAnchor="middle"
              className="t4 pointer-events-none"
            >
              {percent &&
                !isNaN(percent) &&
                `${percent < 0.1 ? "<0.1" : formatters.numShort(percent)}%`}
            </text>
          </g>
        )}
      </g>
    );
  });
};

export default StackedBar;
