const capitalizeFirst = (word) => {
  if (!word) return "";
  return word[0].toUpperCase() + word.slice(1, word.length);
};

const slugify = (word) => {
  if (!word) return "";
  return word
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export { capitalizeFirst, slugify };
