/**
 * Compare results section to display the bacteria percent distribution of each bacteria type for a selected test
 * @param {number} percentage a number indicating the percentage of a bacteria in a user's test results
 * @param {string} color  a string for the bacteria category color (protective, neutral/variable, disruptive, unknown)
 */

import React from "react";

const TrendsTablePercentBar = ({ percentage, color }) => {
  return (
    <div
      className="grid items-center"
      style={{ gridTemplateColumns: "1.2fr .2fr" }}
    >
      <div
        style={{ backgroundColor: "#efefef", height: "10px", width: "100%" }}
      >
        <div
          style={{
            width: `${percentage}%`,
            height: "10px",
            backgroundColor: color,
          }}
        ></div>
      </div>
      <div className="text-base font-medium ml-3 flex justify-end">
        {percentage}%
      </div>
    </div>
  );
};

export default TrendsTablePercentBar;
