import { Prescription } from "../../../types/care";
import { AddCartItemPayload, CartItem } from "../../../types/cart";
import { cn } from "../../../utils/cn";
import { FORM_FACTOR_TO_IMAGE_V2 } from "../constants";
import TransparentCarePhasePill from "../transparentCare/TransparentCarePhasePill";
import { ReactComponent as Checkmark } from "../../common/icons/checkmark.svg";
import { useNavigate } from "react-router-dom";
import { daysElapsed } from "../../../utils/datetime";

const ALaCareTreatmentCard: React.FC<{
  treatment: Prescription;
  phase: string;
  addToCart: (items: AddCartItemPayload[]) => void;
  openCart: () => void;
  inCart: boolean;
}> = ({ treatment, phase, addToCart, inCart, openCart }) => {
  const navigate = useNavigate();
  const formFactor =
    treatment.form_factor as keyof typeof FORM_FACTOR_TO_IMAGE_V2;
  const image = FORM_FACTOR_TO_IMAGE_V2[formFactor]?.image?.individual;
  const treatmentExpiresAt = treatment.expires_at;
  const expiresInDays = treatmentExpiresAt
    ? daysElapsed(Date.now(), treatmentExpiresAt) + 1
    : undefined;

  return (
    <div
      className="bg-white border-evvy-black border-opacity-10 border rounded-2xl w-full max-w-[250px] sm:max-w-[285px] flex flex-col cursor-pointer"
      onClick={() => {
        navigate(`/care/shop/treatment/${treatment.slug}/`);
      }}
    >
      <div className="flex items-center justify-center rounded-2xl bg-gradient-to-b from-[#D8CFCA] to-white via-[#D8CFCA] via-75% h-[130px] sm:h-[200px] relative">
        <TransparentCarePhasePill
          phase={phase}
          productConfig="individual"
          className="absolute top-3 left-3"
          daysUntilExpiry={expiresInDays}
        />
        <img
          src={image}
          alt={formFactor}
          className={cn(
            "drop-shadow-sm",
            FORM_FACTOR_TO_IMAGE_V2[formFactor]?.sizeClassName?.individual
          )}
        />
      </div>
      <div className="flex flex-col justify-between flex-grow">
        <div className="px-5 mb-5">
          <div className="text-lg font-medium leading-tight sm:leading-normal mb-1 sm:mb-0">
            {treatment.preview_title}
          </div>
          <div className="text-sm text-gray-500">
            {treatment.preview_short_description}
          </div>
        </div>
        <div className="px-5 pb-5">
          <button
            className={cn(
              "p-2.5 w-full flex items-center border rounded transition-colors duration-100",
              {
                "border-evvy-dark-beige": inCart,
                "border-evvy-black hover:bg-evvy-blue": !inCart,
              }
            )}
            onClick={(e) => {
              e.stopPropagation();
              if (inCart) {
                openCart();
              } else {
                addToCart([
                  {
                    item_type: "individual-treatment",
                    quantity: 1,
                    treatment_product_id: treatment.id,
                    treatment_product: treatment,
                  },
                ]);
              }
            }}
          >
            {inCart && <Checkmark className="h-5 w-5 mr-2 flex-shrink-0" />}
            <div className="flex-grow flex flex-wrap justify-between items-center">
              <div className="t4">{inCart ? "Added" : "Add"}</div>
              <div className="b2 semibold content-stretch">
                ${treatment.ecomm_product.price.toString().split(".")[0]}
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ALaCareTreatmentCard;
