import { ReactNode, useEffect, useMemo, useState } from "react";
import { Order } from "../types/orders";
import { fetchOpenOrders } from "../services/order";
import { OrdersContext } from "./ordersContext";

export const OrdersContextProvider = ({
  isAuthenticated,
  children,
}: {
  isAuthenticated: boolean;
  children: ReactNode;
}) => {
  const [openOrders, setOpenOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    async function fetchAllOpenOrders() {
      try {
        const allOrderData = (await fetchOpenOrders()) as Order[];
        const testOrders = allOrderData.filter((order) =>
          order.business_verticals.includes("test")
        );
        const ungatedRxOrders = allOrderData.filter(
          (order) => order.order_type === "UX"
        );
        setOpenOrders(allOrderData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (isAuthenticated) {
      fetchAllOpenOrders();
    }
  }, [isAuthenticated, refetch]);

  const refetchOrders = () => {
    setRefetch((prev) => !prev);
  };

  const openTestOrders = useMemo(() => {
    return openOrders.filter((order) =>
      order.business_verticals.includes("test")
    );
  }, [openOrders]);

  const openUngatedRxOrders = useMemo(() => {
    return openOrders.filter((order) => order.order_type === "UX");
  }, [openOrders]);

  return (
    <OrdersContext.Provider
      value={{
        openOrders,
        openTestOrders,
        openUngatedRxOrders,
        setOpenOrders,
        loading,
        refetchOpenOrders: refetchOrders,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
