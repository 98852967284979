import { FC } from "react";
import { StartStiConsultIntakeButton } from "./startStiConsultIntakeButton";

type StiTreatmentModalProps = {
  handleClose: () => void;
  testHash: string;
};

// sti modal copy
const STI_COPY = {
  TITLE: "Important:",
  DESCRIPTION:
    "Evvy's treatment programs do not include treatment for any STIs. This program will not be effective if STIs are not treated first.",
};

export const StiTreatmentModal: FC<StiTreatmentModalProps> = ({
  handleClose,
  testHash,
}) => {
  return (
    <div className={`w-full flex flex-col py-7 px-16 bg-evvy-white`}>
      {/* title */}
      <h5 className="text-4xl text-center">{STI_COPY.TITLE}</h5>
      {/* description */}
      <p className="text-lg ">{STI_COPY.DESCRIPTION}</p>
      <div className="flex flex-col md:flex-row gap-2 w-full justify-center">
        <StartStiConsultIntakeButton
          ctaPrimary={false}
          testHash={testHash}
          location="careIntakeModal"
        />
        <button
          onClick={async () => {
            handleClose();
          }}
          className="w-full bg-evvy-blue text-black hover:bg-evvy-pine hover:text-white my-auto p-6 font-semibold tracking-wider focus:outline-none"
        >
          I understand, continue
        </button>
      </div>
    </div>
  );
};
