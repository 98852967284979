import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import Markdown from "markdown-to-jsx";

// services
import { testsService } from "../../../services/tests";

// components
import { ReactComponent as Chat } from "../../../components/care/icons/chat.svg";
import { PrescriptionCircleIcon as Prescription } from "../../../components/care/icons/prescriptionCircleIcon";
import { ReactComponent as ShippingBox } from "../../../components/care/icons/box.svg";
import { ReactComponent as FirstAid } from "../../../components/care/icons/first-aid-circle.svg";
import { ReactComponent as ID } from "../../../components/care/icons/id-icon.svg";
import { ReactComponent as Union } from "../../../components/care/icons/union.svg";
import { sendConsultIntakeViewSection } from "../../../utils/analytics/customEventTracking";
import PrescriptionBackgroundImage from "../../../components/care/images/raining-prescriptions.png";
import ThreeDoctors from "../../../components/care/images/doctors.png";

// types
import { Consult, ConsultIntake } from "../../../types/care";
import { Test } from "../../../types/test";
import { AxiosError, AxiosResponse } from "axios";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import LoadingSpinner from "../../../components/common/loadingSpinner";
import {
  PRIVACY_POLICY,
  TELEHEALTH_INFORMED_CONSENT,
  TERMS_OF_SERVICE,
} from "../../constants";
import { SHIPPING_DAYS } from "../../../components/care/constants";

const DISCLAIMER_COPY_MD =
  "*If you are experiencing any ulcers, wounds that won't heal, or external (dermatological) symptoms, please seek in-person care. As a reminder, this does not replace your Pap smear or detect / treat STIs.*";
const L1_DISCLAIMER_COPY_MD =
  "*If you are experiencing any ulcers, wounds that won't heal, or external (dermatological) symptoms, please seek in-person care.*";

const TREATMENT_EFFICACY_MD =
  "*Please note that your treatment plan will be designed based on the most up to date literature and Evvy’s clinical study on improving the vaginal microbiome and vaginal symptoms. That said, every person is different and there is always a chance that your specific microbiome or symptoms don’t significantly change within the 2 month treatment window.<br><br>Based on the first 500+ patients we’ve treated, __76% of patients report symptom improvement after going through Evvy’s treatment protocol.__ In case you still feel symptomatic after treatment, we will always support you in identifying appropriate next steps. This may include another test, another round of treatment, or referring you for additional testing or in person care.*";

const TITLE = "What comes next";

const IntakeIntro = ({
  consultIntake,
  submitPage,
  loading,
}: {
  consultIntake: ConsultIntake;
  submitPage: () => void;
  loading: boolean;
}) => {
  const [error, setError] = useState<string>("");
  const [checked, setChecked] = useState(
    Boolean(consultIntake?.terms_agreed_at)
  );
  const testHash = consultIntake?.consult?.test_hash;
  const [test, setTest] = useState<Test>();

  /* Effects */
  // First render
  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: consultIntake.consult.test_hash,
      section: "welcome",
    });
  }, [consultIntake.consult.test_hash, consultIntake.consult.uid]);

  useEffect(() => {
    const fetchTest = () => {
      testsService.fetchTest(
        testHash,
        (response: AxiosResponse) => {
          setTest(response.data);
        },
        (error: AxiosError) => {
          console.error(error);
          setError("Error fetching test data");
        }
      );
    };
    if (testHash) {
      fetchTest();
    }
  }, [testHash]);

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Helmet>
        <title>Care | Consult | Intake | Intro</title>
      </Helmet>
      {!test ? (
        <LoadingSpinner />
      ) : (
        <VaginitisCareIntakeIntro
          consult={consultIntake.consult}
          test={test}
          checked={checked}
          setChecked={setChecked}
          submitPage={submitPage}
          loading={loading}
          error={error}
        />
      )}
    </div>
  );
};

export default IntakeIntro;

const FIRST_TIME_TREATMENT_PROGRAM_STEPS = [
  {
    copy: "Answer questions about your medical history and symptoms to personalize your treatment program.",
    icon: Chat,
  },
  {
    copy: "Pay for your treatment program, after which an Evvy-affiliated provider will review and determine the right prescriptions just for you.",
    icon: Prescription,
  },
  {
    copy: `Any products prescribed by your provider will arrive at your door within in 4-6 business days.`,
    icon: ShippingBox,
  },
];

const PAY_FIRST_STEPS = [
  {
    copy: "Pay for your Rx products.",
    icon: Prescription,
  },
  {
    copy: "Answer questions about your medical history and symptoms.",
    icon: Chat,
  },
  {
    copy: "An Evvy-affiliated provider will review your request.",
    icon: FirstAid,
  },
  {
    copy: `Any products prescribed by your provider will arrive at your door within in 4-6 business days.`,
    icon: ShippingBox,
  },
];

const VaginitisCareIntakeIntro = ({
  consult,
  test,
  checked,
  setChecked,
  submitPage,
  loading,
  error,
}: {
  consult: Consult;
  test: Test;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  submitPage: () => void;
  loading: boolean;
  error: string;
}) => {
  const currentUser = useLoggedInUser();
  const isFirstTimeVaginitisCareUser =
    !currentUser.care.vaginitis.hasCompletedConsult;
  const stepsCopy =
    isFirstTimeVaginitisCareUser && consult.purchase_type === "v0-bundle"
      ? FIRST_TIME_TREATMENT_PROGRAM_STEPS
      : PAY_FIRST_STEPS;

  return (
    <div className="max-w-xl">
      <h3 className="text-center text-3xl sm:text-4xl">
        Get Clinical Care with Evvy
      </h3>
      <div className="bg-white pb-2 rounded-xl">
        <img
          src={PrescriptionBackgroundImage}
          className="rounded-t-xl"
          alt="presciption background"
        />
        <div className="relative -mt-7">
          <img
            src={ThreeDoctors}
            loading="lazy"
            alt="Evvy doctors"
            className="h-10 mx-auto relative z-10"
          />
          <div className="relative z-0 flex -mt-2.5">
            <div className="text-evvy-pine flex bg-evvy-white rounded-full mx-auto px-5 py-2.5 drop-shadow space-x-2">
              <Union className="w-5 h-5" />
              <div className="b3 semibold my-auto">
                Developed by leading OB-GYNS
              </div>
            </div>
          </div>
        </div>
        <div className="px-6">
          <div className="uppercase semibold tracking-wider text-sm text-center py-5">
            {TITLE}
          </div>
          <div className="sm:pt-1 text-left b1">
            {stepsCopy.map((step, index) => (
              <div
                key={index}
                className={`items-center flex ${
                  index < stepsCopy.length - 1 ? "mb-8" : ""
                }`}
              >
                <div className="flex-shrink-0 h-12 w-12 mr-5">
                  <step.icon className="h-full w-full" />
                </div>
                <div className="b1 regular">{step.copy}</div>
              </div>
            ))}
          </div>

          {/* efficacy disclaimer */}
          <div className="border-t border-evvy-black-dull pt-5 mt-7 sm:mt-8 text-left">
            <IntakeIntroEfficacyDisclaimer hasL1={test?.has_vpcr_test} />
          </div>
        </div>
      </div>

      {/* agree to terms */}
      <IntakeIntroAgreeToTermsCheckbox
        checked={checked}
        setChecked={setChecked}
      />

      {/* submit button */}
      <div className="flex cursor-pointer mt-4 mb-4 sm:mb-6 items-center">
        <button
          disabled={loading || !checked}
          onClick={submitPage}
          className="tracking-wider w-full sm:w-2/3 m-3 sm:mx-auto py-6 px-14 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none"
        >
          Let's get started →
        </button>
      </div>

      {error && <div className="text-red-500 text-center mb-4">{error}</div>}
    </div>
  );
};

export const IntakeIntroEfficacyDisclaimer = ({
  hasL1,
}: {
  hasL1: boolean;
}) => {
  const [showEfficacyDetails, setShowEfficacyDetails] = useState(false);

  return (
    <div>
      <div className="b3">
        <Markdown>
          {hasL1 ? L1_DISCLAIMER_COPY_MD : DISCLAIMER_COPY_MD}
        </Markdown>
      </div>
      <div className="mb-8">
        <div
          onClick={() => setShowEfficacyDetails(!showEfficacyDetails)}
          className="caption my-4 underline underline-offset-4 cursor-pointer"
        >
          a note on treatment efficiacy {showEfficacyDetails ? "-" : "+"}
        </div>
        {showEfficacyDetails && (
          <div className="b3">
            <Markdown>{TREATMENT_EFFICACY_MD}</Markdown>
          </div>
        )}
      </div>
    </div>
  );
};

export const IntakeIntroAgreeToTermsCheckbox = ({
  checked,
  setChecked,
}: {
  checked: boolean;
  setChecked: (checked: boolean) => void;
}) => {
  const policyLinkStyleClassName =
    "!underline underline-offset-2 uppercase text-[12px] font-semibold tracking-wider";

  return (
    <div className="flex mt-4 sm:mt-6 mb-4 px-4">
      <input
        checked={checked}
        onChange={() => setChecked(!checked)}
        id="terms"
        name="terms"
        type="checkbox"
        required
        className="mt-0.5 bg-evvy-cream rounded-sm border-evvy-blue cursor-pointer focus:outline-none focus:ring-evvy-blue active:ring-evvy-blue checked:bg-evvy-blue"
      />
      <label
        htmlFor="terms"
        className="block font-medium ml-2 text-sm text-left"
      >
        I agree to the{" "}
        <a
          href={PRIVACY_POLICY}
          className={policyLinkStyleClassName}
          target="_blank"
          rel="noreferrer"
        >
          privacy policy
        </a>
        ,{" "}
        <a
          href={TERMS_OF_SERVICE}
          className={policyLinkStyleClassName}
          target="_blank"
          rel="noreferrer"
        >
          terms of service
        </a>{" "}
        and{" "}
        <a
          href={TELEHEALTH_INFORMED_CONSENT}
          className={policyLinkStyleClassName}
          target="_blank"
          rel="noreferrer"
        >
          telehealth informed consent
        </a>
        .
      </label>
    </div>
  );
};
