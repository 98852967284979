// external
import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
// components
import CloseX from "../closeX";
import RectangularButton from "../../common/rectangularButton";
import LinkedText from "../../common/linkedText";
import { ReactComponent as EvvyTextLogo } from "../../plan/icons/evvy-logo-text.svg";
// lib
import {
  feedbackCopy as COPY,
  feedbackValidationCopy as VALIDATIONS,
} from "../../../pages/plan/constants.js";
import { secondaryColors } from "../../../utils/viz";

const FeedbackContentTemplate = ({ handleClose, handleSubmit }) => {
  /* State Management */
  const [submitted, setSubmitted] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  // error coming from feedback api
  const submitError = useSelector(
    (state) => state.plan.value.submitFeedbackError
  );

  /* Refs */
  const inputRef = useRef(null);

  /* Actions */
  const onClose = () => {
    setSubmitted(false);
    handleClose();
  };

  const onSubmit = (e) => {
    // stop page from refreshing on submit button click (keep button type submit for accessibility)
    e.preventDefault();
    // validate input
    validateInput();
  };

  const validateInput = () => {
    if (inputRef && inputRef.current) {
      // get feedback from text area
      const freeText = inputRef.current.value;
      // initialize errors array - for now only one possible error but in the future we may add more validations
      const errors = [];
      // check text length
      if (freeText.length < 3) errors.push(VALIDATIONS.LENGTH);
      // set validation messages state to array of errors
      setValidationErrors(errors);
      // if there are no errors, submit the text
      if (errors.length < 1) {
        submitFeedback(freeText);
      }
    }
  };

  const submitFeedback = (feedback) => {
    setSubmitted(true);
    handleSubmit(feedback);
  };

  return (
    <form
      className={`w-full flex flex-col items-center bg-evvy-white ${
        submitted ? "py-2 px-10" : "p-10"
      }`}
    >
      {/* close button */}
      <div className="flex justify-end items-center mb-6">
        <CloseX handleClose={onClose} textColorClass="text-evvy-black" />
      </div>
      {submitted && (
        <EvvyTextLogo
          className="mb-6"
          fill={secondaryColors["evvy-black"]}
          stroke={secondaryColors["evvy-black"]}
        />
      )}
      {!submitError && (
        <h5 className="w-full text-center">
          {submitted ? COPY.SUBMIT_TITLE : COPY.TITLE}
        </h5>
      )}
      <div className={`caption text-center ${submitted ? "" : "pt-5"}`}>
        {/* if user has clicked submit, display success text or error text depending on whether we receive an error from feedback api */}
        {submitted ? (
          submitError ? (
            <p>{COPY.SUBMIT_ERROR_CAPTION}</p>
          ) : (
            // set with dangerouslySetInnerHTML to allow strong tag styling of support email copy
            COPY.SUBMIT_CAPTION
          )
        ) : (
          // otherwise show text prompting user to submit feedback
          COPY.CAPTION
        )}
        {/* if there are errors append error messages */}
        {validationErrors &&
          validationErrors.map((msg, i) => (
            <p
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-2"
              role="alert"
              key={`msg-${i}`}
            >
              {msg}
            </p>
          ))}
      </div>
      {!submitted && (
        <textarea
          ref={inputRef}
          className="appearance-none block w-full h-36 p-6 mb-6 bg-evvy-cream rounded-xs border-none focus:outline-none focus:ring-transparent focus:border-none"
        />
      )}
      {!submitted && (
        <>
          <div className="mb-6">
            <RectangularButton
              noLeftMargin
              text="send"
              bgColorClass="bg-evvy-blue"
              textColorClass="text-evvy-black"
              buttonType="submit"
              onClick={onSubmit}
            />
          </div>
          <div>
            <LinkedText noTopMargin noIcon onClick={onClose}>
              Cancel
            </LinkedText>
          </div>
        </>
      )}
    </form>
  );
};

export default FeedbackContentTemplate;
