import { TreatmentGroups } from "../../../types/care";
import { usePanelCart } from "../../../hooks/cart/usePanelCart";
import { transparentCarePhaseColorMap } from "../../../utils/colors";
import TransparentPhaseHeader from "../transparentCare/TransparentPhaseHeader";
import ALaCareTreatmentCard from "./ALaCareTreatmentCard";

const ALaCareSectionHeader = () => {
  const header = "Explore Individual Rx Treatments";

  return (
    <>
      <h2 className="text-[40px]">{header}</h2>
      <div className="border-t border-dashed border-[#11161A33] mt-6 mb-14" />
    </>
  );
};

interface ALaCareProps {
  alacareTreatments?: TreatmentGroups;
}

const ALaCare: React.FC<ALaCareProps> = ({ alacareTreatments }) => {
  const { addToCart, setShowCart, getCartItemByTreatmentSlug } = usePanelCart();
  if (!alacareTreatments || Object.keys(alacareTreatments).length === 0)
    return null;

  return (
    <div className="bg-white px-3.5 sm:px-10 md:px-24 py-14">
      <ALaCareSectionHeader />
      {Object.entries(alacareTreatments)
        .filter(([_, details]) =>
          details.treatments.some((treatment) => treatment.eligible)
        )
        .map(([phase, details]) => (
          <div className="flex flex-col mb-10 sm:mb-14 last:mb-0" key={phase}>
            <TransparentPhaseHeader
              headerText={details.header_text}
              phaseColor={
                transparentCarePhaseColorMap[
                  phase as keyof typeof transparentCarePhaseColorMap
                ].opacity70
              }
              headerClassName="mb-3 sm:mb-4 text-2xl sm:text-3xl"
            />
            {details.sub_header_text && (
              <div className="text-base text-evvy-black mb-6 sm:mb-10">
                {details.sub_header_text}
              </div>
            )}
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 py-4">
              {details.treatments
                .filter((treatment) => treatment.eligible)
                .map((treatment) => (
                  <ALaCareTreatmentCard
                    key={treatment.slug}
                    treatment={treatment}
                    phase={phase}
                    addToCart={addToCart}
                    openCart={() => setShowCart(true)}
                    inCart={
                      getCartItemByTreatmentSlug(treatment.slug) ? true : false
                    }
                  />
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ALaCare;
