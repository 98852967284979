import { cn } from "../../../../utils/cn";
import Modal from "../../../common/modal";

interface OfframpModalProps {
  heading: string;
  onClose: () => void;
  children: React.ReactNode;
  onConfirm: (values: any) => void;
  buttonOptions: "single" | "double";
}

const OfframpModal = ({
  heading,
  onClose,
  children,
  onConfirm,
  buttonOptions,
}: OfframpModalProps) => {
  return (
    <Modal>
      <div className="p-[30px] flex flex-col items-center max-w-[500px]">
        <h6>{heading}</h6>
        <div className="mx-8">{children}</div>
        <div
          className={cn("flex gap-2 w-full mt-6", {
            "justify-between": buttonOptions === "double",
            "justify-center": buttonOptions === "single",
          })}
        >
          <button
            className={cn("t5 w-36 h-12 sm:w-64 sm:h-16", {
              "bg-evvy-blue": buttonOptions === "double",
              "border border-evvy-black": buttonOptions === "single",
            })}
            onClick={onConfirm}
          >
            {buttonOptions === "single" ? "Return Home" : "I Confirm"}
          </button>
          {buttonOptions === "double" && (
            <button
              className="t5 border border-evvy-black w-36 h-12 sm:w-64 sm:h-16"
              onClick={onClose}
            >
              Oops! Go back
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OfframpModal;
