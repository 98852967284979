import { getRequest, postRequest } from "../utils/axios";

export const planService = {
  fetchPlanOld,
  fetchPlan,
  submitFeedback,
};

// old plan view. will be replaced by new v2 versionf
function fetchPlanOld(onSuccess, onError) {
  // fetch plan info
  return getRequest(
    `/api/v1/my-plan-old/`,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function submitFeedback(hash, isHelpful, feedback, onSuccess, onError) {
  return postRequest(
    "/api/v1/my-plan/feedback/",
    {
      test_hash: hash,
      is_helpful: isHelpful,
      feedback: feedback,
    },
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

// new plan view
function fetchPlan(onSuccess, onError, recordViewedPlan = false) {
  // fetch plan info
  const apiUrl = "/api/v1/my-plan/";
  const url = recordViewedPlan ? `${apiUrl}?record_viewed_plan=true` : apiUrl;
  return getRequest(
    url,
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}
