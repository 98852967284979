import React from "react";
import Tippy from "@tippyjs/react/headless";

const VizToolip = ({ children, bacteria, percent, hidden, visible }) => {
  return (
    <>
      <span aria-describedby="tooltip">Bacteria percentage</span>
      <Tippy
        visible={visible}
        render={(attr) => (
          <div
            id="tooltip"
            role="tooltip"
            className={`bg-white rounded-md px-8 py-6 w-max max-w-screen-xs xs:max-w-fullshadow hidden ${
              hidden ? "md:hidden" : "md:block"
            }`}
            tabIndex={-1}
            {...attr}
          >
            <div className="flex items-center justify-between flex-grow t2">
              {/* bacteria name */}
              <div className="mr-6">{bacteria.short_name}</div>
              {/* bacteria percent */}
              <div>{percent}%</div>
            </div>
            <div id="arrow" data-popper-arrow />
          </div>
        )}
        animation={false}
      >
        {children}
      </Tippy>
    </>
  );
};

export default React.memo(VizToolip);
