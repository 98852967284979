import React, { useEffect } from "react";
import Modal from "../common/modal";
import CloseX from "../common/closeX";
import { Announcement } from "../../types/user";
import { sendViewedAnnouncements } from "../../utils/analytics/customEventTracking";
import { announcementService } from "../../services/announcements";

export const AnnouncementModal: React.FC<{
  handleClose: () => void;
  announcement?: Announcement;
}> = ({ handleClose, announcement }) => {
  const title = announcement?.title || "Message from the Evvy Team";
  const message = announcement?.message || "";

  useEffect(() => {
    if (announcement?.id) {
      // this case is for acknowledging an announcement has been viewed to the backend
      // it should only trigger when the announcement is not viewed to save on api requests
      if (!announcement?.viewed_at) {
        announcementService.handleViewAnnouncement(announcement?.id);
      }
      // this case is to trigger analytics when a user views an announcement (no matter if it has been viewed)
      sendViewedAnnouncements({ announcementId: announcement?.id });
    }
  }, [announcement?.id, announcement?.viewed_at]);

  if (!announcement) return null;

  return (
    <Modal closeModal={handleClose}>
      <div className="block bg-evvy-white p-10 text-center">
        <CloseX
          handleClose={handleClose}
          side="right"
          textColorClass="text-evvy-black-dull"
        />
        <h5>{title}</h5>
        <div className="text-left text-lg whitespace-pre-line">{message}</div>
      </div>
    </Modal>
  );
};
