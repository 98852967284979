import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Stethoscope } from "../../../../components/common/icons/stethoscope.svg";
import { ReactComponent as Pills } from "../../../../components/care/icons/pills.svg";
import { ReactComponent as Heart } from "../../../../components/care/icons/heart.svg";
import { ConsultIntake } from "../../../../types/care";
import {
  CONSULT_TURNAROUND_TIME,
  CONSULT_TURNAROUND_TIME_UNGATED,
} from "../../../../components/care/constants";
import { sendConsultIntakeViewSection } from "../../../../utils/analytics/customEventTracking";
import PageWrapper from "../../../../components/care/consultIntake/ungatedRx/PageWrapper";
import { evvyCareSupportCenterLink } from "../../../constants";

interface DoneProps {
  consultIntake: ConsultIntake;
}
const Done = ({ consultIntake }: DoneProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: consultIntake?.consult?.test_hash,
      section: "thank you",
      consultType: consultIntake?.consult?.type,
    });
  }, [consultIntake.consult?.test_hash, consultIntake.consult.uid]);

  const isUngatedRxConsult = consultIntake.consult?.type === "ungated-rx";

  return (
    <div className="block w-full mt-8 sm:mt-10 mb-4">
      <Helmet>
        <title>Care | Consult | Intake | Done</title>
      </Helmet>

      <div className="max-w-2xl px-4">
        <h3 className="flex-1 mr-2 text-center">You’re done!</h3>
        <img
          className="w-full"
          src="/images/graphics/assorted-treatments.png"
          loading="lazy"
          alt="RX Treatments"
        />
        <div className="flex-1 pt-8 pb-4 px-6 sm:px-8 bg-evvy-white rounded-b-2xl">
          <div className="uppercase mb-8 inline-block font-semibold tracking-wider text-sm">
            What’s next
          </div>

          <div className="flex flex-row items-center mb-8">
            <div className="w-12 h-12 mr-5 flex items-center justify-center bg-highlighter-mint rounded-full flex-shrink-0">
              <Stethoscope className="h-6 w-6 text-evvy-pine" />
            </div>
            <span className="leading-snug">
              An Evvy-affiliated provider will review your treatments and intake
              form within{" "}
              {isUngatedRxConsult
                ? CONSULT_TURNAROUND_TIME_UNGATED
                : CONSULT_TURNAROUND_TIME}
              . We'll reach out to you over email if there are any questions.
            </span>
          </div>

          <div className="flex flex-row items-center mb-8">
            <div className="w-12 h-12 mr-5 flex items-center justify-center bg-highlighter-mint rounded-full flex-shrink-0">
              <Pills className="h-6 w-6" />
            </div>
            <span className="leading-snug">
              Prescribed treatments will be shipped straight to your door (and
              typically arrive within 6 business days of purchase).
            </span>
          </div>

          <div className="flex flex-row items-center mb-4">
            <div className="w-12 h-12 mr-5 flex items-center justify-center bg-highlighter-mint rounded-full">
              <Heart className="h-6 w-6" />
            </div>
            <span className="leading-snug">
              In the meantime,{" "}
              <a
                href={evvyCareSupportCenterLink}
                target="_blank"
                className="underline persistSize"
                rel="noreferrer"
              >
                check out our FAQs
              </a>
              .
            </span>
          </div>
        </div>
      </div>
      <PageWrapper.FormFooter
        handleSubmit={() => {
          navigate("/care/");
        }}
        buttonCTA="Return to care"
      />
    </div>
  );
};
export default Done;
