import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import LayoutAuth from "../../components/auth/layout";

import { authService } from "../../services/auth";
import { getParameterByName } from "../../utils/urls";

const PasswordResetConfirm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token] = useState(getParameterByName("token"));
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [sentResetEmail, setSentResetEmail] = useState(null);

  // makes sure token is valid
  useEffect(() => {
    if (!token) {
      setError("Invalid password reset link");
      return;
    }

    setLoading(true);
    authService.checkPasswordTokenValidity(
      token,
      (response) => {
        setLoading(false);
        setTokenIsValid(true);
      },
      (error, response) => {
        setError(
          "This link is invalid or has expired. Please request a new one."
        );
        setLoading(false);
        setTokenIsValid(false);
      }
    );
  }, [token]); // Refetches user config when the access token is set

  // actions
  const submitForm = (e) => {
    e.preventDefault(); // to not post to the current url
    setLoading(true);
    setError(null);

    const password = e.target.elements["password"].value;

    authService.forgotPasswordConfirm(
      token,
      password,
      (response) => {
        setError(null);
        window.location = "/login/?successMessage=PASSWORD_RESET";
        // setLoading(false);  // no need, wait for redirect
      },
      (error, response) => {
        setError(
          (response && response.password && response.password[0]) ||
            "could not reset password"
        );
        setLoading(false);
      }
    );
  };

  return (
    <LayoutAuth
      metaTitle="Evvy - Password Reset Confirm"
      title="Reset your password"
    >
      <form className="space-y-6" action="#" onSubmit={submitForm}>
        {error ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-xs">
            {error}
          </div>
        ) : (
          ""
        )}

        {tokenIsValid ? (
          <React.Fragment>
            <div>
              <label htmlFor="password" className="block uppercase font-medium">
                New Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="off"
                  required
                  className="aappearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black focus:outline-none focus:ring-transparent focus:border-crazy-purple sm"
                />
              </div>
            </div>

            <div className="sm:flex items-center justify-between sm:flex-row-reverse">
              <button
                type="submit"
                className="cursor-pointer w-full sm:w-auto justify-center tracking-wider py-6 px-20 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
                disabled={loading}
              >
                {loading ? "Submit →" : "Submit →"}
              </button>
              <div className="mr-auto mt-4 sm:mt-0">
                <div></div>
              </div>
            </div>
            {/*
            <div className="flex items-center justify-between">
              <div className=">
                <Link to="/login/" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Log in
                </Link>
              </div>
            </div>*/}
          </React.Fragment>
        ) : (
          ""
        )}
      </form>
    </LayoutAuth>
  );
};

export default PasswordResetConfirm;
