/**
 * A styled card for the 'your plan' section (non-microbial steps)
 * @param {string} text a string of the non-highlighted title text
 * @param {string} highlightedText a string of highlighted title text
 * @param {string} description a string of the details for this insight
 * @param {string} researchRating an object containing a string key called 'title' whose value is a string of the status of the research (e.g. 'Emerging') to be passed onto the Research Item component
 * @param {string} link a string with the text label for the LinkedText component
 * @param {boolean} noBorder determines whether the card should not have bottom border (default is false, only true for last card)
 * @param {*} openModalWith callback to handle link click and open modal, passing along card data
 * @returns jsx of a card
 */

import React from "react";

// imported components
import Markdown from "markdown-to-jsx";

// components
import LinkedText from "../common/linkedText";
import HighlightedText from "./highlightedText";

// lib
import { modalTypes as MTYPES } from "../../pages/plan_unfurled/constants.tsx";
import { eventNames } from "../../utils/analytics/customEventTracking.js";
import { analyticsClickHandler } from "../../utils/analytics/helpers";

const SymptomCard = ({
  data,
  link = "Learn more",
  noBorder,
  openModalWith,
  analyticsEventArgs = null,
}) => {
  const {
    title = "",
    description = "",
    slug,
    get_group_display: groupName,
  } = data;
  // double pipe indicates division between part of title that should and should not be highlighted
  const [text = "", highlightedText = ""] = title.split("||") || ["", ""];
  // get first line of text (line breaks indicated by pipe)
  const [firstLine = ""] = description?.split("|") || [""];

  return (
    <div
      className={`w-full text-left text-evvy-black p-12 ${
        !noBorder ? "border-b border-evvy-pine" : ""
      }`}
    >
      <div className="t2 medium">
        <div>
          <span className="uppercase">{text[0]}</span>
          <span>{text.slice(1)} </span>
          {highlightedText && (
            <HighlightedText
              bgColorClass="bg-highlighter-mint"
              text={highlightedText}
            />
          )}
        </div>
      </div>
      {/* // only show first line of description */}
      <p className="mt-8">
        <Markdown>{firstLine}</Markdown>
      </p>
      <div className="text-evvy-pine">
        <LinkedText
          borderColorClass="border-evvy-pine"
          noTopMargin
          onClick={analyticsClickHandler({
            eventName: eventNames.CLICKED_LEARN_MORE,
            eventArgs: analyticsEventArgs,
            clickHandler: () =>
              openModalWith({
                type: MTYPES.RECOMMENDATION,
                data: Object.assign({ analyticsEventArgs }, data),
              }),
          })}
        >
          {link}
        </LinkedText>
      </div>
    </div>
  );
};

export default SymptomCard;
