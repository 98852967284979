import { FullStoryAPI } from "react-fullstory";

// disable fullstory if FullStoryAPI is undefined or if local development
const isFullStoryEnabled = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const impersonate = queryParams.get("__impersonate");
  if (impersonate) {
    console.log("Fullstory analytics disabled due to impersonation");
    return false;
  }
  return (
    typeof FullStoryAPI !== "undefined" &&
    window.location.hostname != "localhost"
  );
};

const DESTINATION_FULLSTORY = "fullstory";
const DESTINATION_KLAVIYO = "klaviyo";

// send custom event to fullstory
const trackCustomEvent = (
  eventName,
  eventProperties,
  analyticsDestinations = [DESTINATION_FULLSTORY]
) => {
  if (window.location.hostname == "localhost") {
    // log for local development
    console.log(eventName, eventProperties);
  }
  if (
    analyticsDestinations.includes(DESTINATION_FULLSTORY) &&
    isFullStoryEnabled()
  ) {
    FullStoryAPI("event", eventName, eventProperties);
  }

  if (
    analyticsDestinations.includes(DESTINATION_KLAVIYO) &&
    isKlaviyoEnabled()
  ) {
    // transform eventProperties object to split the key name on "_" and only take the preceding part before the underscore
    const transformedProperties = Object.keys(eventProperties).reduce(
      (acc, key) => {
        const [firstPart] = key.split("_");
        acc[firstPart] = eventProperties[key];
        return acc;
      },
      {}
    );
    window.klaviyo.push(["track", eventName, transformedProperties]);
  }
};

const isKlaviyoEnabled = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const impersonate = queryParams.get("__impersonate");
  if (impersonate) {
    console.log("Klaviyo analytics disabled due to impersonation");
    return false;
  }
  return (
    typeof window.klaviyo !== "undefined" &&
    window.location.hostname != "localhost"
  );
};

// identify user to fullstory
const identifyUser = (userConfig) => {
  // identify to fullstory
  if (userConfig && isFullStoryEnabled()) {
    const userId = `${userConfig.env?.name}-${userConfig.identity.id}`;
    FullStoryAPI("identify", userId, {
      environment: userConfig.env.name,
      is_staff: userConfig.identity.is_staff,
      evvy_user_id: userConfig.identity.id,
      is_member: userConfig.subscription?.is_member,
      account_created_date: userConfig.identity?.date_joined,
      total_test_count: userConfig.tests?.total_test_count,
      feature_toggles: userConfig.features,
      care: userConfig.care,
      experiments: userConfig?.experiments,
      is_provider: userConfig?.identity?.is_provider,
      is_provider_verified: userConfig?.provider_info?.is_verified,
    });
  }
  // identify to klaviyo
  if (userConfig && isKlaviyoEnabled()) {
    window.klaviyo.identify({
      email: userConfig.identity.email,
      $first_name: userConfig.identity.firstName,
      $last_name: userConfig.identity.lastName,
    });
  }
};

export {
  trackCustomEvent,
  identifyUser,
  DESTINATION_FULLSTORY,
  DESTINATION_KLAVIYO,
};
