import { FormikErrors } from "formik";
import { Link } from "react-router-dom";

const FormFooter = ({
  handleSubmit,
  errors,
  isLastPage,
  isDoneScreen,
  isSubmitting,
  disabled,
}: {
  handleSubmit: () => void;
  errors: FormikErrors<any>;
  isLastPage?: boolean;
  isDoneScreen?: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div className="z-100">
      <div className="border-t border-evvy-black border-opacity-20 bg-evvy-cream w-screen p-4 fixed bottom-0 left-0 flex justify-center">
        <div className="block sm:flex items-center">
          <div className="flex-auto text-center">
            {isDoneScreen ? (
              <Link to={`/tests/`} className="w-full  block mx-auto">
                <button
                  className="cursor-pointer tracking-wider w-64 sm:w-96 py-4 px-10 sm:px-20 font-semibold rounded-xl shadow-sm uppercase bg-evvy-blue focus:outline-none"
                  disabled={isSubmitting || disabled}
                  type="button"
                >
                  Go to my tests →
                </button>
              </Link>
            ) : (
              <button
                className="cursor-pointer tracking-wider w-64 sm:w-96 py-4 px-10 sm:px-20 font-semibold rounded-xl shadow-sm uppercase bg-evvy-blue focus:outline-none"
                onClick={handleSubmit}
                disabled={isSubmitting || disabled}
                type="button"
              >
                {isLastPage ? "Submit" : "Next"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormFooter;
