import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Modal from "../common/modal";

const TestLaterModal = ({ closeModal }) => {
  return (
    <Modal widthClass="w-7/12" closeModal={closeModal}>
      <div className="block md:flex p-12 pb-4">
        <div className="flex-1 mr-10">
          <h3>Are you sure you want to exit the testing process?</h3>
        </div>
        <div className="flex-1 flex flex-col">
          <p>
            Your recommendations will be most accurate if you take your sample
            at the same time you fill out your Health Context.
          </p>
          <p className="font-semibold">
            We strongly recommend finishing the testing process in one sitting!
          </p>
          {/*<div className="p-8 md:px-16">
            <span>Want to know more? </span>
            <span className="uppercase underline font-semibold tracking-wider">Learn why this matters</span> →
          </div>*/}
          <br />
          <br />
          <br />
          <br />
          <div>
            <button
              className="cursor-pointer w-full tracking-wider py-6 px-20 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
              onClick={closeModal}
            >
              Okay, Continue Testing
            </button>
            <Link
              to="/tests/"
              className="cursor-pointer w-full mt-4 text-center"
            >
              <div className="uppercase underline font-semibold tracking-wider uppercase p-10">
                ← Exit Testing
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TestLaterModal;
