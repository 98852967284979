const HealthProfileStart = ({
  isReturningUser,
}: {
  isReturningUser: boolean;
}) => {
  return (
    <div className="b2">
      <p>
        Your Health Context told us more about your vaginal health and goals.
        But vaginal health doesn’t happen in a vacuum — next, your Health
        Profile helps us understand your broader health and how it fits into the
        puzzle.
      </p>
      {isReturningUser ? (
        <p>
          Most questions have been prefilled with your answers from a previous
          test.{" "}
          <b>
            Please read carefully and update any answers that may have changed.
          </b>
        </p>
      ) : (
        <p>
          Once you’ve filled this out the first time, you’ll only need to verify
          or update your information for all future tests.
        </p>
      )}
    </div>
  );
};

export default HealthProfileStart;
