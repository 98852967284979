import { cn } from "../../utils/cn";
import { ReactComponent as AlertTriangle } from "./icons/alert-triangle.svg";

const ErrorBanner: React.FC<{
  children: React.ReactNode;
  parentAdditionalClassName?: string;
}> = ({ children, parentAdditionalClassName = "" }) => {
  return (
    <div
      className={cn(
        "bg-coral/70 p-4 flex my-auto items-center rounded-lg",
        parentAdditionalClassName
      )}
    >
      <AlertTriangle className="stroke-evvy-black h-4 w-5 mr-1" />
      <span className="b2 medium">{children}</span>
    </div>
  );
};

export default ErrorBanner;
