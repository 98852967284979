import { useState, useEffect, useCallback } from "react";
import { testsService } from "../../services/tests";
import { TestTrendSummary } from "../../types/test";

export const useProblemsSummary = (testHash?: string) => {
  const [data, setData] = useState<TestTrendSummary>();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  const fetchProblemsSummary = useCallback(async () => {
    if (testHash) {
      setIsLoading(true);
      try {
        const response = await testsService.fetchTestProblemsSummary(testHash);
        setData(response.data);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [testHash]);

  useEffect(() => {
    if (testHash) fetchProblemsSummary();
  }, [testHash, fetchProblemsSummary, refetch]);

  const refetchProblemsSummary = () => {
    setRefetch((prev) => !prev);
  };

  return {
    testSummary: data,
    error,
    isLoading,
    refetchProblemsSummary,
  };
};
