// downloads a raw file contents
const downloadFile = (fileContents, fileName, mimeType) => {
  const blob = new Blob([fileContents], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export { downloadFile };
