import classNames from "classnames";
import { ConsultForTreatmentPlan } from "../../types/care";
import { getDateDisplayFromDateStr } from "../../utils/datetime";
import { getQuestionTitle } from "../../utils/questions";
import { ReactComponent as Info } from "../plan_unfurled/icons/info.svg";
import Tippy from "@tippyjs/react";

const SymptomReliefProblemsModule = ({
  consult,
}: {
  consult?: ConsultForTreatmentPlan;
}) => {
  const consultSubmittedDate = consult?.intake_submitted_at
    ? getDateDisplayFromDateStr(consult.intake_submitted_at)
    : null;
  const symptoms = consult?.symptoms || [];

  return (
    <div>
      <div className="bg-evvy-cream rounded-xl p-4 sm:p-5 space-y-4">
        <div>
          <div className="t4">Your microbiome summary</div>
          {consultSubmittedDate && (
            <div className="b3 medium text-evvy-black-dull">{`Based on your intake from ${consultSubmittedDate}`}</div>
          )}
        </div>
        {/* Symptoms */}
        <div className="flex items-center">
          <div className="t4">Your symptoms</div>
          <Tippy
            arrow
            render={() => (
              <div
                className="flex bg-evvy-pine p-3 rounded-md max-w-sm"
                tabIndex={-1}
              >
                <div className="text-evvy-white">
                  These are the symptoms you shared in your intake form.
                </div>
              </div>
            )}
            placement="bottom"
            animation={false}
          >
            <Info
              stroke="currentColor"
              fill="currentColor"
              className="ml-1.5 w-4 h-4 text-evvy-black-dull/75"
            />
          </Tippy>
        </div>
        {symptoms && (
          <div className="flex flex-wrap">
            {symptoms.map((condition, i) => (
              <div
                key={`condition-${i}`}
                className={classNames(
                  "rounded-full py-1 px-2.5 text-sm sm:text-base medium text-center bg-evvy-dark-beige mb-2 mr-1.5"
                )}
              >
                {getQuestionTitle(condition)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SymptomReliefProblemsModule;
