import React, { useRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import { getQuestionTitle } from "../../../../utils/questions";

// components
import BlueRectangularButton from "../../../../components/common/blueRectangularButton";
import UpdateReminderMsg from "../../../../components/care/consultIntake/updateReminderMessage";

// analytics utils
import { sendConsultIntakeViewSection } from "../../../../utils/analytics/customEventTracking";
import { ConsultIntake } from "../../../../types/care";
import { getError } from "./utils";

const DemographicsQuestions = ({
  consultIntake,
  submitPage,
  loading,
}: {
  consultIntake: ConsultIntake;
  submitPage: (
    data: { height: number; weight: number },
    onError: (error: any) => void
  ) => void;
  loading: boolean;
}) => {
  const [error, setError] = useState("");
  const [apiErrors, setApiErrors] = useState({});

  // for tooltips
  const heightQuestion = useRef<HTMLDivElement>(null);
  const weightQuestion = useRef<HTMLDivElement>(null);

  const allQuestions = {
    height: heightQuestion,
    weight: weightQuestion,
  };

  const consult = consultIntake.consult;

  /* Effects */
  // First render
  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consult?.uid,
      testHash: consult?.test_hash,
      section: "demographics",
      consultType: "sti",
    });
  }, [consult.test_hash, consult.uid]);

  // utils
  const validateWeight = (weight: number) => {
    let errorMessage;
    if (weight && (weight < 50 || weight > 500)) {
      errorMessage = "Weight out of range";
    }
    return errorMessage;
  };

  const validateHeight = (feet: number, inches: number) => {
    let errorMessage;
    if (inches > 11) {
      errorMessage = "Inches must be less than 12";
    }
    const height = feet * 12 + inches;
    if (height && (height < 36 || height > 108)) {
      errorMessage = "Height out of range";
    }
    return errorMessage;
  };

  const populateInitialValues = () => {
    return {
      height_feet: consultIntake.height
        ? Math.round(consultIntake.height / 12)
        : consultIntake?.past_health_history?.demographics_height
        ? Math.round(consultIntake.past_health_history.demographics_height / 12)
        : 0,
      height_inches: consultIntake?.height
        ? Math.round(consultIntake.height % 12)
        : consultIntake?.past_health_history?.demographics_height
        ? Math.round(consultIntake.past_health_history.demographics_height % 12)
        : 0,
      weight:
        consultIntake?.weight ||
        consultIntake?.past_health_history?.demographics_weight ||
        0,
    };
  };

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Helmet>
        <title>Care | Consult | STI Intake | Demographics</title>
      </Helmet>
      <div className="max-w-2xl px-4">
        <h3 className="text-center">Demographics</h3>
        {error ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
            {error}
          </div>
        ) : (
          ""
        )}
        {/* message reminding user to review their answers */}
        {consultIntake?.past_health_history && !consultIntake?.submitted_at ? (
          <UpdateReminderMsg />
        ) : (
          ""
        )}
        <Formik
          initialValues={populateInitialValues()}
          validate={(values) => {
            var errors = {} as {
              height_feet?: string;
              height_inches?: string;
              weight?: string;
              height?: string;
            };
            Object.keys(allQuestions).forEach((key) => {
              const typeKey = key as keyof typeof allQuestions;
              const current = allQuestions[typeKey].current;
              if (current) {
                ReactTooltip.hide(current);
              }
            });

            // required questions
            var requiredQuestions = ["height_feet", "height_inches", "weight"];
            requiredQuestions.forEach((key) => {
              const typeKey = key as keyof typeof values;

              if (!values[typeKey] && values[typeKey] !== 0) {
                // note that 0 is a perfectly fine answer (height inches)
                const errorKey = ["height_feet", "height_inches"].includes(
                  typeKey
                )
                  ? "height"
                  : "weight"; // convert this combined field
                errors[errorKey] = "This is a required question";
                const current = allQuestions[errorKey].current;
                if (current) {
                  ReactTooltip.show(current); // manually show, without requiring hover
                }
              }
            });

            // validate weight
            const errorMsg = validateWeight(values["weight"]);
            if (errorMsg) {
              errors["weight"] = errorMsg;
              ReactTooltip.rebuild();
              const current = allQuestions["weight"].current;
              if (current) {
                ReactTooltip.show(current);
              }
            }
            // validate height
            const errorHeightMsg = validateHeight(
              values["height_feet"],
              values["height_inches"]
            );
            if (errorHeightMsg) {
              errors["height"] = errorHeightMsg;
              ReactTooltip.rebuild();
              const current = allQuestions["height"].current;
              if (current) {
                ReactTooltip.show(current);
              }
            }

            return errors;
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            setError("");
            const newValues = {
              height: values["height_feet"] * 12 + values["height_inches"],
              weight: values["weight"],
            };

            submitPage(newValues, (response) => {
              if (typeof response === "object") {
                setApiErrors(response);
                Object.keys(response).forEach((k) => {
                  const typeK = k as keyof typeof allQuestions;
                  const current = allQuestions[typeK].current;
                  if (current) {
                    ReactTooltip.rebuild();
                    ReactTooltip.show(current);
                  }
                });
              } else {
                setError(response || "Error saving consult intake");
              }
            });
          }}
        >
          {({ errors, handleSubmit }) => (
            <Form>
              <div className="flex-1 bg-evvy-white p-5 sm:p-8 mb-3 rounded-lg">
                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("height", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("height", errors, apiErrors)}
                  data-for="height"
                  ref={heightQuestion}
                >
                  <ReactTooltip
                    id="height"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("demographics_height")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <div className="flex">
                    <div className="mr-4">
                      <label>Feet</label>
                      <Field
                        name="height_feet"
                        type="number"
                        className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                        autoComplete="off"
                      />
                    </div>
                    <div>
                      <label>Inches</label>
                      <Field
                        name="height_inches"
                        type="number"
                        className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("weight", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("weight", errors, apiErrors)}
                  data-for="weight"
                  ref={weightQuestion}
                >
                  <ReactTooltip
                    id="weight"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("demographics_weight")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <div>
                    <Field
                      name="weight"
                      type="integer"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              <div>
                {errors && Object.keys(errors).length > 0 ? (
                  <div className="bg-coral p-2 px-3 font-medium rounded-md my-4">
                    Please fix the errors above to continue
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="flex mt-6">
                <BlueRectangularButton
                  text="Continue"
                  paddingXClass="sm:px-32"
                  disabled={loading}
                  onClick={handleSubmit}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DemographicsQuestions;
