import React, { useState } from "react";

// This wraps a DOM element and detects when user makes sertain swipe actions
const DetectSwipe = ({ children, className, onSwipeLeft, onSwipeRight }) => {
  // state
  const minSwipeDistance = 50; // the required distance between touchStart and touchEnd to be detected as a swipe
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // actions
  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    // handle the swipe
    if (isLeftSwipe && onSwipeLeft) {
      return onSwipeLeft();
    } else if (isRightSwipe && onSwipeRight) {
      return onSwipeRight();
    }
    if (isLeftSwipe || isRightSwipe)
      console.log("swipe", isLeftSwipe ? "left" : "right");
  };

  return (
    <div
      className={className}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {children}
    </div>
  );
};

export default DetectSwipe;
