// external
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { range } from "d3";
import classNames from "classnames";

// imported components
import Markdown from "markdown-to-jsx";

// components
import TimelineNumber from "../timelineNumber";
import Tag from "../tag";
import ArrowButton from "../arrowButton";
import CloseX from "../../common/closeX";
import Citations from "../../common/citations";
// lib
import { researchCopy } from "../../../pages/plan_unfurled/constants.tsx";
import { setPlanModalStep } from "../../../pages/plan/planSlice";
import { analyticsClickHandler } from "../../../utils/analytics/helpers";
import { eventNames } from "../../../utils/analytics/customEventTracking";
import { getShopUrlFromCareProduct } from "../../../components/care/utils";

const RecommondationContentTemplate = ({ data, handleClose }) => {
  const { microbialActions = [], analyticsEventArgs, aLaCareEligible } = data;
  /* Redux */
  // redux dispatch method for updating global store
  const dispatch = useDispatch();

  // get data from store about whether which plan item step to show & highlight
  const activeStep = useSelector((state) => state.plan.value.planModalStep);

  const [microbialAction, setMicrobialAction] = useState(
    microbialActions[activeStep - 1]
  );

  useEffect(() => {
    setMicrobialAction(microbialActions[activeStep - 1]);
  }, [activeStep]);

  const {
    tag = false,
    title = "",
    description = "",
    evvyproductrecommendation_set: evvyRecommendations,
    planitemrecommendation_set: recommendations,
    research_rating: researchRating,
    planitemcitation_set: citations,
    slug,
    get_group_display: groupName,
  } = microbialAction || data;

  let filteredExternalRecommendations = recommendations;
  if (evvyRecommendations?.length > 0 && aLaCareEligible) {
    // If there are Evvy product recommendations for this plan profile, filter out ones that may be duplicate products.
    filteredExternalRecommendations = recommendations.filter(
      (recommendation) => {
        return !recommendation.hide_if_eligible_for_a_la_care;
      }
    );
  }

  return (
    <div className="w-full bg-evvy-white px-4 py-8 md:p-8">
      {/* close button */}
      <div className="flex justify-end items-center">
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>

      {tag && (
        <>
          {/* timeline indicator row */}
          <div className="flex space-x-4">
            {range(1, microbialActions?.length + 1).map((number, i) => (
              <button
                onClick={analyticsClickHandler({
                  eventName: eventNames.CLICKED_CIRCLE_NAV,
                  eventArgs: analyticsEventArgs,
                  clickHandler: () => dispatch(setPlanModalStep(number)),
                })}
                key={`recommend-step-${i}`}
              >
                <TimelineNumber
                  number={number}
                  outlined={number != activeStep}
                />
              </button>
            ))}
          </div>
          {/* step tag - only for ~Microbial~ steps*/}
          <div className="mt-8">
            <Tag tag={tag} />
          </div>
        </>
      )}
      {/* step title  - remove double pipes from title */}
      <div className="t1 my-6">
        <Markdown>{title && title.replace(/\|\|/g, " ")}</Markdown>
      </div>
      <hr />
      {/* step details  - new line at pipe indicator*/}
      <div className="my-6">
        {description &&
          description.split("|")?.map((descriptionItem, i) => (
            <p key={`reccomend-desc-${i}`}>
              <Markdown>{descriptionItem}</Markdown>
            </p>
          ))}
      </div>
      {/* if there are recommendations, add section */}
      {aLaCareEligible && evvyRecommendations?.length > 0 && (
        <>
          <div className="t3 text-evvy-pine">
            {"Available through Evvy Care"}
          </div>
          <p className="mt-4">{researchCopy.EVYV_RECOMMENDATIONS}:</p>
          {/* research link buttons */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-6">
            {evvyRecommendations.map(({ display_text, evvy_product }, i) => (
              <ArrowButton
                href={getShopUrlFromCareProduct(evvy_product)}
                text={display_text}
                onClick={analyticsClickHandler({
                  eventName: eventNames.CLICKED_RECOMMENDATION,
                  eventArgs: {
                    ...analyticsEventArgs,
                    recommendationTitle: display_text,
                    aLaCareEligible,
                  },
                })}
                key={`arrow-${i}`}
              />
            ))}
          </div>
        </>
      )}
      {/* if there are external recommendations (non-evvy products) after filtering for a la care, add section */}
      {filteredExternalRecommendations?.length > 0 && (
        <div
          className={classNames({ "mt-6": evvyRecommendations?.length > 0 })}
        >
          <div className="t3 text-evvy-pine">{"Our Recommendations"}</div>
          <p className="mt-4">{researchCopy.RECOMMENDATIONS}:</p>
          {/* research link buttons */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-6">
            {filteredExternalRecommendations?.map(
              ({ display_text, url }, i) => (
                <ArrowButton
                  href={url}
                  text={display_text}
                  onClick={analyticsClickHandler({
                    eventName: eventNames.CLICKED_RECOMMENDATION,
                    eventArgs: {
                      ...analyticsEventArgs,
                      recommendationTitle: display_text,
                      aLaCareEligible,
                    },
                  })}
                  key={`arrow-${i}`}
                />
              )
            )}
          </div>
        </div>
      )}
      {/* research grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mt-10">
        {/* research */}
        <div className="">
          <div className="t3 text-evvy-pine">Research</div>
          <div className="py-3 my-4 border-b border-t border-evvy-pine/15">
            <p className="text-evvy-pine mb-1">
              <span className="medium">Status: </span>
              <span>{researchRating?.title}</span>
            </p>
            <div className="caption">{researchRating?.description}</div>
          </div>
          <div className="caption">{researchCopy.RESEARCH}</div>
        </div>
        {/* sources  (if there are any) */}
        {citations && citations.length > 0 && (
          <Citations citations={citations} eventArgs={analyticsEventArgs} />
        )}
      </div>
    </div>
  );
};

export default RecommondationContentTemplate;
