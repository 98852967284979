import React from "react";

import { IntercomProvider } from "react-use-intercom";

import { timeStampToUnix } from "../../utils/datetime";
import { User } from "../../types/user";
import { useOptionalUser } from "../../hooks/useOptionalUser";

const INTERCOM_APP_ID = "c5ccqnec";

type BootProps = {
  name?: string;
  email?: string;
  created_at?: number;
};

export const IntercomWidget = ({
  bootProps,
  children,
}: {
  bootProps?: BootProps;
  children: React.ReactNode;
}) => {
  const currentUser = useOptionalUser();

  if (!currentUser) {
    return <>{children}</>;
  }

  // default intercom user props, which can be overriden with bootProps
  const defaultBootProps = getDefaultBootPropsFromUser(currentUser);
  const bootPropsToUse = bootProps || defaultBootProps;

  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      autoBoot
      autoBootProps={bootPropsToUse}
    >
      {children}
    </IntercomProvider>
  );
};

const getDefaultBootPropsFromUser = (user: User) => {
  return {
    name: `${user.identity.firstName} ${user.identity.lastName}`,
    email: user.identity.email,
    created_at: String(timeStampToUnix(new Date(user.identity.dateJoined))),
  };
};
