import { useState, useEffect, useCallback } from "react";
import { testsService } from "../../../services/tests";
import { TransparentCareRecommendations } from "../../../types/care";

export const useTransparentCareTreatments = (testHash?: string) => {
  const [data, setData] = useState<TransparentCareRecommendations>();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  const fetchTransparentCareTreatments = useCallback(async () => {
    if (testHash) {
      setIsLoading(true);
      try {
        const response =
          await testsService.fetchTestTransparentCareRecommendations(testHash);
        setData(response.data);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [testHash]);

  useEffect(() => {
    if (testHash) {
      fetchTransparentCareTreatments();
    } else {
      setIsLoading(false);
    }
  }, [testHash, fetchTransparentCareTreatments, refetch]);

  const refetchTransparentCareTreatments = () => {
    setRefetch((prev) => !prev);
  };

  return {
    transparentCareTreatments: data,
    error,
    isLoading,
    refetchTransparentCareTreatments,
  };
};
