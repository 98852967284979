import { useState, useRef } from "react";
import { Helmet } from "react-helmet";

import ErrorBoundary from "../../../components/common/errorBoundary";
import Modal from "../../../components/common/modal";
import LinkedText from "../../../components/common/linkedText";
import BlueRectangularButton from "../../../components/common/blueRectangularButton";

import { ReactComponent as Headshot } from "../../../components/care/icons/headshot.svg";
import { ReactComponent as PhotoID } from "../../../components/care/icons/photo-id.svg";
import {
  ConfirmNameDOB,
  TakePhotoModal,
} from "../../../components/care/consultIntake/";

// services
import { authService } from "../../../services/auth";
import { accountService } from "../../../services/account";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import PageWrapper from "../../../components/care/consultIntake/ungatedRx/PageWrapper";

const IdPhotoUpload = ({
  consultIntake,
  submitPage,
  updateConsultIntake,
  loading,
  redo,
  hideHeader = false,
  useStickyFooter = false,
}) => {
  const [selfiePhotoUrl, setSelfiePhotoUrl] = useState(
    consultIntake?.selfie_image_url
  );
  const [idPhotoUrl, setIdPhotoUrl] = useState(consultIntake?.id_image_url);
  const [modalType, setModalType] = useState(null); // used to set what type of photo is being uploaded for the take photo modal
  const [uploadingPhoto, setUploadingPhoto] = useState(false); // to be used for take photo modal loading state
  const [error, setError] = useState(null);
  const [submittingDOB, setSubmittingDOB] = useState(false);
  const disablePhotoUpload = consultIntake?.submitted_at && !redo;

  const currentUser = useLoggedInUser();

  const helmetTitleSuffix = redo ? "Reupload" : "Photo";

  const formRef = useRef(null);

  /*
   * Actions
   */
  // submit updated name and DOB info
  const submitNameDOB = (event) => {
    if (event) {
      event.preventDefault();
    }

    const form = formRef.current;
    if (!form) return;

    setSubmittingDOB(true);
    // allow user to continue if intake is already submitted and we are redoing photo upload
    if (consultIntake.submitted_at && !redo) {
      // if intake is already submitted, we don't need to update name and DOB
      submitPage({});
      setSubmittingDOB(false);
      return;
    }

    accountService.updateAccount(
      {
        first_name: form.first_name.value,
        last_name: form.last_name.value,
        birthday: form.birthday.value,
      },
      (response) => {
        // lets update stored user config and then continue to next page
        authService.fetchUser(() => {
          // Updating intake is handled when photo is uploaded,
          // so submit no data to consult intake, just continue to next page
          setSubmittingDOB(false);
          submitPage({});
        });
      },
      (error, response) => {
        console.error(error);
        setError(response || "Error updating name and DOB");
        setSubmittingDOB(false);
      }
    );
  };

  // udates consult intake with photo
  const uploadPhotoToIntake = (photo, photoType) => {
    setUploadingPhoto(true);
    if (!modalType) {
      openModal(photoType);
    }
    setError(null);
    const data = {};
    if (photoType === "selfie") {
      data.selfie_image_file = photo;
    } else if (photoType === "id") {
      data.id_image_file = photo;
    } else {
      // invalid photo type
      setUploadingPhoto(false);
      setError("Invalid photo type");
      return;
    }
    updateConsultIntake(
      data,
      (respData) => {
        setUploadingPhoto(false);
        if (photoType === "selfie") {
          setSelfiePhotoUrl(respData?.selfie_image_url);
        }
        if (photoType === "id") {
          setIdPhotoUrl(respData?.id_image_url);
        }
        closeModal();
      },
      (errResp) => {
        setUploadingPhoto(false);
        console.error(errResp);
        setError("Error uploading photo: " + errResp);
      }
    );
  };

  // open take-photo modal for specified photo type
  const openModal = (photoType) => {
    setModalType(photoType);
    setError(null);
  };

  // close take-photo modal and clear photo type for modal
  const closeModal = () => {
    setModalType(null);
  };

  // remove displayed photo to allow new photo to be uploaded
  // (does not delete photo from backend, just from current view)
  const clearPhoto = (photoType) => {
    if (photoType === "selfie") {
      setSelfiePhotoUrl(null);
    } else {
      setIdPhotoUrl(null);
    }
  };

  // upload photo from file selector, instead of from take-photo modal
  const handlePhotoUploadFromFile = (event, photoType) => {
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      uploadPhotoToIntake(reader.result, photoType);
    };
    reader.onerror = (error) => {
      setError("Error uploading file");
    };
  };

  // upload photo from file selector for selfie photo type
  const handleSelfiePhotoUploadFromFile = (event) => {
    handlePhotoUploadFromFile(event, "selfie");
  };

  // upload photo from file selector for id photo type
  const handleIDPhotoUploadFromFile = (event) => {
    handlePhotoUploadFromFile(event, "id");
  };

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Helmet>
        <title>{`Care | Consult | ${
          consultIntake.consult.type === "sti" ? "STI Intake" : "Intake"
        } | Identity Verification | ${helmetTitleSuffix}`}</title>
      </Helmet>
      {modalType && (
        <ErrorBoundary>
          <Modal closeModal={closeModal} widthClass="w-full max-w-xl">
            <TakePhotoModal
              photoType={modalType}
              uploadPhoto={uploadPhotoToIntake}
              handleClose={closeModal}
              uploading={uploadingPhoto}
              error={error}
            />
          </Modal>
        </ErrorBoundary>
      )}
      <div className="max-w-3xl px-4">
        {!hideHeader && <h3 className="text-center">Identity Verification</h3>}
        {error && (
          <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
            {error}
          </div>
        )}
        <div className="flex-1 bg-evvy-white p-5 sm:p-10 mt-5 mb-3 rounded-lg border-b">
          <div className="grid grid-cols-4 gap-6 text-center">
            <div className="col-span-4 sm:col-span-2 mb-2">
              <div className="b1 medium">1. Add a photo of yourself</div>
              <div className="b3 p-2">
                Make sure the photo is clear and well-lit, and that there is
                nothing in front of your face.
              </div>
              {selfiePhotoUrl ? (
                <img
                  src={selfiePhotoUrl}
                  alt="Selfie"
                  loading="lazy"
                  className="mx-auto mb-2"
                />
              ) : (
                <label>
                  <div className="block bg-evvy-silverfish p-10 mb-4 mt-2 rounded-lg cursor-pointer">
                    <Headshot className="mx-auto mb-4 cursor-pointer" />
                    <div className="font-semibold text-[#445C89] cursor-pointer">
                      Click here to upload
                    </div>
                    <input
                      id="selfie-file"
                      type="file"
                      accept=".bmp,.jpeg,.jpg,.png"
                      className="hidden"
                      onChange={handleSelfiePhotoUploadFromFile}
                    />
                  </div>
                </label>
              )}
              <LinkedText
                disabled={disablePhotoUpload}
                onClick={
                  selfiePhotoUrl
                    ? () => clearPhoto("selfie")
                    : () => openModal("selfie")
                }
                noIcon
                noTopMargin
              >
                {selfiePhotoUrl ? "Add New Photo" : "Take Photo"}
              </LinkedText>
            </div>
            <div className="col-span-4 sm:col-span-2">
              <div className="b1 medium">2. Add a photo of your ID</div>
              <div className="b3 p-2">
                Ensure that your name, date of birth, and photo are visible.
              </div>
              {idPhotoUrl ? (
                <img
                  src={idPhotoUrl}
                  alt="ID Photograph"
                  loading="lazy"
                  className="mx-auto mb-2"
                />
              ) : (
                <label>
                  <div className="block bg-evvy-silverfish p-10 mb-4 mt-2 rounded-lg cursor-pointer">
                    <PhotoID className="mx-auto mb-4 cursor-pointer" />
                    <div className="font-semibold text-[#445C89] cursor-pointer">
                      Click here to upload
                    </div>
                    <input
                      id="id-file"
                      type="file"
                      accept=".bmp,.jpeg,.jpg,.png"
                      className="hidden"
                      onChange={handleIDPhotoUploadFromFile}
                    />
                  </div>
                </label>
              )}
              <LinkedText
                disabled={disablePhotoUpload}
                onClick={
                  idPhotoUrl ? () => clearPhoto("id") : () => openModal("id")
                }
                noIcon
                noTopMargin
              >
                {idPhotoUrl ? "Add New Photo" : "Take Photo"}
              </LinkedText>
            </div>
          </div>
        </div>
        <form ref={formRef} action="#" onSubmit={submitNameDOB}>
          {/* Only show this component after they finished uploading their ID and selfie photo */}
          {selfiePhotoUrl && idPhotoUrl && (
            <ConfirmNameDOB
              firstName={currentUser.identity.firstName}
              lastName={currentUser.identity.lastName}
              birthday={currentUser.identity.birthday}
            />
          )}
          {useStickyFooter ? (
            <>
              <div className="h-16" />
              <PageWrapper.FormFooter
                isSubmitting={loading}
                handleSubmit={submitNameDOB}
                buttonCTA="Next"
                disabled={loading}
              />
            </>
          ) : (
            <div className="flex mt-6 mb-4">
              <BlueRectangularButton
                text="Continue"
                type="submit"
                paddingXClass="px-32"
                disabled={
                  loading || submittingDOB || !selfiePhotoUrl || !idPhotoUrl
                }
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default IdPhotoUpload;
