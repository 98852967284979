import { Link, useParams } from "react-router-dom";

const InvalidForm = () => {
  const { testHash: hash } = useParams();

  return (
    <div className="p-4 bg-coral rounded-md flex">
      <div>This is an invalid Health Context url</div>
      <Link
        to={`/tests/${hash}/context/`}
        className="ml-auto tracking-wider text-sm font-semibold cursor-pointer hover:underline"
      >
        Go back to safety
      </Link>
    </div>
  );
};

export default InvalidForm;
