import { FC } from "react";

export const SimpleDropdownSelect: FC<{
  value?: string;
  optionList: { value: string; label: string }[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  classNameAddition?: string;
}> = ({ value, optionList, onChange, classNameAddition = "" }) => {
  return (
    <div className="relative w-full">
      <select
        onChange={onChange}
        className={`${classNameAddition} appearance-none cursor-pointer bg-none bg-transparent rounded-md border-solid border-evvy-blue pl-4 pr-8 py-4 w-full whitespace-nowrap`}
        value={value}
      >
        {/* place holder empty option */}
        <option key="" value="" disabled selected={!value}>
          {"Select..."}
        </option>
        {optionList.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-3 flex items-center text-evvy-blue text-lg">
        ↓
      </div>
    </div>
  );
};
