import React from "react";

const ClinicianMessage = ({ message, prevMessage }) => {
  const prevDate = prevMessage
    ? new Date(Date.parse(prevMessage.created_at)).toLocaleDateString("en-US")
    : "";
  const currDate = message
    ? new Date(Date.parse(message.created_at)).toLocaleDateString("en-US")
    : "";
  const dateToShow =
    currDate && !prevDate
      ? currDate
      : currDate && prevDate && currDate !== prevDate
      ? currDate
      : "";

  return (
    <React.Fragment>
      {dateToShow && (
        <div className="b3 regular text-gray-500 pt-8">{dateToShow}</div>
      )}
      <div className="flex justify-start my-6 py-3" key={message?.id}>
        <div className="w-10/12 sm:w-3/5 relative">
          <div className="px-9 py-7 w-full bg-evvy-cream rounded-xl text-left">
            <div className="pb-6 b1 whitespace-pre-wrap border-b border-gray-300">
              {message.text}
            </div>
            <div className="pt-6 flex content-start">
              <div className="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-evvy-pine rounded-full">
                <span className="text-lg font-semibold text-evvy-cream">
                  {message.user_initials}
                </span>
              </div>
              <div className="pl-4">
                <div className="b2 sm:b1 semibold sm:regular">
                  {message.user_display_name}
                </div>
                <div className="b3 regular">Your Evvy-affiliated Provider</div>
              </div>
            </div>
          </div>
          <div className="mx-auto b3 absolute left-0 pt-1 text-gray-500">
            {new Date(Date.parse(message.created_at)).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ClinicianMessage;
