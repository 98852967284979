import React from "react";

const AlreadySubmitted = ({}) => {
  return (
    <div className="bg-coral p-2 px-3 font-medium rounded-md my-6">
      You are viewing the answers that you submitted on the day of your test.
      These answers have been submitted and any changes you make will not be
      saved.
    </div>
  );
};

export default AlreadySubmitted;
