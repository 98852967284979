import { useContext } from "react";
import { AuthContext } from "../contexts/authContext";

export const useIdentity = () => {
  const authContext = useContext(AuthContext);
  const currentUser = authContext.currentUser;
  if (!currentUser) {
    throw new Error("useIdentity must be used within an AuthContext");
  }
  return currentUser.identity;
};
