import { Field } from "formik";
import { HealthContextFields } from "../../../types/test";
import { Question } from "../../../utils/healthHistoryV2";
import { indexCheckboxStyles } from "../../../utils/colors";
import { useEffect, useState } from "react";
import { cn } from "../../../utils/cn";
import { generateYearOptions } from "../../../utils/datetime";
import QuestionTitle from "./questionTitle";

const RelatedDiagnosesQuestion = ({
  question,
  healthContext,
  values,
  handleChange,
  setFieldValue,
  setSelectedRelatedDiagnoses,
  setCurrentCheckedOptionsForPage,
}: {
  question: Question;
  healthContext: HealthContextFields;
  handleChange: () => void;
  setFieldValue: () => void;
  setSelectedRelatedDiagnoses: any;
  setCurrentCheckedOptionsForPage?: any;
  values: any;
}) => {
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<
    string[]
  >([]);
  const allRelatedDiagnoses =
    healthContext._related_diagnosis_options.related_diagnoses;

  // Prefill the years and toggle open based on the selected diagnosis
  useEffect(() => {
    if (values["related_diagnoses"]) {
      let diagnosisYearKeys = Object.keys(values["related_diagnoses"]);
      if (Array.isArray(values["related_diagnoses"])) {
        diagnosisYearKeys = values["related_diagnoses"];
      }
      if (currentSelectedOptions.length > 0) {
        diagnosisYearKeys = diagnosisYearKeys.filter(
          (option) => option != "none"
        );
      }
      setCurrentSelectedOptions(
        Array.from(new Set([...diagnosisYearKeys, ...currentSelectedOptions]))
      );
      setSelectedRelatedDiagnoses(
        Array.from(new Set([...diagnosisYearKeys, ...currentSelectedOptions]))
      );
    }
  }, [values]);

  const mainFieldChecked = (option: string) => {
    return currentSelectedOptions?.includes(option);
  };

  const handleCheckboxChange =
    ({
      handleChange,
      setFieldValue,
      option,
    }: {
      handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
      setFieldValue: (
        fieldName: string,
        defaultAnswer: string | string[]
      ) => void;
      option: string;
    }) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e);
      if (!e.target.checked) {
        setCurrentCheckedOptionsForPage(
          currentSelectedOptions.filter((value) => value !== option)
        );
        setCurrentSelectedOptions(
          currentSelectedOptions.filter((value) => value !== option)
        );
      } else if (e.target.checked) {
        if (option === "none") {
          setFieldValue("related_diagnoses", ["none"]);
          setSelectedRelatedDiagnoses(currentSelectedOptions);
          setCurrentSelectedOptions(["none"]);
          setCurrentCheckedOptionsForPage(["none"]);
        } else {
          if (!currentSelectedOptions.includes(option)) {
            const filteredOptions = currentSelectedOptions.filter(
              (option) => option !== "none"
            );
            setCurrentCheckedOptionsForPage([option, ...filteredOptions]);
            setCurrentSelectedOptions([...filteredOptions, option]);

            // TODO: this is probably not needed (same functionality as setCurrentSelectedOptions)
            // but needs more testing for cleaning up. clean up ticket: DVY-2505
            setSelectedRelatedDiagnoses([option, ...currentSelectedOptions]);
          }
        }
      }
    };

  return (
    <div>
      <QuestionTitle questionTitle={question.slug} />
      {allRelatedDiagnoses &&
        allRelatedDiagnoses?.map((option, index) => (
          <div className={"mt-[10px] mb-[10px"} key={option[0]}>
            <label className="cursor-pointer flex items-center mb-1">
              <Field
                type={"checkbox"}
                name={question.slug}
                value={option[0]}
                className={indexCheckboxStyles(index)}
                onChange={handleCheckboxChange({
                  handleChange,
                  setFieldValue,
                  option: option[0],
                })}
                checked={mainFieldChecked(option[0])}
              />
              <span className="ml-2 leading-5">{option[1]}</span>
            </label>
            {/* Render the diagnosis year dropdown if the diagnosis is selected */}
            {currentSelectedOptions.includes(option[0]) &&
              option[0] !== "none" &&
              option[0] !== "other" && (
                <Field
                  as={"select"}
                  id={option[0]}
                  name={option[0]}
                  type={"date"}
                  className={cn(
                    "ml-8 mb-2 mt-2 border border-black border-opacity-20 rounded-xl sm:w-96 w-64 outline-none focus:ring-0 p-2"
                  )}
                  placeholder="Your Answer"
                  autoComplete="off"
                  value={values["related_diagnoses"][`${option[0]}`]}
                >
                  <option value="" label="Select diagnosis year" />
                  {generateYearOptions(1950, new Date().getFullYear()).map(
                    (year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    )
                  )}
                </Field>
              )}
          </div>
        ))}
    </div>
  );
};

export default RelatedDiagnosesQuestion;
