import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { group, hierarchy, range, line, curveBasisClosed } from "d3";
import { voronoiTreemap } from "d3-voronoi-treemap";
import SimplexNoise from "simplex-noise";

// lib
import { useResponsiveSize } from "../../utils/customHooks";
import VizTooltip from "../../components/results_v2/vizTooltip";
import { stroke, breakpoints, fill, textClasses } from "../../utils/viz";
import { updateHover, updateSelect } from "../results_v2/resultsSlice";
import { blurLevels } from "../results_v2/constants";
import { getIsMobile } from "../../utils/general";

// custom results data visualization
const ResultsViz = ({ data, vizState, loading }) => {
  const { isMoving, groupExplosionBasis, nodeExplosionBasis, blurProp } =
    vizState;
  const simplex = new SimplexNoise();

  /* Redux */
  // redux dispatch method for updating global store
  const dispatch = useDispatch();

  // get hoveredBacteria from store
  const hoveredBacteria = useSelector((state) => state.results.value.hovered);
  const selectedBacteria = useSelector((state) => state.results.value.selected);
  const selectedBacteriaType = useSelector(
    (state) => state.results.value.selectedType
  );

  /* Refs */
  const svgRef = useRef(null);

  /* Custom Hooks */
  // Updates svg dimensions on window resize
  const [[width, totalHeight]] = useResponsiveSize(
    svgRef.current && svgRef.current.parentElement.clientWidth,
    800
  );
  const [[, , isMobile]] = useResponsiveSize(
    window.innerWidth,
    window.innerWidth
  );

  const height = isMobile ? width - 270 : totalHeight;
  const denom = isMobile ? 11 : 3; // prevent viz blowout in "small" windows
  // viz parameters
  const config = {
    height: height - (isMobile ? 0 : 81), // accomodate for sticky header
    width: width,
    radius: width / (denom ? denom : 6), // catch case denom is Nan
    dispersion: 10,
    numSegments: 2,
    margins: { T: 50 },
    smoothness: 80,
    variability: 20,
  };
  const centerPosY = config.height / 2;
  const centerPosX = config.width / 2;

  const ellipse = useMemo(
    () =>
      range(45).map((i) => {
        const degree = i * 8;
        const radians = (degree * Math.PI) / 180;
        const noise =
          simplex.noise2D(degree / config.smoothness, 1) * config.variability;
        return [
          centerPosX + (config.radius + noise) * Math.cos(radians),
          centerPosY + (config.radius + noise) * Math.sin(radians),
        ];
      }),
    [height, width]
  );

  // creates voronoi treemap
  const root = useMemo(() => {
    if (data) {
      // filters out zero values (zero values break voronoi)
      const filtered = data.filter((d) => d.normalized_percent > 0);
      // builds root
      const rt = hierarchy(
        group(filtered, (d) => d.bacteria.type),
        (d) => d[1]
      ).sum((d) => d.normalized_percent);
      // maps voronoi polygons onto root
      voronoiTreemap().clip(ellipse)(rt);
      // returns root with polygon mapping
      rt.children = rt.children.map((group) => ({
        ...group,
        children: group.children.map((node) => ({
          ...node,
          path:
            line().curve(curveBasisClosed)(
              resample(node.polygon, config.numSegments)
            ) + "z",
        })),
      }));
      return rt;
    }
  }, [data, width, height]);

  // handles shape translations
  const explode = useCallback(
    (group, [parentX, parentY], explodeBasis) => {
      return `translate(
      ${(group.polygon.site.x - parentX) * explodeBasis}px,
      ${(group.polygon.site.y - parentY) * explodeBasis}px
    )`;
    },
    [height, width]
  );

  // sets hoveredBacteria bacteria
  const handleHover = useCallback((bacteria) => {
    dispatch(updateHover(bacteria));
  }, []);

  // sets clicked bacteria
  const handleClick = useCallback(
    (bacteria) => {
      selectedBacteria === bacteria
        ? dispatch(updateSelect(null))
        : dispatch(updateSelect(bacteria));
    },
    [selectedBacteria]
  );

  return (
    <svg
      height={config.height}
      filter={`url(#gooey-${blurProp})`}
      ref={svgRef}
      className="w-full"
    >
      <defs>
        {stroke.range().map((color) => (
          <filter key={color} id={`inset-shadow-${color}`}>
            <feGaussianBlur stdDeviation="8" result="offset-blur" />
            <feComposite
              operator="out"
              in="SourceGraphic"
              in2="offset-blur"
              result="inverse"
            />
            <feFlood floodColor={color} floodOpacity="1" result="color" />
            <feComposite
              operator="in"
              in="color"
              in2="inverse"
              result="shadow"
            />
            <feComponentTransfer in="shadow" result="shadow">
              <feFuncA type="linear" />
            </feComponentTransfer>
            <feComposite operator="over" in="shadow" in2="SourceGraphic" />
          </filter>
        ))}
        {Object.values(blurLevels).map((stdDev) => (
          <filter id={`gooey-${stdDev}`} key={`gooey-${stdDev}`}>
            <feGaussianBlur
              id="blur1"
              in="SourceGraphic"
              result="blur"
              stdDeviation={stdDev}
            ></feGaussianBlur>
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
              result="gooey"
            ></feColorMatrix>
          </filter>
        ))}
      </defs>
      {loading && (
        <g className="w-2/4 h-1/2 transform translate-y-2/4 translate-x-2/4 loader">
          {[6, 5, 4, 3, 2, 1].map((i) => (
            <g
              className="animate-pulse sm:animate-loading"
              style={{
                animationDelay: `${1 + 0.25 * i}s`,
                animationDuration: `3s`,
              }}
              key={`rotate-${i}`}
            >
              <g className="transform sm:translate-y-loader sm:translate-x-loader">
                <circle
                  className="animate-grow"
                  r={`${(1 + 0.1 * i) * 40}px`}
                  style={{ fill: `${fill(i)}` }}
                ></circle>
              </g>
            </g>
          ))}
        </g>
      )}
      {!loading && (
        <g>
          {root &&
            root.children.map((group, i) => (
              // creates a group for each bacteria type
              <g
                key={`group-${i}`}
                // transition first stage more slowly than second
                className={`group-g type-${
                  group.data[0]
                } transition-none sm:transition-transform ${
                  nodeExplosionBasis === 0
                    ? "sm:duration-500"
                    : "sm:duration-100"
                } sm:in-out-cubic`}
                style={{
                  transform: explode(
                    group,
                    [centerPosX, centerPosY],
                    groupExplosionBasis
                  ),
                }}
              >
                {group.children.map((node, i) => {
                  const { data } = node,
                    { bacteria } = data;
                  // create a group for each bacteria
                  return (
                    <VizTooltip
                      bacteria={node.data.bacteria}
                      percent={node.data.normalized_percent}
                      hidden={nodeExplosionBasis === 0}
                      visible={
                        selectedBacteria !== bacteria.id &&
                        hoveredBacteria === bacteria.id
                      }
                      key={`tooltip-${i}`}
                    >
                      <a
                        className={`transition-none sm:transition-transform sm:duration-500 sm:in-out-cubic
                    ${isMoving ? "animate-wiggle" : ""}
                    ${
                      nodeExplosionBasis === 0
                        ? "pointer-events-none cursor-default"
                        : "pointer-events-auto cursor-pointer"
                    }`}
                        style={{
                          transform: explode(
                            node,
                            [group.polygon.site.x, group.polygon.site.y],
                            nodeExplosionBasis
                          ),
                          animationDelay: `${i + 1}s`,
                        }}
                        onMouseOver={() => handleHover(bacteria.id)}
                        onMouseOut={() => handleHover(null)}
                        href={`#${bacteria.id}-row`}
                        onClick={() => handleClick(bacteria.id)}
                        tabIndex={-1}
                      >
                        <path
                          d={node.path}
                          style={{
                            stroke: stroke(bacteria.type),
                          }}
                          filter={`url(#inset-shadow-${stroke(bacteria.type)})`}
                          className={`transition-colors duration-500 stroke-2
                      ${textClasses[bacteria.type].fill} fill-current hover:${
                            textClasses[bacteria.type].hover
                          }
                        ${
                          (nodeExplosionBasis > 0 &&
                            selectedBacteria === bacteria.id &&
                            !hoveredBacteria) ||
                          hoveredBacteria === bacteria.id ||
                          selectedBacteriaType === bacteria.type
                            ? textClasses[bacteria.type].hover
                            : ""
                        }
                      `}
                        ></path>
                      </a>
                    </VizTooltip>
                  );
                })}
              </g>
            ))}
        </g>
      )}
    </svg>
  );
};

export const ResultsLoadingAnimation = () => {
  // Fun loading animation that is similar to the results page viz. Can be used as loading animation for other pages!
  /* Refs */
  const svgRef = useRef(null);
  // Updates svg dimensions on window resize
  const [[width, totalHeight]] = useResponsiveSize(
    svgRef.current && svgRef.current.parentElement.clientWidth,
    800
  );
  const isMobile = getIsMobile();

  const height = isMobile ? width - 270 : totalHeight;
  const denom = isMobile ? 11 : 3; // prevent viz blowout in "small" windows
  // viz parameters
  const config = {
    height: height - (isMobile ? 0 : 81), // accomodate for sticky header
    width: width,
    radius: width / (denom ? denom : 6), // catch case denom is Nan
    dispersion: 10,
    numSegments: 2,
    smoothness: 80,
    variability: 20,
  };
  const blurProp = blurLevels.HIGH;

  return (
    <svg
      height={config.height * 0.8}
      filter={`url(#gooey-${blurProp})`}
      ref={svgRef}
      className="w-full"
    >
      <defs>
        {stroke.range().map((color) => (
          <filter key={color} id={`inset-shadow-${color}`}>
            <feGaussianBlur stdDeviation="8" result="offset-blur" />
            <feComposite
              operator="out"
              in="SourceGraphic"
              in2="offset-blur"
              result="inverse"
            />
            <feFlood floodColor={color} floodOpacity="1" result="color" />
            <feComposite
              operator="in"
              in="color"
              in2="inverse"
              result="shadow"
            />
            <feComponentTransfer in="shadow" result="shadow">
              <feFuncA type="linear" />
            </feComponentTransfer>
            <feComposite operator="over" in="shadow" in2="SourceGraphic" />
          </filter>
        ))}
        {Object.values(blurLevels).map((stdDev) => (
          <filter id={`gooey-${stdDev}`} key={`gooey-${stdDev}`}>
            <feGaussianBlur
              id="blur1"
              in="SourceGraphic"
              result="blur"
              stdDeviation={stdDev}
            ></feGaussianBlur>
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
              result="gooey"
            ></feColorMatrix>
          </filter>
        ))}
      </defs>

      <g className="w-2/4 h-1/2 transform translate-y-2/4 translate-x-2/4 loader">
        {[6, 5, 4, 3, 2, 1].map((i) => (
          <g
            className="animate-loading"
            style={{
              animationDelay: `${1 + 0.25 * i}s`,
              animationDuration: `3s`,
            }}
            key={`rotate-${i}`}
          >
            <g className="transform translate-y-loader translate-x-loader">
              <circle
                className="animate-grow"
                r={`${(1 + 0.1 * i) * 40}px`}
                style={{ fill: `${fill(i)}` }}
              ></circle>
            </g>
          </g>
        ))}
      </g>
    </svg>
  );
};

/**
 * Segment resampling function to create curved polygons
 * @param {[number, number]} points original voronoi children polygons created with the root function
 * @param {number} numSegments number of segments to divide the existing segments into, in order to create curved connections. Fewer segments means more curves, less associated with the data. More segments means straighter lines, more closely tied to the reality of the voronoi.
 * @returns a new set of points that works with d3.curveBasisClosed() as control points to create a curved polygon look. Source: https://bl.ocks.org/mbostock/4636377
 */
const resample = (points, numSegments) => {
  let i = -1;
  let n = points.length;
  let p0 = points[n - 1];
  let x0 = p0[0];
  let y0 = p0[1];
  let p1, x1, y1;
  let points2 = [];
  while (++i < n) {
    p1 = points[i];
    x1 = p1[0];
    y1 = p1[1];
    points2.push(
      [
        (x0 * (numSegments - 1) + x1) / numSegments,
        (y0 * (numSegments - 1) + y1) / numSegments,
      ],
      [
        (x0 + x1 * (numSegments - 1)) / numSegments,
        (y0 + y1 * (numSegments - 1)) / numSegments,
      ],
      p1
    );
    p0 = p1;
    x0 = x1;
    y0 = y1;
  }
  return points2;
};

export default React.memo(ResultsViz);
