/**
 * Results section (upper table) of the compare/trends table
 * @param {string} bacteriaType
 * @param {string} typeColor a string for the bacteria category color (protective, neutral, disruptive, unknown)
 * @param {number} percentCurrent a number indicating the percentage of a bacteria in a user's current test results
 * @param {number} percentLast a number indicating the percentage of a bacteria in a user's previous/compare results
 * @param {number} percentDiff a number indicating the percentage difference between current and previous test results
//  * @param {string} trendDirection a string for trend direction (up, down, neutral)
//  * @param {string} trendColor a string for indicating a trend direction color 
 * @param {array} currentBacteria an array containing data for bacteria results present in a user's test
 * @param {array} compareBacteria an array containing data for bacteria results present in a user's test
 */

// react
import React, { useState } from "react";

// components
import TrendsPill from "./trendsPill";
import TrendsTablePercentBar from "./trendsTablePercentBar";

// icons
import { ReactComponent as TrendUp } from "../../../components/results_v2/icons/trend-line-up.svg";
import { ReactComponent as TrendDown } from "../../../components/results_v2/icons/trend-line-down.svg";
import { ReactComponent as TrendNeutral } from "../../../components/results_v2/icons/trend-line-neutral.svg";
import { ReactComponent as ExpandIcon } from "../../../components/common/icons/expand-icon.svg";
import { ReactComponent as ShrinkIcon } from "../../../components/common/icons/shrink-icon.svg";
import { ReactComponent as Info } from "../../../components/common/icons/infoGray.svg";

// utils
import {
  bacteriaTypeToCodeMap,
  formatters,
  trendsTablePillColors,
  typeDictionary,
} from "../../../utils/viz";
import { useResponsiveSize } from "../../../utils/customHooks";

// lib
import { TREND_DIRECTION } from "../../../pages/results_v2/constants";
import Tippy from "@tippyjs/react";
import Markdown from "markdown-to-jsx";
import { secondaryColors } from "../../../utils/viz";

/** TOOLTIP
 * This tooltip is on rollover of the info button when viewing the extra info on a microbe */
const Tooltip = ({ tooltipCopy }) => (
  <Tippy
    render={() => (
      <div
        id="tooltip"
        role="tooltip"
        className="hidden md:flex bg-evvy-white p-3 rounded-md max-w-sm border border-dashed border-black"
        tabIndex={-1}
      >
        <div className="text-evvy-black">
          <Markdown>{tooltipCopy.replace("|", "<br><br>")}</Markdown>
        </div>
      </div>
    )}
    placement="top"
    animation={false}
  >
    <Info
      stroke={secondaryColors["image-placeholder"]}
      className="ml-2 mb-0.5 w-4 h-4 inline"
    />
  </Tippy>
);

const TrendsTableCategoryRow = ({
  bacteriaType,
  typeColor,
  percentCurrent,
  percentLast,
  percentDiff,
  trendDirection,
  currentBacteria,
  compareBacteria,
  analyticsOnClick,
  compareDiffVersion,
  newSide,
}) => {
  const [[width, height, isMobile]] = useResponsiveSize(window.innerWidth, 700);

  // state controller for whether bacteria details for each row should be visible
  const [showRows, setShowRows] = useState(false);

  const clickHandler = () => setShowRows(!showRows);

  // calculate based on percent diff and direction
  const createPill = () => {
    let svg;
    let fill;
    let trendIndicator;

    if (trendDirection === TREND_DIRECTION.NEUTRAL) {
      fill = trendsTablePillColors.black;
      trendIndicator = trendDirection;
      svg = <TrendNeutral fill={fill} />;
    }

    if (
      bacteriaType === typeDictionary["NE"].label ||
      bacteriaType === typeDictionary["UN"].label
    ) {
      fill = trendsTablePillColors.black;
      trendIndicator = TREND_DIRECTION.NEUTRAL;
      if (trendDirection === TREND_DIRECTION.UP) {
        svg = <TrendUp fill={fill} />;
      } else if (trendDirection === TREND_DIRECTION.DOWN) {
        svg = <TrendDown fill={fill} />;
      }
    } else if (bacteriaType === typeDictionary["GO"].label) {
      if (trendDirection === TREND_DIRECTION.UP) {
        fill = trendsTablePillColors.green;
        trendIndicator = trendDirection;
        svg = <TrendUp fill={fill} />;
      } else if (trendDirection === TREND_DIRECTION.DOWN) {
        fill = trendsTablePillColors.red;
        trendIndicator = trendDirection;
        svg = <TrendDown fill={fill} />;
      }
    } else if (bacteriaType === typeDictionary["BA"].label) {
      if (trendDirection === TREND_DIRECTION.UP) {
        fill = trendsTablePillColors.red;
        trendIndicator = TREND_DIRECTION.DOWN;
        svg = <TrendUp fill={fill} />;
      } else if (trendDirection === TREND_DIRECTION.DOWN) {
        fill = trendsTablePillColors.green;
        trendIndicator = TREND_DIRECTION.UP;
        svg = <TrendDown fill={fill} />;
      }
    }

    return (
      <TrendsPill
        percentage={percentDiff}
        trendIndicator={trendIndicator}
        svg={svg}
      />
    );
  };

  const formatRelativeAbundancePercentage = (percent) => {
    return percent && percent > 0
      ? `${formatters.numShort(percent)}%`
      : "<0.1%";
  };

  return (
    <div
      className={`grid gap-5 md:gap-8 h-auto py-4 items-start ${
        bacteriaType !== "protective" ? "border-evvy-black/30 border-t" : ""
      } text-left`}
      style={{
        gridTemplateColumns: isMobile ? "1fr 1fr" : ".75fr 1fr 1fr .25fr",
      }}
    >
      <div
        className={`grid ${isMobile && `col-span-full`}`}
        style={{ gridTemplateColumns: isMobile ? "1fr" : ".35fr 1fr 1fr" }}
      >
        {!isMobile && (
          <>
            <div className="flex items-center mr-4">
              <div
                className={`dot-legend rounded-full bg-${bacteriaTypeToCodeMap[bacteriaType]}-fill`}
                aria-hidden="true"
              />
            </div>
            <span className="capitalize text-lg font-semibold mr-2">
              {bacteriaType}
            </span>
            {createPill()}
          </>
        )}
        {/* display mobile row */}
        {isMobile && (
          <div className="flex justify-between">
            <div className="flex row">
              <div className="flex items-center mr-4">
                <div
                  className={`dot-legend rounded-full bg-${bacteriaTypeToCodeMap[bacteriaType]}-fill`}
                  aria-hidden="true"
                />
              </div>
              <span className="capitalize text-lg font-semibold mr-2">
                {bacteriaType}
              </span>
            </div>
            <div className="flex row">
              {createPill()}
              <button
                className="flex justify-end"
                onClick={() => setShowRows(!showRows)}
              >
                {showRows ? (
                  <>
                    <ShrinkIcon />
                  </>
                ) : (
                  <>
                    <ExpandIcon />
                  </>
                )}
              </button>
            </div>
          </div>
        )}
      </div>
      <div>
        <TrendsTablePercentBar percentage={percentLast} color={typeColor} />
        {/* map through individual scores here. add bacteria object  compareBacteria*/}
        <div
          className={`grid items-start ${showRows ? "" : "hidden"}`}
          style={{ gridTemplateColumns: "1.2fr .2fr" }}
        >
          {compareBacteria?.map((d, i) => (
            <>
              <div
                key={`${d}-${i}-copy`}
                className="text-base font-medium my-1"
              >
                <span>
                  {d?.short_name}
                  {compareDiffVersion && newSide === "compare" && d?.tooltip ? (
                    <Tooltip tooltipCopy={d?.tooltip} />
                  ) : compareDiffVersion &&
                    newSide === "compare" &&
                    d?.is_new ? (
                    "*"
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div
                key={`${d}-${i}-number`}
                className="text-base font-medium my-1 flex justify-end"
              >
                {formatRelativeAbundancePercentage(d?.percent)}
              </div>
            </>
          ))}
        </div>
      </div>
      <div>
        <TrendsTablePercentBar percentage={percentCurrent} color={typeColor} />
        {/* map through individual scores */}
        <div
          className={`grid items-start ${showRows ? "" : "hidden"}`}
          style={{ gridTemplateColumns: "1.2fr .2fr" }}
        >
          {currentBacteria?.map((d, i) => (
            <>
              <div
                key={`${d}-${i}-copy`}
                className="text-base font-medium my-1"
              >
                <span>
                  {d?.short_name}
                  {compareDiffVersion && newSide === "base" && d?.tooltip ? (
                    <Tooltip tooltipCopy={d?.tooltip} />
                  ) : compareDiffVersion && newSide === "base" && d?.is_new ? (
                    "*"
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div
                key={`${d}-${i}-number`}
                className="text-base font-medium my-1 flex justify-end"
              >
                {formatRelativeAbundancePercentage(d?.percent)}
              </div>
            </>
          ))}
        </div>
      </div>
      {/* hide expan icon on mobile */}
      {!isMobile && (
        <button
          className="flex justify-end"
          onClick={showRows ? clickHandler : analyticsOnClick(clickHandler)}
        >
          {showRows ? (
            <>
              <ShrinkIcon />
            </>
          ) : (
            <>
              <ExpandIcon />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default TrendsTableCategoryRow;
