import { AxiosResponse } from "axios";
import { getRequest, postRequest } from "../utils/axios";
import { States } from "../utils/location";
import { ProviderTestOrder } from "../types/provider";

const getProviderTestOrders: () => Promise<
  AxiosResponse<ProviderTestOrder[]>
> = () => {
  const response = getRequest(
    "/api/v1/provider-test-orders/",
    (response: any) => {
      return response;
    },
    (error: any, response: any) => {
      throw error;
    }
  );
  return response;
};

const createProviderTestOrder: (
  patientEmail: string,
  payer: "patient" | "provider",
  addExpandedPCR: boolean
) => Promise<AxiosResponse<ProviderTestOrder>> = (
  patientEmail,
  payer,
  addExpandedPCR
) => {
  const response = postRequest(
    "/api/v1/provider-test-orders/",
    {
      patient_email: patientEmail,
      payer: payer,
      add_expanded_pcr: addExpandedPCR,
    },
    (response: any) => {
      return response;
    },
    (error: any, response: any) => {
      throw error;
    }
  );
  return response;
};

export type CreateProviderBulkTestOrderData = {
  num_ordered: number;
  address_first_line: string;
  address_second_line: string;
  city: string;
  state_code?: States;
  zip_code: string;
  add_sti: boolean;
};

const createProviderBulkTestOrder: (
  data: CreateProviderBulkTestOrderData
) => Promise<AxiosResponse<void>> = (data) => {
  const response = postRequest(
    "/api/v1/provider-test-orders/bulk_create/",
    data,
    (response: any) => {
      return response;
    },
    (error: any, response: any) => {
      throw error;
    }
  );
  return response;
};

const remindPatient: (patientEmail: string) => Promise<AxiosResponse<void>> = (
  patientEmail
) => {
  const response = postRequest(
    "/api/v1/provider-test-orders/remind_patient/",
    {
      patient_email: patientEmail,
    },
    (response: any) => {
      return response;
    },
    (error: any, response: any) => {
      throw error;
    }
  );
  return response;
};

export const providerService = {
  createProviderTestOrder,
  createProviderBulkTestOrder,
  getProviderTestOrders,
  remindPatient,
};
