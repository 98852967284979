import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";

// components
import ErrorBoundary from "../../components/common/errorBoundary";
import LoadingSpinner from "../../components/common/loadingSpinner";
import StickyHeader from "../../components/care/messages/stickyHeader";
import StickyMessageInputBar from "../../components/care/messages/stickyMessageInputBar";
import PatientMessage from "../../components/care/messages/patientMessage";
import ClinicianMessage from "../../components/care/messages/clinicianMessage";
import { authService } from "../../services/auth";

// services
import { careService } from "../../services/care";

// lib
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import {
  sendMessagingViewThread,
  eventNames,
} from "../../utils/analytics/customEventTracking";
import { useParams } from "react-router-dom";

const MessageThread = ({ consults }) => {
  /*
   * State
   */
  const { consultId } = useParams();
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [nextCursor, setNextCursor] = useState("");
  const consult = consults.filter((c) => c.uid === consultId)[0];

  useEffect(() => {
    fetchFirstPageConsultMessages(sendAnalytics);
  }, [consultId]);

  /* Actions */
  const sendAnalytics = (response) => {
    const numUserMessages = response.data?.messages?.filter(
      (msg) => msg?.role === "patient"
    )?.length;
    const numProviderMessages = response.data?.messages?.filter(
      (msg) => msg?.role === "clinician"
    )?.length;
    sendMessagingViewThread({
      consultId,
      testHash: consult?.test_hash,
      numUserMessages,
      numProviderMessages,
    });
  };

  const fetchFirstPageConsultMessages = (onSuccess) => {
    setLoading(true);
    setError(null);
    const limit = 200; // fetch a lot of a messages up front
    careService.fetchConsultMessages(
      consultId,
      limit,
      "",
      (response) => {
        setMessages(response.data?.messages?.reverse());
        setHasNextPage(response.data.has_next_page);
        setNextCursor(response.data.oldest_message_id);
        setLoading(false);
        // refresh notification state
        authService.fetchUser();
        if (response.data?.messages?.length > 0) {
          window.scrollTo(0, document.body.scrollHeight); // scroll to most recent message!
        }

        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error) => {
        setLoading(false);
        setError(
          "Error loading messages. Please try again. If this issue persists, please contact support@evvy.com"
        );
      }
    );
  };

  // for getting next pages of results
  const fetchNextPageConsultMessages = () => {
    setLoadingNextPage(true);
    setError(null);
    const limit = 50;
    careService.fetchConsultMessages(
      consultId,
      limit,
      nextCursor,
      (response) => {
        const currPageMessages = messages;
        const nextPageMessages = response.data?.messages?.reverse();
        // combine prev results with new results
        setMessages(nextPageMessages.concat(currPageMessages));
        setHasNextPage(response.data.has_next_page);
        setNextCursor(response.data.oldest_message_id);
        setLoadingNextPage(false);
      },
      (error) => {
        setLoadingNextPage(false);
        setError(
          "Error loading messages. Please try again. If this issue persists, please contact support@evvy.com"
        );
      }
    );
  };

  const sendMessage = (msgText, onSuccess) => {
    setSending(true);
    setError(null);
    careService.sendConsultMessage(
      consultId,
      { text: msgText },
      (response) => {
        fetchFirstPageConsultMessages();
        setSending(false);
        if (onSuccess) {
          onSuccess();
        }
      },
      (error) => {
        setSending(false);
        setError(
          "Error sending message. Please try again. If this issue persists, please contact support@evvy.com"
        );
      }
    );
  };

  return (
    <Layout
      title="Care | Messages | Thread"
      bgClass="bg-evvy-white"
      full
      header={
        <ErrorBoundary>
          <StickyHeader
            onClickBack={analyticsClickHandler({
              eventName: eventNames.MESSAGING_CLICKED_BACK,
              eventArgs: {
                consultId,
                testHash: consult?.test_hash,
              },
            })}
            testHash={consult?.test_pretty_hash}
            isSTIConsult={consult?.type === "sti"}
          />
        </ErrorBoundary>
      }
      extraFooter={
        <StickyMessageInputBar
          analyticsEventArgs={{
            consultId,
            testHash: consult?.test_hash,
            numUserMessages: messages?.filter((msg) => msg?.role === "patient")
              ?.length,
            numProviderMessages: messages?.filter(
              (msg) => msg?.role === "clinician"
            )?.length,
          }}
          sending={sending}
          sendMessage={sendMessage}
          isClosed={consult?.is_closed}
        />
      }
    >
      <div className="max-w-6xl w-full mx-auto px-4 text-center">
        {messages && messages.length > 0 && hasNextPage && nextCursor && (
          <div className="py-4">
            <button
              className="bg-white border border-black hover:bg-evvy-black hover:text-white mt-auto p-6 font-semibold tracking-wider focus:outline-none"
              disabled={loadingNextPage}
              onClick={() => fetchNextPageConsultMessages()}
            >
              <div>
                Load older messages{loadingNextPage ? <LoadingSpinner /> : ""}
              </div>
            </button>
          </div>
        )}

        {messages && messages.length > 0 ? (
          messages.map((msg, ind, arr) =>
            msg?.role === "patient" ? (
              <PatientMessage
                message={msg}
                prevMessage={ind > 0 ? arr[ind - 1] : null}
                key={ind}
              />
            ) : (
              <ClinicianMessage
                message={msg}
                prevMessage={ind > 0 ? arr[ind - 1] : null}
                key={ind}
              />
            )
          )
        ) : !loading ? (
          <div className="py-8 max-w-md mx-auto">
            <h3>No Messages Yet</h3>
            <div className="b1">
              If you'd like to reach out to your healthcare provider regarding
              your current consultation, send them a message by typing below.
            </div>
          </div>
        ) : (
          <div className="py-10">
            <LoadingSpinner />
          </div>
        )}

        {error && (
          <div className="py-8 text-red-500 text-center mb-4">{error}</div>
        )}
      </div>
    </Layout>
  );
};

export default MessageThread;
