import { useState } from "react";
import Modal from "../../components/common/modal";
import { Formik, Field, Form } from "formik";

import { authService } from "../../services/auth";
import { accountService } from "../../services/account";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_PAGE_URL, PROVIDER_ACCOUNT_PAGE_URL } from "./constants";

interface NameModalProps {
  handleClose: () => void;
}

const NameModal: React.FC<NameModalProps> = ({ handleClose }) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // context
  const currentUser = useLoggedInUser();

  const navigate = useNavigate();

  return (
    <Modal
      widthClass="w-full md:w-6/12"
      closeModal={() => {
        handleClose();
      }}
    >
      <div className="bg-white p-12">
        <div className="mb-12">
          <h2>Update your name</h2>
        </div>

        <Formik
          initialValues={{
            first_name: (currentUser && currentUser.identity.firstName) || "",
            last_name: (currentUser && currentUser.identity.lastName) || "",
          }}
          validate={(values) => {
            setError(null);

            if (!values.last_name) {
              setError("Missing last name");
            }
            if (!values.first_name) {
              setError("Missing first name");
            }

            return {};
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            setError(null);
            setLoading(true);
            accountService.updateAccount(
              values,
              () => {
                // lets update user config and then take user back to account page
                authService.fetchUser(() => {
                  setLoading(false);
                  // this following navigation hack effectively re-renders the account component without
                  // needing to "refresh" the page. the reason we need this is because the account
                  // information is stored in local storage which is not reactive where we display it
                  navigate(
                    currentUser.identity.isProvider
                      ? PROVIDER_ACCOUNT_PAGE_URL
                      : ACCOUNT_PAGE_URL,
                    { replace: true }
                  );
                  handleClose();
                });
              },
              (error, response) => {
                setError(response || "Error saving account info");
                setLoading(false);
              }
            );
          }}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => (
            <Form>
              {error && (
                <div className="bg-coral p-2 px-3 font-medium rounded-sm my-6">
                  {error}
                </div>
              )}

              <div className="mb-8">
                <div className="text-lg font-medium mb-2">First Name</div>
                <Field
                  id="first_name"
                  name="first_name"
                  type="text"
                  autoFocus
                  autoComplete="off"
                  required
                  placeholder="First Name"
                  className="appearance-none block w-full p-6 bg-evvy-cream rounded-xs border-none focus:outline-none focus:ring-transparent focus:border-none"
                />
              </div>

              <div className="mb-12">
                <div className="text-lg font-medium mb-2">Last Name</div>
                <Field
                  id="last_name"
                  name="last_name"
                  type="text"
                  autoFocus
                  autoComplete="off"
                  required
                  placeholder="Last Name"
                  className="appearance-none block w-full p-6 bg-evvy-cream rounded-xs border-none focus:outline-none focus:ring-transparent focus:border-none"
                />
              </div>

              <div className="flex items-center">
                <button
                  onClick={handleClose}
                  className="ml-auto underline underline-offset-4 uppercase text-sm font-semibold tracking-wider cursor-pointer"
                >
                  Cancel
                </button>
                <button
                  className="cursor-pointer tracking-wider ml-12 py-6 px-20 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
                  type="submit"
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default NameModal;
