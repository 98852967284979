import React from "react";

const ResearchImage = ({ status }) => {
  return (
    <div className="flex gap-2">
      <img
        className="w-16 h-16 rounded-lg"
        src={`/images/graphics/research-${status.toLowerCase()}.jpg`}
      />
      <div className="inline-block m-1">
        <span className="bg-highlighter-yellow p-1 w-max">{status}</span>
      </div>
    </div>
  );
};

export default React.memo(ResearchImage);
