import { Link } from "react-router-dom";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";

const PastTreatmentsButtons = ({
  hasPastTreatments,
  hasPastConsultMessages,
  baseAnalyticsEventArgs,
}: {
  hasPastTreatments: boolean;
  hasPastConsultMessages: boolean;
  baseAnalyticsEventArgs: any;
}) => {
  return (
    <div className="py-6 text-center bg-gradient-to-r from-evvy-silverfish to-highlighter-mint uppercase font-semibold">
      {hasPastTreatments && (
        <Link
          to="/care/past-treatments/"
          className="linkedText underline cursor-pointer w-max p-pd"
          onClick={analyticsClickHandler({
            eventName: eventNames.CARE_CLICKED_VIEW_PAST_CONSULTATIONS,
            eventArgs: baseAnalyticsEventArgs,
          })}
        >
          View past treatment programs
        </Link>
      )}

      {hasPastTreatments && hasPastConsultMessages && (
        <span className="inline-block caption px-2">|</span>
      )}

      {hasPastConsultMessages && (
        <Link
          to="/care/consults/messages/"
          className="linkedText underline cursor-pointer w-max p-pd"
          onClick={analyticsClickHandler({
            eventName: eventNames.CARE_CLICKED_VIEW_PAST_MESSAGES,
            eventArgs: baseAnalyticsEventArgs,
          })}
        >
          View past messages
        </Link>
      )}
    </div>
  );
};

export default PastTreatmentsButtons;
