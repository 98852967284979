import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import CloseX from "../../common/closeX";

const AddMedicationModal = ({ submitMedication, handleClose }) => {
  const [error, setError] = useState(null);

  return (
    <div className="block bg-evvy-white p-12">
      <div className="flex justify-end items-center mb-6">
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>
      <div className="mb-12 text-center">
        <h3>Add your medication or supplement below</h3>
      </div>

      <Formik
        initialValues={{
          medication_name: "",
        }}
        validate={(values) => {
          setError(null);
          if (!values.medication_name) {
            setError("Missing medication name");
          }
          return {};
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          submitMedication(values.medication_name);
          handleClose();
        }}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm my-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <div className="mb-8">
              <Field
                id="medication_name"
                name="medication_name"
                type="text"
                autoFocus
                autoComplete="off"
                required
                placeholder="Enter name"
                className="appearance-none block w-full p-6 rounded-xs border border-gray-300 focus:outline-none focus:ring-transparent focus:border-evvy-blue"
              />
            </div>

            <div className="flex items-center">
              <button
                className="mx-auto justify-center cursor-pointer tracking-wider py-6 px-20 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
                type="submit"
                disabled={!values.medication_name}
              >
                Submit
              </button>
            </div>
            <div className="flex items-center my-4">
              <button
                className="mx-auto justify-center linkedText t4 border-b-2 border-evvy-black cursor-pointer"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddMedicationModal;
