import React from "react";
import { Link } from "react-router-dom";

const ReferredTemplate = ({ handleClose, data }) => {
  const { referral_note, clinician_display_name } = data || {};

  return (
    <div className={`w-full flex flex-col py-7 px-8 bg-evvy-white text-center`}>
      {/* title */}
      <h5 className="text-2xl">
        Please read the below note from your provider:
      </h5>
      {/* description */}
      <div className="bg-evvy-cream p-4">
        {referral_note ? (
          <div className="b1 italic">{referral_note}</div>
        ) : (
          <div className="b1 italic">
            Based on your medical information, you aren't eligible for virtual
            care through Evvy at this time.
            <br />
            Common reasons for ineligibility include pregnancy status or other
            health conditions that make it difficult for a provider to determine
            the right treatment without seeing you in person.
            <br />
            We'd love to offer you a free call with an Evvy certified health
            coach to help walk through your results and what you can do next.
          </div>
        )}
        <div className="t4 mt-4">{clinician_display_name}</div>
      </div>
      <div className="b1 my-4 semibold">
        Your cost of the treatment program will be completely refunded.
      </div>
      <div className="flex items-center mx-auto text-center justify-center mb-4 mt-2">
        <Link to={`/care/`}>
          <button
            onClick={handleClose}
            className="t4 bg-evvy-white text-evvy-black py-5 px-20 sm:px-10 w-full rounded-sm font-semibold tracking-wider border border-evvy-black focus:outline-none"
          >
            Return to care
          </button>
        </Link>
      </div>
    </div>
  );
};
export default ReferredTemplate;
