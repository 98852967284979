import { useEffect, useState } from "react";

import axios from "axios";
import { providerService } from "../../services/provider";
import { ProviderTestOrder } from "../../types/provider";

export const useProviderTestOrders: () => [
  ProviderTestOrder[],
  boolean,
  string,
  () => Promise<void>
] = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [providerTestOrders, setProviderTestOrders] = useState<
    ProviderTestOrder[]
  >([]);

  useEffect(() => {
    fetchProviderTestOrders();
  }, []);

  const fetchProviderTestOrders = async () => {
    try {
      const testOrderResponse = await providerService.getProviderTestOrders();
      setProviderTestOrders(testOrderResponse.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return [providerTestOrders, loading, error, fetchProviderTestOrders];
};
