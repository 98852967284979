/**
 * A carousel wrapper
 * @param {[]} children array of jsx elements such as an array of cards to populate carousel
 * @param {number} space a number which corresponds to the amount of space the carousel needs to be scrolled to expose a new element (e.g. card width + gap b/n cards)
 * @returns jsx of a carousel wrapper with styled horizontal scrollbar and right/left buttons
 */

import React, { Children, cloneElement } from "react";
import { select } from "d3";

const Carousel = ({ children, space }) => {
  const isMobile = window && window.innerWidth < 600 ? true : false; // mobile detects swipe actions on the calendar
  const arrowThreshold = isMobile ? 1 : 3; // number of cards to scroll when clicking arrows

  return (
    <>
      <div
        style={{ scrollSnapType: "x", scrollBehavior: "smooth" }}
        className="flex overflow-x-scroll py-10 space-x-4 max-w-full"
        id="carousel"
      >
        {Children.map(children, (child) => (
          <div style={{ flexShrink: "0", scrollSnapAlign: "start" }}>
            {cloneElement(child)}
          </div>
        ))}
      </div>
      {/* append arrow buttons if there is more than one carousel item */}
      {children && children.length > arrowThreshold && (
        <div className="hidden md:flex gap-2 self-end text-4xl text-evvy-pine mt-4">
          <button
            onClick={() => {
              const carousel = select("#carousel");
              const currentScrollLeft = carousel.property("scrollLeft");
              select("#carousel").property(
                "scrollLeft",
                currentScrollLeft - space
              );
            }}
            className="arrow opacity-50"
          >
            {"<-"}
          </button>
          <button
            onClick={() => {
              const carousel = select("#carousel");
              const currentScrollLeft = carousel.property("scrollLeft");
              select("#carousel").property(
                "scrollLeft",
                currentScrollLeft + space
              );
            }}
            className="arrow"
          >
            {" "}
            {"->"}
          </button>
        </div>
      )}
    </>
  );
};

export default Carousel;
