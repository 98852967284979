import {
  HdyhauSurvey,
  HealthContextFields,
  HealthContextRelatedDiagnosesFields,
  Test,
  TupleArray,
} from "../types/test";
import { prefillAnswer } from "./answerUtils";

type QuestionType =
  | "multiselect_dimensional"
  | "multiselect_dimensional_short"
  | "multiselect_dimensional_dropdown"
  | "multiselect"
  | "singleselect"
  | "multitext"
  | "singletext"
  | "number"
  | "date"
  | "dropdown"
  | "number_scale"
  | "multidates"
  | "height";

export type EducationalMomentType = {
  getText: (healthContextValues?: HealthContextFields) => string | undefined;
  getHeader?: (healthContextValues?: HealthContextFields) => string | undefined;
  showEducationalMoment?: (params: {
    healthContextValues?: HealthContextFields;
    relatedDiagnoses?: HealthContextRelatedDiagnosesFields;
  }) => boolean; // function to determine if the educational moment should be shown
  alwaysShow?: boolean;
  style?: "headerSmall";
};

export type SectionIntro = {
  getText: (healthContextValues?: HealthContextFields) => string | undefined;
  getHeader?: (healthContextValues?: HealthContextFields) => string | undefined;
};

export type Question = {
  slug: keyof HealthContextFields; // URL identifier for the question
  showQuestion: (params: {
    // show question applies on the page level now
    currentPageHealthContextValues?: HealthContextFields; // current page values
    healthContextValues?: HealthContextFields; // health context stored in backend
    relatedDiagnoses?: HealthContextRelatedDiagnosesFields;
    currentCheckedOptionsForMultidimensional?: string[];
  }) => boolean;
  getInitialValue: (params: {
    healthContextValues: HealthContextFields;
    hdyhauSurvey?: HdyhauSurvey;
  }) => any;
  handleValidate?: (params: {
    healthContextValues?: HealthContextFields;
    currentCheckedOptionsForMultidimensional?: string[];
  }) => boolean;
  getFollowupOptions?: (params: {
    healthContextValues?: HealthContextFields;
  }) => TupleArray<string> | undefined;
  getOptions?: (params: {
    healthContextValues?: HealthContextFields;
    currentPageHealthContextValues?: HealthContextFields;
    hdyhauSurvey?: HdyhauSurvey;
  }) => TupleArray<string> | undefined;
  answerType: QuestionType;
  optionAnswerType?: QuestionType; // used for multidimensional questions
  options?: string[]; // used for multidimensional questions
  optional?: boolean;
  isRelatedDiagnosisFollowup?: boolean;
};

export type Page = {
  slug: string; // unique URL identifier for the page
  section: string; // header for the page (used to group pages)
  isStatic?: boolean; // whether the page is static (no questions)
  customErrorMessage?: (params: {
    healthContextValues?: any;
  }) => string | undefined;
  showPage: (params: {
    healthContextValues?: HealthContextFields;
    relatedDiagnoses?: HealthContextRelatedDiagnosesFields;
    hasPreviousTestWithoutCare?: boolean;
    hasPreviousTestWithCare?: boolean;
    ldtEnabled?: boolean;
    expanUpsellEnabled?: boolean;
    hdyhauSurvey?: HdyhauSurvey;
  }) => boolean; // whether the page should be shown given current values
  questions: Question[]; // all potential questions that can be shown on a page
  sectionIntro?: SectionIntro; // section intro (defined for first page of a section)
  intro?: SectionIntro; // optional section intro for the first page of a section
  educationalMoment?: EducationalMomentType; // optional educational moment
  formatPayload?: (params: {
    // formatting data for the API payload if there is custom formatting needed
    currentPageHealthContextValues: HealthContextFields;
    updatedRelatedDiagnosesConditions?: string[];
  }) => any;
};

export const getHealthHistoryURL = (hash?: string, slug?: string) => {
  return `/tests/${hash}/health-history/${slug}`;
};
const CHARACTER_LIMIT = 500;

export const UNSELECTED_OPTION: { [question: string]: string } = {
  symptoms_additional: "NO",
  diagnoses_30: "NO",
  diagnoses: "NE",
  infertility_treatments: "not-attempted",
  prescription_treatment: "not-prescribed",
};

const MULTIDIMENSIONAL_QUESTION_OPTIONS: Record<string, string[]> = {
  diagnoses_30: [
    "diagnoses_30day_bv",
    "diagnoses_30day_yeast_infection",
    "diagnoses_30day_uti",
    "diagnoses_30day_av",
    "diagnoses_30day_ureaplasma",
    "diagnoses_30day_mycoplasma",
    "diagnoses_30day_cytolytic_vaginosis",
    "diagnoses_30day_chlamydia",
    "diagnoses_30day_trichomoniasis",
    "diagnoses_30day_gonorrhea",
  ],
  symptoms_additional: [
    "symptoms_additional_dryness",
    "symptoms_additional_excessive_discharge",
    "symptoms_additional_odorous_discharge",
    "symptoms_additional_vulva_pain",
    "symptoms_additional_vulvar_burning",
    "symptoms_additional_external_itchiness",
    "symptoms_additional_vaginal_burning_internal",
    "symptoms_additional_internal_itchiness",
    "symptoms_additional_pain_while_peeing",
    "symptoms_additional_frequent_urination",
    "symptoms_additional_pain_with_sex",
    "symptoms_additional_vaginal_pain_or_swelling",
  ],
  prescription_treatment: [
    "prescription_treatment_antibiotics_metro",
    "prescription_treatment_antibiotics_clinda",
    "prescription_treatment_antibiotics_not_listed",
    "prescription_treatment_antifungal",
    "prescription_treatment_hormone_therapy",
  ],
  diagnoses: [
    "diagnoses_bv",
    "diagnoses_yeast_infection",
    "diagnoses_uti",
    "diagnoses_av",
    "diagnoses_ureaplasma",
    "diagnoses_mycoplasma",
    "diagnoses_cytolytic_vaginosis",
    "diagnoses_chlamydia",
    "diagnoses_trichomoniasis",
    "diagnoses_gonorrhea",
  ],
  infertility_treatments: [
    "fertility_treatment_intrauterine_insemination",
    "fertility_treatment_in_vitro_fertilization",
    "fertility_treatment_hormone_therapy",
    "fertility_treatment_ovulation_induction",
    "fertility_treatment_surgical_interventions",
    "fertility_treatment_alternative_therapies",
    "fertility_treatment_therapy_not_listed",
  ],
};

const getRelatedDiagnosisInitialValue = ({
  healthContextValues,
  condition,
  fieldUpdate,
}: {
  healthContextValues: HealthContextFields;
  condition: string;
  fieldUpdate: string;
}) => {
  const relatedDiagnosisMap = getRelatedDiagnosisMap(
    healthContextValues.healthcontextrelateddiagnoses_set
  );
  if (relatedDiagnosisMap) {
    const relevantCondition = relatedDiagnosisMap[condition];
    return relevantCondition?.[
      fieldUpdate as keyof HealthContextRelatedDiagnosesFields
    ];
  }
  return "";
};

// fills any unselected options with the unselected option value for the question
const formatMultidimensionalPayload = ({
  currentPageHealthContextValues,
  question,
  isFertilityQuestion,
}: {
  currentPageHealthContextValues: HealthContextFields;
  question: string;
  isFertilityQuestion?: boolean;
}) => {
  MULTIDIMENSIONAL_QUESTION_OPTIONS[question].map((option) => {
    const key = option as keyof HealthContextFields;
    if (!option.includes("_none")) {
      if (
        !currentPageHealthContextValues[key] ||
        currentPageHealthContextValues[key] === ""
      ) {
        currentPageHealthContextValues[key] = UNSELECTED_OPTION[question];
      }
    }
  });

  if (isFertilityQuestion) {
    return {
      data: {},
      relatedDiagnoses: {
        field_updates: currentPageHealthContextValues,
        updated_conditions: [question],
      },
    };
  } else {
    return {
      data: currentPageHealthContextValues,
    };
  }
};

const formatRelatedDiagnosisPayload = ({
  healthContextValues,
  condition,
}: {
  healthContextValues: HealthContextFields;
  condition: string;
}) => {
  const diagnosesUpdates = healthContextValues;
  // for date answers, if they are set to the empty string they should be cleared to undefined
  // some date answers are optional and backend expects undefined and not empty string
  Object.keys(diagnosesUpdates).forEach((key) => {
    if (
      key.includes("date") &&
      diagnosesUpdates[key as keyof HealthContextFields] === ""
    ) {
      diagnosesUpdates[key as keyof HealthContextFields] = undefined;
    }
  });
  const conditionsToUpdate = [condition];
  const relatedDiagnoses = {
    field_updates: diagnosesUpdates,
    updated_conditions: conditionsToUpdate,
  };
  return {
    data: {},
    relatedDiagnoses: relatedDiagnoses,
  };
};

const getSymptomDiagnoses = (vaginalDischarge?: string) => {
  switch (vaginalDischarge) {
    case "thin-grey":
      return "BV";
    case "white-chunky":
      return "YEAST";
    case "yellow-copious":
      return "AV";
    default:
      return undefined;
  }
};

const getCancerFollowups = (condition: string): Question[] => {
  const cancerFollowupQuestion: Question[] = [
    {
      isRelatedDiagnosisFollowup: true,
      slug: "cancer_type_other" as keyof HealthContextFields,
      getInitialValue: ({ healthContextValues }) => {
        if (condition === "cancer-other") {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition,
            fieldUpdate: "cancer_type_other",
          });
        }
      },
      handleValidate: ({ healthContextValues }) => {
        if (
          healthContextValues?.[
            "cancer_type_other" as keyof HealthContextFields
          ] !== ""
        ) {
          return false;
        }
        return true;
      },
      answerType: "singletext",
      showQuestion: () => condition === "cancer-other",
    },
    {
      isRelatedDiagnosisFollowup: true,
      slug: "cancer_treatment" as keyof HealthContextFields,
      getInitialValue: ({ healthContextValues }) => {
        return getRelatedDiagnosisInitialValue({
          healthContextValues,
          condition,
          fieldUpdate: "cancer_treatment",
        });
      },
      handleValidate: ({ healthContextValues }) => {
        return (
          healthContextValues?.["cancer_treatment" as keyof HealthContextFields]
            ?.length === 0
        );
      },
      answerType: "multiselect",
      showQuestion: () => true,
    },
    {
      isRelatedDiagnosisFollowup: true,
      slug: "cancer_treatment_other" as keyof HealthContextFields,
      getInitialValue: ({ healthContextValues }) => {
        return getRelatedDiagnosisInitialValue({
          healthContextValues,
          condition,
          fieldUpdate: "cancer_treatment_other",
        });
      },
      answerType: "singletext",
      showQuestion: ({ currentPageHealthContextValues }) => {
        return (
          currentPageHealthContextValues?.[
            "cancer_treatment" as keyof HealthContextFields
          ]?.includes("other") || false
        );
      },
    },
    {
      isRelatedDiagnosisFollowup: true,
      slug: "cancer_in_remission" as keyof HealthContextFields,
      getInitialValue: ({ healthContextValues }) => {
        return getRelatedDiagnosisInitialValue({
          healthContextValues,
          condition,
          fieldUpdate: "cancer_in_remission",
        });
      },
      handleValidate: ({ healthContextValues }) => {
        return (
          healthContextValues?.[
            "cancer_in_remission" as keyof HealthContextFields
          ] === ""
        );
      },
      answerType: "singleselect",
      showQuestion: ({ healthContextValues }) => {
        if (healthContextValues?.healthcontextrelateddiagnoses_set) {
          const relatedDiagnosisMap = getRelatedDiagnosisMap(
            healthContextValues?.healthcontextrelateddiagnoses_set
          );
          const relevantCondition = relatedDiagnosisMap[condition];
          return (
            (relevantCondition?.["diagnosis_year"] &&
              relevantCondition?.["diagnosis_year"] <
                new Date().getFullYear() - 5) ||
            false
          );
        }
        return false;
      },
    },
    {
      isRelatedDiagnosisFollowup: true,
      slug: "cancer_last_surgery_date" as keyof HealthContextFields,
      getInitialValue: ({ healthContextValues }) => {
        return getRelatedDiagnosisInitialValue({
          healthContextValues,
          condition,
          fieldUpdate: "cancer_last_surgery_date",
        });
      },
      handleValidate: ({ healthContextValues }) => {
        return !healthContextValues?.[
          "cancer_last_surgery_date" as keyof HealthContextFields
        ];
      },
      answerType: "date",
      showQuestion: ({ currentPageHealthContextValues }) => {
        return (
          currentPageHealthContextValues?.[
            "cancer_treatment" as keyof HealthContextFields
          ]?.includes("surgery") || false
        );
      },
    },
    {
      isRelatedDiagnosisFollowup: true,
      slug: "cancer_last_radiation_treatment_date" as keyof HealthContextFields,
      getInitialValue: ({ healthContextValues }) => {
        return getRelatedDiagnosisInitialValue({
          healthContextValues,
          condition,
          fieldUpdate: "cancer_last_radiation_treatment_date",
        });
      },
      handleValidate: ({ healthContextValues }) => {
        return !healthContextValues?.[
          "cancer_last_radiation_treatment_date" as keyof HealthContextFields
        ];
      },
      answerType: "date",
      showQuestion: ({ currentPageHealthContextValues }) => {
        return (
          currentPageHealthContextValues?.[
            "cancer_treatment" as keyof HealthContextFields
          ]?.includes("radiation") || false
        );
      },
    },
    {
      isRelatedDiagnosisFollowup: true,
      slug: "cancer_last_chemotherapy_treatment_date" as keyof HealthContextFields,
      getInitialValue: ({ healthContextValues }) => {
        return getRelatedDiagnosisInitialValue({
          healthContextValues,
          condition,
          fieldUpdate: "cancer_last_chemotherapy_treatment_date",
        });
      },
      handleValidate: ({ healthContextValues }) => {
        return !healthContextValues?.[
          "cancer_last_chemotherapy_treatment_date" as keyof HealthContextFields
        ];
      },
      answerType: "date",
      showQuestion: ({ currentPageHealthContextValues }) => {
        return (
          currentPageHealthContextValues?.[
            "cancer_treatment" as keyof HealthContextFields
          ]?.includes("chemotherapy") || false
        );
      },
    },
  ];
  return cancerFollowupQuestion;
};

const validateNumber = (value: any) => {
  if (!value || isNaN(Number(value))) {
    return true;
  }
  return false;
};

export const getRelatedDiagnosisMap = (
  relatedDiagnoses: HealthContextRelatedDiagnosesFields[]
) => {
  return relatedDiagnoses.reduce((acc, condition) => {
    const conditionKey = condition.condition;
    acc[conditionKey] = condition;
    return acc;
  }, {} as { [key: string]: HealthContextRelatedDiagnosesFields });
};

const hasRelatedDiagnosisCondition = ({
  healthContextValues,
  condition,
}: {
  healthContextValues?: HealthContextFields;
  condition: string;
}) => {
  const hasCondition =
    healthContextValues?.healthcontextrelateddiagnoses_set?.find(
      (diagnosis) => diagnosis.condition === condition
    );
  return hasCondition ? true : false;
};

const getInitialValueForRelatedDiagnosis = ({
  healthContextValues,
  field,
}: {
  healthContextValues: HealthContextFields;
  field: string;
}) => {
  const values: Record<string, any> = {};
  let relatedDiagnosesAnswers =
    healthContextValues.healthcontextrelateddiagnoses_set;
  const relatedDiagnosisMap = getRelatedDiagnosisMap(relatedDiagnosesAnswers);
  Object.keys(relatedDiagnosisMap).map((condition) => {
    values[condition] =
      relatedDiagnosisMap[condition][
        field as keyof HealthContextRelatedDiagnosesFields
      ];
  });
  return values;
};

// TODO: add prefill answer for multiselect dimensional questions
const getInitialValueForMultiselectDimensional = ({
  healthContextValues,
  parentQuestionSlug,
}: {
  healthContextValues: HealthContextFields;
  parentQuestionSlug: string;
}) => {
  const values: Record<string, any> = {};

  MULTIDIMENSIONAL_QUESTION_OPTIONS[parentQuestionSlug].map((option) => {
    let hasAnswer = healthContextValues[option as keyof HealthContextFields];
    if (
      Array.isArray(healthContextValues[option as keyof HealthContextFields])
    ) {
      if (
        healthContextValues[option as keyof HealthContextFields].length === 0
      ) {
        hasAnswer = false;
      }
    }
    if (hasAnswer) {
      values[option] = healthContextValues[option as keyof HealthContextFields];
    } else if (
      healthContextValues.past_health_history &&
      healthContextValues.past_health_history[
        option as keyof HealthContextFields
      ]
    ) {
      values[option] =
        healthContextValues.past_health_history[
          option as keyof HealthContextFields
        ];
    }
  });

  return values;
};

const validateMultidimensionalQuestion = ({
  healthContextValues,
  parentQuestionSlug,
  optionAnswerType,
  currentCheckedOptionsForMultidimensional,
}: {
  healthContextValues?: HealthContextFields;
  parentQuestionSlug: string;
  optionAnswerType?: QuestionType;
  currentCheckedOptionsForMultidimensional?: string[];
}) => {
  const options = MULTIDIMENSIONAL_QUESTION_OPTIONS[parentQuestionSlug];
  const defaultUnselectedValue = UNSELECTED_OPTION[parentQuestionSlug];

  if (healthContextValues) {
    // if "None" is selected, check that all questions have a default value AND currentCheckedOptions is empty
    const allValuesAreDefault = options.every((option) => {
      const currentOptionValue =
        healthContextValues?.[option as keyof HealthContextFields];
      if (optionAnswerType === "multiselect") {
        if (Array.isArray(currentOptionValue)) {
          return (
            currentOptionValue?.length === 1 &&
            currentOptionValue[0] === defaultUnselectedValue
          );
        }
      } else {
        return currentOptionValue === defaultUnselectedValue;
      }
    });

    // No values are selected, return error
    const allValuesEmpty = options.every((option) => {
      const currentOptionValue =
        healthContextValues?.[option as keyof HealthContextFields];
      if (optionAnswerType === "multiselect") {
        if (Array.isArray(currentOptionValue)) {
          return !currentOptionValue || currentOptionValue?.length === 0;
        } else {
          return !currentOptionValue;
        }
      } else {
        return currentOptionValue === "" || !currentOptionValue;
      }
    });

    // None value is selected, so there are no errors; return false
    if (
      allValuesAreDefault &&
      currentCheckedOptionsForMultidimensional?.length === 0
    ) {
      return false;
    }

    // if there are no values selected, return true
    if (allValuesEmpty) {
      return true;
    }

    // if currentCheckedOptions is NOT empty, make sure all have non-default values
    if (
      currentCheckedOptionsForMultidimensional &&
      currentCheckedOptionsForMultidimensional?.length > 0
    ) {
      for (const option of currentCheckedOptionsForMultidimensional) {
        const currentOptionValue =
          healthContextValues?.[option as keyof HealthContextFields];

        if (options.includes(option)) {
          // Check if the option is checked, but the value is blank or default
          if (optionAnswerType === "multiselect") {
            if (
              Array.isArray(currentOptionValue) &&
              (currentOptionValue.length === 0 ||
                currentOptionValue[0] === defaultUnselectedValue)
            ) {
              return true;
            }
          } else {
            if (
              currentOptionValue === "" ||
              !currentOptionValue ||
              currentOptionValue === defaultUnselectedValue
            ) {
              return true;
            }
          }
        }
      }
    }
  }

  return false;
};

export const HEALTH_HISTORY_PAGES: Page[] = [
  // INTRO STATIC PAGES
  {
    slug: "intro",
    section: "What to Expect",
    sectionIntro: {
      getHeader: () => "What to Expect",
      getText: () =>
        "To activate your test, you'll need to answer two questionnaires about your health so we can get you the best care. Note that all sections must be completed in order to receive results.",
    },
    showPage: () => true,
    questions: [],
  },
  {
    slug: "verify",
    section: "What to Expect",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "personal-information",
    section: "Address",
    showPage: ({ ldtEnabled }) => {
      return ldtEnabled || false;
    },
    questions: [],
  },
  // Only show for users that are re-testing
  {
    slug: "re-test",
    section: "Updates",
    showPage: ({ hasPreviousTestWithoutCare }) => {
      return hasPreviousTestWithoutCare || false;
    },
    sectionIntro: {
      getHeader: () => "Updates",
      getText: () => "Let's review changes since your last test",
    },
    questions: [
      {
        slug: "basics_hoping_to_learn",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.basics_hoping_to_learn;
        },
        answerType: "singletext",
      },
      {
        slug: "basics_follow_recommendations",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues?.basics_follow_recommendations;
        },
        answerType: "singleselect",
      },
      {
        slug: "basics_follow_recommendations_details",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.basics_follow_recommendations ===
            "YE"
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.basics_follow_recommendations_details;
        },
        answerType: "multitext",
      },
      {
        slug: "basics_retest_symptoms_changed",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.basics_follow_recommendations ===
            "YE"
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues?.basics_retest_symptoms_changed;
        },
        answerType: "singleselect",
      },
    ],
  },
  // only show for users that have completed care for their previous test
  {
    slug: "post-care",
    section: "Updates",
    showPage: ({ hasPreviousTestWithCare }) => {
      return hasPreviousTestWithCare || false;
    },
    sectionIntro: {
      getHeader: () => "Updates",
      getText: () => "Let's review changes since your last test",
    },
    questions: [
      {
        slug: "basics_hoping_to_learn",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.basics_hoping_to_learn;
        },
        answerType: "multitext",
      },
      {
        slug: "post_care_followed_protocol",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return String(healthContextValues.post_care_followed_protocol);
        },
        handleValidate: ({ healthContextValues }) => {
          return validateNumber(
            healthContextValues?.post_care_followed_protocol
          );
        },
        answerType: "number_scale",
      },
      {
        slug: "post_care_treatments_taken",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_treatments_taken;
        },
        answerType: "multiselect",
      },
      {
        slug: "post_care_treatments_full_course_taken",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            (currentPageHealthContextValues?.post_care_treatments_taken &&
              currentPageHealthContextValues?.post_care_treatments_taken
                ?.length > 0 &&
              !currentPageHealthContextValues?.post_care_treatments_taken?.includes(
                "none"
              )) ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_treatments_full_course_taken;
        },
        answerType: "singleselect",
      },
      {
        slug: "post_care_treatments_not_completed",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            (currentPageHealthContextValues?.post_care_treatments_full_course_taken ===
              "NO" &&
              currentPageHealthContextValues?.post_care_treatments_taken &&
              currentPageHealthContextValues?.post_care_treatments_taken
                ?.length > 0 &&
              !currentPageHealthContextValues?.post_care_treatments_taken?.includes(
                "none"
              )) ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_treatments_not_completed;
        },
        answerType: "multiselect",
        getOptions: ({
          healthContextValues,
          currentPageHealthContextValues,
        }) => {
          const treatmentsTaken =
            currentPageHealthContextValues?.post_care_treatments_taken;
          const allOptions =
            healthContextValues?._options.post_care_treatments_taken;
          const filteredOptions = allOptions?.filter((option) =>
            treatmentsTaken?.includes(option[0])
          );
          return filteredOptions;
        },
      },
    ],
  },
  {
    slug: "post-care-side-effects",
    section: "Updates",
    showPage: ({ hasPreviousTestWithCare }) => {
      return hasPreviousTestWithCare || false;
    },
    sectionIntro: {
      getHeader: () => "Updates",
      getText: () => "Let's review changes since your last test",
    },
    questions: [
      {
        slug: "post_care_had_side_effects",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_had_side_effects;
        },
        answerType: "singleselect",
      },
      {
        slug: "post_care_side_effects",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.post_care_had_side_effects === "YE"
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_side_effects;
        },
        answerType: "multiselect",
      },
      {
        slug: "post_care_side_effects_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.post_care_side_effects?.includes(
              "OT"
            ) || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_side_effects_other;
        },
        answerType: "singletext",
      },
    ],
  },
  {
    slug: "post-care-change",
    section: "Updates",
    showPage: ({ hasPreviousTestWithCare }) => {
      return hasPreviousTestWithCare || false;
    },
    questions: [
      {
        slug: "post_care_symptom_change",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_symptom_change;
        },
        answerType: "singleselect",
      },
      {
        slug: "post_care_improved_symptoms",
        // somewhat better or significantly better
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.post_care_symptom_change === "SB" ||
            currentPageHealthContextValues?.post_care_symptom_change === "WB"
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_improved_symptoms;
        },
        answerType: "multiselect",
      },
      {
        slug: "post_care_improved_symptoms_other",
        // somewhat worse or significantly worse
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            ((currentPageHealthContextValues?.post_care_symptom_change ===
              "SB" ||
              currentPageHealthContextValues?.post_care_symptom_change ===
                "WB") &&
              currentPageHealthContextValues?.post_care_improved_symptoms?.includes(
                "OT"
              )) ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_improved_symptoms_other;
        },
        answerType: "singletext",
      },
      {
        slug: "post_care_worsened_symptoms",
        // somewhat worse or significantly worse
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.post_care_symptom_change === "WW" ||
            currentPageHealthContextValues?.post_care_symptom_change === "SW"
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_worsened_symptoms;
        },
        answerType: "multiselect",
      },
      {
        slug: "post_care_worsened_symptoms_other",
        // somewhat worse or significantly worse
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            ((currentPageHealthContextValues?.post_care_symptom_change ===
              "WW" ||
              currentPageHealthContextValues?.post_care_symptom_change ===
                "SW") &&
              currentPageHealthContextValues?.post_care_worsened_symptoms?.includes(
                "OT"
              )) ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_worsened_symptoms_other;
        },
        answerType: "singletext",
      },
    ],
  },
  {
    slug: "symptoms",
    section: "Symptoms",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Symptoms",
      getText: () =>
        "We know how frustrating vaginal symptoms can be. Help us understand yours so we can problem solve together.",
    },
    educationalMoment: {
      showEducationalMoment: ({ healthContextValues }) => {
        return (
          (healthContextValues?.symptoms_has_current &&
            healthContextValues?.symptoms_has_current !== "") ||
          false
        );
      },
      getHeader: (healthContextValues) => {
        if (healthContextValues?.symptoms_has_current === "YE") {
          return "You're not alone";
        } else if (healthContextValues?.symptoms_has_current === "NO") {
          return "Way to go";
        }
      },
      getText: (healthContextValues) => {
        if (healthContextValues?.symptoms_has_current === "YE") {
          return "7 in 10 Evvy testers also have active symptoms they're trying to solve.";
        } else if (healthContextValues?.symptoms_has_current === "NO") {
          return "Proactively caring for your microbiome can help prevent future symptoms.";
        }
      },
    },
    questions: [
      {
        slug: "symptoms_has_current",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.symptoms_has_current;
        },
        answerType: "singleselect",
      },
    ],
  },
  {
    slug: "symptoms-trigger",
    section: "Symptoms",
    showPage: ({ healthContextValues }) => {
      return healthContextValues?.symptoms_has_current === "YE";
    },
    questions: [
      {
        slug: "symptoms_obvious_trigger",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.symptoms_obvious_trigger;
        },
        answerType: "multiselect",
      },
      {
        slug: "symptoms_trigger_other" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.symptoms_obvious_trigger?.includes(
              "other"
            ) || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.symptoms_trigger_other;
        },
        answerType: "singletext",
      },
    ],
  },
  {
    slug: "current-symptoms-details",
    section: "Symptoms",
    showPage: () => true,
    educationalMoment: {
      alwaysShow: true,
      getHeader: () => "Note:",
      getText: () =>
        "The term vulva refers to external skin and labia and the term vagina refers only to the internal canal.",
    },
    formatPayload: ({ currentPageHealthContextValues }) => {
      return formatMultidimensionalPayload({
        currentPageHealthContextValues,
        question: "symptoms_additional",
      });
    },
    questions: [
      {
        slug: "symptoms_additional",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getInitialValueForMultiselectDimensional({
            healthContextValues,
            parentQuestionSlug: "symptoms_additional",
          });
        },
        handleValidate: ({
          healthContextValues,
          currentCheckedOptionsForMultidimensional,
        }) => {
          return validateMultidimensionalQuestion({
            healthContextValues,
            parentQuestionSlug: "symptoms_additional",
            currentCheckedOptionsForMultidimensional,
          });
        },
        getFollowupOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.symptoms_additional;
        },
        answerType: "multiselect_dimensional_short",
        options: MULTIDIMENSIONAL_QUESTION_OPTIONS.symptoms_additional,
      },
    ],
  },
  {
    slug: "discharge",
    section: "Discharge",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Discharge",
      getText: () =>
        "Discharge plays a crucial role in keeping the vagina healthy and free from infections. It's also a critical clue to what's going on down there!",
    },
    questions: [
      {
        slug: "vaginal_discharge",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.vaginal_discharge;
        },
        answerType: "singleselect",
      },
    ],
  },
  {
    slug: "discharge-details",
    section: "Discharge",
    showPage: () => true,
    educationalMoment: {
      showEducationalMoment: ({ healthContextValues }) => {
        return (
          !healthContextValues?.symptoms_vaginal_smell?.includes("NO") || false
        );
      },
      getHeader: () => "Unusual is the norm",
      getText: () => {
        return "Over half of Evvy test takers also report an unusual smell.";
      },
    },
    questions: [
      {
        slug: "symptoms_vaginal_discharge_color",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          if (healthContextValues.symptoms_vaginal_discharge_color === "") {
            const diagnosis = getSymptomDiagnoses(
              healthContextValues.vaginal_discharge
            );
            switch (diagnosis) {
              case "BV":
                return "GY"; // Gray
              case "YEAST":
                return "WC"; // White/cream/off-white
              case "AV":
                return "YE"; // Yellow
              default:
                return healthContextValues["symptoms_vaginal_discharge_color"];
            }
          } else {
            return healthContextValues["symptoms_vaginal_discharge_color"];
          }
        },
        answerType: "singleselect",
      },
      {
        slug: "symptoms_vaginal_discharge_color_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.symptoms_vaginal_discharge_color ===
              "OT" || false
          );
        },
        answerType: "singletext",
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.symptoms_vaginal_discharge_color_other;
        },
      },
      {
        slug: "symptoms_vaginal_smell",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          if (
            healthContextValues.symptoms_vaginal_discharge_consistency === ""
          ) {
            const diagnosis = getSymptomDiagnoses(
              healthContextValues.vaginal_discharge
            );
            switch (diagnosis) {
              case "BV":
                return ["FI"]; // Fishy
              default:
                return healthContextValues.symptoms_vaginal_smell;
            }
          } else {
            return healthContextValues.symptoms_vaginal_smell;
          }
        },
        answerType: "multiselect",
      },
      {
        slug: "symptoms_vaginal_smell_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.symptoms_vaginal_smell?.includes(
              "OT"
            ) || false
          );
        },
        answerType: "singletext",
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.symptoms_vaginal_smell_other;
        },
      },
      {
        slug: "symptoms_vaginal_discharge_consistency",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          if (
            healthContextValues.symptoms_vaginal_discharge_consistency === ""
          ) {
            const diagnosis = getSymptomDiagnoses(
              healthContextValues.vaginal_discharge
            );
            switch (diagnosis) {
              case "BV":
                return "TH"; // Thin/milky
              case "YEAST":
                return "CH"; // Chunky/cottage cheese
              case "AV":
                return "TV"; // Thick/viscous
              default:
                return healthContextValues[
                  "symptoms_vaginal_discharge_consistency"
                ];
            }
          } else {
            return healthContextValues[
              "symptoms_vaginal_discharge_consistency"
            ];
          }
        },
        answerType: "singleselect",
      },
      {
        slug: "symptoms_vaginal_discharge_consistency_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.symptoms_vaginal_discharge_consistency ===
              "OT" || false
          );
        },
        answerType: "singletext",
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.symptoms_vaginal_discharge_consistency_other;
        },
      },
    ],
  },
  {
    slug: "diagnoses",
    section: "Diagnoses",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Related Diagnoses",
      getText: () =>
        "Imbalances in the vaginal microbiome can manifest as yeast infections, bacterial vaginosis (BV), cytolytic vaginosis (CV), aerobic vaginitis (AV), and more.",
    },
    educationalMoment: {
      getHeader: () => "Why we built Evvy",
      getText: () => {
        return "Bacterial vaginosis symptoms will recur in up to 50% of people within 6 months of treatment. Understanding your vaginal microbiome can help get to the root of the issue (Vodstricil 2021).";
      },
      showEducationalMoment: ({ healthContextValues }) => {
        return (
          healthContextValues?.diagnoses_30day_bv === "YE" ||
          healthContextValues?.diagnoses_30day_bv === "TH"
        );
      },
    },
    formatPayload: ({ currentPageHealthContextValues }) => {
      return formatMultidimensionalPayload({
        currentPageHealthContextValues,
        question: "diagnoses_30",
      });
    },
    questions: [
      {
        slug: "diagnoses_30",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getInitialValueForMultiselectDimensional({
            healthContextValues,
            parentQuestionSlug: "diagnoses_30",
          });
        },
        handleValidate: ({
          healthContextValues,
          currentCheckedOptionsForMultidimensional,
        }) => {
          return validateMultidimensionalQuestion({
            healthContextValues,
            parentQuestionSlug: "diagnoses_30",
            currentCheckedOptionsForMultidimensional,
          });
        },
        answerType: "multiselect_dimensional",
        options: MULTIDIMENSIONAL_QUESTION_OPTIONS.diagnoses_30,
        getFollowupOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.diagnoses_30;
        },
      },
    ],
  },
  {
    slug: "diagnoses-methods",
    section: "Diagnoses",
    showPage: ({ healthContextValues }) => {
      return (
        healthContextValues?.diagnoses_30day_bv === "YE" ||
        healthContextValues?.diagnoses_30day_yeast_infection === "YE"
      );
    },
    questions: [
      {
        slug: "diagnoses_30day_bv_method",
        showQuestion: ({
          currentPageHealthContextValues,
          healthContextValues,
        }) => {
          return healthContextValues?.diagnoses_30day_bv === "YE";
        },
        answerType: "singleselect",
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.diagnoses_30day_bv_method;
        },
      },
      {
        slug: "diagnoses_30day_bv_method_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.diagnoses_30day_bv_method ===
            "other"
          );
        },
        answerType: "singletext",
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.diagnoses_30day_bv_method_other;
        },
      },
      {
        slug: "diagnoses_30day_yeast_infection_method",
        showQuestion: ({
          currentPageHealthContextValues,
          healthContextValues,
        }) => {
          return healthContextValues?.diagnoses_30day_yeast_infection === "YE";
        },
        answerType: "singleselect",
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.diagnoses_30day_yeast_infection_method;
        },
      },
      {
        slug: "diagnoses_30day_yeast_infection_method_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.diagnoses_30day_yeast_infection_method ===
            "other"
          );
        },
        answerType: "singletext",
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.diagnoses_30day_yeast_infection_method_other;
        },
      },
    ],
  },
  {
    slug: "treatments",
    section: "Treatments",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Treatments",
      getText: () =>
        "Let us know what treatments you've tried previously and whether they worked for you.",
    },
    formatPayload: ({ currentPageHealthContextValues }) => {
      const prescriptionTreatmentPayload = formatMultidimensionalPayload({
        currentPageHealthContextValues,
        question: "prescription_treatment",
      });
      return prescriptionTreatmentPayload;
    },
    questions: [
      {
        slug: "prescription_treatment",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          const values = getInitialValueForMultiselectDimensional({
            healthContextValues,
            parentQuestionSlug: "prescription_treatment",
          });
          return getInitialValueForMultiselectDimensional({
            healthContextValues,
            parentQuestionSlug: "prescription_treatment",
          });
        },
        handleValidate: ({
          healthContextValues,
          currentCheckedOptionsForMultidimensional,
        }) => {
          return validateMultidimensionalQuestion({
            healthContextValues,
            parentQuestionSlug: "prescription_treatment",
            currentCheckedOptionsForMultidimensional,
          });
        },
        getFollowupOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.prescription_treatment;
        },
        answerType: "multiselect_dimensional",
        options: MULTIDIMENSIONAL_QUESTION_OPTIONS.prescription_treatment,
      },
      {
        slug: "other_vaginal_health_products",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.other_vaginal_health_products;
        },
        answerType: "multiselect",
      },
      {
        slug: "other_vaginal_health_products_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.other_vaginal_health_products?.includes(
              "other"
            ) || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.other_vaginal_health_products_other;
        },
        answerType: "singletext",
      },
    ],
  },
  {
    slug: "treatment-response",
    section: "Treatments",

    showPage: ({ healthContextValues }) => {
      let hasPrescriptions = false;
      MULTIDIMENSIONAL_QUESTION_OPTIONS.prescription_treatment.map((option) => {
        if (
          healthContextValues?.[option as keyof HealthContextFields] &&
          healthContextValues?.[option as keyof HealthContextFields] !==
            "not-prescribed" &&
          option !== "prescription_treatment_none"
        ) {
          hasPrescriptions = true;
        }
      });
      const hasOtherVaginalProducts =
        healthContextValues?.other_vaginal_health_products &&
        healthContextValues?.other_vaginal_health_products.length > 0 &&
        !healthContextValues?.other_vaginal_health_products.includes("none") &&
        !(
          healthContextValues.other_vaginal_health_products.length === 1 &&
          healthContextValues.other_vaginal_health_products.includes("other")
        );
      return hasPrescriptions || hasOtherVaginalProducts || false;
    },
    questions: [
      {
        slug: "treatment_response_antibiotics_metro",
        showQuestion: ({ healthContextValues }) => {
          return (
            (healthContextValues?.prescription_treatment_antibiotics_metro &&
              healthContextValues?.prescription_treatment_antibiotics_metro !==
                "not-prescribed") ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.treatment_response_antibiotics_metro;
        },
        getOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.treatment_response;
        },
        answerType: "singleselect",
      },
      {
        slug: "treatment_response_antibiotics_clinda",
        showQuestion: ({ healthContextValues }) => {
          return (
            (healthContextValues?.prescription_treatment_antibiotics_clinda &&
              healthContextValues?.prescription_treatment_antibiotics_clinda !==
                "not-prescribed") ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.treatment_response_antibiotics_clinda;
        },
        getOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.treatment_response;
        },
        answerType: "singleselect",
      },
      {
        slug: "treatment_response_antibiotics_other",
        showQuestion: ({ healthContextValues }) => {
          return (
            (healthContextValues?.prescription_treatment_antibiotics_not_listed &&
              healthContextValues?.prescription_treatment_antibiotics_not_listed !==
                "not-prescribed") ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.treatment_response_antibiotics_other;
        },
        getOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.treatment_response;
        },
        answerType: "singleselect",
      },
      {
        slug: "treatment_response_hormone_therapy",
        showQuestion: ({ healthContextValues }) => {
          return (
            (healthContextValues?.prescription_treatment_hormone_therapy &&
              healthContextValues?.prescription_treatment_hormone_therapy !==
                "not-prescribed") ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.treatment_response_hormone_therapy;
        },
        getOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.treatment_response;
        },
        answerType: "singleselect",
      },
      {
        slug: "treatment_response_antifungal",
        showQuestion: ({ healthContextValues }) => {
          return (
            (healthContextValues?.prescription_treatment_antifungal &&
              healthContextValues?.prescription_treatment_antifungal !==
                "not-prescribed") ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.treatment_response_antifungal;
        },
        getOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.treatment_response;
        },
        answerType: "singleselect",
      },
      {
        slug: "treatment_response_douching",
        showQuestion: ({ healthContextValues }) => {
          return (
            healthContextValues?.other_vaginal_health_products?.includes(
              "douching"
            ) || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.treatment_response_douching;
        },
        getOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.treatment_response;
        },
        answerType: "singleselect",
      },
      {
        slug: "treatment_response_feminine_hygiene",
        showQuestion: ({ healthContextValues }) => {
          return (
            healthContextValues?.other_vaginal_health_products?.includes(
              "feminine-hygiene"
            ) || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.treatment_response_feminine_hygiene;
        },
        getOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.treatment_response;
        },
        answerType: "singleselect",
      },
      {
        slug: "treatment_response_probiotics",
        showQuestion: ({ healthContextValues }) => {
          return (
            healthContextValues?.other_vaginal_health_products?.includes(
              "probiotics"
            ) || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.treatment_response_probiotics;
        },
        getOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.treatment_response;
        },
        answerType: "singleselect",
      },
      {
        slug: "treatment_response_boric_acid",
        showQuestion: ({ healthContextValues }) => {
          return (
            healthContextValues?.other_vaginal_health_products?.includes(
              "boric-acid"
            ) || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.treatment_response_boric_acid;
        },
        getOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.treatment_response;
        },
        answerType: "singleselect",
      },
    ],
  },
  {
    slug: "treatment-details",
    section: "Treatments",
    formatPayload: ({ currentPageHealthContextValues }) => {
      const extraDataItems: Record<string, string>[] = [];

      Object.keys(currentPageHealthContextValues).forEach((key) => {
        if (currentPageHealthContextValues[key as keyof HealthContextFields]) {
          const extraDataItem = {
            key: key,
            value:
              currentPageHealthContextValues[key as keyof HealthContextFields],
          };
          extraDataItems.push(extraDataItem);
        }
      });

      return {
        data: currentPageHealthContextValues,
        extraDataItems: extraDataItems,
        onError: {},
      };
    },
    showPage: ({ healthContextValues }) => {
      let hasOtherVaginalProducts =
        healthContextValues?.other_vaginal_health_products &&
        healthContextValues?.other_vaginal_health_products.length > 0 &&
        !healthContextValues?.other_vaginal_health_products.includes("none") &&
        !(
          healthContextValues.other_vaginal_health_products.length === 1 &&
          healthContextValues.other_vaginal_health_products.includes("other")
        );

      return hasOtherVaginalProducts || false;
    },
    questions: [
      {
        slug: "treatments__boric-acid__brand_or_product" as keyof HealthContextFields,
        showQuestion: ({ healthContextValues }) => {
          return (
            healthContextValues?.other_vaginal_health_products?.includes(
              "boric-acid"
            ) || false
          );
        },
        handleValidate: ({ healthContextValues }) => {
          return (
            healthContextValues?.[
              "treatments__boric-acid__brand_or_product" as keyof HealthContextFields
            ] === undefined ||
            healthContextValues?.[
              "treatments__boric-acid__brand_or_product" as keyof HealthContextFields
            ] === "" ||
            false
          );
        },
        answerType: "singletext",
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.healthcontextextradata_set.find(
            (data: any) =>
              data.key === "treatments__boric-acid__brand_or_product"
          )?.value;
        },
      },
      {
        slug: "treatments__probiotics__brand_or_product" as keyof HealthContextFields,
        showQuestion: ({ healthContextValues }) => {
          return (
            healthContextValues?.other_vaginal_health_products?.includes(
              "probiotics"
            ) || false
          );
        },
        handleValidate: ({ healthContextValues }) => {
          return (
            healthContextValues?.[
              "treatments__probiotics__brand_or_product" as keyof HealthContextFields
            ] === undefined ||
            healthContextValues?.[
              "treatments__probiotics__brand_or_product" as keyof HealthContextFields
            ] === "" ||
            false
          );
        },
        answerType: "singletext",
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.healthcontextextradata_set.find(
            (data: any) =>
              data.key === "treatments__probiotics__brand_or_product"
          )?.value;
        },
      },
      {
        slug: "treatments__feminine-hygiene__brand_or_product" as keyof HealthContextFields,
        showQuestion: ({ healthContextValues }) => {
          return (
            healthContextValues?.other_vaginal_health_products?.includes(
              "feminine-hygiene"
            ) || false
          );
        },
        handleValidate: ({ healthContextValues }) => {
          return (
            healthContextValues?.[
              "treatments__feminine-hygiene__brand_or_product" as keyof HealthContextFields
            ] === undefined ||
            healthContextValues?.[
              "treatments__feminine-hygiene__brand_or_product" as keyof HealthContextFields
            ] === "" ||
            false
          );
        },
        answerType: "singletext",
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.healthcontextextradata_set.find(
            (data: any) =>
              data.key === "treatments__feminine-hygiene__brand_or_product"
          )?.value;
        },
      },
      {
        slug: "treatments__douching__brand_or_product" as keyof HealthContextFields,
        showQuestion: ({ healthContextValues }) => {
          return (
            healthContextValues?.other_vaginal_health_products?.includes(
              "douching"
            ) || false
          );
        },
        handleValidate: ({ healthContextValues }) => {
          return (
            healthContextValues?.[
              "treatments__douching__brand_or_product" as keyof HealthContextFields
            ] === undefined ||
            healthContextValues?.[
              "treatments__douching__brand_or_product" as keyof HealthContextFields
            ] === "" ||
            false
          );
        },
        answerType: "singletext",
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.healthcontextextradata_set.find(
            (data: any) => data.key === "treatments__douching__brand_or_product"
          )?.value;
        },
      },
    ],
  },
  {
    slug: "sexual-activity",
    section: "Sexual Activity",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Sexual Activity",
      getText: () => "Sex can have a big impact on your vaginal microbiome.",
    },
    questions: [
      {
        slug: "sexual_activity_timing",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.sexual_activity_timing;
        },
        answerType: "singleselect",
      },
    ],
  },
  {
    slug: "sexual-activity-details",
    section: "Sexual Activity",
    showPage: ({ healthContextValues }) => {
      return (
        healthContextValues?.sexual_activity_timing !== "PR" &&
        healthContextValues?.sexual_activity_timing !== "NE" &&
        healthContextValues?.sexual_activity_timing !== "OM"
      );
    },
    questions: [
      {
        slug: "sexual_activity_condoms",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.sexual_activity_condoms;
        },
        answerType: "singleselect",
      },
      {
        slug: "sexual_activity_partners",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.sexual_activity_partners;
        },
        answerType: "singleselect",
      },
      {
        slug: "recent_sexual_activity",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.recent_sexual_activity;
        },
        answerType: "multiselect",
      },
    ],
  },
  {
    slug: "main-problem",
    section: "How we can help",
    showPage: () => true,
    customErrorMessage: ({ healthContextValues }) => {
      if (
        healthContextValues?.basics_main_reason_for_test &&
        healthContextValues?.basics_main_reason_for_test?.length >=
          CHARACTER_LIMIT
      ) {
        return `Please limit your response to ${CHARACTER_LIMIT} characters or less`;
      } else {
        return "Please answer all questions to continue";
      }
    },
    questions: [
      {
        slug: "basics_main_reason_for_test",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.basics_main_reason_for_test;
        },
        handleValidate: ({ healthContextValues }) => {
          if (
            !healthContextValues?.basics_main_reason_for_test ||
            (healthContextValues?.basics_main_reason_for_test &&
              healthContextValues?.basics_main_reason_for_test?.length >=
                CHARACTER_LIMIT)
          ) {
            return true;
          }
          return false;
        },
        answerType: "multitext",
      },
    ],
  },
  // TAKE SAMPLE HERE
  {
    slug: "sample-overview",
    section: "Sample",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Time to Test",
      getText: () =>
        "Grab your test and follow the instructions to take your sample. After you've taken your sample, please come back here to confirm and complete the final questionnaire.",
    },
    questions: [],
  },
  // LDT UPSELL SCREEN HERE
  {
    slug: "additional",
    section: "Sample",
    showPage: ({ expanUpsellEnabled }) => {
      return expanUpsellEnabled || false;
    },
    questions: [],
  },
  // HEALTH PROFILE INTRO STATIC SCREENS HERE
  {
    slug: "health-profile",
    section: "Health Profile",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Health Profile",
      // show different copy for first time vs. returning user
      getText: () =>
        "You’re almost there! Just a few more questions so that our team of researchers and experts can curate the best resources and recommendations for you.",
    },
    questions: [],
  },
  {
    slug: "demographics",
    section: "Demographics",
    showPage: () => true,
    formatPayload: ({ currentPageHealthContextValues }) => {
      currentPageHealthContextValues["demographics_height"] =
        currentPageHealthContextValues[
          "demographics_height_feet" as keyof HealthContextFields
        ] *
          12 +
        currentPageHealthContextValues[
          "demographics_height_inches" as keyof HealthContextFields
        ];
      return {
        data: currentPageHealthContextValues,
      };
    },
    sectionIntro: {
      getHeader: () => "Demographics",
      getText: () =>
        "Research shows that age and ethnicity can play a role in the composition of your vaginal microbiome.",
    },
    educationalMoment: {
      alwaysShow: true,
      getHeader: () => {
        return "WHY WE BUILT EVVY";
      },
      getText: () => {
        return "Studies have shown there is a connection between the vaginal microbiome and race, but research has not been able to identify why.";
      },
    },
    questions: [
      {
        slug: "demographics_height",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "demographics_height",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        handleValidate: ({ healthContextValues }) => {
          let errors = false;
          const feet =
            healthContextValues?.[
              "demographics_height_feet" as keyof HealthContextFields
            ];
          const inches =
            healthContextValues?.[
              "demographics_height_inches" as keyof HealthContextFields
            ];
          if (
            (!feet && String(feet) !== "0") ||
            (!inches && String(inches) !== "0") ||
            inches > 11
          ) {
            errors = true;
          }
          const height = feet * 12 + inches;
          if (height < 36 || height > 108) {
            errors = true;
          }
          return errors;
        },
        answerType: "height",
      },
      {
        slug: "demographics_weight",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "demographics_weight",
            healthContext: healthContextValues,
            defaultAns: undefined,
          });
        },
        handleValidate: ({ healthContextValues }) => {
          return validateNumber(healthContextValues?.demographics_weight);
        },
        answerType: "number",
      },
      {
        slug: "demographics_race",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "demographics_race",
            healthContext: healthContextValues,
            defaultAns: [],
          });
        },
        answerType: "multiselect",
      },
      {
        slug: "demographics_race_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.demographics_race?.includes("OT") ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "demographics_race_other",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singletext",
      },
      {
        slug: "demographics_hispanic_or_latino",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "demographics_hispanic_or_latino",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singleselect",
      },
    ],
  },
  {
    slug: "demographics-gender",
    section: "Demographics",
    showPage: () => true,
    questions: [
      {
        slug: "demographics_gender_identity",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "demographics_gender_identity",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singleselect",
      },
      {
        slug: "demographics_gender_identity_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.demographics_gender_identity ===
            "OT"
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "demographics_gender_identity_other",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singletext",
      },
      {
        slug: "demographics_born_with_vagina",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "demographics_born_with_vagina",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singleselect",
      },
      {
        slug: "demographics_gender_affirming_care",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.demographics_born_with_vagina ==
            "NO"
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "demographics_gender_affirming_care",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singleselect",
      },
    ],
  },
  {
    slug: "lifestyle",
    section: "Lifestyle",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Lifestyle",
      getText: () =>
        "By understanding your lifestyle, we can point to personalized opportunities to improve your vaginal health.",
    },
    questions: [
      {
        slug: "lifestyle_smoking",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "lifestyle_smoking",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singleselect",
      },
      {
        slug: "lifestyle_diet",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "lifestyle_diet",
            healthContext: healthContextValues,
            defaultAns: [],
          });
        },
        answerType: "multiselect",
      },
      {
        slug: "lifestyle_diet_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.lifestyle_diet?.includes("other") ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "lifestyle_diet_other",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singletext",
      },
      {
        slug: "lifestyle_exercise",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "lifestyle_exercise",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singleselect",
      },
    ],
  },
  {
    slug: "vaginal-health",
    section: "Related Diagnoses",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Related Diagnoses",
      getText: () =>
        "Vaginal health doesn't happen in a vacuum. By understanding your other diagnoses, we can piece together how these might be affecting your vaginal microbiome.",
    },
    educationalMoment: {
      showEducationalMoment: ({ healthContextValues }) => {
        if (
          healthContextValues?.diagnoses_bv?.includes("P2") ||
          healthContextValues?.diagnoses_bv?.includes("P3") ||
          healthContextValues?.diagnoses_bv?.includes("O2") ||
          healthContextValues?.diagnoses_yeast_infection?.includes("P2") ||
          healthContextValues?.diagnoses_yeast_infection?.includes("P3") ||
          healthContextValues?.diagnoses_yeast_infection?.includes("O2") ||
          healthContextValues?.diagnoses_uti?.includes("P2") ||
          healthContextValues?.diagnoses_uti?.includes("P3") ||
          healthContextValues?.diagnoses_uti?.includes("O2")
        ) {
          return true;
        }
        return false;
      },
      getHeader: () => {
        return "You are (definitely) not alone";
      },
      getText: (healthContextValues) => {
        if (
          healthContextValues?.diagnoses_bv?.includes("P2") ||
          healthContextValues?.diagnoses_bv?.includes("P3") ||
          healthContextValues?.diagnoses_bv?.includes("O2")
        ) {
          return "Almost 1 in 3 people with vaginas get bacterial vaginosis (BV) each year and around 70% of Evvy testers have had it at some point.";
        } else if (
          healthContextValues?.diagnoses_yeast_infection?.includes("P2") ||
          healthContextValues?.diagnoses_yeast_infection?.includes("P3") ||
          healthContextValues?.diagnoses_yeast_infection?.includes("O2")
        ) {
          return "Around 70% of Evvy testers have had a yeast infection at some point.";
        } else if (
          healthContextValues?.diagnoses_uti?.includes("P2") ||
          healthContextValues?.diagnoses_uti?.includes("P3") ||
          healthContextValues?.diagnoses_uti?.includes("O2")
        ) {
          return "Around 60% of Evvy testers have had a UTI at some point.";
        }
      },
    },
    formatPayload: ({ currentPageHealthContextValues }) => {
      MULTIDIMENSIONAL_QUESTION_OPTIONS.diagnoses.map((option) => {
        const key = option as keyof HealthContextFields;
        if (!key.includes("_none")) {
          if (
            !currentPageHealthContextValues[key] ||
            currentPageHealthContextValues[key].length === 0
          ) {
            currentPageHealthContextValues[key] = [
              UNSELECTED_OPTION["diagnoses"],
            ];
          }
          if (
            !Array.isArray(currentPageHealthContextValues[key]) &&
            currentPageHealthContextValues[key]
          ) {
            currentPageHealthContextValues[key] = [
              currentPageHealthContextValues[key],
            ];
          }
        }
        return {
          data: currentPageHealthContextValues,
        };
      });
    },
    questions: [
      {
        slug: "diagnoses",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getInitialValueForMultiselectDimensional({
            healthContextValues,
            parentQuestionSlug: "diagnoses",
          });
        },
        handleValidate: ({
          healthContextValues,
          currentCheckedOptionsForMultidimensional,
        }) => {
          return validateMultidimensionalQuestion({
            healthContextValues,
            parentQuestionSlug: "diagnoses",
            optionAnswerType: "multiselect",
            currentCheckedOptionsForMultidimensional,
          });
        },
        getFollowupOptions: ({ healthContextValues }) => {
          return healthContextValues?._options.diagnoses;
        },
        answerType: "multiselect_dimensional",
        optionAnswerType: "multiselect",
        options: MULTIDIMENSIONAL_QUESTION_OPTIONS.diagnoses,
      },
    ],
  },
  {
    slug: "other-related-diagnoses",
    section: "Related Diagnoses",
    showPage: () => true,
    formatPayload: ({
      currentPageHealthContextValues,
      updatedRelatedDiagnosesConditions,
    }) => {
      const formattedDiagnosesYears = Object.keys(
        currentPageHealthContextValues
      )
        .filter((key) => key !== "related_diagnoses")
        .reduce((obj: any, key) => {
          if (key === "related_diagnoses_other") {
            obj["condition_other"] =
              currentPageHealthContextValues[key as keyof HealthContextFields];
            return obj;
          } else {
            const conditionDiagnosisYearKey = "diagnosis_year_" + key;
            obj[conditionDiagnosisYearKey] =
              currentPageHealthContextValues[key as keyof HealthContextFields];
            return obj;
          }
        }, {});

      const relatedDiagnoses = {
        field_updates: formattedDiagnosesYears,
        updated_conditions: updatedRelatedDiagnosesConditions,
        delete_fields: true,
      };

      return {
        data: Object.fromEntries(
          Object.entries(currentPageHealthContextValues).filter(
            ([key, value]) => key !== "related_diagnoses"
          )
        ),
        relatedDiagnoses: relatedDiagnoses,
        onError: {},
      };
    },
    questions: [
      {
        slug: "related_diagnoses_immunocompromised",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "related_diagnoses_immunocompromised",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singleselect",
      },
      {
        slug: "related_diagnoses",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          // related diagnoses extra data set
          const relatedDiagnoses =
            healthContextValues.healthcontextrelateddiagnoses_set;
          if (relatedDiagnoses) {
            const relatedDiagnosisYearMap = getInitialValueForRelatedDiagnosis({
              healthContextValues,
              field: "diagnosis_year",
            });
            return {
              ...relatedDiagnosisYearMap,
            };
          }
          return {};
        },
        handleValidate: ({ healthContextValues }) => {
          let errors: string[] = [];
          if (healthContextValues) {
            if (Array.isArray(healthContextValues["related_diagnoses"])) {
              if (healthContextValues["related_diagnoses"]?.length === 0) {
                return true;
              }
              healthContextValues["related_diagnoses"].map((key) => {
                if (!key.includes("none") && !key.includes("other")) {
                  if (!healthContextValues[key as keyof HealthContextFields]) {
                    errors.push(key);
                  }
                }
              });
            } else if (
              // Handles the nothing checked state; user cannot progress without selecting a related diagnosis
              healthContextValues["related_diagnoses"] &&
              Object.keys(healthContextValues["related_diagnoses"]).length === 0
            ) {
              return true;
            }
          }
          if (errors.length > 0) {
            return true;
          }
          return false;
        },
        answerType: "multiselect_dimensional_dropdown",
      },
      {
        slug: "related_diagnoses_other" as keyof HealthContextFields,
        showQuestion: ({ currentCheckedOptionsForMultidimensional }) => {
          return (
            currentCheckedOptionsForMultidimensional?.includes("other") || false
          );
        },
        handleValidate: ({ healthContextValues }) => {
          return !(
            healthContextValues?.[
              "related_diagnoses_other" as keyof HealthContextFields
            ] !== "" &&
            healthContextValues?.[
              "related_diagnoses_other" as keyof HealthContextFields
            ]
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          const relatedDiagnosisMap = getRelatedDiagnosisMap(
            healthContextValues.healthcontextrelateddiagnoses_set
          );
          if (relatedDiagnosisMap) {
            const relevantCondition = relatedDiagnosisMap["other"];
            return relevantCondition?.[
              "condition_other" as keyof HealthContextRelatedDiagnosesFields
            ];
          }
          return "";
        },
        answerType: "singletext",
      },
    ],
  },
  {
    slug: "autoimmune",
    section: "Related Diagnoses",
    showPage: ({ healthContextValues }) => {
      return hasRelatedDiagnosisCondition({
        healthContextValues,
        condition: "autoimmune",
      });
    },
    formatPayload: ({ currentPageHealthContextValues }) => {
      return formatRelatedDiagnosisPayload({
        healthContextValues: currentPageHealthContextValues,
        condition: "autoimmune",
      });
    },
    questions: [
      {
        isRelatedDiagnosisFollowup: true,
        slug: "autoimmune_conditions" as keyof HealthContextFields,
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "autoimmune",
            fieldUpdate: "autoimmune_conditions",
          });
        },
        answerType: "singletext",
      },
    ],
  },
  {
    slug: "infertility",
    section: "Infertility or subfertility",
    showPage: ({ healthContextValues }) => {
      return hasRelatedDiagnosisCondition({
        healthContextValues,
        condition: "infertility",
      });
    },
    educationalMoment: {
      alwaysShow: true,
      getHeader: () => {
        return "YOUR STORY MATTERS";
      },
      getText: () => {
        return "Thank you for taking the time to share your experience with us. Your responses help us uncover important insights that contribute to advancing fertility care and help close the gaps in existing research.";
      },
    },
    formatPayload: ({ currentPageHealthContextValues }) => {
      const multidimensionalPayload = formatMultidimensionalPayload({
        currentPageHealthContextValues,
        question: "infertility_treatments",
        isFertilityQuestion: true,
      });
      return {
        data: {},
        relatedDiagnoses: {
          field_updates:
            multidimensionalPayload?.relatedDiagnoses?.field_updates,
          updated_conditions: ["infertility"],
        },
      };
    },
    questions: [
      {
        slug: "infertility_treatments",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          const relatedDiagnosisMap = getRelatedDiagnosisMap(
            healthContextValues.healthcontextrelateddiagnoses_set
          );
          const relevantCondition = relatedDiagnosisMap["infertility"];
          return relevantCondition;
        },
        handleValidate: ({
          healthContextValues,
          currentCheckedOptionsForMultidimensional,
        }) => {
          return validateMultidimensionalQuestion({
            healthContextValues,
            parentQuestionSlug: "infertility_treatments",
            currentCheckedOptionsForMultidimensional,
          });
        },
        getFollowupOptions: ({ healthContextValues }) => {
          return healthContextValues?._related_diagnosis_options
            .infertility_treatments;
        },
        answerType: "multiselect_dimensional",
        optionAnswerType: "singleselect",
        options: MULTIDIMENSIONAL_QUESTION_OPTIONS.infertility_treatments,
        isRelatedDiagnosisFollowup: true,
      },
      {
        slug: "fertility_treatment_other" as keyof HealthContextFields,
        isRelatedDiagnosisFollowup: true,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "fertility_treatment_therapy_not_listed" as keyof HealthContextFields
            ] &&
            currentPageHealthContextValues?.[
              "fertility_treatment_therapy_not_listed" as keyof HealthContextFields
            ] !== UNSELECTED_OPTION["infertility_treatments"]
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "infertility",
            fieldUpdate: "fertility_treatment_other",
          });
        },
        answerType: "singletext",
      },
      {
        slug: "infertility_cause_identified" as keyof HealthContextFields,
        isRelatedDiagnosisFollowup: true,
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "infertility",
            fieldUpdate: "infertility_cause_identified",
          });
        },
        answerType: "singleselect",
      },
      {
        slug: "infertility_cause" as keyof HealthContextFields,
        isRelatedDiagnosisFollowup: true,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "infertility_cause_identified" as keyof HealthContextFields
            ] === "yes" || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          const relatedDiagnosisMap = getRelatedDiagnosisMap(
            healthContextValues.healthcontextrelateddiagnoses_set
          );
          const relevantCondition = relatedDiagnosisMap["infertility"];
          return relevantCondition?.["infertility_cause"];
        },
        answerType: "singletext",
      },
    ],
  },
  {
    slug: "cancer-other",
    section: "Other Cancer",
    formatPayload: ({ currentPageHealthContextValues }) => {
      return formatRelatedDiagnosisPayload({
        healthContextValues: currentPageHealthContextValues,
        condition: "cancer-other",
      });
    },
    showPage: ({ healthContextValues }) => {
      return hasRelatedDiagnosisCondition({
        healthContextValues,
        condition: "cancer-other",
      });
    },
    questions: getCancerFollowups("cancer-other"),
  },
  {
    slug: "cancer-ovarian",
    section: "Ovarian Cancer",
    formatPayload: ({ currentPageHealthContextValues }) => {
      return formatRelatedDiagnosisPayload({
        healthContextValues: currentPageHealthContextValues,
        condition: "cancer-ovarian",
      });
    },
    showPage: ({ healthContextValues }) => {
      return hasRelatedDiagnosisCondition({
        healthContextValues,
        condition: "cancer-ovarian",
      });
    },
    questions: getCancerFollowups("cancer-ovarian"),
  },
  {
    slug: "cancer-cervical",
    section: "Cervical Cancer",
    formatPayload: ({ currentPageHealthContextValues }) => {
      return formatRelatedDiagnosisPayload({
        healthContextValues: currentPageHealthContextValues,
        condition: "cancer-cervical",
      });
    },
    showPage: ({ healthContextValues }) => {
      return hasRelatedDiagnosisCondition({
        healthContextValues,
        condition: "cancer-cervical",
      });
    },
    questions: getCancerFollowups("cancer-cervical"),
  },
  {
    slug: "cancer-endometrial",
    section: "Endometrial Cancer",
    formatPayload: ({ currentPageHealthContextValues }) => {
      return formatRelatedDiagnosisPayload({
        healthContextValues: currentPageHealthContextValues,
        condition: "cancer-endometrial",
      });
    },
    showPage: ({ healthContextValues }) => {
      return hasRelatedDiagnosisCondition({
        healthContextValues,
        condition: "cancer-endometrial",
      });
    },
    questions: getCancerFollowups("cancer-endometrial"),
  },
  {
    slug: "cancer-breast",
    section: "Breast Cancer",
    formatPayload: ({ currentPageHealthContextValues }) => {
      return formatRelatedDiagnosisPayload({
        healthContextValues: currentPageHealthContextValues,
        condition: "cancer-breast",
      });
    },
    showPage: ({ healthContextValues }) => {
      return hasRelatedDiagnosisCondition({
        healthContextValues,
        condition: "cancer-breast",
      });
    },
    questions: getCancerFollowups("cancer-breast"),
  },
  {
    slug: "diabetes",
    section: "Diabetes",
    formatPayload: ({ currentPageHealthContextValues }) => {
      return formatRelatedDiagnosisPayload({
        healthContextValues: currentPageHealthContextValues,
        condition: "diabetes",
      });
    },
    showPage: ({ healthContextValues }) => {
      return hasRelatedDiagnosisCondition({
        healthContextValues,
        condition: "diabetes",
      });
    },
    questions: [
      {
        isRelatedDiagnosisFollowup: true,
        slug: "diabetes_type" as keyof HealthContextFields,
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "diabetes",
            fieldUpdate: "diabetes_type",
          });
        },
        answerType: "singleselect",
      },
      {
        isRelatedDiagnosisFollowup: true,
        slug: "diabetes_hba1c" as keyof HealthContextFields,
        optional: true,
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "diabetes",
            fieldUpdate: "diabetes_hba1c",
          });
        },
        handleValidate: ({ healthContextValues }) => {
          return validateNumber(
            healthContextValues?.["diabetes_hba1c" as keyof HealthContextFields]
          );
        },
        answerType: "number",
      },
      {
        isRelatedDiagnosisFollowup: true,
        slug: "diabetes_last_hba1c_date" as keyof HealthContextFields,
        optional: true,
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "diabetes",
            fieldUpdate: "diabetes_last_hba1c_date",
          });
        },
        answerType: "date",
      },
    ],
  },
  {
    slug: "hpv",
    section: "HPV",
    educationalMoment: {
      alwaysShow: true,
      getHeader: () => {
        return "You're not alone";
      },
      getText: () => {
        return "Around 8 in 10 of sexually active people will get a genital HPV infection at some point.";
      },
    },
    formatPayload: ({ currentPageHealthContextValues }) => {
      return formatRelatedDiagnosisPayload({
        healthContextValues: currentPageHealthContextValues,
        condition: "hpv",
      });
    },
    showPage: ({ healthContextValues }) => {
      return hasRelatedDiagnosisCondition({
        healthContextValues,
        condition: "hpv",
      });
    },
    questions: [
      {
        optional: true,
        isRelatedDiagnosisFollowup: true,
        slug: "last_screening_date" as keyof HealthContextFields,
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "hpv",
            fieldUpdate: "last_screening_date",
          });
        },
        answerType: "date",
      },
    ],
  },
  {
    slug: "cin",
    section: "Cervical Intra-Epithelial Neoplasia",
    formatPayload: ({ currentPageHealthContextValues }) => {
      return formatRelatedDiagnosisPayload({
        healthContextValues: currentPageHealthContextValues,
        condition: "cin",
      });
    },
    showPage: ({ healthContextValues }) => {
      return hasRelatedDiagnosisCondition({
        healthContextValues,
        condition: "cin",
      });
    },
    questions: [
      {
        isRelatedDiagnosisFollowup: true,
        slug: "cin_type" as keyof HealthContextFields,
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "cin",
            fieldUpdate: "cin_type",
          });
        },
        answerType: "singleselect",
      },
      {
        isRelatedDiagnosisFollowup: true,
        slug: "last_screening_date" as keyof HealthContextFields,
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "cin",
            fieldUpdate: "last_screening_date",
          });
        },
        answerType: "date",
        optional: true,
      },
    ],
  },
  {
    slug: "endometriosis",
    section: "Endometriosis",
    formatPayload: ({ currentPageHealthContextValues }) => {
      const formattedPayload = formatRelatedDiagnosisPayload({
        healthContextValues: currentPageHealthContextValues,
        condition: "endometriosis",
      });
      return formattedPayload;
    },
    showPage: ({ healthContextValues }) => {
      return hasRelatedDiagnosisCondition({
        healthContextValues,
        condition: "endometriosis",
      });
    },
    questions: [
      {
        slug: "endometriosis_diagnosis_method" as keyof HealthContextFields,
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "endometriosis",
            fieldUpdate: "endometriosis_diagnosis_method",
          });
        },
        isRelatedDiagnosisFollowup: true,
        answerType: "multiselect",
      },
      {
        slug: "endometriosis_diagnosis_method_other" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "endometriosis_diagnosis_method" as keyof HealthContextFields
            ]?.includes("other") || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "endometriosis",
            fieldUpdate: "endometriosis_diagnosis_method_other",
          });
        },
        isRelatedDiagnosisFollowup: true,
        answerType: "singletext",
      },
      {
        slug: "endometriosis_treatment" as keyof HealthContextFields,
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "endometriosis",
            fieldUpdate: "endometriosis_treatment",
          });
        },
        isRelatedDiagnosisFollowup: true,
        answerType: "multiselect",
      },
      {
        slug: "endometriosis_treatment_other" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "endometriosis_treatment" as keyof HealthContextFields
            ]?.includes("other") || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "endometriosis",
            fieldUpdate: "endometriosis_treatment_other",
          });
        },
        isRelatedDiagnosisFollowup: true,
        answerType: "singletext",
      },
      {
        slug: "endometriosis_lesion_surgery_date" as keyof HealthContextFields,
        optional: true,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "endometriosis_treatment" as keyof HealthContextFields
            ]?.includes("surgery") || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return getRelatedDiagnosisInitialValue({
            healthContextValues,
            condition: "endometriosis",
            fieldUpdate: "endometriosis_lesion_surgery_date",
          });
        },
        isRelatedDiagnosisFollowup: true,
        answerType: "date",
      },
    ],
  },
  {
    slug: "pregnancy",
    section: "Pregnancy",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Pregnancy",
      getText: () =>
        "Hormonal and physical changes during pregnancy can impact the vaginal microbiome, and research shows that the vaginal microbiome may play a role in pregnancy outcomes (Gupta 2020).",
    },
    educationalMoment: {
      getHeader: () => {
        return "Did you know?";
      },
      getText: () => {
        return "The vaginal microbiome shifts alongside physical and hormonal shifts during pregnancy and postpartum (Serrano 2019).";
      },
      showEducationalMoment: ({ healthContextValues }) => {
        return healthContextValues?.pregnancy_status === "CU" || false;
      },
    },
    formatPayload: ({ currentPageHealthContextValues }) => {
      if (
        !Array.isArray(currentPageHealthContextValues.pregnancy_status) &&
        currentPageHealthContextValues.pregnancy_status
      ) {
        currentPageHealthContextValues.pregnancy_status = [
          currentPageHealthContextValues.pregnancy_status,
        ];
      }
      return {
        data: currentPageHealthContextValues,
      };
    },
    questions: [
      {
        slug: "pregnancy_status",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          const answer = prefillAnswer({
            questionName: "pregnancy_status",
            healthContext: healthContextValues,
            defaultAns: "",
          });
          if (Array.isArray(answer) && answer.length > 0) {
            return answer[0];
          } else {
            return answer;
          }
        },
        answerType: "singleselect",
      },
      {
        slug: "pregnancy_breastfeeding",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.pregnancy_status?.includes("PP") ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.pregnancy_breastfeeding;
        },
        answerType: "singleselect",
      },
      {
        slug: "pregnancy_postpartum_timing",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.pregnancy_status?.includes("PP") ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.pregnancy_postpartum_timing;
        },
        answerType: "singleselect",
      },
      {
        slug: "pregnancy_month",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.pregnancy_status?.includes("CU") ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.pregnancy_month;
        },
        answerType: "dropdown",
      },
    ],
  },
  {
    slug: "pregnancy-history",
    section: "Pregnancy",
    showPage: () => true,
    questions: [
      {
        slug: "pregnancy_previously",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "pregnancy_previously",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singleselect",
      },
      {
        slug: "pregnancy_experienced",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "pregnancy_experienced",
            healthContext: healthContextValues,
            defaultAns: [],
          });
        },
        answerType: "multiselect",
      },
      {
        slug: "pregnancy_experienced_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.pregnancy_experienced?.includes(
              "OT"
            ) || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "pregnancy_experienced_other",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singletext",
      },
    ],
  },
  {
    slug: "hormonal",
    section: "Hormonal Health",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Hormonal Health",
      getText: () =>
        "Your current hormonal status and symptoms play a critical role in your vaginal health.",
    },
    educationalMoment: {
      getHeader: (healthContextValues) => {
        if (
          healthContextValues?.hormonal_menopause_symptoms?.includes(
            "vaginal"
          ) ||
          healthContextValues?.hormonal_menopause_symptoms?.includes("urinary")
        ) {
          return "You're not alone";
        } else {
          return "Did you know?";
        }
      },
      getText: (healthContextValues) => {
        if (
          healthContextValues?.hormonal_menopause &&
          healthContextValues?.hormonal_menopause !== "NO" &&
          !healthContextValues?.hormonal_menopause_symptoms?.includes(
            "vaginal"
          ) &&
          !healthContextValues?.hormonal_menopause_symptoms?.includes("urinary")
        ) {
          return "Hormonal changes during menopause, specifically a decrease in estrogen, have an impact on the vaginal microbiome (Gandhi 2016).";
        } else if (
          healthContextValues?.hormonal_menopause_symptoms?.includes(
            "vaginal"
          ) ||
          healthContextValues?.hormonal_menopause_symptoms?.includes("urinary")
        ) {
          return "Vaginal symptoms and UTIs are unfortunately more common in menopause (Jung 2019). Fortunately, we know this change is often tied to the vaginal microbiome — you're in the right place!";
        }
      },
      showEducationalMoment: ({ healthContextValues }) => {
        return (
          (healthContextValues?.hormonal_menopause &&
            healthContextValues?.hormonal_menopause !== "NO") ||
          false
        );
      },
    },
    questions: [
      {
        slug: "hormonal_menopause",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "hormonal_menopause",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singleselect",
      },
      {
        slug: "hormonal_menopause_hormones",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            (currentPageHealthContextValues?.hormonal_menopause &&
              currentPageHealthContextValues?.hormonal_menopause !== "NO" &&
              currentPageHealthContextValues?.hormonal_menopause !== "EA") ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "hormonal_menopause_hormones",
            healthContext: healthContextValues,
            defaultAns: [],
          });
        },
        answerType: "multiselect",
      },
      {
        slug: "hormonal_menopause_hormones_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.hormonal_menopause_hormones?.includes(
              "other"
            ) || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "hormonal_menopause_hormones_other",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singletext",
      },
      {
        slug: "hormonal_menopause_symptoms",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            (currentPageHealthContextValues?.hormonal_menopause &&
              currentPageHealthContextValues?.hormonal_menopause !== "NO" &&
              currentPageHealthContextValues?.hormonal_menopause !== "EA") ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "hormonal_menopause_symptoms",
            healthContext: healthContextValues,
            defaultAns: [],
          });
        },
        answerType: "multiselect",
      },
      {
        slug: "hormonal_menopause_hysterectomy",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return currentPageHealthContextValues?.hormonal_menopause === "EA";
        },
        getInitialValue: ({ healthContextValues }) => {
          return prefillAnswer({
            questionName: "hormonal_menopause_hysterectomy",
            healthContext: healthContextValues,
            defaultAns: "",
          });
        },
        answerType: "singleselect",
      },
    ],
  },
  {
    slug: "recent-period",
    section: "Hormonal Health",
    showPage: ({ healthContextValues }) => {
      return (
        healthContextValues?.hormonal_menopause !== "EA" &&
        healthContextValues?.hormonal_menopause !== "YE" &&
        healthContextValues?.pregnancy_status !== "CU"
      );
    },
    educationalMoment: {
      alwaysShow: true,
      getHeader: () => "Did you know?",
      getText: () =>
        "Your vaginal microbiome typically changes at different points in your cycle.",
    },
    questions: [
      {
        slug: "basics_recent_period",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.basics_recent_period;
        },
        answerType: "singleselect",
      },
      {
        slug: "basics_last_period_date",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return currentPageHealthContextValues?.basics_recent_period === "YE";
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.basics_last_period_date;
        },
        answerType: "date",
      },
      {
        slug: "hormonal_period_counts",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return currentPageHealthContextValues?.basics_recent_period === "YE";
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.hormonal_period_counts;
        },
        answerType: "singleselect",
      },
      {
        slug: "hormonal_period_products",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return currentPageHealthContextValues?.basics_recent_period === "YE";
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.hormonal_period_products;
        },
        answerType: "multiselect",
      },
      {
        slug: "hormonal_period_products_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            (currentPageHealthContextValues?.basics_recent_period === "YE" &&
              currentPageHealthContextValues?.hormonal_period_products?.includes(
                "OT"
              )) ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.hormonal_period_products_other;
        },
        answerType: "singletext",
      },
    ],
  },
  {
    slug: "contraception",
    section: "Hormonal Health",
    showPage: ({ healthContextValues }) => {
      return (
        healthContextValues?.hormonal_menopause !== "EA" &&
        healthContextValues?.hormonal_menopause !== "YE" &&
        healthContextValues?.pregnancy_status !== "CU"
      );
    },
    questions: [
      {
        slug: "contraception_types_used",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.contraception_types_used;
        },
        answerType: "multiselect",
      },
      {
        slug: "contraception_type_other",
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.contraception_types_used?.includes(
              "OT"
            ) || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.contraception_type_other;
        },
        answerType: "singletext",
      },
      {
        slug: "contraception_brand",
        showQuestion: ({ currentPageHealthContextValues }) => {
          const contraceptionTypesUsed =
            currentPageHealthContextValues?.contraception_types_used;
          return (
            (contraceptionTypesUsed &&
              contraceptionTypesUsed?.length > 0 &&
              !contraceptionTypesUsed.includes("NO") &&
              (contraceptionTypesUsed.includes("PI") ||
                contraceptionTypesUsed.includes("PM") ||
                contraceptionTypesUsed.includes("IH") ||
                contraceptionTypesUsed.includes("IC") ||
                contraceptionTypesUsed.includes("IN") ||
                contraceptionTypesUsed.includes("IM") ||
                contraceptionTypesUsed.includes("PA") ||
                contraceptionTypesUsed.includes("RI") ||
                contraceptionTypesUsed.includes("TO"))) ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.contraception_brand;
        },
        answerType: "singletext",
      },
    ],
  },
  {
    slug: "hormonal-symptoms",
    section: "Hormonal Health",
    showPage: ({ healthContextValues }) => {
      return (
        healthContextValues?.hormonal_menopause === "NO" &&
        healthContextValues?.pregnancy_status !== "CU"
      );
    },
    educationalMoment: {
      getHeader: () => "Us, too",
      getText: () =>
        "Around 85% of Evvy testers are experiencing at least one hormone related symptom.",
      showEducationalMoment: ({ healthContextValues }) => {
        return (
          (healthContextValues?.hormonal_symptoms &&
            healthContextValues?.hormonal_symptoms?.length > 0 &&
            !healthContextValues?.hormonal_symptoms?.includes("NO")) ||
          false
        );
      },
    },
    questions: [
      {
        slug: "hormonal_symptoms" as keyof HealthContextFields,
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.hormonal_symptoms || false;
        },
        answerType: "multiselect",
      },
    ],
  },
  {
    slug: "quality-of-life",
    section: "Quality of Life",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Quality of Life",
      getText: () =>
        "We know that vaginal symptoms can have a big impact on your overall wellbeing.",
    },
    educationalMoment: {
      getHeader: () => "We're with you",
      getText: () => "We started Evvy because we deserve better.",
      showEducationalMoment: ({ healthContextValues }) => {
        return (
          (healthContextValues?.related_diagnoses_quality_of_life &&
            healthContextValues?.related_diagnoses_quality_of_life >= 6) ||
          false
        );
      },
    },
    questions: [
      {
        slug: "related_diagnoses_quality_of_life",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return String(healthContextValues.related_diagnoses_quality_of_life);
        },
        handleValidate: ({ healthContextValues }) => {
          return validateNumber(
            healthContextValues?.related_diagnoses_quality_of_life
          );
        },
        answerType: "number_scale",
      },
      {
        slug: "quality_of_life_most_affected" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            (currentPageHealthContextValues?.related_diagnoses_quality_of_life &&
              currentPageHealthContextValues?.related_diagnoses_quality_of_life >=
                6) ||
            false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.quality_of_life_most_affected || false;
        },
        answerType: "multiselect",
      },
    ],
  },
  {
    slug: "why-evvy",
    section: "Why Evvy",
    showPage: () => true,
    questions: [
      {
        slug: "basics_why_evvy" as keyof HealthContextFields,
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.basics_why_evvy;
        },
        answerType: "multiselect",
      },
      {
        slug: "basics_why_evvy_other" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.basics_why_evvy?.includes(
              "other"
            ) || false
          );
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.basics_why_evvy_other;
        },
        answerType: "singletext",
      },
    ],
  },
  {
    slug: "more-info",
    section: "How did you hear about us?",
    showPage: ({ hdyhauSurvey }) => {
      return !hdyhauSurvey?.uid;
    },
    formatPayload: ({ currentPageHealthContextValues }) => {
      return {
        data: { hdyhau: currentPageHealthContextValues },
      };
    },
    questions: [
      {
        slug: "hdyhau" as keyof HealthContextFields,
        showQuestion: () => true,
        getOptions: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?._options.hdyhau || [];
        },
        getInitialValue: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?.hdyhau || "";
        },
        answerType: "singleselect",
      },
      {
        slug: "hdyhau_other" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "hdyhau" as keyof HealthContextFields
            ] === "OT"
          );
        },
        getInitialValue: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?.hdyhau_other || "";
        },
        answerType: "singletext",
      },
      {
        slug: "when_heard_about_evvy" as keyof HealthContextFields,
        showQuestion: () => true,
        getOptions: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?._options?.when_heard_about_evvy || [];
        },
        getInitialValue: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?.when_heard_about_evvy || "";
        },
        answerType: "singleselect",
      },
      {
        slug: "which_advertisement" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "hdyhau" as keyof HealthContextFields
            ] === "AD"
          );
        },
        getOptions: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?._options?.which_advertisement || [];
        },
        getInitialValue: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?.which_advertisement || "";
        },
        answerType: "singleselect",
      },
      {
        slug: "which_advertisement_other" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "which_advertisement" as keyof HealthContextFields
            ] === "OT" &&
            currentPageHealthContextValues?.[
              "hdyhau" as keyof HealthContextFields
            ] === "AD"
          );
        },
        getInitialValue: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?.which_advertisement_other || "";
        },
        answerType: "singletext",
      },
      {
        slug: "which_news" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "hdyhau" as keyof HealthContextFields
            ] === "NE"
          );
        },
        getOptions: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?._options?.which_advertisement || [];
        },
        getInitialValue: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?.which_advertisement || "";
        },
        answerType: "singleselect",
      },
      {
        slug: "which_social_media" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "hdyhau" as keyof HealthContextFields
            ] === "SM"
          );
        },
        getOptions: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?._options?.which_social_media || [];
        },
        getInitialValue: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?.which_social_media || "";
        },
        answerType: "singleselect",
      },
      {
        slug: "which_social_media_other" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "which_social_media" as keyof HealthContextFields
            ] === "OT" &&
            currentPageHealthContextValues?.[
              "hdyhau" as keyof HealthContextFields
            ] === "SM"
          );
        },
        getInitialValue: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?.which_social_media_other || "";
        },
        answerType: "singletext",
      },
      {
        slug: "which_influencer" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "hdyhau" as keyof HealthContextFields
            ] === "IN"
          );
        },
        getInitialValue: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?.which_influencer || "";
        },
        answerType: "singletext",
      },
      {
        slug: "which_community" as keyof HealthContextFields,
        showQuestion: ({ currentPageHealthContextValues }) => {
          return (
            currentPageHealthContextValues?.[
              "hdyhau" as keyof HealthContextFields
            ] === "CO"
          );
        },
        getInitialValue: ({ hdyhauSurvey }) => {
          return hdyhauSurvey?.which_community || "";
        },
        answerType: "singletext",
      },
    ],
  },
  {
    slug: "done",
    section: "What's next?",
    sectionIntro: {
      getHeader: () => "All done!",
      getText: () =>
        "Thank you so much for trusting Evvy with your vaginal health. We can't wait to get you answers and next steps soon.",
    },
    showPage: () => true,
    formatPayload: ({ currentPageHealthContextValues }) => {
      return {
        data: { hdyhau: currentPageHealthContextValues },
      };
    },
    questions: [],
  },
];

export const getNextPage = ({
  currentPage,
  healthContextValues,
  test,
  ldtEnabled,
  expanUpsellEnabled,
  hdyhauSurvey,
}: {
  currentPage: Page;
  healthContextValues: HealthContextFields;
  test: Test;
  ldtEnabled: boolean;
  expanUpsellEnabled: boolean;
  hdyhauSurvey?: HdyhauSurvey;
}): Page | undefined => {
  const currentIndex = HEALTH_HISTORY_PAGES.findIndex(
    (page) => page.slug === currentPage.slug
  );
  const hasPreviousTestWithoutCare =
    test?.has_other_active_test &&
    !test?.previous_test_care.previous_test_completed_care;
  const hasPreviousTestWithCare =
    test?.previous_test_care.previous_test_completed_care;

  for (let i = currentIndex + 1; i < HEALTH_HISTORY_PAGES.length; i++) {
    const page = HEALTH_HISTORY_PAGES[i];
    if (
      page.showPage({
        healthContextValues,
        hasPreviousTestWithoutCare,
        hasPreviousTestWithCare,
        ldtEnabled,
        expanUpsellEnabled,
        hdyhauSurvey,
      })
    ) {
      return page;
    }
  }
  return undefined;
};

export const getPreviousPage = ({
  currentPage,
  healthContextValues,
  test,
  ldtEnabled,
  expanUpsellEnabled,
}: {
  currentPage: Page;
  healthContextValues: HealthContextFields;
  test: Test;
  ldtEnabled: boolean;
  expanUpsellEnabled: boolean;
}): Page | undefined => {
  const currentIndex = HEALTH_HISTORY_PAGES.findIndex(
    (page) => page.slug === currentPage.slug
  );
  const hasPreviousTestWithoutCare =
    test?.has_other_active_test &&
    !test?.previous_test_care.previous_test_completed_care;
  const hasPreviousTestWithCare =
    test?.previous_test_care.previous_test_completed_care;

  for (let i = currentIndex; i > 0; i--) {
    if (i - 1 >= 0) {
      const page = HEALTH_HISTORY_PAGES[i - 1];
      if (
        page.showPage({
          healthContextValues,
          hasPreviousTestWithoutCare,
          hasPreviousTestWithCare,
          ldtEnabled,
          expanUpsellEnabled,
        })
      ) {
        return page;
      }
    } else {
      return undefined;
    }
  }
};
