import { useState } from "react";

import { ShowDetailButton } from "../common/showDetailButton";

import { sendExpandedResultsCard } from "../../utils/analytics/customEventTracking";
import { useBaseAnalyticsArgs } from "../../hooks/useBaseAnalyticsArgs";
import { ResistanceResult } from "../../types/results";

const AMR_DESCRIPTION =
  "Antimicrobial resistance (AMR) genes can reveal the resistance potential within a sample’s microbial community to certain treatments.";

const QUESTION_ANSWERS = [
  {
    question:
      "If I test positive for an antimicrobial resistance, does it mean that medications within the associated antimicrobial class definitely won’t work for me? ",
    answer:
      "Not necessarily. PCR AMR testing is looking for resistance genes within an entire sample. It doesn’t differentiate between bacteria, so it doesn’t tell us which specific bacteria in the sample has the resistance gene. There may also be more than one bacteria in your sample that carries the resistance gene.",
  },
  {
    question: "How does this affect my treatment? ",
    answer:
      "Information from your AMR results can help guide future treatment decisions by revealing resistance potential. You may also gain insight into why a medication hasn’t worked for you in the past, and which treatments may have a higher chance of being effective.",
  },
];

const AMR_TITLE = "Antimicrobial resistance(s): ";

export const ResistanceResults: React.FC<{
  resistanceResults: ResistanceResult[];
  className?: string;
}> = ({ resistanceResults, className }) => {
  const [showDetails, setShowDetails] = useState(false);
  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  const detectedResistances = resistanceResults.filter(
    (resistanceResult) => resistanceResult.detected
  );
  const detectedResistanceNames = detectedResistances.map(
    (resistanceResult) => resistanceResult.resistance.display_name
  );

  return (
    <div className={className}>
      <div className="flex justify-between content-center my-auto">
        <span>
          <span className="t3 semibold">{AMR_TITLE}</span>
          <span className="text-base sm:text-lg medium">{`${detectedResistanceNames.join(
            ", "
          )}`}</span>
        </span>
        <div className="mt-1.5">
          <ShowDetailButton
            open={showDetails}
            toggleOpenClose={() => {
              setShowDetails(!showDetails);
              sendExpandedResultsCard({
                cardType: "resistance-results",
                ...baseAnalyticsArgs,
              });
            }}
          />
        </div>
      </div>
      {showDetails && (
        <div className="mt-5 text-base sm:text-lg ">
          <div className="medium pb-3">{AMR_DESCRIPTION}</div>
          <ol className="list-disc">
            {QUESTION_ANSWERS.map((qa, i) => (
              <li className="" key={i}>
                <span className="medium">{qa.question}</span>
                <span className="italic regular">{qa.answer}</span>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
};
