import PageWrapper from "../../../../components/care/consultIntake/ungatedRx/PageWrapper";
import { ConsultIntake } from "../../../../types/care";
import { getQuestionTitle } from "../../../../utils/questions";
import { Field, Form, Formik } from "formik";
import { indexCheckboxStyles } from "../../../../utils/colors";
import { cn } from "../../../../utils/cn";
import { useEffect, useState } from "react";
import { sendConsultIntakeViewSection } from "../../../../utils/analytics/customEventTracking";

interface SymptomsProps {
  consultIntake: ConsultIntake;
  submitPage: (data: any) => void;
  isSubmitting: boolean;
}

type FormValues = {
  [key: string]: string;
};

interface CheckboxStates {
  [key: string]: boolean;
}

const Symptoms = ({
  submitPage,
  consultIntake,
  isSubmitting,
}: SymptomsProps) => {
  const additionalSymptoms = Object.keys(consultIntake).filter((key) =>
    key.startsWith("symptoms_additional_")
  );

  const [isNoneChecked, setIsNoneChecked] = useState(() =>
    additionalSymptoms.every(
      (symptom) => consultIntake[symptom as keyof ConsultIntake] === "NO"
    )
  );

  const [checkedSymptoms, setCheckedSymptoms] = useState<CheckboxStates>(() => {
    const initial: CheckboxStates = {};
    additionalSymptoms.forEach((symptom) => {
      initial[symptom] =
        consultIntake[symptom as keyof ConsultIntake] !== "NO" &&
        consultIntake[symptom as keyof ConsultIntake] !== "";
    });
    return initial;
  });

  const handleSubmit = (values: any) => {
    // set all unselected symptoms to "NO" before submitting
    const updatedValues = { ...values };
    additionalSymptoms.forEach((symptom) => {
      if (updatedValues[symptom] === "") {
        updatedValues[symptom] = "NO";
      }
    });
    submitPage(updatedValues);
  };

  const handleNoneChange = (
    setFieldValue: (field: string, value: any) => void
  ) => {
    // reset all checkbox states stored in state
    setCheckedSymptoms((prev) => {
      const newState = { ...prev };
      additionalSymptoms.forEach((symptom) => {
        newState[symptom] = false;
      });
      return newState;
    });
    // reset all checkbox values to empty string
    additionalSymptoms.forEach((symptomKey) => {
      setFieldValue(symptomKey, "");
    });

    setIsNoneChecked(!isNoneChecked);
  };

  const severityOptions = consultIntake?._options?.symptoms_additional || [];

  const handleSymptomChange = (
    setFieldValue: (field: string, value: any) => void,
    symptom: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    setCheckedSymptoms((prev) => ({ ...prev, [symptom]: isChecked }));

    setFieldValue(symptom, "");

    if (isNoneChecked) setIsNoneChecked(false);
  };

  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: consultIntake?.consult?.test_hash,
      section: "symptoms",
      consultType: consultIntake.consult.type,
    });
  }, [consultIntake.consult?.test_hash, consultIntake.consult.uid]);

  return (
    <>
      <PageWrapper section="Symptoms">
        <div className="b2 mb-4">{getQuestionTitle("ungated_rx_symptoms")}</div>
        <Formik
          initialValues={
            {
              ...Object.fromEntries(
                additionalSymptoms.map((key) => [
                  key,
                  consultIntake[key as keyof ConsultIntake] || "",
                ])
              ),
            } as FormValues
          }
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Form className="mb-0">
              <div role="group" aria-labelledby="checkbox-group">
                <label
                  className="mb-4 cursor-pointer flex items-center"
                  key="symptoms_additional_none"
                >
                  <input
                    type="checkbox"
                    className={indexCheckboxStyles(0)}
                    checked={isNoneChecked}
                    onChange={() => {
                      handleNoneChange(setFieldValue);
                    }}
                  />
                  <span className="ml-3.5">No current symptoms</span>
                </label>

                {additionalSymptoms.map((symptom, index) => (
                  <div key={symptom} className="mb-5 last:mb-0">
                    <label
                      className={cn("mb-5 cursor-pointer flex items-center", {
                        "mb-0": index === additionalSymptoms.length - 1,
                      })}
                    >
                      <input
                        type="checkbox"
                        className={indexCheckboxStyles(index + 1)}
                        checked={checkedSymptoms[symptom]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleSymptomChange(setFieldValue, symptom, e);
                        }}
                      />
                      <span className="ml-3.5">
                        {getQuestionTitle(symptom)}
                      </span>
                    </label>

                    {checkedSymptoms[symptom] && (
                      <div className="flex flex-row ml-8">
                        {severityOptions
                          .filter(
                            (followupOption) => followupOption[0] !== "NO"
                          )
                          .map(([optionKey, optionLabel]) => (
                            <label
                              key={optionKey}
                              className={cn(
                                `w-full text-center flex justify-center cursor-pointer p-3 rounded-xl mr-2`,
                                {
                                  "bg-evvy-blue-light border-evvy-blue border-2":
                                    values[symptom] === optionKey,
                                  "border-evvy-grey border hover:bg-evvy-blue-light hover:border-evvy-blue hover:border":
                                    values[symptom] !== optionKey,
                                }
                              )}
                            >
                              <Field
                                type="radio"
                                name={symptom}
                                value={optionKey}
                                className="hidden"
                              />
                              <span className="cursor-pointer w-full">
                                {optionLabel}
                              </span>
                            </label>
                          ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <PageWrapper.FormFooter
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                buttonCTA="Next"
                disabled={
                  !isNoneChecked &&
                  (Object.entries(checkedSymptoms).every(
                    ([_, isChecked]) => !isChecked
                  ) || // no checkboxes selected
                    Object.entries(checkedSymptoms).some(
                      ([symptom, isChecked]) =>
                        isChecked &&
                        (values[symptom] === "" ||
                          values[symptom] === undefined) // any checked box without a severity
                    ))
                }
              />
            </Form>
          )}
        </Formik>
      </PageWrapper>
    </>
  );
};

export default Symptoms;
