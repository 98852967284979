import { useState, useEffect, useCallback } from "react";
import { testsService } from "../../services/tests";
import { ALaCareRecommendations } from "../../types/care";

export const useALaCareRecommendations = (testHash?: string) => {
  const [data, setData] = useState<ALaCareRecommendations>();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  const fetchALaCareRecommendations = useCallback(async () => {
    if (testHash) {
      setIsLoading(true);
      try {
        const response = await testsService.fetchTestALaCareRecommendations(
          testHash
        );
        setData(response.data);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [testHash]);

  useEffect(() => {
    if (testHash) fetchALaCareRecommendations();
  }, [testHash, fetchALaCareRecommendations, refetch]);

  const refetchALaCareRecommendations = () => {
    setRefetch((prev) => !prev);
  };

  return {
    aLaCareRecommendations: data,
    error,
    isLoading,
    refetchALaCareRecommendations,
  };
};
