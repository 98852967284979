import { FC, useMemo, useState } from "react";
import {
  ProviderTestData,
  ProviderTestOrder,
  Status,
} from "../../types/provider";
import {
  ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { pdf } from "@react-pdf/renderer";
import PDFMerger from "pdf-merger-js";
import MyDocument from "../results_v2/PdfDoc";
import LoadingSpinner from "../../components/common/loadingSpinner";

import { ReactComponent as DownloadTray } from "../../components/results_v2/icons/download-tray.svg";
import {
  sendProviderClickedDownloadResults,
  sendProviderClickedFilterText,
} from "../../utils/analytics/customEventTracking";
import { providerService } from "../../services/provider";
import { BellAlertIcon } from "@heroicons/react/24/outline";
import Modal from "../../components/common/modal";
import BlueRectangularButton from "../../components/common/blueRectangularButton";
import RectangularButton from "../../components/common/rectangularButton";
import { ProblemsSummary } from "../../types/test";
import ProblemsModulePdf from "../results_v2/ProblemsModulePdf";

type ProviderTestTableProps = {
  orders: ProviderTestOrder[];
};

const columnHelper = createColumnHelper<ProviderTestData>();

const columns = [
  columnHelper.accessor("orderDate", {
    header: "Provider Order Date",
    cell: (props) => {
      const providerOrderString = props.getValue();
      const orderDate = new Date(
        Date.parse(providerOrderString)
      ).toLocaleDateString();

      return (
        <div
          title={new Date(Date.parse(providerOrderString)).toLocaleTimeString()}
        >
          {orderDate}
        </div>
      );
    },
  }),
  columnHelper.accessor("patientEmail", {
    header: "Patient Email",
  }),
  columnHelper.accessor("testHash", {
    header: "Test Hash",
  }),
  columnHelper.accessor("sampleDate", {
    header: "Sample Taken On",
    cell: (props) => {
      const sampleDateString = props.getValue();
      const sampleDate = sampleDateString
        ? new Date(Date.parse(sampleDateString)).toLocaleDateString()
        : "-";

      return (
        <div
          title={
            sampleDateString &&
            new Date(Date.parse(sampleDateString)).toLocaleTimeString()
          }
        >
          {sampleDate}
        </div>
      );
    },
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (props) => {
      const status = props.getValue();
      const patientEmail = props.row.getValue("patientEmail") as string;

      return <StatusPill status={status} patientEmail={patientEmail} />;
    },
  }),
  columnHelper.accessor("careEligibilityInfo", {
    header: "Care Eligibility",
    cell: (props) => {
      const careEligibilityInfo = props.getValue();
      if (careEligibilityInfo) {
        const statusColor =
          careEligibilityInfo?.status === "Eligible" ||
          careEligibilityInfo?.status === "Enrolled"
            ? "bg-green-300"
            : careEligibilityInfo?.status === "Eligibility lapsed"
            ? "bg-yellow-300"
            : "bg-red-300";
        return (
          <div
            className={`rounded-full ${statusColor} w-fit px-4 py-1 whitespace-nowrap`}
            title={careEligibilityInfo?.tooltip}
          >
            {careEligibilityInfo?.status}
          </div>
        );
      } else {
        return <div />;
      }
    },
  }),
  columnHelper.accessor("resultsData", {
    header: "Get Results",
    enableColumnFilter: false,
    enableSorting: false,
    cell: (props) => {
      const resultsData = props.getValue();
      const bacteriaData = resultsData.bacteriaData;
      const status = props.row.getValue("status");
      const testHash = props.row.getValue("testHash") as string;
      const resultsPDFUrl = resultsData.reportUrl;
      const problemsSummary = resultsData.problemsSummary;
      return (
        <div className="min-h-[40px]">
          {bacteriaData &&
            bacteriaData.length > 0 &&
            status === Status.READY && (
              <DownloadButton
                bacteriaData={bacteriaData}
                testHash={testHash}
                resultsPDFUrl={resultsPDFUrl}
                problemsSummary={problemsSummary}
                testData={props.row.original}
              />
            )}
        </div>
      );
    },
  }),
  columnHelper.accessor("hasAddedExpan", {
    header: "Has Added STI Test",
    enableColumnFilter: false,
    enableSorting: false,
    cell: (props) => {
      const hasAddedExpan = props.getValue();
      // Return checkmark if true, nothing if false
      return (
        <div>
          {hasAddedExpan && (
            <div className="t2 flex flex-col items-center ">✓</div>
          )}
        </div>
      );
    },
  }),
];

export const ProviderTestTable: FC<ProviderTestTableProps> = ({ orders }) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const data = useMemo(
    () =>
      orders.map((order) => {
        return {
          patientEmail: order.patient_email || "",
          testHash: order.test_pretty_hash || "",
          orderDate: order.create_date,
          sampleDate: order.sample_taken_at,
          status: order?.status as Status,
          resultsData: {
            reportUrl: order?.results_pdf_url,
            bacteriaData: order?.bacteria_data,
            problemsSummary: order?.problems_summary,
          },
          hasAddedExpan: order.add_expanded_pcr,
          careEligibilityInfo: order.care_eligibility_info,
          problemsSummary: order.problems_summary,
          scores: order.scores,
          patientFirstName: order.patient_first_name,
          patientLastName: order.patient_last_name,
        };
      }),
    [orders]
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      globalFilter,
      columnFilters,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const filteredStatus = columnFilters.find(
    (filter) => filter.id === "status"
  )?.value;

  return (
    <>
      <input
        id="first_name"
        name="first_name"
        type="text"
        autoFocus
        autoComplete="given-name"
        required
        placeholder="Search..."
        value={globalFilter}
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="my-2 appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm  focus:outline-none focus:ring-transparent focus:border-crazy-purple rounded-xl"
      />
      <div className="my-4 flex gap-2 flex-wrap ">
        {Object.values(Status)
          .filter(
            (status) =>
              status !== Status.RECEIVED_AT_LAB && status !== Status.SEQUENCING
          )
          .map((status) => (
            <button
              key={status}
              className={`px-4 py-2 text-[12px] text-evvy-pine  border-evvy-pine border rounded-full md:hover:bg-evvy-pine md:hover:text-white ${
                status === filteredStatus
                  ? "bg-evvy-pine text-white"
                  : "bg-white"
              }`}
              onClick={() => {
                if (status === filteredStatus) {
                  setColumnFilters([]);
                } else {
                  setColumnFilters([{ id: "status", value: status }]);
                  sendProviderClickedFilterText({
                    filterText: status,
                  });
                }
              }}
            >
              {status}
            </button>
          ))}
      </div>
      <div className="overflow-auto md:overflow-visible">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="align-text-top"
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <span className="ml-1 text-[12px]">▲</span>,
                            desc: <span className="ml-1 text-[12px]">▼</span>,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {data.length > 10 && (
          <div className="flex items-center gap-2">
            <button
              className="border rounded text-lg p-2"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {"<<"}
            </button>
            <button
              className="border rounded text-lg p-2 px-4"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </button>
            <button
              className="border rounded text-lg p-2 px-4"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {">"}
            </button>
            <button
              className="border rounded text-lg p-2"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {">>"}
            </button>
            <span className="flex items-center gap-1">
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()}
              </strong>
            </span>
          </div>
        )}
      </div>
    </>
  );
};

const DownloadButton = ({
  bacteriaData,
  testHash,
  testData,
  resultsPDFUrl,
  problemsSummary,
}: {
  bacteriaData: any;
  testHash: string;
  resultsPDFUrl?: string;
  testData?: any;
  problemsSummary: ProblemsSummary;
}) => {
  const [loading, setLoading] = useState(false);
  const resultsFilename = `evvy_results_test_${testHash}.pdf`;

  // PDF downloader that combines MDX PDF report with Evvy Results PDF
  const onDownloadResultsClick = async () => {
    setLoading(true);
    const showIntroPage = resultsPDFUrl ? false : true;

    // generate Evvy PDF
    const ogDoc = (
      <MyDocument microbiome={bacteriaData} showIntroPage={showIntroPage} />
    );
    const asPdf = pdf();
    asPdf.updateContainer(ogDoc);
    let evvyGeneratedPdfBlob = await asPdf.toBlob();

    // generate problems module PDF
    const problemsModulePdf = (
      <ProblemsModulePdf
        problemsSummary={problemsSummary}
        testData={testData}
      />
    );
    const problemsModulePdfDoc = pdf();
    problemsModulePdfDoc.updateContainer(problemsModulePdf);
    let problemsModulePdfBlob = await problemsModulePdfDoc.toBlob();

    // typescript doesn't recognize saveAsBlob() method
    let merger = new PDFMerger() as any;

    const problemsModuleArrayBuffer = await problemsModulePdfBlob.arrayBuffer();
    await merger.add(problemsModuleArrayBuffer, null);

    // if we have user-facing report, then include prepend problems module pdf
    // and append evvy report to the end
    if (resultsPDFUrl) {
      const response = await fetch(resultsPDFUrl);
      let userFacingReportBlob = await response.blob();
      const reportArrayBuffer = await userFacingReportBlob.arrayBuffer();
      await merger.add(reportArrayBuffer, null);
    }

    const evvyReportArrayBuffer = await evvyGeneratedPdfBlob.arrayBuffer();
    await merger.add(evvyReportArrayBuffer, null);

    const mergedPdf = await merger.saveAsBlob();
    const url = window.URL.createObjectURL(mergedPdf);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", resultsFilename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setLoading(false);
  };

  return (
    <div className="w-full">
      <button
        disabled={loading}
        className={`rounded-lg bg-gray-100 w-fit p-2 px-3 font-normal "mx-auto block`}
        onClick={() => {
          onDownloadResultsClick();
          sendProviderClickedDownloadResults();
        }}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="t2 flex flex-col items-center ">
            ↓
            <DownloadTray className="-mt-[6px] " />
          </div>
        )}
      </button>
    </div>
  );
};

const StatusPill = ({
  patientEmail,
  status,
}: {
  patientEmail: string;
  status: Status;
}) => {
  const [error, setError] = useState<string | null>(null);
  const [reminderSent, setReminderSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const statusColor =
    status === Status.RECEIVED_AT_LAB ||
    status === Status.SEQUENCING ||
    status === Status.RECEIVED
      ? "bg-yellow-300"
      : status === Status.READY
      ? "bg-green-300"
      : "bg-red-300";

  const handleRemind = async () => {
    try {
      setError(null);
      setLoading(true);
      await providerService.remindPatient(patientEmail);
      setReminderSent(true);
      setTimeout(() => setIsModalOpen(false), 2000);
    } catch (err) {
      setError("Failed to send reminder. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative">
      <div className="flex items-center">
        <div
          className={`rounded-full ${statusColor} px-4 py-1 whitespace-nowrap text-center w-fit flex items-center`}
        >
          {status}
        </div>
        {status === Status.WAITING && (
          <BellAlertIcon
            className="h-8 w-8 ml-2 cursor-pointer rounded-full hover:bg-gray-200 p-1"
            onClick={() => setIsModalOpen(true)}
          />
        )}
      </div>
      {isModalOpen && (
        <Modal
          widthClass="w-full md:w-6/12 p-6"
          preventBodyScroll
          closeModal={() => {
            setIsModalOpen(false);
            setReminderSent(false);
          }}
        >
          <h2 className="text-xl font-bold mb-4">
            Send a reminder to this patient?
          </h2>
          <p className="mb-6">
            Our records show your patient has not completed their order of the
            Evvy test. Help your patients stay on top of their vaginal
            microbiome health by sending a gentle reminder to finish ordering!
          </p>

          <div className="flex gap-2">
            <BlueRectangularButton
              onClick={handleRemind}
              text={reminderSent ? "Sent!" : "Send reminder"}
              fullWidth
              loading={loading}
              disabled={reminderSent}
            />
            <RectangularButton
              bgColorClass="bg-white"
              textColorClass="text-black"
              borderColor="border-black"
              onClick={() => {
                setIsModalOpen(false);
              }}
              text="Cancel"
              fullWidth
            />
          </div>
          {error && <div className="text-red-500 mt-2">{error}</div>}
        </Modal>
      )}
    </div>
  );
};
