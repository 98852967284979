import { Link } from "react-router-dom";

const UngatedRxIntakeHeader = ({
  previousPage,
  baseUrl,
  hasBoricAcidTreatment,
  allowReupload,
}: {
  previousPage: string;
  baseUrl: string;
  hasBoricAcidTreatment: boolean;
  allowReupload: boolean;
}) => {
  const steps = [
    {
      name: "Intake Form",
      sections: [
        "consent",
        "allergies",
        "symptoms",
        hasBoricAcidTreatment ? "boric-acid" : "",
      ],
      color: "crazy-purple",
    },
    { name: "Shipping", sections: ["shipping"], color: "coral" },
    {
      name: "ID Verification",
      sections: ["photo", allowReupload ? "reupload" : ""],
      color: "dv-pond",
    },
  ];

  const getCorrespondingSection = (section: string) => {
    return steps.find((step) => step.sections.includes(section))?.name;
  };

  const currentStep = getCorrespondingSection(
    window.location.pathname
      .split("/")
      .filter((part) => part !== "")
      .pop() || ""
  );

  const showNav = !window.location.pathname.includes("/done/");
  const isPreviousPageHome =
    previousPage === "/care/" || previousPage === "/tests/";

  return (
    <div className="fixed top-0 left-0 right-0 bg-evvy-cream z-50">
      <nav className="w-full px-6 md:px-20 py-4 sm:py-5 flex justify-between align-center border-b-2 border-gray-300 border-solid pointer-events-none">
        <Link
          to={isPreviousPageHome ? previousPage : `${baseUrl}${previousPage}`}
        >
          <button
            className="p-2 bg-evvy-cream rounded-full border-2 border-evvy-black text-evvy-black group flex items-center pointer-events-auto"
            aria-label="Back to care"
          >
            <span className="uppercase w-7 h-7 text-center">
              <h4 className="font-bold leading-6">←</h4>
            </span>
          </button>
        </Link>

        {showNav && (
          <div className="mx-auto w-full hidden sm:flex max-w-5xl items-center">
            {steps.map((step, index) => (
              <div
                key={step.name}
                className="flex-1 flex items-center justify-center color-evvy-black p-2 t4 text-center uppercase tracking-wider pointer-events-none font-semibold border-t-4 border-transparent"
              >
                <div
                  className={`border rounded-full text-center mr-2 ${
                    currentStep === step.name
                      ? `border-${step.color} bg-${step.color} text-white`
                      : "border-black"
                  }`}
                >
                  <div className="w-8 h-8 leading-6 flex items-center justify-center b1 semibold rounded-full">
                    {index + 1}
                  </div>
                </div>
                <div className="uppercase">{step.name}</div>
              </div>
            ))}
          </div>
        )}

        <Link to="/tests" className="pointer-events-auto">
          <img
            className="h-8 mt-2 sm:h-10"
            src="/images/evvy-logo-text.svg"
            loading="lazy"
            alt="Evvy Text Logo"
          />
        </Link>
      </nav>

      {showNav && (
        <div className="sm:hidden flex py-4 px-2 font-semibold uppercase tracking-wider border-b-2 border-gray-300 border-solid bg-evvy-cream">
          {steps.map((step, index) => (
            <div
              key={step.name}
              className={`flex items-center mx-auto ${
                currentStep === step.name ? "block" : "hidden"
              }`}
            >
              <div
                className={`border rounded-full border-${step.color} bg-${step.color} text-white text-center mr-2`}
              >
                <div className="w-6 h-6 block leading-6">{index + 1}</div>
              </div>
              <div className="uppercase">{step.name}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UngatedRxIntakeHeader;
