import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form } from "formik";

import ReactTooltip from "react-tooltip";

import { healthContextService } from "../../services/healthContext";
import { indexCheckboxStyles, indexRadioStyles } from "../../utils/colors";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";
import { uncheckNoneChangeHandler } from "../../utils/answerUtils";
import FormFooter from "./formFooter";

const TreatmentsForm = ({ ...subrouteProps }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // general error (str)
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, hash, isSubmitting, test } =
    subrouteProps;

  // for tooltips
  const treatmentsQuestion = useRef(null); // required
  const treatmentsOtherQuestion = useRef(null);
  const antibioticsQuestions = useRef(null);
  const antifungalsQuestions = useRef(null);
  const probioticsQuestions = useRef(null);
  const hormoneTherapyQuestions = useRef(null);
  const boricAcidQuestions = useRef(null);
  const feminineHygeneQuestions = useRef(null);
  const treatmentOtherQuestions = useRef(null);

  const extraQuestionMap = {
    treatments__AB: antibioticsQuestions,
    treatments__AF: antifungalsQuestions,
    treatments__PR: probioticsQuestions,
    treatments__HR: hormoneTherapyQuestions,
    treatments__BA: boricAcidQuestions,
    treatments__FH: feminineHygeneQuestions,
    treatments__OT: treatmentOtherQuestions,
  };

  const allQuestions = Object.assign(
    {
      treatments: treatmentsQuestion,
      treatments_other: treatmentsOtherQuestion,
    },
    extraQuestionMap
  );

  // construct extra context questions
  const questionTypes = ["brand", "why", "start", "end", "often", "helped"];
  var extraDataQuestions = [];
  healthContext._options.treatments
    .filter((o) => o[0] !== "NO")
    .forEach((o) => {
      questionTypes.forEach((q) => {
        const key = `treatments__${o[0]}__${q}`;
        const existingAnswer = healthContext.healthcontextextradata_set.filter(
          (e) => e.key === key
        )[0];
        extraDataQuestions.push({
          // 'questionRef': useRef(null),
          key: key,
          questionFamilyKey: `treatments__${o[0]}`,
          value: existingAnswer ? existingAnswer.value : "",
        });
      });
    });

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "context",
      questionGroup: "treatments",
      testOrder: test.test_order,
      testHash: test.hash,
      version: 1,
    });
  }, []);

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">Treatments</h3>
        <div className="flex-1 leading-5">
          As you probably guessed, what you put down there (and what you
          ingest!) impacts what types of microbes can exist in your vaginal
          microbiome.
        </div>
      </div>

      <Formik
        initialValues={Object.assign(
          {
            treatments: healthContext.treatments || [],
            treatments_other: healthContext.treatments_other || "",
          },
          extraDataQuestions.reduce(
            (obj, item) => Object.assign(obj, { [item.key]: item.value }),
            {}
          )
        )}
        validate={(values) => {
          var errors = {};
          Object.keys(allQuestions).forEach((key) =>
            ReactTooltip.hide(allQuestions[key].current)
          );
          ReactTooltip.rebuild();

          // required questions (multi select, needs at least 1)
          const requiredQuestions = ["treatments"];
          requiredQuestions.forEach((key) => {
            if (!values[key].length) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          // if user answered anything but "NO", make sure they answer extra data questions
          values.treatments
            .filter((t) => t !== "NO")
            .forEach((t) => {
              extraDataQuestions
                .filter((q) => q["questionFamilyKey"] == `treatments__${t}`)
                .forEach((q) => {
                  if (!values[q["key"]]) {
                    errors[q["questionFamilyKey"]] =
                      "Please answer all of these question";
                    ReactTooltip.show(
                      allQuestions[q["questionFamilyKey"]].current
                    ); // manually show, without requiring hover
                  } else if (values[q["key"]].length > 500) {
                    errors[q["questionFamilyKey"]] =
                      "Responses must not exceed 500 characters";
                    ReactTooltip.show(
                      allQuestions[q["questionFamilyKey"]].current
                    ); // manually show, without requiring hover
                  }
                });
            });

          // required "other" context
          const requiredOtherQuestions = ["treatments"];
          requiredOtherQuestions.forEach((key) => {
            if (values[key].includes("OT") && !values[`${key}_other`]) {
              errors[`${key}_other`] = "This is a required question";
              ReactTooltip.show(allQuestions[`${key}_other`].current); // manually show, without requiring hover
            }
          });
          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);

          // first submit any extra data items
          var extraDataItems = [];
          const noExtraValues =
            values.treatments.length === 1 && values.treatments[0] == "NO";
          extraDataQuestions.forEach((q) => {
            if (values[q.key] && !noExtraValues) {
              extraDataItems.push({ key: q.key, value: values[q.key] });
            }
          });
          setLoading(true);

          healthContextService.createExtraData(
            hash,
            { items: extraDataItems },
            (response) => {
              // now submit rest of the page
              setLoading(true);
              submitCurrentPage(values, (response) => {
                if (typeof response === "object") {
                  setApiErrors(response);
                  Object.keys(response).forEach((key) =>
                    ReactTooltip.show(allQuestions[key].current)
                  );
                } else {
                  setError(response || "Error saving health context");
                }
              });
            },
            (error) => {
              console.log("got an error", error);
              setLoading(false);
            }
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          handleChange,
        }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("treatments", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("treatments", errors, apiErrors)}
              data-for="treatments"
              ref={treatmentsQuestion}
            >
              <ReactTooltip
                id="treatments"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />

              <h4 className="t1">
                {getQuestionTitle("treatments")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <p>Please select all that apply</p>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.treatments.map((o, i) => (
                  <label className="block mb-3 cursor-pointer flex" key={o[0]}>
                    <Field
                      type="checkbox"
                      name="treatments"
                      value={o[0]}
                      className={`h-5 w-5 ${indexCheckboxStyles(i)}`}
                      onChange={uncheckNoneChangeHandler({
                        handleChange,
                        setFieldValue,
                        fieldName: "treatments",
                        fieldValues: values.treatments,
                        noneCode: ["NO", "NA", "none"],
                      })}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            {values.treatments.includes("OT") ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("treatments_other", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("treatments_other", errors, apiErrors)}
                data-for="treatments_other"
                ref={treatmentsOtherQuestion}
              >
                <ReactTooltip
                  id="treatments_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("treatments_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="treatments_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            {values.treatments
              .filter((t) => t !== "NO")
              .map((t) => (
                <div
                  className={`mt-12 p-4 border rounded-md ${
                    getError(`treatments__${t}`, errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError(`treatments__${t}`, errors, apiErrors)}
                  data-for={`treatments__${t}`}
                  key={`treatments__${t}`}
                  ref={extraQuestionMap[`treatments__${t}`]}
                >
                  <h4 className="t1">
                    {
                      healthContext._options.treatments.filter(
                        (o) => o[0] === t
                      )[0][1]
                    }
                  </h4>
                  <ReactTooltip
                    id={`treatments__${t}`}
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />

                  <div className={`mt-8`}>
                    <h4 className="t3">
                      {getQuestionTitle("treatments_brand")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name={`treatments__${t}__brand`}
                      as="input"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>

                  <div className={`mt-8`}>
                    <h4 className="t3">
                      {getQuestionTitle("treatments_why")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name={`treatments__${t}__why`}
                      as="input"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>

                  <div className={`mt-8`}>
                    <h4 className="t3">
                      {getQuestionTitle("treatments_start")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name={`treatments__${t}__start`}
                      type="date"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>

                  <div className={`mt-8`}>
                    <h4 className="t3">
                      {getQuestionTitle("treatments_end")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name={`treatments__${t}__end`}
                      type="date"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>

                  <div className={`mt-8`}>
                    <h4 className="t3">
                      {getQuestionTitle("treatments_often")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name={`treatments__${t}__often`}
                      as="input"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>

                  <div className={`mt-8`}>
                    <h4 className="t3">
                      {getQuestionTitle("treatments_helped")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <div role="group" aria-labelledby="checkbox-group">
                      {[
                        ["yes", "Yes"],
                        ["short", "Only in the short term"],
                        ["no", "Not at all"],
                      ].map((o) => (
                        <label
                          className="block mb-3 cursor-pointer flex items-center"
                          key={o[0]}
                        >
                          <Field
                            type="radio"
                            name={`treatments__${t}__helped`}
                            value={o[0]}
                            className={indexRadioStyles(null)}
                          />
                          <span className="ml-2">{o[1]}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              ))}

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              isSubmitting={isSubmitting || loading}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TreatmentsForm;
