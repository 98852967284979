import { configureStore } from "@reduxjs/toolkit";
import resultsReducer from "../pages/results_v2/resultsSlice";
import compareReducer from "../pages/compare/compareSlice";
import planReducer from "../pages/plan/planSlice";

export default configureStore({
  reducer: {
    results: resultsReducer,
    compare: compareReducer,
    plan: planReducer,
  },
});
