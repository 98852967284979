import React from "react";
import ResearchToolip from "./researchTooltip";
import ResearchImage from "./researchImage";
import LinkedText from "../common/linkedText";
import Check from "./check";
import {
  titleSplit,
  tagToColorMapping,
  splitDescription,
  replaceDescription,
} from "./utils";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";

const YouShouldTry = ({ item, openModalWith }) => {
  const {
    title,
    description,
    section,
    tag,
    research_rating,
    description_cta,
    planitemcitation_set,
    slug,
  } = item;

  return (
    <div className="relative rounded-xl bg-evvy-white md:w-9/20 w-11/12 m-4 md:my-8 inline-flex flex-wrap">
      {tag && (
        <div className="absolute top-0 -right-4 translate-x-1">
          <span
            className={`t4 py-2 px-4 bg-gradient-to-r from-${tagToColorMapping[tag]}-gradientLight to-${tagToColorMapping[tag]}-oldFill`}
          >
            {tag}
          </span>
        </div>
      )}
      <div className="p-8 flex-grow w-full max-h-48 border border-evvy-cream border-collapse flex">
        <Check />
        <div className="t1 w-3/5">{titleSplit(title, "You may want to")}</div>
      </div>
      <div className="t1 p-8 flex-grow w-1/2 max-w-3/5 border border-evvy-cream border-collapse">
        <div className="content line-clamp-6">
          {replaceDescription(description)}
        </div>
        <LinkedText
          onClick={analyticsClickHandler({
            eventName: eventNames.CLICKED_LEARN_MORE,
            eventArgs: { slug, groupName: null, planVersion: "v1" },
            clickHandler: () =>
              openModalWith({
                type: "_learnmore",
                data: {
                  section,
                  title,
                  splitDescription: splitDescription(description),
                  description_cta,
                  planitemcitation_set,
                  slug,
                },
              }),
          })}
        >
          Learn More
        </LinkedText>
      </div>
      <div className="t4 p-8 flex-grow min-w-max border border-evvy-cream border-collapse">
        <ResearchToolip
          openModalWith={openModalWith}
          eventArgs={{
            slug,
            groupName: null,
            researchStatus: research_rating?.title,
            planVersion: "v1",
          }}
        />
        <ResearchImage status={research_rating.title} />
      </div>
    </div>
  );
};

export default YouShouldTry;
