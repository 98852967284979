import { ascending } from "d3";

import { lastPeriod, getPeriodContext } from "../../utils/copy";
import { listItemDictionary, dataTypes, customOrder } from "./constants";

/**
sortAcc - sorts inputs according to custom sort metric
*/
const cSortAcc = (cSort, m) => (cSort ? cSort.indexOf(m) : m);

const customSortAscending = (list, cSort, metricAcc) =>
  list.sort((a, b) =>
    ascending(cSortAcc(cSort, metricAcc(a)), cSortAcc(cSort, metricAcc(b)))
  );

// returns human readable copy for health context
const contextLanguage = (
  {
    health_history: healthContext,
    dominant_bacteria_name: dominantMicrobe,
    common_community_type: type,
    common_community_type_info: typeInfo,
  },
  dataType,
  { healthcontext }
) => {
  const contextItems = healthContext && Object.entries(healthContext);
  // sort context items in custom order
  const sortedItems = customSortAscending(
    contextItems,
    customOrder,
    ([k]) => k
  );
  return sortedItems && dataType && dataType != dataTypes.DEFAULT
    ? [
        {
          text: listItemDictionary.cst.intro?.[dataType],
          coloredText: `Type ${type}`,
          type: [type, typeInfo, true],
        },
        {
          text: listItemDictionary.dominant_microbe.intro?.[dataType],
          coloredText: dominantMicrobe,
        },
        // ending form { text: xyz, coloredText: xyz }
        ...sortedItems.map(([contextItem]) => {
          if (contextItem === "basics_last_period_date") {
            if (healthcontext && healthContext.basics_last_period_date) {
              const { sample_taken_at: sampleDate } = healthcontext;
              const { basics_last_period_date: periodDate } = healthContext;

              return sampleDate && periodDate
                ? getPeriodContext(periodDate, sampleDate)
                : { text: lastPeriod.default };
            } else {
              return { text: lastPeriod.default };
            }
          } else {
            return {
              // grey intro text
              text:
                listItemDictionary[contextItem] &&
                listItemDictionary[contextItem].intro?.[dataType],
              // black text containing user specific answer
              coloredText:
                // first check that the frontend dictionary contains this key
                listItemDictionary[contextItem] &&
                // if the user data is an array of answers (codes), map over each code to return human readable copy for that code
                (healthContext[contextItem].length
                  ? healthContext[contextItem]
                      .map(
                        (answer) =>
                          // get the copy for that code or if there is no copy for that code, apply default copy
                          listItemDictionary[contextItem].answers[answer] ||
                          listItemDictionary[contextItem].answers[
                            dataTypes.DEFAULT
                          ]
                      )
                      // filter out case when undefined
                      .filter((answer) => answer)
                      .join(", ")
                  : // if the user data is an empty array (no answers), apply default copy
                    listItemDictionary[contextItem].answers[dataTypes.DEFAULT]),
            };
          }
        }),
      ]
    : [];
};

export { contextLanguage };
