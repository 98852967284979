import { useEffect, useMemo, useState, useCallback } from "react";
import { careService } from "../../services/care";
import { Consult, PrescriptionRecord } from "../../types/care";

function usePrescriptions(consultId?: Consult["uid"]) {
  const [data, setData] = useState<PrescriptionRecord[]>();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPrescriptions = useCallback(async () => {
    try {
      const response = await careService.fetchPrescriptions(consultId);
      setData(response?.data);
    } catch (error: any) {
      setError(error);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [consultId]);

  const prescriptionsWithNoRefillsRemaining = useMemo(() => {
    return data?.filter(
      (prescription) =>
        prescription.is_refillable_product &&
        prescription.refills_remaining <= 0
    );
  }, [data]);

  const prescriptionsWithRefillsRemaining = useMemo(() => {
    return data?.filter(
      (prescription) =>
        prescription.is_refillable_product && prescription.refills_remaining > 0
    );
  }, [data]);

  const prescriptionsNotRefillable = useMemo(() => {
    return data?.filter((prescription) => !prescription.is_refillable_product);
  }, [data]);

  useEffect(() => {
    if (consultId) fetchPrescriptions();
  }, [consultId, fetchPrescriptions]);

  const prescriptionFromSlug = useMemo(
    () => (slug: string) =>
      data?.find((prescription) => prescription?.product.slug === slug)
        ?.product,
    [data]
  );

  return {
    allPrescriptions: data,
    prescriptionsWithNoRefillsRemaining,
    prescriptionsWithRefillsRemaining,
    prescriptionsNotRefillable,
    prescriptionFromSlug,
    error,
    isLoading,
  };
}

export default usePrescriptions;
