import React from "react";
import Tippy from "@tippyjs/react/headless";
import ResearchToolipContentTemplate from "./modalTemplates/researchStatus";
import { ReactComponent as Info } from "../common/icons/info.svg";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";

const ResearchToolip = ({ openModalWith, eventArgs = {} }) => {
  return (
    <div className="mb-2 w-max">
      <span aria-describedby="tooltip">Research status</span>
      <Tippy
        render={(attr) => (
          <div
            id="tooltip"
            role="tooltip"
            className="hidden md:flex"
            tabIndex={-1}
            {...attr}
          >
            <ResearchToolipContentTemplate />
            <div id="arrow" data-popper-arrow />
          </div>
        )}
        animation={false}
      >
        <span
          className="cursor-pointer"
          onClick={analyticsClickHandler({
            eventName: eventNames.CLICKED_RESEARCH_STATUS,
            eventArgs,
            clickHandler: () =>
              openModalWith({
                type: "_research",
              }),
          })}
        >
          {<Info className="ml-1 mb-1 w-4 h-4 inline" />}
        </span>
      </Tippy>
    </div>
  );
};

export default React.memo(ResearchToolip);
