import { createSlice } from "@reduxjs/toolkit";
import { PlanSliceState } from "../../types/subscription";

const initialPlanSliceState: PlanSliceState = {
  value: {
    moveTestUpError: null,
    submitFeedbackError: null,
    researchModalOpen: false,
    planModalStep: null,
  },
};

export const planSlice = createSlice({
  name: "plan",
  initialState: initialPlanSliceState,
  reducers: {
    setMoveTestUpError: (state, action) => {
      state.value.moveTestUpError = action.payload;
    },
    setSubmitFeedbackError: (state, action) => {
      state.value.submitFeedbackError = action.payload;
    },
    setResearchModalOpen: (state, action) => {
      state.value.researchModalOpen = action.payload;
    },
    setPlanModalStep: (state, action) => {
      state.value.planModalStep = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMoveTestUpError,
  setSubmitFeedbackError,
  setResearchModalOpen,
  setPlanModalStep,
} = planSlice.actions;

export default planSlice.reducer;
