import React from "react";

const ListItem = ({
  bgColorClass,
  borderColorClass,
  number,
  label,
  href,
  onClick,
  bold,
}) => {
  return (
    <a
      className={`ml-auto mb-2 md:mb-3 flex items-center group ${
        href ? "" : "pointer-events-none"
      }`}
      href={`#${href}`}
      onClick={onClick ? onClick : undefined}
      aria-label="link to this section"
      aria-describedby={href}
    >
      {/* number icon */}
      <div
        className={`rounded-full ${bgColorClass} ${
          borderColorClass ? borderColorClass : ""
        } text-evvy-black hover:bg-evvy-pine hover:text-white transition-colors`}
      >
        <span className="px-1 block w-[25px] h-[25px] text-center flex items-center justify-center t4">
          {number}
        </span>
      </div>
      {/* screen label */}
      <div className={`ml-4 t2 text-left ${bold ? "semibold" : ""}`}>
        {label}
      </div>
    </a>
  );
};

export default ListItem;
