import { useEffect, useState } from "react";
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Layout from "../../components/layout";

// components
import ErrorBoundary from "../../components/common/errorBoundary";
import LoadingSpinner from "../../components/common/loadingSpinner";
import IntakeHeader from "../../components/care/consultIntake/intakeHeader";

import MedicationQuestions from "./intake/medicationQuestions";
import IdentityVerificationInfo from "./intake/identityVerificationInfo";
import IdPhotoUpload from "./intake/idPhotoUpload";
import IntakeExit from "./intake/exit";
import { careService } from "../../services/care";
import { Consult, ConsultIntake } from "../../types/care";
import IntakeIntro from "./intake/sti/intro";
import DemographicsQuestions from "./intake/sti/demographicsQuestions";
import PregnancyQuestions from "./intake/sti/pregnancy";
import GeneralQuestions from "./intake/sti/generalMedicalQuestions";
import { Pharmacy } from "./intake/sti/pharmacy";
import IntakeDone from "./intake/sti/done";

const STIConsultIntake = () => {
  /*
   * State
   */

  const { consultId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // start out as loading so that page doesn't try to render without data loaded up first
  const [error, setError] = useState("");
  const [consultIntake, setConsultIntake] = useState<ConsultIntake>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [consultAlreadySubmitted, setConsultAlreadySubmitted] = useState(false);
  const [allowReupload, setAllowReupload] = useState(false);

  var allPages = [
    "intro/",
    "demographics/",
    "medical-questions/",
    "treatment/medications/",
    "treatment/general/",
    "treatment/pharmacy/",
    "identity-verification/info/",
    "identity-verification/photo/",
    "done/",
  ];

  const baseUrl = `/care/sti/consults/${consultId}/intake/`;
  const currentPage = pathname.split(baseUrl)[1];

  if (
    currentPage === "identity-verification/redo/" ||
    currentPage === "identity-verification/reupload/"
  ) {
    allPages = [
      "identity-verification/redo/",
      "identity-verification/reupload/",
      "done/",
    ];
  }

  const pageIndex = allPages.indexOf(currentPage);
  const isValidPage = pageIndex !== -1;
  const isFirstPage = isValidPage ? pageIndex === 0 : false;
  const isLastPage = isValidPage ? pageIndex === allPages.length - 1 : false;
  var previousPage = isFirstPage ? "/tests/" : allPages[pageIndex - 1];
  if (previousPage === "intro/") {
    // if consult is paid, skip intro
    if (consultIntake?.consult?.consult_paid) {
      previousPage = "/tests/";
    }
  }
  const nextPage = !isLastPage ? allPages[pageIndex + 1] : null;
  const isLastIntakeInputPage = nextPage === "done/"; // last page where you are inputting data (so /done/ page doesn't count)

  /* effects */
  useEffect(() => {
    const fetchConsultIntake = async (consultId: string) => {
      const consultResponse = await careService.fetchConsultIntake(
        consultId,
        (response: { data: ConsultIntake }) => {
          return response;
        }
      );

      const consultIntake = consultResponse.data as ConsultIntake;

      if (consultIntake.submitted_at) {
        setConsultAlreadySubmitted(true);
        if (consultIntake.consult.status === "ID") {
          setAllowReupload(true);
        }
      }

      // If the user has not accepted the terms, then redirect them to the intro page
      if (!consultIntake.terms_agreed_at && !isFirstPage) {
        navigate(`${baseUrl}intro/`);
      }

      setConsultIntake(consultIntake);
      setLoading(false);

      return consultIntake;
    };

    if (consultId) {
      fetchConsultIntake(consultId);
    }
  }, [baseUrl, consultId, isFirstPage, navigate]); // Fetches consult intake upon page load & intake if it exists

  const getNextPageFullUrl = (nextPage: string) => {
    // if next page is not a relative to the intake pages, then just use it directly
    if (nextPage.startsWith("/")) {
      return nextPage;
    } else {
      return `${baseUrl}${nextPage}`;
    }
  };

  const submitCurrentPage = (data: any, onError: (error?: string) => void) => {
    // if user already submitted intake or if no data, then just continue to next page
    if (consultIntake?.submitted_at || Object.keys(data).length === 0) {
      // if is last page, then there might be empty data fields, so just submit
      if (!consultIntake?.submitted_at && isLastIntakeInputPage) {
        submitConsultIntake(onError);
        return;
      }

      if (nextPage) {
        navigate(getNextPageFullUrl(nextPage));
      } else {
        navigate(`${baseUrl}done/`);
      }
      window.scrollTo(0, 0);
      return;
    }

    // otherwise if not already submitted, then update before continuing to next page
    updateConsultIntake(
      data,
      (responseData: { consult: Consult }) => {
        // check if referred out
        // IE status means that they are ineligible for care, and move them to the exit page
        if (responseData.consult.status === "IE") {
          navigate(`${baseUrl}exit/`);
          window.scrollTo(0, 0);
          return;
        }

        if (!isLastIntakeInputPage && nextPage) {
          navigate(getNextPageFullUrl(nextPage));
          window.scrollTo(0, 0);
        } else {
          // last page yay!
          // submit consult intake
          submitConsultIntake(onError);
        }
      },
      onError
    );
  };

  const submitConsultIntake = (onError?: (response?: string) => void) => {
    // if they haven't paid for care yet, then just redirect them to checkout
    if (!consultIntake?.consult?.consult_paid) {
      goToCheckout();
      return;
    } else {
      setIsSubmitting(true);
      careService.submitConsultIntake(
        consultId,
        () => {
          navigate(`${baseUrl}done/`);
          setIsSubmitting(false);
          window.scrollTo(0, 0);
        },
        (err: any, resp: string) => {
          console.error(err);
          setIsSubmitting(false);
          if (onError) {
            onError(resp);
          }
        }
      );
    }
  };

  // this is used when we just want to update consult without going to next page
  const updateConsultIntake = (
    data: any,
    onSuccess?: (responseData: { consult: Consult }) => void,
    onError?: (error?: string) => void
  ) => {
    setIsSubmitting(true);
    // if user already submitted intake or if no data
    if (consultIntake?.submitted_at && !allowReupload) {
      if (onError) {
        onError();
      }
      setIsSubmitting(false);
      navigate(`${baseUrl}done/`);
      return;
    }

    // otherwise if not already submitted, then update
    careService.updateConsultIntake(
      consultId,
      data,
      (response: { data: ConsultIntake }) => {
        setConsultIntake(response.data);
        if (onSuccess) {
          onSuccess(response.data);
        }
        setIsSubmitting(false);
      },
      (err: any, resp: string) => {
        console.error(err);
        setIsSubmitting(false);
        if (onError) {
          onError(resp);
        }
        if (resp === "Consult intake already submitted") {
          // if somehow user navigated back and tries to resubmit
          navigate(`${baseUrl}done/`);
        }
      }
    );
  };

  // used by intro screen to accept terms and proceed to next page
  const proceedToNextPage = () => {
    // IE status means that they are ineligible for care, and move them to the exit page
    if (consultIntake?.consult.status === "IE") {
      navigate(`${baseUrl}exit/`);
      window.scrollTo(0, 0);
      return;
    }
    // otherwise, if they've already paid, then just progress them to next page
    if (consultIntake?.consult.consult_paid) {
      navigate(`${baseUrl}${nextPage}`);
      window.scrollTo(0, 0);
      return;
    }

    // this means that status is "IN" and they haven't paid yet, so send them to checkout
    goToCheckout();
  };

  // used by intro screen to accept terms and proceed to next page
  const proceedPastIntroPage = () => {
    if (!consultIntake?.terms_agreed_at) {
      careService
        .asyncAgreeToTerms(consultId)
        .then((response) => {
          proceedToNextPage();
        })
        .catch((error) => {
          setError("Error agreeing to terms");
        });
    } else {
      proceedToNextPage();
    }
  };

  const goToCheckout = () => {
    setIsSubmitting(true);
    careService.fetchConsultCheckoutURL(
      consultId,
      (resp: { data: { checkout_url: string } }) => {
        const { checkout_url } = resp.data || {};
        window.location.href = checkout_url;
      }
    );
  };

  return (
    <Layout
      title="Care | Consult | STI Intake"
      padded={false}
      bgClass="bg-evvy-cream"
      full
      header={
        <ErrorBoundary>
          <IntakeHeader
            previousPage={previousPage}
            baseUrl={baseUrl}
            onClickBack={() => navigate(-1)}
          />
        </ErrorBoundary>
      }
    >
      {error ? (
        <div className="text-red-500">{error}</div>
      ) : loading || !consultIntake ? (
        <div className="mt-10">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="max-w-full sm:max-w-4xl py-4 mx-auto px-3 sm:px-0">
          {consultAlreadySubmitted &&
            currentPage !== "done/" &&
            !allowReupload && (
              <div className="flex">
                <div className="mx-auto bg-coral p-2 px-3 font-medium rounded-md mt-6">
                  These answers have been submitted.
                  <br />
                  Any changes you make will not be saved.
                </div>
              </div>
            )}
          <Routes>
            {/* consult intake info + form pages */}
            <Route
              path="/intro/"
              element={
                <IntakeIntro
                  consultIntake={consultIntake}
                  submitPage={proceedPastIntroPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/demographics/"
              element={
                <DemographicsQuestions
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/medical-questions/"
              element={
                <PregnancyQuestions
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/treatment/medications/"
              element={
                <MedicationQuestions
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/treatment/general/"
              element={
                <GeneralQuestions
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/identity-verification/info/"
              element={
                <IdentityVerificationInfo
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                  redo={false}
                />
              }
            />
            <Route
              path="/identity-verification/photo/"
              element={
                <IdPhotoUpload
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  updateConsultIntake={updateConsultIntake}
                  loading={isSubmitting}
                  redo={false}
                />
              }
            />

            <Route
              path="/treatment/pharmacy/"
              element={
                <Pharmacy
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/done/"
              element={<IntakeDone consultIntake={consultIntake} />}
            />
            <Route
              path="/exit/"
              element={<IntakeExit consultIntake={consultIntake} />}
            />
            <Route
              path="/identity-verification/redo/"
              element={
                <IdentityVerificationInfo
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                  redo={true}
                />
              }
            />
            <Route
              path="/identity-verification/reupload/"
              element={
                <IdPhotoUpload
                  consultIntake={consultIntake}
                  submitPage={submitConsultIntake}
                  updateConsultIntake={updateConsultIntake}
                  loading={isSubmitting}
                  redo={true}
                />
              }
            />

            {/* redirect on load */}
            <Route path="/" element={<Navigate to="intro/" replace />} />
          </Routes>
        </div>
      )}
    </Layout>
  );
};

export default STIConsultIntake;
