import React, { useEffect, useState, useRef } from "react";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import { indexRadioStyles } from "../../utils/colors";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";

const AdditionalForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, test, hdyhauSurvey } =
    subrouteProps;

  // for tooltips
  const anythingElseQuestion = useRef(null);
  const hdyhauQuestion = useRef(null);
  const whichInfluencerQuestion = useRef(null);
  const whichCommunityQuestion = useRef(null);
  const whichSocialMediaQuestion = useRef(null);
  const whichSocialMediaOtherQuesiton = useRef(null);
  const whichAdvertisementQuestion = useRef(null);
  const whichAdvertisementOtherQuestion = useRef(null);
  const whichNewsQuestion = useRef(null);
  const hdyhauOtherQuestion = useRef(null);
  const whenHeardAboutEvvyQuestion = useRef(null);

  const allQuestions = {
    health_history_anything_else: anythingElseQuestion,
    hdyhau: hdyhauQuestion,
    which_influencer: whichInfluencerQuestion,
    which_community: whichCommunityQuestion,
    which_social_media: whichSocialMediaQuestion,
    which_social_media_other: whichSocialMediaOtherQuesiton,
    which_advertisement: whichAdvertisementQuestion,
    which_advertisement_other: whichAdvertisementOtherQuestion,
    which_news: whichNewsQuestion,
    hdyhau_other: hdyhauOtherQuestion,
    when_heard_about_evvy: whenHeardAboutEvvyQuestion,
  };

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "profile",
      questionGroup: "anything else",
      testOrder: test?.test_order,
      testHash: test?.hash,
      version: 1,
    });
  }, []);

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">Anything Else?</h3>
        <div className="flex-1"></div>
      </div>

      <Formik
        initialValues={{
          health_history_anything_else:
            healthContext.health_history_anything_else || "",
        }}
        validate={(values) => {
          var errors = {};
          Object.keys(allQuestions).forEach((key) =>
            ReactTooltip.hide(allQuestions[key].current)
          );
          ReactTooltip.rebuild();
          // required questions
          const requiredQuestions = [];
          if (!hdyhauSurvey?.uid) {
            requiredQuestions.push("hdyhau");
            requiredQuestions.push("when_heard_about_evvy");

            if (values.hdyhau === "IN") {
              requiredQuestions.push("which_influencer");
            } else if (values.hdyhau === "CO") {
              requiredQuestions.push("which_community");
            } else if (values.hdyhau === "SM") {
              requiredQuestions.push("which_social_media");
            } else if (values.hdyhau === "AD") {
              requiredQuestions.push("which_advertisement");
            } else if (values.hdyhau === "NE") {
              requiredQuestions.push("which_news");
            }
            requiredQuestions.forEach((key) => {
              if (!values[key]) {
                errors[key] = "This is a required question";
                ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
              }
            });

            // required "other" context
            const requiredOtherQuestions = [
              "hdyhau",
              "which_social_media",
              "which_advertisement",
            ];
            requiredOtherQuestions.forEach((key) => {
              if (values[key] === "OT" && !values[`${key}_other`]) {
                errors[`${key}_other`] = "This is a required question";
                ReactTooltip.show(allQuestions[`${key}_other`].current); // manually show, without requiring hover
              }
            });
          }
          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          submitCurrentPage(values, (response) => {
            console.log("form got an error", response);
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((key) =>
                ReactTooltip.show(allQuestions[key].current)
              );
            } else {
              setError(response || "Error saving health history");
            }
          });
        }}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            {!hdyhauSurvey?.uid ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("hdyhau", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("hdyhau", errors, apiErrors)}
                data-for="hdyhau"
                ref={hdyhauQuestion}
              >
                <ReactTooltip
                  id="hdyhau"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("hdyhau")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <div role="group" aria-labelledby="checkbox-group">
                  {hdyhauSurvey?._options?.hdyhau.map((o, i) => (
                    <label
                      className="block mb-3 cursor-pointer flex items-center"
                      key={o[0]}
                    >
                      <Field
                        type="radio"
                        name="hdyhau"
                        value={o[0]}
                        className={indexRadioStyles(i)}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {!hdyhauSurvey?.uid && values.hdyhau === "IN" ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("which_influencer", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("which_influencer", errors, apiErrors)}
                data-for="which_influencer"
                ref={whichInfluencerQuestion}
              >
                <ReactTooltip
                  id="which_influencer"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("which_influencer")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="which_influencer"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            {!hdyhauSurvey?.uid && values.hdyhau === "CO" ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("which_community", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("which_community", errors, apiErrors)}
                data-for="which_community"
                ref={whichCommunityQuestion}
              >
                <ReactTooltip
                  id="which_community"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("which_community")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="which_community"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            {!hdyhauSurvey?.uid && values.hdyhau === "SM" ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("which_social_media", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("which_social_media", errors, apiErrors)}
                data-for="which_social_media"
                ref={whichSocialMediaQuestion}
              >
                <ReactTooltip
                  id="which_social_media"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("which_social_media")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <div role="group" aria-labelledby="checkbox-group">
                  {hdyhauSurvey?._options?.which_social_media.map((o, i) => (
                    <label
                      className="block mb-3 cursor-pointer flex items-center"
                      key={o[0]}
                    >
                      <Field
                        type="radio"
                        name="which_social_media"
                        value={o[0]}
                        className={indexRadioStyles(i)}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {!hdyhauSurvey?.uid && values.which_social_media === "OT" ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("which_social_media_other", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "which_social_media_other",
                  errors,
                  apiErrors
                )}
                data-for="which_social_media_other"
                ref={whichSocialMediaOtherQuesiton}
              >
                <ReactTooltip
                  id="which_social_media_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("which_social_media_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="which_social_media_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            {!hdyhauSurvey?.uid && values.hdyhau === "AD" ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("which_advertisement", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("which_advertisement", errors, apiErrors)}
                data-for="which_advertisement"
                ref={whichAdvertisementQuestion}
              >
                <ReactTooltip
                  id="which_advertisement"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("which_advertisement")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <div role="group" aria-labelledby="checkbox-group">
                  {hdyhauSurvey?._options?.which_advertisement.map((o, i) => (
                    <label
                      className="block mb-3 cursor-pointer flex items-center"
                      key={o[0]}
                    >
                      <Field
                        type="radio"
                        name="which_advertisement"
                        value={o[0]}
                        className={indexRadioStyles(i)}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {!hdyhauSurvey?.uid && values.which_advertisement === "OT" ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("which_advertisement_other", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "which_advertisement_other",
                  errors,
                  apiErrors
                )}
                data-for="which_advertisement_other"
                ref={whichAdvertisementOtherQuestion}
              >
                <ReactTooltip
                  id="which_advertisement_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("which_advertisement_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="which_advertisement_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            {!hdyhauSurvey?.uid && values.hdyhau === "NE" ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("which_news", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("which_news", errors, apiErrors)}
                data-for="which_news"
                ref={whichNewsQuestion}
              >
                <ReactTooltip
                  id="which_news"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("which_news")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="which_news"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            {!hdyhauSurvey?.uid && values.hdyhau === "OT" ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("hdyhau_other", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("hdyhau_other", errors, apiErrors)}
                data-for="hdyhau_other"
                ref={hdyhauOtherQuestion}
              >
                <ReactTooltip
                  id="hdyhau_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("hdyhau_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="hdyhau_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            {!hdyhauSurvey?.uid ? (
              <div
                className={`border-b border-dashed mt-12 p-4 pb-10 rounded-md ${
                  getError("when_heard_about_evvy", errors, apiErrors)
                    ? "border-coral"
                    : ""
                }`}
                data-tip={getError("when_heard_about_evvy", errors, apiErrors)}
                data-for="when_heard_about_evvy"
                ref={whenHeardAboutEvvyQuestion}
              >
                <ReactTooltip
                  id="when_heard_about_evvy"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("when_heard_about_evvy")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <div role="group" aria-labelledby="checkbox-group">
                  {hdyhauSurvey?._options?.when_heard_about_evvy.map((o, i) => (
                    <label
                      className="block mb-3 cursor-pointer flex items-center"
                      key={o[0]}
                    >
                      <Field
                        type="radio"
                        name="when_heard_about_evvy"
                        value={o[0]}
                        className={indexRadioStyles(i)}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="mt-12 p-4">
              <h4 className="t1">
                {getQuestionTitle("health_history_anything_else")}
              </h4>
              <p>
                (For example, anything about your general health history that
                you think might be relevant for us to know.)
              </p>
              <div>
                <Field
                  name="health_history_anything_else"
                  as="textarea"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            </div>

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AdditionalForm;
