import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { sendViewedHealthHistorySection } from "../../../utils/analytics/customEventTracking";

const ContextWhy = ({ test }) => {
  // route params
  const { testHash: hash } = useParams();

  // effects
  useEffect(() => {
    sendViewedHealthHistorySection({
      section: "context",
      sectionStatus: "blank",
      testHash: hash,
      testOrder: test.test_order,
    });
  }, []); // execute on page load

  return (
    <div>
      <Helmet>
        <title>Test | Context | Why</title>
      </Helmet>
      <div className="block sm:flex h-60">
        <h2 className="flex-1">
          Context is{" "}
          <span className="bg-highlighter-purple rounded-sm px-1 -mx-1">
            everything.
          </span>
        </h2>

        <div className="flex-1 font-medium mt-2">
          <p className="text-lg">
            These questions will help us contextualize your results from this
            swab with your current symptoms and experiences.
          </p>
          <p className="text-lg">
            Required questions will be indicated by{" "}
            <span className="text-evvy-blue">*</span>
          </p>
        </div>
      </div>

      <div className="border-t border-dashed mt-10 py-10 flex">
        <Link
          to={`/tests/${hash}/context/verify/`}
          className="w-full sm:w-auto block mx-auto"
          onClick={() => window.scrollTo(0, 0)}
        >
          <button className="cursor-pointer tracking-wider w-full py-6 px-20 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none">
            Great! Let's Go →
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ContextWhy;
