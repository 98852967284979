import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import TestLaterModal from "../../../components/sample/testLaterModal";
import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as WarningIcon } from "../../../components/sample/icons/warning.svg";

const LDTLabelReminder = ({ fetchTest }) => {
  // state
  const [laterModalOpen, setLaterModalOpen] = useState(false);

  // route params
  const { testHash: hash } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Should always start at top of screen

  // actions
  const proceedToNextPage = async () => {
    // go to sample page
    await fetchTest(hash);
    navigate(`/tests/${hash}/sample/`);
  };

  return (
    <div>
      <Helmet>
        <title>Test | Sample | Stop</title>
      </Helmet>
      <div className="block sm:flex w-full">
        <div className="flex-1 sm:h-60">
          <h2>
            Time to{" "}
            <span className="bg-coral rounded-sm px-1 -mx-1">test!</span>
          </h2>
        </div>

        <div className="flex-1 flex flex-col font-medium">
          <div>
            <img
              className="object-cover w-full rounded-lg"
              src="/images/graphics/sample-tube-label.png"
              loading="lazy"
              alt="Sample Tube Label"
              // style={{height: '250px'}}
            />
          </div>
          <span className="text-md text-dv-orange pt-5">
            <WarningIcon className="h-6 w-6 inline pr-1" />
            STOP
          </span>
          <p className="text-lg pt-2">
            If the tube is not already labeled, please take the sticker from
            your box and apply it to the outside of the tube. Do this before
            taking your sample.
          </p>
        </div>
      </div>

      <div className="border-t border-dashed mt-10 py-10 flex items-center">
        <div className="flex-1">
          <div
            className="uppercase inline-block underline font-semibold tracking-wider cursor-pointer"
            onClick={() => setLaterModalOpen(true)}
          >
            ← I'll test later
          </div>
        </div>
        <div className="flex-1">
          <button
            onClick={proceedToNextPage}
            className="cursor-pointer w-full flex-1 tracking-wider py-6 px-10 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none"
          >
            Continue →
          </button>
        </div>
        <div className="hidden sm:block flex-1"></div>
      </div>

      {laterModalOpen ? (
        <TestLaterModal closeModal={() => setLaterModalOpen(false)} />
      ) : (
        ""
      )}
    </div>
  );
};

export default LDTLabelReminder;
