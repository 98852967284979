import { AxiosError, AxiosResponse } from "axios";
import { getRequest, putRequest } from "../utils/axios";
import { SubscriptionType } from "../types/subscription";

function fetchHasSubscription(
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => void
) {
  return getRequest(
    "/api/v1/subscription/exists/",
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError) => {
      if (onError) {
        onError(error);
      }
    }
  );
}

function fetchPortalLink(
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => void
) {
  return getRequest(
    "/api/v1/subscription/portal-link/",
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError) => {
      if (onError) {
        onError(error);
      }
    }
  );
}

function fetchSubscriptionInfo() {
  return getRequest(
    "/api/v1/subscription/",
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      // valid case to have no subscription
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    }
  );
}

async function swapSubscription(newSubscriptionType: SubscriptionType) {
  const response = await putRequest(
    "/api/v1/subscription/swap/",
    { subscription_type: newSubscriptionType },
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
}

function triggerNextCharge(
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => void
) {
  return putRequest(
    "/api/v1/subscription/next-charge/",
    {},
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError) => {
      if (onError) {
        onError(error);
      }
    }
  );
}

export const subscriptionService = {
  fetchHasSubscription,
  fetchPortalLink,
  fetchSubscriptionInfo,
  triggerNextCharge,
  swapSubscription,
};
