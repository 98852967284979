import React, { useState } from "react";
import { Link } from "react-router-dom";

import LayoutAuth from "../../components/auth/layout";

import { authService } from "../../services/auth";
// import { getParameterByName } from "../../utils/urls";

const PasswordReset = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sentResetEmail, setSentResetEmail] = useState(null);

  const submitForm = (e) => {
    e.preventDefault(); // to not post to the current url
    setLoading(true);
    setError(null);

    const email = e.target.elements["email"].value;

    authService.forgotPassword(
      email,
      (response) => {
        setError(null);
        setLoading(false);
        setSentResetEmail(email);
      },
      (error, response) => {
        setError(
          (response && response.email && response.email[0]) ||
            "could not reset password"
        );
        setLoading(false);
      }
    );
  };

  return (
    <LayoutAuth
      metaTitle="Evvy — Reset Your Password"
      title={
        sentResetEmail
          ? "Please check your email."
          : "Enter the email address associated with your account."
      }
    >
      <form className="space-y-6" action="#" onSubmit={submitForm}>
        {error ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-xs">
            {error}
          </div>
        ) : (
          ""
        )}

        {sentResetEmail ? (
          <div className="mb-8 text-center">
            <div>
              <p>
                If you have an account with Evvy associated with the email you
                entered, we've sent you a link to reset your password.
              </p>
              <p>
                If you do not receive a reset password email, you may have
                created your account with a different email or you may not have
                created an account yet (most users don't create their account
                until activating their first test!) Click{" "}
                <Link to="/register/">here</Link> to create your account.
              </p>
            </div>
            <div className="mt-8">
              <img
                className="h-40 mx-auto"
                src="/images/graphics/triple-welcome.png"
                loading="lazy"
                alt="Evvy Science"
              />
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div>
              <label htmlFor="email" className="block uppercase font-medium">
                Email Address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black focus:outline-none focus:ring-transparent focus:border-crazy-purple sm"
                />
              </div>
            </div>

            <div className="sm:flex items-center justify-between sm:flex-row-reverse">
              <button
                type="submit"
                className="cursor-pointer w-full sm:w-auto justify-center tracking-wider py-6 px-20 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
                disabled={loading}
              >
                {loading ? "Submit →" : "Submit →"}
              </button>

              <div className="mr-auto mt-4 sm:mt-0">
                <div>
                  <Link
                    to="/login/"
                    className="underline uppercase text-sm font-semibold tracking-wider"
                  >
                    ← Back
                  </Link>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </form>
    </LayoutAuth>
  );
};

export default PasswordReset;
