/**
 * A styled button or link (same style, different html for accessibility)
 * @param {boolean} isLink determines whether element is an anchor tag or button
 * @param {string} href an external url
 * @param {jsx} icon an svg imported as a react component
 * @param {string} textColorClass this should be a string for a text color, such as `text-evvy-black`, since in tailwind, the stroke of the text is referenced with `currentColor`.
 * @param {string} bgColorClass this should be a string for a background color, such as `bg-evvy-black`, since in tailwind, the fill of the background is referenced with `currentColor`.
 * @param {string} text a string to label button/link
 * @param {boolean} fullWidth determines whether button should take full width of it's parent container
 * @param {boolean} noLeftMargin sets left margin to 0 when true
 * @param {*} onClick click handler for button
 * @returns jsx of a button or anchor tag
 */
import React from "react";
import { ReactComponent as Checkmark } from "../../components/common/icons/checkmark.svg";
import classNames from "classnames";

// simple rectangular button component
const RectangularButton = ({
  isLink = false,
  href = "",
  text,
  textColorClass = "text-white",
  bgColorClass = "bg-evvy-blue",
  target = "_blank",
  fullWidth = false,
  noLeftMargin = false,
  onClick,
  disabled = false,
  buttonType = "button",
  verticalPadding = "py-4",
  horizontalPadding = "px-10",
  borderColor = "",
  icon = "",
}) => {
  return (
    <>
      {isLink ? (
        <a
          href={href}
          target={target}
          className={`t4 ml-0 sm:${
            fullWidth || noLeftMargin ? "ml-0" : "ml-4"
          } w-full sm:${
            fullWidth ? "w-full" : "w-auto"
          } mt-2 sm:mt-0 ${verticalPadding} ${horizontalPadding} rounded-sm font-semibold tracking-wider text-center pointer ${bgColorClass} ${textColorClass} ${
            borderColor ? `border ${borderColor}` : ""
          }`}
          onClick={onClick ? onClick : null}
          disabled={disabled}
        >
          {text}
        </a>
      ) : (
        <button
          className={`rectangular t4 ml-0 sm:${
            fullWidth || noLeftMargin ? "ml-0" : "ml-4"
          } w-full sm:${
            fullWidth ? "w-full" : "w-auto"
          } mt-2 sm:mt-0 ${verticalPadding} ${horizontalPadding} rounded-sm font-semibold tracking-wider pointer ${bgColorClass} ${textColorClass} ${
            borderColor ? `border ${borderColor}` : ""
          }`}
          disabled={disabled}
          onClick={onClick ? onClick : null}
          type={buttonType}
        >
          <div
            className={
              "w-full flex items-center align-center justify-center pointer"
            }
          >
            {icon && (
              <span className="flex my-auto">
                <Checkmark className="h-5 w-5 mr-1" />
              </span>
            )}
            {text}
          </div>
        </button>
      )}
    </>
  );
};

export default RectangularButton;
