import Markdown from "markdown-to-jsx";
import LoadingSpinner from "../common/loadingSpinner";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import ProblemsModule from "../tests/problemsModule";
import { Consult, TreatmentPlan } from "../../types/care";
import { TestTrendSummary } from "../../types/test";
import classNames from "classnames";
import SymptomReliefProblemsModule from "./symptomReliefProblemsModule";

// Doctor's note for the treatment
const TreatmentNote = ({
  consult,
  openProblemsModuleModal,
  test,
  treatmentPlan,
}: {
  consult: Consult;
  openProblemsModuleModal: () => void;
  test?: TestTrendSummary;
  treatmentPlan: TreatmentPlan;
}) => {
  const currentUser = useLoggedInUser();

  return (
    <div className="max-w-6xl w-full mx-auto pb-20 pt-8 px-4">
      <div className="block px-2 sm:px-10 py-12 bg-white rounded-lg sm:flex flex-wrap">
        <div
          className={classNames("flex-1 px-8 sm:pl-0 sm:pr-8", {
            "sm:border-r": consult.type !== "ungated-rx",
          })}
        >
          <div className="uppercase font-semibold tracking-wider text-sm">
            A note from your provider
          </div>
          <h2 className="mt-2">
            Hi {currentUser.identity.firstName || "there"},
          </h2>
          <div className="my-4 whitespace-pre-wrap leading-6 list-disc">
            <Markdown>
              {consult.physician_note || "Missing physician note"}
            </Markdown>
          </div>
          <div className="mt-8 uppercase font-semibold tracking-wider text-sm">
            — {consult.clinician_display_name || "Missing clinician name"}
          </div>
        </div>

        <hr
          className="block sm:hidden my-8"
          style={{ backgroundColor: "#e5e7eb" }}
        />
        {consult.type === "ungated-rx" &&
          (treatmentPlan?.consult ? (
            <div className="flex-1 mt-8 sm:mt-0 sm:pl-12">
              <SymptomReliefProblemsModule consult={treatmentPlan?.consult} />
            </div>
          ) : (
            <LoadingSpinner />
          ))}
        {consult.type !== "ungated-rx" && test && (
          <div className="flex-1 mt-8 sm:mt-0 sm:pl-12">
            {test && test?.scores ? (
              <ProblemsModule
                testSummary={test}
                openModal={openProblemsModuleModal}
              />
            ) : (
              <LoadingSpinner />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TreatmentNote;
