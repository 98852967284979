import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import LayoutWelcome from "../../components/welcome/layout";

import { authService } from "../../services/auth";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { useOrdersFromContext } from "../../hooks/useOrdersFromContext";
import {
  sendVerifyEmailViewedPage,
  sendVerifyEmailClickedCTA,
} from "../../utils/analytics/customEventTracking";
// import { getParameterByName } from "../../utils/urls";

const VerifyEmail = () => {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [resent, setResent] = useState(null);
  const [resending, setResending] = useState(null);
  const [error, setError] = useState(null);

  const currentUser = useLoggedInUser();
  const isUngatedRxAccount =
    currentUser?.identity?.registrationRoute === "treatment";

  // effects
  useEffect(() => {
    sendEmail();
    sendVerifyEmailViewedPage({
      version: isUngatedRxAccount ? "ungated-rx" : "standard",
    });
  }, []); // Send email when loading up this page

  // actions
  const sendEmail = () => {
    if (sending) return;

    setSending(true);
    authService.verifyEmail(
      (response) => {
        setSent(true);
        setSending(false);
      },
      (error) => {
        setSending(false);
        setError(
          error && error.response && error.response.data
            ? error.response.data
            : "could not send verification email"
        );
      }
    );
  };

  const resend = () => {
    if (sending || resending) return;

    setResending(true);
    authService.verifyEmail(
      (response) => {
        setResent(true);
        setResending(false);
      },
      (response) => {
        setResending(false);
        setError(
          error && error.response && error.response.data
            ? error.response.data
            : "could not resend verification email"
        );
      }
    );
  };

  return (
    <LayoutWelcome
      metaTitle="Verify Email"
      title="Please verify your email address"
    >
      <div className="mb-8 text-center">
        {sending ? (
          <div>Sending you a verification email...</div>
        ) : sent ? (
          <div>Check your email for a link to verify your account.</div>
        ) : error ? (
          <div className="bg-coral p-2 px-3 text-sm font-medium rounded-xs">
            {error}
          </div>
        ) : (
          ""
        )}

        <div className="mt-8">
          <img
            className="h-40 mx-auto"
            src="/images/graphics/triple-welcome.png"
            loading="lazy"
            alt="Evvy Science"
          />
        </div>
        {sent ? (
          <div className="mt-8">
            {resending ? (
              <span className="uppercase text-[12px] font-semibold tracking-wider cursor-pointer">
                Sending...
              </span>
            ) : resent ? (
              <span className="uppercase text-[12px] font-semibold tracking-wider">
                We just sent you a new email. Go check your inbox.
              </span>
            ) : (
              <div>
                <div
                  className="underline uppercase text-[12px] font-semibold tracking-wider cursor-pointer"
                  onClick={() => {
                    sendVerifyEmailClickedCTA({
                      version: isUngatedRxAccount ? "ungated-rx" : "standard",
                    });
                    resend();
                  }}
                >
                  Didn't receive an email?
                </div>
                <br />
                <div className="underline uppercase text-[12px] font-semibold tracking-wider cursor-pointer">
                  <Link to="/account/email/">
                    Not {currentUser.identity.email}? Change email
                  </Link>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </LayoutWelcome>
  );
};

export default VerifyEmail;
