const titleSplit = (text, splitOffPhrase, textColorClass) => {
  if (text.startsWith(splitOffPhrase)) {
    return [
      <span key="splitOffPhrase">{splitOffPhrase}</span>,
      <span
        key="restOfText"
        className={`${textColorClass ? textColorClass : "text-dv-pond"}`}
      >
        {text.replace(splitOffPhrase, "")}
      </span>,
    ];
  } else {
    return [
      <span className={`${textColorClass ? textColorClass : "text-dv-pond"}`}>
        {text}
      </span>,
    ];
  }
};

const tagToColorMapping = {
  Maintenance: "NE",
  "Promote Protective": "GO",
  "Reduce Disruptive": "BA",
};

/**
 * TODO: possible import
 * This object is copied from tailwind.config.js. ideally this would be imported
 * (ref: https://tailwindcss.com/docs/configuration#referencing-in-java-script)
 * but react prevents relative imports from outside of src */
const colorNames = [
  "dv-orange",
  "dv-pond",
  "dv-pink",
  "dv-apple",
  "dv-terra",
  "dv-frog",
  "dv-sage",
];

// define replaceAll in case it's not available (10% of browsers)
if (typeof String.prototype.replaceAll == "undefined") {
  String.prototype.replaceAll = function (match, replace) {
    return this.replace(new RegExp(match, "g"), () => replace);
  };
}

const splitDescription = (description) => description.split("|");
const replaceDescription = (description) => description.replaceAll("|", "");

export {
  titleSplit,
  tagToColorMapping,
  colorNames,
  splitDescription,
  replaceDescription,
};
