import {
  diagnosesCodes,
  dischargeCodes,
  smellCodes,
  treatmentCodes,
  symptomCodes,
} from "../../utils/copy";

const listItemDictionary = {
  symptoms_vaginal_discharge: {
    intro: {
      type: "typical discharge is",
      test: "your discharge was",
    },
    answers: dischargeCodes,
  },
  symptoms_vaginal_smell: {
    intro: {
      type: "typical smell is",
      test: "your vaginal smell was",
    },
    answers: smellCodes,
  },
  treatments: {
    intro: {
      type: "within 60 days of this result, typical treatments included",
      test: "You had recently used",
    },
    answers: treatmentCodes,
  },
  symptoms: {
    intro: {
      test: "your symptoms included",
    },
    answers: symptomCodes,
  },
  diagnoses: {
    intro: {
      type: "within 60 days of this result, typical diagnoses include",
      test: "You were recently diagnosed with",
    },
    answers: diagnosesCodes,
  },
  dominant_microbe: {
    intro: {
      test: "your dominant microbe was",
      type: "the typical dominant microbe is",
    },
    answers: {},
  },
  cst: {
    intro: {
      test: "your CST type was",
      type: "your CST type was",
    },
    answers: {},
  },
};
const optionsDictionary = { test: "My test from" };
// temporarily removing to disable type function of compare page
// { test: "My test from", type: "Type"}

// note: saving for eventually adding types back in
const typesDictionary = {
  "I-A": "Type 1-A",
  "I-B": "Type 1-B",
  II: "Type 2",
  "III-A": "Type 3-A",
  "III-B": "Type 3-B",
  "IV-A": "Type 4-A",
  "IV-B": "Type 4-B",
  "IV-C": "Type 4-C",
  "IV-C0": "Type 4-C0",
  "IV-C1": "Type 4-C1",
  "IV-C2": "Type 4-C2",
  "IV-C3": "Type 4-C3",
  "IV-C4": "Type 4-C4",
  V: "Type 5",
};

const emptyCopy = {
  DEFAULT: "Choose a previous test from the dropdown above to begin.",
  ERROR:
    "We are unable to retrieve data for your selection at this time. Please select something else.",
  NO_VALENCE: "There are no microbes in the selected comparison group.",
  ONE_TEST: `“Compare” makes it easy to see how your vaginal microbiome is changing over time.`,
  EVVY_MEMBERS: "You’ll automatically unlock Compare after your second test!",
};

const mobileCopy = {
  HEADER: "Hey there, small screen!",
  CONTENT:
    "Our “Compare” feature is best on a big screen. Revisit us from your computer or tablet to get the full experience!",
};

const dataTypes = {
  DEFAULT: "default",
  TEST: "test",
  TYPE: "type",
};

const colors = ["dv-pond", "dv-orange", "dv-pink", "dv-frog", "evvy-blue"];

const customOrder = [
  "symptoms_vaginal_smell",
  "symptoms_vaginal_discharge",
  "symptoms",
  "basics_last_period_date",
  "treatments",
  "diagnoses",
];

export {
  listItemDictionary,
  optionsDictionary,
  typesDictionary,
  emptyCopy,
  mobileCopy,
  colors,
  dataTypes,
  customOrder,
};
