import { useState, ReactNode } from "react";
import { ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

interface ExpandableComponentProps {
  title: string;
  closedTitle?: string;
  children: ReactNode;
}

const ExpandableComponent: React.FC<ExpandableComponentProps> = ({
  title,
  closedTitle,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full">
      {isOpen && (
        <div className="pl-8 py-2 space-y-2  rounded-b-md">{children}</div>
      )}
      <div
        onClick={toggleOpen}
        className="flex items-center space-x-2 rounded-md mt-2"
      >
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronRightIcon className="h-5 w-5 text-gray-500" />
        )}
        <span className="text-sm text-gray-500">
          {isOpen && closedTitle ? closedTitle : title}
        </span>
      </div>
    </div>
  );
};

export default ExpandableComponent;
