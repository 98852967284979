import { AxiosResponse, AxiosError } from "axios";
import { getRequest, postRequest } from "../utils/axios";

const fetchAnnouncements = async (
  onSuccess: (response: AxiosResponse) => void
) => {
  const response = await getRequest(
    "/api/v1/announcements/",
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

const handleViewAnnouncement = async (announcementId: string) => {
  const response = await postRequest(
    "/api/v1/announcements/",
    { announcement_id: announcementId },
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

export const announcementService = {
  fetchAnnouncements,
  handleViewAnnouncement,
};
