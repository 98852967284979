// valid success message copy

export const successMessageCopy = {
  EMAIL_VERIFIED: "Email Address Verified",
  PASSWORD_RESET: "Password reset. Please log in.",
};
export type SuccessMessageKey = keyof typeof successMessageCopy;

export const GENERAL_COACHING_CALL_LINK =
  "https://calendly.com/d/gqx-fp7-s52/evvy-1-1-coaching-call";

export const communityProviderRecsLink =
  "https://www.evvy.com/community-provider-recs";

export const facebookSupportGroupLink = "https://www.evvy.com/supportgroup";

export const askEvvyLink = "https://www.evvy.com/ask";

export const PDP_URL = "https://www.evvy.com/vaginal-microbiome-test";

export const evvyCareSupportCenterLink =
  "https://support.evvy.com/en/collections/4753441-clinical-care-questions";

export const sageDatabaseUpdateCopy =
  "* Evvy's updated test reflects newly identified bacterial species and may look slightly different from your last test.";

export const careRetestCheckoutLink =
  "https://evvybio.myshopify.com/cart/43067652735127:1?channel=buy_button&utm_source=myevvy&utm_medium=retest&utm_campaign=careretestflow";

export const ASK_EVVY_ARTICLES = [
  {
    title: "BV vs. yeast infections: what’s the difference?",
    link: "https://www.evvy.com/blog/bv-vs-yeast-whats-the-difference",
    image: "/images/graphics/microbial_colors.png",
  },
  {
    title: "7 reasons why your vaginal infection won’t go away",
    link: "https://www.evvy.com/blog/7-reasons-why-your-vaginal-infection-wont-go-away",
    image: "/images/graphics/pills.png",
  },
  {
    title:
      "Leading OB/GYNs: Why Evvy’s At-Home Vaginal Microbiome Test is Revolutionary",
    link: "https://www.evvy.com/blog/obgyn-evvy-vaginal-microbiome-test-review",
    image: "/images/graphics/hands.png",
  },
  {
    title: "How does sex influence vaginal pH?",
    link: "https://www.evvy.com/blog/sex-vaginal-ph-microbiome",
    image: "/images/graphics/back_square.png",
  },
  {
    title: "What is a yeast infection?",
    link: "https://www.evvy.com/blog/what-is-a-yeast-infection",
    image: "/images/graphics/sheets.png",
  },
];

export const WHITE_PAPER_LINK =
  "https://evvy-public-content.s3.us-east-2.amazonaws.com/documents/Evvy_Innovative_Vaginal_Care_Platform_White_Paper.pdf";

export const GET_CLINICAL_CARE = "Get Clinical Care";

export const CANCELLATION_EMAIL = "cancel@evvy.com";

export const SUPPORT_EMAIL = "support@evvy.com";

export const CARE_SUPPORT_EMAIL = "care@evvy.com";

export const VAGINAL_TEST_PRICE = 129;

export const TELEHEALTH_INFORMED_CONSENT =
  "https://www.wheel.com/wheel-provider-group-telehealth-informed-consent";

export const TERMS_OF_SERVICE = "https://www.evvy.com/terms-of-service";

export const PRIVACY_POLICY = "https://www.evvy.com/privacy-policy";

export const PROVIDER_DISCLAIMER_TEXT = `I'd like to automatically share my Evvy results & status of any follow-up care with the provider who ordered this test for me. *(Un-check this box if you'd rather not share any Evvy information with this provider!)*`;
