import OutlinedButton from "../plan_unfurled/outlinedButton";
import { ReactComponent as Lightbulb } from "../plan_unfurled/icons/lightbulb.svg";
import { secondaryColors } from "../../utils/viz";
import { eventNames } from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { evvyCareSupportCenterLink } from "../../pages/constants";

export const TreatmentFAQButton: React.FC<{
  analyticsArgs: { consultId?: string; testHash?: string };
}> = ({ analyticsArgs }) => {
  return (
    <div>
      <OutlinedButton
        href={evvyCareSupportCenterLink}
        icon={
          <Lightbulb
            stroke={secondaryColors["evvy-pine"]}
            width="100%"
            height="100%"
          />
        }
        text="FAQs"
        color="evvy-pine"
        sameTab={false}
        onClick={analyticsClickHandler({
          eventName: eventNames.TREATMENT_CLICKED_FAQ,
          eventArgs: analyticsArgs,
        })}
      />
    </div>
  );
};
