import { Bacteria } from "../../types/results";
import ArrowLink from "./arrowLink";
import UnorderedListItem from "../common/unorderedListItem";
import { sendClickedBacteriaAssociation } from "../../utils/analytics/customEventTracking";
import { useBaseAnalyticsArgs } from "../../hooks/useBaseAnalyticsArgs";
import { getIsMobile } from "../../utils/general";

const WHAT_TO_KNOW_COPY = "What to know:";
const ASSOCIATIONS_TITLE = "Associations:";

type BacteriaRowContentProps = {
  bacteria: Bacteria;
  open: boolean;
};

export const BacteriaRowContent: React.FC<BacteriaRowContentProps> = ({
  bacteria,
  open,
}: BacteriaRowContentProps) => {
  const baseAnalyticsArgs = useBaseAnalyticsArgs();
  const isMobile = getIsMobile();
  return (
    <>
      <div className="title tracking-wider mt-6" aria-hidden={`${!open}`}>
        {WHAT_TO_KNOW_COPY}
      </div>
      {/* if no description append default note */}
      <div
        className={`space-y-6 py-8 ${
          isMobile ? "b2 regular" : "t3"
        } cursor-text select-text`}
        role="list"
        aria-hidden={`${!open}`}
      >
        {bacteria.description && bacteria.description != ""
          ? bacteria.description
              .split("|")
              .map((point, i) => (
                <UnorderedListItem
                  key={`bacteria-description-${i}`}
                  text={point}
                  noBorder={i === bacteria.description.split("|").length - 1}
                />
              ))
          : "The scientific community is still figuring out this microbe's exact role in the vaginal microbiome, but we will keep your results updated as further research emerges!"}
      </div>

      {/* bacteria health associations */}
      {bacteria.bacteriaassociation_set.length >= 1 && (
        <div aria-hidden={`${!open}`}>
          <div className="t4">{ASSOCIATIONS_TITLE}</div>
          <div className="flex mt-3 flex-wrap t3">
            {bacteria.bacteriaassociation_set.map(({ name, link }, i) => (
              // pill with link
              <ArrowLink
                key={`bacteria-assoc-${i}`}
                href={link}
                text={name}
                ariaHidden={!open}
                onClick={() =>
                  sendClickedBacteriaAssociation({
                    bacteriaName: bacteria.short_name,
                    bacteriaId: bacteria.id,
                    associationTitle: name,
                    ...baseAnalyticsArgs,
                  })
                }
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
