import React from "react";

const UpdateReminderMsg = ({}) => {
  return (
    <div className="bg-coral p-4 font-medium rounded-md my-6">
      This section has been prefilled with your answers from a previous test.
      For anything which may have changed, please read carefully and update your
      answers.
    </div>
  );
};

export default UpdateReminderMsg;
