import {
  AddressInfo,
  HdyhauSurvey,
  HealthContextFields,
  Test,
} from "../../../types/test";
import PageWrapper from "../page";
import { useParams, useNavigate } from "react-router-dom";
import {
  HEALTH_HISTORY_PAGES,
  Page,
  getHealthHistoryURL,
  getNextPage,
  getPreviousPage,
} from "../../../utils/healthHistoryV2";
import { testsService } from "../../../services/tests";
import Header, {
  HEALTH_CONTEXT_SECTIONS,
  HEALTH_PROFILE_SECTIONS,
  getHealthHistorySection,
} from "../header";
import { useEffect, useState } from "react";
import { healthContextService } from "../../../services/healthContext";
import { Upgrade } from "../../../pages/tests/additional/upgrade";
import { sendClickedBackHealthHistoryPage } from "../../../utils/analytics/customEventTracking";

const HealthHistoryForm = ({
  healthContext,
  test,
  fetchTest,
  fetchHealthContext,
  updateHealthContext,
  createHealthContext,
  ldtEnabled,
  recordConsent,
  addressInfo,
  updateAddressInfo,
  hdyhauSurvey,
  submitHdyhauSurvey,
}: {
  healthContext: HealthContextFields;
  test: Test;
  fetchHealthContext: any;
  fetchTest: any;
  updateHealthContext: (
    hash: string,
    data: any,
    onSubmit: any,
    onError: any
  ) => void;
  createHealthContext: (
    hash?: string,
    success?: () => void,
    failure?: () => void
  ) => void;
  ldtEnabled: boolean;
  recordConsent: (
    hash?: string,
    success?: () => void,
    failure?: () => void
  ) => void;
  addressInfo: AddressInfo;
  updateAddressInfo: any;
  hdyhauSurvey?: HdyhauSurvey;
  submitHdyhauSurvey?: any;
}) => {
  const navigate = useNavigate();
  const { testHash: hash, pageSlug: slug } = useParams();
  const page = HEALTH_HISTORY_PAGES.find((page) => page.slug === slug);
  const [previousPage, setPreviousPage] = useState<Page | null | undefined>(
    null
  );

  const completedSample = healthContext && healthContext.sample_taken_at;
  const submittedHealthHistory =
    healthContext && healthContext.health_history_submitted_at;

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (page) {
      const previousPage = getPreviousPage({
        currentPage: page,
        healthContextValues: healthContext,
        test,
        ldtEnabled: ldtEnabled,
        expanUpsellEnabled: test?.expan_upsell_enabled,
      });
      setPreviousPage(previousPage);
    }
  }, [page]);

  if (!page) {
    // TODO: add error state
    return <h1>null</h1>;
  }

  const handleUpdateHealthContext = (
    data: any,
    onError: any,
    submitHdyhauSurvey?: any
  ) => {
    if (
      (completedSample && HEALTH_CONTEXT_SECTIONS.includes(page.section)) ||
      (submittedHealthHistory && HEALTH_PROFILE_SECTIONS.includes(page.section))
    ) {
      const nextPage = getNextPage({
        currentPage: page,
        healthContextValues: healthContext,
        test,
        ldtEnabled: ldtEnabled,
        expanUpsellEnabled: test?.expan_upsell_enabled,
      });
      if (nextPage) {
        const url = getHealthHistoryURL(hash, nextPage?.slug);
        navigate(url);
      }
    } else if (hash) {
      updateHealthContext(
        hash,
        data,
        () => {
          // Have to call this after the health context is updated
          const nextPage = getNextPage({
            currentPage: page,
            healthContextValues: healthContext,
            test,
            ldtEnabled: ldtEnabled,
            expanUpsellEnabled: test?.expan_upsell_enabled,
            hdyhauSurvey,
          });
          if (nextPage && nextPage.slug !== "done") {
            const url = getHealthHistoryURL(hash, nextPage?.slug);
            navigate(url);
          } else {
            testsService.submitHealthHistory(
              test.hash,
              () => {
                navigate(`/tests/${hash}/health-history/done/`);
                window.scrollTo(0, 0);
                if (submitHdyhauSurvey) {
                  submitHdyhauSurvey();
                }
              },
              (error: any, response: any) => {
                alert(response);
              }
            );
          }
        },
        onError
      );
    }
  };

  const submitCurrentPage = ({
    data,
    extraDataItems,
    relatedDiagnoses,
    onError,
  }: {
    data: any;
    extraDataItems: any;
    relatedDiagnoses: {
      updated_conditions: string[];
      field_updates: string[];
      delete_fields: boolean;
    };
    onError: any;
  }) => {
    if (hash) {
      if (
        page.slug === "other-related-diagnoses" ||
        (relatedDiagnoses && Object.keys(relatedDiagnoses).length !== 0)
      ) {
        return healthContextService.updateRelatedDiagnoses(
          hash,
          {
            updated_conditions: relatedDiagnoses.updated_conditions,
            field_updates: relatedDiagnoses.field_updates,
            // If creating only and no updates, conditions that are blank should be deleted.
            delete_fields: relatedDiagnoses.delete_fields,
          },
          () => {
            if (data) {
              handleUpdateHealthContext(data, onError);
            }
          }
        );
      } else if (extraDataItems) {
        healthContextService.createExtraData(
          hash,
          {
            items: extraDataItems,
          },
          () => {
            if (data) {
              handleUpdateHealthContext(data, onError);
            }
          }
        );
      } else if (data.hdyhau) {
        handleUpdateHealthContext(data, onError, () =>
          submitHdyhauSurvey(data.hdyhau)
        );
      } else {
        handleUpdateHealthContext(data, onError);
      }
    }
  };

  return (
    <div className="bg-evvy-cream min-h-screen">
      <Header
        onClickBack={() => {
          sendClickedBackHealthHistoryPage({
            testHash: hash,
            section: getHealthHistorySection(page.section),
            page: page.slug,
            questionGroup: page.section,
          });
        }}
        currentSection={page.section}
        previousPage={previousPage}
        hash={hash}
        prettyHash={test.pretty_hash}
      />
      {page.slug === "additional" ? (
        <>
          <Upgrade healthContext={healthContext} />
        </>
      ) : (
        <div className="max-w-full sm:max-w-[610px] py-8 mx-auto px-8 sm:px-0 static">
          {page && (
            <PageWrapper
              page={page}
              healthContext={healthContext}
              submitPage={submitCurrentPage}
              questions={page.questions}
              createHealthContext={createHealthContext}
              test={test}
              fetchTest={fetchTest}
              fetchHealthContext={fetchHealthContext}
              ldtEnabled={ldtEnabled}
              recordConsent={recordConsent}
              addressInfo={addressInfo}
              updateAddressInfo={updateAddressInfo}
              hdyhauSurvey={hdyhauSurvey}
              submitHdyhauSurvey={submitHdyhauSurvey}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default HealthHistoryForm;
