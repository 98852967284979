import React, { useEffect, useState, useRef } from "react";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import UpdateReminderMsg from "./updateReminderMessage";
import { indexCheckboxStyles, indexRadioStyles } from "../../utils/colors";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";
import {
  prefillAnswer,
  prefillMultiSelectAnswer,
  uncheckNoneChangeHandler,
} from "../../utils/answerUtils";

const HormonalHealthForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, test } = subrouteProps;

  // for tooltips
  const symptomsQuestion = useRef(null);
  const contraceptionTypeQuestion = useRef(null);
  const contraceptionTypeOtherQuestion = useRef(null);
  const contraceptionBrandQuestion = useRef(null);
  const menopauseQuestion = useRef(null);

  const allQuestions = {
    hormonal_symptoms: symptomsQuestion,
    contraception_types_used: contraceptionTypeQuestion,
    contraception_type_other: contraceptionTypeOtherQuestion,
    contraception_brand: contraceptionBrandQuestion,
    hormonal_menopause: menopauseQuestion,
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "profile",
      questionGroup: "hormonal health",
      testOrder: test.test_order,
      testHash: test.hash,
      version: 1,
    });
  }, []);

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  const populateInitialValues = () => {
    return {
      hormonal_symptoms: prefillMultiSelectAnswer({
        questionName: "hormonal_symptoms",
        healthContext,
        answerOptions: healthContext._options.hormonal_symptoms,
      }),
      contraception_types_used: prefillMultiSelectAnswer({
        questionName: "contraception_types_used",
        healthContext,
      }),
      contraception_type_other: prefillAnswer({
        questionName: "contraception_type_other",
        healthContext,
      }),
      contraception_brand: prefillAnswer({
        questionName: "contraception_brand",
        healthContext,
      }),
      hormonal_menopause: prefillAnswer({
        questionName: "hormonal_menopause",
        healthContext,
      }),
    };
  };

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">Hormonal Health</h3>
        <div className="flex-1 leading-5">
          Recent research is highlighting how hormonal fluctuations can affect
          the microbiome. If you suffer with recurring infections, paying
          attention to when they occur in relation to your cycle may be helpful.
          By giving us this context, we can provide you with personalized
          education and recommendations!
        </div>
      </div>

      {healthContext.past_health_history ? <UpdateReminderMsg /> : ""}

      <Formik
        initialValues={populateInitialValues()}
        validate={(values) => {
          var errors = {};
          Object.keys(allQuestions).forEach((key) =>
            ReactTooltip.hide(allQuestions[key].current)
          );

          // required questions
          const requiredQuestions = ["hormonal_menopause"];
          // conditionally required
          if (
            values["contraception_types_used"].filter(
              (item) => !["NO", "OT", "SU", "PS", "AW"].includes(item)
            ).length
          ) {
            requiredQuestions.push("contraception_brand");
          }
          requiredQuestions.forEach((key) => {
            if (!values[key]) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          // required multiselect questions (multi select, needs at least 1)
          const requiredMultiselectQuestions = [
            "hormonal_symptoms",
            "contraception_types_used",
          ];
          requiredMultiselectQuestions.forEach((key) => {
            if (!values[key].length) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          // required "other" context
          if (
            values["contraception_types_used"].includes("OT") &&
            !values[`contraception_type_other`]
          ) {
            errors[`contraception_type_other`] = "This is a required question";
            ReactTooltip.show(allQuestions[`contraception_type_other`].current);
          }

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          submitCurrentPage(values, (response) => {
            console.log("form got an error", response);
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((k) => {
                ReactTooltip.show(allQuestions[k].current);
              });
            } else {
              setError(response || "Error saving health context");
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("hormonal_symptoms", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("hormonal_symptoms", errors, apiErrors)}
              data-for="hormonal_symptoms"
              ref={symptomsQuestion}
            >
              <ReactTooltip
                id="hormonal_symptoms"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("hormonal_symptoms")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <p>Please select all that apply</p>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.hormonal_symptoms.map((o, i) => (
                  <label className="mb-3 cursor-pointer flex" key={o[0]}>
                    <Field
                      type="checkbox"
                      name="hormonal_symptoms"
                      value={o[0]}
                      className={indexCheckboxStyles(i)}
                      onChange={uncheckNoneChangeHandler({
                        handleChange,
                        setFieldValue,
                        fieldName: "hormonal_symptoms",
                        fieldValues: values.hormonal_symptoms,
                      })}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("contraception_types_used", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("contraception_types_used", errors, apiErrors)}
              data-for="contraception_types_used"
              ref={contraceptionTypeQuestion}
            >
              <ReactTooltip
                id="contraception_types_used"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("contraception_types_used")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <p>Please select all that apply</p>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.contraception_types_used.map((o, i) => (
                  <label
                    className="block mb-3 cursor-pointer flex items-center"
                    key={o[0]}
                  >
                    <Field
                      type="checkbox"
                      name="contraception_types_used"
                      value={o[0]}
                      className={indexCheckboxStyles(i)}
                      onChange={uncheckNoneChangeHandler({
                        handleChange,
                        setFieldValue,
                        fieldName: "contraception_types_used",
                        fieldValues: values.contraception_types_used,
                      })}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            {values.contraception_types_used.includes("OT") ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("contraception_type_other", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "contraception_type_other",
                  errors,
                  apiErrors
                )}
                data-for="contraception_type_other"
                ref={contraceptionTypeOtherQuestion}
              >
                <ReactTooltip
                  id="contraception_type_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("contraception_type_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="contraception_type_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            {values["contraception_types_used"].filter(
              (item) => !["NO", "OT", "SU", "PS", "AW"].includes(item)
            ).length ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("contraception_brand", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("contraception_brand", errors, apiErrors)}
                data-for="contraception_brand"
                ref={contraceptionBrandQuestion}
              >
                <ReactTooltip
                  id="contraception_brand"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("contraception_brand")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="contraception_brand"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("hormonal_menopause", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("hormonal_menopause", errors, apiErrors)}
              data-for="hormonal_menopause"
              ref={menopauseQuestion}
            >
              <ReactTooltip
                id="hormonal_menopause"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("hormonal_menopause")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.hormonal_menopause.map((o, i) => (
                  <label
                    className="block mb-3 cursor-pointer flex items-center"
                    key={o[0]}
                  >
                    <Field
                      type="radio"
                      name="hormonal_menopause"
                      value={o[0]}
                      className={indexRadioStyles(i)}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default HormonalHealthForm;
