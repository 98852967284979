import { createContext } from "react";
import { User } from "../types/user";

export type AuthContextType = {
  authenticated: boolean;
  accessToken?: string;
  refreshToken?: string;
  currentUser?: User;
};

export const AuthContext = createContext<AuthContextType>({
  authenticated: false,
});
