// imported components
import Markdown from "markdown-to-jsx";

// map of all questions & their titles (as we use this in multiple places)
const questionTitles = {
  // HEALTH CONTEXT - SYMPTOMS
  symptoms_has_current:
    "Do you currently have vaginal symptoms that you are trying to solve for?",
  symptoms_best_description:
    "Which of the following best describes your symptoms?",
  symptoms_reason_for_test: "Why are you taking this test?",
  // HEALTH CONTEXT - BASICS
  basics_reason_for_test: "Why are you taking the Vaginal Health Test?",
  basics_reason_for_test_other: 'Please describe "Other"',
  basics_primary_concerns:
    "What are your primary concerns (if any) about your vaginal health right now?", // deprecated
  basics_hoping_to_learn: "What are you hoping to learn from this new test?",
  basics_follow_recommendations:
    "Did you follow any of the recommendations provided in the My Plan section from your last test?",
  basics_follow_recommendations_details:
    "Which recommendations from My Plan did you follow?",
  basics_retest_symptoms_changed:
    "Since your last test, how have your vaginal symptoms changed?",
  basics_changes_since_last:
    "Has anything specific changed since your last test?",
  basics_changes_since_last_details: "Please describe",
  basics_recent_period: "Have you had a period in the last 60 days?",
  basics_last_period_date:
    "What was the first day of your last menstrual period?",
  basics_main_reason_for_test:
    "What is the main problem you're hoping Evvy can help you with?",
  // Post Care only
  post_care_symptom_change:
    "Since the start of your Evvy treatment program, how have your vaginal symptoms changed?",
  post_care_improved_symptoms:
    "Of the symptoms you experienced at the beginning of treatment, which of the following have improved over the course of treatment?",
  post_care_improved_symptoms_other: 'Please describe "Other"',
  post_care_worsened_symptoms:
    "Of the symptoms you experienced at the beginning of treatment, which of the following have worsened over the course of treatment?",
  post_care_worsened_symptoms_other: 'Please describe "Other"',
  post_care_had_side_effects:
    "Did you experience any side effects during your treatment protocol?",
  post_care_side_effects:
    "Did you experience any side effects during your treatment protocol?",
  post_care_side_effects_other: 'Please describe "Other"',
  post_care_followed_protocol:
    "On a scale of 1-5, how closely did you follow the protocol prescribed by your Evvy-affiliated provider? Reminder the protocol includes both treatment and best practices (e.g. using protection during intercourse)",
  post_care_treatments_taken:
    "Have you taken any of the following treatments for your vaginal health since your last test?",
  post_care_treatments_full_course_taken:
    "Did you complete the full course of treatment as prescribed for all of the treatments selected above?",
  post_care_treatments_not_completed:
    "Select which treatment(s) you did not complete the full dose for:",
  // HEALTH CONTEXT - SYMPTOMS
  vaginal_discharge:
    "Which of the following best describes your current discharge?",
  symptoms_vaginal_smell:
    "Which of the following best describes your **current vaginal odor / smell**? ",
  symptoms_vaginal_smell_v2:
    "Please confirm which of the following best describes your **current vaginal odor / smell**",
  symptoms_vaginal_smell_other: 'Please describe "Other"',
  symptoms_vaginal_discharge:
    "Which of the following describes your current vaginal discharge?", // deprecated
  symptoms_vaginal_discharge_other: 'Please describe "Other"', // deprecated
  symptoms_vaginal_discharge_color:
    "Which of the following best describes the **color of your current vaginal discharge**?",
  symptoms_vaginal_discharge_color_v2:
    "Please confirm which of the following best describes the **color of your current vaginal discharge**",
  symptoms_vaginal_discharge_color_other: 'Please describe "Other"',
  symptoms_vaginal_discharge_consistency:
    "Which of the following describes the **consistency of your current vaginal discharge**?",
  symptoms_vaginal_discharge_consistency_v2:
    "Please confirm which of the following best describes the **consistency of your current vaginal discharge**",
  symptoms_vaginal_discharge_consistency_other: 'Please describe "Other"',
  symptoms_additional:
    "Are you **currently experiencing** (within the past week) any of these symptoms?", // Asked once for all the below
  symptoms_additional_caption:
    "Please select all symptoms that apply and the severity per each symptom.",
  symptoms_additional_none: "None",
  symptoms_additional_excessive_discharge: "Excessive discharge",
  symptoms_additional_odorous_discharge: "Odorous discharge",
  symptoms_additional_vagina_pain: "Vaginal pain (internal)",
  symptoms_additional_vulva_pain: "Vulvar pain or swelling (external)",
  symptoms_additional_vulva_redness: "Vulvar redness or swelling",
  symptoms_additional_vulvar_burning: "Vulvar burning (external)",
  symptoms_additional_vaginal_burning_internal: "Vaginal burning (internal)",
  symptoms_additional_vaginal_pain_or_swelling: "Vaginal pain or swelling",
  symptoms_additional_vaginal_swelling: "Vaginal swelling",
  symptoms_additional_external_itchiness: "Vulvar itching (external)",
  symptoms_additional_internal_itchiness: "Vaginal itching (internal)",
  symptoms_additional_dryness: "Vaginal dryness",
  symptoms_additional_burning_sensation: "Burning sensation",
  symptoms_additional_pain_with_sex: "Pain with sex",
  symptoms_additional_pain_while_peeing: "Pain or burning while peeing",
  symptoms_additional_frequent_urination:
    "Frequent urination or urinary urgency (the 'gotta-go' feeling)",
  symptoms_obvious_trigger:
    "Do you feel like your symptoms have an obvious trigger?",
  symptoms_trigger_other: 'Please describe "Other"',

  // HEALTH CONTEXT - DIAGNOSES
  diagnoses_30:
    "**In the past 30 days, do you think you have had any of the following infections? Please select any infections that apply and whether or not you were diagnosed by a medical professional.**",
  diagnoses_30day_none: "None",
  diagnoses_30_caption:
    "You will be able to share history of infections beyond the past 30 days later in this form!",
  diagnoses_30day_bv: "Bacterial vaginosis (BV)",
  diagnoses_30day_yeast_infection: "Candidiasis (Yeast infection)",
  diagnoses_30day_uti: "Urinary tract infection (UTI)",
  diagnoses_30day_av: "Aerobic vaginitis (AV)",
  diagnoses_30day_ureaplasma: "Ureaplasma",
  diagnoses_30day_mycoplasma: "Mycoplasma",
  diagnoses_30day_vaginal_lactobacillosis: "Vaginal lactobacillosis",
  diagnoses_30day_cytolytic_vaginosis: "Cytolytic vaginosis",
  diagnoses_30day_chlamydia: "Chlamydia",
  diagnoses_30day_trichomoniasis: "Trichomoniasis",
  diagnoses_30day_gonorrhea: "Gonorrhea",
  diagnoses_30day_bv_method:
    "How did your medical provider diagnose your BV infection?",
  diagnoses_30day_bv_method_other: 'Please describe "Other"',
  diagnoses_30day_yeast_infection_method:
    "How did your medical provider diagnose your yeast infection?",
  diagnoses_30day_yeast_infection_method_other: 'Please describe "Other"',

  // below block should be moved to health profile
  diagnoses: "Tell us about your history with vaginal infections & STIs.", // Asked once for all the below
  diagnoses_caption:
    "Please select all conditions that apply and the frequency per each condition.",
  diagnoses_none: "None",
  diagnoses_bv: "Bacterial vaginosis (BV)",
  diagnoses_yeast_infection: "Candidiasis (Yeast infection)",
  diagnoses_uti: "Urinary tract infection (UTI)",
  diagnoses_av: "Aerobic vaginitis (AV)",
  diagnoses_ureaplasma: "Ureaplasma",
  diagnoses_mycoplasma: "Mycoplasma",
  diagnoses_vaginal_lactobacillosis: "Vaginal Lactobacillosis",
  diagnoses_cytolytic_vaginosis: "Cytolytic Vaginosis",
  diagnoses_chlamydia: "Chlamydia",
  diagnoses_trichomoniasis: "Trichomoniasis",
  diagnoses_genital_herpes: "Genital Herpes",
  diagnoses_genital_warts: "Genital Warts",
  diagnoses_hiv: "HIV",
  diagnoses_hpv: "HPV",
  diagnoses_gonorrhea: "Gonorrhea",

  // HEALTH CONTEXT - TREATMENTS
  treatments:
    "Have you used/tried any of the following treatments or products in the **past 30 days**?",
  treatments_other: 'Please describe "Other"',
  healthcontextextradata_set: "", // this one's special. user answers treatments 0-n times, for each option fills in the below questions
  treatments_brand: "What brand are you using?",
  treatments_why: "Why are you using it?",
  treatments_start: "When did you start?",
  treatments_end: "Date of last use?",
  treatments_often: "How often do you use it?",
  treatments_helped: "Do you feel that it has helped your symptoms?",
  treatments_brand_or_product:
    "What is the name of the brand and product you are using?",
  ["treatments__boric-acid__brand_or_product"]: "Boric acid suppositories",
  ["treatments__feminine-hygiene__brand_or_product"]:
    "Feminine hygiene products",
  ["treatments__douching__brand_or_product"]: "Douching products",
  ["treatments__probiotics__brand_or_product"]: "Probiotics",
  prescription_treatment:
    "Have you used any of the following prescription treatments in the past 30 days?",
  prescription_treatment_caption:
    "Please select all prescription treatments that apply and the prescription reason per each treatment.",
  other_vaginal_health_products:
    "Have you used any other vaginal health products in the past 30 days?",
  other_vaginal_health_products_other: 'Please describe "Other"',
  treatment_response:
    "How have your symptoms changed as a result of the prescription treatments or products you've tried?",
  treatment_response_none: "None",
  treatment_response_antibiotics_metro:
    "Antibiotic (Metronidazole: Flagyl, Metrogel)",
  treatment_response_antibiotics_clinda:
    "Antibiotic (Clindamycin: Cleocin, Xaciato, Clindamax)",
  treatment_response_antibiotics_other: "Other antibiotic",
  treatment_response_antifungal:
    "Antifungal (e.g. fluconazole, clotrimazole, brexafemme, nystatin)",
  treatment_response_hormone_therapy: "Hormone therapy",
  treatment_response_douching: "Douching",
  treatment_response_feminine_hygiene:
    "Feminine hygiene products (wash, spray, wipes)",
  treatment_response_probiotics: "Probiotics",
  treatment_response_boric_acid: "Boric acid suppositories",

  prescription_treatment_none: "None",
  prescription_treatment_antibiotics_metro:
    "Antibiotic (Metronidazole: Flagyl, Metrogel)",
  prescription_treatment_antibiotics_clinda:
    "Antibiotic (Clindamycin: Cleocin, Xaciato, Clindamax)",
  prescription_treatment_antibiotics_not_listed: "Other antibiotic",
  prescription_treatment_antifungal:
    "Antifungal (e.g. fluconazole, clotrimazole, brexafemme, nystatin)",
  prescription_treatment_hormone_therapy: "Hormone therapy",

  // HEALTH CONTEXT - ANYTHING ELSE
  health_context_anything_else:
    "Is there anything else you'd like us to know about your vaginal health?",

  // HEALTH HISTORY - DEMOGRAPHICS
  demographics_height: "How tall are you?",
  demographics_weight: "How much do you weigh? (in pounds)",
  demographics_race: "What race / ethnicity do you identify with?",
  demographics_race_other: 'Please describe "Other"',
  demographics_hispanic_or_latino: "Do you identify as Hispanic or Latino?",
  demographics_born_with_vagina: "Were you born with a vagina?",
  demographics_born_with_vagina_other: 'Please describe "Other"',
  demographics_gender_affirming_care:
    "Have you undergone any gender affirming care resulting in a neovagina?",
  demographics_gender_identity: "What gender do you identify with?",
  demographics_gender_identity_other: 'Please describe "Other"',

  // HEALTH HISTORY - LIFESTYLE
  lifestyle_smoking: "Do you smoke? (any tobacco including vapes)",
  lifestyle_exercise: "How often do you exercise?",
  lifestyle_diet: "Do you follow any specific diet?",
  lifestyle_diet_other: 'Please describe "Other"',

  // HEALTH HISTORY - QUALITY OF LIFE
  related_diagnoses_quality_of_life:
    "How much have your vaginal symptoms affected your quality of life?",
  quality_of_life_most_affected:
    "Which aspects of your life have your symptoms most affected?",
  basics_why_evvy: "Which of the following are you hoping Evvy can help with?",
  basics_why_evvy_other: 'Please describe "Other"',
  condition: "Condition",
  diagnosis_year: "Diagnosis year",

  // HEALTH HISTORY - RELATED DIAGNOSES
  related_diagnoses:
    "Have you been diagnosed with any of the following conditions?",
  related_diagnoses_caption:
    "Select all that apply and the year you were diagnosed.",
  related_diagnoses_diabetes:
    "Please specify whether diabetes is controlled or uncontrolled",
  related_diagnoses_other: 'Please describe "Other"',
  related_diagnoses_immunocompromised:
    "Are you currently immunocompromised or actively on immunosuppressants?",
  related_diagnoses_trigger:
    "Do you feel there is a specific trigger that brings on infections and/or symptoms?", // deprecated
  related_diagnoses_trigger_other: 'Please describe "Other"', // deprecated

  // HEALTH HISTORY - RELATED DIAGNOSES DIAGNOSIS DATE
  diagnosis_year_infertility:
    "What year were you diagnosed with Infertility or Subfertility?",
  diagnosis_year_pid:
    "What year were you diagnosed with Pelvic Inflammatory Disease (PID)?",
  diagnosis_year_pcos:
    "What year were you diagnosed with Polycystic Ovary Syndrome (PCOS)?",
  diagnosis_year_pmdd:
    "What year were you diagnosed with Premenstrual Dysphoric Disorder (PMDD)?",
  "diagnosis_year_missing-periods":
    "What year were you diagnosed with Functional Hypothalamic Amenorrhea (missing periods)?",
  diagnosis_year_endometriosis:
    "What year were you diagnosed with Endometriosis?",
  diagnosis_year_adenomyosis: "What year were you diagnosed with Adenomyosis?",
  diagnosis_year_cancer: "What year were you diagnosed with Cancer?",
  "diagnosis_year_lichen-sclerosus":
    "What year were you diagnosed with Lichen Sclerosus?",
  "diagnosis_year_interstitial-cystitis":
    "What year were you diagnosed with Interstitial Cystitis?",
  "diagnosis_year_atrophic-vaginitis":
    "What year were you diagnosed with Atrophic Vaginitis?",
  diagnosis_year_gsm:
    "What year were you diagnosed with Genitourinary Syndrome of Menopause (GSM)?",
  "diagnosis_year_vaginal-prolapse":
    "What year were you diagnosed with Vaginal Prolapse?",
  "diagnosis_year_urinary-incontinence":
    "What year were you diagnosed with Urinary Incontinence?",
  diagnosis_year_vulvodynia: "What year were you diagnosed with Vulvodynia?",
  diagnosis_year_vaginismus: "What year were you diagnosed with Vaginismus?",
  diagnosis_year_crohns: "What year were you diagnosed with Crohn's Disease?",
  diagnosis_year_ibs:
    "What year were you diagnosed with Irritable Bowel Syndrome (IBS)?",
  diagnosis_year_sibo:
    "What year were you diagnosed with Small Intestinal Bacterial Overgrowth (SIBO)?",
  diagnosis_year_diabetes: "What year were you diagnosed with Diabetes?",
  "diagnosis_year_genital-warts":
    "What year were you diagnosed with Genital Warts?",
  diagnosis_year_hiv: "What year were you diagnosed with HIV?",
  diagnosis_year_herpes: "What year were you diagnosed with Herpes?",
  diagnosis_year_hpv:
    "What year were you diagnosed with Human Papillomavirus (HPV)?",
  diagnosis_year_hpv: "What year were you diagnosed with HPV?",
  diagnosis_year_graves:
    "What year were you diagnosed with Hypothyroidism or Graves' Disease?",
  diagnosis_year_cin:
    "What year were you diagnosed with Cervical Intraepithelial Neoplasia (CIN)?",
  diagnosis_year_depression: "What year were you diagnosed with Depression?",
  diagnosis_year_anxiety: "What year were you diagnosed with Anxiety?",
  "diagnosis_year_heart-disease":
    "What year were you diagnosed with Heart Disease?",
  diagnosis_year_allergies: "What year were you diagnosed with Allergies?",
  diagnosis_year_autoimmune:
    "What year were you diagnosed with an Autoimmune Condition?",
  diagnosis_year_other: 'What year were you diagnosed with "Other"?',
  "diagnosis_year_cancer-breast":
    "What year were you diagnosed with Breast Cancer?",
  "diagnosis_year_cancer-cervical":
    "What year were you diagnosed with Cervical Cancer?",
  "diagnosis_year_cancer-ovarian":
    "What year were you diagnosed with Ovarian Cancer?",
  "diagnosis_year_cancer-endometrial":
    "What year were you diagnosed with Endometrial Cancer?",
  "diagnosis_year_cancer-other": "What year were you diagnosed with Cancer?",

  // HEALTH HISTORY - RELATED DIAGNOSES DETAILS
  autoimmune_conditions:
    "Which specific autoimmune condition(s) have you been diagnosed with?",
  infertility_cause_identified: `Was your doctor able to identify a potential cause of infertility or was it considered "unexplained" infertility?`,
  infertility_cause: "What was the root cause identified?",
  cancer_treatment: "Did you undergo any of the following treatments?",
  cancer_treatment_other: 'Please describe "Other"',
  cancer_type_other: "What type of cancer?",
  cancer_in_remission: "Are you in remission?",
  cancer_last_surgery_date: "When was the date of your last surgery?",
  cancer_last_treatment_date: "When was the date of your last treatment?",
  cancer_last_radiation_treatment_date:
    "When was the date of your last radiation treatment?",
  cancer_last_chemotherapy_treatment_date:
    "When was the date of your last chemotherapy treatment?",

  breast_cancer_treatment:
    "Did you undergo any of the following treatments for Breast Cancer?",
  breast_cancer_treatment_other: 'Please describe "Other"',
  breast_cancer_in_remission: "Are you in remission for Breast Cancer?",
  breast_cancer_last_surgery_date:
    "When was the date of your last surgery for Breast Cancer?",
  breast_cancer_last_treatment_date:
    "What was the date of your last chemotherapy or radiation treatment for Breast Cancer?",
  breast_cancer_last_radiation_treatment_date:
    "When was the date of your last radiation treatment for Breast Cancer?",
  breast_cancer_last_chemotherapy_treatment_date:
    "When was the date of your last chemotherapy treatment for Breast Cancer?",

  ovarian_cancer_treatment:
    "Did you undergo any of the following treatments for Ovarian Cancer?",
  ovarian_cancer_treatment_other: 'Please describe "Other"',
  ovarian_cancer_in_remission: "Are you in remission for Ovarian Cancer?",
  ovarian_cancer_last_surgery_date:
    "When was the date of your last surgery for Ovarian Cancer?",
  ovarian_cancer_last_radiation_treatment_date:
    "When was the date of your last radiation treatment for Ovarian Cancer?",
  ovarian_cancer_last_chemotherapy_treatment_date:
    "When was the date of your last chemotherapy treatment for Ovarian Cancer?",

  cervical_cancer_treatment:
    "Did you undergo any of the following treatments for Cervical Cancer?",
  cervical_cancer_treatment_other: 'Please describe "Other"',
  cervical_cancer_in_remission: "Are you in remission for Cervical Cancer?",
  cervical_cancer_last_surgery_date:
    "When was the date of your last surgery for Cervical Cancer?",
  cervical_cancer_last_radiation_treatment_date:
    "When was the date of your last radiation treatment for Cervical Cancer?",
  cervical_cancer_last_chemotherapy_treatment_date:
    "When was the date of your last chemotherapy treatment for Cervical Cancer?",

  endometrial_cancer_treatment:
    "Did you undergo any of the following treatments for Endometrial Cancer?",
  endometrial_cancer_treatment_other: 'Please describe "Other"',
  endometrial_cancer_in_remission:
    "Are you in remission for Endometrial Cancer?",
  endometrial_cancer_last_surgery_date:
    "When was the date of your last surgery for Endometrial Cancer?",
  endometrial_cancer_last_treatment_date:
    "What was the date of your last chemotherapy or radiation treatment for Endometrial Cancer?",
  endometrial_cancer_last_radiation_treatment_date:
    "When was the date of your last radiation treatment for Endometrial Cancer?",
  endometrial_cancer_last_chemotherapy_treatment_date:
    "When was the date of your last chemotherapy treatment for Endometrial Cancer?",

  other_cancer_type_other: "What type of cancer?",
  other_cancer_treatment:
    "Did you undergo any of the following treatments for this diagnosis?",
  other_cancer_treatment_other: 'Please describe "Other"',
  other_cancer_in_remission: "Are you in remission?",
  other_cancer_last_surgery_date:
    "When was the date of your last surgery for this diagnosis?",
  other_cancer_last_radiation_treatment_date:
    "When was the date of your last radiation treatment for this diagnosis?",
  other_cancer_last_chemotherapy_treatment_date:
    "When was the date of your last chemotherapy treatment for this diagnosis?",

  diabetes_type: "What type of diabetes do you have?",
  diabetes_hba1c: "What was your most recent HbA1C?",
  diabetes_last_hba1c_date: "When was your most recent HbA1C taken?",
  herpes_outbreak_location: "Where are you experiencing Herpes outbreaks?",
  hpv_last_screening_date: "When was your last HPV screening?",
  cin_type: "What type of CIN were you most recently diagnosed with?",
  last_screening_date: "When was your last screening? (Optional)",
  endometriosis_diagnosis_method: "How was your Endometriosis diagnosed?",
  endometriosis_diagnosis_method_other: 'Please describe "Other"',
  endometriosis_treatment: "Have you been treated with any of the following?",
  endometriosis_treatment_other: 'Please describe "Other"',
  endometriosis_lesion_surgery_date:
    "When was the date of your last surgery to remove lesions? (Optional)",

  // HEALTH HISTORY - RELATED DIAGNOSES FERTILITY TREATMENT TYPES
  infertility_treatments: "Have you undergone any fertility treatments?",
  infertility_treatments_caption:
    "We know this can be a difficult topic, so we've included a <i>'Prefer not to say'</i> option.",
  infertility_treatments_other: 'Please describe "Other"',
  fertility_treatment_none: "Prefer not to say",
  fertility_treatment_intrauterine_insemination:
    "Intrauterine insemination (IUI)",
  fertility_treatment_in_vitro_fertilization: "In vitro fertilization (IVF)",
  fertility_treatment_hormone_therapy:
    "Hormone therapy (eg. progesterone, estrogen)",
  fertility_treatment_ovulation_induction:
    "Ovulation induction (eg. Clomid, Femara, Gonandtropins)",
  fertility_treatment_surgical_interventions:
    "Surgical interventions (eg. laparoscopy for endometriosis)",
  fertility_treatment_alternative_therapies:
    "Alternative or complementary therapies (eg. acupuncture, supplements)",
  fertility_treatment_therapy_not_listed: "Other fertility treatment",
  fertility_treatment_other: "Please describe your other fertility treatment",

  // HEALTH HISTORY - HORMONAL HEALTH
  hormonal_menopause_hormones: "Are you currently taking any hormones?",
  hormonal_menopause_hormones_other: 'Please describe "Other"',
  hormonal_menopause_symptoms:
    "Are you experiencing any of the following menopause-related symptoms?",
  hormonal_menopause_hysterectomy: "Have you had a hysterectomy?",
  hormonal_menopause_hysterectomy_caption:
    "A hysterectomy is when you have had your uterus removed, either on its own or along with the ovaries or cervix.",
  hormonal_menstrual_cycle:
    "How many days long is your menstrual cycle on average?",
  hormonal_period_days: "How many days does your period last on average?",
  hormonal_period_counts:
    "How many periods have you had in the last 12 months?",
  hormonal_period_products:
    "Which of the following period products do you use?",
  hormonal_period_products_other: 'Please describe "Other"',
  hormonal_symptoms:
    "Have you experienced any of the following hormone related symptoms?",
  hormonal_symptoms_v2:
    "Have you been experiencing any of the following hormone related symptoms **in the past 3 months**?",
  hormonal_menopause: "What best describes your current menopause status?",

  // HEALTH HISTORY - CONTRACEPTION
  contraception_types_used:
    "Do you use any of the following forms of contraception?",
  contraception_type: "What type of contraception do you use?",
  contraception_type_other: 'Please describe "Other"',
  contraception_brand:
    "What are the name(s) / brand(s) of contraception you are using?",
  contraception_changed:
    "Have you changed contraception types in the past 12 months?", // deprecated

  // HEALTH HISTORY - SEXUAL ACTIVITY
  sexual_activity_timing: "Are you sexually active?",
  sexual_activity:
    "Which of the following are relevant to your sexual activity in the past 30 days?",
  sexual_activity_condoms:
    "Have you used a barrier method (condoms or dental dams) during sexual activity in the past 30 days?",
  sexual_activity_condoms_v2:
    "How often do you use barrier method (condoms or dental dams) during sexual activity?",
  sexual_activity_partners:
    "How many sexual partners have you had in the past 30 days?",
  recent_sexual_activity:
    "Which of these describe your recent sexual activity?",

  // HEALTH HISTORY - PREGNANCY
  pregnancy_status:
    "Which of the following describes your pregnancy status at the time of taking this sample?",
  pregnancy_breastfeeding: "Are you currently breastfeeding?",
  pregnancy_postpartum_timing: "When did you most recently give birth?",
  pregnancy_month: "How many months pregnant are you?",
  pregnancy_previously: "Have you previously been pregnant?",
  pregnancy_experienced: "Have you experienced any of the following?",
  pregnancy_experienced_other: 'Please describe "Other"',
  pregnancy_experienced_caption:
    "We know some of these are difficult topics, so we’ve included a <i>'Prefer not to say'</i> option.",

  // HEALTH HISTORY - ADDITIONAL
  health_history_anything_else:
    "Is there anything else you would like to share with us?",
  // Survey Questions - HDYHAU
  hdyhau: "How did you hear about Evvy?",
  which_influencer: "Which influencer?",
  which_community: "Which community?",
  which_social_media: "Which social media platform?",
  which_social_media_other: 'Please describe "Other"',
  which_advertisement: "Which advertisement?",
  which_advertisement_other: 'Please describe "Other"',
  which_news: "Which news / online article?",
  hdyhau_other: 'Please describe "Other"',
  when_heard_about_evvy: "When did you first hear about Evvy?",

  // Consultation Intake
  intake_currently_pregnant: "Are you currently pregnant?",
  intake_active_estrogen_prescription:
    "Do you have an active prescription for vaginal estrogen?",
  intake_allergies:
    "Have you had an adverse/allergic reaction to or are unwilling to take any of the following medications?",
  intake_allergies_other: "Please list.",
  intake_yeast_history:
    "Do you have a history of getting a yeast infection after taking antibiotics?",
  intake_vaginal_estrogen:
    "Are you open to taking vaginal estrogen if your clinician recommends it?",
  intake_sti_treated:
    "Your last Evvy test detected a positive STI - have you been treated for this since receiving the diagnosis?",
  intake_sti_treatments: "What treatment did you receive for your STI?",
  intake_sti_treatments_other: 'Please describe "Other"',
  intake_current_symptoms:
    "Please select any moderate or severe symptoms that you are currently experiencing.",
  intake_post_period_symptoms:
    "Do your symptoms worsen directly after your period?",
  intake_anything_else:
    "Is there anything else you'd like your provider to know as they put together your personalized treatment plan?",
  intake_severe_symptoms:
    "Please confirm that you do not currently have a high fever (>101.9F/38.8C), severe abdominal pain, severe pelvic pain, severe flank pain, and/or abnormal vaginal bleeding. If you are experiencing any of these, please seek immediate medical attention or call 911.",

  // STI intake extra questions
  known_sti_exposures:
    "Have you had recent exposure to the following sexually transmitted infections? Select all that apply.",
  details_of_known_sti_exposures:
    "Please list STI(s) that you have been exposed to along with the date of exposure.",
  sti_intake_allergies:
    "Have you had an adverse/allergic reaction to or are you unwilling to take any of the following medications? Please note that this will directly impact what your provider prescribes.",
  sti_intake_allergies_other: "Please list.",
  option_none: "None",

  // Ungated Rx intake
  ungated_rx_allergies:
    "**Have you had an adverse/allergic reaction to any medications?** Please note that this will directly impact what your provider prescribes.",
  ungated_rx_symptoms:
    "**Are you currently experiencing (within the past week) any of these symptoms?** Please select all that apply and the severity per each symptom.",
  ungated_rx_boric_acid: "**How are you hoping boric acid can help you?**",
};

const getQuestionTitle = (key) => {
  return <Markdown>{questionTitles[key] || "MISSING QUESTION TITLE"}</Markdown>;
};

export { questionTitles, getQuestionTitle };
