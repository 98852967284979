import React from "react";
import { getQuestionTitle, questionTitles } from "../../../utils/questions";
import { SectionIntro } from "../../../types/test";

const QuestionTitle = ({
  questionTitle,
  sectionIntro,
  optional,
}: {
  questionTitle: string;
  sectionIntro?: SectionIntro;
  optional?: boolean;
}) => {
  let title = questionTitle;
  if (window.location.pathname.includes("cancer")) {
    // takes a cancer type from the URL if it exists e.g. /health-history/cancer-breast
    const cancerType = window.location.pathname
      .split("/")
      .pop()
      ?.split("-")
      .pop();
    title = cancerType + "_" + questionTitle;
  }
  const v2Array = [
    "symptoms_vaginal_smell",
    "symptoms_vaginal_discharge_color",
    "symptoms_vaginal_discharge_consistency",
    "sexual_activity_condoms",
    "hormonal_symptoms",
  ];
  if (v2Array.includes(questionTitle)) {
    title = questionTitle + "_v2";
  }
  let caption;
  if (Object.keys(questionTitles)?.includes(title + "_caption")) {
    caption = getQuestionTitle(title + "_caption");
  }

  let otherQuestionTitle;
  if (questionTitle.includes("_other") && !getQuestionTitle(title)) {
    otherQuestionTitle = 'Please describe "Other"';
  }

  return (
    <div className="b2 sm:b1 leading-6 mb-4">
      <span className="semibold">
        {otherQuestionTitle ? otherQuestionTitle : getQuestionTitle(title)}
      </span>{" "}
      {caption && <span className="regular">{caption}</span>}
    </div>
  );
};

export default QuestionTitle;
