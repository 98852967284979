import Markdown from "markdown-to-jsx";
import { askEvvyLink, ASK_EVVY_ARTICLES } from "../../pages/constants";
import { ReactComponent as AskEvvyLogo } from "./icons/askEvvyLogo.svg";
import { sendTestsClickedBlogLink } from "../../utils/analytics/customEventTracking";

export const AskEvvyCard: React.FC<{}> = () => {
  const ASK_EVVY_DESCRIPTION_MD = `Looking for more resources as you take your first test? Check out our blog on <u>[#AskEvvy](${askEvvyLink}).</u>`;

  return (
    <div className="bg-white p-6 sm:p-8 rounded-2xl">
      <AskEvvyLogo />
      <div className="b2 medium py-4">
        <Markdown>{ASK_EVVY_DESCRIPTION_MD}</Markdown>
      </div>
      {/* map array of ASK_EVVY_ARTICLES to rows containing the image graphic on the left and then the title. Clicking anywhere on the row should open the link in a new tab */}
      {ASK_EVVY_ARTICLES.map((article, i) => (
        <div key={i} className="py-2">
          <a
            href={article.link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row items-center"
            onClick={() =>
              sendTestsClickedBlogLink({
                link: article.link,
                ctaText: article.title,
              })
            }
          >
            <img
              src={article.image}
              loading="lazy"
              alt={article.title}
              className="rounded-lg h-16 w-16 object-cover"
            />
            <span className="pl-4 b2 medium">{article.title}</span>
          </a>
        </div>
      ))}
    </div>
  );
};
