import { researchCopy } from "../../pages/plan_unfurled/constants";
import { Prescription, ProductCitation } from "../../types/care";

const BORDER_STYLING = "border-b-2 border-evvy-black-dull border-dotted";

export const TreatmentResearchRating = ({
  treatment,
}: {
  treatment: Prescription;
}) => {
  return (
    <div className="b1">
      <div className="b1">
        <div className={`pb-3 my-3 ${BORDER_STYLING}`}>
          <p className="text-evvy-pine mb-1">
            <span className="medium">Status: </span>
            <span>{treatment.research_rating?.title}</span>
          </p>
          <div>{treatment.research_rating?.description}</div>
        </div>
        <div className={`pb-3 ${BORDER_STYLING}`}>{researchCopy.RESEARCH}</div>
      </div>
      {treatment.productcitation_set.length && (
        <div className="py-3">
          <div className="text-evvy-pine medium">Our Sources</div>
          <div className="space-y-2">
            {treatment?.productcitation_set?.map(
              (citation: ProductCitation) => (
                <div key={citation?.title}>
                  <a
                    href={citation?.url}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center medium"
                  >
                    <div className="border-b border-black">
                      {citation?.title}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 ml-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                      />
                    </svg>
                  </a>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};
