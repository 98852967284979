import React from "react";
import "./styles.css";
import { HEALTH_CONTEXT_SECTIONS, HEALTH_PROFILE_SECTIONS } from "./header";

const ProgressBar = ({
  currentSection,
  currentQuestion,
}: {
  currentSection?: string;
  currentQuestion?: string;
}) => {
  if (!currentSection && !currentQuestion) {
    return null;
  }

  let progress = 0;
  if (currentSection && HEALTH_CONTEXT_SECTIONS.includes(currentSection)) {
    const sectionIndex = HEALTH_CONTEXT_SECTIONS.findIndex(
      (section) => section === currentSection
    );
    progress = (sectionIndex / HEALTH_CONTEXT_SECTIONS.length) * 50;
  } else if (
    currentSection &&
    HEALTH_PROFILE_SECTIONS.includes(currentSection)
  ) {
    const sectionIndex = HEALTH_PROFILE_SECTIONS.findIndex(
      (section) => section === currentSection
    );
    progress = (sectionIndex / HEALTH_PROFILE_SECTIONS.length) * 50;
    progress += 50;
  }

  if (currentSection === "Sample") {
    progress = 50;
  }
  if (currentSection === "What's next?") {
    progress = 100;
  }

  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${progress}%` }} />
    </div>
  );
};

export default ProgressBar;
