import React from "react";

import { ReactComponent as Blood } from "../../common/icons/blood.svg";

// types
import { Period, TreatmentPlan } from "../../../types/care";
import { formatDate, strDateToTimestamp } from "../../../utils/datetime";

export const YourLogs: React.FC<{
  treatmentPlan: TreatmentPlan;
  openEditPeriodModal: (id: string) => void;
  openAddPeriodModal: () => void;
  openResetCalendarModal?: () => void;
  onClickPopup?: () => void;
  calendarModified?: boolean;
}> = ({
  treatmentPlan,
  openEditPeriodModal,
  openResetCalendarModal,
  openAddPeriodModal,
  onClickPopup,
  calendarModified,
}) => {
  const periods = treatmentPlan.calendar.periods;
  return (
    <div className="mt-5">
      {calendarModified && (
        <div className={"grid justify-items-end"}>
          <p
            onClick={onClickPopup || openResetCalendarModal}
            className={
              "font-semibold uppercase tracking-wide text-sm/[13px] cursor-pointer underline underline-offset-4"
            }
          >
            <div>Reset calendar</div>
          </p>
        </div>
      )}
      <div className="medium mb-3">Your logs</div>
      <div className="space-y-3">
        {periods.map((period, index) => {
          return (
            <PeriodLog
              key={`period-${index}`}
              period={period}
              openEditPeriodModal={openEditPeriodModal}
            />
          );
        })}
      </div>
      <button
        onClick={onClickPopup || openAddPeriodModal}
        className={
          "flex items-center justify-center bg-white text-evvy-black hover:bg-black hover:text-white border border-black mt-4 py-4 px-8 w-full rounded-sm font-semibold tracking-wider focus:outline-none"
        }
      >
        <Blood className="w-4 h-4 mr-2" stroke="currentColor" />
        <div>Log a period</div>
      </button>
    </div>
  );
};

const PeriodLog: React.FC<{
  period: Period;
  openEditPeriodModal: (id: string) => void;
}> = ({ period, openEditPeriodModal }) => {
  return (
    <div
      className={`bg-evvy-cream p-4 flex justify-between rounded-md my-auto content-center`}
    >
      <div className="my-auto">{`Period: ${formatDate(
        strDateToTimestamp(period.start_date),
        false
      )} - ${formatDate(strDateToTimestamp(period.end_date), false)}`}</div>
      <div
        className={`font-semibold uppercase tracking-wide text-sm/[13px] cursor-pointer`}
        onClick={() => {
          //   open period modal
          openEditPeriodModal(period.id);
        }}
      >
        {"Edit"}
      </div>
    </div>
  );
};
