/**
 * A styled card for the 'your plan' section (microbial steps)
 * @param {string} tag a string of the tag text (such as 'Reduce Disruptive')
 * @param {string} title a string of the title text
 * @param {string} description a string of the details for this insight
 * @param {string} researchRating an object containing a string key called 'title' whose value is a string of the status of the research (e.g. 'Emerging') to be passed onto the Research Item component
 * @param {string} link a string with the text label for the LinkedText component
 * @param {number} number a number of the step associated with the card
 * @param {boolean} noLine determines whether card's timeline line is hidden (default is false, only true for last card in timeline)
 * @param {*} openModalWith callback to handle link click and open modal, passing along card data
 * @param {boolean} userIsMember a boolean value indicating whether the current user has an evvy membership subscription or not (determines evvy product CTA)
 * @returns jsx of a card
 */

import React from "react";
import { useDispatch } from "react-redux";

// imported components
import Markdown from "markdown-to-jsx";

// components
import LinkedText from "../common/linkedText";
import RectangularButton from "../common/rectangularButton";
import ResearchItem from "./researchItem";
import Tag from "./tag";
import TimelineNumber from "./timelineNumber";

// lib
import { modalTypes as MTYPES } from "../../pages/plan_unfurled/constants.tsx";
import { setPlanModalStep } from "../../pages/plan/planSlice";
import { eventNames } from "../../utils/analytics/customEventTracking.js";
import { analyticsClickHandler } from "../../utils/analytics/helpers";

const PlanCard = ({
  data,
  allData,
  number,
  totalSteps,
  link = "Learn more",
  openModalWith,
  userIsMember,
  analyticsEventArgs = null,
}) => {
  const {
    tag = "Reduce Disruptive",
    title = "",
    description = "",
    research_rating: researchRating = "",
    planitemrecommendation_set: recommendations,
    get_group_display: groupName,
    slug,
  } = data;

  // get first line of text
  const [firstLine = ""] = description?.split("|") || [""];
  // find recommendation with is_evvy_product flag in recommendations array
  const isEvvyProduct = recommendations.find(
    ({ is_evvy_product = false }) => is_evvy_product
  );
  // get url and text from evvy product recommendation
  const { url: productUrl = "", display_text: productText = "" } =
    isEvvyProduct || {};

  const handleClick = () => {
    if (userIsMember) openModalWith({ type: MTYPES.NEXT_TEST }); // open modal
  };

  /* Redux */
  // redux dispatch method for updating global store
  const dispatch = useDispatch();

  return (
    <div className="flex px-2 sm:px-0">
      {/* timeline element */}
      <div className="flex flex-col items-center">
        <TimelineNumber number={number} />
        {/* draw border line except for last item in list */}
        {number !== totalSteps && (
          <div className="border border-evvy-pine/25 h-full" />
        )}
      </div>
      {/* plan info card */}
      <div
        className={`w-full text-left text-evvy-black pl-4 sm:pl-10 ${
          number === allData?.length ? "" : "pb-16"
        } `}
      >
        <div className="mb-8" style={{ paddingTop: "5px" }}>
          <Tag tag={tag} />
        </div>
        <div className="t2 medium">
          <div>
            <span className="uppercase">{title[0]}</span>
            <span>
              <Markdown>{title.slice(1)}</Markdown>
            </span>
          </div>
        </div>
        {/* only show first line of description unless plan item is evvy product recommendation */}
        <p className="my-6">
          <Markdown>
            {isEvvyProduct ? description.replaceAll("|", " ") : firstLine}
          </Markdown>
        </p>
        {/* if recommendation type is evvy product recommendation, append CTA. Otherwise, append modal link */}
        {isEvvyProduct ? (
          <div>
            <div className="mb-6">
              <ResearchItem
                status={researchRating?.title}
                eventArgs={Object.assign({}, analyticsEventArgs, {
                  researchStatus: researchRating?.title,
                })}
              />
            </div>
            <div className="text-evvy-pine">
              <LinkedText
                borderColorClass="border-evvy-pine"
                noTopMargin
                overrideTextClass="uppercase leading-5 text-[12px] semibold tracking-wider"
                noIcon
                isLink={!userIsMember}
                href={userIsMember ? null : productUrl}
                onClick={analyticsClickHandler({
                  eventName: eventNames.CLICKED_RETEST,
                  eventArgs: { isMember: userIsMember, ...analyticsEventArgs },
                  clickHandler: handleClick,
                })}
              >
                Order your next evvy test →
              </LinkedText>
            </div>
          </div>
        ) : (
          <div>
            <div className="mb-6">
              <ResearchItem
                status={researchRating?.title}
                eventArgs={Object.assign({}, analyticsEventArgs, {
                  researchStatus: researchRating?.title,
                })}
              />
            </div>
            <div className="text-evvy-pine">
              <LinkedText
                borderColorClass="border-evvy-pine"
                noTopMargin
                onClick={analyticsClickHandler({
                  eventName: eventNames.CLICKED_LEARN_MORE,
                  eventArgs: analyticsEventArgs,
                  clickHandler: () => {
                    // set active step for modal
                    dispatch(setPlanModalStep(number));
                    // then render modal
                    openModalWith({
                      type: MTYPES.RECOMMENDATION,
                      data: {
                        microbialActions: allData,
                        steps: totalSteps,
                        analyticsEventArgs,
                      },
                      handleClose: () => dispatch(setPlanModalStep(null)),
                    });
                  },
                })}
              >
                {link}
              </LinkedText>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanCard;
