import {
  diagnosesCodes,
  dischargeCodes,
  smellCodes,
  treatmentCodes,
  symptomCodes,
} from "../../utils/copy";

const blurLevels = {
  HIGH: 30,
  HIGH_MOBILE: 20,
  MED: 8,
  MED_MOBILE: 3,
  LOW: 0,
};

const screens = [
  {
    isMoving: true,
    groupExplosionBasis: 0,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.HIGH,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.2,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.MED,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.2,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.MED,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.25,
    nodeExplosionBasis: 0.15,
    blurProp: blurLevels.LOW,
  },
  {
    isMoving: true,
    groupExplosionBasis: 0,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.HIGH,
  },
];

// different settings for viz when on mobile and very small
const screens_mobile = [
  {
    isMoving: true,
    groupExplosionBasis: 0,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.HIGH,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.25,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.MED_MOBILE,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.25,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.MED_MOBILE,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.35,
    nodeExplosionBasis: 0.3,
    blurProp: blurLevels.LOW,
  },
  {
    isMoving: true,
    groupExplosionBasis: 0,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.HIGH,
  },
];

const healthContextDictionary = {
  discharge: {
    intro: "your discharge was",
    answers: dischargeCodes,
  },
  smell: {
    // deprecated
    intro: "your vaginal smell was",
    answers: smellCodes,
  },
  treatments: {
    intro: "within 60 days of this test, you had tried",
    answers: treatmentCodes,
  },
  symptoms: {
    intro: "your symptoms included",
    answers: symptomCodes,
  },
  diagnoses_SI: {
    // deprecated and replaced with diagnoses_30
    intro: "within 60 days of this test, you were diagnosed with",
    answers: diagnosesCodes,
  },
  diagnoses_30: {
    intro: "within 30 days of this test, you were diagnosed with",
    answers: diagnosesCodes,
  },
  diagnoses_TH: {
    intro: "you suspected a case of",
    answers: diagnosesCodes,
  },
};

const defaultNote =
  "Above you'll find a detailed breakdown of all the microbes present and what they mean. It's important to note that this is not a diagnosis; this test is only a wellness screen and these microbes are associative based on scientific research.|We've put together a customized vaginal health plan for you with a step by step guide to promote a healthy, protective microbiome. Make sure to click through to \"view your plan\" below!|Thank you,|Team Evvy";

const MENU_ITEMS = [
  {
    number: 1,
    label: "At-a-glance",
    href: "ataglance",
  },
  {
    number: 2,
    label: "Your Microbiome Type",
    href: "microbiometype",
  },
  {
    number: 3,
    label: "Detailed Results",
    href: "detailedresults",
  },
  {
    number: 4,
    label: "Trends & Insights",
    href: "trends",
  },
  {
    number: 5,
    label: "Next Steps",
    href: "nextsteps",
  },
];

const PAGE_SECTION_TITLES = {
  BACTERIA_COMPOSITION: "Bacteria Composition",
  PLAN: "Plan",
  CST: "CST",
  MY_PLAN_STICKY_FOOTER: "My Plan Sticky Footer",
};

const CST_COPY = {
  TITLE: "Your Vaginal Microbiome Type",
  SUBTITLE: `While every vaginal microbiome is unique, current research shows that most vaginal microbiomes fall into one of five categories, known as "Community State Types” (CSTs).`,
  LINK: "learn more about CST's",
  LINK_ADDRESS: "https://www.evvy.com/blog/community-state-types",
  CARD_TITLE: "Your community state type",
  CARD_EXPAND: "Learn More",
  CARD_COLLAPSE: "Show Less",
};

const BACTERIA_COPY = {
  TITLE: "Your Detailed Results",
  SUBTITLE: "Select a tag to view your microbes related to:",
  CARD_EXPAND: "View all microbes",
  CARD_COLLAPSE: "Hide",
  EMPTY_A:
    "Your test indicates that you don’t have a significant amount of microbes related to this condition.",
  EMPTY_A_NO_MICROBIES:
    "Your test indicates that you don’t have any microbes related to this condition.",
  EMPTY_B:
    "If you feel like you might have this condition or are experiencing symptoms, reach out to your doctor.",
  EMPTY_A_GOOD_HEALTH:
    "Your test indicates that you currently don't have many microbes that are associated with good vaginal health.",
  EMPTY_B_GOOD_HEALTH:
    "You may want to focus on increasing the levels of these protective microbes (and your Evvy plan can show you how!)",
};

const DISCLAIMER_COPY = {
  WELLNESS:
    "* As a reminder, the Evvy Vaginal Health test does not detect STIs. It is not intended to replace your regular medical checkups or your Pap smear.",
  EXPANDED_LDT:
    "* As a reminder, the Evvy Vaginal Health test is not intended to replace your regular checkups or Pap smear. To get more information about our testing methodology or to understand what's included in the results, please review the methods page in the PDF download above.",
  STANDARD_LDT:
    "* As a reminder, the Evvy Vaginal Health test does not detect STIs, and is not intended to replace your regular checkups or Pap smear. To get more information about our testing methodology or to understand what's included in the results, please review the methods page in the PDF download above.",
};

const STI_RESULTS_COPY = {
  TITLE: "STI Results",
};

const GOOD_HEALTH_NAME = "Good Health";

const NEXT_COPY = {
  TITLE: "What’s next?",
  SUBTITLE:
    "We’ve created a personalized plan for you based on your unique microbiome, click below to access!",
  GOAL: "your microbiome goal",
  LINK: "View Your Plan",
  SCROLL_TEXT: "Want to keep learning about your microbiome?",
};

const WHATS_UP_COPY = {
  TITLE: "Now you know what's up down there —<br>see what's next:",
  SUBTITLE: "Microbiome Goal",
  LINK: "View Your Plan",
};

const TRENDS_COPY = {
  TITLE: "Trends & Insights",
  SUBTITLE:
    "Your microbiome changes in response to behaviors, hormones, treatments, and more. See below to understand how your microbome changed between tests.",
  SUBTITLE_ONE_TEST:
    "Your microbiome changes in response to behaviors, hormones, treatments, and more. Take another test to understand how your microbiome changes over time.",
};

const RESULTS_COPY = {
  BACTERIA_TYPE: {
    protective: "This type is predominantly",
    variable: "This type is predominantly",
    disruptive: "This type is predominantly",
    unknown: "N/A",
  },
  TYPE_PERCENTAGE: "of Evvy users are also categorized as",
};

const modalTypes = {
  HEALTH_ASSOCIATIONS: "_health_associations",
  ASSOCIATIONS_DISCLAIMER: "_associations_disclaimer",
};

const SECTION_TYPES = {
  circlePack: "circlePack",
  donut: "donut",
};

const smallTypeSvgConfig = {
  width: 54,
  height: 54,
  margin: 0,
  innerRadius: 15,
  outerRadius: 27,
};

const largeTypeSvgConfig = {
  width: 70,
  height: 70,
  margin: 0,
  innerRadius: 24,
  outerRadius: 35,
};

const SYMPTOM_SCORES = ["none", "mild", "moderate", "severe"];
const SYMPTOM_COLORS = ["#C89CD9", "#C89CD9A8", "#C89CD954", "white"];

const REVERSE_COLORS = ["#C89CD9", "#C89CD9A8", "#C89CD954", "white"].reverse();

const CATEGORY_KEYS = {
  protective: "GO",
  disruptive: "BA",
  neutral: "NE",
};

const DATA_TYPES = {
  DEFAULT: "default",
  TEST: "test",
  TYPE: "type",
};

const TREND_DIRECTION = {
  NEUTRAL: "neutral",
  UP: "up",
  DOWN: "down",
};

const TYPES_DICTIONARY = {
  "I-A": "Type 1-A",
  "I-B": "Type 1-B",
  II: "Type 2",
  "III-A": "Type 3-A",
  "III-B": "Type 3-B",
  "IV-A": "Type 4-A",
  "IV-B": "Type 4-B",
  "IV-C": "Type 4-C",
  "IV-C0": "Type 4-C0",
  "IV-C1": "Type 4-C1",
  "IV-C2": "Type 4-C2",
  "IV-C3": "Type 4-C3",
  "IV-C4": "Type 4-C4",
  V: "Type 5",
};

const HEALTH_ASSOCIATIONS_COPY = {
  DISCLAIMER:
    "*An association is not a diagnosis. Always talk to your healthcare provider to understand how these associations may apply to you.*",
  ASSOCIATION_DESCRIPTION:
    "Evvy’s CST associations can help you understand if there are certain health conditions that published research has found to be correlated with your vaginal microbiome type — enabling you to be proactive in your health.",
  CONDITION_DISCLAIMER:
    "No — an association is not a diagnosis! Many people have a microbiome associated with a condition and they do not develop that condition (and vice versa!). It is important to note that being in a CST with certain associated conditions does NOT mean you will certainly develop or experience those associated conditions.",
  WHAT_CONDITION:
    "If my CST is associated with a specific condition, does that mean I have the condition?",
  WHAT_NEXT: "What can I do next?",
  WHAT_ASSOCIATION: "What is a CST association?",
  NEXT_DESCRIPTION:
    "For many conditions, the vaginal microbiome is just one piece of the puzzle, and it’s important to remember that your lifestyle choices, genetics, and other factors can influence your likelihood of developing any condition. Always talk to your healthcare provider to better understand how these associations may apply to you.|The good news is that your vaginal microbiome is a modifiable part of the puzzle, and it can shift with your behaviors and treatments. We’re here to help you ensure that your vaginal microbiome is working in your favor!|*We’re also here support you — reach out anytime at support@evvy.com*",
};

const LOAD_DISPLAY_MAP = {
  L: "Low",
  M: "Medium",
  H: "High",
};

const DEFAULT_COMPARE_TABLE_DATA = {
  default: true,
  bacteria_valences: {
    BA: { bacteria: [], percent: 0 },
    GO: { bacteria: [], percent: 0 },
    NE: { bacteria: [], percent: 0 },
    UN: { bacteria: [], percent: 0 },
  },
  common_community_type: "",
  dominant_bacteria_name: "",
  health_history: {
    basics_last_period_date: "",
    diagnoses: [""],
    treatments: [""],
    symptoms_vaginal_discharge: [""],
    symptoms_vaginal_smell: [""],
  },
};

export {
  blurLevels,
  screens,
  healthContextDictionary,
  defaultNote,
  screens_mobile,
  MENU_ITEMS,
  CST_COPY,
  BACTERIA_COPY,
  DISCLAIMER_COPY,
  NEXT_COPY,
  modalTypes,
  smallTypeSvgConfig,
  largeTypeSvgConfig,
  SYMPTOM_SCORES,
  SYMPTOM_COLORS,
  REVERSE_COLORS,
  RESULTS_COPY,
  SECTION_TYPES,
  CATEGORY_KEYS,
  WHATS_UP_COPY,
  TRENDS_COPY,
  DATA_TYPES,
  TYPES_DICTIONARY,
  HEALTH_ASSOCIATIONS_COPY,
  TREND_DIRECTION,
  DEFAULT_COMPARE_TABLE_DATA,
  PAGE_SECTION_TITLES,
  GOOD_HEALTH_NAME,
  LOAD_DISPLAY_MAP,
  STI_RESULTS_COPY,
};
