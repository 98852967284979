import { daysElapsed } from "./datetime";

const diagnosesCodes = {
  diagnoses_bv: "bacterial vaginosis",
  diagnoses_yeast_infection: "yeast infection",
  diagnoses_uti: "urinary tract infection",
  diagnoses_av: "aerobic vaginitis",
  diagnoses_ureaplasma: "ureaplasma",
  diagnoses_mycoplasma: "mycoplasma",
  diagnoses_vaginal_lactobacillosis: "vaginal lactobacillosis",
  diagnoses_cytolytic_vaginosis: "cytolytic vaginosis",
  diagnoses_trichomoniasis: "trichomoniasis",
  diagnoses_genital_herpes: "genital herpes",
  diagnoses_chlamydia: "chlamydia",
  diagnoses_hiv: "HIV",
  diagnoses_hpv: "HPV",
  diagnoses_gonorrhea: "gonorrhea",
  default: "nothing",
};

const dischargeCodes = {
  NO: "normal",
  EX: "excessive",
  WH: "white and thick",
  FR: "frothy",
  WA: "watery",
  DR: "dry",
  WE: "wet/slippery (like raw egg whites)",
  CH: "chunky/cottage cheese-like",
  ST: "sticky",
  TH: "thin/milky",
  GN: "green",
  YE: "yellow",
  GY: "gray",
  BN: "brown",
  CL: "clear/colorless",
  WI: "white",
  RB: "red/brown",
  PK: "pink",
  CR: "cream or off-white",
  OT: "other",
  default: "",
};

const smellCodes = {
  NO: "nothing unusual",
  CL: "bleach/ammonia",
  YE: "yeasty",
  FI: "fishy",
  SO: "sour",
  VI: "vinegar-like",
  RO: "rotten",
  SW: "sweet",
  OT: "other",
  ON: "onion or BO-like",
  MU: "musty",
  ME: "metallic",
  default: "",
};

const treatmentCodes = {
  NO: "nothing",
  AB: "antibiotics",
  AF: "antifungals",
  PR: "probiotics",
  HR: "hormone replacement therapy",
  BA: "boric acid suppositories",
  FH: "feminine hygiene products",
  default: "no products or treatments",
};

const symptomCodes = {
  symptoms_additional_vagina_pain: "vaginal pain",
  symptoms_additional_vulva_redness: "vulva redness",
  symptoms_additional_vulva_pain: "vulva pain",
  symptoms_additional_vaginal_swelling: "vaginal swelling",
  symptoms_additional_external_itchiness: "external itchiness",
  symptoms_additional_internal_itchiness: "internal itchiness",
  symptoms_additional_dryness: "dryness",
  symptoms_additional_burning_sensation: "burning sensation",
  symptoms_additional_pain_with_sex: "pain with sex",
  symptoms_additional_pain_while_peeing: "pain while peeing",
  symptoms_additional_excessive_discharge: "excessive discharge",
  symptoms_additional_odorous_discharge: "odorous discharge",
  default: "no additional symptoms",
};

const lastPeriod = {
  intro: "your last period started",
  outro: "days before this test",
  outroSingleDay: "day before this test",
  default: "You told us you were not getting a period when you took this test",
};

const getPeriodContext = (periodDate, sampleDate) => ({
  text: lastPeriod.intro,
  coloredText: periodDate && sampleDate && daysElapsed(periodDate, sampleDate),
  outro:
    daysElapsed(periodDate, sampleDate) === 1
      ? lastPeriod.outroSingleDay
      : lastPeriod.outro,
});

export {
  diagnosesCodes,
  dischargeCodes,
  smellCodes,
  treatmentCodes,
  symptomCodes,
  lastPeriod,
  getPeriodContext,
};
