import { Link } from "react-router-dom";

const stats = [
  {
    percentage: "96%",
    description: "of participants felt their treatment was effective.",
  },
  {
    percentage: "83%",
    description:
      "of participants moved from dysbiosis to a protective microbiome.",
  },
  {
    percentage: "100%",
    description:
      "of participants felt like they had the right care and resources.",
  },
];

const EvvyStudyStats = () => (
  <>
    <div className="my-14 sm:my-20 max-w-6xl mx-3 sm:mx-auto">
      <div className="uppercase text-center text-sm font-semibold mb-2">
        Integrative, Supportive, and Effective
      </div>
      <div className="text-center text-2xl md:max-w-xl md:mx-auto mb-14 hidden md:block">
        The Complete Treatment Program was designed based on our{" "}
        <Link
          to="https://evvy-public-content.s3.us-east-2.amazonaws.com/documents/Evvy_Innovative_Vaginal_Care_Platform_White_Paper.pdf"
          className="!text-2xl !underline"
          rel="noopener noreferrer"
          target="_blank"
        >
          IRB-approved study
        </Link>
        .
      </div>
      <div className="text-center text-xl mx-3 md:max-w-xl md:mx-auto mb-14 block md:hidden">
        Results from our{" "}
        <Link
          to="https://evvy-public-content.s3.us-east-2.amazonaws.com/documents/Evvy_Innovative_Vaginal_Care_Platform_White_Paper.pdf"
          className="!text-xl !underline"
          rel="noopener noreferrer"
          target="_blank"
        >
          IRB-approved study
        </Link>{" "}
        proved the efficacy of our Complete Treatment Program.
      </div>
      <div className="flex flex-col mx-3 md:flex-row md:flex-wrap md:justify-between items-center">
        {stats.map((stat, index) => (
          <>
            <div className="flex flex-row items-center w-full md:max-w-[300px] justify-between md:justify-normal">
              <h2 className="bg-highlighter-yellow text-[40px] mb-3 md:mb-0 md:mr-5 !leading-none !my-0">
                {stat.percentage}
              </h2>
              <div className="leading-normal max-w-[60%] xs:max-w-[70%]">
                {stat.description}
              </div>
            </div>
            {index < stats.length - 1 && (
              <>
                <div className="w-full border-b border-dotted border-evvy-black opacity-20 my-8 md:hidden" />
                <div className="h-12 border-l border-dotted border-evvy-black opacity-20 mx-5 hidden md:block" />
              </>
            )}
          </>
        ))}
      </div>
    </div>
  </>
);

export default EvvyStudyStats;
