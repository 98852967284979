/**
 * A styled list of citations to use for "Our Sources"
 * @param {[]} citations is an array of source citations
 * @param {{}} eventArgs properties object to attach to the analytics event triggered on click
 * @returns jsx of a citations list
 */
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";

import React from "react";

const Citations = ({ citations, eventArgs = {} }) => {
  return (
    <div>
      <div className="t3 text-evvy-pine">Our Sources</div>
      <div className="my-4">
        <ol className="mx-0 caption">
          {citations.map(({ title, url }, i) => (
            <li key={`learn-citation-${i}`}>
              {i + 1}.{" "}
              <a
                className="underline"
                href={url}
                onClick={analyticsClickHandler({
                  eventName: eventNames.CLICKED_SOURCE,
                  eventArgs: Object.assign({ citationTitle: title }, eventArgs),
                })}
                target="_blank"
              >
                {title}
              </a>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default Citations;
