import { Consult, Prescription } from "../../types/care";
import React from "react";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";
import classNames from "classnames";

type PrescriptionLearnMoreButtonProps = {
  prescriptionSlug: Prescription["slug"];
  openModal: () => void;
  location: "treatment_plan" | "refills_cart";
  consult?: Consult;
  className?: string;
};

const PrescriptionLearnMoreButton: React.FC<
  PrescriptionLearnMoreButtonProps
> = ({ prescriptionSlug, openModal, location, consult, className }) => {
  const handleClick = () => {
    openModal();
    analyticsClickHandler({
      eventName: eventNames.TREATMENT_EXPAND_PRESCRIPTION,
      eventArgs: {
        consultId: consult?.uid,
        testHash: consult?.test_hash,
        prescriptionSlug,
        location,
      },
    });
  };
  return (
    <span
      onClick={handleClick}
      className={classNames(
        "text-black linkedText t4 border-b border-black cursor-pointer",
        className
      )}
    >
      Instructions +
    </span>
  );
};

export default PrescriptionLearnMoreButton;
