import { useContext } from "react";
import { ConsultsContext } from "../contexts/consultsContext";

export const useCompletedConsults = () => {
  const { consults } = useContext(ConsultsContext);

  const completedConsults = consults?.filter(
    (consult) => consult.status === "CP"
  );

  return completedConsults;
};
