import React, { SyntheticEvent, useState } from "react";
import { careService } from "../../../services/care";
import { Period } from "../../../types/care";
import {
  sendDeletePeriod,
  sendSavePeriod,
} from "../../../utils/analytics/customEventTracking";
import Markdown from "markdown-to-jsx";

const PERIOD_MODAL_TITLE = "Input the dates of your period";
const PERIOD_MODAL_DESCRIPTION_MD =
  "We use this information to recalculate your treatment calendar. *Reminder if you’ve already downloaded your calendar, you may need to re-download the new version. To edit an existing period, click the period icon in your calendar.*";
const PERIOD_START_DATE_LABEL = "Period start date:";
const PERIOD_LENGTH_LABEL = "Period length:";
const EDIT_PERIOD_MODAL_TITLE = "Edit the dates of your period";

export const EditPeriodModal: React.FC<{
  handleClose: () => void;
  data: {
    periodId: string;
    consultUid: string;
    refetch: () => void;
    periods: Period[];
  };
}> = ({ handleClose, data }) => {
  const { consultUid, refetch, periodId, periods } = data;
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  // may be undefined
  const currentPeriod = periods.filter((period) => period.id === periodId)[0];

  async function editPeriod(e: SyntheticEvent) {
    e.preventDefault(); // to not post to the current url
    setSaving(true);
    const targetElement = e.target as typeof e.target & {
      start: { value: string };
      days: { value: number };
    };
    const start = targetElement.start.value;
    const days = targetElement.days.value;

    if (!start || !days) {
      setError("Please enter a start date and period length");
      return;
    }
    try {
      let response;
      // if period already exists, then we are updating existing
      if (periodId) {
        response = await careService.updatePeriod({
          id: periodId,
          startDate: start,
          days,
        });
      } else {
        // otherwise create new!
        response = await careService.createPeriod({
          consultUid,
          startDate: start,
          days,
        });
      }
      refetch();
      handleClose();
      sendSavePeriod({
        consultId: consultUid,
        periodId: periodId || response?.data?.id,
        startDate: start,
        days,
        type: periodId ? "update" : "create",
      });
    } catch (err) {
      console.error(err);
      const error = err as { response: { data: string } };
      setError(error?.response?.data);
    } finally {
      setSaving(false);
    }
  }

  async function deletePeriod() {
    setSaving(true);
    try {
      await careService.deletePeriod(periodId);
      refetch();
      handleClose();
      sendDeletePeriod({
        consultId: consultUid,
        periodId: periodId,
        startDate: currentPeriod?.start_date,
        days: currentPeriod?.days,
      });
    } catch (err) {
      console.error(err);
      const error = err as { response: { data: string } };
      setError(error?.response?.data);
    } finally {
      setSaving(false);
    }
  }

  return (
    <>
      {/* custom style just applying to this component mobile ios safari to force the date input text to align left */}
      <style>
        {`input::-webkit-date-and-time-value { text-align: left; }`}
      </style>
      <div
        className={`w-full flex flex-col pt-7 pb-20 sm:pb-7 px-8 bg-evvy-white`}
      >
        {/* title */}
        <h3 className="text-2xl text-center">
          {periodId ? EDIT_PERIOD_MODAL_TITLE : PERIOD_MODAL_TITLE}
        </h3>
        {/* description */}
        <p className="text-center">
          <Markdown>{PERIOD_MODAL_DESCRIPTION_MD}</Markdown>
        </p>
        <form action="#" onSubmit={editPeriod}>
          <div className="b1 medium mb-1">{PERIOD_START_DATE_LABEL}</div>
          <input
            id="start"
            name="start"
            type="date"
            autoComplete="off"
            required
            defaultValue={currentPeriod?.start_date}
            className="mb-4 text-left text-[16px] appearance-none block w-full p-3 border rounded-md border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
          />
          <div className="b1 medium mb-1">{PERIOD_LENGTH_LABEL}</div>
          <div className="relative">
            <input
              id="days"
              name="days"
              type="number"
              min={1}
              defaultValue={currentPeriod?.days || 5}
              autoComplete="off"
              required
              className="mb-4 text-[16px] appearance-none block w-full p-3 border rounded-md border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
            />
            <div className="absolute inset-y-0 left-5 flex items-center px-3 pointer-events-none b1">
              days
            </div>
          </div>
          {error && (
            <div className="text-red-500 text-center py-4">{error}</div>
          )}
          <button
            className="bg-evvy-blue py-4 px-8 w-full rounded-sm font-semibold tracking-wider focus:outline-none"
            type="submit"
            disabled={saving}
          >
            {saving ? "Saving..." : "Save"}
          </button>
        </form>
        {periodId && (
          <button
            className="-mt-2 border border-black py-4 px-8 w-full rounded-sm font-semibold tracking-wider focus:outline-none"
            disabled={saving}
            onClick={deletePeriod}
          >
            {saving ? "Deleting..." : "Delete"}
          </button>
        )}
      </div>
    </>
  );
};
