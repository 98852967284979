import { FC, useEffect, useState } from "react";
import { ConsultIntake, PharmacyResultType } from "../../../../types/care";
import { Formik, Field, Form } from "formik";
import BlueRectangularButton from "../../../../components/common/blueRectangularButton";
import { getError } from "./utils";
import { careService } from "../../../../services/care";
import { Helmet } from "react-helmet";

type PharmacyProps = {
  consultIntake: ConsultIntake;
  submitPage: (data: any, onError: (error: any) => void) => void;
  loading: boolean;
};

export const Pharmacy: FC<PharmacyProps> = ({ submitPage, consultIntake }) => {
  const [inputValue, setInputValue] = useState<PharmacyInput>({
    postalCode: "",
    pharmacyName: "",
  });

  const [debouncedValue, setDebouncedValue] = useState<PharmacyInput>({
    postalCode: "",
    pharmacyName: "",
  });

  const [selectedPharmacy, setSelectedPharmacy] = useState<
    PharmacyResultType | undefined
  >(consultIntake.patient_selected_pharmacy);

  const [pharmacies, setPharmacies] = useState<PharmacyResultType[]>(
    selectedPharmacy ? [selectedPharmacy] : []
  );

  const [error, setError] = useState<string>("");
  const [apiErrors, setApiErrors] = useState({});

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isInputValueEmpty(inputValue)) setLoading(true);

    setSelectedPharmacy(undefined);

    // Debounce function to delay API calls by 300ms
    const debounce = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 300);

    // Clear the debounce timeout if the component unmounts or inputValue changes
    return () => clearTimeout(debounce);
  }, [inputValue]);

  useEffect(() => {
    // Make an API call when debouncedValue changes

    const searchPharmacies = async () => {
      try {
        const result = await careService.asyncSearchPharmacies(
          debouncedValue.postalCode,
          debouncedValue.pharmacyName
        );

        const dedupedPharmacies = result.data.reduce(
          (acc: PharmacyResultType[], pharmacy: PharmacyResultType) => {
            const duplicate = acc.find(
              (pharmacyResult) =>
                pharmacyResult.name === pharmacy.name &&
                pharmacyResult.address.line.join(",") ===
                  pharmacy.address.line.join(",")
            );
            if (!duplicate) {
              acc.push(pharmacy);
            }
            return acc;
          },
          []
        );
        setPharmacies(dedupedPharmacies);
      } catch (e) {
        setApiErrors(e as any);
      } finally {
        setLoading(false);
      }
    };

    if (debouncedValue.postalCode || debouncedValue.pharmacyName) {
      searchPharmacies();
    }
  }, [debouncedValue]);

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Helmet>
        <title>Care | Consult | STI Intake | Pharmacy</title>
      </Helmet>
      <div className="max-w-2xl px-4">
        <h3 className="text-center">Pharmacy Information</h3>
        {error ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
            {error}
          </div>
        ) : (
          ""
        )}
        <Formik
          initialValues={{
            pharmacyName: consultIntake.patient_selected_pharmacy?.name || "",
            postalCode:
              consultIntake.patient_selected_pharmacy?.address?.postalCode ||
              "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={() => {
            submitPage(
              {
                patient_selected_pharmacy: selectedPharmacy,
              },
              (error) => {
                setError(error);
              }
            );
          }}
        >
          {({ errors, handleChange, handleSubmit, setFieldValue }) => (
            <Form>
              <div className="bg-evvy-white p-5 sm:p-8 mb-3 rounded-lg flex flex-col gap-4">
                <p className="font-medium text-xl">
                  Search for the pharmacy where you'd like to pick up your
                  prescription.
                </p>
                <div
                  className={`rounded-md ${
                    getError("pharmacyName", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("pharmacyName", errors, apiErrors)}
                  data-for="pharmacyName"
                >
                  <p className="t4 mb-2">Pharmacy Name</p>
                  <Field
                    name="pharmacyName"
                    as="input"
                    className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                    placeholder="Your Answer"
                    autoComplete="off"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setInputValue({
                        ...inputValue,
                        pharmacyName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div
                  className={`w-full border rounded-md ${
                    getError("postalCode", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("postalCode", errors, apiErrors)}
                  data-for="postalCode"
                >
                  <p className="t4 mb-2">Zip Code</p>
                  <Field
                    name="postalCode"
                    as="input"
                    className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                    placeholder="Your Answer"
                    autoComplete="off"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setInputValue({
                        ...inputValue,
                        postalCode: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex-1 bg-evvy-white mb-3 rounded-lg p-5 sm:p-8">
                <h4 className="t1 ">
                  Select the pharmacy you'd like us to send your prescription(s)
                  to. If you cannot find your pharmacy in the list below, please
                  contact{" "}
                  <a href="mailto:support@evvy.com" className="!underline">
                    support@evvy.com
                  </a>
                  .
                </h4>
                <div className="mt-2 flex flex-col gap-4 max-h-96 overflow-auto ">
                  {loading
                    ? "Loading..."
                    : pharmacies.length > 0
                    ? pharmacies.map((pharmacy) => (
                        <PharmacyResult
                          key={pharmacy.identifier[0].value}
                          pharmacy={pharmacy}
                          onSelectPharmacy={(pharmacy: PharmacyResultType) => {
                            setSelectedPharmacy(pharmacy);
                            setFieldValue("pharmacyName", pharmacy.name);
                            setFieldValue(
                              "postalCode",
                              pharmacy.address.postalCode
                            );
                          }}
                          isSelected={selectedPharmacy?.name === pharmacy.name}
                        />
                      ))
                    : "No results found"}
                </div>
              </div>
              <div className="flex mt-6">
                <BlueRectangularButton
                  text="Continue"
                  paddingXClass="sm:px-32"
                  disabled={loading || !selectedPharmacy}
                  onClick={handleSubmit}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

type PharmacyInput = {
  postalCode: string;
  pharmacyName: string;
};

const PharmacyResult = ({
  pharmacy,
  onSelectPharmacy,
  isSelected,
}: {
  pharmacy: PharmacyResultType;
  onSelectPharmacy: (pharmacy: PharmacyResultType) => void;
  isSelected?: boolean;
}) => {
  const phone = pharmacy.telecom[0].value;
  const address = pharmacy.address;
  return (
    <div
      className={`flex flex-col justify-between bg bg-evvy-cream p-4 rounded-md cursor-pointer hover:bg-evvy-dark-cream ${
        isSelected ? "border-2 border-evvy-blue" : ""
      }`}
      onClick={() => {
        onSelectPharmacy(pharmacy);
      }}
    >
      <div className="flex-1">
        <h5 className="t2 m-0">{pharmacy.name}</h5>
        {address && <p className="m-0">{formatAddress(pharmacy.address)}</p>}
      </div>
      {phone && (
        <p className="mt-2 mb-0">{formatPhone(pharmacy.telecom[0].value)}</p>
      )}
    </div>
  );
};

const formatAddress = (address: {
  line: string[];
  city: string;
  state: string;
  postalCode: string;
  country: string;
}) => {
  return `${address.line.join(" ")}, ${address.city}, ${address.state} ${
    address.postalCode
  }, ${address.country}`;
};

const formatPhone = (phone: string) => {
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
};

const isInputValueEmpty = (inputValue: PharmacyInput) => {
  return !inputValue.postalCode && !inputValue.pharmacyName;
};
