import { useState, useEffect, useCallback } from "react";
import { careService } from "../../services/care";
import { Consult, TreatmentPlan } from "../../types/care";

const useTreatmentPlan = (consultId?: Consult["uid"]) => {
  const [data, setData] = useState<TreatmentPlan>();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  const fetchTreatmentPlan = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await careService.fetchTreatmentPlan(consultId);
      setData(response.data);
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [consultId]);

  useEffect(() => {
    if (consultId) fetchTreatmentPlan();
  }, [consultId, fetchTreatmentPlan, refetch]);

  const refetchTreatmentPlan = () => {
    setRefetch((prev) => !prev);
  };

  return {
    treatmentPlan: data,
    error,
    isLoading,
    refetchTreatmentPlan,
  };
};

export default useTreatmentPlan;
