import React from "react";

type DOBProps = {
  firstName: string;
  lastName: string;
  birthday: string;
};

export const ConfirmNameDOB = ({ firstName, lastName, birthday }: DOBProps) => {
  return (
    <div className="bg-evvy-white rounded-lg p-5 sm:p-10">
      <div className="b1 medium">3. Confirm your information</div>
      <div className="py-4">
        Make sure this information matches what is shown on your ID.
      </div>
      <div className="space-y-6">
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="first_name" className="uppercase font-medium">
              First Name
            </label>
            <div className="mt-1">
              <input
                id="first_name"
                name="first_name"
                type="text"
                autoFocus
                autoComplete="given-name"
                required
                style={{ fontSize: "16px" }}
                defaultValue={firstName}
                className="appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="last_name" className="uppercase font-medium">
              Last Name
            </label>
            <div className="mt-1">
              <input
                id="last_name"
                name="last_name"
                type="text"
                autoComplete="family-name"
                required
                style={{ fontSize: "16px" }}
                defaultValue={lastName}
                className="appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
              />
            </div>
          </div>
        </div>

        <div>
          <label htmlFor="birthday" className="uppercase font-medium">
            Birthday (mm/dd/yyyy)
          </label>
          <div className="mt-1">
            <input
              id="birthday"
              name="birthday"
              type="date"
              autoComplete="bday"
              required
              style={{ fontSize: "16px" }}
              defaultValue={birthday}
              className="appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
