import React, { useRef, useState } from "react";

import TestCard from "./testCard";
import LinkedText from "../common/linkedText";
import { getIsMobile } from "../../utils/general";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";
const MOBILE_PER_PAGE = 1;
const DESKTOP_PER_PAGE = 3;

const TestCards = ({ tests, openActivateModal }) => {
  const isMobile = getIsMobile();

  const perPage = isMobile ? MOBILE_PER_PAGE : DESKTOP_PER_PAGE;
  const numPages =
    parseInt(tests.length / perPage) + (tests.length % perPage ? 1 : 0);
  const [currentPage, setCurrentPage] = useState(1);
  const testsListRef = useRef();

  // get tests to show based on page
  const testsToShow = tests.slice(
    (currentPage - 1) * perPage,
    currentPage * perPage
  );

  return (
    <div className="bg-white rounded-2xl">
      <div className="flex justify-between items-center p-4 sm:p-8 border-b">
        <h3 className="m-0 text-[36px]">Your Tests</h3>
        {openActivateModal ? ( // openActivateModal isn't passed in for old version of test home page
          <div>
            <LinkedText
              onClick={analyticsClickHandler({
                eventName: eventNames.CLICKED_ACTIVATE,
                eventArgs: { testOrder: tests.length },
                clickHandler: () => openActivateModal(),
              })}
              noTopMargin
            >
              Activate
            </LinkedText>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        id="tests-list"
        className="divide-y-8 divide-evvy-cream"
        ref={testsListRef}
      >
        {testsToShow.map((test, i) => (
          <TestCard key={test.hash} test={test} testIndex={i} />
        ))}
      </div>
      {numPages > 1 ? (
        <div className="p-4 sm:p-8 border-t flex items-center">
          <div
            className="ml-auto border-2 rounded-full p-2 cursor-pointer"
            disabled={currentPage <= 1}
            onClick={() => {
              setCurrentPage(currentPage - 1);
              if (!isMobile) {
                testsListRef.current.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </div>
          <div className="uppercase semibold mx-4 tracking-wider">
            Page {currentPage} of {numPages}
          </div>
          <div
            className="mr-auto border-2 rounded-full p-2 cursor-pointer"
            disabled={currentPage >= numPages}
            onClick={() => {
              setCurrentPage(currentPage + 1);
              if (!isMobile) {
                testsListRef.current.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TestCards;
