import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import { indexCheckboxStyles } from "../../../../utils/colors";
import { uncheckNoneChangeHandler } from "../../../../utils/answerUtils";
import { getQuestionTitle } from "../../../../utils/questions";
import { intakeDisclaimerCopy } from "../../../../components/care/constants";
import BlueRectangularButton from "../../../../components/common/blueRectangularButton";
import { ConsultIntake } from "../../../../types/care";
import { sendConsultIntakeViewSection } from "../../../../utils/analytics/customEventTracking";
import { getError } from "./utils";

const GeneralQuestions = ({
  consultIntake,
  submitPage,
  loading,
}: {
  consultIntake: ConsultIntake;
  submitPage: (data: any, onError: (error: any) => void) => void;
  loading: boolean;
}) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({});

  // for tooltips
  const stiAllergiesQuestion = useRef<HTMLDivElement>(null);
  const stiAllergiesOtherQuestion = useRef<HTMLDivElement>(null);
  const anythingElseQuestion = useRef<HTMLDivElement>(null);

  const allQuestions = {
    sti_intake_allergies: stiAllergiesQuestion,
    sti_intake_allergies_other: stiAllergiesOtherQuestion,
    anything_else: anythingElseQuestion,
  };

  const consult = consultIntake.consult;

  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consult.uid,
      testHash: consult?.test_hash,
      section: "General",
      consultType: "sti",
    });
  }, [consult.test_hash, consult.uid]);

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Helmet>
        <title>
          Care | Consult | STI Intake | Treatment | General Treatment Questions
        </title>
      </Helmet>
      <div className="max-w-2xl px-4">
        <h3 className="text-center">Treatment Questions</h3>
        {error ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
            {error}
          </div>
        ) : (
          ""
        )}
        <Formik
          initialValues={{
            sti_intake_allergies: consultIntake.sti_intake_allergies || [],
            sti_intake_allergies_other:
              consultIntake.sti_intake_allergies_other || "",
            anything_else: consultIntake.anything_else || "",
          }}
          validate={(values) => {
            var errors = {} as {
              sti_intake_allergies?: string;
              sti_intake_allergies_other?: string;
              anything_else?: string;
            };
            Object.keys(allQuestions).forEach((key) => {
              const typeKey = key as keyof typeof allQuestions;
              const current = allQuestions[typeKey].current;
              if (current) {
                ReactTooltip.hide(current);
              }
            });
            ReactTooltip.rebuild();
            // required questions
            var requiredMultiselects = ["sti_intake_allergies"];
            var requiredOtherQuestions = ["sti_intake_allergies"];

            // required multiselect questions (multi select, needs at least 1)
            requiredMultiselects.forEach((key) => {
              const typeKey = key as keyof typeof values;
              if (!values[typeKey].length) {
                errors[typeKey] = "This is a required question";
                const current = allQuestions[typeKey].current;
                if (current) {
                  ReactTooltip.show(current);
                }
              }
            });

            // required "other" context if "Other" is selected
            requiredOtherQuestions.forEach((key) => {
              const otherKey = `${key}_other` as keyof typeof values;
              if (values[otherKey].includes("OT") && !values[otherKey]) {
                errors[otherKey] = "This is a required question";
                const current = allQuestions[otherKey].current;
                if (current) {
                  ReactTooltip.show(current);
                }
              }
            });
            return errors;
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            setError(null);
            submitPage(values, (response) => {
              if (typeof response === "object") {
                setApiErrors(response);
                Object.keys(response).forEach((k) => {
                  const typeKey = k as keyof typeof allQuestions;
                  const current = allQuestions[typeKey].current;
                  if (current) {
                    ReactTooltip.show(current);
                  }
                });
              } else {
                setError(response || "Error saving consult intake");
              }
            });
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <Form>
              <div className="flex-1 bg-evvy-white p-5 sm:p-8 mb-3 rounded-lg">
                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("sti_intake_allergies", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("sti_intake_allergies", errors, apiErrors)}
                  data-for="sti_intake_allergies"
                  ref={stiAllergiesQuestion}
                >
                  <ReactTooltip
                    id="sti_intake_allergies"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("sti_intake_allergies")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p>Please select all that apply</p>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.sti_intake_allergies.map(
                      (o, i) => (
                        <label
                          className="mb-2 cursor-pointer flex items-center"
                          key={o[0]}
                        >
                          <Field
                            type="checkbox"
                            name="sti_intake_allergies"
                            value={o[0]}
                            className={indexCheckboxStyles(i)}
                            onChange={uncheckNoneChangeHandler({
                              handleChange,
                              setFieldValue,
                              fieldName: "sti_intake_allergies",
                              fieldValues: values.sti_intake_allergies,
                            })}
                          />
                          <span className="ml-2">{o[1]}</span>
                        </label>
                      )
                    )}
                  </div>
                </div>

                {values.sti_intake_allergies.includes("Other") ? (
                  <div
                    className={`p-4 border rounded-md ${
                      getError("sti_intake_allergies_other", errors, apiErrors)
                        ? "border-coral"
                        : "border-transparent"
                    }`}
                    data-tip={getError(
                      "sti_intake_allergies_other",
                      errors,
                      apiErrors
                    )}
                    data-for="sti_intake_allergies_other"
                    ref={stiAllergiesOtherQuestion}
                  >
                    <ReactTooltip
                      id="sti_intake_allergies_other"
                      effect="solid"
                      place="left"
                      type="error"
                      backgroundColor="#FFA684"
                      textColor="#11161A"
                      className="rounded-xs py-20"
                    />
                    <h4 className="t1">
                      {getQuestionTitle("sti_intake_allergies_other")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name="sti_intake_allergies_other"
                      as="input"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div>
                <div className="bg-coral p-2 px-3 font-medium rounded-md my-4 text-center">
                  {errors && Object.keys(errors).length > 0
                    ? "Please fix the errors above to continue"
                    : intakeDisclaimerCopy}
                </div>
              </div>
              <div className="flex mt-6">
                <BlueRectangularButton
                  text="Continue"
                  paddingXClass="sm:px-32"
                  disabled={loading}
                  onClick={handleSubmit}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default GeneralQuestions;
