import { postRequest, getRequest } from "../utils/axios";

function recordResearchConsent(consentResponse, onSuccess, onError) {
  return postRequest(
    "/api/v1/consent/",
    { agreed: consentResponse },
    (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error, response) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

export const consentService = {
  recordResearchConsent,
};
