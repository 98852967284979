export const PrescriptionCircleIcon: React.FC<{
  className?: string;
  strokeClassName?: string;
}> = ({ className = "", strokeClassName = "" }) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      className={className}
      fill="#BFE3D9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="current" />
      <g clipPath="url(#clip0_1678_16287)">
        <path
          d="M32.6368 35.9629H17.009V20.6236C17.009 19.4936 17.9227 18.604 19.0286 18.604H30.5932C31.7232 18.604 32.6128 19.5176 32.6128 20.6236V35.9629H32.6368Z"
          className={strokeClassName}
          stroke="#074C4C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.5455 14.8089H19.1012C18.7886 14.8089 18.5242 14.5444 18.5242 14.2319V11.6112C18.5242 11.2986 18.7886 11.0342 19.1012 11.0342H30.5455C30.8581 11.0342 31.1226 11.2986 31.1226 11.6112V14.2319C31.1466 14.5685 30.8821 14.8089 30.5455 14.8089Z"
          className={strokeClassName}
          stroke="#074C4C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4719 14.8306V18.1485"
          className={strokeClassName}
          stroke="#074C4C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1995 14.8306V18.1485"
          className={strokeClassName}
          stroke="#074C4C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.935 25.8434H26.1941V23.1025H23.5975V25.8434H20.8566V28.44H23.5975V31.1809H26.1941V28.44H28.935V25.8434Z"
          className={strokeClassName}
          stroke="#074C4C"
          strokeWidth="1.4"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_1678_16287">
          <rect
            width="17.6714"
            height="27"
            fill="white"
            transform="translate(16 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
