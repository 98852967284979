import { Field, Form, Formik } from "formik";
import { getQuestionTitle } from "../../../../utils/questions";
import PageWrapper from "./PageWrapper";
import { ConsultIntake } from "../../../../types/care";
import { indexCheckboxStyles } from "../../../../utils/colors";
import { useEffect } from "react";
import { sendConsultIntakeViewSection } from "../../../../utils/analytics/customEventTracking";

interface BoricAcidProps {
  consultIntake: ConsultIntake;
  submitPage: (data: any) => void;
  isSubmitting: boolean;
}

interface FormValues {
  boric_acid_use_case: string[];
  boric_acid_use_case_other: string;
}

const BoricAcid = ({
  consultIntake,
  submitPage,
  isSubmitting,
}: BoricAcidProps) => {
  const handleSubmit = (values: FormValues) => {
    submitPage(values);
  };

  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: null,
      section: "boric acid",
      consultType: consultIntake.consult.type,
    });
  }, [consultIntake.consult.uid]);

  return (
    <PageWrapper section="Boric Acid">
      <div className="b2 mb-4">{getQuestionTitle("ungated_rx_boric_acid")}</div>
      <Formik<FormValues>
        initialValues={{
          boric_acid_use_case: consultIntake?.boric_acid_use_case || [],
          boric_acid_use_case_other:
            consultIntake?.boric_acid_use_case_other || "",
        }}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue, handleSubmit }) => (
          <Form className="mb-0">
            <div role="group" aria-labelledby="checkbox-group">
              {consultIntake?._options?.boric_acid_use_case.map(
                (option, index) => (
                  <>
                    <label
                      className="mb-4 last:mb-0 cursor-pointer flex items-center"
                      key={option[0]}
                    >
                      <Field
                        type="checkbox"
                        name="boric_acid_use_case"
                        value={option[0]}
                        className={indexCheckboxStyles(index)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                          // Clear the text field if "other" is unchecked
                          if (e.target.value === "other" && !e.target.checked) {
                            setFieldValue("boric_acid_use_case_other", "");
                          }
                        }}
                      />
                      <span className="ml-3.5">{option[1]}</span>
                    </label>
                    {values.boric_acid_use_case.includes(option[0]) &&
                      option[0] === "other" && (
                        <div className="mt-4 ml-8">
                          <Field
                            type="text"
                            name="boric_acid_use_case_other"
                            placeholder='Please describe "other"'
                            className="border-px border-evvy-black outline-none focus:ring-0 p-[14px] sm:w-[470px] mr-4"
                          />
                        </div>
                      )}
                  </>
                )
              )}
            </div>
            <PageWrapper.FormFooter
              isSubmitting={isSubmitting}
              handleSubmit={handleSubmit}
              buttonCTA="Next"
              disabled={
                !values.boric_acid_use_case?.length ||
                (values.boric_acid_use_case.includes("other") &&
                  !values.boric_acid_use_case_other.trim())
              }
            />
          </Form>
        )}
      </Formik>
    </PageWrapper>
  );
};

export default BoricAcid;
