import Markdown from "markdown-to-jsx";
import { Prescription } from "../../../types/care";
import CloseX from "../../common/closeX";

export const PrescriptionPreviewTemplate: React.FC<{
  handleClose: () => void;
  data: { prescription: Prescription };
}> = ({ handleClose, data }) => {
  const { prescription } = data;

  const descriptionParagraphs =
    prescription.preview_long_description.split("|");

  return (
    <div className="w-full flex flex-col p-10 sm:p-12 bg-evvy-white text-left">
      <div className="flex justify-end items-center">
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>
      <div className="w-full t1 text-evvy-pine mb-6">
        {prescription.preview_title}
      </div>
      <div className="caption space-y-4">
        {descriptionParagraphs.map((paragraph, index) => (
          <div key={`desc-${index}`}>
            <Markdown>{paragraph}</Markdown>
          </div>
        ))}
      </div>
    </div>
  );
};
