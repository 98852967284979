import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../common/modal";
import RectangularButton from "../common/rectangularButton";

export const ErrorModal = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate("/tests"), [navigate]);

  return (
    <Modal widthClass="max-w-1/2" closeModal={goBack}>
      <div className="p-14 bg-evvy-cream">
        <div className="md:flex gap-8 justify-between">
          <div className="max-w-sm">
            <h3>Sorry about that — something went wrong.</h3>
          </div>
          <div className="max-w-sm">
            <p className="mb-8">
              Looks like we're having a little trouble loading your data.
            </p>
            <p className="mb-8">
              {" "}
              Try checking your internet connection, waiting a few minutes, and
              refreshing the page.
            </p>
            <p className="mb-8">
              If this the problem persists, please reach out to{" "}
              <a href="mailto:support@evvy.com">support@evvy.com</a>.
            </p>
            <div className="flex flex-row-reverse">
              <Link className="w-full" to="/tests/">
                <RectangularButton
                  text="back to tests →"
                  bgColorClass="bg-evvy-blue"
                  textColorClass="text-evvy-black"
                  fullWidth={true}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
