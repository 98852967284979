import React, { useEffect, useState } from "react";
import PageWrapper from "../../../../components/care/consultIntake/ungatedRx/PageWrapper";
import { Consult, ConsultIntake } from "../../../../types/care";
import { getQuestionTitle } from "../../../../utils/questions";
import { Field, Form, Formik } from "formik";
import { indexCheckboxStyles } from "../../../../utils/colors";
import { uncheckNoneChangeHandler } from "../../../../utils/answerUtils";
import OfframpModal from "../../../../components/care/consultIntake/ungatedRx/OfframpModal";
import Markdown from "markdown-to-jsx";
import { useNavigate } from "react-router-dom";
import {
  sendConsultIntakeClickedExitCTA,
  sendConsultIntakeViewSection,
} from "../../../../utils/analytics/customEventTracking";

interface AllergiesProps {
  currentConsult?: Consult;
  consultIntake: ConsultIntake;
  submitPage: (data: any) => void;
  isSubmitting: boolean;
  setBoricAcidRemovedBecauseOfAllergies: (value: boolean) => void;
}

const Allergies = ({
  currentConsult,
  submitPage,
  consultIntake,
  isSubmitting,
  setBoricAcidRemovedBecauseOfAllergies,
}: AllergiesProps) => {
  const [
    showAllergicToSelectedTreatmentsOfframpModal,
    setShowAllergicToSelectedTreatmentsOfframpModal,
  ] = useState(false);
  const [
    showAllergiesOfframpSuccessModal,
    setShowAllergiesOfframpSuccessModal,
  ] = useState(false);
  const [conflictingProducts, setConflictingProducts] = useState<string[]>([]);
  const navigate = useNavigate();
  const [triggerHideBoricAcidQuestion, setTriggerHideBoricAcidQuestion] =
    useState(false);

  const selectedTreatments = currentConsult?.selected_treatments || [];
  const selectedTreatmentSlugs = selectedTreatments.map((t) => t.slug);

  const allergyOptions = consultIntake?._options?.allergies || [];
  const allergyCodeToDisplayName = Object.fromEntries(
    allergyOptions.map((code) => {
      return [code[0], code[1]];
    })
  );

  const relevantAllergies = [
    ...Object.keys(
      consultIntake?._options?.allergies_to_product_slug || {}
    ).filter((allergyCode) =>
      consultIntake?._options?.allergies_to_product_slug?.[allergyCode].some(
        (slug) => selectedTreatmentSlugs.includes(slug)
      )
    ),
  ];

  const allAllergies = [
    ...relevantAllergies,
    "OT", // Other option
    "NO", // None of the above option
  ];

  const handleSubmit = (values: any) => {
    const conflictingSlugsWithAllergySelected = values.allergies.reduce(
      (acc: string[], allergyCode: string) => {
        const conflictingSlugs =
          consultIntake?._options?.allergies_to_product_slug?.[allergyCode] ||
          [];
        const matchingSlugs = conflictingSlugs.filter((slug) =>
          selectedTreatmentSlugs.includes(slug)
        );
        return [...acc, ...matchingSlugs];
      },
      []
    );

    const uniqueConflicts = [
      ...new Set(conflictingSlugsWithAllergySelected),
    ] as string[];

    if (
      uniqueConflicts.some((slug) =>
        consultIntake?._options?.allergies_to_product_slug?.BA?.includes(slug)
      )
    ) {
      // the reason why we don't set the parent state directly is because we allow the user
      // to confirm or deny the offramp below before we actually remove the boric acid treatment
      setTriggerHideBoricAcidQuestion(true);
    }

    const conflictingTitles = uniqueConflicts.map((slug) => {
      const treatment = selectedTreatments.find((t) => t.slug === slug);
      return treatment?.title_short_display || slug;
    });

    setConflictingProducts(conflictingTitles);

    if (uniqueConflicts.length > 0) {
      setShowAllergicToSelectedTreatmentsOfframpModal(true);
    } else {
      submitPage(values);
    }
  };

  const handleOfframpConfirm = (values: any) => {
    // no matter the number of conflicts, we always submit the page
    // so that on backend update we can trigger the necessary offramp logic
    // success modal is only shown if all selected treatments are offramped
    if (conflictingProducts.length === selectedTreatments.length) {
      setShowAllergiesOfframpSuccessModal(true);
    }
    setShowAllergicToSelectedTreatmentsOfframpModal(false);
    submitPage(values);
    if (triggerHideBoricAcidQuestion) {
      setBoricAcidRemovedBecauseOfAllergies(true);
    }
  };

  const handleOfframpSuccess = () => {
    // Trigger analytics
    sendConsultIntakeClickedExitCTA({
      consultId: consultIntake.consult.uid,
      testHash: null,
      ctaText: "return home",
      consultType: consultIntake.consult.type,
      exitType: "allergies",
    });
    setShowAllergiesOfframpSuccessModal(false);
    navigate("/tests");
  };

  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: null,
      section: "allergies",
      consultType: consultIntake.consult.type,
    });
  }, [consultIntake.consult.uid]);

  return (
    <>
      <PageWrapper section="Allergies">
        <div className="b2 mb-4">
          {getQuestionTitle("ungated_rx_allergies")}
        </div>
        <Formik
          initialValues={{
            allergies: consultIntake.allergies || [],
            allergies_other: consultIntake.allergies_other || "",
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, setFieldValue, handleSubmit }) => (
            <>
              <Form className="mb-0">
                <div role="group" aria-labelledby="checkbox-group">
                  {allAllergies.map((allergyCode, index) => (
                    <label
                      className="mb-4 last:mb-0 cursor-pointer flex items-center"
                      key={allergyCode}
                    >
                      <Field
                        type="checkbox"
                        name="allergies"
                        value={allergyCode}
                        className={indexCheckboxStyles(index)}
                        onChange={(event: {
                          target: { checked: boolean; value: string };
                        }) => {
                          uncheckNoneChangeHandler({
                            handleChange,
                            setFieldValue,
                            fieldName: "allergies",
                            fieldValues: values.allergies,
                            noneCode: ["NO"],
                          })(event);
                          if (
                            !event.target.checked &&
                            event.target.value === "OT"
                          ) {
                            setFieldValue("allergies_other", "");
                          }
                        }}
                      />
                      <span className="ml-3.5">
                        {allergyCodeToDisplayName[allergyCode]}
                      </span>
                    </label>
                  ))}

                  {values.allergies.includes("OT") && (
                    <div className="mt-4 ml-8">
                      <Field
                        type="text"
                        name="allergies_other"
                        placeholder="Please specify other allergies"
                        className="w-full p-2 border rounded"
                      />
                    </div>
                  )}
                </div>
                <PageWrapper.FormFooter
                  isSubmitting={isSubmitting}
                  handleSubmit={handleSubmit}
                  buttonCTA="Next"
                  disabled={
                    !values.allergies?.length ||
                    (values.allergies.includes("OT") &&
                      !values.allergies_other.trim())
                  }
                />
              </Form>
              {showAllergicToSelectedTreatmentsOfframpModal && (
                <OfframpModal
                  heading="Please Confirm"
                  onConfirm={() => handleOfframpConfirm(values)}
                  onClose={() =>
                    setShowAllergicToSelectedTreatmentsOfframpModal(false)
                  }
                  buttonOptions="double"
                >
                  <Markdown className="leading-normal">
                    {`Based on your allergies, you are no longer eligible for ${
                      conflictingProducts.length === 1
                        ? "a treatment"
                        : "these treatments"
                    } in your order. **Please confirm you would like us to cancel and refund ${
                      conflictingProducts.length === 1
                        ? "the following treatment"
                        : "the following treatments"
                    }:**`}
                  </Markdown>
                  <ul className="ml-0 mt-6">
                    {conflictingProducts.map((title) => (
                      <li key={title}>• {title}</li>
                    ))}
                  </ul>
                </OfframpModal>
              )}
              {showAllergiesOfframpSuccessModal && (
                <OfframpModal
                  heading="Thank you"
                  onConfirm={handleOfframpSuccess}
                  onClose={() => setShowAllergiesOfframpSuccessModal(false)}
                  buttonOptions="single"
                >
                  <Markdown className="leading-normal">
                    Our team will be in touch to refund your order.
                  </Markdown>
                </OfframpModal>
              )}
            </>
          )}
        </Formik>
      </PageWrapper>
    </>
  );
};

export default Allergies;
