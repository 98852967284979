/**
 * An outlined button styled with a plus button
 * @param {string} text the text label for the button
 * @param {*} onClick function to handle button click
 * @returns jsx of a button
 */

import { React } from "react";

const PlusButtonPill = ({ text, onClick }) => {
  return (
    <button
      className={`flex items-center text-[12px] sm:text-[13px] font-semibold uppercase tracking-wide border border-evvy-pine rounded-full px-4 py-1 mt-3 mr-2 transition-colors text-evvy-pine hover:bg-evvy-pine hover:text-white`}
      onClick={onClick}
    >
      {text}
      <span className="ml-1">+</span>
    </button>
  );
};

export default PlusButtonPill;
