import { useState } from "react";

import { SampleLoad } from "./sampleLoad";
import { ShowDetailButton } from "../common/showDetailButton";

// icons
import { MicrobialLoadLevel } from "../../types/results";
import { LOAD_DISPLAY_MAP } from "../../pages/results_v2/constants";

import { sendExpandedResultsCard } from "../../utils/analytics/customEventTracking";
import { useBaseAnalyticsArgs } from "../../hooks/useBaseAnalyticsArgs";

type OverallSampleLoadProps = {
  level: MicrobialLoadLevel;
  className?: string;
};

const MICROBIAL_LOAD_DEFINITION =
  "Microbial load refers to the absolute number of DNA copies found in a sample, reported for the overall sample as well as the 11 microbes included in the initial PCR test.";

const MICROBIAL_LOAD_VALUE_DEFINITION = {
  L: "A low microbial load is < 10⁵,",
  M: "A medium microbial load ranges between 10⁵ to 10⁷",
  H: "A high microbial load is > 10⁷.",
};

export const OverallSampleLoad: React.FC<OverallSampleLoadProps> = ({
  className = "",
  level,
}: OverallSampleLoadProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  return (
    <div className={className}>
      <div className="flex justify-between content-center my-auto">
        <span className="t3 semibold">{`Overall microbial load: ${LOAD_DISPLAY_MAP[level]}`}</span>
        <span className="hidden sm:block w-5/12 my-auto">
          <SampleLoad level={level} />
        </span>
        <ShowDetailButton
          open={showDetails}
          toggleOpenClose={() => {
            setShowDetails(!showDetails);
            sendExpandedResultsCard({
              cardType: "sample-load",
              ...baseAnalyticsArgs,
            });
          }}
        />
      </div>
      <SampleLoad level={level} className="block sm:hidden mt-4" />
      {showDetails && (
        <div className="mt-5">
          <div className="text-base sm:text-lg medium pb-3">
            {MICROBIAL_LOAD_DEFINITION}
          </div>
          <div className="text-base sm:text-lg regular">
            {MICROBIAL_LOAD_VALUE_DEFINITION[level]}
          </div>
        </div>
      )}
    </div>
  );
};
