// external
import React, { useState, useEffect } from "react";

// imported components
import Markdown from "markdown-to-jsx";

// components
import CloseX from "../../common/closeX";
import Citations from "../../common/citations";
// lib
import { researchCopy } from "../../../pages/plan_unfurled/constants.tsx";
import { HEALTH_ASSOCIATIONS_COPY } from "../../../pages/results_v2/constants";

const RecommondationContentTemplate = ({ data, handleClose }) => {
  const {
    name: title = "",
    description = "",
    research_rating: researchRating,
    associationlinkcitation_set: citations,
    name,
  } = data || {};

  return (
    <div className="w-full bg-evvy-white px-4 py-8 md:p-8">
      {/* close button */}
      <div className="flex justify-end items-center">
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>
      {/* title   */}
      <div className="t1 my-6">{title}</div>
      <hr />
      {/* step details  - new line at pipe indicator*/}
      <div className="my-6">
        {description &&
          description.split("|")?.map((descriptionItem, i) => (
            <p key={`reccomend-desc-${i}`}>
              <Markdown>{descriptionItem}</Markdown>
            </p>
          ))}
      </div>

      <div className="my-6">
        <p key="association-disclaimer">
          <Markdown>{HEALTH_ASSOCIATIONS_COPY.DISCLAIMER}</Markdown>
        </p>
      </div>
      {/* research grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mt-10">
        {/* research */}
        <div className="">
          <div className="t3 text-evvy-pine">Research</div>
          <div className="py-3 my-4 border-b border-t border-evvy-pine/15">
            <p className="text-evvy-pine mb-1">
              <span className="medium">Status: </span>
              <span>{researchRating?.title}</span>
            </p>
            <div className="caption">{researchRating?.description}</div>
          </div>
          <div className="caption">{researchCopy.RESEARCH}</div>
        </div>
        {/* sources  (if there are any) */}
        {citations && citations.length > 0 && (
          <Citations
            citations={citations}
            eventArgs={{ name, resultsVersion: "v2" }} // will be changed in the future
          />
        )}
      </div>
    </div>
  );
};

export default RecommondationContentTemplate;
