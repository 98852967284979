/**
 * A donut styled by category type
 * @param {string} color a string for the bacteria category color (protective, neutral/variable, disruptive, unknown)
 * @param {number} percentage a number indicating the percentage of affected users for a category
 * @param {object} size an object for chart dimensions {width, height, margin, innerRadius, outerRadius}
 * @returns jsx of a svg, in the shape of a donut chart
 */

import React, { useRef, useEffect } from "react";
import { select, pie, arc } from "d3";

const Donut = ({ color, percentage, size }) => {
  // calculate percent remainder from incoming percentage value
  const chartData = [
    {
      name: "affected",
      value: percentage,
    },
    {
      name: "remaining",
      value: 100 - percentage,
    },
  ];

  // main ref element
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      // main chart svg
      const svg = select(ref.current);

      svg.attr("width", size.width).attr("height", size.height);

      // chart groups
      const group = svg
        .append("g")
        .attr("class", "donut-group")
        .attr("transform", `translate(${size.width / 2}, ${size.height / 2})`);

      // arc size object
      const arcPie = pie()
        .value((d) => d.value)
        .sort(null); // prevents default sort by group size

      // arc generator - generates arc path based on radius params
      const arcGenerator = arc()
        .innerRadius(size.innerRadius)
        .outerRadius(size.outerRadius);

      // define arc section
      const section = group
        .selectAll(".section")
        .data(arcPie(chartData))
        .join("g")
        .attr("class", (d) => d?.data && `section group-${d.data.name}`);

      // draw arc section
      section
        .append("path")
        .attr("class", (d) => d?.data?.name)
        .attr("d", arcGenerator)
        .attr("fill", color)
        .attr("opacity", (d) =>
          d?.data?.name === "remaining" ? "30%" : "100%"
        );
    }
  }, []);

  return (
    <div className="donut-chart">
      <svg className="main-group" ref={ref}></svg>
    </div>
  );
};

export default Donut;
