import {
  View,
  Text,
  StyleSheet,
  Page,
  Image,
  Link,
  Svg,
  Path,
  Rect,
  Circle,
  Document,
} from "@react-pdf/renderer";
import pdfStyles from "./pdfStyles";
import { ProblemsSummary, TestScores } from "../../types/test";
import { formatDate } from "../../utils/datetime";
import { ProviderTestData } from "../../types/provider";
import { Marked } from "@ts-stack/markdown";
import Html from "react-pdf-html";

const baseStyles = StyleSheet.create(pdfStyles[0] as any);

const moduleStyles = StyleSheet.create({
  body: {
    color: "#11161A",
    fontFamily: "Inter",
  },
  // header
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: "6%",
    height: "10%",
    backgroundColor: "#F3F1EE",
    opacity: 0.8,
  },
  leftColumn: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
  rightColumn: {
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  headerRightDetails: {
    alignItems: "flex-end",
  },
  headerText: {
    ...baseStyles.fs_10,
    ...baseStyles.fw_semi_bold,
  },
  image: {
    width: 70,
  },
  headerLeft: {
    flexDirection: "column",
  },
  headerRight: {
    flexDirection: "column",
    alignItems: "flex-end",
  },

  // content
  contentContainer: {
    paddingHorizontal: "6%",
    paddingVertical: "4%",
    height: "82%",
    ...baseStyles.fs_9,
  },
  identifyingInfoContainer: {
    opacity: 0.6,
  },
  resultsText: {
    fontFamily: "Inter",
    ...baseStyles.fs_10,
    margin: 0,
  },
  link: {
    fontFamily: "Inter",
    ...baseStyles.fs_10,
    marginBottom: 0,
    color: "black",
    textDecoration: "underline",
    textDecorationColor: "black",
    textUnderlineOffset: 6,
  },

  // accessory components
  microbiomeStiPill: {
    backgroundColor: "#E6F0FF",
    paddingHorizontal: 7,
    paddingVertical: 3,
    borderRadius: 10,
    marginVertical: 2,
    flexDirection: "row",
    alignItems: "center",
  },
  microbiomeStiPillHorizontalMargin: {
    marginHorizontal: 4,
  },
  microbiomeStiPillText: {
    ...baseStyles.uppercase,
    ...baseStyles.fs_9,
  },
  inlineText: {
    ...baseStyles.fs_10,
  },
  textContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },

  // results visualization
  resultsBar: {
    height: 16,
    flexDirection: "row",
    backgroundColor: "#F5F1ED", // Light beige background
    overflow: "hidden",
    marginBottom: 6,
  },
  resultSegment: {
    height: "100%",
  },
  legendContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 8,
  },
  legendCircle: {
    width: 10,
    height: 10,
    borderRadius: 8,
    marginRight: 4,
  },
  legendText: {
    ...baseStyles.fs_h5,
    fontFamily: "Inter",
    color: "#333",
  },

  symptomTagContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 4,
    marginTop: 4,
  },
  symptomTag: {
    backgroundColor: "#ded7d1", // Light gray background for tags
    borderRadius: 9999,
    paddingVertical: 3,
    paddingHorizontal: 10,
    textAlign: "center",
    marginRight: 4,
    marginBottom: 5,
  },
  symptomTagText: {
    ...baseStyles.fs_9,
    fontFamily: "Inter",
  },

  // footer diclaimer
  footerContainer: {
    height: "8%",
    ...baseStyles.fs_h5,
    textAlign: "center",
    paddingHorizontal: "6%",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.6,
    borderTop: "1px solid #D3D3D3",
    lineHeight: 1.6,
  },
});

// logo img src
const imgUrl = `${process.env.PUBLIC_URL}/images/evvy-logo-text.png`;

const STI_DISCLAIMER_LEADING_TEST =
  "The positive STI diagnosis has been confirmed by an Evvy-affiliated provider. Any other conditions mentioned in this report are not a diagnosis.";
const NON_STI_DISCLAIMER_LEADING_TEST = "This is not a diagnosis.";
const DISCLAIMER_BODY_COPY =
  "The information above is based on published literature and clinical guidelines on the associations of certain symptoms and microbes with different vaginal conditions. This information can be leveraged by a provider in order to correctly diagnose a patient.";

const getColor = (type: string): string => {
  switch (type.toLowerCase()) {
    case "sti":
      return "#F58D8D"; // STI red color
    case "infection":
    case "disrupted":
    case "disruptive":
      return "rgba(255, 166, 132, 0.8)"; // salmon with 80% opacity
    case "healthy":
      return "rgba(65, 144, 122, 0.5)"; // green
    case "protective":
      return "#6CA7FF"; // darker blue
    case "variable":
      return "#C29ED5"; // purple
    case "suboptimal":
    default:
      return "#CEDEFC"; // lighter blue
  }
};

const MicrobiomeStiPill: React.FC<{
  children: React.ReactNode;
  style?: any;
  type?: "microbiome" | "sti";
}> = ({ children, style, type = "microbiome" }) => {
  const backgroundColor = getColor(
    type === "sti" ? "sti" : (children as string)
  );
  const pillStyle = [
    moduleStyles.microbiomeStiPill,
    { backgroundColor },
    type !== "sti" && moduleStyles.microbiomeStiPillHorizontalMargin,
    style,
  ];
  return (
    <View style={pillStyle}>
      {type === "sti" && (
        <Svg
          width={10}
          height={12}
          viewBox="0 0 37 37"
          style={{ paddingTop: 1, marginLeft: -1.5 }}
        >
          <Path
            d="M18.1662 2.89062C18.3146 2.63368 18.6854 2.63368 18.8338 2.89062L32.185 26.0156C32.3334 26.2726 32.1479 26.5938 31.8512 26.5938H5.14878C4.85208 26.5938 4.66665 26.2726 4.815 26.0156L18.1662 2.89062Z"
            stroke="black"
            stroke-width="2.75"
          />
          <Rect
            x="17.50008"
            y="9.75"
            width="1.8"
            height="10.4319"
            rx="1.49028"
            fill="black"
          />
          <Circle cx="18.5" cy="22.6626" r="1" fill="black" />
        </Svg>
      )}
      <Text style={moduleStyles.microbiomeStiPillText}>{children}</Text>
    </View>
  );
};

// New component for wrapping symptoms
const SymptomsPills: React.FC<{ symptoms: string[] }> = ({ symptoms }) => {
  return (
    <View style={moduleStyles.symptomTagContainer}>
      {symptoms.map((symptom, i) => (
        <SymptomTagPill key={`${symptom}-${i}`} tagText={symptom} />
      ))}
    </View>
  );
};

const SymptomTagPill = ({ tagText }: { tagText: string }) => {
  return (
    <View style={moduleStyles.symptomTag}>
      <Text style={[moduleStyles.symptomTagText, baseStyles.fw_semi_bold]}>
        {tagText}
      </Text>
    </View>
  );
};

const ResultsVisualization = ({ testScores }: { testScores: TestScores }) => {
  return (
    <View>
      <View style={moduleStyles.resultsBar}>
        {Object.entries(testScores)
          .filter(([_, score]) => score.value > 0)
          .map(([key, score]) => (
            <View
              key={key}
              style={[
                moduleStyles.resultSegment,
                {
                  flex: score.value,
                  backgroundColor: getColor(key),
                },
              ]}
            />
          ))}
      </View>
      <View style={moduleStyles.legendContainer}>
        {Object.entries(testScores)
          .filter(([_, score]) => score.value > 0)
          .map(([key, score]) => (
            <View key={key} style={moduleStyles.legendItem}>
              <View
                style={[
                  moduleStyles.legendCircle,
                  {
                    backgroundColor: getColor(key),
                  },
                ]}
              />
              <Text style={moduleStyles.legendText}>
                {`${score.value}% ${key}`}
              </Text>
            </View>
          ))}
      </View>
    </View>
  );
};

interface ProblemsModulePdfProps {
  problemsSummary: ProblemsSummary;
  testData: ProviderTestData;
}

const MicrobiomeStateDisplay: React.FC<{
  problemsSummary: ProblemsSummary;
}> = ({ problemsSummary }) => {
  const { microbiome_state, stis, ngs_microbiome_state } = problemsSummary;

  const renderDisruptedState = () => {
    return (
      <>
        <MicrobiomeStiPill>infection</MicrobiomeStiPill>
        <Text style={moduleStyles.inlineText}>in the vaginal microbiome.</Text>
      </>
    );
  };
  const renderNonDisruptedState = (microbiome_state: string) => {
    return (
      <>
        <MicrobiomeStiPill>{microbiome_state}</MicrobiomeStiPill>
        <Text style={moduleStyles.inlineText}>vaginal microbiome</Text>
      </>
    );
  };

  if (microbiome_state === "sti-positive") {
    return (
      <>
        <Text style={moduleStyles.inlineText}>
          Results from this test indicate the presence of{" "}
        </Text>
        {stis.map((sti, index) => (
          <MicrobiomeStiPill key={index} style={baseStyles.mr_s} type="sti">
            {sti}
          </MicrobiomeStiPill>
        ))}
        <Text style={moduleStyles.inlineText}>
          and may indicate a{ngs_microbiome_state === "disrupted" && "n"}
        </Text>
        {ngs_microbiome_state === "disrupted"
          ? renderDisruptedState()
          : renderNonDisruptedState(ngs_microbiome_state)}
      </>
    );
  } else if (microbiome_state === "disrupted") {
    return (
      <>
        <Text style={moduleStyles.inlineText}>
          Results and symptoms from this test may indicate an
        </Text>
        {renderDisruptedState()}
      </>
    );
  } else {
    return (
      <>
        <Text style={moduleStyles.inlineText}>
          Results from this test indicate a
        </Text>
        {renderNonDisruptedState(microbiome_state)}
      </>
    );
  }
};

const ProblemsModulePdf: React.FC<ProblemsModulePdfProps> = ({
  problemsSummary,
  testData,
}) => {
  return (
    <Document>
      <Page size="A4" style={moduleStyles.body}>
        <View style={moduleStyles.headerContainer}>
          <View style={moduleStyles.headerLeft}>
            <Image style={moduleStyles.image} src={imgUrl} />
            <Text style={[moduleStyles.headerText, baseStyles.mt_s]}>
              Vaginal Health Test
            </Text>
          </View>
          <View style={moduleStyles.headerRight}>
            <View style={[moduleStyles.headerRightDetails, baseStyles.mb_s]}>
              <Text style={moduleStyles.headerText}>169 Madison Ave #2064</Text>
              <Text style={moduleStyles.headerText}>New York, NY 10016</Text>
            </View>
            <View style={moduleStyles.headerRightDetails}>
              <Text style={moduleStyles.headerText}>support@evvy.com</Text>
            </View>
          </View>
        </View>

        <View style={moduleStyles.contentContainer}>
          <Text>Evvy Expanded Vaginal Health Test</Text>
          <Text
            style={[
              baseStyles.fw_extra_bold,
              baseStyles.fs_h3,
              baseStyles.mb_m,
              baseStyles.mt_s,
            ]}
          >
            Microbiome Summary
          </Text>
          <View style={moduleStyles.identifyingInfoContainer}>
            <View style={baseStyles.flex_row}>
              <Text style={[baseStyles.fw_semi_bold, baseStyles.mr_xs]}>
                Patient Name:
              </Text>
              <Text>
                {testData.patientFirstName} {testData.patientLastName}
              </Text>
            </View>
            <View style={baseStyles.flex_row}>
              <Text style={[baseStyles.fw_semi_bold, baseStyles.mr_xs]}>
                Test ID:
              </Text>
              <Text>{testData.testHash}</Text>
            </View>
            <View style={baseStyles.flex_row}>
              <Text style={[baseStyles.fw_semi_bold, baseStyles.mr_xs]}>
                Sample taken on:
              </Text>
              <Text>
                {testData.sampleDate &&
                  formatDate(new Date(testData.sampleDate).getTime())}
              </Text>
            </View>
          </View>
          {/* solid divider line */}
          <View
            style={[
              baseStyles.mt_m,
              baseStyles.mb_m,
              { borderBottom: "1px solid #D3D3D3" },
            ]}
          />
          <Text
            style={[
              baseStyles.uppercase,
              baseStyles.fw_bold,
              baseStyles.mt_m,
              baseStyles.mb_l,
              baseStyles.fs_10,
            ]}
          >
            Provider Insights
          </Text>
          <View
            style={[
              moduleStyles.textContainer,
              baseStyles.fw_bold,
              baseStyles.mb_l,
            ]}
          >
            <MicrobiomeStateDisplay problemsSummary={problemsSummary} />
          </View>
          <Html
            stylesheet={{
              p: {
                ...moduleStyles.resultsText,
                marginBottom: 14,
              },
              "p:last-child": {
                marginBottom: 0,
              },
              a: moduleStyles.link,
            }}
          >
            {problemsSummary.provider_info
              .map((info) => Marked.parse(info))
              .join("")}
          </Html>
          {problemsSummary.ngs_microbiome_state === "disrupted" && (
            <View style={[baseStyles.mt_m, baseStyles.ml_s]}>
              {problemsSummary.conditions.map((condition) => (
                <Text style={baseStyles.fs_10} key={condition}>
                  <Text> • </Text>
                  {condition[0] + condition.slice(1).toLowerCase()}
                </Text>
              ))}
            </View>
          )}

          {/* dotted divider line */}
          <View
            style={[
              baseStyles.mt_m,
              baseStyles.mb_m,
              { borderBottom: "1px dotted #D3D3D3" },
            ]}
          />

          <Text style={[baseStyles.mb_m, baseStyles.fs_10]}>
            The{" "}
            <Link
              src="https://www.evvy.com/blog/community-state-types"
              style={moduleStyles.link}
            >
              Community State Type
            </Link>{" "}
            (CST) associated with this test is Type{" "}
            {problemsSummary?.overall_cst_type}
          </Text>

          <View
            style={[
              baseStyles.mt_m,
              baseStyles.mb_m,
              { borderBottom: "1px solid #D3D3D3" },
            ]}
          />

          <Text
            style={[
              baseStyles.uppercase,
              baseStyles.fw_bold,
              baseStyles.mt_l,
              baseStyles.mb_s,
            ]}
          >
            Breakdown of Microbes Identified
          </Text>
          <ResultsVisualization testScores={testData?.scores} />
          <View style={[baseStyles.mt_m]}>
            {problemsSummary.symptoms.length > 0 ? (
              <>
                <Text
                  style={[
                    baseStyles.uppercase,
                    baseStyles.fw_bold,
                    baseStyles.mt_xxl,
                    baseStyles.mb_s,
                  ]}
                >
                  Symptoms Noted At Sample Collection
                </Text>
                <SymptomsPills symptoms={problemsSummary.symptoms} />
              </>
            ) : (
              <Text style={[baseStyles.fw_semi_bold, baseStyles.mt_l]}>
                No Reported Symptoms At Sample Collection
              </Text>
            )}
          </View>

          <View style={[baseStyles.mt_xl]}>
            {problemsSummary.context.length > 0 && (
              <>
                <Text style={[baseStyles.uppercase, baseStyles.fw_bold]}>
                  Relevant Context From Health History
                </Text>
                <SymptomsPills symptoms={problemsSummary.context} />
              </>
            )}
          </View>
        </View>

        <View style={moduleStyles.footerContainer}>
          <Text>
            {problemsSummary.stis.length > 0
              ? STI_DISCLAIMER_LEADING_TEST
              : NON_STI_DISCLAIMER_LEADING_TEST}
            {DISCLAIMER_BODY_COPY}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default ProblemsModulePdf;
