import { scaleOrdinal, groups, rollup, sum } from "d3";
import { daysElapsed } from "../../utils/datetime";

// takes in an array of objects
// {
//   hash: string;
//   create_date: date;
//   healthcontext: {key: string, value: string ("NO", "MI", "MO", "SE")},
//   test_order: number;
// }[]
// returns an array of objects
// { desc: string;
//   score: string; ("NO", "MI", "MO", "SE")
//   score_numerical: number; (0, 1, 2, 3)
//   hash: string;
//   date: date;
// }[]

const getScoreNumerical = scaleOrdinal(
  ["NO", "MI", "MO", "SE"],
  [0.2, 1, 2, 3]
);

const getTrackerData = (data) => {
  const trackerData = data.flatMap((d) =>
    Object.entries(d.healthcontext)
      .filter(([key]) => key !== "sample_taken_at")
      .map(([symptom, score]) => ({
        desc: symptom,
        score,
        score_numerical: getScoreNumerical(score),
        hash: d.hash,
        date: d.create_date,
      }))
  );

  // group data by symptom
  const groupedTrackerData = groups(trackerData, (d) => d.desc);

  // filter out symptoms that are "none" for all tests
  const filteredTrackerData = groupedTrackerData.filter(
    ([symptom, data]) =>
      data && data.find(({ score }) => ["MO", "MI", "SE"].includes(score))
  );

  return filteredTrackerData;
};

// takes in the bacteria results data and returns a map of bacteria type to total percent (e.g 'GO' => 98)
const getAggregatedPercentages = (microbiome) =>
  rollup(
    microbiome,
    (v) => sum(v, (d) => d.normalized_percent),
    (d) => d.bacteria.type
  );

const timeSincePeriod = (test, control) => {
  if (
    test?.health_history?.basics_last_period_date &&
    control?.selectedOption?.healthcontext?.sample_taken_at
  ) {
    const dateOne = new Date(
      control.selectedOption.healthcontext.sample_taken_at
    );
    const dateTwo = new Date(test.health_history.basics_last_period_date);

    return `${daysElapsed(dateTwo, dateOne) - 1} days before test`;
  } else {
    return "Didn't start";
  }
};

export { getTrackerData, getAggregatedPercentages, timeSincePeriod };
