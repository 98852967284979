import React, { useEffect } from "react";

import Modal from "../../../components/common/modal";
import ExitConsultModal from "../../../components/care/consultIntake/exitConsultModal";
import {
  eventNames,
  sendConsultIntakeViewSection,
} from "../../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../../utils/analytics/helpers";

const IntakeExit = ({ consultIntake }) => {
  // if intake has "Yes" as answer to currently pregnant, then the exit reason is due to pregnancy.
  // Otherwise, it has to be due to state the user listed in their shipping address
  const ineligibilityReason =
    consultIntake.consult?.ineligible_reason === "no-treatment"
      ? "no-treatment"
      : consultIntake.currently_pregnant === "YE"
      ? "pregnancy"
      : consultIntake.severe_symptoms === "YE"
      ? "severe-symptoms"
      : consultIntake.sti_treated === "NO"
      ? "untreated-sti"
      : consultIntake?.medical_conditions?.includes("OC") ||
        consultIntake?.medical_conditions?.includes("CC") ||
        consultIntake?.medical_conditions?.includes("EC") ||
        consultIntake?.medical_conditions?.includes("D1") ||
        consultIntake?.medical_conditions?.includes("D2") ||
        consultIntake?.medical_conditions?.includes("HI")
      ? "medical-condition"
      : "state";

  /* Effects */
  // First render
  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: consultIntake?.consult?.test_hash,
      section: "exit",
      consultType: consultIntake?.consult?.type,
    });
  }, []);

  // analytics utils
  const analyticsOnClick = ({ ctaText, exitType }) =>
    analyticsClickHandler({
      eventName: eventNames.CONSULT_INTAKE_CLICKED_EXIT_CTA,
      eventArgs: {
        consultId: consultIntake.consult.uid,
        testHash: consultIntake?.consult?.test_hash,
        exitType,
        ctaText,
        consultType: consultIntake?.consult?.type,
      },
    });

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Modal widthClass="w-full md:w-2/5">
        <ExitConsultModal
          modalType={ineligibilityReason}
          analyticsOnClick={analyticsOnClick}
          consult={consultIntake.consult}
        />
      </Modal>
    </div>
  );
};

export default IntakeExit;
