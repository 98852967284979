import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LayoutWelcome from "../../components/welcome/layout";
import RectangularButton from "../../components/common/rectangularButton";

// services
import { consentService } from "../../services/consent";
import { authService } from "../../services/auth";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import {
  sendResearchConsentClickedCTA,
  sendResearchConsentViewedPage,
} from "../../utils/analytics/customEventTracking";

const ResearchConsent = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [consentCompleted, setConsentCompleted] = useState(false);

  const currentUser = useLoggedInUser();
  const isUngatedRxAccount =
    currentUser?.identity?.registrationRoute === "treatment";
  const navigate = useNavigate();

  // TODO: replace with link from pita
  const RESEARCH_LINK =
    "https://drive.google.com/file/d/1COe6RrXNAgDNK6hNiRg0IqMZeMTESMzG/view?usp=sharing";

  useEffect(() => {
    sendResearchConsentViewedPage({
      version: isUngatedRxAccount ? "ungated-rx" : "standard",
    });
  }, []);
  // actions
  const recordConsent = (consentResp) => {
    // if consent already completed, then just redirect without submitting
    if (consentCompleted) {
      if (!currentUser.identity.emailVerified) {
        navigate("/verify-email/");
        return;
      } else {
        navigate("/");
        return;
      }
    }

    // else if consent not completed, then submit and redirect
    sendResearchConsentClickedCTA({
      ctaText: consentResp ? "agree" : "decline",
      version: isUngatedRxAccount ? "ungated-rx" : "standard",
    });

    setLoading(true);
    consentService.recordResearchConsent(
      consentResp,
      (response) => {
        setConsentCompleted(true);
        authService.fetchUser(
          (resp) => {
            if (!resp.data.identity.email_verified) {
              navigate("/verify-email/");
            } else {
              navigate("/");
            }
            setLoading(false);
          },
          () => {
            setLoading(false);
            setError("Failed to fetch user");
          }
        );
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setError("Error recording research consent.");
        setLoading(false);
      }
    );
  };

  return (
    <LayoutWelcome
      metaTitle="Research Consent"
      contentWidthClass="sm:max-w-2xl"
      smallTopMargin={true}
    >
      {error && (
        <div className="bg-coral p-2 px-3 text-sm font-medium rounded-xs">
          {error}
        </div>
      )}
      <h4 className="text-3xl">
        Join us in revolutionizing how we understand & treat the female body.
      </h4>
      <div className="t3 medium pb-2">
        Women were not included in clinical research in the US until 1993.
      </div>
      <div className="b2 pb-4">
        So much of female health (and especially vaginal health!) is still a
        medical mystery, largely because we're lacking critical data on female
        biomarkers. Evvy is here to change that — by helping you better
        understand your own body while powering a platform that demystifies
        female health conditions as a whole.
      </div>
      <div className="t3 medium pb-2">
        A new future in women's health research.
      </div>
      <div className="b2 pb-2">
        By agreeing, your data will become part of Evvy's research program,
        ensuring that the next generation of healthcare is designed for people
        with vaginas. All of your data is encrypted, and your identifying
        information (like your name or email) will never be publicized.
      </div>
      <div className="b2">
        Here are some examples of some of the research we're pioneering in
        vaginal health:
      </div>
      <ul className="list-disc pt-2 space-y-0.5 mb-3">
        <li className="b2 my-0">More specific vaginal health diagnoses</li>
        <li className="b2 my-0">Novel, holistic treatment options</li>
        <li className="b2 my-0">
          Biomarker identification for broader female health conditions
        </li>
      </ul>
      <div className="t4 mt-7 pb-8 border-b border-black border-dashed">
        More information can be found{" "}
        <a href={RESEARCH_LINK} className="underline persistSize">
          here
        </a>
        . If you have any questions, reach out to us anytime at{" "}
        <a href="mailto:support@evvy.com">support@evvy.com</a>
      </div>
      <div className="flex sm:hidden mt-12 mb-14 justify-between">
        <div className="my-auto">
          <button
            disabled={loading}
            className={`linkedText text-sm uppercase tracking-wider semibold cursor-pointer w-max p-pd mt-0`}
            onClick={() => recordConsent(false)}
          >
            decline
          </button>
        </div>
        <div className="">
          <RectangularButton
            text={`agree & continue ->`}
            bgColorClass="bg-evvy-blue"
            textColorClass="text-evvy-black"
            verticalPadding="py-5"
            disabled={loading}
            onClick={() => recordConsent(true)}
          />
        </div>
      </div>
      <div className="hidden sm:block mt-9 mb-32 text-center">
        <div className="mx-auto">
          <RectangularButton
            text={`agree & continue ->`}
            bgColorClass="bg-evvy-blue"
            textColorClass="text-evvy-black"
            verticalPadding="py-5"
            disabled={loading}
            onClick={() => recordConsent(true)}
          />
        </div>
        <div className="mt-7">
          <button
            disabled={loading}
            className={`linkedText text-sm uppercase tracking-wider semibold cursor-pointer w-max p-pd mt-0`}
            onClick={() => recordConsent(false)}
          >
            decline
          </button>
        </div>
      </div>
    </LayoutWelcome>
  );
};
export default ResearchConsent;
