/**
* Type results section with SVG and Text
* @param {object} typeData a data object containing user result data:
  {
    dominant_bacteria_category: string
    health_associations: array
    info: string[]
    name: string
    name_group: string
    name_user_facing: string
    percent_evvy_users: number
  }
* @param {string} sectionType a string which defines the component output, donut (donut chart + copy) vs circlePack (svg image + copy).
* @param {boolean} mobileSize a boolean to check window size and adjust svg size accordingly

* @returns jsx of a card 
*/

import React, { useEffect, useState } from "react";

// constants
import {
  smallTypeSvgConfig,
  largeTypeSvgConfig,
  RESULTS_COPY,
  SECTION_TYPES,
} from "../../../pages/results_v2/constants";

//icons
import { ReactComponent as CirclePack } from "../icons/circle-pack.svg";

// utils
import { categoryToColorMap } from "../../../utils/viz";

// local components
import Donut from "./resultsDonut";
import { getIsMobile } from "../../../utils/general";

const TypeResultsDetail = ({ typeData, sectionType, mobileSize }) => {
  // deconstruct typedata for necessary variables
  const {
    dominant_bacteria_category: category,
    percent_evvy_users: percentage,
    name_user_facing: userType,
  } = typeData || {};

  // set color for svg based on bacteria type
  const color = category && categoryToColorMap[category].fill;

  // svg size can be set to smallTypeSvgConfig || largeTypeSvgConfig. set for CirclePack & Donut
  const size = mobileSize ? smallTypeSvgConfig : largeTypeSvgConfig;

  const isMobile = getIsMobile();

  // TODO fix size update on mobileSize value change
  // const [size, setSize] = useState(mobileSize ? smallTypeSvgConfig: largeTypeSvgConfig)
  // useEffect(() => {
  //   mobileSize ? setSize(smallTypeSvgConfig) : setSize(largeTypeSvgConfig);
  // }, [mobileSize])

  return (
    <div className="py-4">
      {/* checks for sectionType prop, expects: circlePack || donut. returns svg + copy based on sectionType prop */}
      <div className="flex flex-row">
        {sectionType && sectionType === SECTION_TYPES.circlePack ? (
          <CirclePack width={size.width} height={size.height} fill={color} />
        ) : (
          <Donut color={color} percentage={percentage} size={size} />
        )}
        <div
          className={`${
            isMobile ? "text-base font-medium" : "t3"
          } px-6 my-auto`}
        >
          {sectionType && sectionType === SECTION_TYPES.circlePack ? (
            <>
              {/* returns circlePack copy */}
              <div className="text-left">
                {RESULTS_COPY.BACTERIA_TYPE[category] &&
                  RESULTS_COPY.BACTERIA_TYPE[category]}
              </div>
              <div className="text-left">
                <strong>
                  {category === "variable" ? "variable" : category} bacteria.
                </strong>
              </div>
            </>
          ) : (
            <>
              {/* returns donut copy */}
              <div className="text-left pr-8">
                <strong>{percentage && percentage}%</strong> of Evvy users are
                also categorized as {userType && userType}.
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TypeResultsDetail;
