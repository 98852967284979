import React from "react";
import {
  HealthContextFields,
  HealthContextOptions,
  HealthContextRelatedDiagnosesOptions,
} from "../../types/test";
import {
  HEALTH_HISTORY_PAGES,
  Question,
  getRelatedDiagnosisMap,
} from "../../utils/healthHistoryV2";
import { getQuestionTitle } from "../../utils/questions";
import { Helmet } from "react-helmet";
import classNames from "classnames";

const RELATED_DIAGNOSIS_PAGES = [
  "autoimmune",
  "infertility",
  "cancer-other",
  "cancer-breast",
  "cancer-ovarian",
  "cancer-cervical",
  "cancer-endometrial",
  "diabetes",
  "hpv",
  "cin",
  "endometriosis",
];

const TestResponsesV2 = ({
  healthContext,
}: {
  healthContext: HealthContextFields;
}) => {
  if (!healthContext) {
    return null;
  }
  const seenSections: string[] = [];

  const getOptionTitle = (option: string, question: Question) => {
    const relatedDiagnosisOptions = healthContext._related_diagnosis_options;
    const healthContextOptions = healthContext._options;

    if (question.answerType === "multiselect_dimensional_dropdown") {
      const options = relatedDiagnosisOptions.related_diagnoses;
      const answerOption = options.find((value) => value[0] === option);
      return answerOption?.[1];
    }

    if (question.isRelatedDiagnosisFollowup) {
      let options;
      if (question.getFollowupOptions) {
        options =
          question.getFollowupOptions({
            healthContextValues: healthContext,
          }) || [];
      } else {
        options =
          relatedDiagnosisOptions[
            question.slug as keyof HealthContextRelatedDiagnosesOptions
          ];
      }
      const answerOption = options.find((value) => value[0] === option);
      return <div className="mb-8">{answerOption?.[1]}</div>;
    } else {
      let questionOptions;
      if (question.getOptions) {
        questionOptions = question.getOptions({
          healthContextValues: healthContext,
        });
      } else {
        questionOptions =
          healthContextOptions[question.slug as keyof HealthContextOptions];
      }
      const answerOption = questionOptions?.find(
        (value) => value[0] === option
      );
      return <div className="mb-8">{answerOption?.[1]}</div>;
    }
  };

  const getDiagnosisYear = (diagnosis: string) => {
    const relatedDiagnoses = getRelatedDiagnosisMap(
      healthContext.healthcontextrelateddiagnoses_set
    );
    const diagnosisYear = relatedDiagnoses[diagnosis]?.diagnosis_year;
    return diagnosisYear;
  };

  const healthHistoryResponses = HEALTH_HISTORY_PAGES.map((page) => {
    const showSectionTitle = !seenSections.includes(page.section);
    if (!seenSections.includes(page.section)) {
      seenSections.push(page.section);
    }
    if (
      page.showPage({ healthContextValues: healthContext }) &&
      page.questions.length > 0 &&
      page.slug !== "more-info"
    ) {
      return (
        <div
          className={classNames("mb-8", {
            "border-t border-dotted border-gray-400 pt-8": showSectionTitle,
          })}
        >
          {showSectionTitle && <h3>{page.section}</h3>}
          {RELATED_DIAGNOSIS_PAGES.includes(page.slug) && (
            <div className={"mb-8 -mt-3 ml-1"}>
              Diagnosed in {getDiagnosisYear(page.slug)}
            </div>
          )}
          {page.slug === "treatment-response" && (
            <div className={`t3 mb-4 `}>
              {getQuestionTitle("treatment_response")}
            </div>
          )}
          {page.questions
            .filter((q) =>
              q.showQuestion({
                currentPageHealthContextValues: healthContext,
                healthContextValues: healthContext,
              })
            )
            .map((question) => {
              const questionValue = question.getInitialValue({
                healthContextValues: healthContext,
              });
              if (question.answerType === "multiselect") {
                if (questionValue.length === 0 || !questionValue) {
                  return null;
                }
                return (
                  <div>
                    <div className={`t3 mb-2`}>
                      {getQuestionTitle(question.slug)}
                    </div>
                    <ul className="content list-disc">
                      {questionValue?.map((value: string) => {
                        return <li>{getOptionTitle(value, question)}</li>;
                      })}
                    </ul>
                  </div>
                );
              } else if (question.answerType === "singleselect") {
                if (questionValue !== "") {
                  return (
                    <div
                      className={
                        page.slug === "treatment-response" ? "ml-4" : ""
                      }
                    >
                      <div className={`t3 mb-2`}>
                        {getQuestionTitle(question.slug)}
                      </div>
                      <p>{getOptionTitle(questionValue, question)}</p>
                    </div>
                  );
                }
              } else if (
                question.answerType === "multiselect_dimensional_dropdown"
              ) {
                if (questionValue !== "") {
                  return (
                    <div
                      className={
                        page.slug === "treatment-response" ? "ml-4" : ""
                      }
                    >
                      <div className={`t3 mb-2`}>
                        {getQuestionTitle(question.slug)}
                      </div>
                      <div className="mt-4">
                        {Object.keys(questionValue).map((key) => {
                          if (questionValue[key]) {
                            return (
                              <li>
                                {getOptionTitle(key, question)} - Diagnosed in{" "}
                                {questionValue[key]}{" "}
                              </li>
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                }
              } else if (question.answerType === "height") {
                if (questionValue !== "") {
                  return (
                    <div className="mb-4">
                      <div className={`t3 mb-2`}>
                        {getQuestionTitle(question.slug)}
                      </div>
                      {parseInt(((questionValue as any) / 12) as any)}'{" "}
                      {(questionValue as any) % 12}"
                    </div>
                  );
                }
              } else if (
                question.answerType === "singletext" ||
                question.answerType === "multitext" ||
                question.answerType === "number" ||
                question.answerType === "number_scale" ||
                question.answerType === "dropdown"
              ) {
                if (questionValue === "" || !questionValue) {
                  return null;
                }
                return (
                  <div>
                    <div className={`t3 mb-2`}>
                      {getQuestionTitle(question.slug)}
                    </div>
                    <p>{questionValue}</p>
                  </div>
                );
              } else if (
                question.answerType === "multiselect_dimensional" ||
                question.answerType === "multiselect_dimensional_short"
              ) {
                return question.isRelatedDiagnosisFollowup ? (
                  <div>
                    <div className={`t3 mb-2 `}>
                      {getQuestionTitle(question.slug)}
                    </div>
                    {Object.keys(questionValue).map((key) => {
                      if (
                        question.options?.includes(key) &&
                        questionValue[key] &&
                        !key.includes("_none")
                      ) {
                        return (
                          <div>
                            <div className={`t3 mb-2 ml-4`}>
                              {getQuestionTitle(key)}
                            </div>
                            <div className="ml-4">
                              {getOptionTitle(questionValue[key], question)}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                ) : (
                  <div>
                    <div className={`t3 mb-2`}>
                      {getQuestionTitle(question.slug)}
                    </div>
                    {Object.keys(questionValue).map((key) => {
                      if (
                        questionValue[key] &&
                        questionValue[key] !== "" &&
                        questionValue[key].length > 0 &&
                        !key.includes("_none")
                      ) {
                        return (
                          <div>
                            <div className={`t3 ml-4 mb-2`}>
                              {getQuestionTitle(key)}
                            </div>
                            <div className={"ml-4"}>
                              {Array.isArray(questionValue[key])
                                ? questionValue[key].map((value: string) => {
                                    return (
                                      <p>{getOptionTitle(value, question)}</p>
                                    );
                                  })
                                : getOptionTitle(questionValue[key], question)}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              }
            })}
        </div>
      );
    }
  });

  return (
    <div>
      <Helmet>
        <title>Test | Responses</title>
      </Helmet>
      <div className="mb-0 bg-evvy-cream rounded-lg p-5 sm:p-12">
        {!healthContext.health_history_submitted_at ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-md my-6">
            You are viewing an incomplete subset of your responses for this
            test. Please submit your health history.
          </div>
        ) : (
          ""
        )}
        <div className="block w-full">
          <div className="py-10 text-center">
            <h3>Your Health Context</h3>
            <div className="t4">
              Test Date:{" "}
              {String(healthContext.health_history_submitted_at) ||
                "missing date"}
            </div>
          </div>
        </div>
        {healthHistoryResponses}
      </div>
    </div>
  );
};

export default TestResponsesV2;
