import React from "react";

import { ReactComponent as FiveStars } from "./icons/five-stars.svg";
import { PrescriptionCircleIcon } from "./icons/prescriptionCircleIcon";
import { PillsCircleIcon } from "./icons/pillsCircleIcon";
import { CreamCircleIcon } from "./icons/creamCircleIcon";
import WomanProfileImage from "../common/images/womanProfile.png";
import { ReactComponent as ArrowDown } from "./icons/arrow-down.svg";
import Markdown from "markdown-to-jsx";

import { getIsMobile } from "../../utils/general";

const SUB_HEADER = "From the community";
const TITLE_MD = "Amy’s Evvy Journey";
const REVIEW_QUOTE = `“My gynecologist kept recommending treatments that clearly weren’t working for my recurrent vaginal infections. With Evvy, I immediately felt better and accompanied in this journey.”`;
const REVIEWER_NAME = "Amy E.";
const REVIEWER_DESCRIPTION = "Real Evvy Customer";
const GOAL_TITLE = "VAGINAL HEALTH GOAL";
const GOAL_DESCRIPTION =
  "Get relief from recurrent vaginal infections and external itching";
const TREATMENT_PROGRAM_TITLE = "TREATMENT PROGRAM";
const TREATMENT_1 = "Compounded treatments";
const TREATMENT_2 = "Personalized supplements";
const TREATMENT_3 = "Topical soothing cream";
const TREATMENT_OUTCOME_TITLE = "TREATMENT OUTCOMES";
const BEFORE_TITLE_MD = "**Before** Evvy | Dec 23, 2022";
const AFTER_TITLE_MD = "**After** Evvy | Mar 6, 2023";
const PERCENTAGES_CONFIG = [
  [87, 13],
  [12, 77, 7, 4],
];

export const CareReview = () => {
  const isMobile = getIsMobile();
  const backgroundIconFill = "fill-[#DED7D1]";
  return (
    <div className="max-w-7xl mx-auto px-4 md:px-0 py-12 md:py-20 md:flex">
      <div className="md:w-2/5 bg-evvy-pine rounded-t-3xl md:rounded-r-none md:rounded-l-3xl p-12 md:p-20 text-center md:text-left">
        <div className="text-highlighter-yellow t4">{SUB_HEADER}</div>
        <h2 className="text-evvy-white py-2">
          <Markdown>{TITLE_MD}</Markdown>
        </h2>
        <img
          src={WomanProfileImage}
          loading="lazy"
          alt="Photo of Amy"
          className="object-cover h-44 w-44 mx-auto md:mx-0"
        />
        <div className="text-evvy-white pt-8">
          <FiveStars className="mx-auto md:mx-0" />
          <div className="t2 regular py-5">{REVIEW_QUOTE}</div>
          <div className="b1 medium">{`- ${REVIEWER_NAME}`}</div>
          <div className="b1 italic">{REVIEWER_DESCRIPTION}</div>
        </div>
      </div>
      <div className="md:w-3/5 bg-evvy-cream rounded-r-3xl p-12 md:p-16 space-y-12 md:space-y-14 text-center md:text-left">
        <div className="space-y-5">
          <div className="bg-highlighter-mint rounded-full w-fit t4 px-3 py-1 mx-auto md:mx-0">
            {GOAL_TITLE}
          </div>
          <div className="t1 medium">{GOAL_DESCRIPTION}</div>
        </div>
        <div className="space-y-8 justify-center">
          <div className="bg-highlighter-mint rounded-full w-fit t4 px-3 py-1 mx-auto md:mx-0">
            {TREATMENT_PROGRAM_TITLE}
          </div>
          <div className="block md:flex regular md:medium t3 text-evvy-black space-y-5 md:space-y-0">
            <div className="flex ml-6 md:ml-0">
              <div className="mr-3">
                <PrescriptionCircleIcon
                  className={`${backgroundIconFill} h-9 w-9 md:h-12 md:w-12`}
                  strokeClassName="stroke-black"
                />
              </div>
              <div className="my-auto">{TREATMENT_1}</div>
            </div>
            <div className="flex ml-6 md:ml-0">
              <div className="mr-3">
                <PillsCircleIcon
                  className={`${backgroundIconFill} h-9 w-9 md:h-12 md:w-12`}
                  strokeClassName="stroke-black"
                />
              </div>
              <div className="my-auto">{TREATMENT_2}</div>
            </div>
            <div className="flex ml-6 md:ml-0">
              <div className="mr-3">
                <CreamCircleIcon
                  className={`${backgroundIconFill} h-9 w-9 md:h-12 md:w-12`}
                  strokeClassName="stroke-black"
                />
              </div>
              <div className="my-auto">{TREATMENT_3}</div>
            </div>
          </div>
        </div>
        <div className="space-y-8">
          <div className="bg-highlighter-mint rounded-full w-fit t4 px-3 py-1 mx-auto md:mx-0">
            {TREATMENT_OUTCOME_TITLE}
          </div>
          <div className="flex">
            <ArrowDown className="-ml-6 mr-4 mt-6 hidden md:block" />
            <div className="space-y-12 w-full">
              {isMobile ? <VerticalChart /> : <HorizontalChart />}
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 font-semibold text-sm tracking-wider uppercase ml-8 md:ml-0">
                <div className="flex">
                  <div className="dot-legend rounded-full bg-GO-fill mr-2" />
                  <div className="">Protective</div>
                </div>
                <div className="flex">
                  <div className="dot-legend rounded-full bg-BA-fill mr-2" />
                  <div className="">Disruptive</div>
                </div>
                <div className="flex">
                  <div className="dot-legend rounded-full bg-NE-fill mr-2" />
                  <div className="">Neutral</div>
                </div>
                <div className="flex">
                  <div className="dot-legend rounded-full bg-UN-fill mr-2" />
                  <div className="">Unknown</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HorizontalChart = () => {
  return (
    <>
      {/* Before bar */}
      <div>
        <div className="mb-2">
          <Markdown>{BEFORE_TITLE_MD}</Markdown>
        </div>
        <div className="flex w-full h-12">
          <div
            className={`bg-BA-fill h-full`}
            style={{ width: `${PERCENTAGES_CONFIG[0][0]}%` }}
          ></div>
          <div
            className={`bg-UN-fill h-full`}
            style={{ width: `${PERCENTAGES_CONFIG[0][1]}%` }}
          ></div>
        </div>
      </div>
      {/* After bar */}
      <div>
        <div className="mb-2">
          <Markdown>{AFTER_TITLE_MD}</Markdown>
        </div>
        <div className="flex w-full h-12">
          <div
            className={`bg-BA-fill h-full`}
            style={{ width: `${PERCENTAGES_CONFIG[1][0]}%` }}
          ></div>
          <div
            className={`bg-GO-fill h-full`}
            style={{ width: `${PERCENTAGES_CONFIG[1][1]}%` }}
          ></div>
          <div
            className={`bg-NE-fill h-full`}
            style={{ width: `${PERCENTAGES_CONFIG[1][2]}%` }}
          ></div>
          <div
            className={`bg-UN-fill h-full`}
            style={{ width: `${PERCENTAGES_CONFIG[1][3]}%` }}
          ></div>
        </div>
      </div>
    </>
  );
};

const VerticalChart = () => {
  return (
    <div className="flex justify-around leading-6">
      {/* Before bar */}
      <div>
        <div className="mb-6">
          <Markdown>{BEFORE_TITLE_MD.replace("|", "<br>")}</Markdown>
        </div>
        <div className="h-80 w-20 mx-auto">
          <div
            className={`bg-BA-fill h-full`}
            style={{ height: `${PERCENTAGES_CONFIG[0][0]}%` }}
          ></div>
          <div
            className={`bg-UN-fill h-full`}
            style={{ height: `${PERCENTAGES_CONFIG[0][1]}%` }}
          ></div>
        </div>
      </div>
      {/* After bar */}
      <div>
        <div className="mb-6">
          <Markdown>{AFTER_TITLE_MD.replace("|", "<br>")}</Markdown>
        </div>
        <div className="h-80 w-20 mx-auto">
          <div
            className={`bg-BA-fill h-full`}
            style={{ height: `${PERCENTAGES_CONFIG[1][0]}%` }}
          ></div>
          <div
            className={`bg-GO-fill h-full`}
            style={{ height: `${PERCENTAGES_CONFIG[1][1]}%` }}
          ></div>
          <div
            className={`bg-NE-fill h-full`}
            style={{ height: `${PERCENTAGES_CONFIG[1][2]}%` }}
          ></div>
          <div
            className={`bg-UN-fill h-full`}
            style={{ height: `${PERCENTAGES_CONFIG[1][3]}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};
