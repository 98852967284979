// external
import React, { useState, useEffect } from "react";

// imported components
import Markdown from "markdown-to-jsx";

// components
import CloseX from "../../common/closeX";

// constants
import { HEALTH_ASSOCIATIONS_COPY } from "../../../pages/results_v2/constants";

const AssociationDisclaimerTemplate = ({ handleClose }) => {
  const title = "About CST Associations";

  return (
    <div className="w-full bg-evvy-white px-4 py-8 md:p-8">
      {/* close button */}
      <div className="flex justify-end items-center">
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>
      {/* title   */}
      <div className="t1 my-6">{title}</div>
      <hr />
      {/* details  - new line at pipe indicator*/}
      <div className="b2 my-6">
        <p className="semibold">
          <Markdown>{HEALTH_ASSOCIATIONS_COPY.WHAT_ASSOCIATION}</Markdown>
        </p>
        <p>
          <Markdown>
            {HEALTH_ASSOCIATIONS_COPY.ASSOCIATION_DESCRIPTION}
          </Markdown>
        </p>
        <p className="semibold">
          <Markdown>{HEALTH_ASSOCIATIONS_COPY.WHAT_CONDITION}</Markdown>
        </p>
        <p>
          <Markdown>{HEALTH_ASSOCIATIONS_COPY.CONDITION_DISCLAIMER}</Markdown>
        </p>
        <p className="semibold">
          <Markdown>{HEALTH_ASSOCIATIONS_COPY.WHAT_NEXT}</Markdown>
        </p>
        {HEALTH_ASSOCIATIONS_COPY.NEXT_DESCRIPTION.split("|")?.map(
          (descriptionItem, i) => (
            <p key={`disclaimer-desc-${i}`}>
              <Markdown>{descriptionItem}</Markdown>
            </p>
          )
        )}
      </div>
    </div>
  );
};

export default AssociationDisclaimerTemplate;
