import { useProviderTestOrders } from "../../hooks/provider/useProviderTestOrders";
import { ProviderTestTable } from "./providerTestTable";
import { ReactComponent as ChecklistIcon } from "../../components/common/icons/checklist.svg";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../../components/common/loadingSpinner";
import { sendProviderViewedTestsPage } from "../../utils/analytics/customEventTracking";
import { useEffect } from "react";

export const ProviderTests = () => {
  const [orders, loading, error] = useProviderTestOrders();

  useEffect(() => {
    sendProviderViewedTestsPage();
  }, []);
  const PROVIDER_PORTAL_COACHING_CALL_URL =
    "https://calendly.com/lindsay-evvy/evvy-provider-portal-coaching-call";

  return (
    <div className="max-w-7xl mx-auto w-full h-full grow">
      {error ? (
        <div className="bg-red-400 p-8 rounded-2xl mb-4 max-w-5xl mx-auto">
          {error}
        </div>
      ) : loading ? (
        <div className="flex items-center justify-center h-full">
          <LoadingSpinner />
        </div>
      ) : orders.length > 0 ? (
        <div className="flex flex-col gap-2 min-h-[500px]">
          <div className="bg-white p-8 rounded-2xl mt-4 overflow-auto">
            <h3>Track Orders</h3>
            <ProviderTestTable orders={orders} />
          </div>
          <div className="bg-white p-4 rounded-2xl mt-4 mb-8 text-gray-500 flex gap-4 justify-center ">
            <QuestionMarkCircleIcon className="h-5 w-5 " />
            <h4 className="mb-0 text-center text-[1rem] ">
              Need help deciphering results?{" "}
              <a
                href={PROVIDER_PORTAL_COACHING_CALL_URL}
                target="_blank"
                rel="noopener noreferrer"
                className=" underline !text-[1rem]"
              >
                Schedule a quick session
              </a>{" "}
              with an Evvy coach.
            </h4>
          </div>
        </div>
      ) : (
        <div className="bg-white p-8 rounded-2xl mt-4 mb-8 text-gray-500 ">
          <ChecklistIcon className="h-6 w-6 mx-auto " />
          <h4 className="mb-0 text-center mt-4 text-[1rem]">
            All tests ordered for your patients will show up here. Place an
            order to get started!
          </h4>
        </div>
      )}
    </div>
  );
};
