import { useEffect, useState } from "react";

const NUM_RAYS = 8;
const CX = 60.5;
const CY = 60;
const RADIUS = 20;
const R_INNER = 43;
const R_OUTER = 50;
const R_END = 75;
export const CompleteCycle = () => {
  const angles = Array.from(
    { length: NUM_RAYS },
    (_, i) => (360 / NUM_RAYS) * i
  );
  const [showRays, setShowRays] = useState(false);
  const [inTransition, setInTransition] = useState(true);

  useEffect(() => {
    // timer for how long until the rays should start bursting outwards
    let transitionTimer: NodeJS.Timeout;
    // timer for how long to wait until starting to show the rays at all
    let rayBurstStartTimer = setTimeout(() => {
      setShowRays(true);
      transitionTimer = setTimeout(() => {
        setInTransition(false);
      }, 100);
    }, 600);
    return () => {
      clearTimeout(rayBurstStartTimer);
      clearTimeout(transitionTimer);
    };
  }, []);

  return (
    <svg
      className="my-auto"
      width="121"
      height="120"
      viewBox="0 0 121 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g
          className="animate-single-spin"
          style={{
            transformOrigin: `${CX - RADIUS}px ${CY - RADIUS}px`,
            translate: `${RADIUS}px ${RADIUS}px`,
          }}
        >
          <path
            d="M77.4824 39.4139C77.2644 39.6319 76.9011 39.6319 76.6831 39.4139C75.7022 38.542 73.1591 37.0888 73.0864 36.3621C71.4152 21.1033 59.1718 8.71456 43.5496 7.26133C43.2227 7.225 43.586 7.225 43.259 7.225C43.0773 7.225 42.9683 6.97069 43.15 6.82536L46.2381 3.84625C46.3471 3.77359 46.3471 3.62827 46.2381 3.51928L43.4406 0.358516C43.2953 0.213193 43.4043 -0.0411209 43.6223 -0.00479033C43.9493 0.0315403 43.6223 -0.00479033 43.9493 0.0315403C63.2772 1.70275 78.4633 17.0343 80.3525 35.9625C80.4252 36.7981 78.3907 38.5783 77.4824 39.4139Z"
            className="fill-evvy-blue"
          />
          <path
            d="M40.5349 77.0885C40.317 76.8706 40.3533 76.5072 40.5349 76.2893C41.4069 75.3447 42.9328 72.8015 43.623 72.7289C58.9182 71.312 71.4523 59.2502 73.1962 43.6644C73.2325 43.3374 73.2325 43.7007 73.2325 43.3737C73.2325 43.1921 73.4868 43.0831 73.6321 43.2647L76.5749 46.3892C76.6476 46.4982 76.7929 46.4982 76.9019 46.4255L80.1353 43.7007C80.2806 43.5554 80.5349 43.6644 80.4986 43.8824C80.4623 44.2093 80.4986 43.8824 80.4623 44.2093C78.5004 63.5009 62.9146 78.4691 43.9863 80.0676C43.1507 80.0676 41.3705 77.9968 40.5349 77.0885Z"
            className="fill-evvy-blue"
          />
          <path
            d="M3.33494 40.5419C3.55292 40.3239 3.91623 40.3239 4.13421 40.5419C5.11514 41.4138 7.65828 42.8671 7.73094 43.5937C9.40215 58.8525 21.6456 71.2413 37.2677 72.6945C37.5947 72.7308 37.2314 72.7308 37.5584 72.7308C37.74 72.7308 37.849 72.9851 37.6674 73.1305L34.5793 76.1096C34.4703 76.1822 34.4703 76.3276 34.5793 76.4366L37.3767 79.6336C37.522 79.779 37.4131 80.0333 37.1951 79.997C36.8681 79.9606 37.1951 79.997 36.8681 79.9606C17.5402 78.2894 2.35401 62.9579 0.46482 44.0296C0.392159 43.194 2.39034 41.3775 3.33494 40.5419Z"
            className="fill-evvy-blue"
          />
          <path
            d="M40.3145 2.90399C40.5348 3.11962 40.5024 3.48329 40.3231 3.70321C39.4613 4.65712 37.9629 7.21652 37.2734 7.29659C21.9943 8.87775 9.59059 21.0735 8.0143 36.6772C7.98148 37.0045 7.97758 36.6412 7.98109 36.9682C7.98304 37.1498 7.72991 37.2615 7.58265 37.0815L4.60647 33.9888C4.53264 33.8806 4.38732 33.8822 4.27912 33.956L1.07594 36.7881C0.932188 36.9349 0.676717 36.8287 0.710703 36.6103C0.743518 36.283 0.710703 36.6103 0.743518 36.283C2.49758 16.9351 17.9221 1.83663 36.832 0.0347919C37.6668 -0.0468437 39.4692 2.00475 40.3145 2.90399Z"
            className="fill-evvy-blue"
          />
        </g>
        {angles.map((angle, index) => {
          const x1 = CX + R_INNER * Math.cos((angle * Math.PI) / 180);
          const y1 = CY - R_INNER * Math.sin((angle * Math.PI) / 180);
          const x2 = CX + R_OUTER * Math.cos((angle * Math.PI) / 180);
          const y2 = CY - R_OUTER * Math.sin((angle * Math.PI) / 180);
          const x3 = CX + R_END * Math.cos((angle * Math.PI) / 180);
          const y3 = CY - R_END * Math.sin((angle * Math.PI) / 180);

          return (
            <line
              key={index}
              x1={x1}
              y1={y1}
              x2={x2}
              y2={y2}
              stroke="#6CA7FF" // using stroke-evvy-blue wasn't working for <line> svg component
              strokeWidth="2"
              className={`${
                !showRays ? "hidden" : ""
              } transition-all duration-500 ease-in-out`}
              style={
                !inTransition
                  ? {
                      transform: `translate(${x3 - x2}px, ${y3 - y2}px)`,
                      opacity: 0,
                    }
                  : {}
              }
            />
          );
        })}
        <foreignObject
          className="overflow-visible"
          x={CX}
          y={CY}
          width="100%"
          height="100%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          <div
            className="flex flex-col items-center justify-center"
            style={{
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="relative">
              {inTransition && (
                <div
                  className={`${
                    inTransition ? "animate-slide-up-out" : "hidden"
                  } absolute text-center semibold fill-evvy-black`}
                >
                  3
                </div>
              )}
              {
                <div
                  className={`animate-slide-up-in absolute text-center semibold fill-evvy-black`}
                >
                  4
                </div>
              }
              <span className="semibold fill-evvy-black ml-3.5"> / 4</span>
            </div>
          </div>
        </foreignObject>
      </g>
      <defs>
        <clipPath id="clip0_1605_9145">
          <rect
            width="140.1817"
            height="140"
            fill="white"
            transform="translate(-19.6816 -0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
