const Check = () => {
  return (
    <div className="mr-4">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle fill="#40907A" cx="10" cy="10" r="10" />
        <path stroke="white" strokeWidth="2" d="M5.37,10.16l3,3,6.28-6.28" />
      </svg>
    </div>
  );
};

export default Check;
