/**
 * A circle icon for "you should try"
 * @param {string} textColorClass this should be a string for a text color, such as `text-evvy-black`, since in tailwind, the stroke of the circle is referenced with `currentColor` which is set via a text class
 * @returns jsx of an x
 */
const Circle = ({ textColorClass }) => {
  return (
    <div
      className={`mr-4 ${textColorClass ? textColorClass : "text-evvy-blue"}`}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle stroke="currentColor" cx="10" cy="10" r="8" strokeWidth="4" />
      </svg>
    </div>
  );
};

export default Circle;
