import React from "react";
import { createEvents } from "ics";

import { downloadFile } from "../../utils/files";
import { getWeekCTADescription } from "./constants";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";

// a button that when clicked exports the treatment calendar to an .ics file
const TreatmentCalendarIcsExport = ({
  children,
  treatmentPlan,
  baseUrl,
  onClickPopup,
  retestLink,
}) => {
  const currentUser = useLoggedInUser();

  // generates calendar and exports it as a file
  const exportCalendar = () => {
    const fileContents = generateFileContents();
    if (!fileContents) return;

    // generate ICS with text/calendar mimetype
    downloadFile(fileContents, "evvy_treatment_calendar.ics", "text/calendar");
  };

  // based on treatmentPlan, returns ICS file format
  const generateFileContents = () => {
    const appUrl = currentUser.env.frontendUrl || "https://my.evvy.com";
    const treatmentPlanUrl = `${appUrl}${baseUrl}`; //
    const days = treatmentPlan.calendar.treatment_schedule;
    var eventsData = [];
    var lastDate = null;
    days.forEach((day) => {
      const ts = new Date(`${day.date}T00:00:00`); // removes user local timezone and
      const startDate = [ts.getFullYear(), ts.getMonth() + 1, ts.getDate()]; // to format for ICS cal

      // new event for each prescription/date conbo
      day.prescriptions.forEach((prescription) => {
        // title goes from "Vaginal Probiotic |(Lactoferrin, GR-1 probiotic blend)" to "Vaginal Probiotic"
        var title = prescription.title.split("(")[0];
        title = title.split("|")[0];

        // construct the various parts of the event description
        const descriptionTitle = prescription.title.split("|").join(`\n`); // put into separate lines if have pipe
        const descriptionInstructions = (prescription.instructions || "")
          .split("|")
          .filter((i) => i)
          .join(`\n`); // instructions get split into lines (and blank lines removed)
        const descriptionLink = `See treatment plan at ${treatmentPlanUrl}prescription/${prescription.slug}/`;

        eventsData.push({
          title: title,
          description: `${descriptionTitle}\n\n${descriptionInstructions}\n\n${descriptionLink}`,
          start: startDate,
          classification: "PRIVATE",
        });
        lastDate = startDate;
      });
    });

    // final step, add retest reminder!
    eventsData.push({
      title: "Retest with Evvy",
      description: `${getWeekCTADescription(
        "final-retest",
        currentUser?.subscription?.is_member
      )}\n\nOrder next test at ${retestLink}`,
      start: lastDate,
      classification: "PRIVATE",
    });

    // this actually converts it to the file format
    const { error, value } = createEvents(eventsData);
    if (error) {
      alert(error.message || "Error generating calendar file");
      return;
    }
    return value;
  };

  return <div onClick={onClickPopup || exportCalendar}>{children}</div>;
};

export default TreatmentCalendarIcsExport;
