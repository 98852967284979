import { getTagBackgroundClass } from "./utils";

export const PrescriptionTag: React.FC<{ tag: string }> = ({ tag }) => {
  const tagBackgroundColorClass = getTagBackgroundClass(tag);
  return (
    <div
      className={`t4 flex items-center rounded-sm flex-shrink-0 py-2 px-4 bg-gradient-to-r ${tagBackgroundColorClass}`}
    >
      {tag}
    </div>
  );
};
